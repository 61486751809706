
import { getBaseURL } from 'helpers';
import React, { useState } from 'react'
const PDF_File_URL = '/sample.pdf';

const PDFViewerModal = () => {
    const handleClick = () => {
        const newTab = window.open(getBaseURL(PDF_File_URL), '_blank');

        // Dynamically set the favicon for the new tab
        if (newTab) {
            const faviconLink = document.createElement('link');
            faviconLink.rel = 'icon';
            faviconLink.href = './public/favicon.ico'; // Replace with the actual path to your favicon
            newTab.document.head.appendChild(faviconLink);
        }
    };
    let exp = '>>'

    const downloadFileAtURL = (url) => {
        const fileName = url.split("/").pop();
        const aTag = document.createElement('a');
        aTag.setAttribute('id', 'pdf-download');
        aTag.href = url;
        aTag.setAttribute('download', fileName);
        document.body.appendChild(aTag);
        aTag.click();
        aTag.remove();

    }
    return (
        <>


            <div className='row py-4'>
                <div className='col-sm-2'>
                    <i
                        className="fa-solid fa-file-arrow-down text-primary fs-1 cursor-pointer"
                        onClick={() => { downloadFileAtURL(getBaseURL(PDF_File_URL)) }}
                    >
                    </i>
                    <i
                        onClick={handleClick}
                        className="fa-regular mx-3 fa-file-pdf fs-1 text-dark cursor-pointer"
                    >
                    </i>
                </div>
                <div className="col-sm-8 text-center">
                    <h1 className='fw-bold fs-1'>SECTION 9. APINAE
                    </h1>
                </div>
            </div>
            <div className='row py-5 text-center'>
                <div className='col-sm-12'>
                    <p className=''>Chapter 9.1.</p>
                    <h1>INFESTATION OF HONEY BEES WITH ACARAPIS WOODI</h1>
                </div>
            </div>
            <div className='row py-4 '>
                <div className='col-sm-12'>
                    <p className='text-center mt-2'> Article 9.1.1</p>
                    <p>
                        <strong>General provisions</strong>
                        <br />

                        For the purposes of the Terrestrial Code, acarapisosis, also known as acarine disease or tracheal mite infestation, is an infestation of adult honey bees (species of the genus Apis), primarily Apismellifera L. with the mite Acarapiswoodi, an internal obligate parasite of the respiratory system which spreads by direct contact from adult honey bee to adult honey bee.
                        <br />
                        When authorising import or transit of the commodities covered in the chapter, with the exception of those listed in Article 9.1.2., Veterinary Authorities should require the conditions prescribed in this chapter relevant to the acarapisosis status of the honey bee population of the exporting country or zone.
                        <br />
                        Standards for diagnostic tests are described in the Terrestrial Manual.</p>
                    <p className='text-center  mt-2'> Article 9.1.2</p>
                    <p>
                        <strong>Safe commodities
                        </strong>
                        <br />
                        When authorising import or transit of the following commodities, Veterinary Authorities should not require any acarapisosis-related conditions, regardless of the acarapisosis status of the honey bee population of the exporting country or zone:
                        <br />
                        <ol>
                            <li>
                                pre-imago (eggs, larvae and pupae) of honey bees;
                            </li>
                            <li>
                                honey bee semen;
                            </li>
                            <li>
                                honey bee venom;
                            </li>
                            <li>
                                used apicultural equipment;
                            </li>
                            <li>
                                honey;
                            </li>
                            <li>
                                bee-collected pollen;
                            </li>
                            <li>
                                propolis;
                            </li>
                            <li>
                                beeswax;
                            </li>
                            <li>
                                royal jelly.
                            </li>
                        </ol>
                    </p>
                </div>
            </div>

            <hr />
            <div className='text-center'>
                <p>Last Updated: 2 Oct,2023</p>
            </div>
        </>

    )
}

export default PDFViewerModal;