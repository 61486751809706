import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { setCurrentPage, setHeaderTheme } from '../../../../redux/generalSlice';
import { useTranslation } from "react-i18next";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';

const SearchStandards = () => {

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [level1, setLevel1] = React.useState('');
    const [level2, setLevel2] = React.useState('');
    const [level3, setLevel3] = React.useState('');
    // useEffect(() => {
    //     dispatch(setHeaderTheme('white'));
    //     dispatch(setCurrentPage("form"));
    // }, []);
    const handleChange1 = (event) => {
        setLevel1(event.target.value);
    };

    const handleChange2 = (event) => {
        setLevel2(event.target.value);
    };

    const handleChange3 = (event) => {
        setLevel3(event.target.value);
    };
    return (
        <>
            <div className="container py-3">
                <div className="header card-woah border border-secondary px-4 py-2 shadow-sm w-100 my-2">
                    <div className='d-flex justify-content-between w-100'>
                        <div className="card-title1 lh-1">{t("-1", "Dynamic search and access to the information")}</div>
                    </div>

                </div>
                <div className='header card-woah border border-secondary px-4 py-2 shadow-sm w-100 my-2 '>
                <div className="row py-3">

                    <div className="col-md-3 p-3">
                        <h1>Commodities:</h1> 
                    </div>
                    <div className="col-md-3">
                        <Box sx={{ minWidth: 120 }}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Level 1</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={level1}
                                    label="Level1"
                                    onChange={handleChange1}
                                >
                                    <MenuItem value="milk" >Milk</MenuItem>
                                    <MenuItem value="eggs">Eggs</MenuItem>

                                </Select>
                            </FormControl>
                        </Box>
                    </div>
                    <div className="col-md-3">
                        <Box sx={{ minWidth: 120 }}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Level 2</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={level2}
                                    label="Level2"
                                    onChange={handleChange2}
                                >
                                     <MenuItem value="milk" >Milk</MenuItem>
                                    <MenuItem value="eggs">Eggs</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    </div>
                    <div className="col-md-3">
                        <Box sx={{ minWidth: 120 }}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Level 3</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={level3}
                                    label="Level3"
                                    onChange={handleChange3}
                                >
                                    <MenuItem value="milkproducts">Milk Products</MenuItem>
                                    {/* <MenuItem value={20}>Twenty</MenuItem>
                                        <MenuItem value={30}>Thirty</MenuItem> */}
                                </Select>
                            </FormControl>
                        </Box>
                    </div>
                </div>
                </div>
              
            </div>
        </>
    )
}

export default SearchStandards
