import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ViewButton from '../components/pages/ViewButton';
import EditButton from '../components/pages/EditButton';
import { displayNotification, sendAPIRequest } from 'helpers';
import dayjs from 'dayjs';


const CodeTabs = () => {

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const authData = useSelector(s => s.auth.data);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const loadDraftData = async () => {
    setIsLoading(true);
    try {
        const ret = await sendAPIRequest(`/api/draft-chapter/user-drafts/?codes=true`);
        const res = await ret.json();
        if (res.success && res.status === 200) {
            setData(res.data);
        }
    } catch (err) {
      console.log({ err });
      displayNotification('error', 'Failed to load draft data [F]!');
    }
    setIsLoading(false);
  }

  useEffect(() => {
    loadDraftData();
  }, [])

  const formatDate = (dateString = '') => {
    // Parse the date string and format it
    return dayjs(dateString).format('DD MMM-YY hh:mm A');
  };


  return (
    <>
      <div className='p-5'>
        <div className="card-title2 lh-1 border-bottom border-dark">{t("64", "Codes")}</div>
        <div className="scrollable-content pe-2" style={{ height: '100vh', overflow: 'auto' }}>
          <div>
            {
              data.length > 0 && data.map((d, o) => (
                <>
                  <div className="d-flex w-100" key={o}>
                    <div className='d-flex justify-content-between'>
                      <h6 className="pt-7">{t("61", "Section")} {d.section_no} | {t("43", "Chapter")} {d.chapter_no} </h6>
                    </div>

                    <div className="header-right-items ms-auto d-flex align-items-center ">
                      <div className="ms-auto">
                        <div className='ms-2'>
                          {
                            [1, 2].includes(authData.role_id) ? (
                              <ViewButton />
                            ) : (
                              <EditButton status={d.document_transfers} />
                            )
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                  <h6 className="">{d.chapter_name}</h6>
                  <h6 className='d-flex justify-content-between py-2'>{t("-1", "Last Update")}: {formatDate(d.updatedAt)}</h6>
                  <hr />
                </>
              ))
            }
          </div>
        </div>
      </div>
    </>
  )
}

export default CodeTabs
