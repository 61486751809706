import React, { useEffect, useState } from 'react'
import CustomCard from 'components/CustomCard';
import CardHeader from 'components/common/CardHeader';
import CardBody from 'components/common/CardBody';
import { useTranslation } from 'react-i18next';
import TextFieldCustom from 'components/TextFieldCustom';
import AutoCompleteDropdown from 'components/autocomplete/AutoCompleteDropdown';
import { displayNotification, sendAPIRequest } from 'helpers';
import { Box, Divider, IconButton, Modal, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    height: 'auto',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    overflowY: 'auto',
    padding: 0,
};

const MoveChapterModal = ({ open = false, handleClose, moveChapterData, standardDetails }) => {
    const [areaVolName, setAreaVolName] = useState([]);
    const [getAreaSections, setgetAreaSections] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [areaVolNameValue, setAreaVolNameValue] = useState('');
    const [getAreaSectionsValue, setGetAreaSectionsValue] = useState('');
    const [chapterNumber, setChapterNumber] = useState();

    const getSections = async (d) => {
        try {
            const ret = await sendAPIRequest(`/api/draft-standard-structure/get-sections`, 'POST', {
                "animal_type": moveChapterData?.animal_type,
                "standard_type": moveChapterData?.standard_type,
                "lang_id": moveChapterData?.lang_id,
                "area_vol_no": d?.value,
                "version_year": standardDetails?.version_year
            });
            const res = await ret.json()
            if (res.success && res.status === 200) {
                const formattedData = res.data.map(item => ({
                    value: item.value,
                    label: `${item.value}. ${item.label}`
                }));
                setgetAreaSections(formattedData);
            } else {
                displayNotification("error", "Failed to get Area Volume names.");
            }
        } catch (e) {
            console.log({ e });
        }
    }

    const getAreaVolData = async () => {
        try {

            const ret = await sendAPIRequest(`/api/draft-standard-structure/get-area-volume/?animal_type=${moveChapterData?.animal_type}&standard_type=${moveChapterData?.standard_type}&lang_id=${moveChapterData?.lang_id}&version_year=${standardDetails?.version_year}`);
            const res = await ret.json()
            if (res.success && res.status === 200) {
                setAreaVolName(res.data);

            } else {
                displayNotification("error", "Failed to get Area Volume names.");
            }
        } catch (e) {
            console.log({ e });
        }
    }

    const saveUploadModal = async () => {
        if (!window.confirm('Are you sure you want to move this chapter?')) {
            return;
        }
        setIsLoading(true);
        try {
            const ret = await sendAPIRequest(`/api/draft-chapter/move-chapter`, 'POST', {
                "id": moveChapterData?.id,
                "area_vol_no": moveChapterData?.area_vol_no,
                "section_no": moveChapterData?.section_no,
                "lang_id": moveChapterData?.lang_id,
                "animal_type": moveChapterData?.animal_type,
                "standard_type": moveChapterData?.standard_type,
                "chapter_no": moveChapterData?.chapter_no,
                "target_area_vol_no": areaVolNameValue?.value,
                "target_area_vol_name": areaVolNameValue?.label,
                "target_section_no": getAreaSectionsValue?.value,
                "target_section_name": getAreaSectionsValue?.label,
                "targetchapter_no": parseInt(chapterNumber)
            });
            const res = await ret.json()
            if (res.success && res.status === 200) {
                setAreaVolName(res.data);
                handleClose();
                displayNotification("success", "Chapter Moved Successfully");
            } else {
                displayNotification('error', 'Error loading data [B]');
            }
        } catch (e) {
            console.log({ e });
            displayNotification('error', 'Error loading data [F]');
        }
        finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getAreaVolData();
    }, [])
    const { t } = useTranslation();
    return (
        <Modal
            open={open}
            onClose={() => handleClose(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Box sx={{ padding: '10px 20px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Move Chapter
                    </Typography>
                    <IconButton onClick={() => handleClose(false)}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Divider sx={{ backgroundColor: '#000', margin: '0' }} />
                <Box sx={{ paddingX: '20px', paddingBottom: '20px' }}>
                    <div className='row pt-2'>
                        <Typography variant="h7" sx={{ fontWeight: 'bold', marginBottom: '20px' }}>
                            Previous Structure:
                        </Typography>
                        <div className='col-4 '>
                            <TextFieldCustom
                                variant="outlined"
                                id="Chapter"
                                label={t("-1", "Area/Vol")}
                                fullWidth
                                rows={4}
                                disabled={true}
                                sx={{
                                    backgroundColor: "white",
                                    color: "white",
                                    border: "#ff4815",
                                    width: "99%",
                                }}
                                value={moveChapterData?.area_vol_name}
                                size='medium'
                            />
                        </div>
                        <div className='col-4'>
                            <TextFieldCustom
                                variant="outlined"
                                id="Chapter"
                                label={t("-1", "Section No")}
                                fullWidth
                                rows={4}
                                disabled={true}
                                sx={{
                                    backgroundColor: "white",
                                    color: "white",
                                    border: "#ff4815",
                                    width: "99%",
                                }}
                                value={moveChapterData?.section_no}
                                size='medium'
                            />
                        </div>
                        <div className='col-4'>
                            <TextFieldCustom
                                variant="outlined"
                                id="Chapter"
                                label={t("-1", "Chapter No")}
                                fullWidth
                                rows={4}
                                disabled={true}
                                sx={{
                                    backgroundColor: "white",
                                    color: "white",
                                    border: "#ff4815",
                                    width: "99%",
                                }}
                                value={moveChapterData?.chapter_no}
                                size='medium'
                            />

                        </div>
                    </div>
                    <div className='row pt-3'>
                        <Typography variant="h7" sx={{ fontWeight: 'bold', marginY: '10px' }}>
                            New Structure:
                        </Typography>
                        <div className='col-4 pe-1'>
                            <AutoCompleteDropdown
                                label='Area/Vol'
                                className='opacity-100'
                                isGroup={false}
                                showClearIcon={true}
                                error={false}
                                required={true}
                                multiple={false}
                                hasselectall={false}
                                options={areaVolName}
                                value={areaVolNameValue}
                                onChange={(event, value) => {
                                    if (value) {
                                        setAreaVolNameValue(value)
                                        getSections(value);
                                    }
                                    else {
                                        setAreaVolNameValue('')
                                    }
                                }}
                                size='medium'
                            />
                        </div>
                        <div className='col-4 pe-1'>
                            <AutoCompleteDropdown
                                label='Section No'
                                className='opacity-100'
                                isGroup={false}
                                showClearIcon={true}
                                error={false}
                                required={true}
                                multiple={false}
                                hasselectall={false}
                                options={getAreaSections}
                                value={getAreaSectionsValue}
                                onChange={(event, value) => {

                                    if (value) {
                                        setGetAreaSectionsValue(value);
                                    }
                                }}
                                size='medium'
                            />
                        </div>
                        <div className='col-4 pt-2'>
                            <TextFieldCustom
                                variant="outlined"
                                id="Chapter"
                                label={t("-1", "Chapter No")}
                                fullWidth
                                multiline={false}
                                rows={4}
                                required={true}
                                sx={{
                                    backgroundColor: "white",
                                    color: "white",
                                    border: "#ff4815",
                                    width: "99%",
                                }}
                                value={chapterNumber}
                                onChange={(e) => { setChapterNumber(e.target.value) }}
                            />

                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-12 text-center mt-3">
                            <button
                                className='btn btn--outline font-custom fs-6'
                                onClick={() => { handleClose() }}
                            >
                                Close
                            </button>
                            <button
                                className="btn btn--primary font-custom fs-6 ms-2"
                                onClick={saveUploadModal}
                                disabled={isLoading}
                            >
                                {isLoading ? "Saving..." : "Save"}
                            </button>
                        </div>
                    </div>
                </Box>
            </Box>
        </Modal>
    )
}

export default MoveChapterModal
