import { getBaseURL } from 'helpers';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import { useNavigate, useRouteLoaderData } from 'react-router-dom';


const SuccessModal = ({children, header, title, body, navigationLink, reloadData }) => {

    const { t } = useTranslation();
    const [showModel, setShowModel] = useState(false);
    const [modalOpened, setModalOpened] = useState(false);
    const navigate = useNavigate();

    const handleOpen = () => {
        if (!modalOpened) {
            setShowModel(true);
            setModalOpened(true);
        }
    }

    return (
        <>
            {children(handleOpen)}
            <Modal show={showModel} size='md' centered={true}>
                <Modal.Header 
                    className='p-4 bg-dark' 
                    onClick={()=>{setShowModel(false); setModalOpened(false); reloadData(false);}}
                >
                    <div className="mx-auto">
                        <h3 className='text-white'>{header}</h3>
                    </div>
                </Modal.Header>
                <Modal.Body  style={{
				backgroundImage: `url(${getBaseURL("/assets/icons/Reset-Password.png")})`,
				backgroundRepeat: 'no-repeat',
				backgroundSize: '70%',
				backgroundPosition: "19px"
			}} className='p-4 m-auto border-0'>
                    <div>
                        <div className='text-center'>
                            <h4 className="text-uppercase py-5">{title}</h4>
                            {
                                body.map((value, key) => (
                                    <div key={key}>{value}</div>
                                ))
                            }
                        </div>
                    </div>
                    <div className='d-flex justify-content-center py-5'>
                        <button
                            type='button'
                            className='btn btn-dark btn-bg-dark btn-sm'
                            onClick={() => {
                                setShowModel(false);
                                setModalOpened(false);
                                reloadData(false);
                                if (navigationLink) {
                                    navigate(navigationLink);
                                }
                            }}
                        >
                            {t("-1", "Continue")}
                        </button>
                    </div>
                </Modal.Body>
                {/* <Modal.Footer className='p-1'>
                </Modal.Footer> */}
            </Modal>
        </>
    );
}

SuccessModal.defaultProps = {
    children: null,
    header: '',
    title: '',
    body: [],
    navigationLink: '',
    reloadData: () => {}
};

export default SuccessModal;