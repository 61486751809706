import { displayNotification, sendAPIRequest } from 'helpers';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import TextFieldCustom from 'components/TextFieldCustom';
import { LIST_DETAIL_ELEMENTS } from 'constants';
import AutoCompleteDropdown from 'components/autocomplete/AutoCompleteDropdown';
import { Box, Divider, IconButton, Modal, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Spinner from 'components/spinner/Spinner';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    height: 'auto',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    overflowY: 'auto',
    padding: 0,
};
const AddSectionModal = ({ open = false, handleClose, standardDetails, componentLanguage, loadData }) => {

    const { t } = useTranslation();
    const [sectionName, setSectionName] = useState('');
    const [sectionNumber, setSectionNumber] = useState('');
    const [chapterName, setchapterName] = useState('');
    const [areaVolName, setAreaVolName] = useState([]);
    const [areaVolNameValue, setAreaVolNameValue] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const resetStates = () => {
        setSectionName('');
        setchapterName('');
        setAreaVolName([]);
        setAreaVolNameValue('');
        setSectionNumber('');
    }

    const saveSection = async () => {
        if (!window.confirm('Are you sure you want to add this section?')) {
            return;
        }
        if (sectionNumber.includes("-") || sectionNumber.includes("+")) {
            displayNotification('error', 'Invalid input for Section Number');
            return;
        }
        if (!sectionNumber || !chapterName?.trim() || !sectionName?.trim()) {
            displayNotification('error', 'Fill required fields');
            return;
        }
        setIsLoading(true);
        try {
            const ret = await sendAPIRequest(`/api/draft-chapter/add-addSection`, 'POST', {
                "standard_type": standardDetails?.standard_type,
                "animal_type": standardDetails?.animal_type,
                "section_name": sectionName.trim(),
                "chapter_name": chapterName.trim(),
                "section_no": sectionNumber,
                "version_year": standardDetails?.version_year,
                ...(standardDetails?.standard_type === LIST_DETAIL_ELEMENTS?.standard_type?.manuals ? {
                    "area_vol_no": areaVolNameValue?.value ?? '',
                    "area_vol_name": areaVolNameValue?.label ?? '',
                } : null)
            });
            const res = await ret.json();
            if (res.success && res.status === 200) {
                displayNotification("success", "Section Added Successfully");
                resetStates()
                handleClose(false);
                await loadData(standardDetails.standard_type, standardDetails.animal_type);
            } else {
                displayNotification('error', res.message);
            }
        } catch (error) {
            console.log({ error });
            displayNotification("error", "Internall Server Error [F]")
        }
        finally {
            setIsLoading(false);
        }
    }

    const getAreaVolData = async () => {
        try {
            const ret = await sendAPIRequest(`/api/draft-standard-structure/get-area-volume/?animal_type=${standardDetails?.animal_type}&standard_type=${standardDetails?.standard_type}&lang_id=${componentLanguage}&version_year=${standardDetails?.version_year}`);
            const res = await ret.json()
            if (res.success && res.status === 200) {
                setAreaVolName(res.data);
            } else {
                displayNotification("error", "Failed to get Area Volume names.");
            }
        } catch (e) {
            console.log({ e });
        }
    }


    useEffect(() => {
        if (standardDetails?.standard_type === LIST_DETAIL_ELEMENTS?.standard_type?.manuals && open) {
            getAreaVolData();
        }
    }, [open]);

    return (
        <Modal
            open={open}
            onClose={() => handleClose(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Box sx={{ padding: '10px 20px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Add Section
                    </Typography>
                    <IconButton onClick={() => {
                        handleClose(false)
                        resetStates()
                    }}
                        disabled={isLoading}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Divider sx={{ backgroundColor: '#000', margin: '0' }} />
                <Box sx={{ padding: '20px' }}>
                    <div className='row'>
                        {standardDetails?.standard_type === 6 ? (
                            <div className="col-6">
                                <AutoCompleteDropdown
                                    label='Area/Vol'
                                    placeholder={t("-1", "Enter Area/Vol")}
                                    className='opacity-100'
                                    isGroup={false}
                                    showClearIcon={true}
                                    error={false}
                                    required={true}
                                    multiple={false}
                                    hasselectall={false}
                                    options={areaVolName}
                                    value={areaVolNameValue}
                                    onChange={(event, value) => {
                                        if (value) {
                                            setAreaVolNameValue(value);
                                        } else {
                                            setAreaVolNameValue('');
                                        }
                                    }}
                                    size='medium'
                                />
                            </div>
                        ) : null}

                        <div className={standardDetails?.standard_type === 6 ? 'col-6 mt-2' : 'col-6'}>
                            <TextFieldCustom
                                variant="outlined"
                                id="document-title"
                                fullWidth
                                size="medium"
                                required={true}
                                label={t("-1", "Section Number")}
                                placeholder={t("-1", "Enter Section Number")}
                                type="number"
                                sx={{
                                    backgroundColor: "white",
                                    color: "white",
                                    border: "#ff4815",
                                    width: "98%",
                                }}
                                value={sectionNumber}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    if (value.length === 1 && value === '0') {
                                        return;
                                    }
                                    if (value === '' || /^[0-9]*$/.test(value)) {
                                        setSectionNumber(value);
                                    }
                                }}
                            />
                        </div>

                        <div className={standardDetails?.standard_type === 6 ? 'col-6 mt-2' : 'col-6'}>
                            <TextFieldCustom
                                variant="outlined"
                                id="document-title"
                                fullWidth
                                size="medium"
                                required={true}
                                label={t("-1", "Section Name")}
                                placeholder={t("-1", "Enter Section Name")}
                                sx={{
                                    backgroundColor: "white",
                                    color: "white",
                                    border: "#ff4815",
                                    width: "98%",
                                }}
                                value={sectionName}
                                onChange={(e) => setSectionName(e.target.value)}
                            />
                        </div>

                        <div className={standardDetails?.standard_type === 6 ? 'col-6 mt-2' : 'col-6 mt-2'}>
                            <TextFieldCustom
                                variant="outlined"
                                id="document-title"
                                fullWidth
                                size="medium"
                                required={true}
                                label={t("-1", "Chapter Name")}
                                placeholder={t("-1", "Enter Chapter Name")}
                                sx={{
                                    backgroundColor: "white",
                                    color: "white",
                                    border: "#ff4815",
                                    width: "98%",
                                }}
                                value={chapterName}
                                onChange={(e) => setchapterName(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className='row pt-10'>
                        <div className="col-12 text-center mt-3">
                            <button
                                className="btn btn--outline font-custom fs-6 me-2"
                                onClick={() => {
                                    handleClose(false)
                                    resetStates()
                                }}
                                disabled={isLoading}
                            >
                                {t("201", "Close")}
                            </button>
                            <button
                                className="btn btn--primary font-custom fs-6"
                                onClick={saveSection}
                                disabled={isLoading}
                            >
                                {isLoading ? <Spinner message="Saving..." /> : "Save"}
                            </button>
                        </div>
                    </div>
                </Box>
            </Box>
        </Modal>
    );
};



export default AddSectionModal;