
import React, { useEffect, useMemo, useState } from 'react'
import Checkbox from '@mui/material/Checkbox';
import { GridActionsCellItem } from '@mui/x-data-grid';

import { useTranslation } from 'react-i18next';
import AutoCompleteDropdown from 'components/autocomplete/AutoCompleteDropdown';
import { displayNotification, generateOptionsFromData, sendAPIRequest } from 'helpers';
import { Box, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import Loader from 'components/common/Loader';
import PreviewModal from 'components/modals/PreviewModal';
import CustomChip from 'components/CustomChip';
import { BsInfoCircleFill } from 'react-icons/bs';
import { useDispatch } from 'react-redux';
import { setCurrentPage } from 'redux/generalSlice';
import { useSearchParams } from 'react-router-dom';
import DataTable from 'pages/components/DataTable';
import HtmlRenderer from 'components/HtmlRenderer';
import GlossaryDefinationModal from 'components/modals/GlossaryDefinationModal';

const GlossaryImplementation = ({ animal_type, standard_type, chapter, componentLanguage }) => {
    const [location, setLocation] = useSearchParams();
    const { standard, animal, lang } = {
        standard: location.get('standard'),
        animal: location.get('animal'),
        lang: location.get('lang'),

    };
    const { t } = useTranslation();
    const [alertText, setAlertText] = useState(t("-1", `All prepublished chapters in Upload are shown in the chapter dropdown. Users can verify each chapter individually or use 'Load Next Chapter' to load the next one. When a new term is added and validated for pre-publication, it will appear below with all relevant data from the applicable standard.`));
    const [isAlertVisible, setIsAlertVisible] = useState(true);
    const dispatch = useDispatch();
    const [DropDownData, setDropDownData] = useState([]);
    const [openPreview, setopenPreview] = useState(false);
    const [openSeeDefinationModal, setOpenSeeDefinationModal] = useState(false);
    const [selectedValues, setSelectedValues] = useState({});
    const [data, setData] = useState({});
    const [alldata, setAllData] = useState([]);
    const [isSearch, setIsSearch] = useState(false);
    const [prevId, setprevId] = useState('');
    const [spinloader, setSpinLoder] = useState(false);
    const [term, setTerm] = useState('');
    const [glossaryPreviewData, setGlossaryPreviewData] = useState({ term: "", content: "" });
    const [glossaryDefinationData, setGlossaryDefinationData] = useState({ term: "", defination: "" });
    const [selectedRows, setSelectedRows] = useState(new Set());
    const [chapterData, setChapterData] = useState([])
    const [selectedChapter, setSelectedChapter] = useState({
        value: '',
        label: ''
    })
    const [alertMessage, setAertMessage] = useState('');
    const [currentIndex, setCurrentIndex] = useState(0);
    // Pagination states  
    const [currentPage, setCurrentTablePage] = useState(1);
    const rowsPerPage = 15;
    const totalRows = Object.keys(data).reduce((total, key) => total + data[key].length, 0);
    const startRow = (currentPage - 1) * rowsPerPage + 1;
    const endRow = Math.min(startRow + rowsPerPage - 1, totalRows);
    const [filters, setFilters] = useState({
        chapter_selection: []
    });
    const [canPublish, setCanPublish] = useState(true);

    useEffect(() => {
        dispatch(setCurrentPage("publishing-standards"));
    }, []);

    const handleSelectAll = (e) => {
        const newSelectedRows = new Set();
        if (e.target.checked) {

            Object.keys(data).forEach((key) => {
                data[key].forEach((val) => {
                    newSelectedRows.add(val.id);
                });
            });
        }
        setSelectedRows(newSelectedRows);
    };

    const showAlert = (text) => {
        setAlertText(text);
        setIsAlertVisible(prev => !prev);
    };

    const handleSelectRow = (id) => {
        setSelectedRows((prevSelectedRows) => {
            const newSelectedRows = new Set(prevSelectedRows);
            if (newSelectedRows.has(id)) {
                newSelectedRows.delete(id);
            } else {
                newSelectedRows.add(id);
            }
            return newSelectedRows;
        });
    };

    const isAllSelected = selectedRows.size === totalRows;




    const loadFilterData = async () => {
        try {
            const ret = await sendAPIRequest(`/api/temp-glossary-linking/get-glossaries`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                setDropDownData(res.data);
            }
        } catch (e) {
            console.log({ e });
            displayNotification('error', e.message);
        }
    }
    const applyTempGlossary = async () => {
        try {
            setAertMessage("Analysing the selected chapter data. Please wait...");
            setSpinLoder(true);
            const ret = await sendAPIRequest(`/api/temp-glossary-linking/apply-temp-glossary/?structures_id=${selectedChapter?.value ?? ""}&animal_type=${animal}&standard_type=${standard}&lang_id=${lang}`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                await loadData();
            }
        } catch (e) {
            console.log({ e });
            displayNotification('error', e.message);
        }
        setSpinLoder(false);

    }

    const loadChapterData = async () => {
        try {
            const ret = await sendAPIRequest(`/api/temp-glossary-linking/load-chapter-with-linking/?standard_type=${standard}&animal_type=${animal}&lang_id=${lang}`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                setChapterData(res.data);
            }
        } catch (e) {
            console.log({ e });
            displayNotification('error', e.message);
        }
    }

    const handleignoreGlossary = async (ids = []) => {
        if (!window.confirm('Are you sure you want to delete?')) {
            return;
        }
        try {
            const ret = await sendAPIRequest(`/api/temp-glossary-linking/save-glosary-list`, "POST", {
                idStatusArray: selectedValues
            });
            const res = await ret.json();
            if (res.success && res.status === 200) {
                loadData();
                displayNotification("success", "SuccessFully Deleted");
            } else {
                displayNotification("error", res.message);
            }
        } catch (error) {
            console.log(error);
            displayNotification('error', "Error ignoring glossary");
        }
    }
    const handlebulkIgnoreGlossary = async () => {
        if (!window.confirm('Are you sure you want to save?')) {
            return;
        }
        setSpinLoder(true);
        try {
            const ret = await sendAPIRequest(`/api/temp-glossary-linking/save-glosary-list`, "POST", {
                idStatusArray: selectedValues
            });
            const res = await ret.json();
            if (res.success && res.status === 200) {
                loadData();
                displayNotification("success", "SuccessFully saved");
            } else {
                displayNotification("error", res.message);
            }
        } catch (error) {
            console.log(error);
            displayNotification('error', "Error saving data");
        }
        setSpinLoder(false);
    }

    const handlePublishGlossary = async () => {
        if (!window.confirm('Are you sure you want to publish?')) {
            return;
        }
        try {
            setSpinLoder(true)
            const ret = await sendAPIRequest(`/api/handle-publication/glossary-final-publish/${selectedChapter.value ?? ""}`, "POST", {
                ids: selectedValues
            });
            const res = await ret.json();
            if (res.success && res.status === 200) {
                loadChapterData();
                setSpinLoder(false);
                loadData();
                displayNotification("success", "SuccessFully Published");
            } else {
                setSpinLoder(false);
                displayNotification("error", res.message);
            }
        } catch (error) {
            setSpinLoder(false);
            console.log(error);
            displayNotification('error', t("-1", "Error publishing glossary"));
        }
    }

    const handleCloseModal = () => {
        setopenPreview(false);
        setprevId('');
        setTerm('');
        setGlossaryPreviewData({ term: '', content: '' })
    }

    const handleCloseDefinationModal = () => {
        setOpenSeeDefinationModal(false);
        setGlossaryDefinationData({ term: '', defination: '' })
    }
    const openDefinationModal = (id = '', term, defination) => {
        setOpenSeeDefinationModal(true);
        setGlossaryDefinationData({ term: term, defination: defination });
    }
    const openPreviewModal = (id = '', term, content) => {
        setopenPreview(true);
        setprevId(id);
        setTerm(term);
        setGlossaryPreviewData({ term: term, content: content });
    }

    const loadData = async () => {
        // setSpinLoder(true);
        try {
            setAertMessage("Loading Data");
            const ret = await sendAPIRequest(`/api/temp-glossary-linking/?structures_id=${selectedChapter.value ?? ""}`);
            const res = await ret.json();

            if (res.success && res.status === 200) {
                // setData(res.data);
                const combinedArray = Object.values(res.data).flat();
                const findSome = combinedArray.some((x) => x.status === null);
                if (!findSome) {
                    const findSo = combinedArray.some((x) => !x.is_published);
                    if (findSo) {
                        setCanPublish(false);
                    } else {
                        setCanPublish(true);
                    }
                    // setCanPublish(false);
                } else { setCanPublish(true) }
                handleSetIds(combinedArray);
                setAllData(combinedArray);
            }
        } catch (e) {
            console.log({ e });
            displayNotification('error', e.message);
        }
        // setSpinLoder(false);
        setAlertText("");
    };

    const currentRows = Object.keys(data).reduce((acc, key) => {
        return acc.concat(data[key]);
    }, []).slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);

    const totalPages = Math.ceil(totalRows / rowsPerPage);

    const chapterOptions = useMemo(() => {
        return generateOptionsFromData(chapterData, {
            value: "id",
            label: "chapter_display_name",
        }
        );
    }, [chapterData]);


    const loadNextChapter = () => {
        if (chapterOptions.length !== currentIndex) {
            let tem = chapterOptions[currentIndex + 1];
            if (tem) {
                setCurrentIndex(currentIndex + 1);
                setSelectedChapter({
                    value: tem.value,
                    label: tem.label
                });
            }
        }
    }
    // const handleRadioChange = (rowId, value) => {
    //     setSelectedValues((prevState) => ({
    //         ...prevState,
    //         [rowId]: value
    //     }));
    // };


    useEffect(() => {
        loadFilterData();
        loadChapterData();
    }, []);

    useEffect(() => {
        if (selectedChapter.value) {
            // loadData();
            applyTempGlossary();
        }
    }, [selectedChapter.value]);

    useEffect(() => {
        if (chapterOptions.length > 0) {
            setCurrentIndex(0);
            setSelectedChapter({
                value: chapterOptions[currentIndex].value,
                label: chapterOptions[currentIndex].label
            });
        }
    }, [chapterOptions]);


    console.log({ selectedValues })


    const handleRadioChange = (rowId, value) => {
        setSelectedValues((prevState) => ({
            ...prevState,
            [rowId]: value
        }));
    };

    // const handleSetIds = (data=[]) => {
    //   const newData =  data?.map((x) => {
    //         return {
    //             [x.id]:x.status
    //         }
    //     })
    //     setSelectedValues(newData);
    // }


    const handleSetIds = (data = []) => {
        const newData = data?.reduce((acc, x) => {
            acc[x.id] = x.status;  // directly store true or false based on x.status
            return acc;
        }, {});
        setSelectedValues(newData);
    }

    console.log({ selectedValues });

    return (
        <>
            <div className=" my-2 container">
                <div className="header card-woah border border-secondary d-flex px-4 py-2 shadow-sm w-100 my-2">
                    <div className="d-flex justify-content-between w-100">
                        <div className="card-title1 lh-1">
                            {t("-1", "Glossary Implementation")}
                        </div>
                    </div>
                </div>
                <div className="header card-woah border border-secondary px-4 py-2 shadow-sm w-100 my-2 ">
                    <div className="row mt-3">
                        <div className="d-flex align-items-center mb-6">
                            <BsInfoCircleFill
                                className="bi flex-shrink-0 me-4 ms-3 text-blue-light-2 cursor-pointer"
                                size={24}
                                onClick={() => showAlert(alertText)}
                            />
                            {isAlertVisible && (
                                <div className="alert my-0 py-0 me-5 rounded-0 text-dark d-flex align-items-center" role="alert">
                                    <div className="fs-7 text-justify me-0">
                                        {alertText}
                                    </div>
                                </div>
                            )}
                        </div>

                        <div className="col-md-5 mb-3">
                            <AutoCompleteDropdown
                                showClearIcon={true}
                                multiple={false}
                                required={false}
                                error={false}
                                label={t("-1", "Chapter")}
                                size='medium'
                                disabled={false}
                                options={chapterOptions}
                                onChange={(event, value) => {
                                    if (!value) {
                                        setSelectedChapter({
                                            value: "",
                                            label: ""
                                        });
                                        return;
                                    }
                                    setSelectedChapter({
                                        value: value.value,
                                        label: value.label
                                    });
                                    setCurrentIndex(chapterOptions.findIndex((element) => element.value === value.value));
                                    setIsSearch(true);
                                }}
                                value={
                                    selectedChapter.value ? selectedChapter : { value: "", label: "" }
                                }
                            />
                        </div>
                        <div className="col-md-4 mb-3">
                            <AutoCompleteDropdown
                                showClearIcon={true}
                                required={false}
                                error={false}
                                label={t("-1", "Filter Terms")}
                                multiple={true}
                                size='medium'
                                hasselectall={true}
                                disabled={false}
                                options={DropDownData}
                                onChange={(event, value) => {
                                    if (value.some((v) => v.value === "Select all")) {
                                        if (filters.chapter_selection.length === DropDownData.length) {

                                            setFilters({
                                                ...filters,
                                                chapter_selection: [],
                                            });
                                        } else {

                                            setFilters({
                                                ...filters,
                                                chapter_selection: DropDownData.map((r) => r.value),
                                            });
                                        }
                                    } else {

                                        setFilters({
                                            ...filters,
                                            chapter_selection: value.map((r) => r.value),
                                        });
                                    }
                                    setIsSearch(true);
                                }}
                                value={filters.chapter_selection}
                            />
                        </div>
                        <div className="col-md-3 mt-3 text-end">
                            <button
                                className="btn btn--primary font-custom fs-6 ms-1"
                                disabled={chapterOptions.length === currentIndex + 1}
                                onClick={loadNextChapter}
                            >
                                {t("-1", "Load Next Chapter")}
                            </button>
                        </div>
                    </div>

                </div>

                {
                    spinloader && (
                        <div className='d-flex justify-content-center'>
                            <Loader message={alertMessage ?? 'Loading Data, Please wait...'} />
                        </div>
                    )
                }
                {
                    !spinloader &&
                    <>
                        <div>
                            <DataTable
                                getRowId={(row) => row.id}
                                rows={alldata}
                                className="text-white bg-primary rounded-0"
                                columns={[
                                    {
                                        field: "glossary_term",
                                        headerName: t("-1", "Term"),
                                        headerClassName: "text-white bg-primary rounded-0",
                                        minWidth: 150,
                                        flex: 1,
                                    },
                                    {
                                        field: "author_permissions",
                                        headerName: t("-1", "Accept / Ignore"),
                                        headerClassName: "text-white bg-primary rounded-0",
                                        flex: 2,
                                        renderCell: (params) => {
                                            const isAccepted = selectedValues[params.row.id];
                                            console.log({ isAccepted })

                                            return (
                                                <div>
                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <div style={{ display: 'flex', alignItems: 'center', marginRight: '15px' }}>
                                                            <Radio
                                                                checked={selectedValues[params.row.id] === true}
                                                                onChange={() => handleRadioChange(params.row.id, true)}
                                                                color="primary"
                                                                inputProps={{ 'aria-label': 'Accept' }}
                                                                sx={{ paddingLeft: 0 }}
                                                            />
                                                            <span>Accept</span>
                                                        </div>
                                                        <div style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
                                                            <Radio
                                                                checked={selectedValues[params.row.id] === false}
                                                                onChange={() => handleRadioChange(params.row.id, false)}
                                                                color="primary"
                                                                inputProps={{ 'aria-label': 'Ignore' }}
                                                                sx={{ paddingLeft: 0 }}
                                                            />
                                                            <span>Ignore</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        },
                                    },
                                    {
                                        field: "content",
                                        headerName: t("-1", "Text"),
                                        headerClassName: "text-white bg-primary rounded-0",
                                        flex: 4,
                                        renderCell: (params) => (
                                            <HtmlRenderer
                                                key={params.row.id}
                                                htmlContent={params.row.content}
                                            />
                                        ),
                                    },
                                    {
                                        headerName: t("-1", "Status"),
                                        headerClassName: "text-white bg-primary rounded-0",
                                        flex: 1,
                                        renderCell: (params) => (
                                            // <HtmlRenderer
                                            //     key={params.row.status}
                                            //     htmlContent={params.row.status == null ? 'Empty' : params.row.status == true ? 'Accepted' : 'Ignored'}
                                            // />
                                            <CustomChip
                                                label={
                                                    params.row.status == null
                                                        ? 'Empty'
                                                        : params.row.status === true
                                                            ? 'Accepted'
                                                            : 'Ignored'
                                                }
                                                type={
                                                    params.row.status === true
                                                        ? 'green' // Set the color for accepted status
                                                        : params.row.status === false
                                                            ? 'orange' // Set the color for ignored status
                                                            : 'blue'   // Default color for empty status
                                                }
                                            // onClick={() => handleStatusClick(params.row)}
                                            />

                                        ),
                                    },
                                    {
                                        field: "actions",
                                        type: "actions",
                                        headerName: t("-1", "Actions"),
                                        headerClassName: "text-white bg-primary rounded-0",
                                        getActions: (params) => [
                                            <GridActionsCellItem
                                                label={t("-1", "See Definition")}
                                                key={0}
                                                showInMenu
                                                onClick={() => openDefinationModal(params.row.id, params.row.glossary_term, params.row.glossary_text)}
                                            />,
                                            <GridActionsCellItem
                                                label={t("-1", "Preview")}
                                                key={1}
                                                showInMenu
                                                onClick={() => openPreviewModal(params.row.id, params.row.glossary_term, params.row.content)}
                                            />,
                                        ],
                                        width: 90,
                                    },
                                ]}
                            />
                        </div>
                        <div className='mt-3'>
                            <div className="row">
                                <div className="col-md-12 d-flex justify-content-end">

                                    <div className="ms-auto">
                                        <button
                                            className="btn btn--primary font-custom fs-6 ms-1"
                                            // disabled={!canPublish}
                                            onClick={handlebulkIgnoreGlossary}
                                        >
                                            {t("-1", "Save")}
                                        </button>
                                        <button
                                            className="btn btn--outline font-custom fs-6 ms-1"
                                            disabled={canPublish}
                                            onClick={handlePublishGlossary}
                                        >
                                            {t("-1", "Apply")}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>
            <PreviewModal
                open={openPreview}
                handleClose={handleCloseModal}
                prevId={prevId}
                term={term}
                glossaryPreviewData={glossaryPreviewData}
            />
            <GlossaryDefinationModal
                open={openSeeDefinationModal}
                handleClose={handleCloseDefinationModal}
                glossaryDefinationData={glossaryDefinationData}
            />
        </>
    )
}

export default GlossaryImplementation
