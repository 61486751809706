import { Box, Divider, IconButton, Modal, Typography } from '@mui/material';
import Loader from 'components/common/Loader';
import HtmlRenderer from 'components/HtmlRenderer copy';
import { displayNotification, getLanguageAgainstIDs, getStandardAgainstIDs, sendAPIRequest } from 'helpers';
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import CloseIcon from '@mui/icons-material/Close';
import { LIST_DETAIL_ELEMENTS } from 'constants';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '40%',
    height: 'auto',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    overflowY: 'auto',
    padding: 0,
};

const GlossaryDefinationModal = ({ open, handleClose, glossaryDefinationData = { term: '', defination: '' } }) => {
    const { t } = useTranslation();
    return (
        <>
            <Modal
                open={open}
                onClose={() => handleClose(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box sx={{ padding: '10px 20px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Glossary Defination
                        </Typography>
                        {/* <span className='ms-2'>
                            {glossaryTerm}
                        </span> */}
                        <IconButton onClick={() => handleClose(false)}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Divider sx={{ backgroundColor: '#000', margin: '0' }} />
                    <Box sx={{ padding: '20px' }}>
                        <div className='row'>
                            <div className='col-2'>
                                <Typography variant="h7" sx={{ fontWeight: 'bold', marginBottom: '20px' }}>
                                    Term:
                                </Typography>
                            </div>
                            <div className='col-9'>
                                <Typography variant="h7" sx={{ marginBottom: '20px' }}>
                                    {glossaryDefinationData?.term}
                                </Typography>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className='col-2'>
                                <Typography variant="h7" sx={{ fontWeight: 'bold', marginBottom: '20px' }}>
                                    Definition:
                                </Typography>
                            </div>
                            <div className='col-9' >
                                {glossaryDefinationData?.defination}
                            </div>
                        </div>
                    </Box>
                    <Divider sx={{ backgroundColor: '#000', margin: '0' }} />
                    <div className='row '>
                        <div className="col-12 d-flex justify-content-end mt-3">
                            <button
                                type='button'
                                className='btn btn--primary mx-2'
                                onClick={() => {
                                    handleClose()
                                }}
                            >
                                Close
                            </button>
                        </div>



                    </div>
                </Box>
            </Modal>

        </>
    );
}

export default GlossaryDefinationModal;