import { sendAPIRequest } from '../../helpers'

export default async () => {

    try {
        const res = await sendAPIRequest('/api/style-manager')
        const ret = await res.json()
        if (ret.success && ret.status === 200) {
            return ret.data
        }
        return [];
    } catch (err) {
        console.log(err)
        return [];
    }


}