import React, { useState } from 'react'
import { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import logo from "images/WOAH_ANNIVERSARY_PRIMARY LOGO_ORANGE + GOLD_ENG.png";
import { useLocation, useSearchParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { Link } from 'react-router-dom';
import html2pdf from 'html2pdf.js';
import { Chip } from '@mui/material';
import { BsExclamationTriangleFill, BsInfoCircleFill } from 'react-icons/bs';
import Spinner from 'components/spinner/Spinner';
import { useTranslation } from 'react-i18next';
import HtmlRenderer from 'components/HtmlRenderer';
import useLocalStorage from 'hooks/useLocalStorage';
import { displayNotification, sendAPIRequest } from 'helpers';
import { LIST_DETAIL_ELEMENTS } from 'constants';
import { MANUAL_HEADINGS } from 'constants';
import { Divider } from '@mui/material';

let readyForPrint = false;


function ContentNavigationPrint(props) {


    const { t } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();
    const { fileName } = {
        fileName: searchParams.get('file_name'),
    };
    // const isDownload = searchParams.get('download') === 'true' ? true : false;
    const componentRef = React.useRef();
    // const [currentDateTime, setCurrentDateTime] = useState(new Date());
    const [documentID, setDocumentID] = useState('');
    const [lastUpdated, setLastUpdated] = useState('');
    const [data, setData] = useState([]);
    const location = useLocation();
    const [documentMetaData, setDocumentMetaData] = useState({
        section_name: '',
        chapter_name: '',
    });
    const { storedValue, removeItem } = useLocalStorage('metadata');
    const getQueryParam = (name) => {
        const params = new URLSearchParams(location.search);
        return params.get(name);
    };

    useEffect(() => {

        getChapterData();

    }, []);

    useEffect(() => {
        readyForPrint && data.length > 0 && handlePrint();
    }, [readyForPrint]);

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: fileName ? fileName : 'WOAH SONT',
        onAfterPrint: () => {
            removeItem(); 
            window.close();
        },
        onPrintError: () => {
            removeItem();
        }
    })

    const getChapterData = async () => {
        // setIsLoading({ ...isLoading, loading_data: true });
        try {
            // removeHighlighting();
            const RID = getQueryParam("RId");
            const langID = getQueryParam("language_id");
            const isManual = getQueryParam("is_manual");
            const ret = await sendAPIRequest(`/api/contents/navigation-chapter-preview/?is_rid=true&RID=${RID}&language_id=${langID}&is_manual=${isManual}`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                res.data && res.data.length > 0 && setDocumentID(res.data[0].document_id);
                setData(res.data);
                res.data.forEach(d => (
                    d.html_contents && d.html_contents.length > 0 && d.html_contents.forEach(c => {
                        if (c.text_type == LIST_DETAIL_ELEMENTS.styles_categories.code_editing_date_text) {
                            setLastUpdated(c.content);
                        }
                    })
                ))
                const section = res.data.filter(d => d.text_type === LIST_DETAIL_ELEMENTS.text_types.section_name)[0]?.ref_number;
                const chapter = res.data.filter(d => d.text_type === LIST_DETAIL_ELEMENTS.text_types.chapter_name)[0]?.ref_number;
                setDocumentMetaData({ ...documentMetaData, section_name: section, chapter_name: chapter });
                readyForPrint = true;
            }
        } catch (e) {
            console.log({ e });
            displayNotification('error', 'Failed to load chapter data [F]');
        }
        // setIsLoading({ ...isLoading, loading_data: false });
    }

    // console.log("data:", data);

    return (
        <>
            <div className='container'>
                <div
                    style={{
                        display: "flex",
                        position: "fixed",
                        zIndex: 99999,
                        background: "#fff",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                    }}
                >
                    <Spinner message="Loading..." className="m-auto" />
                </div>
                <div className=''>
                    <div ref={componentRef} className="p-4">
                        <div className="border-bottom border-dark navbar">
                            <div className="d-flex header-res">
                                <a
                                    className="navbar-brand"
                                    id="logo-brand"
                                    href="https://www.woah.org/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <img src={logo} alt="woah-logo" height="40px" />
                                </a>
                                <div
                                    style={{ height: "4rem" }}
                                    className="ms-4 title-res border border-right-0 border-top-0 border-bottom-0 border-dark ps-3"
                                >
                                    <Link className="navbar-brand" id="logo-brand-pvs" to="/">
                                        <h4 className="pe-4 pvs-title fw-bold py-4">
                                            WOAH International Standards
                                        </h4>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        {data && data.length > 0 ? (
                            data.map((d, index) => (
                                <div key={`key-${index}`}>
                                    {d.text_type === LIST_DETAIL_ELEMENTS.text_types.section_name && (
                                        <div key={`section-${d.id}`}>
                                            <div className="fs-5 text-black text-center pt-10 pb-5" style={{ letterSpacing: "2px" }} key={d.id}>
                                                {
                                                    storedValue?.area_vol_no
                                                        ? (
                                                            <span>
                                                                Section {storedValue.area_vol_no}.{storedValue.section_no}.
                                                            </span>
                                                        )
                                                        : (
                                                            <span>
                                                                Section {storedValue.section_no}.
                                                            </span>
                                                        )
                                                }

                                            </div>
                                            <h3 className="chapter-heading text-black text-center" style={{ letterSpacing: "2px" }} key={`name-${d.id}`}>
                                                <HtmlRenderer
                                                    tag={"p"}
                                                    htmlContent={d?.standard_content}
                                                />
                                            </h3>
                                            <hr className="dark-underline mb-0" />
                                            <hr className="dark-underline mt-1" />
                                        </div>
                                    )}
                                    {d.text_type === LIST_DETAIL_ELEMENTS.text_types.chapter_name && (
                                        <div key={`chapter-${d.id}`}>
                                            <div className="fs-4 fw-lighter text-black text-center pt-10 pb-7" style={{ letterSpacing: "2px" }} key={d.id}>
                                                {
                                                    storedValue?.area_vol_no
                                                        ? (
                                                            <span>
                                                                Chapter {storedValue.area_vol_no}.{storedValue.section_no}.{storedValue.chapter_no}.
                                                            </span>
                                                        )
                                                        : (
                                                            <span>
                                                                Chapter {storedValue.section_no}.{storedValue.chapter_no}.
                                                            </span>
                                                        )
                                                }

                                            </div>
                                            <h2 className="chapter-heading text-black text-center pb-5" style={{ letterSpacing: "2px" }} key={`name-${d.id}`}>
                                                <HtmlRenderer
                                                    tag={"p"}
                                                    className="document-chftre-intitule"
                                                    htmlContent={d?.standard_content}
                                                />
                                            </h2>
                                        </div>
                                    )}
                                    {d.text_type === LIST_DETAIL_ELEMENTS.text_types.article_heading && (
                                        <div key={`article-${d.id}`} id={`article-${d.id}`}>
                                            <HtmlRenderer
                                                tag={"div"}
                                                className="text-center py-4"
                                                style={{ fontSize: `${props.fontSize + 2}px` }}
                                                htmlContent={d?.standard_content}
                                            />
                                            {d.html_contents && d.html_contents.length > 0 ? (
                                                d.html_contents.map((child) => (
                                                    <div className="px-4 text-justified" key={`art-child-${child.id}`}>
                                                        <HtmlRenderer
                                                            tag={"div"}
                                                            style={{ fontSize: `${props.fontSize}px` }}
                                                            htmlContent={child.content}
                                                        />
                                                    </div>
                                                ))
                                            ) : (
                                                d.child && (
                                                    <div key={`general-${d.child.id}`} id={`${d.child.id}`}>
                                                        <HtmlRenderer
                                                            tag={"div"}
                                                            className={LIST_DETAIL_ELEMENTS.heading_types.general_heading === d.child.text_type ? "py-4 fw-bold" : "py-4"}
                                                            style={{ fontSize: `${props.fontSize + 2}px` }}
                                                            htmlContent={d.child?.standard_content}
                                                        />
                                                        {d.child.html_contents && d.child.html_contents.length > 0 &&
                                                            d.child.html_contents.map((child, i) => (
                                                                <div key={`gen-child-${child.id}`} className={`d-flex ${child.text_type === LIST_DETAIL_ELEMENTS.styles_categories.code_editing_date_text && 'justify-content-center pt-3'}`}>
                                                                    <div className={LIST_DETAIL_ELEMENTS.heading_types.general_heading === d.child.text_type ? "text-justified" : 'justify-content-end'}>
                                                                        <HtmlRenderer
                                                                            tag={LIST_DETAIL_ELEMENTS.heading_types.general_heading === d.child.text_type ? "div" : "p"}
                                                                            style={{ fontSize: `${props.fontSize}px` }}
                                                                            htmlContent={child.content}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            ))}
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    )}
                                    {
                                        (d.text_type === LIST_DETAIL_ELEMENTS.text_types.glossary_term || MANUAL_HEADINGS.includes(parseInt(d.text_type))
                                            || d.text_type === LIST_DETAIL_ELEMENTS.text_types.manual_paragraph
                                            || d.text_type === LIST_DETAIL_ELEMENTS.styles_categories.code_editing_date_text
                                            || d.text_type === LIST_DETAIL_ELEMENTS.text_types.paragraph

                                        ) && (
                                            <Box
                                                key={`article-${d.id}`}
                                                id={`article-${d.id}`}
                                                data-myattribute={d.ref_number}
                                            >
                                                <HtmlRenderer
                                                    tag={"div"}
                                                    className="text-center fs-4"
                                                    style={{ marginBottom: '20px', marginTop: '10px' }}
                                                    htmlContent={d?.standard_content}
                                                />
                                                {
                                                    d.html_contents &&
                                                        d.html_contents.length > 0 ? (
                                                        d.html_contents.map((child) => (
                                                            <Box
                                                                sx={{
                                                                    paddingX: '4px',
                                                                    textAlign: 'justify',
                                                                }}
                                                                key={`art-child-${child.id}`}
                                                            >
                                                                <HtmlRenderer
                                                                    tag={"div"}
                                                                    style={{ fontSize: `${props.fontSize}px` }}
                                                                    htmlContent={child.content}
                                                                />
                                                            </Box>
                                                        ))
                                                    ) : (
                                                        d.child && Object.keys(d.child).length > 0 && (
                                                            <Box
                                                                key={`general-${d.child.id}`}
                                                                id={`${d.child.id}`}
                                                            >
                                                                <HtmlRenderer
                                                                    tag={"div"}
                                                                    className={LIST_DETAIL_ELEMENTS.heading_types.general_heading === d.child.text_type}
                                                                    style={{ fontSize: `${props.fontSize + 2}px` }}
                                                                    htmlContent={d.child?.standard_content}
                                                                />
                                                                {d.child.html_contents &&
                                                                    d.child.html_contents.length > 0 &&
                                                                    d.child.html_contents.map((child, i) => (
                                                                        <>
                                                                            {child.text_type === LIST_DETAIL_ELEMENTS.styles_categories.code_editing_date_text && (
                                                                                <Divider />
                                                                            )}
                                                                            <Box
                                                                                key={`gen-child-${child.id}`}
                                                                                sx={{
                                                                                    display: 'flex',
                                                                                    justifyContent: child.text_type === LIST_DETAIL_ELEMENTS.styles_categories.code_editing_date_text ? 'flex-end' : 'normal',
                                                                                }}
                                                                            >
                                                                                <Box className={LIST_DETAIL_ELEMENTS.heading_types.general_heading === d.child.text_type ? "text-justified" : 'justify-content-end'}>
                                                                                    <HtmlRenderer
                                                                                        tag={LIST_DETAIL_ELEMENTS.heading_types.general_heading === d.child.text_type ? "div" : "p"}
                                                                                        style={{
                                                                                            fontSize: `${props.fontSize}px`,
                                                                                        }}
                                                                                        htmlContent={child.content}
                                                                                    />
                                                                                </Box>
                                                                            </Box>
                                                                        </>
                                                                    ))}
                                                            </Box>
                                                        )
                                                    )
                                                }
                                            </Box>
                                        )
                                    }
                                    {
                                        (d.text_type == LIST_DETAIL_ELEMENTS.text_types.manual_footer) && (
                                            <Box
                                                key={`article-${d.id}`}
                                                id={`article-${d.id}`}
                                                data-myattribute={d.ref_number}
                                            >
                                                <HtmlRenderer
                                                    tag={"div"}
                                                    className=""
                                                    style={{ fontSize: `${props.fontSize + 2}px`, marginBottom: '20px', marginTop: '10px' }}
                                                    htmlContent={d?.standard_content}
                                                />
                                                {
                                                    d.html_contents &&
                                                        d.html_contents.length > 0 ? (
                                                        d.html_contents.map((child) => (
                                                            <Box
                                                                sx={{
                                                                    paddingX: '4px',
                                                                    textAlign: 'justify',
                                                                }}
                                                                key={`art-child-${child.id}`}
                                                            >
                                                                <HtmlRenderer
                                                                    tag={"div"}
                                                                    style={{ fontSize: `${props.fontSize}px` }}
                                                                    htmlContent={child.content}
                                                                />
                                                            </Box>
                                                        ))
                                                    ) : (
                                                        d.child && Object.keys(d.child).length > 0 && (
                                                            <Box
                                                                key={`general-${d.child.id}`}
                                                                id={`${d.child.id}`}
                                                            >
                                                                <HtmlRenderer
                                                                    tag={"div"}
                                                                    className={LIST_DETAIL_ELEMENTS.heading_types.general_heading === d.child.text_type}
                                                                    style={{ fontSize: `${props.fontSize + 2}px` }}
                                                                    htmlContent={d.child?.standard_content}
                                                                />
                                                                {d.child.html_contents &&
                                                                    d.child.html_contents.length > 0 &&
                                                                    d.child.html_contents.map((child, i) => (
                                                                        <>
                                                                            {child.text_type === LIST_DETAIL_ELEMENTS.styles_categories.code_editing_date_text && (
                                                                                <Divider />
                                                                            )}
                                                                            <Box
                                                                                key={`gen-child-${child.id}`}
                                                                                sx={{
                                                                                    display: 'flex',
                                                                                    justifyContent: child.text_type === LIST_DETAIL_ELEMENTS.styles_categories.code_editing_date_text ? 'flex-end' : 'normal',
                                                                                }}
                                                                            >
                                                                                <Box className={LIST_DETAIL_ELEMENTS.heading_types.general_heading === d.child.text_type ? "text-justified" : 'justify-content-end'}>
                                                                                    <HtmlRenderer
                                                                                        tag={LIST_DETAIL_ELEMENTS.heading_types.general_heading === d.child.text_type ? "div" : "p"}
                                                                                        style={{
                                                                                            fontSize: `${props.fontSize}px`,
                                                                                        }}
                                                                                        htmlContent={child.content}
                                                                                    />
                                                                                </Box>
                                                                            </Box>
                                                                        </>
                                                                    ))}
                                                            </Box>
                                                        )
                                                    )
                                                }
                                            </Box>
                                        )
                                    }
                                </div>
                            ))
                        ) : (
                            <div className="text-center text-primary">
                                <Spinner />
                            </div>
                        )}

                    </div>
                </div>

            </div>
        </>
    )
}

export default ContentNavigationPrint
