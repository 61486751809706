import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid";
import DataTable from "pages/components/DataTable";
import TextFieldCustom from "components/TextFieldCustom";
import Button from "components/common/Button";
import CardBody from "components/common/CardBody";
import CardHeader from "components/common/CardHeader";
import Column from "components/common/Column";
import Container from "components/common/Container";
import CustomCard from "components/common/CustomCard";
import Row from "components/common/Row";
import { displayNotification, sendAPIRequest } from "helpers";
import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

const ItemForm = () => {

    const navigate = useNavigate();
    const { id } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const filterName = searchParams.get('filter_name') ?? '';
    const [masterID, setMasterID] = useState(searchParams.get('master_id'));
    const [data, setData] = useState();

    const initForm = {
        list_element_name: '',
        list_element_name_fr: '',
        list_element_name_es: '',
        show_in_filter: true,
        is_terrestrial: true,
        is_aquatic: true,
        is_code: true,
        is_manual: true
    };
    const [form, setForm] = useState(initForm);
    const [isSaving, setIsSaving] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (id) {
            getData();
        }
    }, [id]);
    useEffect(() => {
        loadData();
    }, []);

    const getData = async () => {
        setIsLoading(true);
        try {
            const ret = await sendAPIRequest(`/api/lists/${id}`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                setForm({
                    list_element_name: res.data.list_element_name,
                    list_element_name_fr: res.data.list_element_name_fr,
                    list_element_name_es: res.data.list_element_name_es,
                    show_in_filter: res.data.show_in_filter,
                    is_terrestrial: res.data.is_terrestrial,
                    is_aquatic: res.data.is_aquatic,
                    is_code: res.data.is_code,
                    is_manual: res.data.is_manual,
                });
                setMasterID(res.data.list_master_id);
            }
        } catch (err) {
            console.log(err);
        }
        setIsLoading(false);
    }

    const loadData = async () => {
        setIsLoading(true);
        try {
            const ret = await sendAPIRequest(`/api/lists/master/${masterID}`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                setData(res.data);
            }
        } catch (err) {
            console.log(err);
        }
        setIsLoading(false);
    }

    const handleDelete = async (id) => {
        try {
            const ret = await sendAPIRequest(`/api/lists/${id}`, "DELETE");
            const res = await ret.json();
            if (res.success && res.status === 200) {
                displayNotification('success', 'Successfully deleted [B]');
                await loadData();
            } else {
                displayNotification('error', 'Fail to delete [B]');
            }
        } catch (err) {
            console.log(err);
            displayNotification('error', 'Fail to delete [F]');
        }
    }

    const handleSave = async () => {
        setIsSaving(true);
        let success_msg = 'Successfully created new element';
        let fail_msg = 'Failed to create new element';
        try {
            if (!masterID) {
                displayNotification("error", "Master list is not available [F]");
                setIsSaving(false);
                return;
            }
            if (!form.list_element_name || !form.list_element_name_es || !form.list_element_name_fr) {
                displayNotification('error', 'All text fields are required [F]');
                setIsSaving(false);
                return;
            }
            if (!window.confirm('Are you sure you want to save?')) {
                setIsSaving(false);
                return;
            }
            let ret;

            if (id) {
                ret = await sendAPIRequest(
                    `/api/lists/${id}`,
                    'PUT',
                    form
                );
                success_msg = 'Successfully updated element';
                fail_msg = 'Failed to update element';
            } else {
                ret = await sendAPIRequest(
                    `/api/lists/${masterID}`,
                    'POST',
                    form
                );
                setForm({...initForm})
                success_msg = 'Successfully created new element';
                fail_msg = 'Failed to create new element';
            }
            const res = await ret.json();
            if (res.success && res.status === 200) {
                loadData();
                displayNotification('success', `${success_msg} [B]`);
            } else {
                displayNotification('error', `${fail_msg} [B]`);
            }
        } catch (err) {
            console.log(err);
            displayNotification('error', `${fail_msg} [F]`);
        }
        setIsSaving(false);
    }

    return (
        <>
            <Container>
                <CustomCard className={'d-flex'}>
                    <CardHeader>
                        Insert Item In Filter - {filterName}
                    </CardHeader>
                    <CardBody>
                        <Button
                            onClick={() => navigate('/portal/dynamic-filters')}
                        >
                            Back
                        </Button>
                    </CardBody>
                </CustomCard>
                <CustomCard className={'my-2'}>
                    <CardBody>
                        <Row className={'w-100'}>
                            <Column col={4}>
                                <TextFieldCustom
                                    variant="outlined"
                                    label='Item Name (EN)'
                                    id="document-title"
                                    fullWidth
                                    required
                                    size="small"
                                    sx={{
                                        backgroundColor: "white",
                                        color: "white",
                                        border: "#ff4815",
                                        width: "100%",
                                    }}
                                    value={form.list_element_name}
                                    onChange={(e) => setForm({ ...form, list_element_name: e.target.value })}
                                />
                            </Column>
                            <Column col={4}>
                                <TextFieldCustom
                                    variant="outlined"
                                    label='Item Name (FR)'
                                    id="document-title"
                                    fullWidth
                                    required
                                    size="small"
                                    sx={{
                                        backgroundColor: "white",
                                        color: "white",
                                        border: "#ff4815",
                                        width: "100%",
                                    }}
                                    value={form.list_element_name_fr}
                                    onChange={(e) => setForm({ ...form, list_element_name_fr: e.target.value })}
                                />
                            </Column>
                            <Column col={4}>
                                <TextFieldCustom
                                    variant="outlined"
                                    label='Item Name (ES)'
                                    id="document-title"
                                    fullWidth
                                    required
                                    size="small"
                                    sx={{
                                        backgroundColor: "white",
                                        color: "white",
                                        border: "#ff4815",
                                        width: "100%",
                                    }}
                                    value={form.list_element_name_es}
                                    onChange={(e) => setForm({ ...form, list_element_name_es: e.target.value })}
                                />
                            </Column>
                            <Column col={12} >
                                <FormGroup className="d-block">
                                    <FormControlLabel control={
                                        <Checkbox
                                            defaultChecked
                                            size="small"
                                            checked={form.show_in_filter}
                                            onChange={(e) => setForm({ ...form, show_in_filter: e.target.checked })}
                                        />
                                    } label="Enable" />
                                    <FormControlLabel control={
                                        <Checkbox
                                            defaultChecked
                                            size="small"
                                            checked={form.is_terrestrial}
                                            onChange={(e) => setForm({ ...form, is_terrestrial: e.target.checked })}
                                        />
                                    } label="Terrestrial" />
                                    <FormControlLabel control={
                                        <Checkbox
                                            defaultChecked
                                            size="small"
                                            checked={form.is_aquatic}
                                            onChange={(e) => setForm({ ...form, is_aquatic: e.target.checked })}
                                        />
                                    } label="Aquatic" />
                                    <FormControlLabel control={
                                        <Checkbox
                                            defaultChecked
                                            size="small"
                                            checked={form.is_code}
                                            onChange={(e) => setForm({ ...form, is_code: e.target.checked })}
                                        />
                                    } label="Code" />
                                    <FormControlLabel control={
                                        <Checkbox
                                            defaultChecked
                                            size="small"
                                            checked={form.is_manual}
                                            onChange={(e) => setForm({ ...form, is_manual: e.target.checked })}
                                        />
                                    } label="Manual" />
                                </FormGroup>
                            </Column>
                        </Row>
                        <Row className={'w-100 my-2'}>
                            <Column className={'d-flex justify-content-end'}>
                                <Button
                                    onClick={() => handleSave()}
                                    disabled={isSaving}
                                >
                                    {
                                        isSaving
                                            ? 'Saving...'
                                            : 'Save'
                                    }
                                </Button>
                            </Column>
                        </Row>
                    </CardBody>
                </CustomCard>
                <CustomCard className={'my-2'}>
                    <DataTable
                        getRowId={(row) => row.id}
                        isLoading={isLoading}
                        rows={data}
                        className="text-white bg-primary rounded-0"
                        columns={
                            [
                                {
                                    field: "list_element_name",
                                    headerName: "Item Name (EN)",
                                    headerClassName: "text-white bg-primary rounded-0",
                                    flex: 1,
                                },
                                {
                                    field: "list_element_name_fr",
                                    headerName: "Item Name (FR)",
                                    headerClassName: "text-white bg-primary rounded-0",
                                    flex: 1,
                                },
                                {
                                    field: "list_element_name_es",
                                    headerName: "Item Name (ES)",
                                    headerClassName: "text-white bg-primary rounded-0",
                                    flex: 1,
                                },
                                {
                                    field: "is_terrestrial",
                                    headerName: "Terrestrial",
                                    headerClassName: "text-white bg-primary rounded-0",
                                    flex: 1,
                                    valueGetter: (params) => {
                                        return params.row?.is_terrestrial ? 'Yes' : 'No';
                                    },
                                },
                                {
                                    field: "is_aquatic",
                                    headerName: "Aquatic",
                                    headerClassName: "text-white bg-primary rounded-0",
                                    flex: 1,
                                    valueGetter: (params) => {
                                        return params.row?.is_aquatic ? 'Yes' : 'No';
                                    },
                                },
                                {
                                    field: "is_code",
                                    headerName: "Is Code",
                                    headerClassName: "text-white bg-primary rounded-0",
                                    flex: 1,
                                    valueGetter: (params) => {
                                        return params.row?.is_code ? 'Yes' : 'No';
                                    },
                                },
                                {
                                    field: "is_manual",
                                    headerName: "Manual",
                                    headerClassName: "text-white bg-primary rounded-0",
                                    flex: 1,
                                    valueGetter: (params) => {
                                        return params.row?.is_manual ? 'Yes' : 'No';
                                    },
                                },
                                {
                                    field: "show_in_filter",
                                    headerName: "Show In Filter",
                                    headerClassName: "text-white bg-primary rounded-0",
                                    flex: 1,
                                    valueGetter: (params) => {
                                        return params.row?.show_in_filter ? 'Yes' : 'No';
                                    },
                                },
                                {
                                    field: "actions",
                                    type: "actions",
                                    headerClassName: "text-white bg-primary rounded-0",
                                    headerName: "Actions",
                                    getActions: (params) => [
                                        <GridActionsCellItem
                                            label="Edit"
                                            key={1}
                                            showInMenu
                                            onClick={() => navigate(`/portal/dynamic-filters/item/create/${params.row.id}/?filter_name=${filterName}`)}
                                        />,
                                        <GridActionsCellItem
                                            label={
                                                "Delete"
                                            }
                                            key={0}
                                            showInMenu
                                            onClick={() => {
                                                handleDelete(params.row.id);
                                            }
                                            }
                                        />,
                                    ]
                                }
                            ]
                        }
                    />
                </CustomCard>
            </Container>
        </>
    );
}

ItemForm.defaultProps = {};

export default ItemForm;