import CustomChip from 'components/CustomChip';
import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Tooltip } from '@mui/material';
import { displayNotification, getBaseURL, getLanguageAgainstIDs, getStandardAgainstIDs, sendAPIRequest, } from 'helpers';
import UploadChapter from 'components/modals/UploadChapterModal';
import DataTable from 'pages/components/DataTable';
import { GridActionsCellItem } from '@mui/x-data-grid';
import { useLocation, useNavigate } from 'react-router-dom';
import { LIST_DETAIL_ELEMENTS } from 'constants';
import DocumentTransferModal from 'components/modals/DocumentTransferModal';
import { TRANSFER_STATUS } from 'constants';
import AddChapterModalNew from 'components/modals/AddChapterModalNew';
import MoveChapterModal from 'components/modals/MoveChapterModal';
import { USER_PERMISSIONS } from 'constants';


const SectionModifyExistingComponent = ({ data = {}, loadData = async () => { }, standardDetails = {}, standard_type, area_vol_no = '' }) => {
    const location = useLocation();
    const { t } = useTranslation();
    const [openDropdownId, setOpenDropdownId] = useState(null);
    const dropdownRef = useRef(null);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [openUploadModal, setOpenUploadModal] = useState(false);
    const [moveChapterModal, setMoveChapterModal] = useState(false);
    const [metaData, setMetaData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [transferDocumentDetails, setTraferDocumentDetails] = useState({});
    const closeDropdown = () => {
        setOpenDropdownId(null);
        setDropdownOpen(false);
    };
    const navigate = useNavigate();
    const [transferModal, setTransferModal] = useState(false);
    const [AddChapterModal, setAddChapterModal] = useState(false);
    const [AddChapterData, setAddChapterData] = useState([]);
    const [editChapterModal, setEditChapterModal] = useState(false);
    const [formData, setFormData] = useState({ chapter_name: '', chapter_no: '' });
    const [EditChapterId, setEditChapterId] = useState('');
    const [moveChapterData, setMoveChapterData] = useState([]);

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setDropdownOpen(false);
            setOpenDropdownId(null);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    const previewChapter = (data = {}) => {
        const url = getBaseURL(`/portal/draft-chapter-preview/?chapter_id=${data.structures_id}&is_draft=true`);
        window.open(url, "_blank");
        closeDropdown()
    };

    const handleCloseModal = () => {
        setOpenUploadModal(false);
    }

    const handleCloseMoveChap = async () => {
        setMoveChapterModal(false);
        setMoveChapterData([]);
        await loadData(standardDetails?.standard_type ?? 5, standardDetails?.animal_type ?? 7);
    }

    const handleSave = async (formData) => {
        try {
            //  Remember Need to update backend call staructure. and also Reload chapter on it successfull hit. 
            const ret = await sendAPIRequest(`/api/draft-chapter/add-new-Chapter`, 'POST', {
                "area_vol_no": AddChapterData?.area_vol_no,
                "section_no": AddChapterData?.section_no,
                "lang_id": AddChapterData?.lang_id,
                "animal_type": AddChapterData?.animal_type,
                "standard_type": AddChapterData?.standard_type,
                "chapter_no": formData?.chapter_no,
                "area_vol_name": AddChapterData?.area_vol_name,
                "section_name": AddChapterData?.area_vol_name,
                "chapter_name": formData?.chapter_name
            });
            const res = await ret.json()
            if (res.success && res.status === 200) {
                await loadData(standardDetails?.standard_type ?? 5, standardDetails?.animal_type ?? 7);
                setEditChapterModal(false);
                displayNotification("success", "Chapter Added Successfully");
            } else {
                displayNotification("error", "Failed to Add Chapter");
            }
        } catch (e) {
            console.log({ e });
        }
    }

    const handleClose = async () => {
        setAddChapterModal(false);
        setEditChapterModal(false);
        setFormData({ chapter_name: '', chapter_no: '' })
    }

    const editChapterCall = async () => {
        try {
            const ret = await sendAPIRequest(`/api/draft-chapter/rename-chapter-name`, 'POST', {
                "id": EditChapterId,
                "chapter_name": formData?.chapter_name
            });
            const res = await ret.json()
            if (res.success && res.status === 200) {
                await loadData(standardDetails?.standard_type ?? 5, standardDetails?.animal_type ?? 7);
                setEditChapterModal(false);
                displayNotification("success", "Chapter Name Edited Successfully");
                setEditChapterId('');
            } else {
                displayNotification("error", "Failed to Edit Chapter");
            }
        } catch (e) {
            console.log({ e });
        }
    }

    const hanldeEditChapter = async (params) => {
        setFormData({ chapter_name: params?.chapter_name, chapter_no: '' })

        setAddChapterModal(true);
        setEditChapterModal(true);

    }

    const handleCloseTransferModal = async (load_data = false) => {
        setTransferModal(false);
        setTraferDocumentDetails({});
        if (load_data) {
            await loadData(standardDetails?.standard_type ?? 5, standardDetails?.animal_type ?? 7);
        }
    }

    const handleMofifyEditChapter = (data = {}) => {
        const url = `/portal/modify-chapter?structure_id=${data.structures_id}&is_code=${data.standard_type === LIST_DETAIL_ELEMENTS.standard_type.codes ? true : false}`;
        window.open(url, '_blank'); // Opens the URL in a new tab
    };


    return (
        <div>
            <div className="accordion mt-4 border-0" id={"accordionExample" + data.id}>
                <div className="accordion-item rounded-0">
                    <h1 className="accordion-header accordion__item-label collapsed" id={`heading-${data.id}`}>
                        <button
                            className="accordion-button font-custom-4 fs-4 rounded-0 py-2 collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#collapse-${data.id}`}
                            aria-expanded="false"
                            aria-controls={`collapse-${data.id}`}
                        >
                            <span className="me-auto fs-4 fw-bold">
                                {/* Section: {data.section_no} */}
                                {
                                    standardDetails.standard_type === LIST_DETAIL_ELEMENTS.standard_type.codes
                                        ? `Section: ${data.section_no}`
                                        : `Section: ${area_vol_no}.${data.section_no}`
                                }

                            </span>

                        </button>
                    </h1>
                    <div
                        id={`collapse-${data.id}`}
                        className="accordion-collapse collapse"
                        aria-labelledby={`heading-${data.id}`}
                        data-bs-parent={`#accordionExample${data.id}`}
                    >
                        <DataTable
                            getRowId={(row) => row.structures_id}
                            isLoading={isLoading}
                            pagination={false}
                            rows={data.chapters}
                            className="text-white bg-primary rounded-0"
                            columns={[
                                {
                                    field: "display_name",
                                    headerName: t("-1", "Chapter"),
                                    headerClassName: "text-white bg-primary rounded-0",
                                    flex: 3,
                                },
                                {
                                    field: "User",
                                    headerName: t("-1", "User"),
                                    headerClassName: "text-white bg-primary rounded-0",
                                    flex: 1,
                                    // valueGetter: (params) => {
                                    //     return params?.row?.relationDocTransferDraftStructures.length > 0 ? handleReturnUser(params?.row?.relationDocTransferDraftStructures) : "";
                                    // },
                                },
                                {
                                    field: "status",
                                    headerName: t("-1", "Status"),
                                    headerClassName: "text-white bg-primary rounded-0",
                                    flex: 1,
                                    // renderCell: (params) => {
                                    //     return params?.row?.relationDocTransferDraftStructures.length ? getStatusChip(handleReturnStatus(params?.row?.relationDocTransferDraftStructures ?? [])) : "";
                                    // },
                                },
                                {
                                    field: "actions",
                                    type: "actions",
                                    headerClassName: "text-white bg-primary rounded-0",
                                    headerName: t("-1", "Actions"),
                                    getActions: (params) => {
                                        return [

                                            <GridActionsCellItem
                                                label="Modify Chapter"
                                                key={0}
                                                showInMenu
                                                // onClick={() => console.log("Modify/Edit Chapter:", params.row)}
                                                onClick={() => handleMofifyEditChapter(params.row)}
                                            // disabled={standardDetails?.structureStatus == 4 || standardDetails?.structureStatus == 5 ? true : false}
                                            />,



                                            // <GridActionsCellItem
                                            //     label="Preview"
                                            //     key={1}
                                            //     showInMenu
                                            //     onClick={() => { previewChapter(params.row) }}
                                            //     // onClick={() => console.log("Preview Chapter:", params.row)}
                                            // />,


                                        ];
                                    },
                                },
                            ]}
                        // disableColumnMenu={true}
                        />
                      
                    </div>

                </div>
            </div>
        </div>
    );
};

export default SectionModifyExistingComponent;
