import collect from "collect.js";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useSearchParams, useParams } from "react-router-dom";
import { LIST_DETAIL_ELEMENTS } from "constants";
import { displayNotification, sendAPIRequest } from "helpers";
import { setCurrentPage, setHeaderTheme } from "redux/generalSlice";
import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from "@mui/material";

import Spinner from "components/spinner/Spinner";
import HtmlRenderer from "components/HtmlRenderer copy";


const Glossary = () => {
  const { id } = useParams();
  const editRef = useRef();
  const splitRef = useRef();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [gID, setGID] = useState("");
  const [location, setLocation] = useSearchParams();
  const { chapter_id, term, document_id } = {
    chapter_id: location.get("chapter"),
    term: location.get("term"),
    document_id: location.get("document"),
  };
  const [currentGlossary, setCurrentGlossary] = useState(null)
  const [data, setData] = useState([]);
  const [dbStyleLoad, setDbStyleLoad] = useState(null);
  const [isLoading, setIsLoading] = useState({
    loading_data: false,
  });
  const [documentMetaData, setDocumentMetaData] = useState({
    section_name: "",
    chapter_name: "",
    chapter_no: "",
    section_no: "",
  });

  const { language_id, standard_type, animal_type } = {
    language_id: location.get("lang_id"),
    standard_type: location.get("standard_type"),
    animal_type: location.get("animal_type"),
    term: location.get("term"),
  };

  useEffect(() => {
    dispatch(setHeaderTheme("white"));
    dispatch(setCurrentPage("access-commodities"));
    if (document.getElementById(`${gID}`)) {
      const headingElement = document.getElementById(`${gID}`);
      if (headingElement) {
        const offsetTop = headingElement.offsetTop;
        window.scrollTo({
          top: offsetTop,
          behavior: "smooth",
        });
      }
    }
  }, [data]);

  useEffect(() => {
    laodGlossData2();
  }, []);

  useEffect(() => {
    if (currentGlossary) {
      laodGlossData();
    }

  }, [currentGlossary]);

  // asad
  const laodGlossData = async () => {
    try {
      // let id = document
      setIsLoading({ ...isLoading, loading_data: true });
      const ret = await sendAPIRequest(
        `/api/glossaries/all-terms?standard_type=${currentGlossary?.standard_type ?? ''}&animal_type=${currentGlossary?.animal_type ?? ''}&lang_id=${currentGlossary?.language_id ?? ''}`
      );
      const res = await ret.json();
      if (res.success && res.status === 200) {
        setData(res.data);
      }
    } catch (error) {
      console.log(error);
    }
    setIsLoading({ ...isLoading, loading_data: false });
  };

  const laodGlossData2 = async () => {
    try {
      // let id = document
      setIsLoading({ ...isLoading, loading_data: true });
      const ret = await sendAPIRequest(
        `/api/glossary-linking/get-glossary?id=${id}`
      );
      const res = await ret.json();
      if (res.success && res.status === 200) {
        setGID(res?.data?.g_id);
        setCurrentGlossary(res?.data);
      }
    } catch (error) {
      console.log(error);
    }
    setIsLoading({ ...isLoading, loading_data: false });
  };

  return (
    <div className="container"> <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      my={2}
      width="100%"
    >
      {isLoading.loading_data ? (
        <Spinner message={t("-1", "Loading Data, Please wait...")} />
      ) : (
        <Box px={2} pb={2}>
          <Box>
            <Box>
              <Typography className="glossary-heading">
                {t("-1", "Glossary")}
              </Typography>
              <Divider
                sx={{
                  width: "50%",
                  marginX: "auto",
                  marginTop: "1rem",
                }}
              />
              <Divider
                sx={{
                  width: "50%",
                  paddingTop: "5px",
                  marginBottom: "2rem",
                  marginX: "auto",
                }}
              />
            </Box>
            {documentMetaData.chapter_name && (
              <Box>
                <Typography
                  className="pb-2 pt-2 text-black text-center section-heading"
                  sx={{
                    letterSpacing: "2px",
                    textAlign: "center",
                    paddingTop: "15px",
                  }}
                >
                  {t("-1", "CHAPTER")} {documentMetaData.section_no}.
                  {documentMetaData.chapter_no}.
                </Typography>
                <Typography
                  className="chapter-heading"
                  variant="h3"
                  sx={{
                    letterSpacing: "2px",
                    textAlign: "center",
                  }}
                >
                  {documentMetaData.chapter_name}
                </Typography>
              </Box>
            )}
          </Box>
          {data && data.length > 0 ? (
            data.map((d, index) => (
              <Box key={index + d.id} id={d.g_id}>
                <div className="dictionnaire-intitule-terme">
                  <HtmlRenderer htmlContent={d.glossary_term} />
                </div>
                <Typography variant="body1" className="dictionnaire-definition-terme">
                  <HtmlRenderer htmlContent={d.glossary_text} />
                </Typography>
              </Box>
            ))
          ) : (
            <Grid xs={8}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  marginTop: "30px",
                }}
              >
                <Typography variant="body1" sx={{ marginLeft: "10px" }}>
                  {t("-1", "No Data Found")}
                </Typography>
              </Box>
            </Grid>
          )}
        </Box>
      )}
    </Box></div>
  );
};

Glossary.defaultProps = {};

export default Glossary;
