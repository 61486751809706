import React, { useEffect, useState } from 'react'
import FileUploading from './FileUpload';
import { setCurrentTab } from 'redux/tabsSlice';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { setCurrentPage, setHeaderTheme } from 'redux/generalSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import FileCrack from './FileCrack';
import UploadManuals from './UploadManuals';

const UploadDocuments = ({ documentID, standard }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    // const selectedTab = useSelector(s => s.tabs.tabName);
    
    const currentTab = standard === 'Manuals' ? 'upload-manuals' : 'upload-codes';

    const [tab, setTab] = useState(currentTab);

    useEffect(() => {
        dispatch(setHeaderTheme('white'));
        dispatch(setCurrentPage("data-migration"));
    }, []);

    return (
        <div className='my-2'>
            <div className="header card-woah border border-secondary d-flex px-4 py-2 shadow-sm w-100">
                <div className='d-flex justify-content-between'>
                    <div className="card-title1 lh-1">{t("88", "Upload Documents")}</div>
                </div>
                <div className="header-right-items ms-auto d-flex align-items-center">
                    <div className="ms-auto">
                        <div className="me-2">
                        <button
                                type="button"
                                className={tab === "upload-codes" ? "btn btn-sm btn--tab active me-2" : "btn btn--outline me-2"}
                                onClick={() => {
                                    setTab("upload-codes");
                                    dispatch(setCurrentTab("upload-codes"));
                                    navigate('.', { replace: true });
                                }}
                            >
                              
                                {t("157", "Upload Codes")}
                            </button>

                            <button
                                type="button"
                                className={tab === "upload-manuals" ? "btn btn-sm btn--tab" : "btn btn--outline"}
                                onClick={() => {
                                    setTab("upload-manuals");
                                    dispatch(setCurrentTab("upload-manuals"));
                                }}
                            >
                                
                                {t("158", "Upload Manuals")}
                            </button>
                        </div>

                    </div>
                </div>
            </div>

            {
                tab === "upload-codes" && <FileUploading documentID={parseInt(documentID)} />
            }
            {
                tab === "upload-manuals" && <UploadManuals />
            }
        </div>
    )
}

export default UploadDocuments;