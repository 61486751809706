window.onload = function() {
    // Your JavaScript code to run after the page loads
    console.log("The page has fully loaded!");

    const targetNode = document.getElementsByClassName('ck-editor__editable')[0]

    if(targetNode)
    {

    targetNode.addEventListener("DOMSubtreeModified", (event)=>{

        const images = targetNode.querySelectorAll('img');

        for (const image of images) {

          if (!image.hasAttribute('src')) {
          
            image.setAttribute('src', window.ckEditorImageUrl)

          }

        }

        });

    }
      

  };

