import { Box, Divider, IconButton, Modal, Typography } from '@mui/material';
import Loader from 'components/common/Loader';
import HtmlRenderer from 'components/HtmlRenderer copy';
import { displayNotification, getLanguageAgainstIDs, getStandardAgainstIDs, sendAPIRequest } from 'helpers';
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import CloseIcon from '@mui/icons-material/Close';
import { LIST_DETAIL_ELEMENTS } from 'constants';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    // height: 'auto',
    maxHeight: '80vh',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    overflowY: 'auto',
    padding: 0,
    '&::-webkit-scrollbar': {
        width: '8px', 
    },
    '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#bbbbbb', 
        borderRadius: '8px', 
    },
    '&::-webkit-scrollbar-track': {
        backgroundColor: '#bbbbbb', 
    },
};

const PreviewModal = ({ open, handleClose, prevId, term = '', is_glossary = false, standardDetails, glossaryPreviewData = { term: '', content: '', content_id: '' }, componentLanguage, load = async () => { } }) => {

    const { t } = useTranslation();
    const [data, setData] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [validator, setValidator] = useState(false);
    const [contentData, setContentData] = useState({});
    useEffect(() => {
        if (prevId && !is_glossary) {
            loadData();
        }
        if (is_glossary && prevId) {
            getValidationPermissions();
        }
        if (is_glossary) {
            loadGlossary();
        }
        if (glossaryPreviewData.content_id) {
            loadContentData();
        }
        return () => {
            setData("");
            setIsLoading(false);
        }
    }, [prevId]);


    const loadData = async () => {
        setIsLoading(true);
        try {
            const ret = await sendAPIRequest(`/api/temp-glossary-linking/get-html-content/${prevId}`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                const x = highlightTerm(res.data);
                setData(x);
            }
        } catch (e) {
            console.log({ e });
            displayNotification('error', e.message);
        }
        setIsLoading(false);

    }

    const loadGlossary = async () => {
        setIsLoading(true);
        try {
            const ret = await sendAPIRequest(`/api/draft-glossaries/${prevId}`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                setData(res.data.glossary_text);
            }
        } catch (e) {
            console.log({ e });
            displayNotification('error', e.message);
        }
        setIsLoading(false);

    }

    function highlightTerm(text) {
        text = text.replace(/\n/g, "");
        const regex = new RegExp(term.trim(), "gi");
        const highlightedText = text.replace(regex, `<span class="highlight_term">${term}</span>`);
        return highlightedText;
    }

    const handleValidate = async (id, comments, animal_type, standard_type, lang_id, type = "") => {
        if (!window.confirm('Are you sure you want to Validate?')) {
            return;
        }
        try {
            const standard = getStandardAgainstIDs(standard_type, animal_type);
            const lang_code = getLanguageAgainstIDs(parseInt(lang_id), true);
            const role_id = LIST_DETAIL_ELEMENTS.roles.publisher;
            const ret = await sendAPIRequest(`/api/doc-transfer/validate/?standard=${standard}&lang_code=${lang_code}&role_id=${role_id}&type=2`, 'POST', {
                draft_glossary_id: id,
                comments,
            });
            const res = await ret.json();
            if (res.success && res.status === 400) {
                displayNotification('success', 'No validator available against specific standard [x]');
            }
            if (res.success && res.status === 200) {
                await load();
                setValidator(false);
                handleClose();
                displayNotification('success', 'Successfully validated [x]');
            } else {
                displayNotification('error', 'Failed to validate [x]');
            }
        } catch (err) {
            console.log({ err });
            displayNotification('error', 'Failed to validate.');
        }
    }

    const getValidationPermissions = async () => {
        try {
            let ret;
            ret = await sendAPIRequest(`/api/doc-transfer/get-validation-permission-draft/${prevId}/?type=2`);
            const res = await ret.json();
            if (res.success && res.status === 200 && res.data === true) {
                setValidator(true);
            } else {
                setValidator(false);
            }
        } catch (e) {
            console.log({ e });
            // displayNotification('error', 'Failed to load chapter data [F]');
        }
    }

    const loadContentData = async () => {
        try {
            const ret = await sendAPIRequest(`/api/html-contents/${glossaryPreviewData.content_id}`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                res.data['plaintext'] = extractSurroundingWords(glossaryPreviewData.term, convertHtmlToText(res.data.content))
                setContentData(res.data);
            } else {
                setContentData({});
            }
        } catch (error) {
            console.log({ error });
            displayNotification('error', 'Failed to load content .[F]');
        }
    }

    function convertHtmlToText(html) {
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');
        return (doc.body.textContent || "").replace(/\n/g, ' ');
    }

    function extractSurroundingWords(term, text) {
        // Escape special characters in the term to safely use in regex
        const escapedTerm = term.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
        // Regex to find the term in the text, case-insensitive
        const regex = new RegExp(`\\b(${escapedTerm})\\b`, 'gi');

        // Replace the matched term with a span tag for highlighting
        const highlightedText = text.replace(regex, '<span class="highlight_term">$1</span>');

        return highlightedText;
    }

    return (
        <>
            <Modal
                open={open}
                onClose={() => handleClose(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box sx={{ padding: '10px 20px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Glossary Preview
                        </Typography>
                        <IconButton onClick={() => handleClose(false)}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Divider sx={{ backgroundColor: '#000', margin: '0' }} />
                    <Box sx={{ padding: '20px' }}>
                        <div className='row'>
                            <div className='col-2'>
                                <Typography variant="h7" sx={{ fontWeight: 'bold', marginBottom: '20px' }}>
                                    Term:
                                </Typography>
                            </div>
                            <div className='col-9'>
                                <Typography variant="h7" sx={{ marginBottom: '20px' }}>
                                    {glossaryPreviewData?.term}
                                </Typography>
                            </div>
                        </div>
                        {
                            !isLoading && <div className="row mt-3">
                                <div className='col-2'>
                                    <Typography variant="h7" sx={{ fontWeight: 'bold', marginBottom: '20px' }}>
                                        Text:
                                    </Typography>
                                </div>
                                <div className='col-9' >
                                    {!is_glossary &&
                                        <HtmlRenderer
                                            htmlContent={contentData?.plaintext}
                                        />
                                    }
                                    {
                                        is_glossary && data
                                    }
                                </div>
                            </div>
                        }
                        {
                            isLoading && <div className='d-flex justify-content-center'>
                                <Loader message='Loading Data, Please wait...' />
                            </div>
                        }
                    </Box>
                    <Divider sx={{ backgroundColor: '#000', margin: '0' }} />
                    <div className='row '>
                        <div className="col-12 d-flex justify-content-end mt-3">
                            {validator && <button
                                type='button'
                                className='btn btn--outline'
                                onClick={() => { handleValidate(prevId, "", standardDetails.animal_type, standardDetails.standard_type, componentLanguage, 2) }}
                            >
                                Validate
                            </button>}
                            <button
                                type='button'
                                className='btn btn--primary mx-2'
                                onClick={() => {
                                    setValidator(false);
                                    handleClose()
                                }}
                            >
                                Close
                            </button>
                        </div>



                    </div>
                </Box>
            </Modal>
        </>
    );
}

export default PreviewModal;