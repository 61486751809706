import React, { useEffect, useState } from 'react'
import { Box, Checkbox, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@mui/material';
import { setCurrentPage } from 'redux/generalSlice';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { LIST_DETAIL_ELEMENTS } from 'constants';
import SearchByFilter from './SearchByFilter';
import SearchByKeyword from './SearchByKeyword';
import CustomCard from 'components/common/CustomCard';
import Container from 'components/common/Container';
import CardHeader from 'components/common/CardHeader';
import CardBody from 'components/common/CardBody';
import Button from 'components/common/Button';

const SearchContent = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [searchOption, setSearchOption] = useState('search-by-filter');
    const [terrestrialCode, setTerrestrialCode] = useState(true);
    const [terrestrialManual, setTerrestrialManual] = useState(false);
    const [aquaticCode, setAquaticCode] = useState(false);
    const [aquaticManual, setAquaticManual] = useState(false);

    useEffect(() => {
        dispatch(setCurrentPage('display-navigation'));
    }, []);

    const handleSearchOptionChange = (event) => {
        setSearchOption(event.target.value);
    };

    const goBack = () => {
        navigate(`/portal/content-navigation`);
    }

    const getAnimalType = () => {
        let animalType = [];
        if (terrestrialCode || terrestrialManual) {
            animalType.push(LIST_DETAIL_ELEMENTS.animal_type.terrestrial);
        }
        if (aquaticCode || aquaticManual) {
            animalType.push(LIST_DETAIL_ELEMENTS.animal_type.aquatic);
        }
        return animalType.toString();
    }

    const getStandardType = () => {
        let standardType = [];
        if (terrestrialCode || aquaticCode) {
            standardType.push(LIST_DETAIL_ELEMENTS.standard_type.codes);
        }
        if (terrestrialManual || aquaticManual) {
            standardType.push(LIST_DETAIL_ELEMENTS.standard_type.manuals);
        }
        return standardType.toString();
    }

    const handleChangeCheck = (e) => {
        if (!terrestrialCode && !terrestrialManual && !aquaticCode && !aquaticManual) {

        }
        if (e.target.value === 'TerrestrialManual') {
            if (!terrestrialCode && !aquaticCode && !aquaticManual && e.target.checked === false) {
                return
            } else {
                setTerrestrialManual(e.target.checked)
            }
        } else if (e.target.value === "TerrestrialCode") {
            if (!terrestrialManual && !aquaticCode && !aquaticManual && e.target.checked === false) {
                return
            }
            else {
                setTerrestrialCode(e.target.checked)
            }
        }
        else if (e.target.value === 'AquaticCode') {
            if (!terrestrialCode && !terrestrialManual && !aquaticManual && e.target.checked === false) {
                return
            }
            else {
                setAquaticCode(e.target.checked)
            }
        } else if (e.target.value === 'AquaticManual') {
            if (!terrestrialCode && !terrestrialManual && !aquaticCode && e.target.checked === false) {
                return
            }
            else {
                setAquaticManual(e.target.checked)
            }
        }
    }

    return (
        <>
            <Container>
                <CustomCard className={'d-flex justify-content-between'}>
                    <CardHeader>
                        {t("47", "Search Content")}
                    </CardHeader>
                    <CardBody>
                        <Button
                            onClick={goBack}
                        >
                            {t("46", "Content Navigation")}
                        </Button>
                    </CardBody>
                </CustomCard>
                <CustomCard >
                    <Box
                        display="flex"
                        p={1}
                        justifyContent="center"
                        alignItems="center"
                        border="1px solid LightGray"
                        borderRadius="4px"
                        bgcolor="#F4F4F4"
                    >
                        <FormControl>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                            >
                                <Grid container spacing={2} justifyContent="center">
                                    <Grid item>
                                        <FormControlLabel
                                            className="custom-checkbox-padding mb-2"
                                            control={
                                                <Checkbox
                                                    value="TerrestrialCode"
                                                    onChange={handleChangeCheck}
                                                    checked={terrestrialCode}
                                                />
                                            }
                                            label={t("28", "Terrestrial Code")}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <FormControlLabel
                                            className="custom-checkbox-padding mb-2"
                                            control={
                                                <Checkbox
                                                    value="TerrestrialManual"
                                                    onChange={handleChangeCheck}
                                                    checked={terrestrialManual}
                                                />
                                            }
                                            label={t("29", "Terrestrial Manual")}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <FormControlLabel
                                            className="custom-checkbox-padding mb-2"
                                            control={
                                                <Checkbox
                                                    value="AquaticCode"
                                                    onChange={handleChangeCheck}
                                                    checked={aquaticCode}
                                                />
                                            }
                                            label={t("30", "Aquatic Code")}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <FormControlLabel
                                            className="custom-checkbox-padding mb-2"
                                            control={
                                                <Checkbox
                                                    value="AquaticManual"
                                                    onChange={handleChangeCheck}
                                                    checked={aquaticManual}
                                                />
                                            }
                                            label={t("31", "Aquatic Manual")}
                                        />
                                    </Grid>
                                </Grid>
                            </RadioGroup>
                        </FormControl>
                    </Box>
                    <Box paddingY={2}>
                        <FormControl
                            sx={{
                                paddingX: '10px'
                            }}
                        >
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                value={searchOption}
                                onChange={handleSearchOptionChange}
                            >
                                <FormControlLabel
                                    value="search-by-filter"
                                    control={<Radio />}
                                    label={t("48", "Search by filter")}
                                />
                                <FormControlLabel
                                    value="search-by-keyword"
                                    control={<Radio />}
                                    label={t("49", "Search by text")}
                                />
                            </RadioGroup>
                        </FormControl>
                        {
                            searchOption === "search-by-filter" && (
                                <SearchByFilter
                                    isTerrestrialCode={terrestrialCode}
                                    isTerrestrialManual={terrestrialManual}
                                    isAquaticCode={aquaticCode}
                                    isAquaticManual={aquaticManual}
                                    isManual={terrestrialManual || aquaticManual}
                                    isCode={terrestrialCode || aquaticCode}
                                    isAquatic={aquaticManual || aquaticCode}
                                    isTerrestrial={terrestrialManual || terrestrialCode}
                                    animalType={getAnimalType()}
                                    standardType={getStandardType()}
                                />
                            )
                        }
                        {
                            searchOption === "search-by-keyword" && <SearchByKeyword
                                isTerrestrialCode={terrestrialCode}
                                isTerrestrialManual={terrestrialManual}
                                isAquaticCode={aquaticCode}
                                isAquaticManual={aquaticManual}
                                isManual={terrestrialManual || aquaticManual}
                                isCode={terrestrialCode || aquaticCode}
                                animalType={getAnimalType()}
                                standardType={getStandardType()}
                            />
                        }
                    </Box>
                </CustomCard>
            </Container>
        </>
    )
}

export default SearchContent
