import React, { useState, useEffect, useMemo, useRef } from "react";
import { displayNotification, generateOptionsFromData, sendAPIRequest } from "../../helpers";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import TextFieldCustom from "components/TextFieldCustom";
import { setCurrentPage } from "redux/generalSlice";
import AutoCompleteDropdown from "components/autocomplete/AutoCompleteDropdown";
import Spinner from "components/spinner/Spinner";
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { LIST_DETAIL_ELEMENTS } from "constants";
import { useSearchParams } from "react-router-dom";

const FileUploading = ({ documentID }) => {

    const authData = useSelector(s => s.auth.data);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const browseFileRef = useRef();
    const [searchParams, setSearchParams] = useSearchParams();
    // const currentTab = page ? page : 'other-upload';
    const initFilters = {
        file_name: "",
        document_title: { value: "" },
        standard_type: { value: "" },
        document_type: { value: "" },
        animal_type: { value: "" },
        disease_name: { value: "" },
        language_id: { value: "" },
        section_name: "",
        section_ref: '',
        section: { value: "" },
        chapter_name: "",
        chapter_ref: ''
    };

    const [isLoading, setIsLoading] = useState({
        load_lists: false,
        load_sections: false,
        load_chapters: false,
        is_cracking: false,
    });
    const documentTypes = [{ id: 1, list_element_name: "glossaries" }];
    const [fileName, setFileName] = useState("");
    const [currentFile, setCurrentFile] = useState();
    const [reportData, setReportData] = useState({ ...initFilters });
    const [standardTypes, setStandardTypes] = useState([]);
    const [animalTypes, setAnimalTypes] = useState([]);
    const [diseaseName, setDiseaseName] = useState([]);
    const [languages, setLanguages] = useState([]);

    const [tab, setTab] = useState('html-upload');
    const [chapters, setChapters] = useState([]);
    const [sections, setSections] = useState([]);
    const htmlInitFilters = {
        chapter_id: { value: "" }
    }
    const [htmlFilters, setHTMlFilters] = useState({ ...htmlInitFilters });

    useEffect(() => {
        dispatch(setCurrentPage("data-migrations"));
        loadLists();
    }, []);

    useEffect(() => {
        documentID ? getDocument() : setReportData({ ...initFilters });
    }, [documentID]);

    useEffect(() => {
        tab === 'html-mapping' && loadChapters();
        tab === 'html-upload' && loadSections();
    }, [reportData.language_id.value]);

    const resetFilters = () => {
        setFileName("");
        setCurrentFile("");
        setReportData({ ...initFilters });
        setHTMlFilters({ ...htmlInitFilters });
    }

    const loadChapters = async () => {
        setIsLoading({ ...isLoading, load_chapters: true });
        try {
            const ret = await sendAPIRequest(`/api/documents/?language-1_id=${reportData.language_id.value ? reportData.language_id.value : 102}`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                setChapters(res.data);
            }
        } catch (e) {
            console.log({ e });
        }
        setIsLoading({ ...isLoading, load_chapters: false });
    }

    const loadSections = async () => {
        setIsLoading({ ...isLoading, load_sections: true });
        try {
            const ret = await sendAPIRequest(`/api/contents/chapter-specific-type/?text_type=${LIST_DETAIL_ELEMENTS.text_types.section_name}&language_id=${reportData.language_id.value ? reportData.language_id.value : 102}`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                setSections(res.data);
            }
        } catch (e) {
            console.log({ e });
        }
        setIsLoading({ ...isLoading, load_sections: false });
    }

    const getDocument = async () => {
        try {
            const ret = await sendAPIRequest(`/api/documents/${documentID}`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                if (res.data.is_html_upload) {
                    setTab('html-upload');
                }else{
                    setTab('other-upload');
                }
                setReportData({
                    ...reportData,
                    file_name: "",
                    document_title: { value: res.data.document_name },
                    standard_type: { value: res.data.standard_type },
                    document_type: { value: res.data.document_type },
                    animal_type: { value: res.data.animal_type },
                    disease_name: { value: res.data.disease_id },
                    language_id: { value: res.data.language_id },
                    ...(res.data.is_html_upload
                        ? {
                            section: { value: res.data?.contents[0]?.parent_id },
                            chapter_name: res.data?.contents[0]?.standard_content,
                            chapter_ref: res.data?.contents[0]?.ref_number
                        } : {}
                    )
                });
            }
        } catch (e) {
            console.log({ e });
        }
    }

    const loadLists = async () => {
        setIsLoading({ ...isLoading, load_lists: true });
        try {
            await getStandardTypes();
            await getAnimalTypes();
            await getDiseaseName();
            await getLanguages();
            await loadSections();
        } catch (e) {
            console.log({ e });
        }
        setIsLoading({ ...isLoading, load_lists: false });
    }

    const selectionValidator = () => {
        const common_validation = reportData.document_title.value
            && reportData.standard_type.value && reportData.document_type.value
            && reportData.language_id.value && reportData.animal_type.value;
        const section_validation = reportData.section.value || (
            reportData.section_name && reportData.section_ref
        );
        const chapter_validation = reportData.chapter_name && reportData.chapter_ref;
        const success = {
            success: true,
            error: ''
        };
        const fail = {
            success: false,
            error: t("89", "Please fill all required fields"),
        }
        if (tab === 'other-upload') {
            return common_validation ? success : fail;
        } else if (tab === 'html-upload') {
            return common_validation && section_validation && chapter_validation ? success : fail;
        } else if (tab === 'html-mapping') {
            return reportData.language_id.value && htmlFilters.chapter_id.value ? success : fail;
        } else {
            return fail;
        }
    };

    const fileUpload = () => {
        setFileName("");
        setCurrentFile("");
        if (browseFileRef) {
            browseFileRef.current.accept = tab === 'other-upload' ? ".pdf" : ".htm, .html"
            browseFileRef.current.click();
        } else {
            displayNotification("error", "Error opening file selection window.");
        }
    };

    const saveReport = async () => {
        if (window.confirm("Are you sure you want to upload the file?")) {
            const validation = selectionValidator();
            if (validation) {
                setIsLoading({ ...isLoading, is_cracking: true });
                if (currentFile || documentID) {
                    let formData = new FormData();
                    formData.append("document_name", reportData.document_title.value);
                    formData.append("standard_type", reportData.standard_type.value);
                    // formData.append("document_type", reportData.document_type.value);
                    formData.append("animal_type", reportData.animal_type.value);
                    formData.append("disease_id", reportData.disease_name.value);
                    formData.append("language_id", reportData.language_id.value);
                    try {
                        let data;
                        if (documentID) {
                            formData.append("updatedBy", authData.id);
                            if (tab === 'html-upload') {
                                formData.append("section", reportData.section.value);
                                formData.append("chapter_name", reportData.chapter_name);
                                formData.append("chapter_ref", reportData.chapter_ref);
                            }
                            data = await sendAPIRequest(`/api/documents/${documentID}`, 'PUT', formData);
                        } else {
                            formData.append("file_name", currentFile);
                            formData.append("createdBy", authData.id);
                            if (tab === 'other-upload') {
                                data = await sendAPIRequest("/api/documents/", "POST", formData);
                            } else if (tab === 'html-upload') {
                                if (reportData.section_name) {
                                    formData.append("section_name", reportData.section_name)
                                    formData.append("section_ref", reportData.section_ref)
                                } else {
                                    formData.append("section", reportData.section.value)
                                }
                                formData.append("chapter_name", reportData.chapter_name);
                                formData.append("chapter_ref", reportData.chapter_ref);
                                data = await sendAPIRequest("/api/html/html-upload", "POST", formData);
                            } else {
                                const htmlFormData = new FormData();
                                htmlFormData.append("file_name", currentFile);
                                htmlFormData.append("document_id", htmlFilters.chapter_id.value);
                                htmlFormData.append("createdBy", authData.id);
                                data = await sendAPIRequest("/api/html/", "POST", htmlFormData);
                            }
                        }
                        const resp = await data.json();
                        if (resp.success && resp.status === 200) {
                            displayNotification("success", t("90", "Document saved"));
                            await loadSections();
                        } else {
                            displayNotification("error", resp.message);
                        }
                    } catch (e) {
                        console.log(e);
                        displayNotification("error", t("91", "Unable to save document"));
                    }
                } else {
                    displayNotification("error", t("92", "No file available"));
                }
                setIsLoading({ ...isLoading, is_cracking: false });
            } else {
                displayNotification("error", validation.error);
            }
        }
    };

    const getFile = (files) => {
        const selectedFile = files[0];
        if (selectedFile) {
            setCurrentFile(selectedFile);
            setFileName(selectedFile.name);
        } else {
            setFileName("");
        }
    };

    const handOnChangeValues = (obj, check = false, text = null) => {
        setReportData(obj);
    };

    const getStandardTypes = async () => {
        try {
            const data = await sendAPIRequest("/api/lists/master/1");
            const response = await data.json();
            if (response.success) {
                setStandardTypes(response.data);
            }
        } catch (e) {
            console.log(e);
        }
    };

    const getAnimalTypes = async () => {
        try {
            const data = await sendAPIRequest("/api/lists/master/2");
            const response = await data.json();
            if (response.success) {
                setAnimalTypes(response.data);
            }
        } catch (e) {
            console.log(e);
        }
    };
    const getDiseaseName = async () => {
        try {
            const data = await sendAPIRequest("/api/lists/master/3");
            const response = await data.json();
            if (response.success) {
                setDiseaseName(response.data);
            }
        } catch (e) {
            console.log(e);
        }
    };

    const getLanguages = async () => {
        try {
            const ret = await sendAPIRequest("/api/lists/master/8");
            const res = await ret.json();
            if (res.success && res.status === 200) {
                setLanguages(res.data);
            }
        } catch (e) {
            console.log(e);
        }
    }

    const standardTypesOptions = useMemo(() => {
        return generateOptionsFromData(standardTypes, {
            value: "id",
            label: "list_element_name",
        });
    }, [standardTypes]);

    const documentTypesOptions = useMemo(() => {
        return generateOptionsFromData(documentTypes, {
            value: "id",
            label: "list_element_name",
        });
    }, [standardTypes]);

    const animalTypesOptions = useMemo(() => {
        return generateOptionsFromData(animalTypes, {
            value: "id",
            label: "list_element_name",
        });
    }, [animalTypes]);

    const diseaseNameOptions = useMemo(() => {
        return generateOptionsFromData(diseaseName, {
            value: "id",
            label: "list_element_name",
        });
    }, [diseaseName]);

    const languagesOptions = useMemo(() => {
        return generateOptionsFromData(languages, {
            value: "id",
            label: "list_element_name",
        });
    }, [languages]);

    const chapterOptions = useMemo(() => {
        return generateOptionsFromData(chapters, {
            value: "id",
            label: "document_name",
        });
    }, [chapters]);

    const sectionOptions = useMemo(() => {
        return generateOptionsFromData(sections, {
            value: "id",
            label: "standard_content",
        });
    }, [sections]);

    return (
        <>
            <div className="card card-woah border border-secondary shadow rounded-0 my-2">
                <div className="card-body p-4">
                    <div className="d-flex justify-content-between align-items-xl-baseline">
                        <h5 className="card-title2 m-0">
                            {t("93", "Document")}{" "}
                            {documentID ? t("94", "Metadata") : t("95", "Upload")}
                        </h5>
                        {
                            !documentID && (
                                <div>
                                    <FormControl>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                            sx={{ marginTop: "13px" }}
                                            defaultValue={"other-upload"}
                                            value={tab}
                                            onChange={(e) => setTab(e.target.value)}
                                        >
                                            <FormControlLabel
                                                value="html-mapping"
                                                control={<Radio style={{ height: "18px", }} />}
                                                label="HTML Mapping"
                                                style={{ display:'none'  }}
                                            />
                                            <FormControlLabel
                                                value="html-upload"
                                                control={<Radio style={{ height: "18px" }} />}
                                                label="HTML File Upload"
                                            />
                                            <FormControlLabel
                                                value="other-upload"
                                                control={<Radio style={{ height: "18px" }} />}
                                                label="Other Files Upload"
                                                style={{ display: 'none'}}
                                        />
                                        </RadioGroup>
                                    </FormControl>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
            <>
                <div className="card card-woah border border-secondary shadow rounded-0 h-100 mt-2">
                    <div className="card-body p-4">
                        <div className="align-items-center d-flex flex-column">
                            {
                                !documentID && (
                                    <div className="d-flex flex-column tempus-dominus-widget">
                                        <button
                                            type="button"
                                            className="btn btn--primary mb-2"
                                            onClick={() => fileUpload()}
                                        >
                                            {t("37", "Browse File")}
                                        </button>
                                        <label>
                                            {fileName ? (
                                                <div>
                                                    <strong>{t("38", "Selected File")}: </strong>
                                                    {fileName}
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                        </label>
                                        <input
                                            ref={browseFileRef}
                                            type="file"
                                            id="get-file"
                                            style={{ display: "none" }}
                                            onChange={(e) => getFile(e.target.files)}
                                        />
                                    </div>
                                )
                            }
                            {
                                (tab === "other-upload" || tab === "html-upload") && (
                                    <div className="row w-100 ">
                                        <div className="row mt-2">
                                            <div className="col-3 mt-3">
                                                <label htmlFor="document-type" className="required">
                                                    {t("96", "Standard Type")}
                                                </label>
                                            </div>
                                            <div className="col-7">
                                                <AutoCompleteDropdown
                                                    className={isLoading.load_lists ? "opacity-50" : "opacity-100"}
                                                    disabled={isLoading.load_lists}
                                                    label={isLoading.load_lists ? "Loading..." : t("96", "Standard Type")}
                                                    showClearIcon={false}
                                                    error={false}
                                                    required={true}
                                                    multiple={false}
                                                    hasselectall={false}
                                                    options={standardTypesOptions}
                                                    onChange={(event, value) => {
                                                        handOnChangeValues({
                                                            ...reportData,
                                                            standard_type: {
                                                                value: value?.value,
                                                                text: value?.label,
                                                            },
                                                        });
                                                    }}
                                                    value={
                                                        !reportData.standard_type?.value
                                                            ? null
                                                            : standardTypesOptions.find(r => r.value == reportData?.standard_type?.value)
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-3 mt-3">
                                                <label htmlFor="document-type" className="required">
                                                    {t("97", "Animal Type")}
                                                </label>
                                            </div>
                                            <div className="col-7">
                                                <AutoCompleteDropdown
                                                    className={isLoading.load_lists ? "opacity-50" : "opacity-100"}
                                                    disabled={isLoading.load_lists}
                                                    label={isLoading.load_lists ? "Loading..." : t("97", "Animal Type")}
                                                    showClearIcon={false}
                                                    error={false}
                                                    required={true}
                                                    multiple={false}
                                                    hasselectall={false}
                                                    options={animalTypesOptions}
                                                    onChange={(event, value) => {
                                                        handOnChangeValues({
                                                            ...reportData,
                                                            animal_type: {
                                                                value: value?.value,
                                                                text: value?.label,
                                                            },
                                                        });
                                                    }}
                                                    value={
                                                        !reportData.animal_type?.value
                                                            ? null
                                                            : animalTypesOptions.find(r => r.value == reportData?.animal_type?.value)
                                                    }
                                                />
                                            </div>
                                        </div>
                                        {
                                            (tab === 'other-upload' || tab === 'html-upload') &&
                                            <div className="row mt-2">
                                                <div className="col-3 mt-3">
                                                    <label htmlFor="document-type">
                                                        {t("98", "Disease Name")}
                                                    </label>
                                                </div>
                                                <div className="col-7">
                                                    <AutoCompleteDropdown
                                                        className={isLoading.load_lists ? "opacity-50" : "opacity-100"}
                                                        disabled={isLoading.load_lists}
                                                        label={isLoading.load_lists ? "Loading..." : t("98", "Disease Name")}
                                                        showClearIcon={false}
                                                        error={false}
                                                        required={false}
                                                        multiple={false}
                                                        hasselectall={false}
                                                        options={diseaseNameOptions}
                                                        onChange={(event, value) => {
                                                            handOnChangeValues({
                                                                ...reportData,
                                                                disease_name: {
                                                                    value: value?.value,
                                                                    text: value?.label,
                                                                },
                                                            });
                                                        }}
                                                        value={
                                                            !reportData.disease_name?.value
                                                                ? null
                                                                : diseaseNameOptions.find(r => r.value == reportData?.disease_name?.value)
                                                        }
                                                    />

                                                </div>
                                            </div>
                                        }
                                        <div className="row mt-2">
                                            <div className="col-3 mt-3">
                                                <label htmlFor="document-type" className="required">
                                                    {t("99", "Language")}
                                                </label>
                                            </div>
                                            <div className="col-7">
                                                <AutoCompleteDropdown
                                                    className={isLoading.load_lists ? "opacity-50" : "opacity-100"}
                                                    disabled={isLoading.load_lists}
                                                    label={isLoading.load_lists ? "Loading..." : t("99", "Language")}
                                                    showClearIcon={false}
                                                    error={false}
                                                    required={true}
                                                    multiple={false}
                                                    hasselectall={false}
                                                    options={languagesOptions}
                                                    onChange={(event, value) => {
                                                        handOnChangeValues({
                                                            ...reportData,
                                                            language_id: {
                                                                value: value?.value,
                                                                text: value?.label,
                                                            },
                                                        });
                                                    }}
                                                    value={
                                                        !reportData.language_id?.value
                                                            ? null
                                                            : languagesOptions.find(r => r.value == reportData?.language_id?.value)
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-3 mt-3">
                                                <label htmlFor="document-title" className="required">
                                                    {t("100", "Document Title")}
                                                </label>
                                            </div>
                                            <div className="col-7">
                                                <TextFieldCustom
                                                    variant="outlined"
                                                    id="document-title"
                                                    fullWidth
                                                    size="small"
                                                    sx={{
                                                        backgroundColor: "white",
                                                        color: "white",
                                                        border: "#ff4815",
                                                        width: "98%",
                                                    }}
                                                    value={reportData.document_title.value}
                                                    onChange={(e) =>
                                                        handOnChangeValues({
                                                            ...reportData,
                                                            document_title: { value: e.target.value },
                                                        })
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            {
                                tab === "html-mapping" && (
                                    <div className="row w-100">
                                        <div className="row mt-2">
                                            <div className="col-3 mt-3">
                                                <label htmlFor="document-type" className="required">
                                                    {t("101", "Select Language")}
                                                </label>
                                            </div>
                                            <div className="col-7">
                                                <AutoCompleteDropdown
                                                    className={isLoading.load_lists ? "opacity-50" : "opacity-100"}
                                                    disabled={isLoading.load_lists}
                                                    label={isLoading.load_lists ? "Loading..." : t("99", "Language")}
                                                    showClearIcon={false}
                                                    error={false}
                                                    required={true}
                                                    multiple={false}
                                                    hasselectall={false}
                                                    options={languagesOptions}
                                                    onChange={(event, value) => {
                                                        handOnChangeValues({
                                                            ...reportData,
                                                            language_id: {
                                                                value: value?.value,
                                                                text: value?.label,
                                                            },
                                                        });
                                                    }}
                                                    value={
                                                        !reportData.language_id?.value
                                                            ? { value: "", label: "" }
                                                            : languagesOptions.find(r => r.value == reportData?.language_id?.value)
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-3 mt-3">
                                                <label htmlFor="document-type" className="required">
                                                    {t("102", "Select Chapter")}
                                                </label>
                                            </div>
                                            <div className="col-7">
                                                <AutoCompleteDropdown
                                                    className={isLoading.load_chapters ? "opacity-50" : "opacity-100"}
                                                    disabled={isLoading.load_chapters}
                                                    label={isLoading.load_chapters ? "Loading..." : t("43", "Chapters")}
                                                    showClearIcon={false}
                                                    error={false}
                                                    required={true}
                                                    multiple={false}
                                                    hasselectall={false}
                                                    options={chapterOptions}
                                                    onChange={(event, value) => {
                                                        setHTMlFilters({
                                                            ...htmlFilters,
                                                            chapter_id: {
                                                                value: value?.value,
                                                                text: value?.label,
                                                            },
                                                        });
                                                    }}
                                                    value={
                                                        !htmlFilters.chapter_id?.value
                                                            ? { value: "", label: "" }
                                                            : chapterOptions.find(r => r.value == htmlFilters?.chapter_id?.value)
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            {
                                tab === 'html-upload' && (
                                    <div className="row w-100">
                                        {
                                            !reportData.section_name && (
                                                <div className="row mt-2">
                                                    <div className="col-3 mt-3">
                                                        <label htmlFor="document-type" className="required">
                                                            {t("103", "Select Section")}
                                                        </label>
                                                    </div>
                                                    <div className="col-7">
                                                        <AutoCompleteDropdown
                                                            className={isLoading.load_sections ? "opacity-50" : "opacity-100"}
                                                            disabled={isLoading.load_sections}
                                                            label={isLoading.load_sections ? "Loading..." : t("61", "Sections")}
                                                            showClearIcon={true}
                                                            error={false}
                                                            required={true}
                                                            multiple={false}
                                                            hasselectall={false}
                                                            options={sectionOptions}
                                                            onChange={(event, value) => {
                                                                handOnChangeValues({
                                                                    ...reportData,
                                                                    section: {
                                                                        value: value?.value,
                                                                        text: value?.label,
                                                                    },
                                                                });
                                                            }}
                                                            value={
                                                                !reportData.section?.value
                                                                    ? null
                                                                    : sectionOptions.find(r => r.value == reportData?.section?.value)
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        }
                                        {
                                            (!reportData.section.value && !documentID) && (
                                                <>
                                                    <div className="row mt-2">
                                                        <div className="col-3 mt-3">
                                                            <label htmlFor="section-name" className="required">
                                                                {t("104", "Section Name")}
                                                            </label>
                                                        </div>
                                                        <div className="col-7">
                                                            <TextFieldCustom
                                                                variant="outlined"
                                                                id="section-name"
                                                                fullWidth
                                                                size="small"
                                                                sx={{
                                                                    backgroundColor: "white",
                                                                    color: "white",
                                                                    border: "#ff4815",
                                                                    width: "98%",
                                                                }}
                                                                value={reportData.section_name}
                                                                onChange={(e) =>
                                                                    handOnChangeValues({
                                                                        ...reportData,
                                                                        section_name: e.target.value,
                                                                    })
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2">
                                                        <div className="col-3 mt-3">
                                                            <label htmlFor="section-ref" className="required">
                                                                {t("105", "Section Ref Number")}
                                                            </label>
                                                        </div>
                                                        <div className="col-7">
                                                            <TextFieldCustom
                                                                variant="outlined"
                                                                id="section-ref"
                                                                type="number"
                                                                fullWidth
                                                                size="small"
                                                                sx={{
                                                                    backgroundColor: "white",
                                                                    color: "white",
                                                                    border: "#ff4815",
                                                                    width: "98%",
                                                                }}
                                                                value={reportData.section_ref}
                                                                onChange={(e) =>
                                                                    handOnChangeValues({
                                                                        ...reportData,
                                                                        section_ref: e.target.value,
                                                                    })
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        }
                                        <div className="row mt-2">
                                            <div className="col-3 mt-3">
                                                <label htmlFor="chapter-name" className="required">
                                                    {t("106", "Chapter Name")}
                                                </label>
                                            </div>
                                            <div className="col-7">
                                                <TextFieldCustom
                                                    variant="outlined"
                                                    id="chapter-name"
                                                    fullWidth
                                                    size="small"
                                                    sx={{
                                                        backgroundColor: "white",
                                                        color: "white",
                                                        border: "#ff4815",
                                                        width: "98%",
                                                    }}
                                                    value={reportData.chapter_name}
                                                    onChange={(e) =>
                                                        handOnChangeValues({
                                                            ...reportData,
                                                            chapter_name: e.target.value,
                                                        })
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-3 mt-3">
                                                <label htmlFor="chapter-ref" className="required">
                                                    {t("107", "Chapter Number")}
                                                </label>
                                            </div>
                                            <div className="col-7">
                                                <TextFieldCustom
                                                    variant="outlined"
                                                    id="chapter-ref"
                                                    type="number"
                                                    fullWidth
                                                    size="small"
                                                    sx={{
                                                        backgroundColor: "white",
                                                        color: "white",
                                                        border: "#ff4815",
                                                        width: "98%",
                                                    }}
                                                    value={reportData.chapter_ref}
                                                    onChange={(e) =>
                                                        handOnChangeValues({
                                                            ...reportData,
                                                            chapter_ref: e.target.value,
                                                        })
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            <div>
                                <button
                                    type="button"
                                    className="btn btn--primary mt-5"
                                    onClick={() => saveReport()}
                                    disabled={isLoading.is_cracking}
                                >
                                    {
                                        isLoading.is_cracking ? (
                                            <Spinner message={`${t("108", "Uploading and Cracking")}...`} />
                                        ) : documentID ? t("109", "Update") : t("95", "Upload")
                                    }
                                </button>
                                <button
                                    className="btn btn--outline font-custom fs-6 ms-1 mt-5"
                                    onClick={() => {
                                        resetFilters();
                                    }}
                                >
                                    <span> {t("14", "Reset")}</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        </>
    );
};

FileUploading.defaultProps = {
    documentID: null,
}

export default FileUploading;
