import { DataGrid, GridPagination, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton, GridToolbarQuickFilter } from '@mui/x-data-grid';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';

const DataTable = ({
    getRowId, className, height, isLoading,
    rows, columns, hasIterator, pagination,
    pageSize, hasExport, sx
}) => {

    const { t } = useTranslation();
    const authData = useSelector(s => s.auth.data);
    const siteLanguage = useSelector(s => s.general.siteLanguageSont);

    const GRID_DEFAULT_LOCALE_TEXT = useMemo(() => {
        return {
            // Root
            noRowsLabel: t("-1", "No rows"),
            noResultsOverlayLabel: t("-1", "No results found."),

            // Density selector toolbar button text
            toolbarDensity: t("-1", "Row Spacing"),
            toolbarDensityLabel: t("-1", "Row Spacing"),
            toolbarDensityCompact: t("-1", "Compact"),
            toolbarDensityStandard: t("-1", "Standard"),
            toolbarDensityComfortable: t("-1", "Comfortable"),

            // Columns selector toolbar button text
            toolbarColumns: t("-1", "Columns"),
            toolbarColumnsLabel: t("-1", "Select columns"),

            // Filters toolbar button text
            toolbarFilters: t("-1", "Filters"),
            toolbarFiltersLabel: t("-1", "Show filters"),
            toolbarFiltersTooltipHide: t("-1", "Hide filters"),
            toolbarFiltersTooltipShow: t("-1", "Show filters"),
            toolbarFiltersTooltipActive: (count) =>
                count !== 1 ? `${count} ${t("-1", "active filters")}` : `${count} ${t("-1", "active filter")} `,

            // Quick filter toolbar field
            toolbarQuickFilterPlaceholder: `${t("-1", "Search")}...`,
            toolbarQuickFilterLabel: t("-1", "Search"),
            toolbarQuickFilterDeleteIconLabel: t("-1", "Clear"),

            // Export selector toolbar button text
            toolbarExport: t("-1", "Export"),
            toolbarExportLabel: t("-1", "Export"),
            toolbarExportCSV: t("-1", "Download as CSV"),
            toolbarExportPrint: t("-1", "Print"),
            toolbarExportExcel: t("-1", "Download as Excel"),

            // Columns panel text
            columnsPanelTextFieldLabel: t("-1", "Find column"),
            columnsPanelTextFieldPlaceholder: t("-1", "Column title"),
            columnsPanelDragIconLabel: t("-1", "Reorder column"),
            columnsPanelShowAllButton: t("-1", "Show all"),
            columnsPanelHideAllButton: t("-1", "Hide all"),

            // Filter panel text
            filterPanelAddFilter: t("-1", "Add filter"),
            filterPanelRemoveAll: t("-1", "Remove all"),
            filterPanelDeleteIconLabel: t("-1", "Delete"),
            filterPanelLogicOperator: t("-1", "Logic operator"),
            filterPanelOperator: t("-1", "Operator"),
            filterPanelOperatorAnd: t("-1", "And"),
            filterPanelOperatorOr: t("-1", "Or"),
            filterPanelColumns: t("-1", "Columns"),
            filterPanelInputLabel: t("-1", "Value"),
            filterPanelInputPlaceholder: t("-1", "Filter value"),

            // Filter operators text
            filterOperatorContains: t("-1", "contains"),
            filterOperatorEquals: t("-1", "equals"),
            filterOperatorStartsWith: t("-1", "starts with"),
            filterOperatorEndsWith: t("-1", "ends with"),
            filterOperatorIs: t("-1", "is"),
            filterOperatorNot: t("-1", "is not"),
            filterOperatorAfter: t("-1", "is after"),
            filterOperatorOnOrAfter: t("-1", "is on or after"),
            filterOperatorBefore: t("-1", "is before"),
            filterOperatorOnOrBefore: t("-1", "is on or before"),
            filterOperatorIsEmpty: t("-1", "is empty"),
            filterOperatorIsNotEmpty: t("-1", "is not empty"),
            filterOperatorIsAnyOf: t("-1", "is any of"),
            'filterOperator=': '=',
            'filterOperator!=': '!=',
            'filterOperator>': '>',
            'filterOperator>=': '>=',
            'filterOperator<': '<',
            'filterOperator<=': '<=',

            // Header filter operators text
            headerFilterOperatorContains: t("-1", "Contains"),
            headerFilterOperatorEquals: t("-1", "Equals"),
            headerFilterOperatorStartsWith: t("-1", "Starts with"),
            headerFilterOperatorEndsWith: t("-1", "Ends with"),
            headerFilterOperatorIs: t("-1", "Is"),
            headerFilterOperatorNot: t("-1", "Is not"),
            headerFilterOperatorAfter: t("-1", "Is after"),
            headerFilterOperatorOnOrAfter: t("-1", "Is on or after"),
            headerFilterOperatorBefore: t("-1", "Is before"),
            headerFilterOperatorOnOrBefore: t("-1", "Is on or before"),
            headerFilterOperatorIsEmpty: t("-1", "Is empty"),
            headerFilterOperatorIsNotEmpty: t("-1", "Is not empty"),
            headerFilterOperatorIsAnyOf: t("-1", "Is any of"),
            'headerFilterOperator=': t("-1", "Equals"),
            'headerFilterOperator!=': t("-1", "Not equals"),
            'headerFilterOperator>': t("-1", "Greater than"),
            'headerFilterOperator>=': t("-1", "Greater than or equal to"),
            'headerFilterOperator<': t("-1", "Less than"),
            'headerFilterOperator<=': t("-1", "Less than or equal to"),

            // Filter values text
            filterValueAny: t("-1", "any"),
            filterValueTrue: t("-1", "true"),
            filterValueFalse: t("-1", "false"),

            // Column menu text
            columnMenuLabel: t("-1", "Menu"),
            columnMenuShowColumns: t("-1", "Show columns"),
            columnMenuManageColumns: t("-1", "Manage columns"),
            columnMenuFilter: t("-1", "Filter"),
            columnMenuHideColumn: t("-1", "Hide column"),
            columnMenuUnsort: t("-1", "Unsort"),
            columnMenuSortAsc: t("-1", "Sort by ASC"),
            columnMenuSortDesc: t("-1", "Sort by DESC"),

            // Column header text
            columnHeaderFiltersTooltipActive: (count) =>
                count !== 1 ? `${count} ${t("-1", "active filters")}` : `${count} ${t("-1", "active filter")}`,
            columnHeaderFiltersLabel: t("-1", "Show filters"),
            columnHeaderSortIconLabel: t("-1", "Sort"),

            // Rows selected footer text
            footerRowSelected: (count) =>
                count !== 1
                    ? `${count.toLocaleString()} ${t("-1", "rows selected")}`
                    : `${count.toLocaleString()} ${t("-1", "row selected")}`,

            // Total row amount footer text
            footerTotalRows: `${t("-1", "Total Rows")}:`,

            // Total visible row amount footer text
            footerTotalVisibleRows: (visibleCount, totalCount) =>
                `${visibleCount.toLocaleString()} ${t("-1", "of")} ${totalCount.toLocaleString()}`,

            // Checkbox selection text
            checkboxSelectionHeaderName: t("-1", "Checkbox selection"),
            checkboxSelectionSelectAllRows: t("-1", "Select all rows"),
            checkboxSelectionUnselectAllRows: t("-1", "Unselect all rows"),
            checkboxSelectionSelectRow: t("-1", "Select row"),
            checkboxSelectionUnselectRow: t("-1", "Unselect row"),

            // Boolean cell text
            booleanCellTrueLabel: t("-1", "yes"),
            booleanCellFalseLabel: t("-1", "no"),

            // Actions cell more text
            actionsCellMore: t("-1", "more"),

            // Column pinning text
            pinToLeft: t("-1", "Pin to left"),
            pinToRight: t("-1", "Pin to right"),
            unpin: t("-1", "Unpin"),

            // Tree Data
            treeDataGroupingHeaderName: t("-1", "Group"),
            treeDataExpand: t("-1", "see children"),
            treeDataCollapse: t("-1", "hide children"),

            // Grouping columns
            groupingColumnHeaderName: t("-1", "Group"),
            groupColumn: (name) => `${t("-1", "Group by")} ${name}`,
            unGroupColumn: (name) => ` ${t("-1", "Stop grouping by")} ${name}`,

            // Master/detail
            detailPanelToggle: t("-1", "Detail panel toggle"),
            expandDetailPanel: t("-1", "Expand"),
            collapseDetailPanel: t("-1", "Collapse"),

            // Used core components translation keys
            MuiTablePagination: {},

            // Row reordering text
            rowReorderingHeaderName: t("-1", "Row reordering"),

            // Aggregation
            aggregationMenuItemHeader: t("-1", "Aggregation"),
            aggregationFunctionLabelSum: t("-1", "sum"),
            aggregationFunctionLabelAvg: t("-1", "avg"),
            aggregationFunctionLabelMin: t("-1", "min"),
            aggregationFunctionLabelMax: t("-1", "max"),
            aggregationFunctionLabelSize: t("-1", "size"),
        };
    }, [t, siteLanguage]);

    const getSerialNumber = useCallback((obj) => {
        return String(obj.row.index);
    }, []);

    const getHeight = useCallback(() => {
        return rows.length === 0 || isLoading ? 250 : height;
    }, [rows, isLoading, height]);

    const memoizedRows = useMemo(() => {
        let iterator = 1;
        return rows.map(r => ({ ...r, index: iterator++ }));
    }, [rows]);

    const memoizedColumns = useMemo(() => (
        [
            ...(
                hasIterator ? [
                    {
                        field: 'id',
                        headerName: '#',
                        flex: 0,
                        headerClassName: className,
                        filterable: false,
                        renderCell: getSerialNumber,
                        valueGetter: getSerialNumber,
                        maxWidth: 40,
                    },
                ] : []
            ),
            ...columns
        ].map(r => r.align == undefined && !['actions', 'id'].includes(r.type) ? { ...r, align: 'left' } : r)
    ), [columns, hasIterator, getSerialNumber, className]);

    return (
        <div className='w-100' style={{ maxWidth: '100%', height: getHeight() }}>
            <DataGrid
                getRowId={getRowId}
                rows={memoizedRows}
                columns={memoizedColumns}
                initialState={{
                    pagination: { paginationModel: { pageSize: pageSize || 25, page: 0 } }, // Use pageSize prop, default to 25
                }}
                slots={{
                    toolbar: () => (
                        <GridToolbarContainer>
                            <GridToolbarColumnsButton sx={{ color: 'var(--bs-primary) !important' }} />
                            <GridToolbarFilterButton sx={{ color: 'var(--bs-primary) !important' }} />
                            <GridToolbarDensitySelector sx={{ color: 'var(--bs-primary) !important' }} />
                            {(hasExport === true || [1, 2, 3].includes(authData.role_id)) && <GridToolbarExport sx={{ color: 'var(--bs-primary) !important' }} />}
                            <GridToolbarQuickFilter sx={{ marginLeft: 'auto' }} />
                            {pagination === true && <GridPagination sx={{ marginLeft: 2 }} />}
                        </GridToolbarContainer>
                    ),
                    moreActionsIcon: () => <i className='fas fa-ellipsis-h fa-1-5x text-primary' />
                }}
                slotProps={{
                    toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 500 },
                    },
                }}
                disableRowSelectionOnClick
                loading={isLoading}
                getRowHeight={() => 'auto'}
                density='compact'
                pageSizeOptions={[15, 25, 50, 100]}
                localeText={GRID_DEFAULT_LOCALE_TEXT}
                sx={{
                    display: 'grid block',
                    gridTemplateRows: 'auto 1fr auto',
                    '& .MuiTablePagination-selectLabel, & .MuiTablePagination-displayedRows': {
                        marginBottom: 0
                    },
                    '& .MuiDataGrid-main': {
                        backgroundColor: '#fff'
                    },
                    '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '2px' },
                    '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '8px' },
                    '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '15px' },
                    '& .MuiMenu-list li': {
                        width: '100%',
                        justifyContent: 'center'
                    },
                    // Uncomment this for Issue (PVSIS-258) if font size change is required only otherwise remove it
                    // '&.MuiDataGrid-root': {
                    //     fontSize: '0.9rem',
                    //     lineHeight: 1.5
                    // },

                    // uncomment this when we want header column names proper showing in multi line
                    // '.MuiDataGrid-iconButtonContainer': {
                    //     visibility: 'visible',
                    //     width: 'auto'
                    // },
                    // '.MuiDataGrid-sortIcon': {
                    //     opacity: 'inherit !important',
                    // },
                    // "& .MuiDataGrid-columnHeaderTitle": {
                    //     textWrap: 'wrap',
                    //     lineHeight: 1.3,
                    //     whiteSpace: 'wrap'
                    // },
                    // '& .MuiDataGrid-columnHeaders': {
                    //     maxHeight: '80px !important' 
                    // },
                    // '& .MuiDataGrid-columnHeader': {
                    //     height: 'auto !important',
                    //     paddingTop: '7px',
                    //     paddingBottom: '7px',
                    // },
                    // "& .MuiDataGrid-columnHeaderTitle": {
                    //     whiteSpace: "normal",
                    //     lineHeight: "normal"
                    // },
                    ...sx
                }}
                disableColumnMenu={true}
                hideFooter={!pagination}
            />
        </div>
    );
}

DataTable.defaultProps = {
    getRowId: () => { },
    className: '',
    height: 'auto',
    rows: [],
    columns: [],
    isLoading: false,
    hasIterator: true,
    pagination: true,
    hasExport: false,
    sx: {}
};

export default DataTable;
