import { LIST_DETAIL_ELEMENTS } from 'constants';
import { displayNotification, getBearerToken, objectToFormData, sendAPIRequest } from 'helpers';
import React, { useEffect, useRef, useState } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from 'react-router-dom';
import usePopupAlert from 'hooks/usePopupAlert';


const UploadCoverLetter = ({ open, handleCloseModal, standardDetails, componentLanguage, setIsPDFProcessing }) => {

    const { t } = useTranslation();
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(false)
    const [fileName, setFileName] = useState("");
    const [file, setFile] = useState(null)
    const [saveButtonText, setSaveButtonText] = useState('Generate');
    const { showPopupAlert } = usePopupAlert();
    const [isProcessing, setIsProcessing] = useState(false);
    const initStructure = {
        animal_type: LIST_DETAIL_ELEMENTS.animal_type.terrestrial,
        standard_type: LIST_DETAIL_ELEMENTS.standard_type.codes,
        // language_id: siteLanguage === reFetch ? siteLanguage : reFetch,
        volume: {},
        section: {},
        chapter: {},
        sub_section: {},
        chapter_id: '',
        file: {
            currentFile: null,
            fileName: ""
        }
    };
    const [selectedStructures, setSelectedStructures] = useState(initStructure);
    const navigate = useNavigate();
    const browseFileRef = useRef();
    const fileUpload = () => {
        setFileName("");
        if (browseFileRef) {
            browseFileRef.current.click();
        } else {
            displayNotification("error", "Error opening file selection window.");
        }
    };

    const clearFile = () => {
        setSelectedStructures({ ...selectedStructures, file: { currentFile: null, fileName: "" } });
        setFileName("");
        browseFileRef.current.value = null;
        setFile(null);
    }


    const getFile = (files) => {
        const selectedFile = files[0];
        if (selectedFile) {
            setSelectedStructures({
                ...selectedStructures, file: {
                    currentFile: selectedFile,
                    fileName: selectedFile.name,
                }
            });
            setFileName(selectedFile.name);
            setFile(selectedFile);
        } else {
            setFileName("");
        }
    };
    const separateLinks = (input) => {
        const links = JSON.parse(input);
        const htmlLinks = links.filter(link => link.endsWith('.html'));
        const pngLinks = links.filter(link => link.endsWith('.png'));
        return {
            htmlLinks,
            pngLinks
        };
    };

    useEffect(() => {
        return () => {
            handleCloseModal();
        }
    }, [])

    const generatePDF = async () => {
        try {
            if (!file) {
                displayNotification('error', 'No file selected. Please select a file to proceed.');
                return;
            }
            const abortController = new AbortController();
            const fileBlob = new Blob([file], { type: file.type });
            const cover_letter_base64 = await new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(fileBlob);
                reader.onloadend = () => resolve(reader.result);
                reader.onerror = reject;
            })
            const formData = {
                animal_type: standardDetails.animal_type,
                lang_id: componentLanguage,
                standard_type: standardDetails.standard_type,
                versionYear: standardDetails.version_year,
                cover_letter: cover_letter_base64
            };
            setIsLoading(true);
            const pdfGenerationRequest = sendAPIRequest(
                '/api/generate-pdf/full-chapter-pdf-generate',
                'POST',
                objectToFormData(formData),
                {},
                abortController.signal
            );
            setTimeout(() => {
                abortController.abort();
                setIsLoading(false);
                setIsPDFProcessing(true)
                handleCloseModal();
                displayNotification('success', 'PDF generation process is initiated. You will receive your PDF shortly after refreshing.');
            }, 3000);
            await pdfGenerationRequest
        } catch (e) {
            if (e.name === 'AbortError') {
            } else {
                console.log({ e });
                displayNotification('error', 'An error occurred. Please try again.');
            }
            setIsLoading(false);
        }
    }


    return (
        <>
            <Modal show={open} size='lg' centered>
                <Modal.Header closeButton
                    className='p-4 fw-bold fs-3'
                    onClick={() => { handleCloseModal() }}
                >
                    Upload Cover Letter
                </Modal.Header>
                <Modal.Body className='p-4'>
                    {

                        <div className='d-flex justify-content-center'>
                            <div className="d-flex flex-column justify-content-center my-3">
                                <button
                                    type="button"
                                    className="btn btn--outline mb-2 fs-6"
                                    onClick={() => fileUpload()}
                                >
                                    <i class="fa-solid fa-cloud-arrow-up me-3 fs-5"></i>
                                    {t("37", "Browse File")}
                                </button>
                                <label>
                                    {fileName ? (
                                        <div>
                                            <strong>{t("-1", "Selected File")}: </strong>
                                            {fileName}
                                            <i class="fa-solid fa-xmark mx-3 cursor-pointer" onClick={() => clearFile()}></i>
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </label>
                                {/* <input
                                    ref={browseFileRef}
                                    type="file"
                                    id="get-file"
                                    style={{ display: "none" }}
                                    onChange={(e) => getFile(e.target.files)}
                                /> */}
                                <input
                                    ref={browseFileRef}
                                    type="file"
                                    id="get-file"
                                    style={{ display: "none" }}
                                    accept="image/*"
                                    onChange={(e) => getFile(e.target.files)}
                                />

                            </div>
                        </div>

                    }

                    {fileName && (<img className='img-fluid' src={URL.createObjectURL(file)} />)}


                </Modal.Body>
                <Modal.Footer className='p-4 d-flex justify-content-center'>
                    <div className='d-flex justify-content-end'>
                        <button
                            type='button'
                            className='btn btn--outline font-custom fs-6'
                            onClick={() => { handleCloseModal() }}
                        >
                            Close
                        </button>
                        <button
                            type='button'
                            className='btn btn--primary font-custom fs-6 ms-1'
                            // disabled={isLoading.saving_data}
                            onClick={() => { generatePDF() }}
                        >
                            {isLoading ? <Spinner message={saveButtonText} /> : saveButtonText}
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default UploadCoverLetter;