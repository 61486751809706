import { Accordion, AccordionDetails, AccordionSummary, FormControl, FormControlLabel, IconButton, Radio, RadioGroup } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentPage } from 'redux/generalSlice';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Icon from '@mui/material/Icon';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
const DraftingStandards = () => {

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const siteLanguage = useSelector(s => s.general.siteLanguageSont);
    const authData = useSelector(s => s.auth.data);
    const [section1Expanded, setSection1Expanded] = useState(false);
    const [selectionTerrestrial, setSelectionTerrestrial] = useState('terrestrial-code');
    const [selectionAquatic, setSelectionAquatic] = useState('aquatic-code');
    const [section2Expanded, setSection2Expanded] = useState(false);
    const [chapter1Expanded, setChapter1Expanded] = useState(false);
    const [chapter2Expanded, setChapter2Expanded] = useState(false);
    const [language, setLanguage] = useState('english');
    useEffect(() => {
        dispatch(setCurrentPage('update-publish'));
    }, []);
    const handleAccordion = () => {
        setSection1Expanded(!section1Expanded);
    }

    const handleAccordion2 = () => {
        setSection2Expanded(!section2Expanded);
    }

    const handleChapter = () => {
        setChapter1Expanded(!chapter1Expanded);
    }

    const handleChapter2 = () => {
        setChapter2Expanded(!chapter2Expanded);
    }

    const handleChange = (e) => {
        setSelectionTerrestrial(e.target.value);
    }

    const handleChange2 = (e) => {
        setSelectionAquatic(e.target.value);
    }

    const handleLanguage =(e)=>{
        setLanguage(e.target.value);
    }
    return (
        <>
            <div className="container my-2">
                <div className="header card-woah border border-secondary d-flex px-4 py-2 shadow-sm w-100 my-2">
                    <div className='d-flex justify-content-between w-100'>
                        <div className="card-title1 lh-1">Drafting Standards</div>
                    </div>
                </div>
                <div className="header card-woah border border-secondary px-4 py-2 shadow-sm w-100 my-2">
                    <div className="row py-3 d-flex">
                        <div className="col-md-4">
                            <div className="card-woah border border-dark px-4 pt-3 w-100 rounded rounded-2 bg-great-light">
                                <FormControl>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        className="text-center d-flex justify-content-between"
                                        onChange={handleChange}
                                        value={selectionTerrestrial}
                                    >
                                        <FormControlLabel
                                            className="px-5 mb-2"
                                            value="terrestrial-code"
                                            control={<Radio />}
                                            label={t("28", "Terrestrial Code​")}
                                        />
                                        <FormControlLabel
                                            className="px-5 mb-2"
                                            value="terrestrial-manual"
                                            control={<Radio />}
                                            label={t("29", "Terrestrial Manual​")}

                                        />



                                    </RadioGroup>

                                </FormControl>

                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card-woah border border-dark px-4 pt-3 w-100 rounded rounded-2 bg-great-light">
                                <FormControl>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        className="text-center d-flex justify-content-between"
                                        onChange={handleChange2}
                                        value={selectionAquatic}
                                    >

                                        <FormControlLabel
                                            className="px-5 mb-2"
                                            value="aquatic-code"
                                            control={<Radio />}
                                            label={t("30", "Aquatic Code​")}
                                        />
                                        <FormControlLabel
                                            className="px-5 mb-2"
                                            value="aquatic-manual"
                                            control={<Radio />}
                                            label={t("31", "Aquatic Manual​")}
                                        // disabled
                                        />
                                    </RadioGroup>

                                </FormControl>

                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card-woah border border-dark px-4 pt-3 w-100 text-center rounded rounded-2 bg-great-light">
                                <FormControl>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        className="text-center d-flex justify-content-between"
                                        onChange={handleLanguage}
                                        value={language}
                                    >

                                        <FormControlLabel
                                            className="px-5 mb-2"
                                            value="english"
                                            control={<Radio />}
                                            label={t("-1", "EN​")}

                                        />
                                        <FormControlLabel
                                            className="px-5 mb-2"
                                            value="french"
                                            control={<Radio />}
                                            label={t("-1", "FR​")}
                                        />
                                        <FormControlLabel
                                            className="px-5 mb-2"
                                            value="spanish"
                                            control={<Radio />}
                                            label={t("-1", "ES​")}
                                        />
                                    </RadioGroup>

                                </FormControl>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="header card-woah border border-secondary px-4 py-2 shadow-sm w-100 my-2">
                    <div className="row">
                        <div className="col-md-12">

                            <Accordion onClick={handleAccordion}>
                                <AccordionSummary
                                    sx={{ background: '#FFF1CE' }}
                                    expandIcon={
                                        <IconButton
                                            onClick={handleAccordion}
                                            aria-expanded={section1Expanded}
                                            aria-label="toggle accordion"
                                        >
                                            {section1Expanded ? (
                                                <RemoveCircleIcon sx={{ color: '#FF7954' }} />
                                            ) : (
                                                <AddCircleIcon sx={{ color: '#FF7954' }} />
                                            )}
                                        </IconButton>
                                    }
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                    className="fs-4"
                                >
                                    Section 1
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Accordion onClick={(e) => {
                                        handleChapter();
                                        e.stopPropagation();
                                    }}>
                                        <AccordionSummary
                                            sx={{ background: '#FFF1CE' }}
                                            expandIcon={
                                                <IconButton
                                                    onClick={handleChapter}
                                                    aria-expanded={chapter1Expanded}
                                                    aria-label="toggle accordion"
                                                >
                                                    {chapter1Expanded ? (
                                                        <RemoveCircleIcon sx={{ color: '#FF7954' }} />

                                                    ) : (
                                                        <AddCircleIcon sx={{ color: '#FF7954' }} />
                                                    )}
                                                </IconButton>
                                            }
                                            aria-controls="panel1-content"
                                            id="panel1-header"
                                            className="fs-4"
                                        >
                                            Chapter 1
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe, consequuntur?
                                        </AccordionDetails>
                                    </Accordion>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion
                                onClick={handleAccordion2}
                            >
                                <AccordionSummary
                                    sx={{ background: '#FFF1CE' }}
                                    expandIcon={
                                        <IconButton
                                            aria-expanded={section2Expanded}
                                            aria-label="toggle accordion"
                                            // onClick={(e) => {
                                            //     e.stopPropagation(); 
                                            //     handleAccordion2();
                                            // }}
                                            onClick={handleAccordion2}
                                            className="fs-4"

                                        >
                                            {section2Expanded ?
                                                (<RemoveCircleIcon sx={{ color: '#FF7954' }} />)
                                                :
                                                (<AddCircleIcon sx={{ color: '#FF7954' }} />)
                                            }
                                        </IconButton>}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                    className="fs-4"
                                >
                                    Section 2
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Accordion onClick={(e) => {
                                        handleChapter2();
                                        e.stopPropagation();
                                    }}>
                                        <AccordionSummary
                                            sx={{ background: '#FFF1CE' }}
                                            expandIcon={
                                                <IconButton
                                                    onClick={handleChapter2}
                                                    aria-expanded={chapter2Expanded}
                                                    aria-label="toggle accordion"
                                                >
                                                    {chapter2Expanded ? (
                                                        <RemoveCircleIcon sx={{ color: '#FF7954' }} />

                                                    ) : (
                                                        <AddCircleIcon sx={{ color: '#FF7954' }} />
                                                    )}
                                                </IconButton>
                                            }
                                            aria-controls="panel1-content"
                                            id="panel1-header"
                                            className="fs-4"
                                        >
                                            Chapter 2
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe, consequuntur?
                                        </AccordionDetails>
                                    </Accordion>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion
                           >
                                <AccordionSummary
                                    sx={{ background: '#FFF1CE' }}>
                                    <div className="col-md-6 p-2 fs-4 d-flex justify-content-between">
                                        New Section
                                    </div>
                                    <div className="col-md-6 fs-4 d-flex pt-2 justify-content-end">
                                        <span className="cursor-pointer text-primary">Edit</span>
                                        <span className="cursor-pointer text-primary ms-3">Delete</span>
                                    </div>

                                </AccordionSummary>

                            </Accordion>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default DraftingStandards
