import React from 'react';
import { Modal } from 'react-bootstrap';
import Button from './Button';
import PropTypes from 'prop-types';
import HtmlRenderer from 'components/HtmlRenderer';

const PopupAlert = ({ show, title, body, handleClose, footer, variant }) => {

    const variants = {
        primary: 'bg-primary',
        secondary: 'bg-secondary',
        success: 'bg-success',
        danger: 'bg-danger',
        error: 'bg-danger',
        warning: 'bg-warning',
        info: 'bg-info',
        light: 'bg-light',
        dark: 'bg-dark',
        link: 'bg-link',
    };
    const titleClass = `${ !['light', 'warning', 'info'].includes(variant) ? 'text-white' : ''}`;
    const buttonVariant = variant;

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton className={variants[variant]}>
                <Modal.Title className={titleClass}>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <HtmlRenderer
                    htmlContent={body}
                    crawling={false}
                />
            </Modal.Body>
            <Modal.Footer>
                {footer}
                <Button variant={buttonVariant} onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

PopupAlert.propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    variant: PropTypes.string,
    body: PropTypes.node,
    footer: PropTypes.node
};

PopupAlert.defaultProps = {
    show: false,
    onClose: () => {},
    title: 'Title',
    body: <></>,
    footer: <></>,
    variant: 'success',
};

export default PopupAlert;