import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { CircularProgress, Divider, Grid, IconButton, Tooltip, Typography, styled, tooltipClasses } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Articles from 'pages/frontend/pages-component/Articles';
import { AQUATIC_MANUAL_VOLUMES_AGAINST_AREA_NUMBER, CODE_VOLUMES_AGAINST_AREA_NUMBER, CONTENT_PDF_DOWNLOAD_API, CONTENT_PDF_GENERATE_API, LIST_DETAIL_ELEMENTS, TERRESTRIAL_MANUAL_VOLUMES_AGAINST_AREA_NUMBER } from 'constants';
import { displayNotification, getBaseURL, sendAPIRequest } from 'helpers';
import Container from 'components/common/Container';
import PrintIcon from '@mui/icons-material/Print';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import CustomCard from 'components/common/CustomCard';
import StructureNavigationComponent from '../StructureNavigationComponent';
import useLocalStorage from "hooks/useLocalStorage";
import { document } from 'postcss';
import CardHeader from 'components/common/CardHeader';
import CardBody from 'components/common/CardBody';
import { setCurrentPage } from 'redux/generalSlice';

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#ffece8',
        color: '#ff4815',
        boxShadow: theme.shadows[1],
        fontSize: 12,
        padding: '13px'
    },
}));
const ContentReview = () => {
    const { t } = useTranslation();
    const siteLanguage = useSelector(s => s.general.siteLanguageSont);
    const [componentLanguage, setComponentLanguage] = useState(siteLanguage);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [section, setSelectedSection] = useState({
        value: '',
        label: ''
    });
    const [chapter, setSelectedChapter] = useState({
        value: '',
        label: ''
    });
    const [sections, setSections] = useState([]);
    const [codesSectionsGroups, setCodesSectionsGroups] = useState({});
    const [sectionID, setSectionID] = useState('');
    const [is_clicked, setIsClicked] = useState(false);
    const [availableChapters, setAvailableChapters] = useState([]);
    const isSectionVisible = useSelector(s => s.expand.isSection);
    const isSectionSecVisible = useSelector(s => s.expand.isSectionSec);
    const [selectedOption, setSelectedOption] = useState('terrestrial-code');
    const [baseFontSize, setBaseFontSize] = useState(16);
    const [isLoading, setIsLoading] = useState({
        loading_filters: false,
        loading_data: false,
        load_chapters: false,
        load_sections: false,
    });
    const [generatingPDF, setGeneratingPDF] = useState(false);
    const [disableChapterField, setDisableChapterField] = useState(false);
    const currentDate = new Date().toISOString().slice(0, 10);
    const [selectedRadio, setSelectedRadio] = useState({ animal_type: LIST_DETAIL_ELEMENTS.animal_type.terrestrial, standard: LIST_DETAIL_ELEMENTS.standard_type.codes });
    const [currentVolumeGroup, setCurrentVolumeGroup] = useState(CODE_VOLUMES_AGAINST_AREA_NUMBER[102]);
    const [metaData, setMetaData] = useState(null);
    const [reFetch, setRefetch] = useState(false);
    const [documentMetaData, setDocumentMetaData] = useState({
        section_name: '',
        chapter_name: '',
        chapter_no: '',
        section_no: "",
        area_vol_no: ''
    });
    const { setItem } = useLocalStorage('metadata');
    useEffect(() => {
        setDocumentMetaData(prevState => ({
            ...prevState,
            section_name: metaData?.chapter?.section_name,
            chapter_name: metaData?.chapter?.chapter_name,
            chapter_no: metaData?.chapter?.chapter_no,
            section_no: metaData?.chapter?.section_no,
            area_vol_no: metaData?.standard_type === LIST_DETAIL_ELEMENTS.standard_type.manuals ? metaData?.chapter?.area_vol_no : null,
        }));
    }, [metaData]);
    useEffect(() => {
        setItem(documentMetaData);
    }, [documentMetaData]);

    useEffect(() => {
        dispatch(setCurrentPage('display-navigation'));
        setSelectedOption('terrestrial-code');
        loadSections();
    }, []);
    useEffect(() => {
        setAvailableChapters([]);
        setSelectedChapter({ value: '', label: '' });
        setDisableChapterField(false);
        loadSections();
        loadChapters();
        setVolume();
    }, [selectedOption]);

    useEffect(() => {
        if (section.value) {
            try {
                loadChapters();
            } catch (error) {
                console.error('Error loading chapters:', error);
            }
        }
        else {
            setAvailableChapters([]);
            setSelectedChapter({ value: '', label: '' });
        }
    }, [section.value]);


    const setVolume = () => {
        if (selectedOption === 'terrestrial-manual') {
            setCurrentVolumeGroup(TERRESTRIAL_MANUAL_VOLUMES_AGAINST_AREA_NUMBER[parseInt(componentLanguage)]);
        } else if (selectedOption === 'aquatic-manual') {
            setCurrentVolumeGroup(AQUATIC_MANUAL_VOLUMES_AGAINST_AREA_NUMBER[parseInt(componentLanguage)]);
        } else {
            setCurrentVolumeGroup(CODE_VOLUMES_AGAINST_AREA_NUMBER[parseInt(componentLanguage)]);
        }
    }

    const setCodesSectionsGroupsList = (sect = []) => {
        let obj = {};
        sect.forEach(section => {
            obj[section.id] = section.display_name;
        });
        setCodesSectionsGroups(obj);
    }


    const modifyData = (data, modiferText) => {
        let temp = [];
        data.forEach(d => {
            if (d.ref_number === 0) {
                d.children && d.children.length > 0 && d.children.forEach(child => {
                    temp.push({ ...child, standard_content: `${child.standard_content}`, RID: child.standards_structures_revised.RID });
                });
            } else {
                temp.push(d);
            }
        });
        return temp;
    }

    const loadSections = async () => {
        setIsLoading({ ...isLoading, load_sections: true });
        try {
            const ret = await sendAPIRequest(`/api/contents/generic-sections/?language_id=${siteLanguage}&animal_type=${selectedRadio.animal_type}&standard_type=${selectedRadio.standard}`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                const sect = modifyData(res.data, 'Section');
                setSections(sect);
                setCodesSectionsGroupsList(res.data);
                setIsLoading({ ...isLoading, load_sections: false });
                return sect;
            }
        } catch (e) {
            console.log({ e });
            setIsLoading({ ...isLoading, load_sections: false });
        }
    }

    const loadChapters = async (id = null) => {
        setIsLoading({ ...isLoading, load_chapters: true });
        try {
            const ret = await sendAPIRequest(`/api/contents/generic-chapters/?language_id=${siteLanguage}&section_id=${id ? id : sectionID ? sectionID : ''}&animal_type=${selectedRadio.animal_type}&standard_type=${selectedRadio.standard}`)
            const res = await ret.json();
            if (res.success && res.status === 200) {
                for (let ele of res.data) {
                    ele.standard_content = removeHtmlTags(ele.standard_content)
                }
                setAvailableChapters(res.data);
                setIsLoading({ ...isLoading, load_chapters: false });
                return res.data;
            }
        } catch (e) {
            console.log({ e });
        }
        setIsLoading({ ...isLoading, load_chapters: false });
    }

    const changeComponentLanguage = async (lang) => {
        setMetaData({ ...metaData, language_id: lang });
        setRefetch(!reFetch)
        setComponentLanguage(lang);
    }

    const increaseFontSize = () => {
        if (baseFontSize < 24) {
            setBaseFontSize(prevSize => prevSize + 2);
        }
    };

    const decreaseFontSize = () => {
        if (baseFontSize > 12) {
            setBaseFontSize(prevSize => prevSize - 2);
        }
    };

    const sendDataToBackend = async () => {
        setGeneratingPDF(true);
        try {
            displayNotification('info', 'Generating PDF please wait...');
            const fileName = `WOAH_Report_${currentDate}.pdf`;
            const ret = await fetch(`${CONTENT_PDF_GENERATE_API}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    chapter: metaData.chapter.RID,
                    standard_type: metaData.standard_type,
                    animal_type: metaData.animal_type,
                    componentLanguage: metaData?.language_id,
                    volumeNo: metaData.standard_type === LIST_DETAIL_ELEMENTS.standard_type.manuals ? metaData?.chapter?.area_vol_no : null,

                })
            });
            const data = await ret.json();
            if (data.success && data.status === 200) {
                const downloadResponse = await fetch(`${CONTENT_PDF_DOWNLOAD_API}?fileName=${fileName}&uniqueName=${data.data.file_name}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                if (downloadResponse.ok) {
                    const blob = await downloadResponse.blob();
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = fileName ?? 'testing.pdf';
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                    window.URL.revokeObjectURL(url);
                    displayNotification('success', 'Successfully downloaded file');
                } else {
                    displayNotification('error', 'Error downloading file');
                }
            } else {
                displayNotification('error', 'Error creating PDF');
            }
        } catch (error) {
            console.error('Error sending data:', error);
        }
        setGeneratingPDF(false);
    };


    const sharePdf = async () => {
        if (metaData?.chapter.id) {
            await sendDataToBackendShare()
        }
        else {
            displayNotification('info', 'Please select content for pdf');
        }
    }

    const generatePdf = async () => {
        if (metaData?.chapter.id) {
            await sendDataToBackend()
        }
        else {
            displayNotification('info', 'Please select content for pdf');
        }
    }

    const sendDataToBackendShare = async () => {
        try {
            displayNotification('info', 'Generating Shareable PDF link please wait...');
            const fileName = `WOAH_Report_${currentDate}.pdf`;
            const ret = await fetch(`${CONTENT_PDF_GENERATE_API}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    chapter: metaData.chapter.RID,
                    standard_type: metaData.standard_type,
                    animal_type: metaData.animal_type,
                    componentLanguage: metaData?.language_id,
                    volumeNo: metaData?.chapter?.area_vol_no,
                    volumeNo: metaData.standard_type === LIST_DETAIL_ELEMENTS.standard_type.manuals ? metaData?.chapter?.area_vol_no : null,

                })
            });
            const data = await ret.json();
            if (data.success && data.status === 200) {
                const uniqueName = data.data.file_name;
                const subject = encodeURIComponent("PDF Report");
                const body = encodeURIComponent(`Here is the link to the PDF report: ${CONTENT_PDF_DOWNLOAD_API}/?uniqueName=${uniqueName}&fileName=${fileName}`);

                window.open(`mailto:?subject=${subject}&body=${body}`);
                displayNotification('success', 'Successfully generated shareable link');
            } else {
                displayNotification('error', 'Error creating PDF');
            }
        } catch (error) {
            console.error('Error sending data:', error);
        }
    };

    const handlePrint = () => {
        if (metaData.chapter.RID && metaData.language_id) {
            const file_name = `WOAH_Report_${currentDate}.pdf`;
            const url = getBaseURL(`/portal/content-navigation/print?RId=${metaData?.chapter.RID}&language_id=${metaData?.language_id}&fileName=${file_name}`);
            window.open(url, "self");
        } else {
            displayNotification('warning', `${t("-1", "No content available for  print")}`);
        }
    };

    function removeHtmlTags(input) {
        return input.replace(/<[^>]+>/g, '');
    }



    return (
        <>
            <Container>
                <CustomCard className={'d-flex justify-content-between'}>
                    <CardHeader>
                        {t("45", "Content Review")}
                    </CardHeader>
                    <CardBody>
                        {/* <Tooltip title="Expand/Collapse Section" placement="top">
                                    <i className={`fas ${isStandardVisible ? 'fa-chevron-down' : 'fa-chevron-up'} text-primary fs-2 ps-2`} onClick={() => { setIsSetandardVisible(prev => !prev) }}></i>
                                </Tooltip> */}
                    </CardBody>
                </CustomCard>
                <StructureNavigationComponent
                    config={{
                        is_display_header: false,
                        is_display_structures: true,
                        is_display_area: false,
                        is_display_sections: true,
                        is_display_chapters: true,
                        is_shown_broweFile_button: false,
                        is_download_pdf: true,
                        checkForPermissions: false,
                        is_display_languages: false,
                        is_display_font_changer: false,
                        is_display_pdf_component: true,
                        is_display_chapter_name: true,
                        is_display_standard_heading: true,
                        is_display_completed_standard_pdf: true,
                        in_review: true,
                    }}
                    reFetch={componentLanguage}
                    chilldCheck={true}
                    reloadData={(state) => setMetaData(state)}
                />
                <CustomCard sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 0 }}>
                    <Grid container>
                        <Grid item lg={8} md={8} sm={8} xs={12}>
                            <Box className='chapter-font-custom'>
                                {
                                    selectedOption === "terrestrial-manual" ? `${chapter.label ?? ''}`
                                        :
                                        chapter.label
                                            ? `${chapter.label ?? ""}`
                                            : null
                                }
                            </Box>
                        </Grid>
                        <Grid item lg={4} md={4} sm={4} xs={12}>
                            <Box sx={{ display: 'flex', justifyItems: 'end', justifyContent: 'end', alignItems: 'center' }}>
                                <Typography
                                    variant="button"
                                    sx={{
                                        cursor: 'pointer',
                                        marginX: '5px',
                                        textDecoration: parseInt(componentLanguage) === LIST_DETAIL_ELEMENTS.languages.english ? 'underline' : 'none',
                                    }}
                                    onClick={() => (changeComponentLanguage(LIST_DETAIL_ELEMENTS.languages.english))}
                                >
                                    EN
                                </Typography>
                                <Typography
                                    variant="button"
                                    sx={{
                                        cursor: 'pointer',
                                        marginX: '5px',
                                        textDecoration: parseInt(componentLanguage) === LIST_DETAIL_ELEMENTS.languages.french ? 'underline' : 'none',
                                    }}
                                    onClick={() => (changeComponentLanguage(LIST_DETAIL_ELEMENTS.languages.french))}
                                >
                                    FR
                                </Typography>
                                <Typography
                                    variant="button"
                                    sx={{
                                        cursor: 'pointer',
                                        textDecoration: parseInt(componentLanguage) === LIST_DETAIL_ELEMENTS.languages.spanish ? 'underline' : 'none',
                                        paddingRight: 1
                                    }}
                                    onClick={() => (changeComponentLanguage(LIST_DETAIL_ELEMENTS.languages.spanish))}
                                >
                                    ES
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </CustomCard>
                {
                    metaData && metaData.chapter && metaData.chapter.RID && metaData.standard_type === LIST_DETAIL_ELEMENTS.standard_type.codes && (
                        <Articles
                            componentLanguage={metaData?.language_id}
                            currentChapter={metaData?.chapter}
                            chapter={metaData?.chapter.id}
                            section={metaData?.section}
                            fontSize={baseFontSize}
                            is_clicked={is_clicked}
                            isCode={true}
                            volume_no={metaData?.chapter?.area_vol_no}
                            selectedOption={selectedOption}
                            metaData={metaData}
                            is_review={true}
                            showDownloadHtml={false}
                            showDownloadWord={false}
                            in_review= {true}
                        />
                    )
                }
                {
                    metaData && metaData.chapter && metaData.chapter.RID && metaData.standard_type === LIST_DETAIL_ELEMENTS.standard_type.manuals && (
                        <Articles
                            componentLanguage={metaData?.language_id}
                            currentChapter={metaData?.chapter}
                            chapter={metaData?.chapter.id}
                            section={metaData?.section}
                            fontSize={baseFontSize}
                            is_clicked={is_clicked}
                            isCode={false}
                            volume_no={metaData?.chapter?.area_vol_no}
                            selectedOption={selectedOption}
                            metaData={metaData}
                            is_review={true}
                            showDownloadHtml={false}
                            showDownloadWord={false}
                            in_review= {true}
                        />
                    )
                }

            </Container>
        </>
    )
}

export default ContentReview
