import React from 'react';
import { useLocation } from 'react-router-dom';

const IsExpanded = () => {
    const location = useLocation();
    const refContent = location.state?.refContent;

    return (
        <div>
            <div className="container">
                <div className="row">
                    <div className="col-12" dangerouslySetInnerHTML={{ __html: refContent }} />
                </div>
            </div>
        </div>
    );
};

export default IsExpanded;
