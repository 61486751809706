import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import TextFieldCustom from 'components/TextFieldCustom';
import { useEffect } from 'react';
import Container from 'components/common/Container';

const AddChapterModalNew = ({ open, handleClose, handleSave, edit, formData, setFormData, editChapterCall }) => {

    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);


    const handleClick = async () => {
        const confirmMessage = edit
            ? 'Are you sure you want to rename this chapter?'
            : 'Are you sure you want to add this chapter?';

        if (!window.confirm(confirmMessage)) {
            return;
        }
        setIsLoading(true);
        try {
            if (edit) {
                await editChapterCall(); 
            } else {
                await handleSave(formData); 
            }
            handleClose();
        } catch (error) {
            console.error('Error during save/update:', error);
        } finally {
            setIsLoading(false);
        }
    };


    return (
        <>
            <Modal
                show={open} size='md' centered={true}
            >
                <Modal.Body className='p-4'>
                    <Container>
                        <Typography id="modal-modal-title" variant="h6" component="h2"
                            className="text-center"
                        >{t("-1", edit ? 'Edit Chapter' : "Add New Chapter")}
                        </Typography>
                        {edit ? null : <div className='col-12 mb-3 fs-4 '>
                            <span className='fw-bold pe-2'>{t("-1", "Chapter No")}</span>
                            <TextFieldCustom
                                variant="outlined"
                                id="Chapter"
                                type="number"
                                fullWidth
                                rows={4}
                                required={true}
                                sx={{
                                    backgroundColor: "white",
                                    color: "white",
                                    border: "#ff4815",
                                    width: "99%",
                                }}
                                size="small"
                                value={formData.chapter_no}
                                onChange={(e) => {
                                    const value = parseInt(e.target.value, 10);
                                    if (!isNaN(value) && value >= 0) {
                                        setFormData({ ...formData, chapter_no: value });
                                    } else {
                                        setFormData({ ...formData, chapter_no: 0 });
                                    }
                                }}
                            />
                        </div>}

                        <div className='col-12 mb-3 fs-4 '>
                            <span className='fw-bold pe-2'>{t("-1", "Chapter Name")}</span>
                            <TextFieldCustom
                                variant="outlined"
                                id="Chapter"
                                fullWidth
                                rows={4}
                                required={true}
                                sx={{
                                    backgroundColor: "white",
                                    color: "white",
                                    border: "#ff4815",
                                    width: "99%",
                                }}
                                size='large'
                                multiline
                                value={formData.chapter_name}
                                onChange={(e) => setFormData({ ...formData, chapter_name: e.target.value })}
                            />
                        </div>
                    </Container>

                </Modal.Body>
                <Modal.Footer className='p-4 d-flex justify-content-center'>
                    <button
                        className='btn btn--outline font-custom fs-6'
                        onClick={() => { handleClose() }}
                    >
                        {t("201", "Close")}
                    </button>
                    <button
                        className="btn btn--primary font-custom fs-6"
                        onClick={() => { handleClick() }}
                        disabled={isLoading}
                    >
                        {isLoading ? t("-1", "Saving...") : t("-1", "Save")}
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default AddChapterModalNew;
