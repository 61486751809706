import { RouterProvider } from "react-router-dom";
import { getRouter } from './router';
import React, { useEffect, useState, Suspense } from 'react'
import { sendAPIRequest } from './helpers';
import { LogLevel, PublicClientApplication, EventType, AuthError } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import i18n from './i18next';
import { useSelector } from 'react-redux';
import SiteLoader from './pages/components/SiteLoader';
import { ThemeProvider } from "@mui/material";
import { muiTheme } from "./theme";

function App() {
	const siteLanguage = useSelector(s => s.general.siteLanguageSont);
	const user = useSelector(s => s.auth.data);
	const url = process.env.REACT_APP_AZUREAD_URL;

	const [loading, setLoading] = useState(true);
	const authorityUrl = 'https://login.microsoftonline.com/';
	const [config, setConfig] = useState({
		auth: {
			authority: '',
			clientId: '',
			postLogoutRedirectUri: url,
			redirectUri: url,
			validateAuthority: true,
			navigateToLoginRequestUrl: true
		},
		cache: {
			cacheLocation: "localStorage",
			storeAuthStateInCookie: true
		},
		system: {
			allowNativeBroker: false,
			loggerOptions: {
				loggerCallback: (level, message, containsPii) => {
					if (!containsPii) {
						switch (level) {
							case LogLevel.Error:
								console.error(message);
								break;
							case LogLevel.Warning:
								console.warn(message);
								break;
							// Add other cases if needed
							default:
								break;
						}
					}
				},
			},
		},
	});

	useEffect(() => {
		i18n.changeLanguage(siteLanguage);
		loadKeys();
	}, []);

	const loadKeys = async () => {
		try {
			const req = await sendAPIRequest("/api/devs/config/ad");
			const res = await req.json();
			if (res.success) {
				setConfig({
					...config,
					auth: {
						...config.auth,
						authority: `${authorityUrl}${res.data.tenant_id}`,
						clientId: res.data.ad_app_id
					}
				});
			} else {
				console.error('Failed to load configuration');
			}
			setLoading(false);
		} catch (e) {
			console.error('Error loading configuration', e);
			setLoading(false);
		}
	};

	let msalInstance;

	if (!loading) {
		msalInstance = new PublicClientApplication(config);
		if (msalInstance.getAllAccounts().length > 0) {
			msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
		}
		msalInstance.enableAccountStorageEvents();
		msalInstance.addEventCallback(event => {
			if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
				msalInstance.setActiveAccount(event.payload.account);
			}
			if (event.eventType === EventType.LOGIN_FAILURE && event.error instanceof AuthError) {
				console.error("Azure AD Login Issue");
			}
		});
	}

	return (
		<ThemeProvider theme={muiTheme}>
			<Suspense fallback={<SiteLoader />}>
				{
					!loading && msalInstance ? (
						<MsalProvider instance={msalInstance}>
							<RouterProvider router={getRouter(user)} />
						</MsalProvider>
					) : (
						<SiteLoader />
					)
				}
			</Suspense>
		</ThemeProvider>
	);
}

export default App;
