import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    isExpanded:true,
    isExpand:true,
    isSection: true,
    isSectionSec: true,
  
    };

export const expandSlice = createSlice({
 name: 'expand',
 initialState,
  reducers: {
   isExpandedState(state,action){
    state.isExpanded =action.payload
   },
   isExpandState(state,action){
    state.isExpand = action.payload;
   },
   isSectionState(state, action){
    state.isSection = action.payload;
   },
   isSectionSecState(state, action){
    state.isSectionSec = action.payload;
   },
  
   
  },
})

export const { isExpandState, isSectionSecState,isExpandedState, isSectionState } = expandSlice.actions

export default expandSlice.reducer