import { createBrowserRouter } from "react-router-dom";
import { resetCurrentPage } from "./redux/store";
import React from "react";
import { reactLazyImport } from "./helpers";
import FrontendLayout from "./pages/frontend/FrontendLayout";
import PageError from "./pages/PageError";
import Page404 from "./pages/Page404";
import Contactus from "pages/frontend/components/pages/Contactus";
import ForgotPassword from "pages/frontend/components/auth-pages/ForgotPassword";
import ResetPassword from "pages/frontend/components/auth-pages/ResetPassword";
import AboutPage from "pages/frontend/components/pages/AboutPage";
import ContentNavigation from "pages/frontend/components/internal-pages/ContentNavigation";
import DraftChapterPreviewPrint from "pages/frontend/components/internal-pages/DraftChapterPreviewPrint";
// import Draft from "pages/frontend/components/pages/DraftManagement/Draft";
import SearchContent from "pages/frontend/components/internal-pages/SearchContent";
import CustomTabs from "pages/frontend/components/pages/CustomTabs";
import NavigateStandards from "pages/frontend/components/sub-navigation/NavigateStandards";
import SearchCommodities from "pages/frontend/components/sub-navigation/SearchCommodities";
import Administration from "pages/frontend/components/internal-pages/Administration";
import StandardsUpdateAndPublishing from "pages/frontend/components/internal-pages/StandardsUpdateAndPublishing";
import MainHomePage from "pages/frontend/components/pages/MainHomePage";
import DraftingStandards from "pages/frontend/components/author-pages-components/DraftingStandards";
import ImportFromWord from "pages/frontend/components/author-pages-components/ImportFromWord";
import AddUpdateContentSearch from "pages/frontend/components/author-pages-components/AddUpdateContentSearch";
import PlatformAdministration from "pages/frontend/components/internal-pages/PlatformAdministration";
import BusinessAdministration from "pages/frontend/components/internal-pages/BusinessAdministrator";
import SuperAdmin from "pages/frontend/components/internal-pages/SuperAdmin";
//TODO: To be removed
import ChapterData from "pages/frontend/components/chapters/ChapterData";
import PDFComponent from "pages/frontend/pages-component/PDFComponent";
import PdfList from "pages/frontend/pages-component/PdfList";
import ContentNavigationPrint from "pages/frontend/pages-component/ContentNavigationPrint";
import ManualPrint from "pages/frontend/pages-component/ManualPrint";
import PdfDetails from "pages/frontend/pages-component/PdfDetails";
import DataMapping from "components/mapping/DataMapping";
import Index from "pages/backend/multilingual-keywords/Index";
import Form from "pages/backend/multilingual-keywords/Form";
import HTMLDataMapping from "components/mapping/HTMLDataMapping";
import HTMLChapterData from "pages/frontend/components/chapters/HTMLChapterData";
import ImageUpload from "components/file_uploads/ImageUpload";
import ImagesView from "pages/frontend/components/image-pages/ImagesView";
import ImageMapping from "pages/frontend/components/image-pages/ImageMapping";
import IsExpanded from "pages/frontend/pages-component/IsExpanded";
import AccessToCommoditiesV3 from "pages/frontend/components/internal-pages/AccessToCommoditiesV3";
import UploadDocuments from "components/file_uploads/UploadDocuments";
import DataManualMapping from "components/mapping/DataManualMapping";
import HtmlManualData from "pages/frontend/components/chapters/HtmlManualData";
import CommoditiesDatabase from "pages/frontend/components/pages/CommoditiesDatabase";
import Taging from "pages/frontend/components/pages/Taging/Index";
import EditTaging from "pages/frontend/components/pages/Taging/EditTaging";
import CreateTaging from "pages/frontend/components/pages/Taging/CreateTaging";
import CommoditiesDatabaseForm from "pages/frontend/components/pages/commodities-database/CommoditiesDatabaseForm";
import EditContent from "pages/frontend/pages-component/EidtContent";
import StyleClasses from "components/styles_components/StyleClasses";
import HrefMapping from "components/HrefMapping";
import SystemStatus from "pages/frontend/components/pages/SystemStatus";
import MyEditor from "components/text_editor/MyEditor";
// import DraftPreview from "pages/frontend/components/internal-pages/DraftPreview";
import DynamicFiltersList from "pages/frontend/components/internal-pages/dynamic_filters/DynamicFiltersList";
import DynamicFiltersForm from "pages/frontend/components/internal-pages/dynamic_filters/DynamicFiltersForm";
import ItemForm from "pages/frontend/components/internal-pages/dynamic_filters/ItemForm";
import ViewItems from "pages/frontend/components/internal-pages/dynamic_filters/ViewItems";
import StyleLists from "components/styles_components/StyleLists";
import ChapterTaging from "pages/frontend/components/pages/Taging/ChapterTaging";
import CreateChapterTaging from "pages/frontend/components/pages/Taging/CreateChapterTaging";
import ChapterRedirectComponent from "pages/frontend/components/redirect-pages/ChapterRedirectComponent";
import ChapterPreview from "components/previews/ChapterPreview";
import DraftPreview from "pages/frontend/components/pages/DraftManagement/DraftPreview";
import Draft from "pages/frontend/components/pages/DraftManagement/Draft";
import BackendLayout from "pages/backend/BackendLayout";
import Glossary from "pages/frontend/components/internal-pages/Glossary";
import HandlePublication from "pages/frontend/components/pages/DraftManagement/UserdraftManagement/HandlePublication";
import Profile from "./pages/frontend/components/pages/UserManagement/Profile";
import DraftChapterPreview from "pages/frontend/components/internal-pages/ChapterPreview";
import ContentReview from "pages/frontend/components/internal-pages/ContentReview";
import GlossaryValidation from "pages/frontend/components/pages/DraftManagement/UserdraftManagement/GlossaryValidation";
import PublishingStandards from "pages/frontend/components/pages/DraftManagement/UserdraftManagement/PublishingStandards";
import PublishingStandardsDetails from "pages/frontend/components/pages/DraftManagement/UserdraftManagement/PublishingStandardsDetails";
import ErrorsSite from "pages/frontend/components/pages/GlossaryManagement/errors/ErrorsSite";
import ErrorSiteDetails from "pages/frontend/components/pages/GlossaryManagement/errors/ErrorSiteDetails";
import ModifyExisting from "pages/frontend/components/pages/DraftManagement/ModifyExisting";
import CrossLinkImplementation from "pages/frontend/components/pages/DraftManagement/UserdraftManagement/CrossLinkImplementation";
import GlossaryImplementation from "pages/frontend/components/pages/DraftManagement/UserdraftManagement/GlossaryImplementation";
import ModifyExistingDetails from "pages/frontend/components/pages/DraftManagement/UserdraftManagement/ModifyExistingDetails";
import ModifyExistingChapter from "pages/frontend/components/pages/DraftManagement/ModifyExistingChapter";
import StructuredPreviewContent from "pages/frontend/components/pages/DraftManagement/StructuredPreviewContent";
import ImplementedGlossary from "pages/frontend/components/pages/DraftManagement/ImplementedGlossary";
// import DataMigrationPage from "pages/frontend/components/pages/DataMigrationPage";
const Login = reactLazyImport("/pages/frontend/components/auth-pages/Login", 'Login');
const Home = reactLazyImport("/pages/frontend/Home", 'Home');
const Redirect = reactLazyImport("/pages/components/Redirect", 'Redirect');
const PrivacyPolicy = reactLazyImport("/pages/frontend/PrivacyPolicy", 'PrivacyPolicy');
const TermsAndConditions = reactLazyImport("/pages/frontend/TermsAndConditions", 'TermsAndConditions');
const ChangeLog = reactLazyImport("/pages/ChangeLog", 'ChangeLog');
const DataMigrationPage = reactLazyImport("/pages/frontend/components/pages/DataMigrationPage", 'DataMigrationPage');
const CatalogPage = reactLazyImport("/pages/frontend/components/pages/CatalogPage", 'CatalogPage');
const InterLinkingPage = reactLazyImport("/pages/frontend/components/pages/InterLinkingPage", 'InterLinkingPage');
const Animals = reactLazyImport("/pages/frontend/components/pages/Animals/Index", 'Animals');
const AnimalsForm = reactLazyImport("/pages/frontend/components/pages/Animals/Form", 'AnimalsForm');
const Commodities = reactLazyImport("/pages/frontend/components/pages/Commodities/Index", 'Commodities');
const CommoditiesForm = reactLazyImport("/pages/frontend/components/pages/Commodities/Form", 'CommoditiesForm');
const CommodityGroup = reactLazyImport("/pages/frontend/components/pages/CommodityGroups/Index", 'CommodityGroup');
const CommodityGroupForm = reactLazyImport("/pages/frontend/components/pages/CommodityGroups/Form", ' CommodityGroupForm');
const Diseases = reactLazyImport("/pages/frontend/components/pages/Diseases/Index", ' Diseases');
const DiseasesForm = reactLazyImport("/pages/frontend/components/pages/Diseases/Form", ' DiseasesForm');
const Recommendations = reactLazyImport("/pages/frontend/components/pages/Recommendations/Index", 'Recommendations');
const RecommendationsForm = reactLazyImport("/pages/frontend/components/pages/Recommendations/Form", 'RecommendationsForm');
const RecommendationsStatusType = reactLazyImport("/pages/frontend/components/pages/RecommendationsStatusType/Index", 'Recommendations');
const RecommendationsStatusTypeForm = reactLazyImport("/pages/frontend/components/pages/RecommendationsStatusType/Form", 'RecommendationsForm');
const DraftIndex = reactLazyImport("/pages/frontend/components/pages/DraftManagement/Index", 'DraftIndex');
const DraftForm = reactLazyImport("/pages/frontend/components/pages/DraftManagement/Form", 'DraftForm');
const GlossaryManagement = reactLazyImport("/pages/frontend/components/pages/GlossaryManagement/Index", 'GlossaryManagement');
const CrossLinkingManagement = reactLazyImport("/pages/frontend/components/pages/CrossLinkingManagement/Index", 'CrossLinkingManagement');
const StructureManagementForm = reactLazyImport("/pages/frontend/components/pages/StructureManagement/Form", 'StructureManagementForm');
const UserManagement = reactLazyImport("/pages/frontend/components/pages/UserManagement/Index", 'UserManagement');
const PendingUsers = reactLazyImport("/pages/backend/users/PendingUsers", "PendingUsers");
const RoleManagement = reactLazyImport("/pages/frontend/components/pages/RoleManagement/Index", 'RoleManagent');
const Publishing = reactLazyImport("/pages/frontend/components/pages/Publishing/Index", 'Publishing');
// const  DraftPreview= reactLazyImport("pages/frontend/components/internal-pages/DraftPreview", 'DraftPreview');
const StyleManager = reactLazyImport('/pages/frontend/components/pages/StyleManager/List', 'StyleManager');
const StyleManagerForm = reactLazyImport('/pages/frontend/components/pages/StyleManager/Index', 'StyleManagerForm')
const StyleManagerFormCreate = reactLazyImport('/pages/frontend/components/pages/StyleManager/CreateIndex', 'StyleManagerFormCreate')
const PDFHeaderFooter = reactLazyImport('/pages/frontend/components/pages/PDFHeaderFooter/Index', 'PDFHeaderFooter')
const PDFHeaderFooterForm = reactLazyImport('/pages/frontend/components/pages/PDFHeaderFooter/Form', 'PDFHeaderFooterForm')
// const DraftChapterPreview = reactLazyImport('/pages/frontend/components/internal-pages/ChapterPreview', 'ChapterPreview')
const PDFManager = reactLazyImport('/pages/frontend/components/pages/PDFManager/Index', 'PDFManager')
const PDFManagerCreate = reactLazyImport('/pages/frontend/components/pages/PDFManager/Create', 'PDFManagerCreate')
const StrutureIndex = reactLazyImport('/pages/frontend/components/pages/StructureManagement/Index', 'StructureIndex')


const TestManager = reactLazyImport('/pages/frontend/components/pages/TestManager/Index', 'TestManager')


const getRoute = (routeObj = {}) => {
    return { ...routeObj, loader: () => resetCurrentPage(), errorElement: <FrontendLayout children={<PageError />} bypassAuthCheck={true} /> };
}

let roleWiseRoutes = {
    0: [
        getRoute({ path: '/portal', element: <FrontendLayout children={<Home />} secure={true} /> }),
    ],
    1: [
        getRoute({ path: '/portal/', element: <FrontendLayout children={<MainHomePage />} /> }),
    ],
    2: [
        getRoute({ path: '/portal/content-navigation', element: <FrontendLayout children={<ContentNavigation />} secure={true} /> }),
        getRoute({ path: '/portal/content-navigation/print', element: <FrontendLayout children={<ContentNavigationPrint />} secure={true} /> }),
        getRoute({ path: '/portal/content-navigation/manual/print', element: <FrontendLayout children={<ManualPrint />} secure={true} /> }),
        getRoute({ path: '/portal/chapter', element: <FrontendLayout children={<ChapterData />} secure={true} /> }),
        getRoute({ path: '/portal/advance-search', element: <FrontendLayout children={<SearchContent />} secure={true} /> }),
        getRoute({ path: '/portal/access-commodities', element: <FrontendLayout children={<AccessToCommoditiesV3 />} secure={true} /> }),
        getRoute({ path: '/portal/pdf-list', element: <FrontendLayout children={<PdfList />} secure={true} /> }),
        getRoute({ path: '/portal/pdf-details', element: <FrontendLayout children={<PdfDetails />} secure={true} /> }),
        getRoute({ path: '/portal/html-chapter/', element: <FrontendLayout children={<HTMLChapterData />} secure={true} /> }),
        getRoute({ path: '/portal/glossary-implementation', element: <FrontendLayout children={<GlossaryImplementation />} secure={true} /> }),
        getRoute({ path: '/portal/publishing-standards', element: <FrontendLayout children={<PublishingStandards />} secure={true} /> }),
        getRoute({ path: '/portal/publishing-standards-details', element: <FrontendLayout children={<PublishingStandardsDetails />} secure={true} /> }),
        getRoute({ path: '/portal/draft', element: <FrontendLayout children={<Draft />} secure={true} /> }),
        getRoute({ path: '/portal/draft/taging', element: <FrontendLayout children={<Taging />} secure={true} /> }),
        getRoute({ path: '/portal/draft/taging/:id/edit', element: <FrontendLayout children={<EditTaging />} secure={true} /> }),
        getRoute({ path: '/portal/draft/taging/create', element: <FrontendLayout children={<CreateTaging />} secure={true} /> }),
        getRoute({ path: '/portal/draft/taging/chapter', element: <FrontendLayout children={<ChapterTaging />} secure={true} /> }),
        getRoute({ path: '/portal/draft/taging/chapter/create', element: <FrontendLayout children={<CreateChapterTaging />} secure={true} /> }),
        getRoute({ path: '/portal/modify-chapter/', element: <FrontendLayout children={<ModifyExistingChapter />} secure={true} /> }),
        getRoute({ path: '/portal/implemented-glossary/', element: <FrontendLayout children={<ImplementedGlossary />} secure={true} /> }),
        getRoute({ path: '/portal/glossary/:id', element: <FrontendLayout children={<Glossary />} secure={true} /> }),
        getRoute({ path: '/portal/draft/print', element: <FrontendLayout children={<DraftChapterPreviewPrint />} secure={true} /> }),
        getRoute({ path: '/portal/pdf-component', element: <FrontendLayout children={<PDFComponent />} secure={true} /> }),
        getRoute({ path: '/portal', element: <FrontendLayout children={<MainHomePage />} secure={true} /> }),
        getRoute({ path: '/portal/commodities-database', element: <FrontendLayout children={<CommoditiesDatabase />} secure={true} /> }),
        getRoute({ path: '/portal/commodities-database/create', element: <FrontendLayout children={<CommoditiesDatabaseForm />} secure={true} /> }),
        getRoute({ path: '/portal/commodities-database/:id/edit', element: <FrontendLayout children={<CommoditiesDatabaseForm />} secure={true} /> }),
        getRoute({ path: '/portal/manual-chapter/:id', element: <FrontendLayout children={<HtmlManualData />} secure={true} /> }),
        getRoute({ path: '/portal/contact-us', element: <FrontendLayout children={<Contactus />} secure={true} /> }),
        getRoute({ path: '/portal/edit-content', element: <FrontendLayout children={<EditContent />} secure={true} /> }),
        getRoute({ path: '/portal/standards-update-publishing', element: <FrontendLayout children={<StandardsUpdateAndPublishing />} secure={true} /> }),
        getRoute({ path: '/portal/administration', element: <FrontendLayout children={<Administration />} secure={true} /> }),
        getRoute({ path: '/portal/drafting-standards', element: <FrontendLayout children={<DraftingStandards />} secure={true} /> }),
        getRoute({ path: '/portal/import-msword', element: <FrontendLayout children={<ImportFromWord />} secure={true} /> }),
        getRoute({ path: '/portal/add-update-content', element: <FrontendLayout children={<AddUpdateContentSearch />} secure={true} /> }),
        getRoute({ path: '/portal/platform-administrator', element: <FrontendLayout children={<PlatformAdministration />} secure={true} /> }),
        getRoute({ path: '/portal/business-administrator', element: <FrontendLayout children={<BusinessAdministration />} secure={true} /> }),
        getRoute({ path: '/portal/super-admin', element: <FrontendLayout children={<SuperAdmin />} secure={true} /> }),
        getRoute({ path: '/portal/data-migrations', element: <FrontendLayout children={<DataMigrationPage />} secure={true} /> }),
        getRoute({ path: '/portal/data-migrations/data-mapping/:id', element: <FrontendLayout children={<DataMapping />} secure={true} /> }),
        getRoute({ path: '/portal/data-migrations/data-manual-mapping/:id', element: <FrontendLayout children={<DataManualMapping />} secure={true} /> }),
        getRoute({ path: '/portal/data-migrations/html-data-mapping/:id', element: <FrontendLayout children={<HTMLDataMapping />} secure={true} /> }),
        getRoute({ path: '/portal/data-migrations/multilingual-keywords', element: <FrontendLayout children={<Index />} secure={true} /> }),
        getRoute({ path: '/portal/data-migrations/multilingual-keywords/create', element: <FrontendLayout children={<Form />} secure={true} /> }),
        getRoute({ path: '/portal/multilingual-keywords/:keywordId/edit', element: <FrontendLayout children={<Form />} /> }),
        getRoute({ path: '/portal/system-status', element: <FrontendLayout children={<SystemStatus />} /> }),
        getRoute({ path: '/portal/data-migrations/multilingual-keywords/create', element: <FrontendLayout children={<Form />} secure={true} /> }),
        getRoute({ path: '/portal/catalog', element: <FrontendLayout children={<CatalogPage />} secure={true} /> }),
        getRoute({ path: '/portal/catalog/animal', element: <FrontendLayout children={<Animals />} secure={true} /> }),
        getRoute({ path: '/portal/catalog/animal-form', element: <FrontendLayout children={<AnimalsForm />} secure={true} /> }),
        getRoute({ path: '/portal/catalog/animal-form/:id/edit', element: <FrontendLayout children={<AnimalsForm />} secure={true} /> }),
        getRoute({ path: '/portal/catalog/commodities', element: <FrontendLayout children={<Commodities />} secure={true} /> }),
        getRoute({ path: '/portal/catalog/commodities-form', element: <FrontendLayout children={<CommoditiesForm />} secure={true} /> }),
        getRoute({ path: '/portal/catalog/commodities-form/:id/edit', element: <FrontendLayout children={<CommoditiesForm />} secure={true} /> }),
        getRoute({ path: '/portal/catalog/commoditygroup', element: <FrontendLayout children={<CommodityGroup />} secure={true} /> }),
        getRoute({ path: '/portal/catalog/commoditygroup-form', element: <FrontendLayout children={<CommodityGroupForm />} secure={true} /> }),
        getRoute({ path: '/portal/catalog/commoditygroup-form/:id/edit', element: <FrontendLayout children={<CommodityGroupForm />} secure={true} /> }),
        getRoute({ path: '/portal/catalog/diseases', element: <FrontendLayout children={<Diseases />} secure={true} /> }),
        getRoute({ path: '/portal/catalog/diseases-form', element: <FrontendLayout children={<DiseasesForm />} secure={true} /> }),
        getRoute({ path: '/portal/catalog/diseases-form/:id/edit', element: <FrontendLayout children={<DiseasesForm />} secure={true} /> }),
        getRoute({ path: '/portal/catalog/recommendations', element: <FrontendLayout children={<Recommendations />} secure={true} /> }),
        getRoute({ path: '/portal/catalog/recommendations-form', element: <FrontendLayout children={<RecommendationsForm />} secure={true} /> }),
        getRoute({ path: '/portal/catalog/recommendations-form/:id/edit', element: <FrontendLayout children={<RecommendationsForm />} secure={true} /> }),
        getRoute({ path: '/portal/catalog/recommendations-status-type', element: <FrontendLayout children={<RecommendationsStatusType />} secure={true} /> }),
        getRoute({ path: '/portal/catalog/recommendations-status-type/:id/edit', element: <FrontendLayout children={<RecommendationsStatusTypeForm />} secure={true} /> }),
        getRoute({ path: '/portal/catalog/recommendations-status-type/create', element: <FrontendLayout children={<RecommendationsStatusTypeForm />} secure={true} /> }),
        getRoute({ path: '/portal/glossary-management', element: <FrontendLayout children={<GlossaryManagement />} secure={true} /> }),
        getRoute({ path: '/portal/cross-linking-management', element: <FrontendLayout children={<CrossLinkingManagement />} secure={true} /> }),
        getRoute({ path: '/portal/structural-management', element: <FrontendLayout children={<StructureManagementForm />} secure={true} /> }),
        getRoute({ path: '/portal/user-management', element: <FrontendLayout children={<UserManagement />} secure={true} /> }),
        getRoute({ path: "/portal/user-management/pending-users", element: <FrontendLayout children={<PendingUsers />} secure={true} /> }),
        getRoute({ path: '/portal/style-manager', element: <FrontendLayout children={<StyleManager />} secure={true} /> }),
        getRoute({ path: '/portal/style-manager/create', element: <FrontendLayout children={<StyleManagerFormCreate />} secure={true} /> }),
        getRoute({ path: '/portal/style-manager/edit/:id', element: <FrontendLayout children={<StyleManagerForm />} secure={true} /> }),
        getRoute({ path: '/portal/pdf-management', element: <FrontendLayout children={<PDFManager />} secure={true} /> }),
        getRoute({ path: '/portal/pdf-management/create', element: <FrontendLayout children={<PDFManagerCreate />} secure={true} /> }),
        getRoute({ path: '/portal/test-manager', element: <FrontendLayout children={<TestManager />} secure={true} /> }),
        getRoute({ path: '/portal/pdf-header-footer', element: <FrontendLayout children={<PDFHeaderFooter />} secure={true} /> }),
        getRoute({ path: '/portal/pdf-header-footer/edit/:id', element: <FrontendLayout children={<PDFHeaderFooterForm />} secure={true} /> }),
        getRoute({ path: '/portal/publishing', element: <FrontendLayout children={<Publishing />} secure={true} /> }),
        getRoute({ path: '/portal/draft/create', element: <FrontendLayout children={<DraftForm />} secure={true} /> }),
        getRoute({ path: '/portal/draft/index', element: <FrontendLayout children={<DraftIndex />} secure={true} /> }),
        getRoute({ path: '/portal/draft/edit/:id', element: <FrontendLayout children={<DraftPreview />} secure={true} /> }),
        getRoute({ path: '/portal/structure-content/edit/:id', element: <FrontendLayout children={<StructuredPreviewContent />} secure={true} /> }),
        getRoute({ path: '/portal/dynamic-filters', element: <FrontendLayout children={<DynamicFiltersList />} secure={true} /> }),
        getRoute({ path: '/portal/dynamic-filters/create', element: <FrontendLayout children={<DynamicFiltersForm />} secure={true} /> }),
        getRoute({ path: '/portal/dynamic-filters/update/:id', element: <FrontendLayout children={<DynamicFiltersForm />} secure={true} /> }),
        getRoute({ path: '/portal/dynamic-filters/item/create', element: <FrontendLayout children={<ItemForm />} secure={true} /> }),
        getRoute({ path: '/portal/dynamic-filters/item/create/:id', element: <FrontendLayout children={<ItemForm />} secure={true} /> }),
        getRoute({ path: '/portal/dynamic-filters/items/view/:id', element: <FrontendLayout children={<ViewItems />} secure={true} /> }),
        getRoute({ path: '/portal/draft/taging', element: <FrontendLayout children={<Taging />} secure={true} /> }),
        getRoute({ path: '/portal/draft/taging/:id/edit', element: <FrontendLayout children={<EditTaging />} secure={true} /> }),
        getRoute({ path: '/portal/draft/taging/create', element: <FrontendLayout children={<CreateTaging />} secure={true} /> }),
        getRoute({ path: '/portal/draft/taging/chapter', element: <FrontendLayout children={<ChapterTaging />} secure={true} /> }),
        getRoute({ path: '/portal/draft/taging/chapter/create', element: <FrontendLayout children={<CreateChapterTaging />} secure={true} /> }),
        getRoute({ path: '/portal/user-management/:id/approval', element: <FrontendLayout children={<RoleManagement />} secure={true} /> }),
        getRoute({ path: '/portal/chapter-linking/:id', element: <FrontendLayout children={<ChapterRedirectComponent />} secure={true} /> }),
        getRoute({ path: '/portal/user-profile', element: <FrontendLayout children={<Profile />} secure={true} /> }),
        getRoute({ path: '/portal/get-chapter-preview', element: <FrontendLayout children={<ChapterPreview />} secure={true} /> }),
        getRoute({ path: '/portal/draft-chapter-preview/get-chapter-preview', element: <FrontendLayout children={<DraftChapterPreview />} secure={true} /> }),
        getRoute({ path: '/portal/draft-chapter-preview/get-chpater-preview', element: <FrontendLayout children={<DraftChapterPreview />} secure={true} /> }),
        getRoute({ path: '/portal/structure/index', element: <FrontendLayout children={<StrutureIndex />} secure={true} /> }),
        getRoute({ path: '/portal/content-review', element: <FrontendLayout children={<ContentReview />} secure={true} /> }),
    ],
    3: [
        getRoute({ path: '/portal/content-navigation', element: <FrontendLayout children={<ContentNavigation />} secure={true} /> }),
        getRoute({ path: '/portal/content-navigation/print', element: <FrontendLayout children={<ContentNavigationPrint />} secure={true} /> }),
        getRoute({ path: '/portal/content-navigation/manual/print', element: <FrontendLayout children={<ManualPrint />} secure={true} /> }),
        getRoute({ path: '/portal/modify-chapter/', element: <FrontendLayout children={<ModifyExistingChapter />} secure={true} /> }),
        getRoute({ path: '/portal/implemented-glossary/', element: <FrontendLayout children={<ImplementedGlossary />} secure={true} /> }),
        getRoute({ path: '/portal/glossary/:id', element: <FrontendLayout children={<Glossary />} secure={true} /> }),
        getRoute({ path: '/portal', element: <FrontendLayout children={<MainHomePage />} secure={true} /> }),
        getRoute({ path: '/portal/contact-us', element: <FrontendLayout children={<Contactus />} secure={true} /> }),
        //TODO: To be removed
        getRoute({ path: '/portal/chapter', element: <FrontendLayout children={<ChapterData />} secure={true} /> }),
        getRoute({ path: '/portal/html-chapter/', element: <FrontendLayout children={<HTMLChapterData />} secure={true} /> }),
        getRoute({ path: '/portal/manual-chapter/:id', element: <FrontendLayout children={<HtmlManualData />} secure={true} /> }),
        getRoute({ path: '/portal/access-commodities', element: <FrontendLayout children={<AccessToCommoditiesV3 />} secure={true} /> }),
        getRoute({ path: '/portal/draft', element: <FrontendLayout children={<Draft />} secure={true} /> }),
        getRoute({ path: '/portal/draft/print', element: <FrontendLayout children={<DraftChapterPreviewPrint />} secure={true} /> }),

        getRoute({ path: '/portal/glossary-implementation', element: <FrontendLayout children={<GlossaryImplementation />} secure={true} /> }),
        getRoute({ path: '/portal/content-review', element: <FrontendLayout children={<ContentReview />} secure={true} /> }),
        getRoute({ path: '/portal/glossary-validation', element: <FrontendLayout children={<GlossaryValidation />} secure={true} /> }),
        getRoute({ path: '/portal/advance-search', element: <FrontendLayout children={<SearchContent />} secure={true} /> }),
        getRoute({ path: '/portal/edit-content', element: <FrontendLayout children={<EditContent />} secure={true} /> }),
        getRoute({ path: '/portal/standards-update-publishing', element: <FrontendLayout children={<StandardsUpdateAndPublishing />} secure={true} /> }),
        getRoute({ path: '/portal/administration', element: <FrontendLayout children={<Administration />} secure={true} /> }),
        getRoute({ path: '/portal/drafting-standards', element: <FrontendLayout children={<DraftingStandards />} secure={true} /> }),
        getRoute({ path: '/portal/import-msword', element: <FrontendLayout children={<ImportFromWord />} secure={true} /> }),
        getRoute({ path: '/portal/add-update-content', element: <FrontendLayout children={<AddUpdateContentSearch />} secure={true} /> }),
        getRoute({ path: '/portal/business-administrator', element: <FrontendLayout children={<BusinessAdministration />} secure={true} /> }),
        getRoute({ path: '/portal/super-admin', element: <FrontendLayout children={<SuperAdmin />} secure={true} /> }),
        getRoute({ path: '/portal/platform-administrator', element: <FrontendLayout children={<PlatformAdministration />} secure={true} /> }),
        getRoute({ path: '/portal/catalog', element: <FrontendLayout children={<CatalogPage />} secure={true} /> }),
        getRoute({ path: '/portal/catalog/animal', element: <FrontendLayout children={<Animals />} secure={true} /> }),
        getRoute({ path: '/portal/catalog/animal-form', element: <FrontendLayout children={<AnimalsForm />} secure={true} /> }),
        getRoute({ path: '/portal/catalog/animal-form/:id/edit', element: <FrontendLayout children={<AnimalsForm />} secure={true} /> }),
        getRoute({ path: '/portal/catalog/commodities', element: <FrontendLayout children={<Commodities />} secure={true} /> }),
        getRoute({ path: '/portal/catalog/commodities-form', element: <FrontendLayout children={<CommoditiesForm />} secure={true} /> }),
        getRoute({ path: '/portal/catalog/commodities-form/:id/edit', element: <FrontendLayout children={<CommoditiesForm />} secure={true} /> }),
        getRoute({ path: '/portal/catalog/commoditygroup', element: <FrontendLayout children={<CommodityGroup />} secure={true} /> }),
        getRoute({ path: '/portal/catalog/commoditygroup-form', element: <FrontendLayout children={<CommodityGroupForm />} secure={true} /> }),
        getRoute({ path: '/portal/catalog/commoditygroup-form/:id/edit', element: <FrontendLayout children={<CommodityGroupForm />} secure={true} /> }),
        getRoute({ path: '/portal/catalog/diseases', element: <FrontendLayout children={<Diseases />} secure={true} /> }),
        getRoute({ path: '/portal/catalog/diseases-form', element: <FrontendLayout children={<DiseasesForm />} secure={true} /> }),
        getRoute({ path: '/portal/catalog/diseases-form/:id/edit', element: <FrontendLayout children={<DiseasesForm />} secure={true} /> }),
        getRoute({ path: '/portal/catalog/recommendations', element: <FrontendLayout children={<Recommendations />} secure={true} /> }),
        getRoute({ path: '/portal/catalog/recommendations-form', element: <FrontendLayout children={<RecommendationsForm />} secure={true} /> }),
        getRoute({ path: '/portal/catalog/recommendations-form/:id/edit', element: <FrontendLayout children={<RecommendationsForm />} secure={true} /> }),
        getRoute({ path: '/portal/catalog/recommendations-status-type', element: <FrontendLayout children={<RecommendationsStatusType />} secure={true} /> }),
        getRoute({ path: '/portal/catalog/recommendations-status-type/:id/edit', element: <FrontendLayout children={<RecommendationsStatusTypeForm />} secure={true} /> }),
        getRoute({ path: '/portal/catalog/recommendations-status-type/create', element: <FrontendLayout children={<RecommendationsStatusTypeForm />} secure={true} /> }),
        getRoute({ path: '/portal/glossary-management', element: <FrontendLayout children={<GlossaryManagement />} secure={true} /> }),
        getRoute({ path: '/portal/cross-linking-management', element: <FrontendLayout children={<CrossLinkingManagement />} secure={true} /> }),
        getRoute({ path: '/portal/structural-management', element: <FrontendLayout children={<StructureManagementForm />} secure={true} /> }),
        getRoute({ path: '/portal/draft/create', element: <FrontendLayout children={<DraftForm />} secure={true} /> }),
        getRoute({ path: '/portal/draft/index', element: <FrontendLayout children={<DraftIndex />} secure={true} /> }),
        getRoute({ path: '/portal/draft/edit/:id', element: <FrontendLayout children={<DraftPreview />} secure={true} /> }),
        getRoute({ path: '/portal/structure-content/edit/:id', element: <FrontendLayout children={<StructuredPreviewContent />} secure={true} /> }),
        getRoute({ path: '/portal/user-management', element: <FrontendLayout children={<UserManagement />} secure={true} /> }),
        getRoute({ path: '/portal/publishing', element: <FrontendLayout children={<Publishing />} secure={true} /> }),
        getRoute({ path: '/portal/chapter-linking/:id', element: <FrontendLayout children={<ChapterRedirectComponent />} secure={true} /> }),
        getRoute({ path: '/portal/draft/taging', element: <FrontendLayout children={<Taging />} secure={true} /> }),
        getRoute({ path: '/portal/draft/taging/:id/edit', element: <FrontendLayout children={<EditTaging />} secure={true} /> }),
        getRoute({ path: '/portal/draft/taging/create', element: <FrontendLayout children={<CreateTaging />} secure={true} /> }),
        getRoute({ path: '/portal/draft/taging/chapter', element: <FrontendLayout children={<ChapterTaging />} secure={true} /> }),
        getRoute({ path: '/portal/draft/taging/chapter/create', element: <FrontendLayout children={<CreateChapterTaging />} secure={true} /> }),
        getRoute({ path: '/portal/get-chapter-preview', element: <FrontendLayout children={<ChapterPreview />} secure={true} /> }),
        getRoute({ path: '/portal/draft-chapter-preview/get-chapter-preview', element: <FrontendLayout children={<DraftChapterPreview />} secure={true} /> }),
        getRoute({ path: '/portal/draft-chapter-preview/get-chpater-preview', element: <FrontendLayout children={<DraftChapterPreview />} secure={true} /> }),
        getRoute({ path: '/portal/draft-chapter-preview', element: <FrontendLayout children={<DraftChapterPreview />} secure={true} /> }),
        getRoute({ path: '/portal/commodities-database', element: <FrontendLayout children={<CommoditiesDatabase />} secure={true} /> }),
        getRoute({ path: '/portal/commodities-database/create', element: <FrontendLayout children={<CommoditiesDatabaseForm />} secure={true} /> }),
        getRoute({ path: '/portal/commodities-database/:id/edit', element: <FrontendLayout children={<CommoditiesDatabaseForm />} secure={true} /> }),
        getRoute({ path: '/portal/draft/publish/:id', element: <FrontendLayout children={<HandlePublication />} secure={true} /> }),
        getRoute({ path: '/portal/user-profile', element: <FrontendLayout children={<Profile />} secure={true} /> }),
        getRoute({ path: '/portal/structure/index', element: <FrontendLayout children={<StrutureIndex />} secure={true} /> }),
        getRoute({ path: '/portal/publishing-standards', element: <FrontendLayout children={<PublishingStandards />} secure={true} /> }),
        getRoute({ path: '/portal/modify-existing', element: <FrontendLayout children={<ModifyExisting />} secure={true} /> }),
        getRoute({ path: '/portal/publishing-standards-details', element: <FrontendLayout children={<PublishingStandardsDetails />} secure={true} /> }),
        getRoute({ path: '/portal/modify-existing-details', element: <FrontendLayout children={<ModifyExistingDetails />} secure={true} /> }),

        getRoute({ path: '/portal/error-site', element: <FrontendLayout children={<ErrorsSite />} secure={true} /> }),
        getRoute({ path: '/portal/error-site-details', element: <FrontendLayout children={<ErrorSiteDetails />} secure={true} /> }),
        getRoute({ path: '/portal/crosslink-implementation', element: <FrontendLayout children={<CrossLinkImplementation />} secure={true} /> }),

    ],
    4: [
        getRoute({ path: '/portal/modify-chapter/', element: <FrontendLayout children={<ModifyExistingChapter />} secure={true} /> }),
        getRoute({ path: '/portal/glossary/:id', element: <FrontendLayout children={<Glossary />} secure={true} /> }),
        getRoute({ path: '/portal', element: <FrontendLayout children={<MainHomePage />} secure={true} /> }),
        getRoute({ path: '/portal/style-classes', element: <FrontendLayout children={<StyleClasses />} secure={true} /> }),
        getRoute({ path: '/portal/style-lists', element: <FrontendLayout children={<StyleLists />} secure={true} /> }),
        getRoute({ path: '/portal/editor', element: <FrontendLayout children={<MyEditor />} secure={true} /> }),
        getRoute({ path: '/portal/content-navigation/print', element: <FrontendLayout children={<ContentNavigationPrint />} secure={true} /> }),
        getRoute({ path: '/portal/content-navigation/manual/print', element: <FrontendLayout children={<ManualPrint />} secure={true} /> }),
        getRoute({ path: '/portal/pdf-list', element: <FrontendLayout children={<PdfList />} secure={true} /> }),
        getRoute({ path: '/portal/pdf-details', element: <FrontendLayout children={<PdfDetails />} secure={true} /> }),
        getRoute({ path: '/portal/draft/print', element: <FrontendLayout children={<DraftChapterPreviewPrint />} secure={true} /> }),
        getRoute({ path: '/portal/chapter', element: <FrontendLayout children={<ChapterData />} secure={true} /> }),
        getRoute({ path: '/portal/html-chapter/', element: <FrontendLayout children={<HTMLChapterData />} secure={true} /> }),
        getRoute({ path: '/portal/manual-chapter/:id', element: <FrontendLayout children={<HtmlManualData />} secure={true} /> }),
        getRoute({ path: '/portal/contact-us', element: <FrontendLayout children={<Contactus />} secure={true} /> }),
        getRoute({ path: '/portal/linking', element: <FrontendLayout children={<InterLinkingPage />} secure={true} /> }),
        getRoute({ path: '/portal/access-commodities', element: <FrontendLayout children={<AccessToCommoditiesV3 />} secure={true} /> }),
        getRoute({ path: '/portal/content-navigation', element: <FrontendLayout children={<ContentNavigation />} secure={true} /> }),
        getRoute({ path: '/portal/draft', element: <FrontendLayout children={<Draft />} secure={true} /> }),
        getRoute({ path: '/portal/advance-search', element: <FrontendLayout children={<SearchContent />} secure={true} /> }),
        getRoute({ path: '/portal/edit-content', element: <FrontendLayout children={<EditContent />} secure={true} /> }),
        getRoute({ path: '/portal/standards-update-publishing', element: <FrontendLayout children={<StandardsUpdateAndPublishing />} secure={true} /> }),
        getRoute({ path: '/portal/administration', element: <FrontendLayout children={<Administration />} secure={true} /> }),
        getRoute({ path: '/portal/drafting-standards', element: <FrontendLayout children={<DraftingStandards />} secure={true} /> }),
        getRoute({ path: '/portal/import-msword', element: <FrontendLayout children={<ImportFromWord />} secure={true} /> }),
        getRoute({ path: '/portal/add-update-content', element: <FrontendLayout children={<AddUpdateContentSearch />} secure={true} /> }),
        getRoute({ path: '/portal/platform-administrator', element: <FrontendLayout children={<PlatformAdministration />} secure={true} /> }),
        getRoute({ path: '/portal/business-administrator', element: <FrontendLayout children={<BusinessAdministration />} secure={true} /> }),
        getRoute({ path: '/portal/super-admin', element: <FrontendLayout children={<SuperAdmin />} secure={true} /> }),
        getRoute({ path: '/portal/data-migrations', element: <FrontendLayout children={<DataMigrationPage />} secure={true} /> }),
        getRoute({ path: '/portal/upload-documents', element: <FrontendLayout children={<UploadDocuments />} secure={true} /> }),
        getRoute({ path: '/portal/data-migrations/data-mapping/:id', element: <FrontendLayout children={<DataMapping />} secure={true} /> }),
        // task
        getRoute({ path: '/portal/data-migrations/data-manual-mapping/:id', element: <FrontendLayout children={<DataManualMapping />} secure={true} /> }),
        getRoute({ path: '/portal/data-migrations/html-data-mapping/:id', element: <FrontendLayout children={<HTMLDataMapping />} secure={true} /> }),
        getRoute({ path: '/portal/data-migrations/multilingual-keywords', element: <FrontendLayout children={<Index />} secure={true} /> }),
        getRoute({ path: '/portal/data-migrations/multilingual-keywords/create', element: <FrontendLayout children={<Form />} secure={true} /> }),
        getRoute({ path: '/portal/multilingual-keywords/:keywordId/edit', element: <FrontendLayout children={<Form />} /> }),
        getRoute({ path: '/portal/data-migrations/images/:id', element: <FrontendLayout children={<ImagesView />} secure={true} /> }),
        getRoute({ path: '/portal/data-migrations/image-mapping/:id', element: <FrontendLayout children={<ImageMapping />} secure={true} /> }),
        getRoute({ path: '/portal/data-migrations/ref-tag-mapping/:id', element: <FrontendLayout children={<HrefMapping />} secure={true} /> }),
        getRoute({ path: '/portal/glossary-managment', element: <FrontendLayout children={<GlossaryManagement />} secure={true} /> }),
        getRoute({ path: '/portal/catalog', element: <FrontendLayout children={<CatalogPage />} /> }),
        getRoute({ path: '/portal/chapter-linking/:id', element: <FrontendLayout children={<ChapterRedirectComponent />} secure={true} /> }),
        getRoute({ path: '/portal/user-profile', element: <FrontendLayout children={<Profile />} secure={true} /> }),
        getRoute({ path: '/portal/get-chapter-preview', element: <FrontendLayout children={<ChapterPreview />} secure={true} /> }),
        getRoute({ path: '/portal/draft-chapter-preview/get-chapter-preview/', element: <FrontendLayout children={<DraftChapterPreview />} secure={true} /> }),
        getRoute({ path: '/portal/draft-chapter-preview/get-chpater-preview/', element: <FrontendLayout children={<DraftChapterPreview />} secure={true} /> }),
    ],
    5: [
        getRoute({ path: '/portal/glossary/:id', element: <FrontendLayout children={<Glossary />} secure={true} /> }),
        getRoute({ path: '/portal', element: <FrontendLayout children={<MainHomePage />} secure={true} /> }),
        getRoute({ path: '/portal/contact-us', element: <FrontendLayout children={<Contactus />} secure={true} /> }),
        //TODO: To be removed
        getRoute({ path: '/portal/chapter', element: <FrontendLayout children={<ChapterData />} secure={true} /> }),
        getRoute({ path: '/portal/html-chapter/', element: <FrontendLayout children={<HTMLChapterData />} secure={true} /> }),
        getRoute({ path: '/portal/manual-chapter/:id', element: <FrontendLayout children={<HtmlManualData />} secure={true} /> }),
        getRoute({ path: '/portal/access-commodities', element: <FrontendLayout children={<AccessToCommoditiesV3 />} secure={true} /> }),
        getRoute({ path: '/portal/content-navigation/print', element: <FrontendLayout children={<ContentNavigationPrint />} secure={true} /> }),
        getRoute({ path: '/portal/content-navigation/manual/print', element: <FrontendLayout children={<ManualPrint />} secure={true} /> }),
        getRoute({ path: '/portal/draft/print', element: <FrontendLayout children={<DraftChapterPreviewPrint />} secure={true} /> }),
        getRoute({ path: '/portal/draft', element: <FrontendLayout children={<Draft />} secure={true} /> }),
        getRoute({ path: '/portal/content-navigation', element: <FrontendLayout children={<ContentNavigation />} secure={true} /> }),
        getRoute({ path: '/portal/advance-search', element: <FrontendLayout children={<SearchContent />} secure={true} /> }),
        getRoute({ path: '/portal/edit-content', element: <FrontendLayout children={<EditContent />} secure={true} /> }),
        getRoute({ path: '/portal/standards-update-publishing', element: <FrontendLayout children={<StandardsUpdateAndPublishing />} secure={true} /> }),
        getRoute({ path: '/portal/administration', element: <FrontendLayout children={<Administration />} secure={true} /> }),
        getRoute({ path: '/portal/drafting-standards', element: <FrontendLayout children={<DraftingStandards />} secure={true} /> }),
        getRoute({ path: '/portal/import-msword', element: <FrontendLayout children={<ImportFromWord />} secure={true} /> }),
        getRoute({ path: '/portal/add-update-content', element: <FrontendLayout children={<AddUpdateContentSearch />} secure={true} /> }),
        getRoute({ path: '/portal/business-administrator', element: <FrontendLayout children={<BusinessAdministration />} secure={true} /> }),
        getRoute({ path: '/portal/super-admin', element: <FrontendLayout children={<SuperAdmin />} secure={true} /> }),
        getRoute({ path: '/portal/platform-administrator', element: <FrontendLayout children={<PlatformAdministration />} secure={true} /> }),
        getRoute({ path: '/portal/catalog', element: <FrontendLayout children={<CatalogPage />} secure={true} /> }),

        getRoute({ path: '/portal/catalog/animal', element: <FrontendLayout children={<Animals />} secure={true} /> }),
        getRoute({ path: '/portal/catalog/animal-form', element: <FrontendLayout children={<AnimalsForm />} secure={true} /> }),
        getRoute({ path: '/portal/catalog/animal-form/:id/edit', element: <FrontendLayout children={<AnimalsForm />} secure={true} /> }),
        getRoute({ path: '/portal/catalog/commodities', element: <FrontendLayout children={<Commodities />} secure={true} /> }),
        getRoute({ path: '/portal/catalog/commodities-form', element: <FrontendLayout children={<CommoditiesForm />} secure={true} /> }),
        getRoute({ path: '/portal/catalog/commodities-form/:id/edit', element: <FrontendLayout children={<CommoditiesForm />} secure={true} /> }),
        getRoute({ path: '/portal/catalog/commoditygroup', element: <FrontendLayout children={<CommodityGroup />} secure={true} /> }),
        getRoute({ path: '/portal/catalog/commoditygroup-form', element: <FrontendLayout children={<CommodityGroupForm />} secure={true} /> }),
        getRoute({ path: '/portal/catalog/commoditygroup-form/:id/edit', element: <FrontendLayout children={<CommodityGroupForm />} secure={true} /> }),
        getRoute({ path: '/portal/catalog/diseases', element: <FrontendLayout children={<Diseases />} secure={true} /> }),
        getRoute({ path: '/portal/catalog/diseases-form', element: <FrontendLayout children={<DiseasesForm />} secure={true} /> }),
        getRoute({ path: '/portal/catalog/diseases-form/:id/edit', element: <FrontendLayout children={<DiseasesForm />} secure={true} /> }),
        getRoute({ path: '/portal/catalog/recommendations', element: <FrontendLayout children={<Recommendations />} secure={true} /> }),
        getRoute({ path: '/portal/catalog/recommendations-form', element: <FrontendLayout children={<RecommendationsForm />} secure={true} /> }),
        getRoute({ path: '/portal/catalog/recommendations-form/:id/edit', element: <FrontendLayout children={<RecommendationsForm />} secure={true} /> }),
        getRoute({ path: '/portal/catalog/recommendations-status-type', element: <FrontendLayout children={<RecommendationsStatusType />} secure={true} /> }),
        getRoute({ path: '/portal/catalog/recommendations-status-type/:id/edit', element: <FrontendLayout children={<RecommendationsStatusTypeForm />} secure={true} /> }),
        getRoute({ path: '/portal/catalog/recommendations-status-type/create', element: <FrontendLayout children={<RecommendationsStatusTypeForm />} secure={true} /> }),
        getRoute({ path: '/portal/glossary-management', element: <FrontendLayout children={<GlossaryManagement />} secure={true} /> }),
        getRoute({ path: '/portal/cross-linking-management', element: <FrontendLayout children={<CrossLinkingManagement />} secure={true} /> }),
        getRoute({ path: '/portal/structural-management', element: <FrontendLayout children={<StructureManagementForm />} secure={true} /> }),
        getRoute({ path: '/portal/draft/create', element: <FrontendLayout children={<DraftForm />} secure={true} /> }),
        getRoute({ path: '/portal/draft/index', element: <FrontendLayout children={<DraftIndex />} secure={true} /> }),
        getRoute({ path: '/portal/draft/edit/:id', element: <FrontendLayout children={<DraftPreview />} secure={true} /> }),
        getRoute({ path: '/portal/user-management', element: <FrontendLayout children={<UserManagement />} secure={true} /> }),
        getRoute({ path: '/portal/draft', element: <FrontendLayout children={<Draft />} secure={true} /> }),
        getRoute({ path: '/portal/draft/taging', element: <FrontendLayout children={<Taging />} secure={true} /> }),
        getRoute({ path: '/portal/draft/taging/:id/edit', element: <FrontendLayout children={<EditTaging />} secure={true} /> }),
        getRoute({ path: '/portal/draft/taging/create', element: <FrontendLayout children={<CreateTaging />} secure={true} /> }),
        getRoute({ path: '/portal/draft/taging/chapter', element: <FrontendLayout children={<ChapterTaging />} secure={true} /> }),
        getRoute({ path: '/portal/draft/taging/chapter/create', element: <FrontendLayout children={<CreateChapterTaging />} secure={true} /> }),
        getRoute({ path: '/portal/publishing', element: <FrontendLayout children={<Publishing />} secure={true} /> }),
        getRoute({ path: '/portal/draft-chapter-preview', element: <FrontendLayout children={<DraftChapterPreview />} secure={true} /> }),
        getRoute({ path: '/portal/draft-chapter-preview/get-chapter-preview', element: <FrontendLayout children={<DraftChapterPreview />} secure={true} /> }),
        getRoute({ path: '/portal/draft-chapter-preview/get-chpater-preview', element: <FrontendLayout children={<DraftChapterPreview />} secure={true} /> }),
        getRoute({ path: '/portal/user-profile', element: <FrontendLayout children={<Profile />} secure={true} /> }),
    ],
};

roleWiseRoutes[6] = roleWiseRoutes[3];

export const getRouter = (user) => {
    return createBrowserRouter([
        getRoute({ path: '/', element: <FrontendLayout children={<Home />} /> }),
        getRoute({ path: '/about', element: <FrontendLayout children={<AboutPage />} bypassAuthCheck={true} /> }),
        getRoute({ path: '/privacy-policy', element: <FrontendLayout children={<PrivacyPolicy />} bypassAuthCheck={true} /> }),
        getRoute({ path: '/terms-and-conditions', element: <FrontendLayout children={<TermsAndConditions />} bypassAuthCheck={true} /> }),
        getRoute({ path: '/changelog', element: <FrontendLayout children={<ChangeLog />} bypassAuthCheck={true} /> }),
        getRoute({ path: '/expand-component', element: <FrontendLayout children={<IsExpanded />} bypassAuthCheck={true} /> }),
        getRoute({ path: '/advance-search', element: <FrontendLayout children={<SearchContent />} bypassAuthCheck={true} /> }),
        getRoute({ path: '/login', element: <FrontendLayout children={<Login />} /> }),
        getRoute({ path: '/forgotpassword', element: <FrontendLayout children={<ForgotPassword />} /> }),
        getRoute({ path: '/resetpassword', element: <FrontendLayout children={<ResetPassword />} /> }),
        {
            path: '/portal',
            children: user && user.id && roleWiseRoutes[user.role_id] !== undefined ? (
                roleWiseRoutes[user.role_id]
            ) : [
                getRoute({ path: '/portal', element: <Redirect to='/login' passCurrentRoute={true} /> }),
                getRoute({ path: '/portal/*', element: <Redirect to='/login' passCurrentRoute={true} /> }),
            ]
        },
        ...([1, 2].includes(user?.role_id) ? [
            getRoute({ path: '*', element: <FrontendLayout children={<Page404 />} bypassAuthCheck={true} /> })
        ] : [
            getRoute({ path: '*', element: <FrontendLayout children={<Page404 />} bypassAuthCheck={true} /> })
        ])

    ])
};