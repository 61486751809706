const CardHeader = ({children, className}) => {
    return (
        <div className={`card-title1 lh-1 ${className}`}>
            {children}
        </div>
    );
}

CardHeader.defaultProps = {
    children: <></>,
    className: ''
};

export default CardHeader;