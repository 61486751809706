import { GridActionsCellItem } from "@mui/x-data-grid";
import HtmlRenderer from "components/HtmlRenderer";
import ImageUpload from "components/file_uploads/ImageUpload";
import { displayNotification, sendAPIRequest } from "helpers";
import DataTable from "pages/components/DataTable";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

const ImagesView = () => {

    const { id } = useParams();
    const { t } = useTranslation()

    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState({
        loading_images: false,
        deleting_image: false,
    });

    useEffect(() => {
        getImages();
    }, []);

    const getImages = async () => {
        setIsLoading({ ...isLoading, loading_images: true });
        try {
            const ret = await sendAPIRequest(`/api/document-images/?document_id=${id}`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                setData(res.data);
            }
        } catch (error) {
            console.error(error);
        }
        setIsLoading({ ...isLoading, loading_images: false });
    }

    const deleteImage = async (id) => {
        if (!window.confirm('Are you sure you want to delete')) {
            return;
        }
        setIsLoading({ ...isLoading, deleting_image: true });
        try {
            const ret = await sendAPIRequest(`/api/document-images/${id}`, 'DELETE');
            const res = await ret.json();
            if (res.success && res.status === 200) {
                displayNotification('success', 'Successfully deleted [B]');
                await getImages();
            } else {
                displayNotification('error', 'Failed to delete [B]');
            }
        } catch (error) {
            console.error(error);
            displayNotification('error', 'Failed to delete [B]');
        }
        setIsLoading({ ...isLoading, deleting_image: false });
    }

    return (
        <>
            <div className="container my-2">
                <div className="card card-woah border border-secondary shadow rounded-0 h-100 mt-2">
                    <div className="card-body p-4 d-flex justify-content-between">
                        <h1 className="my-auto"> {t("121","View Images")}</h1>
                        <ImageUpload
                            children={(handleOpen) =>
                                <button
                                    type="button"
                                    className="btn btn--primary mb-2"
                                    onClick={() => handleOpen()}
                                >
                                    {t("120", "Image Upload")}
                                </button>
                            }
                            id={id}
                        />
                    </div>
                </div>
                <div className="card card-woah border border-secondary shadow rounded-0 h-100 mt-2">
                    <div className="card-body p-4">
                        <DataTable
                            getRowId={(row) => row.id}
                            isLoading={isLoading.loading_images}
                            rows={data}
                            className="text-white bg-primary rounded-0"
                            columns={[
                                {
                                    field: "image_name",
                                    headerName: t("202", "Image Name"),
                                    headerClassName: "text-white bg-primary rounded-0",
                                    flex: 1,
                                },
                                {
                                    field: "image_type",
                                    headerName: t("203", "Image Type"),
                                    headerClassName: "text-white bg-primary rounded-0",
                                    flex: 1,
                                },
                                {
                                    field: "image_heading",
                                    headerName: t("204", "Image Heading"),
                                    renderCell: (params) => {
                                        return <HtmlRenderer tag={'span'} htmlContent={params.row?.html_content?.content ?? ''} />
                                    },
                                    headerClassName: "text-white bg-primary rounded-0",
                                    flex: 1,
                                },
                                {
                                    field: "actions",
                                    type: "actions",
                                    headerClassName: "text-white bg-primary rounded-0",
                                    headerName: t("116", "Actions"),
                                    getActions: (params) =>
                                        [
                                            <GridActionsCellItem
                                                label={t("133","Delete")}
                                                key={0}
                                                showInMenu
                                                onClick={() => {
                                                    deleteImage(params.row.id)
                                                }
                                                }
                                            />,
                                        ],
                                    width: 60,
                                    flex: 1,
                                },
                            ]}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

ImagesView.defaultProps = {};

export default ImagesView;