
import { Box } from '@mui/material';
import Container from 'components/common/Container';
import CustomCard from 'components/common/CustomCard';
import Loader from 'components/common/Loader';
import { displayNotification, getBaseURL, sendAPIRequest } from 'helpers';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setCurrentPage } from 'redux/generalSlice';

const PublishingStandards = () => {
    const { t } = useTranslation();

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [standardData, setStandardData] = useState([]);
    const [isLoading, setIsLoading] = useState({
        load_standard: false,
    });

    useEffect(() => {
        dispatch(setCurrentPage('publishing-standards'));
    }, []);

    useEffect(() => {
        getPublishingStandards();
    }, []);

    const getPublishingStandards = async () => {
        setIsLoading({ ...isLoading, load_standard: true });
        try {
            const ret = await sendAPIRequest(`/api/structure/standard-types`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                setStandardData(res.data);
            } else {
                displayNotification("error", "Failed to get Publishing Standards");
            }
            setIsLoading({ ...isLoading, load_standard: false });
        } catch (e) {
            console.log({ e });
        }
    };


    const draftmigrationCatalogs = async () => {
        if (!window.confirm('Are you sure you want to migrate Catalogs?')) {
            return;
        }
        try {
            const ret = await sendAPIRequest(`/api/handle-drafting/catalog-migration`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                setStandardData([]);
                await getPublishingStandards();
            } else {
                displayNotification('error', res.message);
            }
        } catch (error) {
            console.log({ error });
            displayNotification("error", "Internall Server Error [F]")
        }
    }
    const prePublishCatalogs = async () => {
        if (!window.confirm('Are you sure you want to pre-publish Catalogs?')) {
            return;
        }
        try {
            const selectedStandard = standardData.find(item => item.structure_status === 1);
            const ret = await sendAPIRequest(`/api/handle-drafting/catalog-migration-with-year`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                setStandardData([]);
                await getPublishingStandards();
            } else {
                displayNotification('error', res.message);
            }
        } catch (error) {
            console.log({ error });
            displayNotification("error", "Internal Server Error")
        }
    }
    const getColorClass = (structureCode) => {
        switch (structureCode) {
            case "TC":
                return "btn-terrestrial-code";
            case "TM":
                return "btn-terrestrial-manual";
            case "AC":
                return "btn-aquatic-code";
            case "AM":
                return "btn-aquatic-manual";
            default:
                return "btn-default";
        }
    };

    const getColorDisabledClass = (structureCode) => {
        switch (structureCode) {
            case "TC":
                return "btn-terrestrial-code-default";
            case "TM":
                return "btn-terrestrial-manual-default";
            case "AC":
                return "btn-aquatic-code-default";
            case "AM":
                return "btn-aquatic-manual-default";
            default:
                return "btn-default";
        }
    };

    const handleClickStandard = async (standard_detail = {}) => {
        try {
            if (standard_detail.structure_status === 1) {
                await handlemigrate(standard_detail.id);
            } else if (standard_detail.structure_status === 2) {
                displayNotification("warning", "Migration is in progress");
            } else {
                navigate(`/portal/publishing-standards-details?id=${standard_detail?.id}`);
            }
        } catch (error) {
            console.log({ error });
        }
    }


    const handlemigrate = async (id = '') => {
        if (!window.confirm("Do You Want To Migrate Standard")) {
            return;
        }
        try {
            const ret = await sendAPIRequest(`/api/handle-drafting/`, "POST", {
                standard_type_id: id,
            });
            const res = await ret.json();
            if (res.success && res.status === 200) {
                displayNotification("success", "Migration initiated successfully.");
                setStandardData([]);
                await getPublishingStandards();
            }
        } catch (e) {
            console.log({ e });
        }
    };

    return (
        <Container>
            <div className="card card-woah w-100 border shadow-sm rounded-0 px-4 py-2 my-2">
                <div className="row g-0">
                    <div className="col-md-6">
                        <div className="card-title1 lh-1 ">
                            {t("-1", "New Edition")}
                        </div>
                    </div>
                    <div className="col-md-6 text-end">
                        <button
                            className="btn btn--primary font-custom fs-6 ms-1  "
                            onClick={async () => draftmigrationCatalogs()}
                            disabled={![undefined, null, 0].includes(standardData[0]?.catalog_status)}
                        >
                            {t("-1", "Migrate Catalog")}
                        </button>
                    </div>
                </div>
            </div>
            <CustomCard >
                <div className="row justify-content-start"
                    disabled={[undefined, null, 0].includes(standardData[0]?.catalog_status)}
                >
                    {standardData?.map((item) => {
                        const colorClass = getColorClass(item.structure_code);
                        const isDisabled = [undefined, null, 0].includes(item.catalog_status);
                        const disabledClass = isDisabled ? "disabled-color" : colorClass;
                        const disabledColorClass = getColorDisabledClass(item.structure_code);

                        return (
                            <div key={item.id} className="col-md-3 col-sm-6 col-12">
                                <div className={`${isDisabled ? '' : 'st-card'} `}>
                                    <span
                                        className={`d-block text-center ${isDisabled ? disabledColorClass : colorClass} my-2 py-4 px-3 shadow-sm ${isDisabled ? '' : 'cursor-pointer'}`}
                                        onClick={!isDisabled ? () => handleClickStandard(item) : null}
                                        style={{
                                            pointerEvents: isDisabled ? "none" : "auto",
                                            opacity: isDisabled ? 0.6 : 1
                                        }}
                                    >
                                        <span className="d-block py-15 card-title3">{item.structure_name.toUpperCase()}</span>
                                        {
                                            item.structure_status === 2 && (
                                                <Box sx={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                }}>
                                                    <Loader message={'Migration in progress'} />
                                                </Box>
                                            )
                                        }

                                        <img
                                            src={getBaseURL("/assets/icons/links-bar.png")}
                                            alt="Hover Image"
                                            className="hover-image"
                                        />
                                    </span>
                                </div>
                            </div>
                        );
                    })}


                    {/* <div className='d-flex justify-content-end my-4'>
                        <button
                            className="btn btn--primary font-custom fs-6 ms-1"
                            onClick={async () => prePublishCatalogs()}
                            disabled={![undefined, null, 1].includes(standardData[0]?.catalog_status)}
                        >
                            {t("-1", "PrePublish Catalog")}
                        </button>
                    </div> */}
                </div>
            </CustomCard>
        </Container>
    );
};

export default PublishingStandards;
