import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import Command from '@ckeditor/ckeditor5-core/src/command';
import { addListToDropdown, createDropdown } from '@ckeditor/ckeditor5-ui/src/dropdown/utils';
import Model from '@ckeditor/ckeditor5-ui/src/model';
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';
import indentIcon from '@ckeditor/ckeditor5-core/theme/icons/indent.svg';
import outdentIcon from '@ckeditor/ckeditor5-core/theme/icons/outdent.svg';

class CustomIndentPlugin extends Plugin {
    static get pluginName() {
        return 'CustomIndent';
    }

    init() {
        const editor = this.editor;

        // Add commands
        editor.commands.add('customIndent', new CustomIndentCommand(editor, 20));
        editor.commands.add('customOutdent', new CustomIndentCommand(editor, -20));

        // Add model schema and conversion
        this._defineSchema();
        this._defineConverters();

        // Add toolbar buttons
        this._addToolbarButtons();
    }

    _defineSchema() {
        const schema = this.editor.model.schema;
        schema.extend('$block', { allowAttributes: 'customIndent' });
    }

    _defineConverters() {
        const editor = this.editor;
        editor.conversion.for('upcast').attributeToAttribute({
            view: {
                name: 'p',
                styles: { 'margin-left': /\d+px/ }
            },
            model: {
                key: 'customIndent',
                value: (viewElement) => parseInt(viewElement.getStyle('margin-left'), 10) || 0
            }
        });

        editor.conversion.for('downcast').attributeToAttribute({
            model: 'customIndent',
            view: (modelAttributeValue, { writer }) => {
                return modelAttributeValue ? { key: 'style', value: `margin-left:${modelAttributeValue}px;` } : null;
            }
        });
    }

    _addToolbarButtons() {
        const editor = this.editor;
        const t = editor.t;

        editor.ui.componentFactory.add('customIndent', (locale) => {
            const button = new ButtonView(locale);
            button.set({
                label: t('Increase Indent'),
                icon: indentIcon,
                tooltip: true
            });
            button.on('execute', () => {
                editor.execute('customIndent');
            });
            return button;
        });

        editor.ui.componentFactory.add('customOutdent', (locale) => {
            const button = new ButtonView(locale);
            button.set({
                label: t('Decrease Indent'),
                icon: outdentIcon,
                tooltip: true
            });
            button.on('execute', () => {
                editor.execute('customOutdent');
            });
            return button;
        });
    }
}

class CustomIndentCommand extends Command {
    constructor(editor, indentValue) {
        super(editor);
        this.indentValue = indentValue;
    }

    execute() {
        const editor = this.editor;
        const model = editor.model;
        const selection = model.document.selection;
        const blocks = Array.from(selection.getSelectedBlocks());

        model.change((writer) => {
            for (const block of blocks) {
                const currentIndent = parseInt(block.getAttribute('customIndent') || '0', 10);
                const newIndent = Math.max(0, currentIndent + this.indentValue);
                writer.setAttribute('customIndent', newIndent, block);
            }
        });
    }

    refresh() {
        const model = this.editor.model;
        const selection = model.document.selection;
        this.isEnabled = !!Array.from(selection.getSelectedBlocks()).length;
    }
}

export default CustomIndentPlugin;
