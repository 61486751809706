const StandardsSelectCard = ({ children, className }) => {
    return (
        <div className={`card-woah border border-secondary px-4 pt-3 w-100 rounded rounded-2 text-center bg-great-light ${className}`}>
            {children}
        </div>
    );
}

StandardsSelectCard.defaultProps = {
    children: <></>,
    className: ''
};

export default StandardsSelectCard;