const { Spinner } = require("react-bootstrap")

const Loader = ({ message }) => {
    return (
        <div className="d-flex align-items-center">
            <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                style={{ color: "#ff4815" }}
            />
            <div className="m-0 p-0 ps-2">{message}</div>
        </div>
    );
}

export default Loader;