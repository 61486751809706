import React, { useEffect, useState } from 'react';
import { BASE_URL, ENV_SITE_DEV } from '../../constants';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import TestSite from '../TestSite';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CookieConcentPopup from '../components/CookieConcentPopup';
import { sendAPIRequest } from 'helpers';


const FrontendLayout = ({ children, bypassAuthCheck, secure }) => {

    const navigate = useNavigate();
    const user = useSelector(s => s.auth.data);

    const [bgColor, setBgColor] = useState('bg-white');

    useEffect(() => {
        document.getElementById('root')?.classList.add('w-100');
        document.getElementById('root')?.classList.remove('d-flex');
        document.getElementById('root')?.classList.remove('flex-grow-1');
        getManualStyle();
        if (secure == false && user.id != '' && bypassAuthCheck == false) {
            navigate({
                pathname: '/portal'
            });
        }
    }, []);

    
    const getManualStyle = async () => {
        try {
            const ret = await sendAPIRequest('/api/manual-styles');
            const res = await ret.json();
            if (res.success && res.status === 200) {
                if (res.data && res.data.length > 0) {
                    const css = res.data[0].style.replace(/<[^>]+>/g, '');
                    injectCSS(css)
                }
            }
        } catch (err) {
            console.log(err);
        }
    }

    const injectCSS = (cssContent) => {
        const styleElement = document.createElement('style');
        styleElement.appendChild(document.createTextNode(cssContent));
        document.head.appendChild(styleElement);
    };

    return (
        <>
            <link rel="stylesheet" href={`${BASE_URL}/assets/css/frontend.css`} />
            <link rel="stylesheet" href={`${BASE_URL}/assets/css/common.css`} />
            <link rel="stylesheet" href={`${BASE_URL}/assets/css/ckeditor-frontend.css`} />
            {ENV_SITE_DEV && <TestSite />}
            <Header setBgColor={setBgColor} />
            <div className="min-h-70vh">
                {children}
            </div>
            <Footer />
            <CookieConcentPopup />
        </>
    );
}

FrontendLayout.defaultProps = {
    children: [],
    bypassAuthCheck: false,
    secure: false
};

export default FrontendLayout;
