import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { displayNotification, generateOptionsFromData, getBaseURL, handleReplaceUrlForChapterLinking, sendAPIRequest } from 'helpers';
import { CONTENT_PDF_DOWNLOAD_API, CONTENT_PDF_GENERATE_API, LIST_DETAIL_ELEMENTS } from 'constants';
import { Box, CircularProgress, Divider, Grid, IconButton, InputAdornment, TextField, Tooltip, Typography, styled, tooltipClasses } from '@mui/material';
import HtmlRenderer from 'components/HtmlRenderer';
import DataTable from 'components/DataTable';
import PrintIcon from '@mui/icons-material/Print';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ClearIcon from '@mui/icons-material/Clear';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import ZoomInMapIcon from '@mui/icons-material/ZoomInMap';
import { KeyboardArrowDown, KeyboardArrowUp, OpenInNew } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import CustomCard from 'components/common/CustomCard';
import AutoCompleteDropdown from 'components/autocomplete/AutoCompleteDropdown';
import useLocalStorage from "hooks/useLocalStorage";

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#ffece8',
        color: '#ff4815',
        boxShadow: theme.shadows[1],
        fontSize: 12,
        padding: '13px'
    },
}));
const currentDate = new Date().toISOString().slice(0, 10);

const SearchByFilter = (props) => {

    const { t } = useTranslation();
    const siteLanguage = useSelector(state => state.general.siteLanguage);
    const [componentLanguage, setComponentLanguage] = useState(LIST_DETAIL_ELEMENTS.languages.english);
    const [count, setCount] = useState(0);
    const [animalGroup, setAnimalGroup] = useState([]);
    const [codeId, setCodeID] = useState('');
    const [codestandardType, setCodestandardType] = useState('');
    const [currentAnimal, setCurrentAnimal] = useState({});
    const [availableDiseases, setAvailableDiseases] = useState([]);
    const [currentTerm, setCurrentTerm] = useState(0);
    const [currentElement, setCurrentElement] = useState('');
    const [currentDisease, setCurrentDisease] = useState({});
    const [showComponent, setShowComponent] = useState(false);
    const [searchedData, setSearchedData] = useState([]);
    const [data, setData] = useState([]);
    const [RID, setRID] = useState();
    const [search, setSearch] = useState('');
    const [isExpanded, setIsExpanded] = useState(false);
    const [highlightedData, setHighlightedData] = useState(data);
    const [manualstandardType, setManualstandardType] = useState('');
    const [baseFontSize, setBaseFontSize] = useState(14);
    const [isManual, setIsManual] = useState(false);
    const [standardID, setStandardID] = useState('');
    const [showChapterDetails, setShowChapterDetails] = useState(false);
    const [currentSelected, setCurrentSelected] = useState('');
    const [shortName, setShortName] = useState('');
    const [chapterFilterMetadata, setChapterFilterMetadata] = useState({ chapter_no: '', standardType: '', animalType: '' });
    const [documentMetaData, setDocumentMetaData] = useState({
        section_name: '',
        chapter_name: '',
        chapter_no: '',
        section_no: "",
        area_vol_no: ''
    });
    const [isManualSelected, setIsManualSelected] = useState(false)
    const [isLoading, setIsLoading] = useState({
        loading_filters: false,
        loading_data: false,
        loading_search_data: false,
        loading_dynamic_filters: false,
        loading_dynamic_filter_value: false
    });
    const [generatingPDF, setGeneratingPDF] = useState(false);
    const [isLoading1, setIsLoading1] = useState(false);
    const [dynamicFilters, setDynamicFilters] = useState([]);
    const [filtersValues, setFiltersValue] = useState([]);
    const { setItem } = useLocalStorage('metadata');
    const [selectedDynFilter, setSelectedDynFilter] = useState({
        value: "",
        label: ""
    });
    const [selectedDynFilterValue, setSelectedDynFilterValue] = useState({
        value: "",
        label: ""
    });

    useEffect(() => {
        setItem(documentMetaData);
    }, [documentMetaData]);

    useEffect(() => {
        loadAnimalGroup();
    }, [currentDisease, props.isCode, props.isManual, props.isAquatic, props.isTerrestrial]);

    useEffect(() => {
        getDynamicFilters();
    }, [props.isCode, props.isManual, props.isAquatic, props.isTerrestrial]);

    useEffect(() => {
        loadDiseases();
    }, [currentAnimal, props.isCode, props.isManual, props.isAquatic, props.isTerrestrial]);

    useEffect(() => {
        if (chapterFilterMetadata.chapter_no !== '') {
            const row = searchedData.find(r => (r.chapter_no === chapterFilterMetadata.chapter_no && r.standard_type === chapterFilterMetadata.standardType && r.animal_type === chapterFilterMetadata.animalType));
            if (row) {
                handleOnChapterClick(row.standard_type, row.id);
            }
        }
    }, [standardID, searchedData, componentLanguage, chapterFilterMetadata.chapter_no,])

    useEffect(() => {
        if (currentSelected.RID) {
            getChapterData(isManual, currentSelected);
        }
    }, [componentLanguage]);


    useEffect(() => {
        if (selectedDynFilter.value) {
            getDynamicFiltersValues();
        }
    }, [selectedDynFilter.value])

    const animalsGroupOptions = useMemo(() => {
        return generateOptionsFromData(animalGroup, {
            value: "id",
            label: "animal_name",
        });
    }, [animalGroup]);

    const diseaseOptions = useMemo(() => {
        return generateOptionsFromData(availableDiseases, {
            value: "id",
            label: "disease_name",
        });
    }, [availableDiseases]);

    const filtersOptions = useMemo(() => {
        return generateOptionsFromData(dynamicFilters, {
            value: "id",
            label: "list_name",
        });
    }, [dynamicFilters]);
    const filtersValueOptions = useMemo(() => {
        return generateOptionsFromData(filtersValues, {
            value: "id",
            label: "list_name",
        });
    }, [filtersValues]);


    const getDynamicFilters = async () => {
        try {
            const ret = await sendAPIRequest(`/api/dynamic-filters/`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                setDynamicFilters(res.data);
            } else {
                setDynamicFilters([]);
            }
        } catch (err) {
            console.error({ err });
        }
    }

    const getDynamicFiltersValues = async () => {
        try {
            const ret = await sendAPIRequest(`/api/dynamic-filters/values/?master_id=${selectedDynFilter.value ?? ''}&terrestrial=${props.isTerrestrial ? 1 : 0}&code=${props.isCode ? 1 : 0}`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                setFiltersValue(res.data);
            } else {
                setFiltersValue([]);
            }
        } catch (err) {
            console.error({ err });
        }
    }

    const loadAnimalGroup = async () => {
        setIsLoading({ ...isLoading, load_animal_group: true });
        try {
            const ret = await sendAPIRequest(
                `/api/animals/search-content-animals/?is_aquatic=${props.isAquatic}&is_terrestrial=${props.isTerrestrial}&is_code=${props.isCode}&is_manual=${props.isManual}&disease=${currentDisease?.value ?? ''}&language_id=${siteLanguage}`
            );
            const res = await ret.json();
            if (res.success && res.status === 200) {
                if (res.data.length === 1) {
                    let x = {
                        value: res.data[0].id,
                        lable: res.data[0].animal_name
                    }
                    setCurrentAnimal(x)
                }
                setAnimalGroup(res.data)
                setIsLoading({ ...isLoading, load_animal_group: false });
                return res.data;
            }
        } catch (e) {
            console.log({ e });
        }
        setIsLoading({ ...isLoading, load_animal_group: false });
    }

    const loadDiseases = async (id = null) => {
        setIsLoading({ ...isLoading, load_diseases: true });
        try {
            const ret = await sendAPIRequest(
                `/api/diseases/search-content-diseases/?is_aquatic=${props.isAquatic}&is_terrestrial=${props.isTerrestrial}&is_code=${props.isCode}&is_manual=${props.isManual}&animal=${currentAnimal?.value ?? ''}&language_id=${siteLanguage}`
            );
            const res = await ret.json();
            if (res.success && res.status === 200) {
                if (res.data.length === 1) {
                    if (currentDisease && currentDisease.value === res.data[0].id) {
                        setIsLoading({ ...isLoading, load_diseases: false });
                        return
                    }
                    let x = {
                        value: res.data[0].id,
                        lable: res.data[0].disease_name
                    }
                    setCurrentDisease(x)
                }
                setAvailableDiseases(res.data);
                setIsLoading({ ...isLoading, load_diseases: false });
                return res.data;
            }
        } catch (e) {
            console.log({ e });
        }
        setIsLoading({ ...isLoading, load_diseases: false });
    }

    const modifySearchResults = (results) => {
        let temp = [];
        results.forEach(result => {
            result.linked_structure && result.linked_structure.length > 0 && result.linked_structure.forEach(d => {
                temp.push(d);
            });
        });
        return temp;
    }

    const loadSearchedStandardsData = async () => {
        setIsLoading({ ...isLoading, loading_search_data: true });
        try {
            const ret = await sendAPIRequest(
                `/api/search-content/?animal_id=${currentAnimal.value}&disease_id=${currentDisease.value}&language_id=${componentLanguage}&is_terrestrial_code=${props.isTerrestrialCode}&is_terrestrial_manual=${props.isTerrestrialManual}&is_aquatic_code=${props.isAquaticCode}&is_aquatic_manual=${props.isAquaticManual}&filter_id=${selectedDynFilterValue.value ?? ""}`
            );
            const res = await ret.json();
            if (res.success && res.status === 200) {
                setSearchedData(modifySearchResults(res?.data));
                setData([]);
                setDocumentMetaData([]);
            }
        } catch (e) {
            console.error({ e });
        }
        setIsLoading({ ...isLoading, loading_search_data: false });
    }


    const handleChangeSearch = async () => {
        if (currentAnimal && currentDisease) {
            if (animalGroup.length > 0 && availableDiseases.length > 0) {
                setShowComponent(true);
                setShowChapterDetails(false);
                await loadSearchedStandardsData();
                setShortName('');
            }
        } else {
            displayNotification('error', 'Please select values from both dropdowns [F]');
        }
    }

    const resetFilters = () => {
        setCurrentAnimal('');
        setCurrentDisease('');
        setCurrentSelected('');
        setSelectedDynFilter({
            value: "",
            label: ""
        })
        setSelectedDynFilterValue({
            value: "",
            label: ""
        });
        setSearchedData([]);
        setShowComponent(false);
        setShowChapterDetails(false);
        setShortName('');
        setComponentLanguage('102');

    }

    const changeComponentLanguage = async (lang) => {
        setComponentLanguage(lang);
    }

    const getChapterData = async (isManual, d) => {
        setIsLoading({ ...isLoading, loading_data: true });
        try {
            const ret = await sendAPIRequest(`/api/contents/navigation-chapter-preview/?is_rid=true&RID=${d.RID}&language_id=${componentLanguage}&is_manual=${isManual}`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                const modfiFiedData = handleReplaceUrlForChapterLinking(res.data)
                setData(modfiFiedData);
                const section = res.data.filter(d => d.text_type === LIST_DETAIL_ELEMENTS.text_types.section_name)[0]?.ref_number;
                const chapter = res.data.filter(d => d.text_type === LIST_DETAIL_ELEMENTS.text_types.chapter_name)[0]?.ref_number;
                setDocumentMetaData(prevState => ({ ...prevState, section_name: section, chapter_name: chapter }));
            }
        } catch (e) {
            displayNotification('error', 'Failed to load chapter data [F]');
        }
        setIsLoading({ ...isLoading, loading_data: false });
    }
    const escapeRegExp = (string) => {
        return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    };

    const highlightSearchTerm = (htmlContent, search, currentIndex) => {
        if (typeof htmlContent !== 'string' || !search) return { highlightedContent: htmlContent, occurrences: 0 };
        const escapedSearch = escapeRegExp(search);
        const regex = new RegExp(`\\b(${escapedSearch})`, 'gi'); // Match whole words only
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlContent, 'text/html');
        let occurrences = 0;
        const highlightNode = (node) => {
            Array.from(node.childNodes).forEach((childNode) => {
                if (childNode.nodeType === Node.TEXT_NODE) {
                    const parts = childNode.textContent.split(regex);
                    const fragment = document.createDocumentFragment();
                    parts.forEach((part, index) => {
                        const span = document.createElement('span');
                        if (regex.test(part)) {
                            span.style.backgroundColor = 'yellow';
                            span.textContent = part;
                            const uniqueId = `highlight_${currentIndex + occurrences + 1}`; // Generate unique ID
                            span.id = uniqueId;
                            if (occurrences === currentIndex) {
                            }
                            occurrences++;
                        } else {
                            span.textContent = part;
                        }
                        fragment.appendChild(span);
                    });
                    childNode.parentNode.replaceChild(fragment, childNode);
                } else if (childNode.nodeType === Node.ELEMENT_NODE) {
                    highlightNode(childNode);
                }
            });
        };
        highlightNode(doc.body);
        const serializer = new XMLSerializer();
        const highlightedHtml = serializer.serializeToString(doc);
        return { highlightedContent: highlightedHtml, occurrences };
    };


    useEffect(() => {
        if (!search) {
            setHighlightedData(data);
            setCount(0);
            return;
        }
        let totalCount = 0;
        let itemOccurrences = 0;
        const highlighted = data.map(item => {
            const highlightedItem = {};
            for (const key in item) {
                if (Object.hasOwnProperty.call(item, key)) {
                    if (key === 'html_contents') {
                        const highlightedContents = item[key].map(htmlContent => {
                            const { highlightedContent, occurrences } = highlightSearchTerm(htmlContent.content, search, itemOccurrences);
                            itemOccurrences += occurrences;
                            return {
                                ...htmlContent,
                                content: highlightedContent
                            };
                        });
                        highlightedItem[key] = highlightedContents;
                    } else if (key === 'child') {
                        const highlightedChild = {};
                        for (const childKey in item[key]) {
                            if (Object.hasOwnProperty.call(item[key], childKey)) {
                                if (childKey === 'html_contents') {
                                    const highlightedChildContents = item[key][childKey].map(htmlContent => {
                                        const { highlightedContent, occurrences } = highlightSearchTerm(htmlContent.content, search, itemOccurrences);
                                        itemOccurrences += occurrences;
                                        return {
                                            ...htmlContent,
                                            content: highlightedContent
                                        };
                                    });
                                    highlightedChild[childKey] = highlightedChildContents;
                                } else {
                                    const { highlightedContent, occurrences } = highlightSearchTerm(item[key][childKey], search, itemOccurrences);
                                    if (occurrences > 0) {
                                        itemOccurrences += occurrences;
                                        highlightedChild[childKey] = highlightedContent;
                                    } else {
                                        highlightedChild[childKey] = item[key][childKey];
                                    }
                                }
                            }
                        }
                        highlightedItem[key] = highlightedChild;
                    } else {
                        const { highlightedContent, occurrences } = highlightSearchTerm(item[key], search, itemOccurrences);
                        if (occurrences > 0) {
                            itemOccurrences += occurrences;
                            highlightedItem[key] = highlightedContent;
                        } else {
                            highlightedItem[key] = item[key];
                        }
                    }
                }
            }
            return highlightedItem;
        });
        totalCount += itemOccurrences;
        setHighlightedData(highlighted);
        setCount(totalCount);
    }, [search, data]);

    const handleOnChapterClick = async (standard_type, structures_id, chapter_info) => {
        setSearch('')
        setDocumentMetaData({
            section_name: '',
            chapter_name: '',
            chapter_no: '',
            section_no: "",
            area_vol_no: ''
        })
        const d = searchedData.filter(d => d.id === structures_id)[0];
        if (standard_type === LIST_DETAIL_ELEMENTS.standard_type.manuals) {
            setIsManualSelected(true)
        } else {
            setIsManualSelected(false)
        }

        if (d) {
            setDocumentMetaData(prevState => ({
                ...prevState, section_no: d.section_no, area_vol_no: standard_type === LIST_DETAIL_ELEMENTS.standard_type.manuals ? d?.area_vol_no : null,
                chapter_no: d.chapter_no
            }));
        }
        setCurrentSelected(d);
        if (LIST_DETAIL_ELEMENTS.standard_type.codes === parseInt(standard_type)) {
            setIsLoading1(true);
            setIsManual(false);
            await getChapterData(false, d);
            setCodeID(structures_id)
            setCodestandardType(standard_type);
            setShowChapterDetails(true);
            setIsLoading1(false);
        } else {
            setIsLoading1(true);
            setIsManual(true);
            await getChapterData(true, d);
            setCodeID(structures_id)
            setManualstandardType(standard_type)
            setShowChapterDetails(true);
            setIsLoading1(false);
        }
    }

    const handleClearSearch = () => {
        setSearch('');
    };

    const handleIsExpand = () => {
        if (isExpanded == true) {
            setIsExpanded(false);
        } else {
            setIsExpanded(true);
        }
    };

    const increaseFontSize = () => {
        if (baseFontSize < 24) {
            setBaseFontSize(prevSize => prevSize + 2);
        }
    };

    const decreaseFontSize = () => {
        if (baseFontSize > 12) {
            setBaseFontSize(prevSize => prevSize - 2);
        }
    };

    const handlePrint = () => {
        if (RID) {
            const file_name = `WOAH_Report_${currentDate}.pdf`;
            const url = getBaseURL(`/portal/content-navigation/print?RId=${RID}&language_id=${componentLanguage}&fileName=${file_name}&is_manual=${isManual}`);
            window.open(url, "self");
        } else {
            displayNotification('warning', `${t("-1", "No content available for  print")}`);
        }
    };
    const sendDataToBackend = async () => {
        setGeneratingPDF(true);
        try {
            let generalText;
            let animalType = 0;
            let standardType = 0;
            let isCode = false;
            if (shortName === "TC") {
                animalType = LIST_DETAIL_ELEMENTS.animal_type.terrestrial;
                standardType = LIST_DETAIL_ELEMENTS.standard_type.codes;
                isCode = true;
            }
            else if (shortName === "AC") {
                animalType = LIST_DETAIL_ELEMENTS.animal_type.aquatic;
                standardType = LIST_DETAIL_ELEMENTS.standard_type.codes;
                isCode = true;
            }
            else if (shortName === "TM") {
                animalType = LIST_DETAIL_ELEMENTS.animal_type.terrestrial;
                standardType = LIST_DETAIL_ELEMENTS.standard_type.manuals;
                isCode = false;
            }
            else if (shortName === "AM") {
                animalType = LIST_DETAIL_ELEMENTS.animal_type.aquatic;
                standardType = LIST_DETAIL_ELEMENTS.standard_type.manuals;
                isCode = false;
            }
            displayNotification('info', 'Generating PDF please wait...');
            const fileName = `WOAH_Report_${currentDate}.pdf`;
            const ret = await sendAPIRequest(
                `/api/content-navigation-pdf/`,
                'POST',
                {
                    chapter: RID,
                    isManual: isManual,
                    componentLanguage: componentLanguage,
                    animal_type: animalType,
                    standard_type: standardType,
                    volumeNo: standardType === LIST_DETAIL_ELEMENTS.standard_type.manuals ? documentMetaData.area_vol_no : null,
                });

            const data = await ret.json();
            if (data.success && data.status === 200) {
                const downloadResponse = await sendAPIRequest(`/api/content-navigation-pdf/download/?file_name=${fileName}&uniqueName=${data.data.file_name}`);
                if (downloadResponse.ok) {
                    const blob = await downloadResponse.blob();
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = fileName ?? 'testing.pdf';
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                    window.URL.revokeObjectURL(url);
                    displayNotification('success', 'Successfully downloaded file');
                } else {
                    displayNotification('error', 'Error downloading file');
                }
            } else {
                displayNotification('error', 'Error creating PDF');
            }
        } catch (error) {
            console.error('Error sending data:', error);
        }
        setGeneratingPDF(false);
    };


    const laodMetaData = async (id) => {
        try {
            const ret = await sendAPIRequest(`/api/html/manual-heading?id=${id}`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                setDocumentMetaData(prevState => ({
                    ...prevState,
                    section_name: res.data.section_name,
                    chapter_name: res.data.chapter_name,
                    section_no: res.data.section_no,
                    chapter_no: res.data.chapter_no,
                    area_vol_no: res.data.area_vol_no
                }));
            }
        } catch (error) {
            console.log(error)
        }
    }

    const isInViewport = (element) => {
        const rect = element.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    };

    return (
        <>
            <Box sx={{ flexGrow: 1 }} mt={2}>
                <Grid container spacing={2}>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                        <AutoCompleteDropdown
                            className={isLoading.load_animal_group ? "opacity-50" : "opacity-100"}
                            disabled={isLoading.load_animal_group}
                            isGroup={false}
                            label={isLoading.loading_filters ? 'loading...' : t("50", "Animal Group")}
                            showClearIcon={true}
                            error={false}
                            required={true}
                            multiple={false}
                            hasselectall={false}
                            options={animalsGroupOptions}
                            onChange={(event, value) => {
                                setCurrentAnimal(animalsGroupOptions.find(animalsGroupOptions => animalsGroupOptions.value === value?.value));
                            }}
                            value={
                                !currentAnimal?.value
                                    ? { label: '', value: '' }
                                    : animalsGroupOptions.find(r => r.value == currentAnimal?.value) ?? { label: '', value: '' }
                            }
                            size='medium'
                        />
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                        <AutoCompleteDropdown
                            className={isLoading.load_diseases ? "opacity-50" : "opacity-100"}
                            disabled={isLoading.load_diseases}
                            isGroup={false}
                            label={isLoading.loading_filters ? 'loading...' : t("51", "Diseases")}
                            showClearIcon={true}
                            error={false}
                            required={true}
                            multiple={false}
                            hasselectall={false}
                            options={diseaseOptions}
                            onChange={(event, value) => {
                                setCurrentDisease(diseaseOptions.find(diseaseOptions => diseaseOptions.value === value?.value));
                            }}
                            value={
                                !currentDisease?.value
                                    ? { label: '', value: '' }
                                    : diseaseOptions.find(r => r.value == currentDisease?.value) ?? { label: '', value: '' }
                            }
                            size='medium'
                        />
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                        <AutoCompleteDropdown
                            className={isLoading.loading_dynamic_filters ? 'opacity-50' : 'opacity-100'}
                            disabled={isLoading.loading_dynamic_filters}
                            label={isLoading.loading_dynamic_filters ? 'loading...' : t("52", "Dynamic Category")}
                            showClearIcon={true}
                            error={false}
                            required={false}
                            multiple={false}
                            hasselectall={false}
                            options={filtersOptions}
                            onChange={(event, value) => {
                                if (!value) {
                                    setSelectedDynFilter({
                                        value: "",
                                        label: ""
                                    })
                                    return
                                }
                                setSelectedDynFilterValue({
                                    value: "",
                                    label: ""
                                })
                                setSelectedDynFilter({
                                    value: value.value,
                                    label: value.label,
                                })
                            }}
                            value={
                                !selectedDynFilter.value
                                    ? { value: '', label: '' }
                                    : filtersOptions.find((r) => parseInt(r.value) === parseInt(selectedDynFilter.value)) ?? { value: '', label: '' }
                            }
                            size='medium'
                        />
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                        <AutoCompleteDropdown
                            className={isLoading.loading_dynamic_filter_value ? 'opacity-50' : 'opacity-100'}
                            disabled={isLoading.loading_dynamic_filter_value}
                            label={isLoading.loading_dynamic_filter_value ? 'loading...' : t("53", "Filter Value")}
                            showClearIcon={true}
                            error={false}
                            required={false}
                            multiple={false}
                            hasselectall={false}
                            options={filtersValueOptions}
                            onChange={(event, value) => {
                                if (!value) {
                                    setSelectedDynFilterValue({
                                        value: "",
                                        label: ""
                                    })
                                    return;
                                }
                                setSelectedDynFilterValue({
                                    value: value.value,
                                    label: value.label,
                                })
                            }}
                            value={
                                !selectedDynFilterValue.value
                                    ? { value: '', label: '' }
                                    : filtersValueOptions.find((r) => parseInt(r.value) === parseInt(selectedDynFilterValue.value)) ?? { value: '', label: '' }
                            }
                            size='medium'
                        />
                    </Grid>

                </Grid>
            </Box>
            <Grid container justifyContent="flex-end" my={3}>
                <button
                    className="btn btn--primary"
                    onClick={handleChangeSearch}
                >
                    <span> {t("13", "Search")}</span>
                </button>
                <button
                    className="btn btn--outline ms-1"
                    onClick={() => {
                        resetFilters();
                    }}
                >
                    <span> {t("14", "Reset")}</span>
                </button>
            </Grid>
            {showComponent && (
                <Grid container spacing={1}>
                    {!isExpanded && (
                        <Grid item lg={4} md={4} sm={12} xs={12}>
                            <DataTable
                                getRowId={(row) => row?.id}
                                isLoading={isLoading.loading_search_data}
                                rows={searchedData}
                                className="text-white bg-primary rounded-0"
                                columns={[
                                    {
                                        field: "standards",
                                        headerName: t("87","Standards"),
                                        headerClassName: 'text-white bg-primary rounded-0 table-header-text',
                                        flex: 0,
                                        renderCell: params => (
                                            <>{params.row?.standard_short_name}</>
                                        )
                                    },
                                    {
                                        field: "results",
                                        headerName: t("15", "Results"),
                                        headerClassName: "text-white bg-primary rounded-0 table-header-text",
                                        flex: 2,
                                        renderCell: params => (
                                            <span
                                                className='text-primary cursor-pointer'
                                                onClick={() => {
                                                    handleOnChapterClick(params.row?.standard_type, params?.row.id, params.row);
                                                    setShortName(params.row?.standard_short_name)
                                                    setRID(params.row.RID)
                                                }}
                                            >
                                                {params.row?.chapter_name}
                                            </span>
                                        )
                                    },
                                ]}
                                hasIterator={false}
                                pagination={true}
                                hideFooter={true}
                                sx={{
                                    '& .MuiDataGrid-footerContainer': {
                                        display: 'none'
                                    },
                                    '& .MuiDataGrid-toolbarContainer': {
                                        display: 'none'
                                    },
                                }}
                            />
                        </Grid>
                    )}
                    {isLoading1 ?
                        (<>
                            <Grid xs={8}>
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    textAlign: 'center',
                                    // marginTop: '30px'
                                }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <CircularProgress />
                                        <Typography variant="body1" sx={{ marginLeft: '10px' }}>
                                            {t('54', 'Loading data please wait...')}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                        </>) :
                        true &&

                        (<>
                            <Grid item lg={isExpanded ? 12 : 8} md={isExpanded ? 12 : 8} sm={12} xs={12}>
                                <CustomCard type='header' sx={{
                                    marginTop: '0'
                                }}>
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Box marginY={1}
                                            sx={{
                                                display: 'flex',
                                                fontSize: '12px', justifyItems: 'start', justifyContent: 'start', alignItems: 'center'
                                            }}
                                        >
                                            {
                                                currentSelected ? <>
                                                    <span style={{
                                                        color: 'inherit',
                                                        marginRight: '14px',
                                                    }}
                                                    >
                                                        {
                                                            currentSelected.standard_short_name
                                                        }
                                                    </span>
                                                    <span>/</span>
                                                </> : <></>
                                            }
                                            <a
                                                href={
                                                    codeId
                                                        ? getBaseURL(`/portal/chapter/?rid=${RID}&language=${componentLanguage}&ismanual=${isManual}`)
                                                        : ""
                                                }
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className='text-primary '
                                                style={{
                                                    color: 'inherit',
                                                    marginLeft: '14px',
                                                    marginRight: '14px',
                                                }}
                                            >
                                                {
                                                    currentSelected && currentSelected.chapter_name
                                                }
                                            </a>
                                        </Box>
                                        <Box sx={{ display: 'flex', justifyItems: 'end', justifyContent: 'end', alignItems: 'center' }}>
                                            <Typography
                                                variant="button"
                                                sx={{
                                                    cursor: 'pointer',
                                                    marginX: '5px',
                                                    textDecoration: parseInt(componentLanguage) === LIST_DETAIL_ELEMENTS.languages.english ? 'underline' : 'none',
                                                }}
                                                onClick={() => changeComponentLanguage(LIST_DETAIL_ELEMENTS.languages.english)}
                                            >
                                                EN
                                            </Typography>
                                            <Typography
                                                variant="button"
                                                sx={{
                                                    cursor: 'pointer',
                                                    marginX: '5px',
                                                    textDecoration: parseInt(componentLanguage) === LIST_DETAIL_ELEMENTS.languages.french ? 'underline' : 'none',
                                                }}
                                                onClick={() => (changeComponentLanguage(LIST_DETAIL_ELEMENTS.languages.french))}
                                            >
                                                FR
                                            </Typography>
                                            <Typography
                                                variant="button"
                                                sx={{
                                                    cursor: 'pointer',
                                                    marginX: '5px',
                                                    textDecoration: parseInt(componentLanguage) === LIST_DETAIL_ELEMENTS.languages.spanish ? 'underline' : 'none',
                                                    paddingRight: 1
                                                }}
                                                onClick={() => (changeComponentLanguage(LIST_DETAIL_ELEMENTS.languages.spanish))}
                                            >
                                                ES
                                            </Typography>
                                            <Divider orientation="vertical" flexItem style={{ backgroundColor: '#000', opacity: 0.87 }} />
                                            <Typography
                                                variant="h6"
                                                ms={2}
                                                sx={{
                                                    cursor: 'pointer',
                                                    paddingLeft: 1,
                                                }}
                                                onClick={increaseFontSize}
                                            >
                                                A
                                            </Typography>
                                            <Typography
                                                variant="subtitle1"
                                                mx={2}
                                                sx={{ cursor: 'pointer' }}
                                                onClick={decreaseFontSize}
                                            >
                                                A
                                            </Typography>
                                            <Divider orientation="vertical" flexItem style={{ backgroundColor: '#000', opacity: 0.87 }} />
                                            {
                                                generatingPDF ?
                                                    <LightTooltip title="Generating PDF" placement="top" style={{ margin: '1rem' }}>
                                                        <CircularProgress size={20} />
                                                    </LightTooltip> :
                                                    <LightTooltip title={t("55", "Download report PDF")} placement="top"
                                                    >
                                                        <IconButton
                                                            onClick={sendDataToBackend}>
                                                            <PictureAsPdfIcon
                                                                color='primary'
                                                            />
                                                        </IconButton>
                                                    </LightTooltip>
                                            }
                                            <LightTooltip title={t("56", "Print report")} placement="top"
                                            >
                                                <IconButton
                                                    onClick={handlePrint}>
                                                    <PrintIcon
                                                        color='primary'
                                                    />
                                                </IconButton>
                                            </LightTooltip>
                                            <LightTooltip
                                                title={
                                                    !isExpanded ? t("57", "Expand Chapter") : t("58", "Collapse Chapter")
                                                }
                                                placement="top"
                                            >
                                                <IconButton onClick={handleIsExpand} >
                                                    {isExpanded ? (
                                                        <ZoomInMapIcon color="primary" />
                                                    ) : (
                                                        <OpenWithIcon color="primary" />
                                                    )}
                                                </IconButton>
                                            </LightTooltip>
                                            <LightTooltip title={t("59", "Open in new tab")} placement="top">
                                                <a
                                                    href={
                                                        codeId
                                                            ? getBaseURL(`/portal/chapter/?rid=${RID}&language=${componentLanguage}&ismanual=${isManual}`)
                                                            : ""
                                                    }
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className={`cursor-pointer text-hover-primary border-0 ms-2`}
                                                >
                                                    <IconButton >
                                                        <OpenInNew color="primary" />
                                                    </IconButton>
                                                </a>
                                            </LightTooltip>
                                        </Box>
                                    </Box>
                                    <Divider />
                                    <Grid container spacing={2} alignItems="center" mt={1}>
                                        <Grid item xs={8} >
                                            <TextField
                                                required={false}
                                                error={false}
                                                fullWidth
                                                size="small"
                                                value={search}
                                                onChange={(e) => (setSearch(e.target.value), setCurrentTerm(0))}
                                                placeholder='Search content here...'
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <SearchIcon
                                                                style={{
                                                                    fontSize: 'medium'
                                                                }}
                                                            />
                                                        </InputAdornment>
                                                    ),
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            {search.length > 0 && (
                                                                <ClearIcon
                                                                    onClick={handleClearSearch}
                                                                    style={{
                                                                        cursor: 'pointer',
                                                                        fontSize: 'medium'
                                                                    }}
                                                                />
                                                            )}
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                sx={{
                                                    backgroundColor: '#fff',
                                                    paddingBottom: '20px',
                                                }}
                                            />
                                        </Grid>
                                        {
                                            search && search.length > 0 &&
                                            <Grid item xs={4}>
                                                <Grid sx={{ 'display': 'flex' }}>
                                                    <span className='cursor-pointer mx-1'
                                                        onClick={() => {
                                                            const previousTerm = currentTerm - 1;
                                                            if (previousTerm > 0) {
                                                                const element = document.getElementById(`highlight_${previousTerm}`);
                                                                if (currentElement) {
                                                                    currentElement.style.backgroundColor = 'yellow';
                                                                    currentElement.style.color = '';
                                                                }
                                                                if (element) {
                                                                    setCurrentElement(element);
                                                                    element.style.backgroundColor = '#ff4815';
                                                                    element.style.color = '#fff';

                                                                    if (!isInViewport(element)) {
                                                                        element.scrollIntoView({ behavior: "smooth" });
                                                                    }
                                                                }
                                                                setCurrentTerm(previousTerm);
                                                            }
                                                        }}
                                                    >
                                                        <KeyboardArrowUp sx={{
                                                            color: '#ff4815'
                                                        }}
                                                            fontSize="small"
                                                        />
                                                    </span>
                                                    <span className='cursor-pointer mx-1'
                                                        onClick={() => {
                                                            const nextTerm = currentTerm + 1;
                                                            if (nextTerm <= count) {
                                                                const element = document.getElementById(`highlight_${nextTerm}`);
                                                                if (currentElement) {
                                                                    currentElement.style.backgroundColor = 'yellow';
                                                                    currentElement.style.color = '';
                                                                }
                                                                if (element) {
                                                                    setCurrentElement(element);
                                                                    element.style.backgroundColor = '#ff4815';
                                                                    element.style.color = '#fff';

                                                                    if (!isInViewport(element)) {
                                                                        element.scrollIntoView({ behavior: "smooth" });
                                                                    }
                                                                }
                                                                setCurrentTerm(nextTerm);
                                                            }
                                                        }}
                                                    >
                                                        <KeyboardArrowDown sx={{
                                                            color: '#ff4815'
                                                        }}
                                                            fontSize="small"
                                                        />
                                                    </span>

                                                    <Typography
                                                        variant="body2"
                                                    >
                                                        {t("60", "Number of Matches:")} {count}
                                                    </Typography>

                                                </Grid>
                                            </Grid>
                                        }

                                    </Grid>
                                    <Box sx={{
                                        height: '600px',
                                        padding: '0rem 1rem',
                                        overflowY: 'auto',
                                        scrollbarWidth: 'thin',
                                        opacity: isLoading.loading_data ? 0.5 : 1
                                    }}
                                    >
                                        {
                                            isLoading.loading_data || isLoading.loading_search_data ? (
                                                <Box sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    textAlign: 'center',
                                                    marginTop: '30px'
                                                }}>
                                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                        <CircularProgress />
                                                        <Typography variant="body1" sx={{ marginLeft: '10px' }}>
                                                            {t('54', 'Loading data please wait...')}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            ) :
                                                highlightedData && highlightedData.length > 0 ? (
                                                    highlightedData.map((d, index) => (
                                                        <Box
                                                            key={`key-${index}`}
                                                            id={'key-${index}'}
                                                        >
                                                            {
                                                                d.text_type === LIST_DETAIL_ELEMENTS.text_types.section_name
                                                                && documentMetaData.chapter_name == 1
                                                                && (
                                                                    <Box
                                                                        key={`section-${d.id}`}
                                                                    >
                                                                        <Typography
                                                                            className="pb-5 pt-10 text-black text-center section-heading"
                                                                            sx={{ letterSpacing: "2px" }}
                                                                            key={d.id}
                                                                        >
                                                                            {
                                                                                isManualSelected ?
                                                                                    `${t('61', 'Section')} ${documentMetaData.area_vol_no}.${documentMetaData.section_no}` :
                                                                                    `${t('61', 'Section')} ${documentMetaData.section_name}`
                                                                            }
                                                                        </Typography>
                                                                        <Typography
                                                                            className="chapter-heading"
                                                                            sx={{
                                                                                letterSpacing: "2px",
                                                                                textAlign: "center"
                                                                            }}
                                                                            key={`name-${d.id}`}
                                                                        >
                                                                            <HtmlRenderer
                                                                                tag={"p"}
                                                                                htmlContent={d?.standard_content}
                                                                            />
                                                                        </Typography>
                                                                        <Divider
                                                                            sx={{
                                                                                width: "50%",
                                                                                marginX: 'auto'
                                                                            }}
                                                                        />
                                                                        <Divider
                                                                            sx={{
                                                                                width: "50%",
                                                                                paddingTop: '5px',
                                                                                marginX: 'auto'
                                                                            }}
                                                                        />
                                                                    </Box>
                                                                )
                                                            }
                                                            {
                                                                d.text_type === LIST_DETAIL_ELEMENTS.text_types.chapter_name && (
                                                                    <Box key={`chapter-${d.id}`}>
                                                                        <Box
                                                                            className="pb-5 pt-10 text-black text-center section-heading"
                                                                            sx={{
                                                                                letterSpacing: "2px",
                                                                                textAlign: "center",
                                                                                paddingTop: '15px'
                                                                            }}
                                                                            key={d.id}
                                                                        >
                                                                            {
                                                                                isManualSelected ?
                                                                                    `${t("43", "Chapter")} ${documentMetaData.area_vol_no}.${documentMetaData.section_no}.${documentMetaData.chapter_no}` :
                                                                                    `${t("43", "Chapter")} ${documentMetaData.section_name}.${documentMetaData.chapter_name}.`
                                                                            }
                                                                        </Box>
                                                                        <Typography
                                                                            className="chapter-heading"
                                                                            variant="h3"
                                                                            sx={{
                                                                                letterSpacing: "2px",
                                                                                textAlign: "center"
                                                                            }}
                                                                            key={`name-${d.id}`}
                                                                        >
                                                                            <HtmlRenderer
                                                                                tag={"p"}
                                                                                className="document-chftre-intitule"
                                                                                htmlContent={d?.standard_content}
                                                                            />
                                                                        </Typography>
                                                                    </Box>
                                                                )
                                                            }
                                                            {
                                                                (d.text_type === LIST_DETAIL_ELEMENTS.text_types.article_heading) && (
                                                                    <Box
                                                                        key={`article-${d.id}`}
                                                                        id={`article-${d.id}`}
                                                                    >
                                                                        <HtmlRenderer
                                                                            tag={"div"}
                                                                            className="text-center"
                                                                            style={{ fontSize: `${baseFontSize + 2}px`, marginBottom: '20px', marginTop: '10px' }}
                                                                            htmlContent={d?.standard_content}
                                                                        />
                                                                        {
                                                                            d.html_contents &&
                                                                                d.html_contents.length > 0 ? (
                                                                                d.html_contents.map((child) => (
                                                                                    <Box
                                                                                        sx={{
                                                                                            paddingX: '4px',
                                                                                            textAlign: 'justify',
                                                                                        }}
                                                                                        key={`art-child-${child.id}`}
                                                                                    >
                                                                                        <HtmlRenderer
                                                                                            tag={"div"}
                                                                                            style={{ fontSize: `${baseFontSize}px`, }}
                                                                                            htmlContent={child.content}
                                                                                        />
                                                                                    </Box>
                                                                                ))
                                                                            ) : (
                                                                                d.child && (
                                                                                    <Box
                                                                                        key={`general-${d.child.id}`}
                                                                                        id={`${d.child.id}`}
                                                                                    >
                                                                                        <HtmlRenderer
                                                                                            tag={"div"}
                                                                                            className={LIST_DETAIL_ELEMENTS.heading_types.general_heading === d.child.text_type}
                                                                                            style={{ fontSize: `${baseFontSize + 2}px` }}
                                                                                            htmlContent={d.child?.standard_content}
                                                                                        />
                                                                                        {d.child.html_contents &&
                                                                                            d.child.html_contents.length > 0 &&
                                                                                            d.child.html_contents.map((child, i) => (
                                                                                                <>
                                                                                                    {child.text_type === LIST_DETAIL_ELEMENTS.styles_categories.code_editing_date_text && (
                                                                                                        <Divider />
                                                                                                    )}
                                                                                                    <Box
                                                                                                        key={`gen-child-${child.id}`}
                                                                                                        sx={{
                                                                                                            display: 'flex',
                                                                                                            justifyContent: child.text_type === LIST_DETAIL_ELEMENTS.styles_categories.code_editing_date_text ? 'flex-end' : 'normal',
                                                                                                        }}
                                                                                                    >
                                                                                                        <Box className={LIST_DETAIL_ELEMENTS.heading_types.general_heading === d.child.text_type ? "text-justified" : 'justify-content-end'}>
                                                                                                            <HtmlRenderer
                                                                                                                tag={LIST_DETAIL_ELEMENTS.heading_types.general_heading === d.child.text_type ? "div" : "p"}
                                                                                                                style={{
                                                                                                                    fontSize: `${baseFontSize}px`,
                                                                                                                }}
                                                                                                                htmlContent={child.content}
                                                                                                            />
                                                                                                        </Box>
                                                                                                    </Box>
                                                                                                </>
                                                                                            ))}
                                                                                    </Box>
                                                                                )
                                                                            )
                                                                        }
                                                                    </Box>
                                                                )
                                                            }
                                                            {
                                                                (
                                                                    d.text_type === LIST_DETAIL_ELEMENTS.text_types.manual_heading
                                                                    || d.text_type === LIST_DETAIL_ELEMENTS.text_types.manual_reference
                                                                    || d.text_type === LIST_DETAIL_ELEMENTS.text_types.manual_appendex
                                                                    || d.text_type === LIST_DETAIL_ELEMENTS.text_types.manual_biblography
                                                                    || d.text_type === LIST_DETAIL_ELEMENTS.text_types.manual_Further_reading
                                                                    || d.text_type === LIST_DETAIL_ELEMENTS.text_types.manual_online_resource
                                                                    || d.text_type === LIST_DETAIL_ELEMENTS.text_types.manual_paragraph
                                                                    || d.text_type === LIST_DETAIL_ELEMENTS.styles_categories.code_editing_date_text
                                                                ) &&
                                                                <Box
                                                                    key={`article-${d.id}`}
                                                                    id={`article-${d.id}`}
                                                                >
                                                                    <HtmlRenderer
                                                                        tag={"div"}
                                                                        className="text-center"
                                                                        style={{ fontSize: `${baseFontSize + 2}px`, marginBottom: '20px', marginTop: '10px' }}
                                                                        htmlContent={d?.standard_content}
                                                                    />
                                                                    {
                                                                        d.html_contents &&
                                                                            d.html_contents.length > 0 ? (
                                                                            d.html_contents.map((child) => (
                                                                                <Box
                                                                                    sx={{
                                                                                        paddingX: '4px',
                                                                                        textAlign: 'justify',
                                                                                    }}
                                                                                    key={`art-child-${child.id}`}
                                                                                >
                                                                                    <HtmlRenderer
                                                                                        tag={"div"}
                                                                                        style={{ fontSize: `${baseFontSize}px`, }}
                                                                                        htmlContent={child.content}
                                                                                    />
                                                                                </Box>
                                                                            ))
                                                                        ) : (
                                                                            d.child && (
                                                                                <Box
                                                                                    key={`general-${d.child.id}`}
                                                                                    id={`${d.child.id}`}
                                                                                >
                                                                                    <HtmlRenderer
                                                                                        tag={"div"}
                                                                                        className={LIST_DETAIL_ELEMENTS.heading_types.general_heading === d.child.text_type}
                                                                                        style={{ fontSize: `${baseFontSize + 2}px` }}
                                                                                        htmlContent={d.child?.standard_content}
                                                                                    />
                                                                                    {d.child.html_contents &&
                                                                                        d.child.html_contents.length > 0 &&
                                                                                        d.child.html_contents.map((child, i) => (
                                                                                            <>
                                                                                                {child.text_type === LIST_DETAIL_ELEMENTS.styles_categories.code_editing_date_text && (
                                                                                                    <Divider />
                                                                                                )}
                                                                                                <Box
                                                                                                    key={`gen-child-${child.id}`}
                                                                                                    sx={{
                                                                                                        display: 'flex',
                                                                                                        justifyContent: child.text_type === LIST_DETAIL_ELEMENTS.styles_categories.code_editing_date_text ? 'flex-end' : 'normal',
                                                                                                    }}
                                                                                                >
                                                                                                    <Box className={LIST_DETAIL_ELEMENTS.heading_types.general_heading === d.child.text_type ? "text-justified" : 'justify-content-end'}>
                                                                                                        <HtmlRenderer
                                                                                                            tag={LIST_DETAIL_ELEMENTS.heading_types.general_heading === d.child.text_type ? "div" : "p"}
                                                                                                            style={{
                                                                                                                fontSize: `${baseFontSize}px`,
                                                                                                            }}
                                                                                                            htmlContent={child.content}
                                                                                                        />
                                                                                                    </Box>
                                                                                                </Box>
                                                                                            </>
                                                                                        ))}
                                                                                </Box>
                                                                            )
                                                                        )
                                                                    }
                                                                </Box>
                                                            }
                                                        </Box>
                                                    ))
                                                ) : (
                                                    <Box sx={{ textAlign: 'center', marginTop: '30px' }}>
                                                        <Typography variant='body2' color={'primary'} sx={{
                                                            marginTop: '30px'
                                                        }}>
                                                            {t('62', 'Please select any chapter from table to show preview.')}
                                                        </Typography>
                                                    </Box>
                                                )
                                        }
                                    </Box>
                                </CustomCard>
                            </Grid>
                        </>)
                    }
                </Grid>
            )}
        </>
    )
}

export default SearchByFilter