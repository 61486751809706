import { GridActionsCellItem } from "@mui/x-data-grid";
import { displayNotification, sendAPIRequest } from "helpers";
import DataTable from "pages/components/DataTable";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { setCurrentPage } from "redux/generalSlice";
import CreateTaging from "./CreateTaging";

const Index = () => {
    const [location, setLocation] = useSearchParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [refetch, setRefetch] = useState(false);
    const [isAddNew, setAddNew] = useState(false);
    const [chapterDetail, setChapterDetail] = useState(null);
    const { id } = useParams();
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const { section_no, chapter_no, article_no, chapter_id, version_year } = {
        section_no: location.get('section_no'),
        chapter_no: location.get('chapter_no'),
        article_no: location.get('article_no'),
        chapter_id: location.get('chapter_id'),
        version_year: location.get('version_year'),
    };
    const [disesaesDetails, setDiseasesDetails] = useState(null);
    useEffect(() => {
        dispatch(setCurrentPage("administration"));
        handleLoadData();
        loadChapterDetail();
    }, [refetch]);

    const loadData = async () => {
        setIsLoading(true);
        try {
            const ret = await sendAPIRequest(`/api/commodities-animals/get-search-commodities-database`);
            const res = await ret.json();
            if (res.success && res.status === 200) {

                setData(res.data);
            }
        } catch (err) {
            console.log({ err });
        }
        setIsLoading(false);
    }

    const loadChapterDetail = async () => {
        setIsLoading(true);
        try {
            const ret = await sendAPIRequest(`/api/draft-chapter/get-chapter-v2/${chapter_id ?? ""}`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                let newData = res.data
                if (article_no) {
                    newData = { ...newData, article_no: parseInt(article_no), chapter_id: chapter_id ?? "" };
                }
                setChapterDetail(newData);
            }
        } catch (err) {
            console.log({ err });
        }
        setIsLoading(false);
    }

    const handleLoadData = async () => {
        setIsLoading(true);
        try {
            const ret = await sendAPIRequest(`/api/draft-commodities-animals/draft/get-commodities/?chapter_no=${chapter_no ?? ''}&section_no=${section_no ?? ""}&article_no=${article_no ?? ""}&version_year=${version_year ?? ""}`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                //     if (res.data.length > 0) {
                //         let dis = res.data.find((i) => i.disease_id !== null || i.disease_id !== undefined || i.disease_id !== "")
                //         if (dis) {
                //             let tempData = {
                //                 disease_id: dis?.draft_disease?.id ?? "",
                //                 disease_label: dis?.draft_disease?.list_element_name ?? "",
                //                 draft_recommendations_id: dis?.draft_recommendations?.id ?? "",
                //                 draft_recommendations_label: dis?.draft_recommendations?.list_element_name ?? "",
                //                 recommendations_type: dis?.draft_status?.list_element_name ?? "",
                //                 recommendations_type_id: dis?.draft_status?.id ?? "",
                //             }
                //             setDiseasesDetails(tempData);
                //         } else setDiseasesDetails(null);
                //     } else 
                setDiseasesDetails(null);
                setData(res.data);
            } else {
                displayNotification('error', 'Error loading data [B]');
            }
        } catch (err) {
            console.log({ err });
            displayNotification('error', 'Error loading data [F]');
        }
        setIsLoading(false);
    }

    const handleDelete = async (id) => {
        try {
            if (!window.confirm('Are you sure you want to delete?')) {
                return;
            }
            const ret = await sendAPIRequest(`/api/draft-commodities-animals/delete/${id}`, 'DELETE');
            const res = await ret.json();
            if (res.success && res.status === 200) {
                await handleLoadData();
                displayNotification('success', 'Successfully deleted data [B]');
            } else {
                displayNotification('error', 'Failed to delete data [B]');
            }
        } catch (err) {
            console.error({ err });
            displayNotification('error', 'Failed to delete data [F]');
        }
    }

    const handleOpenCloseForm = () => {
        if (isAddNew) {
            setAddNew(false)
        } else {
            setAddNew(true)
        }
    }


    return (
        <div>
            <div className="container my-2">
                <div className="header card-woah border border-secondary d-flex px-4 py-2 my-2 shadow-sm w-100">
                    <div className='d-flex justify-content-between w-100'>
                        <div className="card-title1 lh-1">{t("-1", "Commodity Tagging")} ({t("-1", "Article")}{section_no}.{chapter_no}.{article_no})</div>
                        <button
                            className="btn btn--primary font-custom fs-6 ms-1"
                            onClick={() => handleOpenCloseForm()}
                        >
                            {!isAddNew ? t("-1", "Add New") : t("-1", "Close")}
                        </button>
                    </div>
                </div>
                {
                    isAddNew && <CreateTaging refetch={refetch} setRefetch={setRefetch} chapterDetail={chapterDetail} disesaesDetails={disesaesDetails} is_draft={true} />
                }
                <div className="header card-woah border border-secondary d-flex px-4 py-2 shadow-sm w-100">
                    <DataTable
                        getRowId={(row) => row.id}
                        isLoading={isLoading}
                        rows={data}
                        className="text-white bg-primary rounded-0"
                        columns={
                            [
                                {
                                    field: "commodity_group_name",
                                    headerName: t("-1", "Commodity Group"),
                                    headerClassName: "text-white bg-primary rounded-0",
                                    flex: 1,
                                    valueGetter: (params) => {
                                        return params.row?.commodity_group?.commodity_group_name
                                    },
                                },
                                {
                                    field: "commodity_name",
                                    headerName: t("-1", "Commodity"),
                                    headerClassName: "text-white bg-primary rounded-0",
                                    flex: 1,
                                    valueGetter: (params) => {
                                        return params.row?.commodity?.commodity_name
                                    },
                                },
                                {
                                    field: "animal_name",
                                    headerName: t("-1", "Animal Group"),
                                    headerClassName: "text-white bg-primary rounded-0",
                                    flex: 1,
                                    valueGetter: (params) => {
                                        return params.row?.animal?.animal_name
                                    },
                                },
                                {
                                    field: "recommendation",
                                    headerName: t("-1", "Recommendation"),
                                    headerClassName: "text-white bg-primary rounded-0",
                                    flex: 1,
                                    valueGetter: (params) => {
                                        return params.row?.draft_recommendations?.list_element_name
                                    },
                                },
                                {
                                    field: "status_type",
                                    headerName: t("-1", "Recommendation Status Type"),
                                    headerClassName: "text-white bg-primary rounded-0",
                                    flex: 1,
                                    valueGetter: (params) => {
                                        return params.row?.draft_status?.list_element_name
                                    },
                                },
                                {
                                    field: "disease",
                                    headerName: t("-1", "Disease"),
                                    headerClassName: "text-white bg-primary rounded-0",
                                    flex: 1,
                                    valueGetter: (params) => {
                                        return params.row?.draft_diseases?.disease_name
                                    },
                                },
                                {
                                    field: "actions",
                                    type: "actions",
                                    headerClassName: "text-white bg-primary rounded-0",
                                    headerName: t("-1", "Actions"),
                                    getActions: (params) => {
                                        return [
                                            <GridActionsCellItem
                                                label={
                                                    "Edit"
                                                }
                                                key={0}
                                                showInMenu
                                                onClick={() => {
                                                    navigate(`/portal/draft/taging/${params.row.id}/edit`);
                                                }}
                                            />,
                                            <GridActionsCellItem
                                                label={
                                                    "Delete"
                                                }
                                                key={0}
                                                showInMenu
                                                onClick={() => {
                                                    handleDelete(params.row.id);
                                                }}
                                            />,
                                        ]
                                    }
                                },
                            ]
                        }
                    />
                </div>
            </div>
        </div>
    )
}

export default Index;