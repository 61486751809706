import { useState } from 'react';

const useLocalStorage = (key, initialValue) => {
    const [storedValue, setStoredValue] = useState(() => {
        try {
            const item = window.localStorage.getItem(key);
            return item ? JSON.parse(item) : initialValue;
        } catch (error) {
            console.log(error);
            return initialValue;
        }
    });

    /**
     * Set value of item in local storage
     * @param {*} value 
     */
    const setItem = (value) => {
        try {
            const valueToStore = value instanceof Function ? value(storedValue) : value;
            setStoredValue(valueToStore);
            window.localStorage.setItem(key, JSON.stringify(valueToStore));
        } catch (error) {
            console.log(error);
        }
    };

    /**
     * Remove item from local storage
     */
    const removeItem = () => {
        try {
            setStoredValue(undefined);
            window.localStorage.removeItem(key);
        } catch (error) {
            console.log(error);
        }
    };

    return { storedValue, setItem, removeItem };
};

export default useLocalStorage;
