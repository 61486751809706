import React, { useRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentPage, setHeaderTheme } from 'redux/generalSlice';
import { useNavigate, useParams } from 'react-router';
import { displayNotification, getStyleName, sendAPIRequest } from 'helpers';
import Spinner from 'components/spinner/Spinner';
import EditModal from 'components/modals/EditModal'
import { LIST_DETAIL_ELEMENTS } from 'constants';
import collect from 'collect.js';
import DropDownMenu from './DropDownMenu';
import IgnoreDataModal from 'components/modals/IgnoreDataModal';
import ParentChildLinking from './ParentChildLinking';
import { useSearchParams } from 'react-router-dom';
import StyleDropDownMenu from './StyleDropDownMenu';
import SplitModal from 'components/modals/SplitModal';
import TextFieldCustom from 'components/TextFieldCustom';
import cloneDeep from 'lodash/cloneDeep';
import HtmlRenderer from 'components/HtmlRenderer';

const DataMapping = () => {

    const { id } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const currentPage = searchParams.get('page');
    const showMetaData = searchParams.get('meta-data') === 'true' ? true : false;
    const isMigrated = searchParams.get('is-migrated') === 'true' ? true : false;
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState({
        load_data: true,
        save_data: false,
    });
    const editRef = useRef();
    const splitRef = useRef();
    const [ignoreData, setIgnoreData] = useState([]);
    const [selectedValue, setSelectedValue] = useState('link');
    const [styleList, setStyleList] = useState([]);
    const [matchedCount, setMatchedCount] = useState(0);
    const [dataForSearch, setDataForSearch] = useState([]);
    const authData = useSelector(s => s.auth.data);
    // let dataForSearch = [];

    useEffect(() => {
        dispatch(setHeaderTheme('white'));
        dispatch(setCurrentPage("data-migration"));
        loadStyleList();
        loadData();
        loadIgnoredData();
    }, []);

    const goBack = () => {
        navigate(`/portal/data-migrations`);
    }

    const loadData = async () => {
        setIsLoading({ ...isLoading, load_data: true });
        try {
            const ret = await sendAPIRequest(`/api/data-mapping/${id}`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                setDataForSearch([...res.data]);
                setData([...res.data]);
            }
        } catch (e) {
            console.log({ e });
        }
        setIsLoading({ ...isLoading, load_data: false });
    }

    const loadStyleList = async () => {
        try {
            const ret = await sendAPIRequest(`/api/lists/master/9`);
            const res = await ret.json();
            if (res.success && ret.status === 200) {
                setStyleList(res.data);
            }
        } catch (e) {
            console.log({ e });
        }
    }

    const loadIgnoredData = async () => {
        try {
            const ret = await sendAPIRequest(`/api/data-mapping/ignored-data/${id}`);
            const res = await ret.json();
            if (res.success && ret.status === 200) {
                setIgnoreData(res.data);
            }
        } catch (e) {
            console.log({ e });
        }
    }

    const updateContentAPI = async (info, id, is_child = false) => {
        try {
            let ret;
            if (is_child) {
                ret = await sendAPIRequest(
                    `/api/content-paragraphs/${id}`,
                    'PUT',
                    info
                );
            } else {
                ret = await sendAPIRequest(
                    `/api/contents/${id}`,
                    'PUT',
                    info
                );
            }
            const res = await ret.json();
            if (res.success && res.status === 200) {
                displayNotification('success', "Successfully updated content [B]");
            } else {
                displayNotification('error', 'Failed to update content [B]');
            }
        } catch (e) {
            console.log({ e });
            displayNotification('error', 'Failed to update content [F]');
        }
    }

    const updateContent = async (index, index2, updatedContent) => {
        let temp = [...data];
        if (index2 != null) {
            temp[index].children[index2].json_content = updatedContent;
            isMigrated && await updateContentAPI({ content_text: updatedContent }, temp[index].children[index2].id, true);
        } else {
            temp[index].json_content = updatedContent;
            isMigrated && await updateContentAPI({ standard_content: updatedContent }, temp[index].id);
        }
        setData([...temp]);
    }

    const setContentNumberAPI = async (id, info) => {
        try {
            const ret = await sendAPIRequest(
                `/api/contents/${id}`,
                'PUT',
                info
            );
            const res = await ret.json();
            if (res.success && res.status === 200) {
                displayNotification('success', 'Successfully marked [B]');
                return true;
            } else {
                displayNotification('error', "Failed to mark [B]");
                return false;
            }
        } catch (e) {
            console.log({ e });
            displayNotification('error', "Failed to mark [F]");
            return false;
        }
    }

    const setContentNumber = async (index, index2, number, role, text_type) => {
        let temp = [...data];
        if (index2 != null) {
            const content = temp[index].children[index2];
            temp[index].children = temp[index].children.filter((val, key) => key != index2);
            content.role = role
            content.parent_id = ![LIST_DETAIL_ELEMENTS.text_types.section_name, LIST_DETAIL_ELEMENTS.text_types.chapter_name].includes(content.text_type) ? getChapterID() : null;
            content.text_type = text_type;
            content.ref_number = number;
            const isUpdated = await setContentNumberAPI(
                content.id,
                {
                    text_type: content.text_type,
                    ref_number: content.ref_number
                }
            );
            isUpdated && temp.push(content);
        } else {
            const isUpdated = await setContentNumberAPI(
                temp[index].id,
                {
                    parent_id: ![LIST_DETAIL_ELEMENTS.text_types.section_name, LIST_DETAIL_ELEMENTS.text_types.chapter_name].includes(temp[index].text_type) ? getChapterID() : null,
                    text_type: text_type,
                    ref_number: number
                }
            );
            if (isUpdated) {
                temp[index]['ref_number'] = number;
                temp[index].role = role;
                temp[index].text_type = text_type;
                if (role != LIST_DETAIL_ELEMENTS.text_types.article_heading) {
                    const children = temp[index].children;
                    temp[index].children = [];
                    children && children.length > 0 && temp.push(...children);
                }
            }
        }
        temp = collect(temp).sortBy("offset").toArray();
        setData([...temp]);
    }

    const ignoreContentAPI = async (id, is_child = false) => {
        try {
            let ret;
            if (!is_child) {
                ret = await sendAPIRequest(`/api/contents/${id}`, 'DELETE');
            } else {
                ret = await sendAPIRequest(`/api/content-paragraphs/${id}`, 'DELETE');
            }
            const res = await ret.json();
            if (res.success && res.status === 200) {
                displayNotification('success', 'Successfully ignored data [B]');
            } else {
                displayNotification('error', 'Failed to ignore data [B]');
            }
        } catch (e) {
            console.log(e);
            displayNotification('error', 'Failed to ignore data [F]');
        }
    }

    const ignoreContent = async (index, index2) => {
        if (window.confirm('Are you sure you want to ignore')) {
            let temp = [...data];
            let content = null;
            if (index2 != null) {
                content = temp[index].children[index2];
                temp[index].children = temp[index].children.filter((val, key) => key != index2);
                await ignoreContentAPI(content.id, true);
            } else {
                content = temp[index];
                temp = temp.filter((val, key) => key != index);
                await ignoreContentAPI(content.id, false);
            }
            setData([...temp]);
            content && setIgnoreData([...ignoreData, content]);
        }
    }

    const restoreIgnoreDataAPI = async (content) => {
        try {
            const data = content.map(info =>
            (
                {
                    text_type: LIST_DETAIL_ELEMENTS.text_types.paragraph,
                    standard_content: info.json_content,
                    document_id: info.document_id,
                    level: info.level ?? 0,
                    parent_id: info.parent_id ?? null,
                    offset: info.offset,
                    style_id: info.style_id ?? null,
                    ref_number: info.ref_number ?? null,
                    createdBy: authData.id ?? null,
                }
            ));
            const ret = await sendAPIRequest(`/api/contents/bulk-create`, 'POST', data);
            const res = await ret.json();
            if (res.success && res.status === 201) {
                displayNotification('success', 'Successfully completed process [B]');
                await loadData();
            } else {
                displayNotification('error', 'Failed to complete process [B]');
            }
        } catch (e) {
            console.log(e);
            displayNotification('error', 'Failed to complete process [F]');
        }
    }

    const restoreIgnoreData = async (content, selectedIDs) => {
        try {
            let temp = [...data, ...content];
            temp = collect(temp).sortBy("offset").toArray();
            setData([...temp]);
            let tempIgnoreData = [...ignoreData];
            tempIgnoreData = tempIgnoreData.filter(t => !selectedIDs.includes(t.id));
            setIgnoreData([...tempIgnoreData]);
            await restoreIgnoreDataAPI(content);
            displayNotification('success', 'Successfully restored data [F]');
        } catch (e) {
            console.log({ e });
            displayNotification('error', 'Failed to restore data [F]');
        }
    }

    const handleMarkAsParagraph = async (index) => {
        try {
            let temp = [...data];
            const children = temp[index].children;
            temp[index].children = [];
            temp[index].role = "articleParagraph";
            temp[index].text_type = LIST_DETAIL_ELEMENTS.text_types.paragraph;
            children && children.length > 0 && temp.push(...children);
            temp = collect(temp).sortBy("offset").toArray();
            if (isMigrated) {
                const ret = await sendAPIRequest(
                    `/api/contents/${temp[index].id}`,
                    'PUT',
                    {
                        text_type: temp[index].text_type,
                        standard_content: temp[index].json_content,
                        document_id: temp[index].document_id,
                        level: temp[index].level,
                        parent_id: null,
                        offset: temp[index].offset,
                        style_id: temp[index].style_id,
                        ref_number: temp[index].ref_number,
                        updatedBy: authData.id,
                    }
                );
                const res = await ret.json();
                if (res.success) {
                    displayNotification('success', 'Successfully marked [B]');
                } else {
                    displayNotification('error', "Failed to mark [B]");
                }
            }
            setData([...temp]);
        } catch (e) {
            displayNotification('error', "Failed to mark [F]");
        }
    }

    const handleAPIMarking = async (info, is_child = false) => {
        try {
            let ret;
            const data = {
                text_type: info.text_type,
                standard_content: info.json_content,
                document_id: info.document_id,
                level: info.level,
                parent_id: info.parent_id,
                offset: info.offset,
                style_id: info.style_id,
                ref_number: info.ref_number,
                updatedBy: authData.id,
            };
            if (!is_child) {
                ret = await sendAPIRequest(
                    `/api/contents/${info.id}`,
                    'PUT',
                    data
                );
            } else {
                ret = await sendAPIRequest(
                    `/api/contents/update-content-type/${info.id}/?is_child=true`,
                    'POST',
                    data
                );
            }
            const res = await ret.json();
            if (res.success && res.status === 200) {
                displayNotification('success', 'Successfully marked [B]');
            } else {
                displayNotification('error', "Failed to mark [B]");
            }
        } catch (e) {
            console.log({ e });
            displayNotification('error', "Failed to mark [F]");
        }
    }

    const getChapterID = () => {
        const chapterID = data.filter(d => d.text_type === LIST_DETAIL_ELEMENTS.text_types.chapter_name)[0];
        return chapterID.id;
    }

    const handleMarking = async (index, index2, role, text_type) => {
        let temp = [...data];
        if (index2 != null) {
            const section_number = temp[index].children[index2];
            temp[index].children = temp[index].children.filter((val, key) => key != index2);
            section_number.role = role
            section_number.text_type = text_type;
            section_number.offset = section_number.offset ?? 1;
            temp.push(section_number);
            await handleAPIMarking(section_number, true);
        } else {
            const children = temp[index].children;
            temp[index].children = [];
            temp[index].role = role;
            temp[index].text_type = text_type;
            temp[index].offset = temp[index].offset ?? 1;
            children && children.length > 0 && temp.push(...children);
            await handleAPIMarking(temp[index], false);
        }
        temp = collect(temp).sortBy("offset").toArray();
        setData([...temp]);
    }

    const handleRadioButtonChangeState = (value) => {
        setSelectedValue(value);
    }

    const linkChildAPI = async (info) => {
        try {
            const ret = await sendAPIRequest(
                `/api/content-paragraphs`,
                'POST',
                info
            );
            const res = await ret.json();
            if (res.success && res.status === 201) {
                displayNotification('success', 'Successfully linked child [B]');
            } else {
                displayNotification('error', 'Failed to link child [B]');
            }
        } catch (e) {
            console.log({ e });
            displayNotification('error', 'Failed to link child [F]');
        }
    }

    const linkHeadingWithArticle = async (article_id, heading_id) => {
        try {
            const ret = await sendAPIRequest(
                `/api/contents/${heading_id}`,
                'PUT',
                {
                    parent_id: article_id,
                }
            );
            const res = await ret.json();
            if (res.success && res.status === 200) {
                displayNotification('success', 'Successfully linked child [B]');
                await loadData();
            } else {
                displayNotification('error', 'Failed to link child [B]');
            }
        } catch (e) {
            console.log({ e });
            displayNotification('error', 'Failed to link child [F]');
        }
    }

    const linkChildWithParent = async (index, id, func) => {
        try {
            let temp = [...data];
            if (func === 'link') {
                let text = temp.filter((val, key) => (key === parseInt(id)));
                text = text.map(t => ({ ...t, parent_id: temp[index].id }));
                if (temp[index].text_type === LIST_DETAIL_ELEMENTS.text_types.article_heading) {
                    await linkHeadingWithArticle(temp[index].id, text[0].id);
                } else {
                    temp[index].children.push(...text);
                    temp = temp.filter((val, key) => (key != parseInt(id)));
                    // await linkChildAPI({
                    //     content_id: temp[index].id,
                    // })
                }
            } else {
                if (temp[index].text_type === LIST_DETAIL_ELEMENTS.text_types.article_heading) {
                    const text = temp.filter((val, key) => key === parseInt(id));
                    await linkHeadingWithArticle(null, text[0].id);
                } else {
                    const text = temp[index].children.filter((val, key) => key === parseInt(id))
                        .map(t => ({ ...t, parent_id: null }));
                    temp[index].children = temp[index].children.filter((val, key) => key != parseInt(id));
                    temp.push(...text);
                }
            }
            temp = collect(temp).sortBy("offset").toArray();
            temp[index].children = collect(temp[index].children).sortBy("offset").toArray();
            setData([...temp]);
            displayNotification('success', `Successfully ${func} [F]`);
        } catch (e) {
            console.log({ e });
            displayNotification('error', `Failed to ${func} [F]`);
        }
    }

    const storeData = async () => {
        if (window.confirm('Are you sure you want to save file data.')) {
            setIsLoading({ ...isLoading, save_data: true });
            try {
                const temp = collect(data).sortBy("offset").toArray();
                const ret = await sendAPIRequest(
                    `/api/data-mapping/${id}`,
                    'POST',
                    {
                        data: temp,
                    }
                );
                const res = await ret.json();
                if (res.success && res.status == 200) {
                    displayNotification('success', 'Successfully saved data [B]');
                    await loadData();
                    setIgnoreData([]);
                } else {
                    displayNotification('error', 'Failed to save data [B]');
                }
            } catch (e) {
                console.log({ e });
                displayNotification('error', 'Failed to save data [F]');
            }
            setIsLoading({ ...isLoading, save_data: false });
        }
    }

    const updateOffset = (index, index2, method) => {
        try {
            let temp = [...data];
            if (method === 'increase') {
                if (index2 != null) {
                    const offset = temp[index].children[index2].offset;
                    if (index2 - 1 >= 0) {
                        temp[index].children[index2].offset = temp[index].children[index2 - 1].offset;
                        temp[index].children[index2 - 1].offset = offset;
                    }
                    temp[index].children = collect(temp[index].children).sortBy("offset").toArray();
                } else {
                    const offset = temp[index].offset;
                    if (index - 1 >= 0) {
                        temp[index].offset = temp[index - 1].offset;
                        temp[index - 1].offset = offset;
                    }
                    temp = collect(temp).sortBy("offset").toArray();
                }
            } else if (method === "decrease") {
                if (index2 != null) {
                    const offset = temp[index].children[index2].offset;
                    if (temp[index].children.length > index2 + 1) {
                        temp[index].children[index2].offset = temp[index].children[index2 + 1].offset;
                        temp[index].children[index2 + 1].offset = offset;
                    }
                    temp[index].children = collect(temp[index].children).sortBy("offset").toArray();
                } else {
                    const offset = temp[index].offset;
                    if (temp[index].children.length > index2 + 1) {
                        temp[index].offset = temp[index + 1].offset;
                        temp[index + 1].offset = offset;
                    }
                    temp = collect(temp).sortBy("offset").toArray();
                }
            }
            setData([...temp]);
        } catch (e) {
            console.log({ e });
            displayNotification('error', 'Failed to update offset');
        }
    }

    const splitText = (index, index2, content) => {
        let temp = [...data];
        if (index2 !== null) {
            temp[index].children[index2].json_content = content.updated_content;
            const newContent = {
                ...temp[index].children[index2],
                children: [],
                text_type: LIST_DETAIL_ELEMENTS.text_types.paragraph,
                offset: content.content_offset + 1,
                json_content: content.new_content
            };
            temp[index].children.push(newContent);
            temp[index].children = collect(temp[index].children).sortBy("offset").toArray();
        } else {
            temp[index].json_content = content.updated_content;
            const newContent = {
                ...temp[index],
                children: [],
                text_type: LIST_DETAIL_ELEMENTS.text_types.paragraph,
                offset: content.content_offset + 1,
                json_content: content.new_content
            };
            temp.push(newContent);
            temp = collect(temp).sortBy("offset").toArray();
        }
        setData([...temp]);
    }

    const styleMapping = async (id, style_id, is_content) => {
        try {
            const ret = await sendAPIRequest(`/api/styles-mapping`, 'POST',
                {
                    style_id: style_id,
                    content_id: is_content ? id : null,
                    content_paragraph_id: !is_content ? id : null,
                }
            );
            const res = await ret.json();
            if (res.success && res.status === 200) {
                displayNotification('success', 'Successfully marked styles [B]');
            } else {
                displayNotification('error', 'Failed to style content [B]');
            }
        } catch (e) {
            console.log(e);
            displayNotification('error', 'Failed to style content [F]');
        }
    }
    

    const searchText = (term) => {
        setMatchedCount(0);
        const temp = cloneDeep(dataForSearch);
        const tempData = [...temp.map(text => {
            if (!term) return text;
            const regex = new RegExp(`(${term})`, 'gi');
            if (text.children && text.children.length > 0) {
                text.children = text.children.map(t => {
                    t.json_content = t.json_content.split(regex).map((part, index) => {
                        if (regex.test(part)) {
                            setMatchedCount(pre => pre + 1);
                            return <span key={index} className="text-primary">{part}</span>
                        } else {
                            return part
                        }
                    });
                    return t;
                });
                return text;
            } else {
                return text;
            }
        })];
        setData([...tempData]);
    }

    return (
        <>
            <EditModal ref={editRef} />
            <SplitModal ref={splitRef} />
            <div className="container my-2">
                <div className="row header card-woah border border-secondary d-flex px-4 py-2 shadow-sm w-100">
                    <div className='col-md-8 d-flex justify-content-between'>
                        <div className='d-flex justify-content-between'>
                            <h1 className="col-md-4 card-title1 font-custom-2 lh-1">{t("118", "Data Mapping")}</h1>
                            <TextFieldCustom
                                className="col-md-4 ms-auto my-auto"
                                onChange={(e) => { searchText(e.target.value) }}
                                required={true}
                                error={true}
                                label={t("13", "Search")}
                                fullWidth
                                size="small"
                            />
                            <p
                                className="col-md-4 ms-auto my-auto"
                            >No. of matched records: {matchedCount}</p>
                        </div>
                    </div>
                    <div className="col-md-4 header-right-items ms-auto d-flex align-items-center">
                        <div className="ms-auto">
                            <button
                                className="btn btn--primary font-custom fs-6 ms-1"
                                onClick={goBack}
                            >
                                {t("162", "Back")}
                            </button>
                            {
                                currentPage === 'data-mapping' && <>
                                    <IgnoreDataModal
                                        data={ignoreData}
                                        reloadData={(content, selectedIDs) => restoreIgnoreData(content, selectedIDs)}
                                        children={openModel => (
                                            <button
                                                type='button'
                                                className='btn btn--primary font-custom fs-6 ms-1'
                                                onClick={() => openModel()}
                                            >
                                                
                                                {t("179", "Ignored Data")}
                                            </button>
                                        )}
                                    />
                                    {
                                        !isMigrated &&
                                        <button
                                            className="btn btn--primary font-custom fs-6 ms-1"
                                            onClick={storeData}
                                            disabled={isLoading.save_data}
                                        >
                                            {
                                                isLoading.save_data ?
                                                    <Spinner message="Saving, Please Wait..." />
                                                    : t("180", "Save")
                                            }
                                        </button>
                                    }
                                </>
                            }
                        </div>
                    </div>
                </div>
                <div className="card card-woah border border-secondary shadow rounded-0 mt-2">
                    {
                        isLoading.load_data ? (
                            <div className='my-2'>
                                <Spinner message="Loading Data..." />
                            </div>
                        ) : (
                            <>
                                {
                                    data && data.length > 0 ?
                                        data.map((d, index) => {
                                            return <div className="card-body pt-4 pb-0 px-5" key={`p${d.id}`}>
                                                <div className=' '>
                                                    {
                                                        d.text_type === LIST_DETAIL_ELEMENTS.text_types.paragraph || d.role === null ?
                                                            (
                                                                <div className='align-items-center d-flex w-100' key={d.id}>
                                                                    <div className='d-flex bg-light-beige p-4 border border-dark w-100'>
                                                                        <p className='my-0'>
                                                                            <HtmlRenderer tag={'span'} htmlContent={d.json_content} />
                                                                            {showMetaData && <b>[role: {d.role} - id: {index}]</b>}
                                                                            {currentPage === 'style-mapping' && <b> [style: {getStyleName(d?.content_style?.style_id)}]</b>}
                                                                        </p>
                                                                        {
                                                                            currentPage === 'data-mapping' &&
                                                                            <DropDownMenu
                                                                                editRef={editRef}
                                                                                splitRef={splitRef}
                                                                                setContentNumber={setContentNumber}
                                                                                handleMarking={handleMarking}
                                                                                handleMarkAsParagraph={handleMarkAsParagraph}
                                                                                updateContent={updateContent}
                                                                                ignoreContent={ignoreContent}
                                                                                splitText={splitText}
                                                                                index={index}
                                                                                index2={null}
                                                                                d={d}
                                                                            />
                                                                        }
                                                                        {
                                                                            currentPage === 'style-mapping' &&
                                                                            <StyleDropDownMenu styleList={styleList} id={d.id} is_content={true} styleHandler={styleMapping} />
                                                                        }
                                                                    </div>
                                                                    <span className='d-flex'>
                                                                        <i className="fa-solid fa-caret-up m-1 fs-2 cursor-pointer" onClick={() => updateOffset(index, null, 'increase')}></i>
                                                                        <i className="fa-solid fa-caret-down m-1 fs-2 cursor-pointer" onClick={() => updateOffset(index, null, 'decrease')}></i>
                                                                    </span>
                                                                </div>
                                                            ) : (
                                                                (
                                                                    d.text_type === LIST_DETAIL_ELEMENTS.text_types.article_heading
                                                                    || d.text_type === LIST_DETAIL_ELEMENTS.recommendation_types.safe_commodities
                                                                    || d.text_type === LIST_DETAIL_ELEMENTS.recommendation_types.recommendations_for_importations
                                                                    || d.text_type === LIST_DETAIL_ELEMENTS.recommendation_types.inactivation_procedures
                                                                    || d.text_type === LIST_DETAIL_ELEMENTS.heading_types.general_heading
                                                                )
                                                                    ?
                                                                    <>
                                                                        {
                                                                            (
                                                                                <div className='row mb-0 py-3 bg-light-orange border-light-orange g-0'>
                                                                                    <div className='col-md-6'>
                                                                                        <h2 className="d-flex ms-2">
                                                                                            <HtmlRenderer tag={'span'} htmlContent={d.json_content} />
                                                                                            {showMetaData && <b>[{d.ref_number && `Ref-Number: ${d.ref_number} - `}Role: {d.role} - ID: {index}]</b>}
                                                                                            {currentPage === 'style-mapping' && <b> [style: {getStyleName(d?.content_style?.style_id)}]</b>}
                                                                                            {
                                                                                                currentPage === 'data-mapping' &&
                                                                                                <DropDownMenu
                                                                                                    editRef={editRef}
                                                                                                    splitRef={splitRef}
                                                                                                    setContentNumber={setContentNumber}
                                                                                                    handleMarking={handleMarking}
                                                                                                    handleMarkAsParagraph={handleMarkAsParagraph}
                                                                                                    updateContent={updateContent}
                                                                                                    ignoreContent={ignoreContent}
                                                                                                    splitText={splitText}
                                                                                                    index={index}
                                                                                                    index2={null}
                                                                                                    d={d}
                                                                                                />
                                                                                            }
                                                                                            {
                                                                                                currentPage === 'style-mapping' &&
                                                                                                <StyleDropDownMenu styleList={styleList} id={d.id} is_content={true} styleHandler={styleMapping} />
                                                                                            }
                                                                                        </h2>
                                                                                    </div>
                                                                                    {
                                                                                        currentPage === 'data-mapping' &&
                                                                                        <div className='col-md-6 text-end'>
                                                                                            <ParentChildLinking
                                                                                                handleRadioButtonChangeState={handleRadioButtonChangeState}
                                                                                                linkChildWithParent={linkChildWithParent}
                                                                                                index={index}
                                                                                                index2={null}
                                                                                                selectedValue={selectedValue}
                                                                                            />
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                            )
                                                                        }
                                                                        <>
                                                                            {
                                                                                d.children && d.children.length > 0 &&
                                                                                d.children.map((val, key) => (
                                                                                    <div className='align-items-center d-flex' key={`c${val.id}`}>
                                                                                        <div className='ms-3 d-flex bg-secondary p-4 my-4 w-100'>
                                                                                            <p className='my-0'>
                                                                                                <HtmlRenderer tag={'span'} htmlContent={val.json_content} />
                                                                                                {showMetaData && <b>[Role: {val.role} - ID: {key} - offset: {val.offset}]</b>}
                                                                                                {currentPage === 'style-mapping' && <b> [style: {getStyleName(val?.content_style?.style_id)}]</b>}
                                                                                            </p>
                                                                                            {
                                                                                                currentPage === 'data-mapping' &&
                                                                                                <DropDownMenu
                                                                                                    editRef={editRef}
                                                                                                    splitRef={splitRef}
                                                                                                    setContentNumber={setContentNumber}
                                                                                                    handleMarking={handleMarking}
                                                                                                    handleMarkAsParagraph={handleMarkAsParagraph}
                                                                                                    updateContent={updateContent}
                                                                                                    ignoreContent={ignoreContent}
                                                                                                    splitText={splitText}
                                                                                                    index={index}
                                                                                                    index2={key}
                                                                                                    d={val}
                                                                                                />
                                                                                            }
                                                                                            {
                                                                                                currentPage === 'style-mapping' &&
                                                                                                <StyleDropDownMenu styleList={styleList} id={val.id} is_content={false} styleHandler={styleMapping} />
                                                                                            }
                                                                                        </div>
                                                                                        <span className='d-flex'>
                                                                                            <i className="fa-solid fa-caret-up m-1 fs-2 cursor-pointer" onClick={() => updateOffset(index, key, 'increase')}></i>
                                                                                            <i className="fa-solid fa-caret-down m-1 fs-2 cursor-pointer" onClick={() => updateOffset(index, key, 'decrease')}></i>
                                                                                        </span>
                                                                                    </div>
                                                                                ))
                                                                            }
                                                                        </>
                                                                    </>
                                                                    :
                                                                    <h2 className="text-center mb-0 py-3 bg-light-background d-flex justify-content-center border--light">
                                                                        <HtmlRenderer tag={'span'} htmlContent={d.json_content} />
                                                                        {showMetaData && <b>[{d.ref_number && `Ref-Number: ${d.ref_number} - `}Role: {d.role}]</b>}
                                                                        {currentPage === 'style-mapping' && <b> [style: {getStyleName(d?.content_style?.style_id)}]</b>}
                                                                        {
                                                                            currentPage === 'data-mapping' &&
                                                                            <DropDownMenu
                                                                                editRef={editRef}
                                                                                splitRef={splitRef}
                                                                                setContentNumber={setContentNumber}
                                                                                handleMarking={handleMarking}
                                                                                handleMarkAsParagraph={handleMarkAsParagraph}
                                                                                updateContent={updateContent}
                                                                                ignoreContent={ignoreContent}
                                                                                splitText={splitText}
                                                                                index={index}
                                                                                index2={null}
                                                                                d={d}
                                                                            />
                                                                        }
                                                                        {
                                                                            currentPage === 'style-mapping' &&
                                                                            <StyleDropDownMenu styleList={styleList} id={d.id} is_content={true} styleHandler={styleMapping} />
                                                                        }
                                                                    </h2>
                                                            )
                                                    }
                                                </div>
                                            </div>
                                        }) : <div className='text-center my-2'>{t("199","No data found.")}</div>
                                }
                            </>
                        )
                    }
                </div>

            </div>
        </>
    );
}

export default DataMapping;