import React, { useState } from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';

const AutoSaveButton = ({ saveState, returnData, className }) => {

    const [isChecked, setIsChecked] = useState(saveState);

    const handleChange = (event) => {
        setIsChecked(event.target.checked);
        returnData(event.target.checked);
    };

    return <>
        <FormControlLabel
            control={
                <Checkbox
                    checked={isChecked}
                    onChange={handleChange}
                    color="primary"
                    className={className}
                />
            }
            label={isChecked ? 'Auto Save Enabled' : 'Auto Save Disabled'}
        />
    </>
}

AutoSaveButton.defaultProps = {
    saveState: false,
    className: '',
    returnData: () => { },
};

export default AutoSaveButton;