import { GridActionsCellItem } from '@mui/x-data-grid';
import Button from 'components/common/Button';
import CardBody from 'components/common/CardBody';
import CardHeader from 'components/common/CardHeader';
import Container from 'components/common/Container';
import CustomCard from 'components/common/CustomCard';
import HtmlRenderer from 'components/HtmlRenderer';
import { displayNotification, sendAPIRequest } from 'helpers';
import DataTable from 'pages/components/DataTable';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { setCurrentPage } from 'redux/generalSlice';

const ImplementedGlossary = () => {

    const [location, setLocation] = useSearchParams();
    const [isLoading, setIsLoading] = useState(false);
    const { standard_type, animal_type, language_id } = {
        standard_type: location.get('standard_type'),
        animal_type: location.get('animal_type'),
        language_id: location.get('language_id'),
    };
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [data, setData] = useState([]);


    const loadGloassaryImplementedChapters = async () => {
        try {
            const ret = await sendAPIRequest(`/api/handle-drafting/glossary-implemented-chapters/?standard_type=${standard_type}&animal_type=${animal_type}&language_id=${language_id}`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                setData(res.data);
            } else {
                setData([]);
            }
        } catch (e) {
            console.error(e);
        }
    }

    const revertGlossary = async (structure_id = null) => {
        setIsLoading(true);
        try {
            if (!window.confirm('Are you want to Revert Glossary?')) {
                return;
            }
            const ret = await sendAPIRequest(`/api/handle-drafting/revert-chapter-prepublish/${structure_id}`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                displayNotification("success", 'Successfully reverted glossary')
                await loadGloassaryImplementedChapters();
            }
        } catch (e) {
            console.error(e);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        dispatch(setCurrentPage("publishing-standards"));
    }, []);

    useEffect(() => {
        loadGloassaryImplementedChapters();
    }, [standard_type, animal_type, language_id]);

    return (
        <Container>
            <CustomCard className={'d-flex justify-content-between'}>
                <CardHeader>
                    {t("-1", "Glossary Implemented Chapters")}
                </CardHeader>
                <CardBody>
                    <Button
                        onClick={() => navigate(-1)}
                    >
                        {t("-1", "Back")}
                    </Button>
                </CardBody>
            </CustomCard>
            <CustomCard>
                <DataTable
                    getRowId={(row) => row.id}
                    rows={data}
                    isLoading={isLoading}
                    className="text-white bg-primary rounded-0"
                    columns={[
                        {
                            field: "standard_short_name",
                            headerName: t("-1", "Standard"),
                            headerClassName: "text-white bg-primary rounded-0",
                            minWidth: 150,
                            flex: 1,
                        },
                        {
                            field: "display_name",
                            headerName: t("-1", "Chapters"),
                            headerClassName: "text-white bg-primary rounded-0",
                            minWidth: 150,
                            flex: 10,
                            renderCell: (params) => {
                                return (
                                    <HtmlRenderer
                                        htmlContent={params.row.display_name} />
                                );
                            }
                        },
                        {
                            field: "actions",
                            type: "actions",
                            headerName: t("-1", "Actions"),
                            headerClassName: "text-white bg-primary rounded-0",
                            getActions: (params) => [
                                <GridActionsCellItem
                                    label={t("-1", "Revert Glossary")}
                                    key={0}
                                    showInMenu
                                    onClick={async () => await revertGlossary(params.row.id)}
                                />,
                            ],
                            width: 90,
                        },
                    ]}
                />
            </CustomCard>
        </Container>
    )
}

export default ImplementedGlossary
