import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import TextFieldCustom from 'components/TextFieldCustom';
import { useEffect } from 'react';
import Container from 'components/common/Container';
import { displayNotification, sendAPIRequest } from 'helpers';

const UserNameModel = ({ open, handleClose, data }) => {

    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({ chapter_name: '', chapter_no: '' });
    const [userName, setUserName] = useState(data?.user_name ?? "");



    useEffect(() => {
        setUserName(data?.user_name ?? "")
    }, [open]);

    const handleModalCLose = () => {
        setUserName('')
        handleClose()
    }

    const handleUpdate = async () => {
        setIsLoading(true);
        try {
            const ret = await sendAPIRequest(`/api/users/profile/update`, "POST", {
                user_name: userName
            });
            const res = await ret.json();
            if (res.success && res.status === 200) {
                displayNotification('success', 'Successfully Updated !');
                handleModalCLose();
            }
        } catch (err) {
            console.log({ err });
            handleModalCLose();
            displayNotification('error', 'Failed to load Profile data [F]!');
        }
        setIsLoading(false);
    }


    return (
        <>
            <Modal
                show={open} size='md' centered={true}
            >
                <Modal.Body className='p-4'>
                    <Container>
                        <Typography id="modal-modal-title" variant="h6" component="h2"
                            className="text-center"
                        >{t("-1", "Edit User")}
                        </Typography>
                        <div className='col-12 mb-3 fs-4 '>
                            <span className='fw-bold pe-2'>{t("-1", "User Name")}</span>
                            <TextFieldCustom
                                variant="outlined"
                                id="user_name"
                                fullWidth
                                rows={4}
                                required={true}
                                sx={{
                                    backgroundColor: "white",
                                    color: "white",
                                    border: "#ff4815",
                                    width: "99%",
                                }}
                                size='small'
                                value={userName}
                                onChange={(e) => setUserName(e.target.value)}
                            />
                        </div>
                        <div className='col-12 mb-3 fs-4 '>
                            <span className='fw-bold pe-2'>{t("-1", "Email")}</span>
                            <TextFieldCustom
                                variant="outlined"
                                id="email"
                                fullWidth
                                disabled
                                // rows={4}
                                // required={true}
                                sx={{
                                    backgroundColor: "white",
                                    color: "white",
                                    border: "#ff4815",
                                    width: "99%",
                                }}
                                size='small'
                                value={data?.email}
                            />
                        </div>
                    </Container>
                </Modal.Body>
                <Modal.Footer className='p-4 d-flex justify-content-end'>
                    <button
                        className='btn btn--outline'
                        onClick={handleModalCLose}
                    >
                        {t("-1", "Close")}
                    </button>
                    <button
                        className="btn btn--primary font-custom fs-6"
                        onClick={handleUpdate}
                        disabled={isLoading}
                    >
                        Save
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default UserNameModel
