import { displayNotification, generateOptionsFromData, getAPIURL, sendAPIRequest } from "helpers";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams ,useLocation } from "react-router-dom";
import { LIST_DETAIL_ELEMENTS } from 'constants';
import HtmlRenderer from "components/HtmlRenderer";
import { Spinner } from "react-bootstrap";
import AutoCompleteDropdown from "components/autocomplete/AutoCompleteDropdown";

const HrefMapping = () => {

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const isCode = queryParams.get('iscode');

    const { id } = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState({
        loading_images: false,
        loading_headings: false,
    });
    const [documentImages, setDocumentImages] = useState([]);
    const [imageHeadings, setImageHeadings] = useState([]);
    const [currentItem, setCurrentItem] = useState('');
    const [selectedImage, setSelectedImage] = useState('');
    const [imageSrc, setImageSrc] = useState(null);
    const [mappedImageName, setMappedImageName] = useState('');
    const [checkCode,setCheckCode]= useState(isCode  === 'true' ? true : false)
    const[manualHeadings ,setManualHeadings] = useState([])
    const [hRefTags , setHRefTags] = useState([])
    const [glossaryTerms ,setGlossaryTerms] = useState([])
    const [selectedTerm, setSelectedTerm] = useState({
        value:"",
        label:''
    });

    const [data ,setData] = useState([])

    const [selectedTag , setSelectedTag] = useState({
        value:'',
        id:"",
        parent:null,
        original:'',
        p_id:null
    })

    useEffect(() => {
        LoadContents()
        loadTerms()
    }, []);


    const loadTerms = async () =>{
        setIsLoading({ ...isLoading, loading_images: true });
        try {
            const ret = await sendAPIRequest(`/api/glossaries/get-terms`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                setGlossaryTerms(res.data)
            }
        } catch (err) {
            console.error(err);
        }
        setIsLoading({ ...isLoading, loading_images: false });
    }

    const loadImages = async () => {
        setIsLoading({ ...isLoading, loading_images: true });
        try {
            const ret = await sendAPIRequest(`/api/document-images/?document_id=${id}`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                setDocumentImages(res.data);
            }
        } catch (err) {
            console.error(err);
        }
        setIsLoading({ ...isLoading, loading_images: false });
    }

    const loadManualImageTags = async () => {
        setIsLoading({ ...isLoading, loading_images: true });
        try {
            const ret = await sendAPIRequest(`/api/document-images/get-image-tags/${id}`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                // setDocumentImages(res.data);
                // setManualHeadings(res.data)
            }
        } catch (err) {
            console.error(err);
        }
        setIsLoading({ ...isLoading, loading_images: false });
    }


    const LoadContents = async () => {
        setIsLoading({ ...isLoading, loading_data: true });
        try {
            // const ret = await sendAPIRequest(`/api/contents/manual-chapter/${id}?isrequired=true`);
            const ret = await sendAPIRequest(`/api/contents/navigation-chapter-preview/?chapter=${id}`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                // setData(res.data);
                if(res.data && res.data.length > 0){
                    // setData(res.data)
                   let data = extractedHref(res.data)
                   setHRefTags(data)
                }
                // setDbStyleLoad(res.flag.styleTag??null)
            }
        } catch (e) {
            console.log({ e });
            displayNotification('error', 'Failed to load chapter data [F]');
        }
        setIsLoading({ ...isLoading, loading_data: false });
    }

    const loadData = async () => {
        setIsLoading({ ...isLoading, loading_images: true });
        try {
            const ret = await sendAPIRequest(`/api/document-images/get-image-tags/${id}`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                // console.log('.........................',res.data)
                // setManualHeadings(res.data)
            }
        } catch (err) {
            console.error(err);
        }
        setIsLoading({ ...isLoading, loading_images: false });
    }

    const loadImageHeadings = async () => {
        setIsLoading({ ...isLoading, loading_headings: true });
        try {
            const ret = await sendAPIRequest(`/api/document-images/specific-type-content/?id=${id}&text_type=${LIST_DETAIL_ELEMENTS.styles_categories.image}`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                setImageHeadings(res.data);
            }
        } catch (err) {
            console.error(err);
        }
        setIsLoading({ ...isLoading, loading_headings: false });
    }

    const selectImage = async (id) => {
        const img = documentImages.filter(di => parseInt(di.id) === parseInt(id))[0];
        setMappedImageName(img?.html_content?.content);
        sendAPIRequest(`/api/document-images/image-buffer/${id}`)
        .then((response) => response.blob())
        .then((blob) => {
            const url = window.URL.createObjectURL(
                new Blob([blob]),
            );
            setImageSrc(url);
        });
        setSelectedImage(id);
    }

    const getSelectedHeadingImage = async (id) => {
        const img = documentImages.filter(di => parseInt(di.content_id) === parseInt(id))[0];
        setMappedImageName(img?.html_content?.content);
        sendAPIRequest(`/api/document-images/heading-image/${id}`)
        .then((response) => response.blob())
        .then((blob) => {
            const url = window.URL.createObjectURL(
                new Blob([blob]),
            );
            setImageSrc(url);
        });
    }

    const handleMap = async () => {
        try {
            if (!currentItem || !selectedImage) {
                displayNotification('warning', 'Please Select image and heading for mapping');
                return;
            }
            const content_id = imageHeadings.find((i, k) => parseInt(k) === parseInt(currentItem)).id;
            const ret = await sendAPIRequest(`/api/document-images/map-image/?content_id=${content_id}&image_id=${selectedImage}`);
            const res = await ret.json();
            if (res.success) {
                displayNotification('success', 'Successfully mapped image [B]');
            } else {
                displayNotification('error', 'Failed to mapp image [B]');
            }
        } catch (err) {
            console.error(err);
            displayNotification('error', 'Failed to mapp image [F]');
        }
    }


    const goBack = () => {
        navigate(`/portal/data-migrations`);
    }

    const ImageOptions = useMemo(() => {
        return generateOptionsFromData(documentImages, { value: 'id', label: 'image_name' });
    }, [documentImages]);


    const termsOptions = useMemo (()=> {
        return generateOptionsFromData(glossaryTerms,{
            value:"g_id",
            label:"glossary_term"
        })
    })


    
    const extractedHref = (data) => {

        let extractedTags = []
        for (let x of data){
            let res = {}
            res=  handlehids(x.standard_content,x.id,true)
          if(res){
            extractedTags.push(res)
          }
          if(x.html_contents && x.html_contents.length > 0){

            for (let y of x.html_contents){

                let res=  handlehids(y.content,y.id,false,x.id)
                if(res){
                    extractedTags.push(res)
                  }
            }
          }
          if(x.child){
            let res =  handlehids(x.child?.standard_content,x.id,true)
            if(res){
                extractedTags.push(res)
              } 
              
          if(x.child.html_contents && x.child.html_contents.length > 0){

            for (let y of x.child.html_contents){

                let res=  handlehids(y.content,y.id,false,x.id)
                if(res){
                    extractedTags.push(res)
                  }
            }
          }
        }
          
        }
        if(extractedTags.length> 0 ){
            setHRefTags(extractedTags)
        }
        return extractedTags
    }

    const handlehids = (standard_content , id ,parent,p_id=null )=> {
        let ele = {
            content : [],
            id:'',
            parent:parent,
            original:standard_content,
            p_id:p_id
        }
       let dta = extractAllATags(standard_content)
            if(dta .length > 0){
                ele.content=dta
                 ele.id = id
                return ele
            }
            return false


    }

    function extractAllATags(htmlString) {
        var tempElement = document.createElement('div');
        tempElement.innerHTML = htmlString;
    
        var aTags = tempElement.getElementsByTagName('a');
    
        var extractedATags = [];
    
        for (var i = 0; i < aTags.length; i++) {
            extractedATags.push(aTags[i].outerHTML);
        }
    
        tempElement.remove();
    
        return extractedATags;
    }

    
    const handleMapping = () => {
            // let getApiUrl = `src="${getAPIURL()}/api/document-images/get-image/${selectImage?.value}"`

        
        if (selectedTag.id === '' || selectedTerm.value === '') {
            displayNotification('error', 'Please Select The Required Fileds')
            return
        }
        else {
            let t = `${getAPIURL()}/api/glossaries/get-glossary/${selectedTerm.value}`
            var tempDiv = document.createElement('div');
            tempDiv.innerHTML = selectedTag?.value;
            var tempAnchor = tempDiv.querySelector('a');
            if (tempAnchor) {
                const hrefContent = tempAnchor.getAttribute('href');
                let getKey = hrefContent?.split("#")[1]
                // console.log('getTa',getTag)
                let orignalTag = tempAnchor.outerHTML
                tempAnchor.setAttribute('href', t);
                let newTag = tempAnchor.outerHTML
                setSelectedTag(prevState => ({
                    ...prevState,
                    value: tempAnchor.outerHTML
                }));
                handleModifyString(newTag ,getKey ,orignalTag,t )
            }
            tempDiv.remove()

    
    }

}

function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

const handleModifyString = async (newTag ,key , orignalTag ,link)=> {
    let original = selectedTag.original
    const regex = new RegExp(escapeRegExp(orignalTag), "g");
    const modifiedString = original.replace(regex, newTag);

    let finalData = {
        parent:selectedTag?.parent,
        glossary_tag:key,
        link,
        content:modifiedString,
        id:selectedTag?.id,
        g_id:selectedTerm.value
    }

    await handleSaveData (finalData)
    

}


const handleSaveData = async (data) => {
    setIsLoading({ ...isLoading, loading_images: true });
        try {
            const ret = await sendAPIRequest(`/api/glossaries/set-anchor-src/${data.id}`,"POST",{
                parent:data.parent,
                glossary_tag:data.glossary_tag,
                link:data.link,
                content:data.content,
                g_id:data.g_id
            });
            const res = await ret.json();
            if (res.success && res.status === 200) {
                displayNotification("success","Successfully Mapped!")
                await LoadContents()
            }
        } catch (err) {
            console.error(err);
            displayNotification("error",err?.message)         
            setIsLoading({ ...isLoading, loading_images: false });
        }
        setIsLoading({ ...isLoading, loading_images: false });
}

const handleReplaceSrc = () => {

}

    return (
        <>
            <div className="container my-2">
                <div className="row header card-woah border border-secondary d-flex px-4 py-2 shadow-sm w-100">
                    <div className='col-md-8 d-flex justify-content-between'>
                        <h1 className="col-md-4 card-title1 font-custom-2 lh-1">{t("159", "HTML Data Mapping")}</h1>
                    </div>
                    <div className="col-md-4 header-right-items ms-auto d-flex align-items-center">
                        <div className="ms-auto">
                            <button
                                className="btn btn--primary font-custom fs-6 ms-1"
                                onClick={() => goBack()}
                            >
                                {t("-1", "Back")}
                            </button>
                        </div>
                        <div className="ms-auto">
                            <button
                                className="btn btn--primary font-custom fs-6 ms-1"
                                // onClick={() => handleMap()}
                                onClick={() => handleMapping()}
                            >
                                {t("-1", "Map")}
                            </button>
                        </div>
                    </div>
                </div>
                <div className="card card-woah border border-secondary shadow rounded-0 mt-2">
                    <div className="row">
                        <div className='col-md-2'>
                            <div
                                className='overflow-x-hidden overflow-y-auto scrollspy-inner'
                                style={{
                                    height: "75vh",
                                }}
                            >
                                {

                                    hRefTags && hRefTags.length > 0
                                        ? hRefTags.map((d, index) =>
                                            <div key={index} className='my-1' >
                                                {d.parent}
                                                {
                                                    
                                                    d.content && d.content.length > 0 &&
                                                        d.content.map((i,ind)=>
                                                        (
                                                            
                                                            <div className='list-group bg-light btn-sm d-flex flex-column p-1'
                                                            key={ind+"child"}
                                                            >

                                                            <button
                                                        disabled={isLoading.loading_headings}
                                                        className={
                                                            `${isLoading.loading_headings ? 'opacity-50' : 'opacity-100'} list-group-item rounded-0 list-group-item-action my-1 fs-5 fw-bold cursor-pointer  ${currentItem === index ? 'list-group-item-checked' : ''}`
                                                        }
                                                        onClick={() => {
                                                            // getSelectedHeadingImage(d.id);
                                                            // setCurrentItem(index);
                                                            setSelectedTag({
                                                                value:i,
                                                                id:d.id,
                                                                parent:d.parent,
                                                                original:d.original,
                                                                p_id:d.p_id??null

                                                            })
                                                        }}
                                                    >
                                                        {i}
                                                        {/* <HtmlRenderer tag={'span'} htmlContent={i} /> */}
                                                    </button>
                                                </div>
                                                    
                                                        ))
                                                        }

                                            </div>
                                        ) : 
                                       
                                        
                                        (
                                            isLoading.loading_headings ?
                                                <Spinner message="Loading..." />
                                                : <div className='text-center'>No content available</div>
                                        )
                                }
                            </div>
                        </div>
                        <div className='col-md-10'>
                            <div className='row p-1'>
                                <div className='col-md-12'>
                                    <div
                                        className='overflow-x-hidden overflow-y-auto scrollspy-example'
                                        style={{
                                            height: "75vh",
                                        }}
                                    >
                                        <div className='d-flex justify-content-between ms-auto my-2 border-bottom border-2'>
                                            <div className="row w-100">
                                                <h2 className='p-3 lh-1 col-md-2 my-auto'>Terms</h2>
                                                <div className="col-md-10">
                                                    <AutoCompleteDropdown
                                                        className={isLoading.loading_images ? 'opacity-50' : 'opacity-100'}
                                                        disabled={isLoading.loading_images}
                                                        label={isLoading.loading_images ? 'loading...' : t("-1", "GLOSSARY TERMS")}
                                                        showClearIcon={true}
                                                        error={false}
                                                        required={false}
                                                        multiple={false}
                                                        hasselectall={false}
                                                        options={termsOptions}
                                                        onChange={(event, value) => {
                                                            setSelectedTerm({
                                                                value:value?.value,
                                                                label:value?.label
                                                            })
                                                        }}
                                                        value={
                                                            !selectedTerm
                                                                ? { value: '', label: '' }
                                                                : termsOptions.find((r) => parseInt(r.value) === parseInt(selectedTerm.value)) ?? { value: '', label: '' }
                                                        }
                                                        size='small'
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="d-flex">
                                            <p><b className="my-auto">Mapped heading: </b> </p>
                                            {
                                                mappedImageName ?
                                                <HtmlRenderer tag={'p'} className="my-auto" htmlContent={mappedImageName} />
                                                : <span>No heading is linked</span>
                                            }
                                        </div> */}
                                        {/* {
                                            imageSrc ? (
                                                <img src={imageSrc} alt="Blob-file" style={{ maxWidth: '100%' }} />
                                            ) : (
                                                <p>No image data provided</p>
                                            )
                                        } */}

                                        {
                                            selectedTag &&
                                            <div className="container">
                                                <p><b className="font-weight-bold">Mapping Tag:{selectedTag.parent}</b></p>
                                                <strong className="text-primary" style={{ fontSize: "18px" }}>{selectedTag?.value}{selectedTag.parent}</strong>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

HrefMapping.defaultProps = {};

export default HrefMapping;