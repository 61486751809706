import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Box, Grid, IconButton, InputAdornment, TextField, Tooltip, Typography, Divider, FormGroup, FormControlLabel, Checkbox, CircularProgress, styled, tooltipClasses } from '@mui/material';
import { LIST_DETAIL_ELEMENTS, Search_By_Keywords_Detail, Search_By_Keywords_List, Search_By_Keywords_List_Download } from 'constants';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { getBaseURL, displayNotification, sendAPIRequest, handleReplaceUrlForChapterLinking, generateUniqueId, stripHtml } from 'helpers';
import ZoomInMapIcon from '@mui/icons-material/ZoomInMap';
import { KeyboardArrowDown, KeyboardArrowUp, OpenInNew } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import PrintIcon from '@mui/icons-material/Print';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import HtmlRenderer from 'components/HtmlRenderer';
import useLocalStorage from 'hooks/useLocalStorage';
import DataTableV2 from 'components/DataTableV2';
import CustomDataTable from 'pages/components/CustomDataTable';
import { cloneDeep } from 'lodash';
import CustomCard from 'components/common/CustomCard';


const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#ffece8',
    color: '#ff4815',
    boxShadow: theme.shadows[1],
    fontSize: 12,
    padding: '13px'
  },
}));

const SearchByKeyword = (props) => {


  const [numFields, setNumFields] = useState(1);
  const { t } = useTranslation();
  const siteLanguage = useSelector(s => s.general.siteLanguageSont);
  const [componentLanguage, setComponentLanguage] = useState(siteLanguage);
  const [searchedData, setSearchedData] = useState([]);
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [codeId, setCodeID] = useState('');
  const [chapterID, setChapterID] = useState('');
  const currentDate = new Date().toISOString().slice(0, 10);
  const [search, setSearch] = useState('');
  const [showSearch, setShowSearch] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [highlightedData, setHighlightedData] = useState(data);
  const [keywords, setKeywords] = useState([]);
  const [isManual, setIsManual] = useState(false);
  const [currentTerm, setCurrentTerm] = useState(0);
  const [currentElement, setCurrentElement] = useState('');
  const [baseFontSize, setBaseFontSize] = useState(14);
  const [searchInText, setSearchInText] = useState(0);
  const [searchInHeading, setSearchInHeading] = useState(1);
  const [currentSelected, setCurrentSelected] = useState('');
  const [isManualSelected, setIsManualSelected] = useState(false);
  const [activeItemId, setActiveItemId] = useState(null);

  const { setItem } = useLocalStorage('search-by-keywords', {});
  const [documentMetaData, setDocumentMetaData] = useState({
    section_name: '',
    chapter_name: '',
    chapter_no: '',
    section_no: "",
    area_vol_no: ''
  });
  const [isLoading, setIsLoading] = useState({
    loading_filters: false,
    loading_data: false,
    loading_search_data: false,
  });
  const [generatingPDF, setGeneratingPDF] = useState(false);
  const [generatingPDFList, setGeneratingPDFList] = useState(false);
  const [searchedIDStack, setSearchedIDStack] = useState([]);

  useEffect(() => {
    changeComponentLanguage(siteLanguage);
  }, [siteLanguage]);

  useEffect(() => {
    getOtherLanguageArticleData();
  }, [componentLanguage]);

  const handleClearSearch = () => {
    setSearch('');
  };
  const loadSearchedStandardsData = async (updatedKeywords) => {
    setIsLoading({ ...isLoading, loading_search_data: true });
    try {
      const ret = await sendAPIRequest(
        `/api/search-content/keywords-search/?search_in_text=${searchInText}&search_in_headings=${searchInHeading}&keywords=${updatedKeywords.toString()}&language_id=${componentLanguage}&is_terrestrial_code=${props.isTerrestrialCode}&is_terrestrial_manual=${props.isTerrestrialManual}&is_aquatic_code=${props.isAquaticCode}&is_aquatic_manual=${props.isAquaticManual}`
      );
      const res = await ret.json();
      if (res.success && res.status === 200) {
        setCurrentSelected('')
        setSearchedData(res?.data);
        setData([]);
        setDocumentMetaData(prevState => ({
          ...prevState,
          section_name: res.data.section_name,
          chapter_name: res.data.chapter_name,
          section_no: res.data.section_no,
          chapter_no: res.data.chapter_no
        }));
      }
    } catch (e) {
      console.error({ e });
    }
    setIsLoading({ ...isLoading, loading_search_data: false });
  }

  const handleOnChapterClick = async (standard_type, structures_id, isChapter, id) => {
    setActiveItemId(id);
    setSearch('')
    if (isChapter) {
      const d = searchedData.filter(d => d.parent.structures_id === structures_id)[0];
      if (d) {
        setCurrentSelected(d);
      }
    } else {
      for (let x of searchedData) {
        const d = x.children.filter(d => d.id === id)[0];
        if (d) {
          d['parent'] = x.parent
          setCurrentSelected(d)
        }
      }
    }
    const ty = searchedData.filter(d => d.children.text_type === LIST_DETAIL_ELEMENTS.text_types.chapter_name)[0];
    setDocumentMetaData({
      section_name: '',
      chapter_name: '',
      chapter_no: '',
      section_no: "",
      area_vol_no: ''
    })
    if (standard_type === LIST_DETAIL_ELEMENTS.standard_type.manuals) {
      setIsManualSelected(true);
    } else {
      setIsManualSelected(false)
    }
    if (ty && ty.standards_structures_revised) {
      setDocumentMetaData(prevState => ({
        ...prevState, section_no: ty.standards_structures_revised.section_no,
        area_vol_no: ty.standards_structures_revised.area_vol_no !== null ? ty.standards_structures_revised.area_vol_no : '',
        chapter_no: ty.standards_structures_revised.chapter_no
      }));
    }
    if (LIST_DETAIL_ELEMENTS.standard_type.codes === parseInt(standard_type)) {
      if (isChapter) {
        await getChapterData(structures_id);
      } else {
        await getHeadingData(id);
      }
      setCodeID(structures_id);
    } else {
      if (isChapter) {
        await getChapterData(structures_id);
      } else {
        await getHeadingData(id);
      }
      setCodeID(structures_id);
    }
  }

  const getHeadingData = async (id) => {
    setIsLoading({ ...isLoading, loading_data: true });
    try {
      const ret = await sendAPIRequest(`/api/contents/heading-content/${id}`);
      const res = await ret.json();
      if (res.success && res.status === 200) {
        let modifiedData = handleReplaceUrlForChapterLinking(res.data);
        setData(modifiedData)
      }
    } catch (e) {
      displayNotification('error', 'Failed to load  data [F]');
    }
    setIsLoading({ ...isLoading, loading_data: false });
  }

  const getChapterData = async (id, isManual) => {
    setIsLoading({ ...isLoading, loading_data: true });
    try {
      const ret = await sendAPIRequest(`/api/contents/navigation-chapter-preview/?structures_id=${id}&is_manual=${isManual}`);
      const res = await ret.json();
      if (res.success && res.status === 200) {
        const modfiFiedData = handleReplaceUrlForChapterLinking(res.data)
        setData(modfiFiedData);
        const section = res.data.filter(d => d.text_type === LIST_DETAIL_ELEMENTS.text_types.section_name)[0]?.ref_number;
        const chapter = res.data.filter(d => d.text_type === LIST_DETAIL_ELEMENTS.text_types.chapter_name)[0]?.ref_number;
        setDocumentMetaData(prevState => ({ ...prevState, section_name: section, chapter_name: chapter }));
      }
    } catch (e) {
      displayNotification('error', `${t('82', 'Failed to load chapter data [F]')}`);
    }
    setIsLoading({ ...isLoading, loading_data: false });
  }

  const getOtherLanguageArticleData = async () => {
    try {
      if (currentSelected) {
        const otherLanguageData = await getOtherLanguageArticle(currentSelected.ref_number, currentSelected?.parent?.standards_structures_revised.chapter_no, currentSelected.parent.standards_structures_revised.section_no, currentSelected.parent.standards_structures_revised.area_vol_no, currentSelected.parent.standards_structures_revised.standard_type, currentSelected.parent.standards_structures_revised.animal_type);
        if (otherLanguageData) {
          let modifiedData = handleReplaceUrlForChapterLinking(otherLanguageData);
          const finlData = [modifiedData];
          setData(finlData)
        }
      }
    } catch (e) {
      console.log({ e });
      throw new Error(e);
    }
  }

  const getOtherLanguageArticle = async (article_no, chapter_no, section_no, area_vol_no, standard_type, animal_type) => {
    try {
      const ret = await sendAPIRequest(`/api/contents/other-language-article/?language_id=${componentLanguage}&article_no=${article_no ?? ''}&chapter_no=${chapter_no}&section_no=${section_no}&area_vol_no=${area_vol_no ?? ''}&standard_type=${standard_type}&animal_type=${animal_type}`);
      const res = await ret.json();
      if (res.success && res.status === 200) {
        return res.data;
      } else {
        return false;
      }
    } catch (e) {
      console.log(e)
    }
    return false;
  }

  const detailPdf = async () => {
    setGeneratingPDF(true);
    const Ids = getIds();
    try {
      displayNotification('info', `${t('83', 'Sending Data please wait...')}`);
      const fileName = `WOAH_Report_KeywordsList${currentDate}.pdf`;
      const ret = await sendAPIRequest(
        `/api/search-by-keywords-pdf/detail`,
        'POST',
        {
          keywords: keywords,
          searchInHeading: searchInHeading,
          searchInText: searchInText,
          List: searchedData,
          documentMetaData: documentMetaData,
          isManualSelected: isManualSelected,
          Ids: Ids,
          componentLanguage: componentLanguage
        });
      const temp = await ret.json();

      if (temp.success && temp.status === 200) {
        const downloadResponse = await sendAPIRequest(`/api/search-by-keywords-pdf/download/?file_name=${fileName}&uniqueName=${temp.data.file_name}`);
        if (downloadResponse.ok) {
          const blob = await downloadResponse.blob();
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = fileName ?? 'testing.pdf';
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
          displayNotification('success', `${t('84', 'Successfully downloaded file')}`);
        } else {
          displayNotification('error', `${t('85', 'Error downloading file')}`);
        }
      } else {
        displayNotification('error', `${t('86', 'Error creating PDF')}`);
      }
    } catch (error) {
      console.error('Error sending data:', error);
    }
    setGeneratingPDF(false);
  };

  const getIds = () => {
    let Ids = [];
    for (let childSearchedData of searchedData) {
      for (let d of childSearchedData.children) {

        if (d.text_type === 88) {
          if (d.standards_structures_revised.standard_type === LIST_DETAIL_ELEMENTS.standard_type.manuals) {
            Ids.push({ id: d.structures_id, text_type: d.text_type, is_manual: true })
          } else {
            Ids.push({ id: d.structures_id, text_type: d.text_type, is_manual: false })
          }
        }
        else {
          Ids.push({ id: d.id, text_type: d.text_type })
        }

      }
    }

    return Ids;
  }

  const sendKeywordsToBackend = async () => {
    setGeneratingPDFList(true);
    try {
      displayNotification('info', `${t('83', 'Sending Data please wait...')}`);
      const fileName = `WOAH_Report_KeywordsList${currentDate}.pdf`;
      const ret = await sendAPIRequest(
        `/api/search-by-keywords-pdf/`,
        'POST',
        {
          List: searchedData,
          keywords: keywords,
          searchInHeading: searchInHeading,
          searchInText: searchInText,
          componentLanguage: componentLanguage
        });
      const data = await ret.json();
      if (data.success && data.status === 200) {
        const downloadResponse = await sendAPIRequest(`/api/search-by-keywords-pdf/download/?file_name=${fileName}&uniqueName=${data.data.file_name}`);
        if (downloadResponse.ok) {
          const blob = await downloadResponse.blob();
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = fileName ?? 'testing.pdf';
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
          displayNotification('success', `${t('84', 'Successfully downloaded file')}`);
        } else {
          displayNotification('error', `${t('85', 'Error downloading file')}`);
        }
      } else {
        displayNotification('error', `${t('86', 'Error creating PDF')}`);
      }
    } catch (error) {
      console.error('Error sending data:', error);
    }
    setGeneratingPDFList(false);
  };

  const ListPrint = () => {
    setItem(searchedData)
    const updatedKeywords = keywords.filter(keyword => keyword.trim() !== '')
    const url = getBaseURL(`/search-content/search-by-keywords-list/print?animal_type=${props.animalType}&standard_type=${props.standardType}&search_in_text=${searchInText}&search_in_headings=${searchInHeading}&keywords=${updatedKeywords.toString()}&language_id=${componentLanguage}`);
    window.open(url, "self");
  };

  const DetailPrint = () => {
    setItem(searchedData)
    const metaData = encodeURIComponent(JSON.stringify(documentMetaData));
    const updatedKeywords = keywords.filter(keyword => keyword.trim() !== '')
    const url = getBaseURL(`/search-content/search-by-keywords-detail/print?search_in_text=${searchInText}&search_in_headings=${searchInHeading}&keywords=${updatedKeywords.toString()}&is_manual_selected=${isManualSelected}&document_meta_data=${metaData}`);
    window.open(url, "self");
  };

  const handleIsExpand = () => {
    if (isExpanded == true) {
      setIsExpanded(false);
    } else {
      setIsExpanded(true);
    }
  };

  const increaseFontSize = () => {
    if (baseFontSize < 24) {
      setBaseFontSize(prevSize => prevSize + 2);
    }
  };

  const decreaseFontSize = () => {
    if (baseFontSize > 12) {
      setBaseFontSize(prevSize => prevSize - 2);
    }
  };

  const changeComponentLanguage = async (lang) => {
    setComponentLanguage(lang);
  }

  const addTextField = () => {
    if (numFields < 3) {
      setNumFields(numFields + 1);
    }
  };

  const removeTextField = () => {
    if (numFields > 1) {
      const updatedKeywords = [...keywords];
      updatedKeywords.splice(numFields - 1, 1);
      setKeywords(updatedKeywords);
      setNumFields(numFields - 1);
    }
  };

  const renderTextFields = () => {
    const textFields = [];
    for (let i = 1; i <= numFields; i++) {
      textFields.push(
        <>
          <Grid item lg={3} md={3} sm={3} xs={12} key={i}>
            <TextField
              id={`textField-${i - 1}`}
              className="ms-auto my-auto"
              fullWidth
              value={keywords[i - 1]}
              size="small"
              onChange={(e) => handleTextFieldChange(i - 1, e.target.value)}
              placeholder={t("49", "Search by text")}
            />
          </Grid>
          <Grid key={i}
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingX: '2rem' }}>
            {
              i !== numFields &&
              <span style={{
                paddingLeft: '10px',
                paddingRight: '10px',
              }}>AND</span>
            }
          </Grid>
        </>
      );
    }
    return textFields;
  };

  const handleChangeSearch = async () => {
    setShowSearch(true);
    await loadSearchedStandardsData(keywords.filter(keyword => keyword.trim() !== ''));

  }

  const resetFilters = () => {
    setKeywords(Array(numFields).fill(''));
    setCurrentSelected('')
    setItem({});
    setData([]);
    setSearchedData([]);
    setShowSearch(false)
    setDocumentMetaData({
      section_name: '',
      chapter_name: '',
      chapter_no: '',
      section_no: "",
      area_vol_no: ''
    })

  }

  const handleTextFieldChange = (index, value) => {
    const updatedKeywords = [...keywords];
    updatedKeywords[index] = value;
    setKeywords(updatedKeywords);
  }

  const escapeRegExp = (string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  };

  const highlightSearchTerm = (htmlContent, search, currentIndex, stack = []) => {
    let idsStack = stack;
    if (typeof htmlContent !== 'string' || !search) return { highlightedContent: htmlContent, occurrences: 0 };
    const escapedSearch = escapeRegExp(search);
    const regex = new RegExp(`\\b(${escapedSearch})`, 'gi');
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, 'text/html');
    let occurrences = 0;
    const highlightNode = (node) => {
      Array.from(node.childNodes).forEach((childNode) => {
        if (childNode.nodeType === Node.TEXT_NODE) {
          const parts = childNode.textContent.split(regex);
          const fragment = document.createDocumentFragment();
          parts.forEach((part, index) => {
            const span = document.createElement('span');
            if (regex.test(part)) {
              span.style.backgroundColor = 'yellow';
              span.textContent = part;
              // const uniqueId = `highlight_${currentIndex + occurrences + 1}`;
              const uniqueId = generateUniqueId();
              idsStack.push(uniqueId);
              span.id = uniqueId;
              if (occurrences === currentIndex) {
              }
              occurrences++;
            } else {
              span.textContent = part;
            }
            fragment.appendChild(span);
          });
          childNode.parentNode.replaceChild(fragment, childNode);
        } else if (childNode.nodeType === Node.ELEMENT_NODE) {
          highlightNode(childNode);
        }
      });
    };
    highlightNode(doc.body);
    const serializer = new XMLSerializer();
    const highlightedHtml = serializer.serializeToString(doc);
    return { highlightedContent: highlightedHtml, occurrences, returnStack: idsStack };
  };

  const highlightAfterSearch = (stack = [], searchData = []) => {
    let itemOccurrences = 0;
    let finalData = cloneDeep(data);
    for (const search of searchData) {
      let tempData = finalData.map(item => {
        const highlightedItem = { ...item };
        for (const key in item) {
          if (Object.hasOwnProperty.call(item, key)) {
            if (key === 'html_contents') {
              const highlightedContents = item[key].map(htmlContent => {
                const { highlightedContent, occurrences, returnStack } = highlightSearchTerm(htmlContent.content, search, itemOccurrences, stack);
                stack = returnStack;
                itemOccurrences += occurrences;
                return {
                  ...htmlContent,
                  content: highlightedContent
                };
              });
              highlightedItem[key] = highlightedContents;
            } else if (key === 'child') {
              const highlightedChild = {};
              for (const childKey in item[key]) {
                if (Object.hasOwnProperty.call(item[key], childKey)) {
                  if (childKey === 'html_contents') {
                    const highlightedChildContents = item[key][childKey].map(htmlContent => {
                      const { highlightedContent, occurrences, returnStack } = highlightSearchTerm(htmlContent.content, search, itemOccurrences, stack);
                      stack = returnStack;
                      itemOccurrences += occurrences;
                      return {
                        ...htmlContent,
                        content: highlightedContent
                      };
                    });
                    highlightedChild[childKey] = highlightedChildContents;
                  } else {
                    const { highlightedContent, occurrences, returnStack } = highlightSearchTerm(item[key][childKey], search, itemOccurrences, stack);
                    stack = returnStack;
                    if (occurrences > 0) {
                      itemOccurrences += occurrences;
                      highlightedChild[childKey] = highlightedContent;
                    } else {
                      highlightedChild[childKey] = item[key][childKey];
                    }
                  }
                }
              }
              highlightedItem[key] = highlightedChild;
            } else {
              const { highlightedContent, occurrences, returnStack } = highlightSearchTerm(item[key], search, itemOccurrences, stack);
              stack = returnStack;
              if (occurrences > 0) {
                itemOccurrences += occurrences;
                highlightedItem[key] = highlightedContent;
              } else {
                highlightedItem[key] = item[key];
              }
            }
          }
        }
        return highlightedItem;
      });
      finalData = cloneDeep(tempData);
    }
    return { occurrences: itemOccurrences, finalData, finalStack: stack }
  }

  const highlightContent = () => {
    setSearchedIDStack([]);
    let totalCount = 0;
    let stack = [];
    const { finalData: highlighted, occurrences: itemOccurrences, finalStack } = highlightAfterSearch(stack, !search ? keywords : [search]);
    totalCount += itemOccurrences;
    setHighlightedData(highlighted);
    setSearchedIDStack(finalStack);
    setCount(totalCount);
  }

  useEffect(() => {
    setIsManual(manualCheck);
    if (!search && (keywords && keywords.length < 1)) {
      setHighlightedData(data);
      setCount(0);
      setSearchedIDStack([]);
      return;
    }
    highlightContent();
  }, [search, data]);

  const handleHeadingCheckboxChange = (event) => {
    setSearchInHeading(event.target.checked ? 1 : 0);
  };

  const handleTextCheckboxChange = (event) => {
    setSearchInText(event.target.checked ? 1 : 0);
  };

  const returnChapterName = (params, returnChapter = false) => {
    if (params?.parent?.standards_structures_revised) {
      if (params?.parent?.standards_structures_revised?.section_no === 0) {
        return params?.parent?.standards_structures_revised?.chapter_name;
      }
      const areaNumber = (params?.parent?.standards_structures_revised?.area_vol_no && params?.parent?.standards_structures_revised?.standard_type === LIST_DETAIL_ELEMENTS.standard_type.manuals)
        ? `${params?.parent?.standards_structures_revised?.area_vol_no}.` : ''
      const chapter_name = `${areaNumber}${params?.parent?.standards_structures_revised?.section_no}.${params?.parent?.standards_structures_revised?.chapter_no}. ${params?.parent?.standards_structures_revised?.chapter_name}`;
      return returnChapter ? `Chapter ${chapter_name}` : chapter_name;
    } else {
      return '';
    }
  }

  const isInViewport = (element) => {
    const rect = element.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  };

  const manualCheck = () => {
    if (currentSelected) {
      if (currentSelected?.parent?.standards_structures_revised?.standard_type === LIST_DETAIL_ELEMENTS.standard_type.manuals) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  return (
    <>
      <Box sx={{ flexGrow: 1 }} mt={2}>
        <Grid container spacing={0}>
          {renderTextFields()}
          <Grid item lg={1} md={1} sm={1} xs={12} justifyContent="start">
            {numFields < 3 && (
              <LightTooltip title="Add Text field" placement="top">
                <IconButton className='my-auto px-2' onClick={addTextField}>
                  <AddCircleIcon color="primary" />
                </IconButton>
              </LightTooltip>
            )}
            {numFields > 1 && (
              <LightTooltip title="Remove Text field" placement="top">
                <IconButton className='my-auto ps-2' onClick={removeTextField}>
                  <RemoveCircleIcon color='primary' />
                </IconButton>
              </LightTooltip>
            )}
          </Grid>
        </Grid>
      </Box>
      <Grid container justifyContent="flex-end" my={3}>
        <FormGroup row>
          <FormControlLabel
            control={<Checkbox checked={searchInHeading === 1} onChange={handleHeadingCheckboxChange} />}
            label={t("80", "Search within headings")}
          />
          <FormControlLabel
            control={<Checkbox checked={searchInText === 1} onChange={handleTextCheckboxChange} />}
            label={t("81", "Search within text")}
          />
        </FormGroup>
        <button
          className="btn btn--primary ms-1"
          onClick={handleChangeSearch}
          disabled={isLoading.loading_search_data}
        >
          {
            isLoading.loading_search_data == true ? <span> {t("12", "Searching...")}</span> : <span> {t("13", "Search")}</span>
          }
        </button>
        <button
          className="btn btn--outline ms-1"
          onClick={() => {
            resetFilters();
          }}
        >
          <span> {t("14", "Reset")}</span>
        </button>
      </Grid>
      {true && (
        <Grid container spacing={1}>
          {!isExpanded && (
            <Grid item lg={4} md={4} sm={12} xs={12} sx={{ marginTop: '5px' }}>
              <DataTableV2
                getRowId={(row) => row?.parent.id}
                isLoading={isLoading.loading_search_data}
                rows={searchedData}
                className="text-white bg-primary rounded-0"
                columns={[
                  {
                    field: "standards",
                    headerName: t("87", "Standards"),
                    headerClassName: 'text-white bg-primary rounded-0 table-header-text',
                    flex: 0,
                    renderCell: params => (
                      <>
                        {
                          params.row.text_type === LIST_DETAIL_ELEMENTS.text_types.chapter_name
                            ? params.row.standards_structures_revised?.standard_short_name
                            : params.row?.parent?.standards_structures_revised?.standard_short_name
                        }
                      </>
                    )
                  },
                  {
                    field: "results",
                    headerName: t("15", "Results"),
                    headerClassName: "text-white bg-primary rounded-0 table-header-text",
                    flex: 2,
                    renderCell: params => (
                      <div
                        className='cursor-pointer'
                        style={{ width: '100%' }}
                      // onClick={() => {
                      //   handleOnChapterClick(
                      //     params.row.text_type === LIST_DETAIL_ELEMENTS.text_types.chapter_name
                      //       ? params.row.standards_structures_revised?.standard_type
                      //       : params.row?.parent?.standards_structures_revised?.standard_type,
                      //     params.row.text_type === LIST_DETAIL_ELEMENTS.text_types.chapter_name
                      //       ? params.row.standards_structures_revised?.id
                      //       : params.row?.parent?.standards_structures_revised?.id,
                      //     params.row.text_type === LIST_DETAIL_ELEMENTS.text_types.chapter_name ? true : false,
                      //     params.row.id
                      //   );
                      //   setShortName(
                      //     params.row.text_type === LIST_DETAIL_ELEMENTS.text_types.chapter_name
                      //       ? params.row.standards_structures_revised?.standard_short_name
                      //       : params.row?.parent?.standards_structures_revised?.standard_short_name
                      //   );
                      //   setMainResult(params.row?.standard_content);
                      //   setResult(params.row?.parent?.standard_content);
                      // }}
                      >
                        <span className='text-black fw-bold cursor-pointer'>
                          <HtmlRenderer htmlContent={`${returnChapterName(params.row, true)}`} />
                        </span>
                        {params.row?.children?.map((d, k) => (
                          <div
                            // style={{ borderBottom: '1px solid red' }}
                            className={`hover-bold ${activeItemId === d.id ? 'active-bold' : ''}`}
                            key={k + "articles" + d.id}
                            onClick={() => {
                              handleOnChapterClick(
                                d?.text_type === LIST_DETAIL_ELEMENTS.text_types.chapter_name
                                  ? params.row.parent?.standards_structures_revised?.standard_type
                                  : params.row?.parent?.standards_structures_revised?.standard_type,
                                d?.text_type === LIST_DETAIL_ELEMENTS.text_types.chapter_name
                                  ? params.row.parent?.standards_structures_revised?.id
                                  : params.row?.parent?.standards_structures_revised?.id,
                                params.row.children?.text_type === LIST_DETAIL_ELEMENTS.text_types.chapter_name ? true : false,
                                d.id
                              );
                              setChapterID(params.row.parent.id);
                            }}
                          >
                            {
                              stripHtml(d.standard_content, params.row.parent.standards_structures_revised.standard_type === LIST_DETAIL_ELEMENTS.standard_type.codes ? false : true)
                            }
                            {/* <HtmlRenderer htmlContent={d.standard_content} /> */}
                          </div>

                        ))
                        }
                      </div>
                    )
                  },
                ]}
                hasIterator={false}
                pagination
                pageSize={10}
                rowsPerPageOptions={[5, 10, 20]}
                sx={{
                  '& .MuiDataGrid-footerContainer': {
                    display: 'none'
                  }
                }}
              />
            </Grid>
          )}
          {true &&
            <Grid item lg={isExpanded ? 12 : 8} md={isExpanded ? 12 : 8} sm={12} xs={12}>
              <CustomCard type='header' sx={{
                marginTop: '0'
              }}>
                <Box sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
                  <Box sx={{ display: 'flex', justifyItems: 'end', justifyContent: 'end', alignItems: 'center' }}>
                    <Typography
                      variant="button"
                      sx={{
                        cursor: 'pointer',
                        marginX: '5px',
                        textDecoration: parseInt(componentLanguage) === LIST_DETAIL_ELEMENTS.languages.english ? 'underline' : 'none',
                      }}
                      onClick={() => changeComponentLanguage(LIST_DETAIL_ELEMENTS.languages.english)}
                    >
                      EN
                    </Typography>
                    <Typography
                      variant="button"
                      sx={{
                        cursor: 'pointer',
                        marginX: '5px',
                        textDecoration: parseInt(componentLanguage) === LIST_DETAIL_ELEMENTS.languages.french ? 'underline' : 'none',
                      }}
                      onClick={() => (changeComponentLanguage(LIST_DETAIL_ELEMENTS.languages.french))}
                    >
                      FR
                    </Typography>
                    <Typography
                      variant="button"
                      sx={{
                        cursor: 'pointer',
                        marginX: '5px',
                        textDecoration: parseInt(componentLanguage) === LIST_DETAIL_ELEMENTS.languages.spanish ? 'underline' : 'none',
                        paddingRight: 1
                      }}
                      onClick={() => (changeComponentLanguage(LIST_DETAIL_ELEMENTS.languages.spanish))}
                    >
                      ES
                    </Typography>
                    <Divider orientation="vertical" flexItem style={{ backgroundColor: '#000', opacity: 0.87 }} />
                    {data.length > 0 ? (<Typography
                      variant="h6"
                      ms={2}
                      sx={{
                        cursor: 'pointer',
                        paddingLeft: 1,
                      }}
                      onClick={increaseFontSize}
                    >
                      A
                    </Typography>) : (<Typography
                      variant="h6"
                      ms={2}
                      sx={{
                        paddingLeft: 1,
                      }}
                    >
                      A
                    </Typography>)}
                    {data.length > 0 ? (<Typography
                      variant="subtitle1"
                      mx={2}
                      sx={{ cursor: 'pointer' }}
                      onClick={decreaseFontSize}
                    >
                      A
                    </Typography>) : (
                      <Typography
                        variant="subtitle1"
                        mx={2}
                      >
                        A
                      </Typography>
                    )}

                    <Divider orientation="vertical" flexItem style={{ backgroundColor: '#000', opacity: 0.87 }} />
                    {
                      generatingPDFList ?
                        <LightTooltip title="Generating PDF" placement="top" style={{ margin: '1rem' }}>
                          <CircularProgress size={20} />
                        </LightTooltip> :
                        <LightTooltip title="Download PDF List" placement="top"
                          disabled={searchedData.length > 0 ? false : true}
                        >
                          <IconButton
                            onClick={sendKeywordsToBackend}>
                            <PictureAsPdfIcon
                              color='primary'
                            />
                          </IconButton>
                        </LightTooltip>
                    }
                    <LightTooltip title="Print List" placement="top"
                      disabled={searchedData.length > 0 ? false : true}
                    >
                      <IconButton
                        onClick={ListPrint}
                      >
                        <PrintIcon
                          color='primary'
                        />
                      </IconButton>
                    </LightTooltip>
                    <Divider orientation="vertical" flexItem style={{ backgroundColor: '#000', opacity: 0.87 }} />
                    {
                      generatingPDF ?
                        <LightTooltip title="Generating PDF" placement="top" style={{ margin: '1rem' }}>
                          <CircularProgress size={20} />
                        </LightTooltip> :
                        <LightTooltip title="Download PDF Detail" placement="top"
                          disabled={searchedData.length > 0 ? false : true}
                        >
                          <IconButton
                            onClick={detailPdf}>
                            <PictureAsPdfIcon
                              color='primary'
                            />
                          </IconButton>
                        </LightTooltip>
                    }
                    <LightTooltip title="Print Detail" placement="top"
                      disabled={searchedData.length > 0 ? false : true}
                    >
                      <IconButton
                        onClick={DetailPrint}
                      >
                        <PrintIcon
                          color='primary'
                        />
                      </IconButton>
                    </LightTooltip>

                    <LightTooltip
                      title={
                        !isExpanded ? "Expand Chapter" : "Collapse Chapter"
                      }
                      placement="top"
                    >
                      <IconButton onClick={handleIsExpand} >
                        {isExpanded ? (
                          <ZoomInMapIcon color="primary" />
                        ) : (
                          <OpenWithIcon color="primary" />
                        )}

                      </IconButton>
                    </LightTooltip>
                    {data.length > 0 ? (<LightTooltip title={"Open in new tab"} placement="top"
                    >
                      <a
                        href={
                          codeId
                            ? getBaseURL(`/portal/chapter/?rid=${currentSelected?.parent?.standards_structures_revised.RID}&language=${componentLanguage}&ismanual=${isManual}&standard_type=${currentSelected?.parent?.standards_structures_revised.standard_type}&animal_type=${currentSelected?.parent?.standards_structures_revised.animal_type}`)
                            : ""
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                        className={`cursor-pointer text-hover-primary border-0 ms-2`}
                      >
                        <IconButton >
                          <OpenInNew color="primary" />
                        </IconButton>
                      </a>
                    </LightTooltip>) : (<LightTooltip
                    >

                      <a
                        // href={
                        //   codeId
                        //     ? getBaseURL(`chapter/?structure=${codeId}`)
                        //     : ""
                        // }
                        // target="_blank"
                        // rel="noopener noreferrer"
                        cursor="normal"
                        className={`cursor-pointer text-hover-primary border-0 ms-2`}
                      >
                        <IconButton >
                          <OpenInNew color="primary" />
                        </IconButton>
                      </a>
                    </LightTooltip>
                    )}
                  </Box>
                  <Divider />

                  <Box marginY={2}
                    sx={{
                      display: 'flex',
                      fontSize: '11px', justifyItems: 'start', justifyContent: 'start', alignItems: 'center'
                    }}
                  >
                    <span style={{
                      color: 'inherit',
                      marginRight: '',
                    }}
                    >
                      {
                        currentSelected && currentSelected.text_type == LIST_DETAIL_ELEMENTS.text_types.chapter_name ? currentSelected?.standards_structures_revised.standard_short_name : currentSelected?.parent?.standards_structures_revised?.standard_short_name
                      }
                      {
                        currentSelected && (<span style={{ marginLeft: '14px' }}>/</span>)
                      }
                    </span>
                    <a
                      href={
                        codeId
                          ? getBaseURL(`/portal/chapter/?rid=${currentSelected?.parent?.standards_structures_revised.RID}&language=${componentLanguage}&ismanual=${isManual}&standard_type=${currentSelected?.parent?.standards_structures_revised.standard_type}&animal_type=${currentSelected?.parent?.standards_structures_revised.animal_type}`)
                          : ""
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ marginLeft: '14px' }}
                      className={`cursor-pointer text-hover-primary border-0`}
                    >
                      <HtmlRenderer
                        className="document-chftre-intitule"
                        htmlContent={
                          currentSelected && currentSelected.text_type == LIST_DETAIL_ELEMENTS.text_types.chapter_name
                            ? `${currentSelected?.standards_structures_revised.chapter_no} ${currentSelected?.standards_structures_revised.chapter_name}`
                            : returnChapterName(currentSelected, true)
                        }
                      />
                      {/* 
                      {
                        currentSelected && currentSelected.text_type == LIST_DETAIL_ELEMENTS.text_types.chapter_name
                         ? `${currentSelected?.standards_structures_revised.chapter_no} ${currentSelected?.standards_structures_revised.chapter_name}` 
                         : returnChapterName(currentSelected, true)
                      } */}
                    </a>
                    {
                      currentSelected && currentSelected.text_type == 90 &&
                      <>
                        <span style={{ marginLeft: '14px' }}>/</span>
                        <span style={{
                          color: 'inherit',
                          marginLeft: '14px',
                          marginRight: '14px',
                        }}
                        >
                          {
                            stripHtml(currentSelected && currentSelected?.text_type === LIST_DETAIL_ELEMENTS.text_types.chapter_name
                              ? currentSelected?.standards_structures_revised?.article_number
                              : currentSelected?.standard_content, false)
                          }
                        </span>
                      </>
                    }
                  </Box>
                </Box>
                <Divider />
                <Grid container spacing={2} alignItems="center" mt={1}>
                  {data.length > 0 ? (
                    <>
                      <Grid item xs={8} >
                        <TextField
                          required={false}
                          error={false}
                          fullWidth
                          size="small"
                          value={search}
                          onChange={(e) => (setSearch(e.target.value), setCurrentTerm(0))}
                          placeholder='Search content here...'
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <SearchIcon
                                  style={{
                                    fontSize: 'medium'
                                  }}
                                />
                              </InputAdornment>
                            ),
                            endAdornment: (
                              <InputAdornment position="end">
                                {search.length > 0 && (
                                  <ClearIcon
                                    onClick={handleClearSearch}
                                    style={{
                                      cursor: 'pointer',
                                      fontSize: 'medium'
                                    }}
                                  />
                                )}
                              </InputAdornment>
                            ),
                          }}
                          sx={{
                            backgroundColor: '#fff',
                            paddingBottom: '20px',
                          }}
                        />
                      </Grid>
                      {
                        search && search.length > 0 &&
                        <Grid item xs={4}>
                          <Grid sx={{ 'display': 'flex' }}>
                            <span className='cursor-pointer mx-1'
                              onClick={() => {
                                const previousTerm = currentTerm - 1;
                                if (previousTerm >= 0) {
                                  const element = document.getElementById(searchedIDStack[previousTerm]);
                                  if (currentElement) {
                                    currentElement.style.backgroundColor = 'yellow';
                                    currentElement.style.color = '';
                                  }
                                  if (element) {
                                    setCurrentElement(element);
                                    element.style.backgroundColor = '#ff4815';
                                    element.style.color = '#fff';

                                    if (!isInViewport(element)) {
                                      element.scrollIntoView({ behavior: "smooth" });
                                    }
                                  }
                                  setCurrentTerm(previousTerm);
                                }
                              }}
                            >
                              <KeyboardArrowUp sx={{
                                color: '#ff4815'
                              }}
                                fontSize="small"
                              />
                            </span>
                            <span className='cursor-pointer mx-1'
                              onClick={() => {
                                const nextTerm = currentTerm + 1;
                                if (nextTerm <= count) {
                                  const element = document.getElementById(searchedIDStack[nextTerm]);
                                  if (currentElement) {
                                    currentElement.style.backgroundColor = 'yellow';
                                    currentElement.style.color = '';
                                  }
                                  if (element) {
                                    setCurrentElement(element);
                                    element.style.backgroundColor = '#ff4815';
                                    element.style.color = '#fff';

                                    if (!isInViewport(element)) {
                                      element.scrollIntoView({ behavior: "smooth" });
                                    }
                                  }
                                  setCurrentTerm(nextTerm);
                                }
                              }}
                            >
                              <KeyboardArrowDown sx={{
                                color: '#ff4815'
                              }}
                                fontSize="small"
                              />
                            </span>
                            <Typography
                              variant="body2"
                            >
                              {t("60", "Number of Matches:")} {count}
                            </Typography>

                          </Grid>
                        </Grid>
                      }

                    </>) : (null)}
                </Grid>
                <Box sx={{
                  height: '600px',
                  padding: '0rem 1rem',
                  overflowY: 'auto',
                  scrollbarWidth: 'thin',
                }}
                >
                  {
                    isLoading.loading_data ? (
                      <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        textAlign: 'center',
                        marginTop: '30px'
                      }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <CircularProgress />
                          <Typography variant="body1" sx={{ marginLeft: '10px' }}>
                            Loading data please wait...
                          </Typography>
                        </Box>
                      </Box>
                    ) :
                      highlightedData && highlightedData.length > 0 ? (
                        highlightedData.map((d, index) => (
                          <Box
                            key={`key-${index}`}
                            id={'key-${index}'}
                          >
                            {
                              d.text_type === LIST_DETAIL_ELEMENTS.text_types.section_name
                              && documentMetaData.chapter_name == 1
                              && (
                                <Box
                                  key={`section-${d.id}`}
                                >
                                  <Typography
                                    className="pb-5 pt-10 text-black text-center section-heading"
                                    sx={{ letterSpacing: "2px" }}
                                    key={d.id}
                                  >
                                    {
                                      isManualSelected ?
                                        `Section ${documentMetaData.area_vol_no !== "" ? documentMetaData.area_vol_no + '.' : ''}${documentMetaData.section_no}` :
                                        `Section ${documentMetaData.section_name}`
                                    }
                                  </Typography>
                                  <Typography
                                    className="chapter-heading"
                                    sx={{
                                      letterSpacing: "2px",
                                      textAlign: "center"
                                    }}
                                    key={`name-${d.id}`}
                                  >
                                    <HtmlRenderer
                                      tag={"p"}
                                      htmlContent={d?.standard_content}
                                    />
                                  </Typography>
                                  <Divider
                                    sx={{
                                      width: "50%",
                                      marginX: 'auto'
                                    }}
                                  />
                                  <Divider
                                    sx={{
                                      width: "50%",
                                      paddingTop: '5px',
                                      marginX: 'auto'
                                    }}
                                  />
                                </Box>
                              )
                            }
                            {
                              d.text_type === LIST_DETAIL_ELEMENTS.text_types.chapter_name && (
                                <Box key={`chapter-${d.id}`}>
                                  <Box
                                    className="pb-5 pt-10 text-black text-center section-heading"
                                    sx={{
                                      letterSpacing: "2px",
                                      textAlign: "center",
                                      paddingTop: '15px'
                                    }}
                                    key={d.id}
                                  >
                                    {
                                      isManualSelected ?
                                        `Chapter ${documentMetaData.area_vol_no !== "" || documentMetaData.area_vol_no !== null || documentMetaData.area_vol_no !== undefined ? documentMetaData.area_vol_no + '.' : ''}${documentMetaData.section_no}.${documentMetaData.chapter_no}` :
                                        `Chapter ${documentMetaData.section_name}.${documentMetaData.chapter_name}.`
                                    }
                                  </Box>
                                  <Typography
                                    className="chapter-heading"
                                    variant="h3"
                                    sx={{
                                      letterSpacing: "2px",
                                      textAlign: "center"
                                    }}
                                    key={`name-${d.id}`}
                                  >
                                    <HtmlRenderer
                                      tag={"p"}
                                      className="document-chftre-intitule"
                                      htmlContent={d?.standard_content}
                                    />
                                  </Typography>
                                </Box>
                              )
                            }
                            {
                              (d.text_type === LIST_DETAIL_ELEMENTS.text_types.article_heading || d.text_type === LIST_DETAIL_ELEMENTS.heading_types.general_heading) && (
                                <Box
                                  key={`article-${d.id}`}
                                  id={`article-${d.id}`}
                                >
                                  <HtmlRenderer
                                    tag={"div"}
                                    className="text-center"
                                    style={{ fontSize: `${baseFontSize + 2}px`, marginBottom: '20px', marginTop: '10px' }}
                                    htmlContent={d?.standard_content}
                                  />
                                  {
                                    d.html_contents &&
                                      d.html_contents.length > 0 ? (
                                      d.html_contents.map((child) => (
                                        <Box
                                          sx={{
                                            paddingX: '4px',
                                            textAlign: 'justify',
                                          }}
                                          key={`art-child-${child.id}`}
                                        >
                                          <HtmlRenderer
                                            tag={"div"}
                                            style={{ fontSize: `${baseFontSize}px`, }}
                                            htmlContent={child.content}
                                          />
                                        </Box>
                                      ))
                                    ) : (
                                      d.child && (
                                        <Box
                                          key={`general-${d.child.id}`}
                                          id={`${d.child.id}`}
                                        >
                                          <HtmlRenderer
                                            tag={"div"}
                                            className={LIST_DETAIL_ELEMENTS.heading_types.general_heading === d.child.text_type}
                                            style={{ fontSize: `${baseFontSize + 2}px` }}
                                            htmlContent={d.child?.standard_content}
                                          />
                                          {d.child.html_contents &&
                                            d.child.html_contents.length > 0 &&
                                            d.child.html_contents.map((child, i) => (
                                              <>
                                                {child.text_type === LIST_DETAIL_ELEMENTS.styles_categories.code_editing_date_text && (
                                                  <Divider />
                                                )}
                                                <Box
                                                  key={`gen-child-${child.id}`}
                                                  sx={{
                                                    display: 'flex',
                                                    justifyContent: child.text_type === LIST_DETAIL_ELEMENTS.styles_categories.code_editing_date_text ? 'flex-end' : 'normal',
                                                  }}
                                                >
                                                  <Box className={LIST_DETAIL_ELEMENTS.heading_types.general_heading === d.child.text_type ? "text-justified" : 'justify-content-end'}>
                                                    <HtmlRenderer
                                                      tag={LIST_DETAIL_ELEMENTS.heading_types.general_heading === d.child.text_type ? "div" : "p"}
                                                      style={{
                                                        fontSize: `${baseFontSize}px`,
                                                      }}
                                                      htmlContent={child.content}
                                                    />
                                                  </Box>
                                                </Box>
                                              </>
                                            ))}
                                        </Box>
                                      )
                                    )
                                  }
                                </Box>
                              )
                            }
                            {
                              (
                                d.text_type === LIST_DETAIL_ELEMENTS.text_types.manual_heading
                                || d.text_type === LIST_DETAIL_ELEMENTS.text_types.manual_reference
                                || d.text_type === LIST_DETAIL_ELEMENTS.text_types.manual_appendex
                                || d.text_type === LIST_DETAIL_ELEMENTS.text_types.manual_biblography
                                || d.text_type === LIST_DETAIL_ELEMENTS.text_types.manual_Further_reading
                                || d.text_type === LIST_DETAIL_ELEMENTS.text_types.manual_online_resource
                                || d.text_type === LIST_DETAIL_ELEMENTS.text_types.manual_paragraph
                                || d.text_type === LIST_DETAIL_ELEMENTS.styles_categories.code_editing_date_text
                                || d.text_type === LIST_DETAIL_ELEMENTS.text_types.glossary_term
                              ) &&
                              <Box
                                key={`article-${d.id}`}
                                id={`article-${d.id}`}
                              >
                                <HtmlRenderer
                                  tag={"div"}
                                  className="text-center"
                                  style={{ fontSize: `${baseFontSize + 2}px`, marginBottom: '20px', marginTop: '10px' }}
                                  htmlContent={d?.standard_content}
                                />
                                {
                                  d.html_contents &&
                                    d.html_contents.length > 0 ? (
                                    d.html_contents.map((child) => (
                                      <Box
                                        sx={{
                                          paddingX: '4px',
                                          textAlign: 'justify',
                                        }}
                                        key={`art-child-${child.id}`}
                                      >
                                        <HtmlRenderer
                                          tag={"div"}
                                          style={{ fontSize: `${baseFontSize}px`, }}
                                          htmlContent={child.content}
                                        />
                                      </Box>
                                    ))
                                  ) : (
                                    d.child && (
                                      <Box
                                        key={`general-${d.child.id}`}
                                        id={`${d.child.id}`}
                                      >
                                        <HtmlRenderer
                                          tag={"div"}
                                          className={LIST_DETAIL_ELEMENTS.heading_types.general_heading === d.child.text_type}
                                          style={{ fontSize: `${baseFontSize + 2}px` }}
                                          htmlContent={d.child?.standard_content}
                                        />
                                        {d.child.html_contents &&
                                          d.child.html_contents.length > 0 &&
                                          d.child.html_contents.map((child, i) => (
                                            <>
                                              {child.text_type === LIST_DETAIL_ELEMENTS.styles_categories.code_editing_date_text && (
                                                <Divider />
                                              )}
                                              <Box
                                                key={`gen-child-${child.id}`}
                                                sx={{
                                                  display: 'flex',
                                                  justifyContent: child.text_type === LIST_DETAIL_ELEMENTS.styles_categories.code_editing_date_text ? 'flex-end' : 'normal',
                                                }}
                                              >
                                                <Box className={LIST_DETAIL_ELEMENTS.heading_types.general_heading === d.child.text_type ? "text-justified" : 'justify-content-end'}>
                                                  <HtmlRenderer
                                                    tag={LIST_DETAIL_ELEMENTS.heading_types.general_heading === d.child.text_type ? "div" : "p"}
                                                    style={{
                                                      fontSize: `${baseFontSize}px`,
                                                    }}
                                                    htmlContent={child.content}
                                                  />
                                                </Box>
                                              </Box>
                                            </>
                                          ))}
                                      </Box>
                                    )
                                  )
                                }
                              </Box>
                            }
                          </Box>
                        ))
                      ) : (
                        <Box sx={{ textAlign: 'center', marginTop: '30px' }}>
                          <Typography variant='body2' color={'primary'} sx={{
                            marginTop: '30px'
                          }}>
                            {showSearch && ("Please select any chapter from table to show preview.")}
                          </Typography>
                        </Box>
                      )
                  }
                </Box>
              </CustomCard>
            </Grid>}
        </Grid>
      )}
    </>
  );
}

export default SearchByKeyword;
