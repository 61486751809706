import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { useSelector } from 'react-redux';

const Crumbs = ({ crumbs }) => {

    const authData = useSelector(s => s.auth.data);
    const [crumbsData, setCrumbsData] = useState([...crumbs]);

    useEffect(() => {
        let temp = [...crumbsData];
        if (authData && authData.id && temp[0].label != "Home") {
            temp.unshift({
                path: "/portal/",
                label: "Home",
                active: false,
            });
            temp = temp.filter(val => val != "");
            setCrumbsData([...temp]);
        }
    }, []);

    return (
        <>
            <Breadcrumbs aria-label="breadcrumb">
                {crumbsData.map((crumb, index) => (
                    <Link
                        key={index}
                        to={crumb.path || ""}
                        style={{
                            color: crumb.active ? "black" : "#ff4815",
                            fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                            pointerEvents: crumb.path ? "auto" : "none",
                        }}
                    >
                        {crumb.label}
                    </Link>
                ))}
            </Breadcrumbs>
        </>
    );
}

export default Crumbs;