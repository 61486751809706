import React from 'react'
import { GridActionsCellItem } from "@mui/x-data-grid";
import DataTable from "pages/components/DataTable";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next"; 

const FiltersData = ({isLoading=false,data=[],handleDelete}) => {
    const [location, setLocation] = useSearchParams();
    const { t } = useTranslation();
    const navigate = useNavigate();

  return (

            <div className="header card-woah border border-secondary d-flex px-4 py-2 shadow-sm w-100">
                <DataTable
                    getRowId={(row) => row.id}
                    isLoading={isLoading}
                    rows={data}
                    className="text-white bg-primary rounded-0"
                    columns={
                        [
                            {
                                field: "animal_name",
                                headerName: t("-1", "Animal"),
                                headerClassName: "text-white bg-primary rounded-0",
                                flex: 1,
                                valueGetter: (params) => {
                                    return params.row?.animal?.animal_name
                                  },
                            },
                           
                            {
                                field: "disease",
                                headerName: t("-1", "Disease"),
                                headerClassName: "text-white bg-primary rounded-0",
                                flex: 1,
                                valueGetter: (params) => {  
                                    return params.row?.disease?.disease_name
                                  },
                            },
                            {
                                field: "actions",
                                type: "actions",
                                headerClassName: "text-white bg-primary rounded-0",
                                headerName: t("-1", "Actions"),
                                getActions: (params) => {
                                    return [
                                        <GridActionsCellItem
                                            label={
                                                "Edit"
                                            }
                                            key={0}
                                            showInMenu
                                            onClick={() => {
                                                navigate(`/portal/draft/taging/chapter/create/?id=${params.row.id}`);
                                            }}
                                        />,
                                        <GridActionsCellItem
                                            label={
                                                "Delete"
                                            }
                                            key={0}
                                            showInMenu
                                            onClick={() => {
                                                handleDelete(params.row.id);
                                            }}
                                        />,
                                    ]
                                }
                            },
                        ]
                    }
                />
            </div>

  )
}

export default FiltersData
