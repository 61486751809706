import { Tooltip } from '@mui/material';
import AutoCompleteDropdown from 'components/autocomplete/AutoCompleteDropdown';
import { generateOptionsFromData } from 'helpers';
import React, { useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from "react-i18next";

const IgnoreDataModal = ({ children, data, reloadData }) => {

    const { t } = useTranslation();

    const [modalShowing, setModalShowing] = useState(false);
    const [selectedIgnoreData, setSelectedIgnoreData] = useState([]);

    const handleRestore = () => {
        let temp = [];
        data.forEach(d => {
            if (selectedIgnoreData.includes(d.id)) {
                temp.push(d);
            }
        });
        setModalShowing(false);
        reloadData && reloadData(temp, selectedIgnoreData);
        setSelectedIgnoreData([]);
    }

    const openModel = () => {
        setModalShowing(true);
    }

    const dataOptions = useMemo(() => {
        return generateOptionsFromData(data, {
            value: "id",
            label: "json_content",
        });
    }, [data]);

    return (
        <>
            {children(openModel)}
            <Modal show={modalShowing} size='md' centered={true}>
                <Modal.Header
                    className='p-4'
                    closeButton
                    onClick={() => (setModalShowing(false))}
                >
                    <Modal.Title>Ignore Data</Modal.Title>
                </Modal.Header>
                <Modal.Body className='p-4'>
                    <div className='row'>
                        <div className="col-12">
                            <Tooltip
                                title={t("-1", "You can select multiple options")}
                                placement="top"
                                arrow
                            >
                                <AutoCompleteDropdown
                                    required={true}
                                    error={true}
                                    label={t("-1", "Select 1 or more Ignore Data")}
                                    multiple={true}
                                    hasselectall={true}
                                    options={dataOptions}
                                    onChange={(event, value) => {
                                        if (value.some((v) => v.value == "Select all")) {
                                            if (selectedIgnoreData.length == dataOptions.length) {
                                                // Deselect all options
                                                setSelectedIgnoreData([]);
                                            } else {
                                                // Select all options
                                                setSelectedIgnoreData(dataOptions.map((r) => r.value));
                                            }
                                        } else {
                                            setSelectedIgnoreData(value.map((r) => r.value));
                                        }
                                    }
                                    }
                                    value={selectedIgnoreData}
                                />
                            </Tooltip>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className='p-4 d-flex justify-content-center'>
                    <button
                        // variant="dark"
                        className='btn btn--primary' 
                        onClick={() => (setModalShowing(false))}
                    >
                        {t("-1", "Close")}
                    </button>
                    <button
                        // variant="primary"
                        className='btn btn--primary' 
                        onClick={() => handleRestore()}
                    >
                        {t("-1", "Restore Data")}
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

IgnoreDataModal.defaultProps = {
    children: () => { },
    data: [],
    reloadData: undefined,
}

export default IgnoreDataModal;