import { useState, useCallback } from 'react';

const usePopupAlert = () => {
    const [showPopup, setShowPopup] = useState(false);
    const [popupDetail, setPopupDetail] = useState({
        title: '',
        body: '',
        variant: ''
    });

    const showPopupAlert = useCallback((title, body, variant) => {
        setPopupDetail({ title, body, variant });
        setShowPopup(true);
    }, []);

    const handleClose = useCallback(() => {
        setShowPopup(false);
    }, []);

    return {
        showPopup,
        popupDetail,
        showPopupAlert,
        handleClose
    };
};

export default usePopupAlert;
