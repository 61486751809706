import Spinner from "components/spinner/Spinner";
import { displayNotification, sendAPIRequest } from "helpers";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Modal } from 'react-bootstrap';
import { useImperativeHandle } from 'react';

const ImageUpload = React.forwardRef(({ children, id, reloadData }, ref) => {

    const { t } = useTranslation();
    const authData = useSelector(s => s.auth.data)

    const [showModel, setShowModel] = useState(false);
    const [modalOpened, setModalOpened] = useState(false);
    const [fileName, setFileName] = useState("");
    const [currentFile, setCurrentFile] = useState();
    const [isLoading, setIsLoading] = useState({
        is_uploading: false,
    });

    const [data, setData] = useState({
        id: id,
        reloadData: reloadData,
    });

    useImperativeHandle(ref, () => {
        return {
            handleOpen
        }
    });

    const handleOpen = (id, reloadData) => {
        if (!modalOpened) {
            id && setData(prev => ({ ...prev, id: id }));
            reloadData && setData(prev => ({ ...prev, reloadData: reloadData }));
            setShowModel(true);
            setModalOpened(true);
        }
    }

    const fileUpload = () => {
        setFileName("");
        setCurrentFile("");
        const fileInput = document.getElementById("get-file");
        fileInput.accept = ".png, .jpg, .jpeg"
        fileInput.click();
    };

    const getFile = (files) => {
        const selectedFile = files[0];
        if (selectedFile) {
            setCurrentFile(selectedFile);
            setFileName(selectedFile.name);
        } else {
            setFileName("");
        }
    };

    const uploadImage = async () => {
        setIsLoading({ ...isLoading, is_uploading: true });
        try {
            const formData = new FormData();
            formData.append("file", currentFile);
            formData.append("document_id", data.id);
            formData.append("createdBy", authData.id);
            const ret = await sendAPIRequest(
                `/api/document-images/`,
                'POST',
                formData
            );
            const res = await ret.json();
            if (res.success && res.status === 201) {
                displayNotification('success', 'Successfully uploaded image [B]');
            }  else if (res.success && res.status === 401) {
                displayNotification('error', 'Image already exist [B]');
            } else {
                displayNotification('error', 'Failed to uploade image [B]');
            }
        } catch (e) {
            console.error(e);
            displayNotification('error', 'Failed to uploade image [F]');
        }
        setIsLoading({ ...isLoading, is_uploading: false });
    }

    return (
        <>
            {children(handleOpen)}
            <Modal show={showModel} size='md' centered={true}>
                <Modal.Header
                    className='p-4'
                    closeButton
                    onClick={() => (
                        setShowModel(false),
                        setModalOpened(false)
                    )}
                >
                    <Modal.Title>{t("120","Image Upload")}</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: '70%',
                    backgroundPosition: "19px"
                }}
                    className='p-5 m-auto border-0'>
                        <div className="card-body p-4">
                            <div className="align-items-center d-flex flex-column">
                                <div className="d-flex flex-column tempus-dominus-widget p-3">
                                    <button
                                        type="button"
                                        className="btn btn--primary mb-2"
                                        onClick={() => fileUpload()}
                                    >
                                        {t("37", "Browse File")}
                                    </button>
                                    <label>
                                        {fileName ? (
                                            <div>
                                                <strong>{t("38", "Selected File")}: </strong>
                                                {fileName}
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </label>
                                    <input
                                        type="file"
                                        id="get-file"
                                        style={{ display: "none" }}
                                        onChange={(e) => getFile(e.target.files)}
                                    />
                                    <button
                                        type="button"
                                        className="btn btn--primary mt-5"
                                        onClick={() => uploadImage()}
                                        disabled={isLoading.is_uploading}
                                    >
                                        {
                                            isLoading.is_uploading ? (
                                                <Spinner message={`${t("200", "Uploading")}...`} />
                                            ) : t("95", "Upload")
                                        }
                                    </button>
                                </div>
                            </div>
                        </div>
                </Modal.Body>
                <Modal.Footer className='p-4 d-flex justify-content-center'>
                    <button
                        // variant="dark" 
                        className='btn btn--primary'
                        onClick={() => (
                            setShowModel(false),
                            setModalOpened(false)
                        )}
                    >
                        {t("201", "Close")}
                    </button>

                </Modal.Footer>
            </Modal>
        </>
    );
});

ImageUpload.defaultProps = {
    children: () => { },
    id: null,
    reloadData: null
};

export default ImageUpload;