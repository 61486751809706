import { createSlice } from '@reduxjs/toolkit';
import { SITE_LANGUAGES } from '../constants';

export const generalSlice = createSlice({
  name: 'general',
  initialState: {
    sidebarOpened: false,
    siteLanguageSont: '102',
    siteLanguageSF: 'en',
    currentPage: '',
    headerTheme: 'white',
  },
  reducers: {
    setSidebarState: (state, payload = false) => {
      state.sidebarOpened = payload.payload;
    },
    setSiteLanguage: (state, payload) => {
      state.siteLanguageSont = payload.payload;
      state.siteLanguageSF = SITE_LANGUAGES[payload.payload] ?? 'en';
    },
    setCurrentPage: (state, payload) => {
      state.currentPage = payload.payload;
    },
    setHeaderTheme: (state, payload) => {
      state.headerTheme = payload.payload ? payload.payload : 'white';
    },
  },
})

export const { setSidebarState, setCurrentPage, setHeaderTheme, setSiteLanguage } = generalSlice.actions

export default generalSlice.reducer