import { displayNotification, sendAPIRequest, stripHtml } from 'helpers';
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Modal, Button, Form, Alert } from 'react-bootstrap';
import Spinner from "components/spinner/Spinner";
import { useTranslation } from 'react-i18next';
import TextFieldCustom from 'components/TextFieldCustom';
import { LIST_DETAIL_ELEMENTS } from 'constants';
import AutoCompleteDropdown from 'components/autocomplete/AutoCompleteDropdown';


const AddSectionModal = ({ show, handleClose, standardDetails, componentLanguage, loadData }) => {

    const { t } = useTranslation();
    const [sectionName, setSectionName] = useState('');
    const [chapterName, setchapterName] = useState('');
    const [areaVolName, setAreaVolName] = useState([]);
    const [areaVolNameValue, setAreaVolNameValue] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const resetStates = () => {
        handleClose();
        setSectionName('');
        setchapterName('');
        setAreaVolName([]);
        setAreaVolNameValue('');
    }

    const saveSection = async () => {
        if (!window.confirm('Are you sure you want to add this section?')) {
            return;
        }
        setIsLoading(true);
        try {
            const ret = await sendAPIRequest(`/api/draft-chapter/add-addSection`, 'POST', {
                "standard_type": standardDetails?.standard_type,
                "animal_type": standardDetails?.animal_type,
                "section_name": sectionName,
                "chapter_name": chapterName,
                ...(standardDetails?.standard_type === LIST_DETAIL_ELEMENTS?.standard_type?.manuals ? {
                    "area_vol_no": areaVolNameValue?.value ?? '',
                    "area_vol_name": areaVolNameValue?.label ?? '',
                } : null)
            });
            const res = await ret.json();
            if (res.success && res.status === 200) {
                displayNotification("success", "Section Added Successfully");
                resetStates()
                await loadData(standardDetails.standard_type, standardDetails.animal_type);
            } else {
                displayNotification('error', res.message);
            }
        } catch (error) {
            console.log({ error });
            displayNotification("error", "Internall Server Error [F]")
        }
        finally {
            setIsLoading(false);
        }
    }
    const getAreaVolData = async () => {
        try {
            const ret = await sendAPIRequest(`/api/draft-standard-structure/get-area-volume/?animal_type=${standardDetails?.animal_type}&standard_type=${standardDetails?.standard_type}&lang_id=${componentLanguage}&version_year=${standardDetails?.version_year}`);
            const res = await ret.json()
            if (res.success && res.status === 200) {
                setAreaVolName(res.data);
            } else {
                displayNotification("error", "Failed to get Area Volume names.");
            }
        } catch (e) {
            console.log({ e });
        }
    }

    useEffect(() => {
        if (standardDetails?.standard_type === LIST_DETAIL_ELEMENTS?.standard_type?.manuals) {
            getAreaVolData();
        }
    }, [])

    return (
        <Modal className='' size="xl" show={show} onHide={handleClose}

        >
            <Modal.Header
                closeButton
                className='p-4 fw-bold fs-3'
                onClick={() => { resetStates() }}
            >
                <Modal.Title>Add Section Modal</Modal.Title>
            </Modal.Header>
            <Modal.Body className='p-4'>
                <div className=' row '>
                    {standardDetails?.standard_type === 6 ?
                        <div className="col-4">
                            <AutoCompleteDropdown
                                label='Area/Vol'
                                className='opacity-100'
                                isGroup={false}
                                showClearIcon={true}
                                error={false}
                                required={true}
                                multiple={false}
                                hasselectall={false}
                                options={areaVolName}
                                value={areaVolNameValue}
                                onChange={(event, value) => {
                                    if (value) {
                                        setAreaVolNameValue(value)
                                    }
                                    else {
                                        setAreaVolNameValue('')
                                    }
                                }}
                                size='medium'
                            />
                        </div>
                        : null}
                    <div className="col-4">
                        <TextFieldCustom
                            variant="outlined"
                            id="document-title"
                            fullWidth
                            size="small"
                            required={true}
                            label={t("-1", "Section Name")}
                            sx={{
                                backgroundColor: "white",
                                color: "white",
                                border: "#ff4815",
                                width: "98%",
                            }}
                            value={sectionName}
                            onChange={(e) => {
                                setSectionName(e.target.value)
                            }
                            }
                        />
                    </div>
                    <div className="col-4">
                        <TextFieldCustom
                            variant="outlined"
                            id="document-title"
                            fullWidth
                            size="small"
                            required={true}
                            label={t("-1", "Chapter Name")}
                            sx={{
                                backgroundColor: "white",
                                color: "white",
                                border: "#ff4815",
                                width: "98%",
                            }}
                            value={chapterName}
                            onChange={(e) => { setchapterName(e.target.value) }
                            }
                        />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={() => saveSection()} disabled={isLoading}>
                    {isLoading ? "Saving..." : "Save"}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};



export default AddSectionModal;