import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useTranslation } from 'react-i18next';
import TextFieldCustom from 'components/TextFieldCustom';
import { Divider, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { displayNotification, sendAPIRequest } from 'helpers';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '40%',
    height: '450px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    overflowY: 'auto',
    padding: 0,
};

const RenameChapterModalCustom = ({ open = false, NbContent, handleClose, handleSaveRenameSection }) => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [NBtext, setNBtxt] = useState(NbContent.standard_content ?? "");

    // useEffect(() => {
    //     if (data.length > 0 && sectionName) {
    //         const selectedSection = data.find((item) => item.id === sectionName);
    //         if (selectedSection) {
    //             setChapterName(selectedSection.name);
    //         }
    //     }
    // }, [data, sectionName]);

    const handleSave = async ()=>{
        try {
            if (!window.confirm("Are you sure you want to update?")) { return; }
            const ret = await sendAPIRequest(`/api/contents/update-nb/${NbContent.id}`, 'PUT', {
                standard_content: NBtext,
            });
            const res = await ret.json();
            if (res.success && res.status === 200) {
                displayNotification("success", "Successfully Updated");
            } else {
                displayNotification("error", res.message);
            }
            handleClose();
        } catch (e) {
            console.log({ e });
            displayNotification("error", "failed to update");
        }
    }

    return (
        <>
            <Modal
                open={open}
                onClose={() => handleClose(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box sx={{ padding: '10px 20px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Rename Chapter
                        </Typography>
                        <IconButton onClick={() => handleClose(false)}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Divider sx={{ backgroundColor: '#000', margin: '0' }} />
                    <Box sx={{ padding: '20px' }}>
                        <div className='row'>
                            <div className='col-12 mb-3'>
                                <TextFieldCustom
                                    variant="outlined"
                                    label="Rename Chapter"
                                    id="Section Name"
                                    fullWidth
                                    rows={12}
                                    required={true}
                                    multiline={true}
                                    sx={{
                                        backgroundColor: "white",
                                        color: "white",
                                        border: "#ff4815",
                                        width: "99%",
                                    }}
                                    size='large'
                                    value={NBtext}
                                    onChange={(e) => {
                                        setNBtxt(e.target.value)
                                    }}
                                />
                            </div>
                        </div>
                        <div className='row mt-10'>
                            <div className="col-12 text-center mt-3">
                                <button
                                    className="btn btn--outline font-custom fs-6 me-2"
                                    onClick={() => handleClose(false)}
                                >
                                    {t("201", "Close")}
                                </button>
                                <button
                                    className="btn btn--primary font-custom fs-6"
                                    onClick={() => { handleSave() }}
                                    disabled={isLoading}
                                >
                                    {isLoading ? t("-1", "Saving...") : t("-1", "Save")}
                                </button>
                            </div>
                        </div>
                    </Box>
                </Box>
            </Modal>
        </>
    )
}

RenameChapterModalCustom.defaultProps = {

};

export default RenameChapterModalCustom
