import React, { useEffect, useState } from 'react'
import { TRANSFER_STATUS } from 'constants';
import { displayNotification, sendAPIRequest } from 'helpers';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { LIST_DETAIL_ELEMENTS } from 'constants';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled, Typography } from "@mui/material";


const EditingHistory = () => {

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const authData = useSelector(s => s.auth.data);
    const [requestRelloader, setRequestRelloader] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);


    useEffect(() => {
        loadDraftData();
    }, []);


    const historyData = [
        { tc: "TC", orgName: "WOAH Animal Organization Health", icon: "fa-info-circle" },
        { tc: "TC", orgName: "WOAH Animal Organization Health", icon: "fa-info-circle" },
        // Add more items as needed
    ];

    const loadDraftData = async () => {
        setIsLoading(true);
        try {
            const ret = await sendAPIRequest(`/api/draft-chapter/history`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                setData(res.data);
            }
        } catch (err) {
            console.log({ err });
            displayNotification('error', 'Failed to load draft data [F]!');
        }
        setIsLoading(false);
    }
    function truncateString(text = '', maxLength = 30) {
        if (text.length > maxLength) {
            const truncatedText = text.slice(0, maxLength).trim();
            const lastSpaceIndex = truncatedText.lastIndexOf(' ');
            const finalText = lastSpaceIndex > -1 ? truncatedText.slice(0, lastSpaceIndex) : truncatedText;
            return finalText + '...';
        } else {
            return text;
        }
    }
    const HtmlTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} placement="top-start" />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#f5f5f9',
            color: 'rgba(0, 0, 0, 0.87)',
            maxWidth: 1000,
            fontSize: theme.typography.pxToRem(12),
            border: '1px solid #787878',
            padding: '30px',
            boxShadow: '1px 2px 9px #A9A9A9',
        },
    }));
    return (
        <>
            <div className='p-5'>
                <div className="card-title2 lh-1 border-bottom border-dark">{t("66", "History")}</div>
                <div className="scrollable-content" style={{ height: '100vh', overflow: 'auto' }}>
                    <div>
                        <ul className='pt-6 fs-6 pb-20 px-0'>
                            {data.map((item, index) => (
                                <li key={index} className="d-flex justify-content-between align-items-center mb-3">
                                    <span className="fw-bold">{item.draft_chapter?.standard_short_name}</span>
                                    <span className="ms-8 flex-grow-1">{
                                        item.draft_chapter.stanndard_type === LIST_DETAIL_ELEMENTS.standard_type.codes ?
                                            item.draft_chapter.section_no + "." + item.draft_chapter.chapter_no + " " + truncateString(item.draft_chapter?.chapter_name ?? "", 40) :
                                            item.draft_chapter.area_vol_no + "." + item.draft_chapter.section_no + "." + item.draft_chapter.chapter_no + " " + truncateString(item.draft_chapter?.chapter_name ?? "", 40)}</span>
                                    <HtmlTooltip
                                                    title={
                                                        <React.Fragment>
                                                            <Typography color="inherit">
                                                                {/* <InfoCard/> */}
                                                               
                                                                <ul>
                                                                    <li>
                                                                  Author: {item?.draft_chapter?.author?.user_name??''}
                                                                    </li>
                                                                    <li>
                                                                 Validator: {item.sender?.user_name??""}
                                                                    </li>
                                                                    <li>
                                                                    Publisher: {item?.receiver?.user_name ?? ""}
                                                                    </li>
                                                                </ul>
                                                            </Typography>
                                                        </React.Fragment>
                                                    }
                                                >
                                                     <i className='fas fa-info-circle text-muted fs-4 me-2 cursor-pointer'></i> 
                                                </HtmlTooltip>
                                  {/* Icon */}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EditingHistory
