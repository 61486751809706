import React, { useEffect } from 'react'
import { useTranslation } from "react-i18next";

const Contactus = () => {

    const { t } = useTranslation();


    return (
        <>
            <div className="container mb-2 mt-20">
                <div className="row mt-20 pt-20">
                    <div className="col-md-6 py-5 pe-8">
                        <h1 className=" font-custom-2 fs-b text-end">
                            Contact Us
                        </h1>
                    </div>
                    <div className="col-md-3 border-start border-2 border-dark">
                        <div className="bg-dropdown-menu px-7 py-10">
                            <div className="font-custom-2 text-primary fs-1 ">
                                Email
                            </div>
                            <p className="fs-5 text-white">
                                tcc.secretariat@woah.org
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Contactus
