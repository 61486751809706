import { Chip } from '@mui/material'
import React from 'react'

const themes = {
    primary: {
        backgroundColor: '#ff4815',
        color: '#ffffff',
    },
    blue: {
        backgroundColor: '#dbe7ff',
        color: '#003366',
    },
    green: {
        backgroundColor: '#D9EEE1',
        color: '#04AA6D',
    },
    orange: {
        backgroundColor: '#ffeee7',
        color: '#cc6600',
    },
    purple: {
        backgroundColor: '#E5E0FF',
        color: '#6A0DAD',
    },
    red: {
        backgroundColor: '#FFCDD2',
        color: '#B71C1C',
    },
};
function CustomChip(props) {
    return (
        <Chip
            {...props}
            sx={{
                ...props.sx,
                ...themes[props.type],
                borderRadius: '12px',
                paddingY: 0,
                height: '25px',
                fontWeight: 'bold',
                fontSize: '0.75rem',
            }}
        />
    )
}

CustomChip.defaultProps = {
    type: 'primary',
    sx: {}
};

export default CustomChip