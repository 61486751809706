import { useState, useEffect, useRef, useMemo } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials';
import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import Heading from '@ckeditor/ckeditor5-heading/src/heading';
import List from '@ckeditor/ckeditor5-list/src/list';
import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold';
import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic';
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';
// import { FontFamily, FontSize } from '@ckeditor/ckeditor5-font/src/font';
import FontSize from '@ckeditor/ckeditor5-font/src/fontsize';
import FontFamily from '@ckeditor/ckeditor5-font/src/fontfamily';
import Autoformat from '@ckeditor/ckeditor5-autoformat/src/autoformat'
import AutoImage from '@ckeditor/ckeditor5-image/src/autoimage';
import AutoLink from '@ckeditor/ckeditor5-link/src/autolink';
import Autosave from '@ckeditor/ckeditor5-autosave/src/autosave';
import BalloonToolbar from '@ckeditor/ckeditor5-ui/src/toolbar/balloon/balloontoolbar';
import BlockQuote from '@ckeditor/ckeditor5-block-quote/src/blockquote';
import BlockToolbar from '@ckeditor/ckeditor5-ui/src/toolbar/block/blocktoolbar';
import Bookmark from '@ckeditor/ckeditor5-bookmark/src/bookmark';
import Base64UploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/base64uploadadapter';
import Code from '@ckeditor/ckeditor5-basic-styles/src/code';
import CodeBlock from '@ckeditor/ckeditor5-code-block/src/codeblock';
import FindAndReplace from '@ckeditor/ckeditor5-find-and-replace/src/findandreplace';
import FontBackgroundColor from '@ckeditor/ckeditor5-font/src/fontbackgroundcolor';
import FontColor from '@ckeditor/ckeditor5-font/src/fontcolor';
import GeneralHtmlSupport from '@ckeditor/ckeditor5-html-support/src/generalhtmlsupport';
import Highlight from '@ckeditor/ckeditor5-highlight/src/highlight';
import HorizontalLine from '@ckeditor/ckeditor5-horizontal-line/src/horizontalline';
import HtmlComment from '@ckeditor/ckeditor5-html-support/src/htmlcomment';
import HtmlEmbed from '@ckeditor/ckeditor5-html-embed/src/htmlembed';
import ImageBlock from '@ckeditor/ckeditor5-image/src/imageblock';
import ImageCaption from '@ckeditor/ckeditor5-image/src/imagecaption';
import ImageInline from '@ckeditor/ckeditor5-image/src/imageinline';
import ImageInsert from '@ckeditor/ckeditor5-image/src/imageinsert';
import ImageInsertViaUrl from '@ckeditor/ckeditor5-image/src/imageinsertviaurl';
import ImageResize from '@ckeditor/ckeditor5-image/src/imageresize';
import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle';
import ImageTextAlternative from '@ckeditor/ckeditor5-image/src/imagetextalternative';
import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar';
import ImageUpload from '@ckeditor/ckeditor5-image/src/imageupload';
import Indent from '@ckeditor/ckeditor5-indent/src/indent';
import IndentBlock from '@ckeditor/ckeditor5-indent/src/indentblock';
import Link from '@ckeditor/ckeditor5-link/src/link';
import LinkImage from '@ckeditor/ckeditor5-link/src/linkimage';
import ListProperties from '@ckeditor/ckeditor5-list/src/listproperties';
// import Markdown from '@ckeditor/ckeditor5-markdown-gfm/src/markdown';
import MediaEmbed from '@ckeditor/ckeditor5-media-embed/src/mediaembed';
import Mention from '@ckeditor/ckeditor5-mention/src/mention';
import PageBreak from '@ckeditor/ckeditor5-page-break/src/pagebreak';
import PasteFromMarkdownExperimental from '@ckeditor/ckeditor5-markdown-gfm/src/pastefrommarkdownexperimental';
import PasteFromOffice from '@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice';
import RemoveFormat from '@ckeditor/ckeditor5-remove-format/src/removeformat';
import ShowBlocks from '@ckeditor/ckeditor5-show-blocks/src/showblocks';
import SourceEditing from '@ckeditor/ckeditor5-source-editing/src/sourceediting';
import SpecialCharacters from '@ckeditor/ckeditor5-special-characters/src/specialcharacters';
import SpecialCharactersArrows from '@ckeditor/ckeditor5-special-characters/src/specialcharactersarrows';
import SpecialCharactersCurrency from '@ckeditor/ckeditor5-special-characters/src/specialcharacterscurrency';
import SpecialCharactersEssentials from '@ckeditor/ckeditor5-special-characters/src/specialcharactersessentials';
import SpecialCharactersLatin from '@ckeditor/ckeditor5-special-characters/src/specialcharacterslatin';
import SpecialCharactersMathematical from '@ckeditor/ckeditor5-special-characters/src/specialcharactersmathematical';
import SpecialCharactersText from '@ckeditor/ckeditor5-special-characters/src/specialcharacterstext';
import Strikethrough from '@ckeditor/ckeditor5-basic-styles/src/strikethrough';
// import Style from '@ckeditor/ckeditor5-basic-styles/src/style';
import Subscript from '@ckeditor/ckeditor5-basic-styles/src/subscript';
import Superscript from '@ckeditor/ckeditor5-basic-styles/src/superscript';
import Table from '@ckeditor/ckeditor5-table/src/table';
import TableCaption from '@ckeditor/ckeditor5-table/src/tablecaption';
import TableCellProperties from '@ckeditor/ckeditor5-table/src/tablecellproperties';
import TableColumnResize from '@ckeditor/ckeditor5-table/src/tablecolumnresize';
import TableProperties from '@ckeditor/ckeditor5-table/src/tableproperties';
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar';
// import TextPartLanguage from '@ckeditor/ckeditor5-text-part-language/src/textpartlanguage';
// import TextTransformation from '@ckeditor/ckeditor5-text-transform/src/texttransformation';
// import Title from '@ckeditor/ckeditor5-heading/src/title';
import TodoList from '@ckeditor/ckeditor5-list/src/todolist';
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline';
import IndentPlugin from './IndentPlugin'

import FootNote from './CustomFootNotePlugin';
import fetchCustomStyles from './CustomStyles'; // Import the fetch function
import ImagePicker from './ImageGalleryPlugin'

import { PasteFromOfficeEnhanced } from '@ckeditor/ckeditor5-paste-from-office-enhanced/src';
import { FormatPainter } from '@ckeditor/ckeditor5-format-painter/src';

/**
 * Create a free account with a triaImagePicker: https://portal.ckeditor.com/checkout?plan=free
 */
// const LICENSE_KEY = 'NHovd1ZGN2hudTNGQW5rT2pKcGpQM2daNkFLcXFoVUltWnJBKzhKSWN4akkzVGFKYi9scy1NakF5TlRFd01ERT0=';
// const LICENSE_KEY = 'GPL';
// const LICENSE_KEY = '4z/wVF7hnu3FAnkOjJpjP3gZ6AKqqhUImZrA+8JIcxjI3TaJb/ls';
// const LICENSE_KEY = 'eyJhbGciOiJFUzI1NiJ9.eyJleHAiOjE3NTkyNzY3OTksImp0aSI6IjQ1M2JhZTUzLWIzMjItNGM3ZS1iM2Q4LTQ0NzA3MmI4MjIwYSIsImxpY2Vuc2VkSG9zdHMiOlsiMTI3LjAuMC4xIiwibG9jYWxob3N0IiwiMTkyLjE2OC4qLioiLCIxMC4qLiouKiIsIjE3Mi4qLiouKiIsIioudGVzdCIsIioubG9jYWxob3N0IiwiKi5sb2NhbCJdLCJkaXN0cmlidXRpb25DaGFubmVsIjpbInNoIiwiZHJ1cGFsIl0sIndoaXRlTGFiZWwiOnRydWUsImxpY2Vuc2VUeXBlIjoiZGV2ZWxvcG1lbnQiLCJmZWF0dXJlcyI6WyJEUlVQIiwiRE8iLCJGUCIsIlNDIiwiVE9DIiwiVFBMIiwiUE9FIiwiQ0MiLCJNRiJdLCJ2YyI6IjRmM2Y0OWNjIn0.wwLVyeonGHCFgFmea5OEOAAx7phjAqVTE64kzl20ukgUBZpgPlOID2frntjYTwrj6qD_3mYbHclZ_o6fBDFDcQ'
// const LICENSE_KEY = 'eyJhbGciOiJFUzI1NiJ9.eyJleHAiOjE3NTkyNzY3OTksImp0aSI6ImRkMjhiOTJkLTkzZWMtNDAwZC1iZWUwLTAxZDU1YTRhYmE3OSIsImRpc3RyaWJ1dGlvbkNoYW5uZWwiOlsic2giLCJkcnVwYWwiXSwid2hpdGVMYWJlbCI6dHJ1ZSwiZmVhdHVyZXMiOlsiRFJVUCIsIkRPIiwiRlAiLCJTQyIsIlRPQyIsIlRQTCIsIlBPRSIsIkNDIiwiTUYiXSwidmMiOiJlOWY5M2RhYiJ9.3KJtniFm5UbqRXnZMtZ2RWbQklW5MG2HKDAVQ7RO0sK4c4UsiaBK9DPoVlGU5jTYKBwZS0zM0iVbu-qsvoX-lA'
const LICENSE_KEY = process.env.REACT_APP_CK_EDITOR_LICENSE_KEY;


export default function App({ editorData, setEditorData, customConfig, disable }) {
	const editorContainerRef = useRef(null);
	const editorRef = useRef(null);
	const [isLayoutReady, setIsLayoutReady] = useState(false);
	const [customStyles, setCustomStyles] = useState([]);

	// useEffect(() => {
	// 	const fetchStyles = async () => {
	// 		const styles = await fetchCustomStyles();
	// 		setCustomStyles(styles.map(customStyle => ({ model: 'headings-' + Math.floor(Math.random() * 400) + Date.now() + Math.floor(Math.random() * 10000), title: customStyle.name, view: { name: customStyle?.html_tag?.short_name ?? 'p', classes: customStyle.classes.replace(/(p\.|div\.)/g, '').replace(/,/g, ' ') }, class: 'ck-heading_paragraph' })));
	// 		setIsLayoutReady(true);
	// 	}

	// 	fetchStyles();

	// 	return () => {
	// 		setIsLayoutReady(false);
	// 	}
	// }, []);

	useEffect(() => {
		const fetchStyles = async () => {
			const styles = await fetchCustomStyles();
			setCustomStyles(styles.map(customStyle => ({
				model: 'headings-' + Math.floor(Math.random() * 400) + Date.now() + Math.floor(Math.random() * 10000),
				title: customStyle.name,
				view: {
					name: customStyle?.html_tag?.short_name ?? 'p',
					classes: customStyle.classes.replace(/(p\.|div\.)/g, '').replace(/,/g, ' '),
					css_styles: customStyle.styles
				},
				class: 'ck-heading_paragraph'
			})));
			setIsLayoutReady(true);
		}

		fetchStyles();

		return () => {
			setIsLayoutReady(false);
		}
	}, []);

	useEffect(() => {
		if (customStyles.length > 0) {
			let styles = "";

			customStyles.forEach(style => {
				const className = style.view?.classes;

				if (style.view?.css_styles?.length > 0) {
					style.view.css_styles.forEach(cssStyle => {
						styles += `.${className} { ${cssStyle.css_property}: ${cssStyle.css_property_value}; } `;
					});
				}
			});

			const styleSheet = document.createElement("style");
			styleSheet.type = "text/css";
			styleSheet.innerText = styles;

			document.head.appendChild(styleSheet);

			return () => {
				document.head.removeChild(styleSheet);
			};
		}
	}, [customStyles]);



	const { editorConfig } = useMemo(() => {
		if (!isLayoutReady) {
			return {};
		}
		return {
			editorConfig: {
				chapterId: customConfig.chapterId,
				toolbar: {
					items: [
						'sourceEditing',
						'showBlocks',
						'formatPainter',
						'findAndReplace',
						'textPartLanguage',
						'|',
						'heading',
						'style',
						'|',
						'fontSize',
						'fontFamily',
						'fontColor',
						'fontBackgroundColor',
						'|',
						'bold',
						'italic',
						'underline',
						'strikethrough',
						'subscript',
						'superscript',
						'code',
						'removeFormat',
						'|',
						'specialCharacters',
						'horizontalLine',
						'pageBreak',
						'link',
						'bookmark',
						'insertImage',
						'mediaEmbed',
						'insertTable',
						'highlight',
						'blockQuote',
						'codeBlock',
						'|',
						'alignment',
						'|',
						'bulletedList',
						'numberedList',
						'todoList',
						'|',
						'customIndent',
						'customOutdent',
						...(disable ? [] : ['imagePicker', 'footnote'])
					],
					shouldNotGroupWhenFull: true
				},
				plugins: [
					Alignment,
					Autoformat,
					AutoImage,
					AutoLink,
					Autosave,
					BalloonToolbar,
					Base64UploadAdapter,
					BlockQuote,
					BlockToolbar,
					Bold,
					Bookmark,
					Code,
					CodeBlock,
					Essentials,
					FindAndReplace,
					FontBackgroundColor,
					FontColor,
					FontFamily,
					FontSize,
					FormatPainter,
					FootNote,
					// FullPage,
					GeneralHtmlSupport,
					Heading,
					Highlight,
					PasteFromOffice,
					HorizontalLine,
					HtmlComment,
					HtmlEmbed,
					ImageBlock,
					ImageCaption,
					ImageInline,
					ImageInsert,
					ImageInsertViaUrl,
					ImageResize,
					ImageStyle,
					ImageTextAlternative,
					ImageToolbar,
					ImageUpload,
					Indent,
					ImagePicker,
					IndentBlock,
					Italic,
					Link,
					LinkImage,
					List,
					ListProperties,
					MediaEmbed,
					Mention,
					PageBreak,
					Paragraph,
					PasteFromMarkdownExperimental,
					PasteFromOfficeEnhanced,
					RemoveFormat,
					ShowBlocks,
					SourceEditing,
					SpecialCharacters,
					SpecialCharactersArrows,
					SpecialCharactersCurrency,
					SpecialCharactersEssentials,
					SpecialCharactersLatin,
					SpecialCharactersMathematical,
					SpecialCharactersText,
					Strikethrough,
					// Style,
					Subscript,
					Superscript,
					Table,
					TableCaption,
					TableCellProperties,
					TableColumnResize,
					TableProperties,
					TableToolbar,
					// TextPartLanguage,
					// TextTransformation,
					// Title,
					TodoList,
					Underline,
					IndentPlugin
				],
				// balloonToolbar: ['bold', 'italic', '|', 'link', 'insertImage', '|', 'bulletedList', 'numberedList'],
				// blockToolbar: [
				// 	'fontSize',
				// 	'fontColor',
				// 	'fontBackgroundColor',
				// 	'|',
				// 	'bold',
				// 	'italic',
				// 	'|',
				// 	'link',
				// 	'insertImage',
				// 	'insertTable',
				// 	'|',
				// 	'bulletedList',
				// 	'numberedList',
				// 	'outdent',
				// 	'indent'
				// ],
				fontFamily: {
					supportAllValues: true
				},
				fontSize: {
					options: [10, 12, 14, 'default', 18, 20, 22],
					supportAllValues: true
				},
				heading: {
					options: [
						...customStyles
					]
				},
				htmlSupport: {
					allow: [
						{
							name: /^.*$/,
							styles: true,
							attributes: true,
							classes: true
						}
					]
				},
				image: {
					toolbar: [
						'toggleImageCaption',
						'imageTextAlternative',
						'|',
						'imageStyle:inline',
						'imageStyle:wrapText',
						'imageStyle:breakText',
						'|',
						'resizeImage'
					]
				},
				initialData: editorData,
				licenseKey: LICENSE_KEY,
				htmlSupport: {
					allow: [
						{
							name: /^.*$/,
							styles: true,
							attributes: true,
							classes: true
						}
					]
				},
				allowedContent: true,
				link: {
					addTargetToExternalLinks: true,
					defaultProtocol: 'https://',
					decorators: {
						toggleDownloadable: {
							mode: 'manual',
							label: 'Downloadable',
							attributes: {
								download: 'file'
							}
						}
					}
				},
				list: {
					properties: {
						styles: true,
						startIndex: true,
						reversed: true
					}
				},
				mention: {
					feeds: [
						{
							marker: '@',
							feed: [
								/* See: https://ckeditor.com/docs/ckeditor5/latest/features/mentions.html */
							]
						}
					]
				},
				placeholder: 'Type or paste your content here!',
				style: {
					definitions: [
						{
							name: 'Article category',
							element: 'h3',
							classes: ['category']
						},
						{
							name: 'Title',
							element: 'h2',
							classes: ['document-title']
						},
						{
							name: 'Subtitle',
							element: 'h3',
							classes: ['document-subtitle']
						},
						{
							name: 'Info box',
							element: 'p',
							classes: ['info-box']
						},
						{
							name: 'Side quote',
							element: 'blockquote',
							classes: ['side-quote']
						},
						{
							name: 'Marker',
							element: 'span',
							classes: ['marker']
						},
						{
							name: 'Spoiler',
							element: 'span',
							classes: ['spoiler']
						},
						{
							name: 'Code (dark)',
							element: 'pre',
							classes: ['fancy-code', 'fancy-code-dark']
						},
						{
							name: 'Code (bright)',
							element: 'pre',
							classes: ['fancy-code', 'fancy-code-bright']
						}
					]
				},
				table: {
					contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells', 'tableProperties', 'tableCellProperties']
				}
			}
		};
	}, [isLayoutReady]);

	useEffect(() => {
		if (editorConfig) {
			configUpdateAlert(editorConfig);
		}
	}, [editorConfig]);

	return (
		<div className="main-container">
			<div
				className="editor-container editor-container_classic-editor editor-container_include-style editor-container_include-block-toolbar"
				ref={editorContainerRef}
			>
				<div className="editor-container__editor">
					<div ref={editorRef}>{editorConfig && <CKEditor editor={ClassicEditor} config={editorConfig} data={editorData} onChange={(event, editor) => {
						const data = editor.getData();
						setEditorData(data);
					}} />}</div>
				</div>
			</div>
		</div>
	);
}

/**
 * This function exists to remind you to update the config needed for premium features.
 * The function can be safely removed. Make sure to also remove call to this function when doing so.
 */
function configUpdateAlert(config) {
	if (configUpdateAlert.configUpdateAlertShown) {
		return;
	}

	const isModifiedByUser = (currentValue, forbiddenValue) => {
		if (currentValue === forbiddenValue) {
			return false;
		}

		if (currentValue === undefined) {
			return false;
		}

		return true;
	};

	const valuesToUpdate = [];

	configUpdateAlert.configUpdateAlertShown = true;

	if (!isModifiedByUser(config.licenseKey, '<YOUR_LICENSE_KEY>')) {
		valuesToUpdate.push('LICENSE_KEY');
	}

	if (valuesToUpdate.length) {
		window.alert(
			[
				'Please update the following values in your editor config',
				'to receive full access to Premium Features:',
				'',
				...valuesToUpdate.map(value => ` - ${value}`)
			].join('\n')
		);
	}
}
