import React, { useEffect, useRef, useState } from "react";
import { Link } from 'react-router-dom';
import logo from "images/logo.png";
import { useDispatch, useSelector } from 'react-redux';
import { changeSiteLanguage } from "index";
import { useTranslation } from "react-i18next";
import NavbarUserIcon from "pages/components/NavbarUserIcon";
import { LIST_DETAIL_ELEMENTS } from "constants";
import { getHeaderHeight } from "helpers";

const Header = (passedProps) => {

	const { t } = useTranslation();
	const dispatch = useDispatch();

	const headerTheme = useSelector((s) => s.general.headerTheme);
	const siteLanguage = useSelector(s => s.general.siteLanguageSont);
	const authData = useSelector(s => s.auth.data);
	const currentPage = useSelector((s) => s.general.currentPage);
	const [scrollToTopOffset, setScrollToTopOffset] = useState(0);
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const [updatePublishDropdownOpen, setUpdatePublishDropdownOpen] = useState(false);
	const [publishingDropdownOpen, setPublishingDropdownOpen] = useState(false);
	const [adminDropdownOpen, setAdminDropdownOpen] = useState(false);
	const [toolsDropdownOpen, setToolsDropdownOpen] = useState(false);
	const dropdownRef = useRef(null);
	const updatePublishDropdownRef = useRef(null);
	const publishingDropdownRef = useRef(null);
	const adminDropdownRef = useRef(null);
	const toolsDropdownRef = useRef(null);

	useEffect(() => {
		window.removeEventListener("scroll", handleWindowScroll);
		window.addEventListener("scroll", handleWindowScroll, { passive: true });
		return () => window.removeEventListener("scroll", handleWindowScroll);
	}, []);

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	const handleWindowScroll = () => setScrollToTopOffset(window.pageYOffset);

	const handleDropdownToggle = () => {
		setDropdownOpen(!dropdownOpen);
	};

	const handleUpdatePublishDropdownToggle = () => {
		setUpdatePublishDropdownOpen(!updatePublishDropdownOpen);
	};

	const handlePublishDropdownToggle = () => {
		setPublishingDropdownOpen(!publishingDropdownOpen);
	};

	const handleToolsDropdownToggle = () => {
		setToolsDropdownOpen(!updatePublishDropdownOpen);
	};

	const handleAdminDropdownToggle = () => {
		setAdminDropdownOpen(!adminDropdownOpen);
	};

	const handleClickOutside = (event) => {
		if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
			setDropdownOpen(false);
		}
		if (updatePublishDropdownRef.current && !updatePublishDropdownRef.current.contains(event.target)) {
			setUpdatePublishDropdownOpen(false);
		}
		if (publishingDropdownRef.current && !publishingDropdownRef.current.contains(event.target)) {
			setPublishingDropdownOpen(false);
		}
		if (adminDropdownRef.current && !adminDropdownRef.current.contains(event.target)) {
			setAdminDropdownOpen(false);
		}
		if (toolsDropdownRef.current && !toolsDropdownRef.current.contains(event.target)) {
			setToolsDropdownOpen(false);
		}
	};

	const handleDropdownClose = () => {
		setDropdownOpen(false);
		setUpdatePublishDropdownOpen(false);
		setPublishingDropdownOpen(false);
		setAdminDropdownOpen(false);
		setToolsDropdownOpen(false);
	};

	return (
		<>
			{
				authData && authData.id ? (
					<>
						<section
							className={`bob sticky-top bg-${headerTheme}`}
							style={{
								top: `-${scrollToTopOffset >= 200 ? getHeaderHeight() : 0}px`,
							}}
						>
							<div className={`container `}>
								<div className="border-bottom border-dark navbar">
									<div className="d-flex header-res">
										<a
											className="navbar-brand"
											id="logo-brand"
											href="https://www.woah.org/"
											target="_blank"
										>
											<img src={logo} height="50px" />
										</a>
										<div
											style={{ height: "4rem" }}
											className="ms-4 title-res border border-right-0 border-top-0 border-bottom-0 border-dark ps-3 d-flex align-items-center"
										>
											<Link
												className="navbar-brand"
												id="logo-brand-pvs"
												to="/"
											>
												<h2 className=" pvs-title fw-bold align-middle text-uppercase fw-bold my-0">
													{t("214", "Codes & Manuals - Back Office")}
												</h2>
											</Link>
										</div>
									</div>
									<div
										className="d-flex flex-column flex-lg-row flex-md-row flex-sm-row"
										data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
										data-kt-menu-attach="parent"
										data-kt-menu-placement="bottom-end"
									>
										<NavbarUserIcon />
									</div>
								</div>
								{
									authData.role_id > 0 && (
										<nav className="navbar navbar-expand-xl navbar-light ">
											<div className="flex-grow-1 d-flex flex-column">
												<button
													className="navbar-toggler ms-auto"
													type="button"
													data-bs-toggle="collapse"
													data-bs-target="#navbarSupportedContent"
													aria-controls="navbarSupportedContent"
													aria-expanded="false"
													aria-label="Toggle navigation"
												>
													<span className="navbar-toggler-icon" />
												</button>
												<div
													className="collapse navbar-collapse"
													id="navbarSupportedContent"
												>
													<ul className="navbar-nav mx-auto">
														{/* uncomment this after demo */}
														<li className="nav-item border-1 border-dark" style={{ listStyleType: "none" }}>
															<Link
																className={`nav-link py-0 transition-hover-all-0  text-hover-primary ${currentPage == "home" ? "text-primary" : ""
																	}`}
																to="/portal/"
															>
																<span className="fs-7 text-uppercase fw-bold  lh-1">
																	{t("1", "Home")}
																</span>
															</Link>
														</li>
														<li
															className="nav-item dropdown mx-2 px-3"
															style={{ marginTop: '12px', listStyleType: 'none' }}
															ref={dropdownRef}
														>
															<span
																className={`fs-7 text-uppercase fw-bold dropdown-toggle lh-1 ${currentPage === 'display-navigation' ? 'text-primary' : ''}`}
																type="button"
																id="dropdownMenuButton"
																onClick={handleDropdownToggle}
																aria-haspopup="true"
																aria-expanded={dropdownOpen}
															>
																{t('2', 'Display and Navigation Tool')}
															</span>
															<div
																className={`dropdown-menu custom-dropdown-menu${dropdownOpen ? ' show' : ''}`}
																aria-labelledby="dropdownMenuButton"
															>
																<Link
																	className="dropdown-item custom-dropdown-item fs-6"
																	to="/portal/content-navigation"
																	onClick={handleDropdownClose}
																>
																	{t('46', 'Content Navigation')}
																</Link>
																<Link
																	className="dropdown-item custom-dropdown-item fs-6"
																	to="/portal/advance-search"
																	onClick={handleDropdownClose}
																>
																	{t('47', 'Search Content')}
																</Link>
															</div>
														</li>
														<li className="nav-item" style={{ listStyleType: "none" }}>
															<Link
																className={`nav-link py-0 transition-hover-all-0 text-hover-primary  ${currentPage == "access-commodities" ? "text-primary" : ""
																	}`}
																to="/portal/access-commodities"
															>
																<span className="fs-7 text-uppercase fw-bold  lh-1">
																	{t("3", "Recommended Measures for Trade by Commodity")}
																</span>
															</Link>
														</li>
														{/* {
															(authData && authData.role_id === 3 || authData.role_id === LIST_DETAIL_ELEMENTS.roles.publisher || authData.role_id ===
																LIST_DETAIL_ELEMENTS.roles.validator) && (
																<li className="nav-item" style={{ listStyleType: "none" }}>
																	<Link className={`nav-link py-0 transition-hover-all-0 text-hover-primary ${currentPage == "draft-management"
																		? "text-primary" : ""}`} to="/portal/draft/index">
																		<span className="fs-7 text-uppercase fw-bold  lh-1">
																			{t("-1", "Testing Publisher")}
																		</span>
																	</Link>
																</li>
															)
														} */}
														{/* uncomment this after demo */}
														{
															(authData &&
																(authData.role_id === 2 ||
																	authData.role_id === 3 ||
																	authData.role_id === LIST_DETAIL_ELEMENTS.roles.publisher ||
																	authData.role_id === LIST_DETAIL_ELEMENTS.roles.validator)
															) && (
																<li
																	className="nav-item dropdown mx-2 px-3"
																	style={{ marginTop: '12px', listStyleType: 'none' }}
																	ref={publishingDropdownRef}
																>
																	<span
																		className={`fs-7 text-uppercase fw-bold dropdown-toggle lh-1 ${currentPage === 'publishing-standards' ? 'text-primary' : ''}`}
																		type="button"
																		id="dropdownMenuButton"
																		onClick={handlePublishDropdownToggle}
																		aria-haspopup="true"
																		aria-expanded={publishingDropdownOpen}
																	>
																		{t('322', 'Publishing Tool')}
																	</span>
																	<div
																		className={`dropdown-menu custom-dropdown-menu${publishingDropdownOpen ? ' show' : ''}`}
																		aria-labelledby="dropdownMenuButton"
																	>
																		<Link
																			className="dropdown-item custom-dropdown-item fs-6"
																			to="/portal/publishing-standards"
																			onClick={handleDropdownClose}
																		>
																			{t('-1', 'Global Publication Management')}
																		</Link>
																		<Link
																			className="dropdown-item custom-dropdown-item fs-6"
																			to="/portal/modify-existing"
																			onClick={handleDropdownClose}
																		>
																			{t('-1', 'Modify Existing Content')}
																		</Link>
																		{(authData &&
																			(authData.role_id === 3 ||
																				authData.role_id === LIST_DETAIL_ELEMENTS.roles.publisher ||
																				authData.role_id === LIST_DETAIL_ELEMENTS.roles.validator)
																		) && (
																				<>
																					<Link
																						className="dropdown-item custom-dropdown-item fs-6"
																						to="/portal/content-review"
																						onClick={handleDropdownClose}
																					>
																						{t('-1', 'New Edition Review')}
																					</Link>
																					<Link
																						className="dropdown-item custom-dropdown-item fs-6"
																						to={{ pathname: "/portal/access-commodities", search: "?in_review=true" }}
																						onClick={handleDropdownClose}
																					>
																						{t('-1', 'New Edition Commodities Review')}
																					</Link>
																				</>
																			)}
																	</div>
																</li>
															)
														}
														{
															(authData && authData.role_id === 2) && (
																<li
																	className="nav-item dropdown mx-2 px-3"
																	style={{ marginTop: '12px', listStyleType: 'none' }}
																	ref={adminDropdownRef}
																>
																	<span
																		className={`fs-7 text-uppercase fw-bold dropdown-toggle lh-1 ${currentPage === 'administration' ? 'text-primary' : ''}`}
																		type="button"
																		id="dropdownMenuButton"
																		onClick={handleAdminDropdownToggle}
																		aria-haspopup="true"
																		aria-expanded={adminDropdownOpen}
																	>
																		{t('228', 'Administration')}
																	</span>
																	<div
																		className={`dropdown-menu custom-dropdown-menu${adminDropdownOpen ? ' show' : ''}`}
																		aria-labelledby="dropdownMenuButton"
																	>
																		<Link
																			className="dropdown-item custom-dropdown-item fs-6"
																			to="/portal/commodities-database"
																			onClick={handleDropdownClose}
																		>
																			{t('229', 'Commodities Database')}
																		</Link>
																		<Link
																			className="dropdown-item custom-dropdown-item fs-6"
																			to="/portal/catalog"
																			onClick={handleDropdownClose}
																		>
																			{t('-1', 'Catalogs Management')}
																		</Link>
																		<Link
																			className="dropdown-item custom-dropdown-item fs-6"
																			to="/portal/user-management"
																			onClick={handleDropdownClose}
																		>
																			{t('232', 'Users Management')}
																		</Link>

																		<Link
																			className="dropdown-item custom-dropdown-item fs-6"
																			to="/portal/style-manager"
																			onClick={handleDropdownClose}
																		>
																			{t('233', 'Style Manager')}
																		</Link>
																		<Link
																			className="dropdown-item custom-dropdown-item fs-6"
																			to="/portal/pdf-management"
																			onClick={handleDropdownClose}
																		>
																			{t('234', 'Full Book PDF Management')}
																		</Link>
																		<Link
																			className="dropdown-item custom-dropdown-item fs-6"
																			to="/portal/pdf-header-footer"
																			onClick={handleDropdownClose}
																		>
																			{t('235', 'PDF Header/Footer Configuration')}
																		</Link>
																	</div>
																</li>
															)
														}
														{
															(authData && authData.role_id === 4) && (
																<li className="nav-item" style={{ listStyleType: "none" }}>
																	<Link
																		className={`nav-link py-0 transition-hover-all-0  text-hover-primary ${currentPage == "data-migration" ? "text-primary" : ""
																			}`}
																		to="/portal/data-migrations"
																	>
																		<span className="fs-7 text-uppercase fw-bold  lh-1">
																			{t("4", "Data Migration")}
																		</span>
																	</Link>
																</li>
															)
														}
														{
															(authData && authData.role_id === 4) && (
																<>
																	{/* <li
																		className="nav-item dropdown mx-2 px-3"
																		style={{ marginTop: '12px', listStyleType: 'none' }}
																		ref={toolsDropdownRef}
																	>
																		<span
																			className={`fs-7 text-uppercase fw-bold dropdown-toggle lh-1 ${currentPage === 'update-publish' ? 'text-primary' : ''}`}
																			type="button"
																			id="dropdownMenuButton"
																			onClick={handleToolsDropdownToggle}
																			aria-haspopup="true"
																			aria-expanded={toolsDropdownOpen}
																		>
																			{t('5', 'Tools')}
																		</span>
																		<div
																			className={`dropdown-menu custom-dropdown-menu${toolsDropdownOpen ? ' show' : ''}`}
																			aria-labelledby="dropdownMenuButton"
																		>
																			<Link
																				className="dropdown-item custom-dropdown-item fs-6"
																				to="/portal/style-classes"
																				onClick={handleDropdownClose}
																			>
																				{t("222", "Tanveer Tool")}
																			</Link>
																			<Link
																				className="dropdown-item custom-dropdown-item fs-6"
																				to="/portal/style-lists"
																				onClick={handleDropdownClose}
																			>
																				{t("212", "Editing Tool")}
																			</Link>
																			<Link
																				className="dropdown-item custom-dropdown-item fs-6"
																				to="/portal/linking"
																				onClick={handleDropdownClose}
																			>
																				{t('213', 'Linking Cleaning')}
																			</Link>
																		</div>
																	</li> */}
																	<li className="nav-item" style={{ listStyleType: "none" }}>
																		<Link
																			className={`nav-link py-0 transition-hover-all-0  text-hover-primary ${currentPage == "editor" ? "text-primary" : ""
																				}`}
																			to="/portal/editor"
																		>
																			<span className="fs-7 text-uppercase fw-bold  lh-1">
																				{t("6", "Editor")}
																			</span>
																		</Link>
																	</li>
																</>
															)
														}
													</ul>
												</div>
											</div>
										</nav>
									)
								}
							</div>
							<div id="headerEndMarker"></div>
						</section>
					</>
				) : (
					<section
						className={`bob sticky-top bg-${headerTheme}`}
						style={{
							top: `-${scrollToTopOffset >= 200 ? getHeaderHeight() : 0}px`,
						}}
					>
						<div className={`container`}>
							{/* <div
								id="lang-group"
								className={`d-flex border-bottom border-dark bg-${headerTheme}`}
							>
								<div className="list-group list-group-horizontal ms-auto">
									<span className="vertical-line"></span>
									<span
										className={`bg-${headerTheme} cursor-pointer list-group-item text-hover-primary border-0 text-center font-custom ${siteLanguage == `${LIST_DETAIL_ELEMENTS.languages.english}` ? 'text-decoration-underline' : ''}`}
										onClick={() => changeSiteLanguage(`${LIST_DETAIL_ELEMENTS.languages.english}`)}
									>
										EN
									</span>
									<span
										className={`bg-${headerTheme} cursor-pointer list-group-item text-hover-primary border-0 text-center font-custom ${siteLanguage == `${LIST_DETAIL_ELEMENTS.languages.french}` ? 'text-decoration-underline' : ''}`}
										onClick={() => changeSiteLanguage(`${LIST_DETAIL_ELEMENTS.languages.french}`)}
									>
										FR
									</span>
									<span
										className={`bg-${headerTheme} cursor-pointer list-group-item text-hover-primary border-0 text-center  font-custom ${siteLanguage == `${LIST_DETAIL_ELEMENTS.languages.spanish}` ? 'text-decoration-underline' : ''}`}
										onClick={() => changeSiteLanguage(`${LIST_DETAIL_ELEMENTS.languages.spanish}`)}
									>
										ES
									</span>
								</div>
							</div> */}
							<nav className="navbar navbar-expand-xl navbar-light py-3 border-bottom border-dark">
								<a
									className="navbar-brand"
									id="logo-brand"
									href="https://www.woah.org/"
									target="_blank"
								>
									<img src={logo} height="50px" />
								</a>
								<div
									style={{ height: "4rem" }}
									className="border border-right-0 border-top-0 border-bottom-0 border-dark ps-3 d-flex align-items-center"
								>
									<Link
										className="navbar-brand"
										id="logo-brand-pvs"
										to="/"
									>
										<h2 className=" pvs-title fw-bold align-middle text-uppercase fw-bold my-0">
											{t("214", "Codes & Manuals - Back Office")}
										</h2>
									</Link>
								</div>
								<div className="flex-grow-1 d-flex flex-column">
									<button
										className="navbar-toggler ms-auto"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#navbarSupportedContent"
										aria-controls="navbarSupportedContent"
										aria-expanded="false"
										aria-label="Toggle navigation"
									>
										<span className="navbar-toggler-icon" />
									</button>
									<div
										className="collapse navbar-collapse"
										id="navbarSupportedContent"
									>
										<ul className="navbar-nav ms-auto my-4 mt-2">
											<li style={{ listStyleType: "none" }} className="nav-item border-1 border-start border-dark">
												<Link
													className={`nav-link px-5 py-0 transition-hover-all-0 text-hover-primary ${currentPage == "home" ? "text-primary" : ""
														}`}
													to="/"
												>
													<span className="card-title1 text-uppercase lh-1">
														{t("-1", "Home")}
													</span>
												</Link>
											</li>
											<li style={{ listStyleType: "none" }} className="nav-item border-1 border-start border-dark me-0 pe-0">
												<Link
													className={`nav-link ps-5 py-0 transition-hover-all-0 me-0 pe-0 text-hover-primary ${currentPage == "about" ? "text-primary" : ""
														}`}
													to="/about"
												>
													<span className="card-title1 text-uppercase lh-1 ">
														{t("-1", "About")}
													</span>
												</Link>
											</li>
										</ul>
									</div>
									<div className="ms-auto">
										<Link to={"/login"}>
											<button className="btn1 btn-dark fs-7">{t("-1", "LOGIN")}</button>
										</Link>
									</div>

								</div>
							</nav>
						</div>
						<div id="headerEndMarker"></div>
					</section>
				)
			}
		</>
	);
};

export default Header;
