import React, { useState } from 'react';
import { getBaseURL } from '../../../../helpers';
import { sendAPIRequest } from '../../../../helpers';
import { useTranslation } from "react-i18next";
import TextFieldCustom from 'components/TextFieldCustom';
import { BASE_URL } from 'constants';
import SuccessModal from 'components/modals/SuccessModal';

const ForgotPassword = () => {

    const { t } = useTranslation();

    const [isWorking, setIsWorking] = useState(false);
    const [email, setEmail] = useState('');
    const [isSendEmail, setIsSendEmail] = useState(false);



    const forgetPassword = async () => {
        setIsWorking(true);
        try {
            const ret = await sendAPIRequest(
                `/api/auth/profile/forget-password`,
                'POST',
                {
                    base_url: BASE_URL,
                    email,
                }
            );
            const res = await ret.json();
            if (res.success) {
                setIsSendEmail(true);
            } else {
                setIsSendEmail(false);
            }
        } catch (e) {
            console.log({ e });
        }
        setIsWorking(false);
    }

    return (
        <div className='py-20 ' style={{
            backgroundImage: `url(${getBaseURL("/assets/icons/forgot-pw.jpg")})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: "center",
        }}>
            {
                isSendEmail && (
                    <SuccessModal
                        reloadData={(params) => setIsSendEmail(false)}
                        children={handleOpen => handleOpen()}
                        header={"Forgot Password Request"}
                        title={"Email Sent"}
                        body={[<div key={1} className="alert alert-danger" role="alert">
                           A link for reseting password has been sent to your email.
                           Please check your email.</div>]}
                        navigationLink={'/login'}
                    />
                )
            }
            <div className='container py-20 min-vh-50'
            >
                <div className="row">
                    <div className="col-md-4 offset-md-4 col-sm-6 offset-sm-3 col-12">
                        <div className="card bg-login d-flex">
                            <div className="card-body bg-white border shadow shadow-lg rounded  px-17 py-12" style={{
                                backgroundImage: `url(${getBaseURL("/assets/icons/Strips-Orange.png")})`,
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: '200%',
                                backgroundPosition: "-100px -95px",
                            }}>
                                <h1 className="text-center mb-3 text-dark">FORGOT PASSWORD</h1>
                                <div className="form-group row py-12">
                                    <div className="col-12">
                                        <TextFieldCustom
                                            type="email"
                                            label="Email"
                                            variant="outlined"
                                            className='form-control'
                                            fullWidth
                                            size='small'
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="d-flex">
                                    <button
                                        className="btn1 mx-auto"
                                        disabled={isWorking}
                                        onClick={() => forgetPassword()}
                                    >
                                        {isWorking ? t("-1", "Please Wait") + '...' : t("-1", "Send") + " " + t("-1", "Email")}
                                    </button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ForgotPassword;
