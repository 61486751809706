import { GridActionsCellItem } from "@mui/x-data-grid";
import Button from "components/common/Button";
import CardBody from "components/common/CardBody";
import CardHeader from "components/common/CardHeader";
import Container from "components/common/Container";
import CustomCard from "components/common/CustomCard";
import { displayNotification, sendAPIRequest } from "helpers";
import DataTable from "pages/components/DataTable";
import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

const ViewItems = () => {

    const navigate = useNavigate();
    const { id } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const filterName = searchParams.get('filter_name') ?? '';
    const [data, setData] = useState();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        setIsLoading(true);
        try {
            const ret = await sendAPIRequest(`/api/lists/master/${id}`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                setData(res.data);
            }
        } catch (err) {
            console.log(err);
        }
        setIsLoading(false);
    }

    const handleDelete = async (id) => {
        try {
            const ret = await sendAPIRequest(`/api/lists/${id}`, "DELETE");
            const res = await ret.json();
            if (res.success && res.status === 200) {
                displayNotification('success', 'Successfully deleted [B]');
                await loadData();
            } else {
                displayNotification('error', 'Fail to delete [B]');
            }
        } catch (err) {
            console.log(err);
            displayNotification('error', 'Fail to delete [F]');
        }
    }

    return (
        <>
            <Container>
                <CustomCard className={'d-flex'}>
                    <CardHeader>
                        View Filter Items - {filterName}
                    </CardHeader>
                    <CardBody>
                        <Button
                            onClick={() => navigate('/portal/dynamic-filters/')}
                        >
                            Back
                        </Button>
                        <Button
                            onClick={() => navigate('/portal/dynamic-filters/item/create')}
                        >
                            Add Items
                        </Button>
                    </CardBody>
                </CustomCard>
                <CustomCard className={'my-2'}>
                    <DataTable
                        getRowId={(row) => row.id}
                        isLoading={isLoading}
                        rows={data}
                        className="text-white bg-primary rounded-0"
                        columns={
                            [
                                {
                                    field: "list_element_name",
                                    headerName: "Item Name (EN)",
                                    headerClassName: "text-white bg-primary rounded-0",
                                    flex: 1,
                                },
                                {
                                    field: "list_element_name_fr",
                                    headerName: "Item Name (FR)",
                                    headerClassName: "text-white bg-primary rounded-0",
                                    flex: 1,
                                },
                                {
                                    field: "list_element_name_es",
                                    headerName: "Item Name (ES)",
                                    headerClassName: "text-white bg-primary rounded-0",
                                    flex: 1,
                                },
                                {
                                    field: "is_terrestrial",
                                    headerName: "Terrestrial",
                                    headerClassName: "text-white bg-primary rounded-0",
                                    flex: 1,
                                    valueGetter: (params) => {
                                        return params.row?.is_terrestrial ? 'Yes' : 'No';
                                    },
                                },
                                {
                                    field: "is_aquatic",
                                    headerName: "Aquatic",
                                    headerClassName: "text-white bg-primary rounded-0",
                                    flex: 1,
                                    valueGetter: (params) => {
                                        return params.row?.is_aquatic ? 'Yes' : 'No';
                                    },
                                },
                                {
                                    field: "is_code",
                                    headerName: "Code",
                                    headerClassName: "text-white bg-primary rounded-0",
                                    flex: 1,
                                    valueGetter: (params) => {
                                        return params.row?.is_code ? 'Yes' : 'No';
                                    },
                                },
                                {
                                    field: "is_manual",
                                    headerName: "Manual",
                                    headerClassName: "text-white bg-primary rounded-0",
                                    flex: 1,
                                    valueGetter: (params) => {
                                        return params.row?.is_manual ? 'Yes' : 'No';
                                    },
                                },
                                {
                                    field: "show_in_filter",
                                    headerName: "Enable",
                                    headerClassName: "text-white bg-primary rounded-0",
                                    flex: 1,
                                    valueGetter: (params) => {
                                        return params.row?.show_in_filter ? 'Yes' : 'No';
                                    },
                                },
                                {
                                    field: "actions",
                                    type: "actions",
                                    headerClassName: "text-white bg-primary rounded-0",
                                    headerName: "Actions",
                                    getActions: (params) => [
                                        <GridActionsCellItem
                                            label="Edit"
                                            key={1}
                                            showInMenu
                                            onClick={() => navigate(`/portal/dynamic-filters/item/create/${params.row.id}/?filter_name=${filterName}&master_id=${id}`)}
                                        />,
                                        <GridActionsCellItem
                                            label={
                                                "Delete"
                                            }
                                            key={0}
                                            showInMenu
                                            onClick={() => {
                                                handleDelete(params.row.id);
                                            }
                                            }
                                        />,
                                    ]
                                }
                            ]
                        }
                    />
                </CustomCard>
            </Container>
        </>
    );
}

ViewItems.defaultProps = {};

export default ViewItems;