import React, { useMemo, useState } from 'react'
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { setCurrentPage } from 'redux/generalSlice';
import Tooltip from '@mui/material/Tooltip';
import AutoCompleteDropdown from 'components/autocomplete/AutoCompleteDropdown';
import { LIST_DETAIL_ELEMENTS, CONTENT_PDF_DOWNLOAD_API, CONTENT_PDF_GENERATE_API } from 'constants';
import { generateOptionsFromData, sendAPIRequest, displayNotification } from 'helpers';
import { CODE_VOLUMES } from 'constants';
import Editer from '../components/edit/Editer';
import StandardsSelectCard from 'components/common/StandardsSelectCard';
const EditContent = () => {

    const siteLanguage = useSelector(s => s.general.siteLanguageSont);
    const [componentLanguage, setComponentLanguage] = useState('102');
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const headerTheme = useSelector((s) => s.general.headerTheme);
    const [section, setSelectedSection] = useState({
        value: '',
        label: '',
        group_value: ''
    });
    const [chapter, setSelectedChapter] = useState({
        value: '',
        label: ''
    });
    const [sections, setSections] = useState([]);
    const [manualSections, setManualSections] = useState([]);
    const [currentSection, setCurrentSection] = useState([]);
    const [currentChapter, setCurrentChapter] = useState([]);
    const [availableChapters, setAvailableChapters] = useState([]);
    const [availableManualChapters, setAvailableManualChapters] = useState([]);
    const [showScrollButton, setShowScrollButton] = useState(false);
    const [isSectionVisible, setIsSectionVisible] = useState(true);
    const [isSectionSecVisible, setIsSectionSecVisible] = useState(true);
    const [selectedOption, setSelectedOption] = useState('terrestrial-code');
    const [baseFontSize, setBaseFontSize] = useState(16);
    const [isLoading, setIsLoading] = useState({
        loading_filters: false,
        loading_data: false,
    });
    const [data, setData] = useState([]);
    const [documentMetaData, setDocumentMetaData] = useState({
        section_name: '',
        chapter_name: '',
    });
    const currentDate = new Date().toISOString().slice(0, 10);
    const [sectionID, setSectionID] = useState('');
    const [chapterID, setChapterID] = useState('');
    const [selectedRadio, setSelectedRadio] = useState({ animal_type: LIST_DETAIL_ELEMENTS.animal_type.terrestrial, standard: LIST_DETAIL_ELEMENTS.standard_type.codes });

    useEffect(() => {
        dispatch(setCurrentPage('display-navigation'));
        setSelectedOption('terrestrial-code');
        window.scrollTo(0, 0);
        const handleScroll = () => {
            setShowScrollButton(window.scrollY > 100);
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        languageSwitch();
    }, [componentLanguage]);

    useEffect(() => {
        dispatch(setCurrentPage('update-publish'));
    }, []);

    useEffect(() => {
        setAvailableChapters([]);
        setSelectedChapter({ value: '', label: '' });
    }, [selectedOption]);

    useEffect(() => {
        if (section.value) {
            try {
                if (selectedOption === "terrestrial-manual" || selectedOption === 'aquatic-manual') {
                    loadManualChapters()
                }
                else {

                    loadChapters();
                }
            } catch (error) {
                console.error('Error loading chapters:', error);
            }
        }
        else {
            setAvailableChapters([]);
            setSelectedChapter({ value: '', label: '' });
        }
    }, [section.value]);

    useEffect(() => {
        if (selectedOption === "terrestrial-code") {
            loadSections();
        }
        else if (selectedOption === "terrestrial-manual") {
            loadManualSection("terrestrial-manual");
        }
        else if (selectedOption === "aquatic-manual") {
            loadManualSection("aquatic-manual");
        }
        else if (selectedOption === "aquatic-code") {
            loadSections();
        }
    }, [selectedRadio])


    const languageSwitch = async () => {
        const sect = await loadSections();
        const s_id = getSectionID(section.value, sect);
        if (sectionID) {
            const chap = await loadChapters(s_id);
            getChapterID(chapter.value, chap);
        }
    }
    const getSectionID = (ref_number = null, sect = null) => {
        const tempSections = sect ? sect : sections;
        const s_id = tempSections.filter(section => section.ref_number === ref_number)[0]?.id;
        setSectionID(s_id);
        return s_id;
    }
    const getChapterID = (ref_number = null, chap = null) => {
        const chapters = chap ? chap : availableChapters;
        const c_id = chapters.filter(chapter => chapter.ref_number === ref_number)[0]?.id
        setChapterID(c_id);
        return c_id;
    }

    const handleChange = async (event) => {
        switch (event.target.value) {
            case 'terrestrial-code':
                setSelectedRadio({ animal_type: LIST_DETAIL_ELEMENTS.animal_type.terrestrial, standard: LIST_DETAIL_ELEMENTS.standard_type.codes });
                break;
            case 'terrestrial-manual':
                setSelectedRadio({ animal_type: LIST_DETAIL_ELEMENTS.animal_type.terrestrial, standard: LIST_DETAIL_ELEMENTS.standard_type.manuals });
                break;
            case 'aquatic-code':
                setSelectedRadio({ animal_type: LIST_DETAIL_ELEMENTS.animal_type.aquatic, standard: LIST_DETAIL_ELEMENTS.standard_type.codes });
                break;
            case 'aquatic-manual':
                setSelectedRadio({ animal_type: LIST_DETAIL_ELEMENTS.animal_type.aquatic, standard: LIST_DETAIL_ELEMENTS.standard_type.manuals });
                break;
            default:
                return '';
        }
        setSelectedOption(event.target.value);

        if (event.target.value === "terrestrial-manual") {
            setSelectedSection({
                value: "",
                label: ''
            });
            await loadManualSection(event.target.value)
        }

        if (event.target.value === "aquatic-manual") {
            setSelectedSection({
                value: "",
                label: ''
            });
            await loadManualSection(event.target.value)
        }

        if (event.target.value === "terrestrial-code") {
            setSelectedSection({
                value: "",
                label: ''
            });
            await loadSections()
        }
        if (event.target.value === "aquatic-code") {
            setSelectedSection({
                value: "",
                label: ''
            });
            await loadSections()
        }
    };

    const getHeaderText = () => {
        switch (selectedOption) {
            case 'terrestrial-code':
                return 'Terrestrial Animal Health Code';
            case 'terrestrial-manual':
                return 'Manual of Diagnostic Tests and Vaccines for Terrestrial Animals​';
            case 'aquatic-code':
                return 'Aquatic Animal Health Code';
            case 'aquatic-manual':
                return 'Manual of Diagnostic Tests for Aquatic Animals';
            default:
                return '';
        }
    };

    const modifyData = (data, modiferText) => {
        let temp = [];
        data.forEach(d => {
            temp.push({ ...d, standard_content: `${modiferText} ${d.ref_number}: ${d.standard_content}` });
        });
        return temp;
    }

    const loadSections = async () => {
        setIsLoading({ ...isLoading, load_sections: true });
        try {
            const ret = await sendAPIRequest(
                `/api/contents/chapter-specific-type/?text_type=${LIST_DETAIL_ELEMENTS.text_types.section_name}&language_id=${componentLanguage}&animal_type=${selectedRadio.animal_type}&standard_type=${selectedRadio.standard}`
            );
            const res = await ret.json();
            if (res.success && res.status === 200) {
                setSections(modifyData(res.data, 'Section'));
                setIsLoading({ ...isLoading, load_sections: false });
                return res.data;
            }
        } catch (e) {
            console.log({ e });
        }
        setIsLoading({ ...isLoading, load_sections: false });
    }

    const loadManualSection = async (text) => {
        setIsLoading({ ...isLoading, load_sections: true });
        try {

            let animaltype = LIST_DETAIL_ELEMENTS.animal_type.terrestrial

            if (text === "terrestrial-manual") {
                animaltype = LIST_DETAIL_ELEMENTS.animal_type.terrestrial
            }
            if (text === "aquatic-manual") {
                animaltype = LIST_DETAIL_ELEMENTS.animal_type.aquatic
            }

            const ret = await sendAPIRequest(`/api/contents/sections?standard_type=${LIST_DETAIL_ELEMENTS.standard_type.manuals}&animal_type=${animaltype}&lang_id=${componentLanguage}`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                let setIds = res.data.map((ele, i) => {
                    return {
                        area_vol_name: ele.area_vol_name,
                        area_vol_no: ele.area_vol_no,
                        section_name: ele.section_name,
                        section_no: ele.section_no,
                        id: i + 1
                    }
                })
                setManualSections(setIds)
            }
        } catch (e) {
            console.log({ e });
        }
        setIsLoading({ ...isLoading, load_sections: false });
    }

    const getManualVolumes = (data) => {
        let manualVolumes = {};
        data.forEach(d => {

            manualVolumes[d.area_vol_no] = d.area_vol_name;
        });

        return manualVolumes;
    };

    const loadChapters = async (id = null) => {
        setIsLoading({ ...isLoading, load_chapters: true });
        try {
            const ret = await sendAPIRequest(
                `/api/contents/chapter-specific-type/?text_type=${LIST_DETAIL_ELEMENTS.text_types.chapter_name}&language_id=${componentLanguage}&section=${id ? id : sectionID}&animal_type=${selectedRadio.animal_type}&standard_type=${selectedRadio.standard}`
            );
            const res = await ret.json();
            if (res.success && res.status === 200) {
                for (let ele of res.data) {
                    ele.standard_content = removeHtmlTags(ele.standard_content)
                }
                setAvailableChapters(modifyData(res.data, 'Chapter'));
                setIsLoading({ ...isLoading, load_chapters: false });
                return res.data;
            }
        } catch (e) {
            console.log({ e });
        }
        setIsLoading({ ...isLoading, load_chapters: false });
    }

    const loadManualChapters = async () => {
        setIsLoading({ ...isLoading, load_chapters: true });
        try {
            let getSectionValue = manualSections.find((r) => r.id === section.value)
            let animal_type = selectedOption === "terrestrial-manual" ? LIST_DETAIL_ELEMENTS.animal_type.terrestrial : LIST_DETAIL_ELEMENTS.animal_type.aquatic
            const ret = await sendAPIRequest(`/api/structure/load-chapter?standard_type=${LIST_DETAIL_ELEMENTS.standard_type.manuals}&lang_id=${componentLanguage}&animal_type=${animal_type}`, "POST", {
                label: getSectionValue?.section_name,
                value: getSectionValue?.section_no,
                vol: getSectionValue?.area_vol_no
            });
            const res = await ret.json();
            if (res.success && res.status === 200) {
                setAvailableManualChapters(res.data);
            }
        } catch (e) {
            console.log({ e });
        }
        setIsLoading({ ...isLoading, load_chapters: false });
    }

    const sectionOptions = useMemo(() => {
        return generateOptionsFromData(sections, {
            value: "ref_number",
            label: "standard_content",
            group_value: 'parent_id'
        },
            false,
            true,
            CODE_VOLUMES
        );
    }, [sections]);

    const manualSectionOptions = useMemo(() => {
        return generateOptionsFromData(
            manualSections,
            {
                value: "id",
                label: "section_name",
                group_value: "area_vol_no",
            },
            false,
            true,
            getManualVolumes(manualSections)
        );
    }, [manualSections]);

    const manualChapterOptions = useMemo(() => {
        return generateOptionsFromData(availableManualChapters, {
            value: "chapter_no",
            label: "chapter_name",
        });
    }, [availableManualChapters]);


    const chapterOptions = useMemo(() => {
        return generateOptionsFromData(availableChapters, {
            value: "ref_number",
            label: "standard_content",
        });
    }, [availableChapters]);

    const changeComponentLanguage = async (lang) => {
        setComponentLanguage(lang);
    }

    const navigateTo = () => {
        navigate(`/portal/advance-search`);
    }

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    const scrollToTopButtonStyle = {
        position: 'fixed',
        bottom: '460px',
        right: '40px',
        zIndex: 1000,
        fontSize: '2rem'
    };

    const increaseFontSize = () => {
        if (baseFontSize < 24) {
            setBaseFontSize(prevSize => prevSize + 2);
        }
    };

    const decreaseFontSize = () => {
        if (baseFontSize > 12) {
            setBaseFontSize(prevSize => prevSize - 2);
        }
    };

    const manualPdfGeneration = async () => {
        try {
            displayNotification('info', 'Generating PDF please wait...');
            const fileName = `WOAH_Report_${currentDate}.pdf`;
            // for local use only
            const ret = await sendAPIRequest(
                `/api/content-navigation-pdf/manual`,
                'POST',
                {
                    chapter
                })

        } catch (error) {
            console.error('Error sending data:', error);
        }
    };

    const sendDataToBackendDownload = async () => {
        try {
            displayNotification('info', 'Generating PDF please wait...');
            const fileName = `WOAH_Report_${currentDate}.pdf`;

            // for live use only
            // const ret = await fetch(`${CONTENT_PDF_GENERATE_API}`, {
            //     method: 'POST',
            //     headers: {
            //         'Content-Type': 'application/json',
            //     },
            //     body: JSON.stringify({
            //         chapter: chapterID,

            //     })
            // });

            // // for local use only
            const ret = await sendAPIRequest(
                `/api/content-navigation-pdf/`,
                'POST',
                {
                    chapter: chapterID,
                });

            const data = await ret.json();

            if (data.success && data.status === 200) {
                // For local enviornment
                const downloadResponse = await sendAPIRequest(`/api/content-navigation-pdf/download?file_name=${fileName}`);
                // For live enviornment only
                // const downloadResponse = await fetch(`${CONTENT_PDF_DOWNLOAD_API}?file_name=${fileName}`, {
                //     method: 'GET',
                //     headers: {
                //         'Content-Type': 'application/json',
                //     },
                // });
                if (downloadResponse.ok) {
                    const blob = await downloadResponse.blob();
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = fileName ?? 'testing.pdf';
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                    window.URL.revokeObjectURL(url);
                    displayNotification('success', 'Successfully downloaded file');
                } else {
                    displayNotification('error', 'Error downloading file');
                }
            } else {
                displayNotification('error', 'Error creating PDF');
            }
        } catch (error) {
            console.error('Error sending data:', error);
        }
    };

    const sendDataToBackendShare = async () => {
        try {
            displayNotification('info', 'Generating Shareable PDF link please wait...');
            const fileName = `WOAH_Report_${currentDate}.pdf`;

            const generateResponse = await fetch(`${CONTENT_PDF_GENERATE_API}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    chapter: chapterID,
                })
            });

            const generateData = await generateResponse.json();

            if (generateData.success && generateData.status === 200) {
                // Download PDF
                const downloadResponse = await fetch(`${CONTENT_PDF_DOWNLOAD_API}?fileName=${fileName}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                if (downloadResponse.ok && downloadResponse.status === 200) {
                    const pdfUrl = downloadResponse.url;
                    const shareableLink = pdfUrl;
                    window.open(`mailto:?subject=PDF Report&body=Here is the link to the PDF report: ${shareableLink}`);

                    displayNotification('success', 'Successfully generated shareable link');
                } else {

                    displayNotification('error', 'Error downloading file');
                }
            } else {

                displayNotification('error', 'Error creating PDF');
            }
        } catch (error) {
            console.error('Error sending data:', error);
        }
    };

    const handlePrint = () => {
        if (chapterID) {
            const file_name = `WOAH_Report_List_${currentDate}.pdf`;
            const url = `/portal/content-navigation/print?chapterId=${chapterID}`;
            window.open(url, "self");
        } else {
            displayNotification('warning', `${t("-1", "No content available for print")}`);
        }
    };

    const toggleSectionVisibility = () => {
        setIsSectionVisible(!isSectionVisible);
    };
    const toggleSecSectionVisibility = () => {
        setIsSectionSecVisible(!isSectionSecVisible);
    };

    function removeHtmlTags(input) {
        return input.replace(/<[^>]+>/g, '');
    }

    return (
        <>
            <div className="container my-2">
                <div className="header card-woah border border-secondary d-flex px-4 py-2 shadow-sm w-100 my-2">
                    <div className="card-title1 lh-1" >
                        {t("-1", "Add/Update Content")}
                    </div>
                    <div className="header-right-items ms-auto d-flex align-items-center">
                        <div className="ms-auto">
                            <Tooltip title="Expand/Collapse Section" placement="top">
                                <i className={`fas ${isSectionVisible ? 'fa-chevron-down' : 'fa-chevron-up'} text-primary fs-2 ps-2`} onClick={toggleSectionVisibility}></i>
                            </Tooltip>
                        </div>
                    </div>
                </div>
                {isSectionVisible && (
                    <div className="header card-woah border border-secondary px-4 py-2 shadow-sm w-100 my-2">
                        <div className="row py-3">
                            <div className="col-md-12">
                                <StandardsSelectCard>
                                    <FormControl>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                            className="text-center d-flex justify-content-between"
                                            onChange={handleChange}
                                            value={selectedOption}
                                        >
                                            <FormControlLabel
                                                className="px-5 mb-2"
                                                value="terrestrial-code"
                                                control={<Radio />}
                                                label={t("28", "Terrestrial Code​")}
                                            />
                                            <FormControlLabel
                                                className="px-5 mb-2"
                                                value="terrestrial-manual"
                                                control={<Radio />}
                                                label={t("29", "Terrestrial Manual​")}
                                            />
                                            <FormControlLabel
                                                className="px-5 mb-2"
                                                value="aquatic-code"
                                                control={<Radio />}
                                                label={t("30", "Aquatic Code​")}
                                            />
                                            <FormControlLabel
                                                className="px-5 mb-2"
                                                value="aquatic-manual"
                                                control={<Radio />}
                                                label={t("31", "Aquatic Manual​")}
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </StandardsSelectCard>
                            </div>
                        </div>
                    </div>
                )}
                <div className="header card-woah border border-secondary px-4 shadow-sm w-100 my-2">
                    <div className="list-group list-group-horizontal ms-auto">
                        <div className="d-flex justify-content-between align-items-center w-100">
                            <div className="d-flex">
                                <div className="card-title1 lh-1 py-3">{getHeaderText()}</div>
                            </div>
                            <Tooltip title={t("-1", "Expand/Collapse Section​")} placement="top">
                                <i className={`fas ${isSectionSecVisible ? 'fa-chevron-down' : 'fa-chevron-up'} text-primary fs-2 ps-2`} onClick={toggleSecSectionVisibility}></i>
                            </Tooltip>
                        </div>
                    </div>
                    {isSectionSecVisible && (
                        <div className="row d-flex">
                            <div className="col-md-6">
                                <Box sx={{ minWidth: 120 }}>
                                    <AutoCompleteDropdown
                                        className={isLoading.load_sections ? "opacity-50" : "opacity-100"}
                                        disabled={isLoading.load_sections}
                                        isGroup={true}
                                        label={(selectedOption === 'terrestrial-code' || selectedOption === 'aquatic-code') ? "Area/Section" : "Part/Section"}
                                        showClearIcon={true}
                                        error={false}
                                        required={true}
                                        multiple={false}
                                        hasselectall={false}
                                        options={selectedOption === 'terrestrial-manual' || selectedOption === 'aquatic-manual' ? manualSectionOptions : sectionOptions}
                                        onChange={(event, value) => {
                                            if (selectedOption === 'terrestrial-manual' || selectedOption === 'aquatic-manual') {
                                                setSelectedSection({
                                                    value: value?.value,
                                                    label: value?.label,
                                                    group_value: value?.group_value
                                                });
                                                setSelectedChapter({ value: '', label: '' });
                                                setChapterID("");
                                                setCurrentSection(manualSectionOptions.find(section => section.value === value?.value));
                                            }
                                            else {
                                                setSelectedSection({
                                                    value: value?.value,
                                                    label: value?.label,
                                                });
                                                setSelectedChapter({ value: '', label: '' });
                                                setChapterID("");
                                                getSectionID(value?.value);
                                                setCurrentSection(sections.filter(section => section.ref_number === value?.value)[0]);
                                            }
                                        }
                                        }
                                        value={
                                            selectedOption === "terrestrial-manual" || selectedOption === 'aquatic-manual' ?
                                                !section.value ? null : manualSectionOptions.find(r => r.value === section?.value)
                                                : !section?.value
                                                    ? null
                                                    : sectionOptions.find(r => r.value == section?.value)
                                        }
                                        size='small'
                                    />
                                </Box>
                            </div>
                            <div className="col-md-6">
                                {section !== 'glossary' && section !== 'foreward' && section !== 'userguide'
                                    && (
                                        <Box sx={{ minWidth: 120 }}>
                                            <AutoCompleteDropdown
                                                className={isLoading.loading_filters ? 'opacity-50' : 'opacity-100'}
                                                disabled={isLoading.loading_filters}
                                                label={isLoading.loading_filters ? 'loading...' : t("43", "Chapter")}
                                                showClearIcon={true}
                                                error={false}
                                                required={false}
                                                multiple={false}
                                                hasselectall={false}
                                                options={selectedOption === 'terrestrial-manual' || selectedOption === 'aquatic-manual' ? manualChapterOptions : chapterOptions}
                                                onChange={(event, value) => {
                                                    if (selectedOption === 'terrestrial-manual' || selectedOption === 'aquatic-manual') {
                                                        setSelectedChapter({
                                                            value: value?.value,
                                                            label: value?.label,
                                                        });
                                                        setCurrentChapter(manualChapterOptions.find(chapter => chapter.value === value?.value && chapter.label === value.label));
                                                    } else {
                                                        setSelectedChapter({
                                                            value: value?.value,
                                                            label: value?.label,
                                                        });
                                                        getChapterID(value?.value);
                                                        setCurrentChapter(availableChapters.filter(chapter => chapter.ref_number === value?.value)[0]);
                                                    }
                                                }
                                                }
                                                value={
                                                    selectedOption === "terrestrial-manual" || selectedOption === 'aquatic-manual'
                                                        ? (manualChapterOptions.find(r => r.value === chapter?.value) ?? null)
                                                        : (!chapter?.value ? null : chapterOptions.find(r => r.value == chapter?.value) ?? { value: '', label: '' })
                                                }
                                                size='small'
                                            />
                                        </Box>
                                    )}
                            </div>
                        </div>
                    )}
                </div>
                <div className="header-res header card-woah border border-secondary mt-2 d-flex px-4 py-2 shadow-sm w-100">
                    <div className="d-flex justify-content-between">
                        <div className="card-title2 lh-1 text-uppercase">
                            {
                                selectedOption === "terrestrial-manual" ? `${chapter.value} ${chapter.label}`
                                    :
                                    chapter.label
                                        ? `${chapter.label}`
                                        : null
                            }
                        </div>
                    </div>
                    <div className="header-right-items ms-auto d-flex align-items-center">
                        <div className="ms-auto">
                            <div className="list-group list-group-horizontal ms-auto">

                                <span
                                    className={`bg-${headerTheme} cursor-pointer dropdown list-group-item text-hover-primary border-0 text-center font-custom ${componentLanguage == `${LIST_DETAIL_ELEMENTS.languages.english}` ? "text-decoration-underline" : ""}`}
                                    type="button"
                                    onClick={() => (changeComponentLanguage(`${LIST_DETAIL_ELEMENTS.languages.english}`))}
                                >
                                    EN
                                </span>
                                <span
                                    className={`bg-${headerTheme} cursor-pointer dropdown list-group-item text-hover-primary border-0 text-center font-custom ${componentLanguage == `${LIST_DETAIL_ELEMENTS.languages.french}` ? "text-decoration-underline" : ""}`}
                                    type="button"
                                    onClick={() => (changeComponentLanguage(`${LIST_DETAIL_ELEMENTS.languages.french}`))}
                                >
                                    FR
                                </span>
                                <span
                                    className={`bg-${headerTheme} cursor-pointer dropdown list-group-item text-hover-primary border-0 text-center font-custom ${componentLanguage == `${LIST_DETAIL_ELEMENTS.languages.spanish}` ? "text-decoration-underline" : ""}`}
                                    type="button"
                                    onClick={() => (changeComponentLanguage(`${LIST_DETAIL_ELEMENTS.languages.spanish}`))}
                                >
                                    ES
                                </span>
                                <div className="d-flex border-start border-dark" style={{ height: "25px" }}>
                                    <span className="fs-1 cursor-pointer mt-auto ms-3"
                                        onClick={increaseFontSize}
                                    >
                                        A
                                    </span>
                                    <span className="fs-5 cursor-pointer mt-auto ms-3"
                                        onClick={decreaseFontSize}

                                        style={{ marginBottom: -3 }}>
                                        A
                                    </span>
                                    <div className="d-flex ms-3 border-start border-dark">
                                        <span
                                            className={`bg-${headerTheme} cursor-pointer list-group-item text-hover-primary border-0 text-center font-custom`}
                                        >
                                            <Tooltip title="Download Chapter" placement="top">
                                                <i className="fas fa-file-pdf text-primary fs-2 " onClick={sendDataToBackendDownload}></i>
                                            </Tooltip>
                                        </span>
                                        <span
                                            className={`bg-${headerTheme} cursor-pointer list-group-item text-hover-primary border-0 text-center font-custom ms-2`}
                                        >
                                            <Tooltip title="Print Chapter" placement="top">
                                                <i
                                                    className="fas fa-print text-primary fs-2 " onClick={handlePrint}
                                                >
                                                </i>
                                            </Tooltip>
                                        </span>
                                        <span
                                            className={`bg-${headerTheme} cursor-pointer list-group-item text-hover-primary border-0 text-center font-custom ms-2`}
                                            onClick={() => {
                                                sendDataToBackendShare();
                                            }}
                                        >
                                            <Tooltip title="Share Chapter" placement="top">
                                                <i
                                                    className="fas fa-envelope text-primary fs-2 "
                                                >
                                                </i>
                                            </Tooltip>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    ((chapterID && selectedOption === "terrestrial-code") || (chapterID && selectedOption === "aquatic-code")) && (
                        <Editer
                            chapter={chapterID}
                            section={section}
                            fontSize={baseFontSize}
                        />
                    )}
                {((chapter.value && selectedOption === "terrestrial-manual") || (chapter.value && selectedOption === 'aquatic-manual')) && (
                    <Editer
                        chapter={chapter.value}
                        section={section}
                        fontSize={baseFontSize}
                        selectedOption={selectedOption}
                        dat={chapter}
                    />
                )}
                {showScrollButton && (
                    <i
                        className="fa-solid fa-angles-up text-primary custom-scroll-top-button cursor-pointer"
                        onClick={scrollToTop}
                        style={scrollToTopButtonStyle}
                    >
                    </i>
                )}
            </div>
        </>
    )
}

export default EditContent

