import { Add, CheckCircle, Launch, Replay, Visibility } from '@mui/icons-material';
import { Box, CircularProgress } from '@mui/material';
import Button from 'components/common/Button';
import Container from 'components/common/Container';
import CustomCard from 'components/common/CustomCard';
import Loader from 'components/common/Loader';
import CustomChip from 'components/CustomChip';
import ViewLogsModal from 'components/modals/ViewLogsModal';
import { LIST_DETAIL_ELEMENTS } from 'constants';
import { displayNotification, getBaseURL, sendAPIRequest } from 'helpers';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setCurrentPage } from 'redux/generalSlice';
import StandardCardComponent from './Components/StandardCardComponent';
import { ACTIVE_PROCESS_STATUSES } from 'constants';


const PublishingStandards = () => {
    const { t } = useTranslation();
    const authData = useSelector(s => s.auth.data);
    const [publisherPermission, setPublisherPermission] = useState();
    const [isPublishDisabled, setIsPublishDisabled] = useState(false);
    const [roleId, setRoleId] = useState();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [standardData, setStandardData] = useState([]);
    const [isLoading, setIsLoading] = useState({
        load_standard: false,
    });
    const [isEnabled, setIsEnabled] = useState(false);
    const [isMigrating, setIsMigrating] = useState(false);
    const [editingPermission, setEditingPermission] = useState({
        TC: false,
        TM: false,
        AC: false,
        AM: false,
    });
    const [isActiveProcess, setIsActiveProcess] = useState(false);
    useEffect(() => {
        dispatch(setCurrentPage('publishing-standards'));
        setPublisherPermission(authData?.is_publisher);
        setRoleId(authData?.role_id);
    }, []);

    useEffect(() => {
        getPublishingStandards();
        editingCompletePermission()
        const interval = setInterval(() => {
            getPublishingStandards();
            editingCompletePermission()

        }, 60 * 1000);
        return () => clearInterval(interval);
    }, []);

    const getPublishingStandards = async () => {
        setIsLoading({ ...isLoading, load_standard: true });
        try {
            const ret = await sendAPIRequest(`/api/structure/standard-types`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                setStandardData(res.data);
                if(res.data.length > 0 && res.data.some((i)=>i.structure_status === 5 && i.structure_status <= 5 ) ) {
                    finalPublishingPermission();
                }
                setIsActiveProcess(checIfAnyProcessActive(res.data));
                await findStatus(res.data)
            } else {
                displayNotification("error", "Failed to get Publishing Standards");
            }
            setIsLoading({ ...isLoading, load_standard: false });
        } catch (e) {
            console.log({ e });
        }
    };

    const draftmigrationCatalogs = async () => {
        setIsMigrating(true);
        if (!window.confirm('Are you sure you want to migrate Catalogs?')) {
            return;
        }
        try {
            const ret = await sendAPIRequest(`/api/handle-drafting/catalog-migration`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                setStandardData([]);
                await getPublishingStandards();
            } else {
                displayNotification('error', res.message);
            }
        } catch (error) {
            console.log({ error });
            displayNotification("error", "Internall Server Error [F]")
        } finally {
            setIsMigrating(false);
        }
    }

    const areAllStatusesValidated = () => {
        return standardData.every((item) =>
            item?.english_status === 'Validated' &&
            item?.french_status === 'Validated' &&
            item?.spanish_status === 'Validated'
        );
    };
    const prePublishCatalogs = async () => {
        if (!window.confirm('Are you sure you want to Pre-Publish Catalogue?')) {
            return;
        }
        try {
            const ret = await sendAPIRequest(`/api/handle-drafting/catalog-migration-with-year`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                setStandardData([]);
                await getPublishingStandards();
                displayNotification("success", "Catalogs published successfully.");
                setIsEnabled(true);
            } else {
                displayNotification('error', res.message);
            }
        } catch (error) {
            console.log({ error });
            displayNotification("error", "Internal Server Error");
        }
    };

    const handleClickStandard = async (standard_detail = {}) => {
        try {
            if (standard_detail.structure_status === 1) {
                await handlemigrate(standard_detail.id);
            } else if (standard_detail.structure_status === 2) {
                displayNotification("warning", "Migration is in progress");
            } else {
                navigate(`/portal/publishing-standards-details?id=${standard_detail?.id}`);
            }
        } catch (error) {
            console.log({ error });
        }
    }


    const handlemigrate = async (id = '') => {
        if (!window.confirm("Do You Want To Migrate Standard")) {
            return;
        }
        try {
            const ret = await sendAPIRequest(`/api/handle-drafting/`, "POST", {
                standard_type_id: id,
            });
            const res = await ret.json();
            if (res.success && res.status === 200) {
                displayNotification("success", "Migration initiated successfully.");
                setStandardData([]);
                await getPublishingStandards();
            }
        } catch (e) {
            console.log({ e });
        }
    };

    const finalPublishing = async () => {
        if (!window.confirm('Are you sure you want to publish?')) {
            return;
        }
        setIsLoading(true);
        try {
            const ret = await sendAPIRequest(`/api/handle-drafting/final-publication`, 'POST');
            const res = await ret.json();
            if (res.success && res.status === 200) {
                setIsPublishDisabled(false);
                getPublishingStandards();
                editingCompletePermission()
                displayNotification('success', 'Process created will finished soon')
            } else {
                displayNotification('error', res.message);
            }
        } catch (e) {
            console.log({ e });
        } finally {
            setIsLoading(false);
        }
    }

    const handleRevertStandard = async (standard_type_id) => {
        if (!window.confirm('Are you sure you want to revert this standard to draft?')) {
            return;
        }
        try {
            const ret = await sendAPIRequest(`/api/handle-drafting/revert-standard-to-draft/`, 'POST', {
                standard_type_id
            });
            const res = await ret.json();
            if (res.success && res.status === 200) {
                await getPublishingStandards();
            }
        } catch (e) {
            console.log(e);
            displayNotification('error', 'Failed to revert the standard to draft');
        }
    }

    const findStatus = async (data) => {
        try {
            const ret = await sendAPIRequest(`/api/doc-transfer/get-status-permission-draft/`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                const tem = modifyStandardData(res.data, data)
                if (tem.length > 0) {
                    setStandardData(tem);
                }
            }
        } catch (e) {
            console.log(e);
            displayNotification('error', 'Failed to revert the standard to draft');
        }
    }

    const modifyStandardData = (data = [], standrdData = []) => {
        const returnData = standrdData?.map((x) => {
            const y = data.find((z) => z.id === x.id);
            return {
                ...x,
                status_data: y
            }
        })
        return returnData;
    }

    const editingCompletePermission = async (id = '') => {
        try {
            const ret = await sendAPIRequest(`/api/doc-transfer/editing-complete-permission/`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                setEditingPermission(res.data);
            }
        } catch (e) {
            console.log(e);
            displayNotification('error', 'Failed to load Permission for Editing complete');
        }
    }
    const finalPublishingPermission = async () => {
        try {
            const ret = await sendAPIRequest(`/api/doc-transfer/final-pubish-permission/`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                setIsPublishDisabled(res.data);
            }
        } catch (e) {
            console.log(e);
            displayNotification('error', 'Failed to load Permission for Editing complete');
        }
    }

    const returnShortName = (standard_type = '', animal_type) => {
        try {
            let stan = '';
            let anima = ''
            if (standard_type === LIST_DETAIL_ELEMENTS.standard_type.codes) {
                stan = "C"
            } else {
                stan = "M"
            }
            if (animal_type === LIST_DETAIL_ELEMENTS.animal_type.terrestrial) {
                anima = "T"
            } else {
                anima = "A"
            }
            return anima + stan;
        } catch (error) {
            console.log({ error });
            throw error;
        }
    }



    const checIfAnyProcessActive = (data = []) => {
        try {
            return data.some((i) => ACTIVE_PROCESS_STATUSES.includes(i.structure_status));
        } catch (error) {
            console.log({ error });
        }
    }

    return (
        <>
            <Container>
                <div className="card card-woah w-100 border shadow-sm rounded-0 px-4 py-2 my-2">
                    <div className="row g-0">
                        <div className="col-md-4">
                            <div className="card-title1 lh-1 ">
                                {t("-1", "Global Publication Management")}
                            </div>
                        </div>
                        <div className="col-md-8 d-flex justify-content-end align-items-center">
                            {![undefined, null, 0].includes(standardData[0]?.catalog_status) ? (
                                <button
                                    className="btn btn-primary font-custom fs-6 ms-1"
                                    onClick={() => window.open(`/portal/catalog/?is_draft=true&status=${standardData[0]?.catalog_status}`, '_blank')}
                                    disabled={standardData[0]?.catalog_status !== 1}
                                >
                                    {t("-1", "Modify Catalogue")}
                                </button>
                            ) : (
                                <button
                                    className="btn btn-primary font-custom fs-6 ms-1"
                                    onClick={async () => draftmigrationCatalogs()}
                                    disabled={![undefined, null, 0].includes(standardData[0]?.catalog_status)}
                                >
                                    {t("-1", "Migrate Catalogue")}
                                </button>
                            )}
                            {![undefined, null, 0].includes(standardData[0]?.catalog_status) && (
                                <button
                                    className="btn btn--primary font-custom fs-6 ms-1"
                                    onClick={async () => prePublishCatalogs()}
                                    disabled={standardData[0]?.catalog_status !== 1}
                                >
                                    {t("-1", "Close Catalogue")}
                                </button>
                            )}
                        </div>

                        {/* <div className="col-md-8 d-flex justify-content-end align-items-center">
                            {![undefined, null, 0].includes(standardData[0]?.catalog_status) ? (
                                standardData[0]?.catalog_status === 1 && (
                                    <button
                                        className="btn btn-primary font-custom fs-6 ms-1"
                                        onClick={() => navigate(`/portal/catalog/?is_draft=true&status=${standardData[0]?.catalog_status}`)}
                                    >
                                        {t("-1", "Modify Catalogue")}
                                    </button>
                                )
                            ) : (
                                ![undefined, null, 0].includes(standardData[0]?.catalog_status) || (
                                    <button
                                        className="btn btn-primary font-custom fs-6 ms-1"
                                        onClick={async () => draftmigrationCatalogs()}
                                    >
                                        {t("-1", "Migrate Catalogue")}
                                    </button>
                                )
                            )}
                            {![undefined, null, 0].includes(standardData[0]?.catalog_status) && standardData[0]?.catalog_status === 1 && (
                                <button
                                    className="btn btn--primary font-custom fs-6 ms-1"
                                    onClick={async () => prePublishCatalogs()}
                                >
                                    {t("-1", "Close Catalogue")}
                                </button>
                            )}
                        </div> */}
                    </div>
                </div>
                <CustomCard>
                    <div className="row">
                        {standardData?.map((item) => (
                            <div
                                key={item.id}
                                className="col-md-3 col-sm-6 col-12 mb-4"
                            >
                                <StandardCardComponent
                                    item={item}
                                    roleId={roleId}
                                    publisherPermission={publisherPermission}
                                    isPublishDisabled={isPublishDisabled}
                                    handleClickStandard={handleClickStandard}
                                    handleRevertStandard={handleRevertStandard}
                                    finalPublishing={finalPublishing}
                                    areAllStatusesValidated={areAllStatusesValidated}
                                    getPublishingStandards={getPublishingStandards}
                                    setStandardData={setStandardData}
                                    isActiveProcess={isActiveProcess}
                                    editingPermission={editingPermission[returnShortName(item.standard_type, item.animal_type)]}
                                />
                            </div>
                        ))}
                    </div>
                    <div className="row py-4">
                        <div className="col-md-12 d-flex justify-content-end">
                            {publisherPermission === true && isPublishDisabled ?
                                <button className='btn btn--primary font-custom fs-6 ms-1 '
                                    onClick={() => { finalPublishing() }}
                                // disabled={!areAllStatusesValidated() || isPublishDisabled}
                                >
                                    Publish Standards
                                </button>
                                : null}
                        </div>
                    </div>
                </CustomCard>
            </Container>

        </>
    );
};


export default PublishingStandards;
