import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { setCurrentPage, setHeaderTheme } from '../../../../redux/generalSlice';
import { useTranslation } from "react-i18next";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

const NavigateStandards = () => {

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [age, setAge] = React.useState('');
    // useEffect(() => {
    //     dispatch(setHeaderTheme('white'));
    //     dispatch(setCurrentPage("form"));
    // }, []);



    const handleChange = (event) => {
        setAge(event.target.value);
    };
    return (
        <>
            <div className="container">
                <div className="header card-woah border border-secondary px-4  shadow-sm w-100 ">
                    <div className="card-woah border border-dark px-4 py-3 w-100 my-2 rounded rounded-2 text-center bg-great-light">
                        <FormControl>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                className='text-center'
                            >
                                <FormControlLabel value="terrestrial_animal" control={<Radio />} label="Terrestrial Animal Health Code" />
                                <FormControlLabel value="terrestrial_manula" control={<Radio />} label="Aquatic Animal Health Code" />
                                <FormControlLabel value="aquatic_animal" control={<Radio />} label="Manual of Diagnostic Tests and Vaccines for Terrestrial Animals" />
                                <FormControlLabel value="aquatic_manual" control={<Radio />} label="Manual of Diagnostic Tests for Aquatic Animals" />
                            </RadioGroup>
                        </FormControl>
                    </div>
                    <div className="row py-3">
                        <div className="col-md-4">
                            <Box sx={{ minWidth: 120 }}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label"sx={{background:'#fff'}}>Section/Area</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={age}
                                        label="Age"
                                        onChange={handleChange}
                                    >
                                        <MenuItem value={10}>Glossary</MenuItem>
                                      
                                    </Select>
                                </FormControl>
                            </Box>
                        </div>
                        <div className="col-md-4">
                            <Box sx={{ minWidth: 120 }}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label" sx={{background:'#fff'}}>Chapter</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={age}
                                        label="Age"
                                        onChange={handleChange}
                                    >
                                        <MenuItem value={10}>Chapter 16: Procedure for official recognition</MenuItem>
                                        {/* <MenuItem value={20}>Twenty</MenuItem>
                                        <MenuItem value={30}>Thirty</MenuItem> */}
                                    </Select>
                                </FormControl>
                            </Box>
                        </div>
                        <div className="col-md-4 text-end">
                        <button
                            type='button'
                            className='btn btn-dark btn1 btn-sm'
                        >
                            {t("-1", "Search")}
                        </button>
                        <button
                            type='button'
                            className='btn btn-dark btn3 btn-sm ms-2'
                        >
                            {t("-1", "Advance Search")}
                        </button>
                        </div>
                    </div>
                </div>

                {/* <div className="header card-woah border border-secondary px-4 py-2 shadow-sm w-100 my-2 border-bottom-4 border-left-4">
                    <div className='d-flex justify-content-between w-100'>
                        <h1 className="card-title1 font-custom-2 lh-1">{t("-1", "Code Manual")}</h1>
                    </div>
            </div> */}
            </div>
        </>
    )
}

export default NavigateStandards
