import { FormControl, FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material';
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const ParentChildLinking = ({handleRadioButtonChangeState, linkChildWithParent, index, index2, selectedValue}) => {

    const { t } = useTranslation();

    const [selectedID, setSelectedID] = useState(''); 

    return (
        <React.Fragment>
                <div>
                    <TextField
                        value={selectedID}
                        onChange={(e) => setSelectedID(e.target.value)}
                        id="outlined-basic"
                        label="Enter Child Id"
                        variant="outlined"
                        size="small"
                        type="number"
                        sx={{
                            margin: "5px",
                        }}
                        className="bg-white"
                    />
                    <FormControl>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            sx={{ marginTop: "13px" }}
                            defaultValue={"link"}
                            value={selectedValue}
                            onChange={(e) => handleRadioButtonChangeState(e.target.value)}
                        >
                            <FormControlLabel
                                value="link"
                                control={<Radio style={{ height: "18px" }} />}
                                label="Link"
                            />
                            <FormControlLabel
                                value="un-link"
                                control={<Radio style={{ height: "18px" }} />}
                                label="Unlink"
                            />
                        </RadioGroup>
                    </FormControl>
                    <button
                        className="btn btn--primary mx-2"
                        onClick={() =>
                            linkChildWithParent(index, selectedID, selectedValue)
                        }
                    >
                        {t("-1", "Link")}
                    </button>
                </div>
        </React.Fragment>
    );
};

ParentChildLinking.defaultProps = {
    handleRadioButtonChangeState: () => {},
    linkChildWithParent: () => {}, 
    index: null, 
    index2: null,
    selectedValue: null,
};

export default ParentChildLinking;
