import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';
import { store } from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import i18n from './i18next';
import { setSiteLanguage } from './redux/generalSlice';
import { getAPIURL } from 'helpers';

export const changeSiteLanguage = async (lang=102) => {
  store.dispatch(setSiteLanguage(lang));
  i18n.options.backend.loadPath = getAPIURL(`/api/multilingual/${lang}/133/all`);
  await i18n.loadNamespaces('133');
  i18n.changeLanguage(lang);
}

const root = ReactDOM.createRoot(document.getElementById('root'));
const persistor = persistStore(store);

root.render(
  // <React.StrictMode>
    <HelmetProvider>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <App />
          </PersistGate>
        </Provider>
      </LocalizationProvider>
    </HelmetProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
