import Loader from 'components/common/Loader';
import { LIST_DETAIL_ELEMENTS } from 'constants';
import { displayNotification, getBearerToken, processFile, sendAPIRequest } from 'helpers';
import React, { useEffect, useRef, useState } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from 'react-router-dom';
import usePopupAlert from 'hooks/usePopupAlert';


const UploadChapter = ({ open, handleCloseModal, metaData }) => {

    const { t } = useTranslation();
    const location = useLocation();
    const [isLoading, setIsLoading] = useState({
        loading_data: false,
        saving_data: false,
    });
    const [fileName, setFileName] = useState("");
    const [saveButtonText, setSaveButtonText] = useState('Save');
    const { showPopupAlert } = usePopupAlert();
    const initStructure = {
        animal_type: LIST_DETAIL_ELEMENTS.animal_type.terrestrial,
        standard_type: LIST_DETAIL_ELEMENTS.standard_type.codes,
        // language_id: siteLanguage === reFetch ? siteLanguage : reFetch,
        volume: {},
        section: {},
        chapter: {},
        sub_section: {},
        chapter_id: '',
        file: {
            currentFile: null,
            fileName: ""
        }
    };
    const [selectedStructures, setSelectedStructures] = useState(initStructure);
    const navigate = useNavigate();
    const browseFileRef = useRef();
    const fileUpload = () => {
        setFileName("");
        if (browseFileRef) {
            browseFileRef.current.click();
        } else {
            displayNotification("error", "Error opening file selection window.");
        }
    };

    const clearFile = () => {
        setSelectedStructures({ ...selectedStructures, file: { currentFile: null, fileName: "" } });
        setFileName("");
    }


    const getFile = (files) => {
        const selectedFile = files[0];
        if (selectedFile) {
            setSelectedStructures({
                ...selectedStructures, file: {
                    currentFile: selectedFile,
                    fileName: selectedFile.name,
                }
            });
            setFileName(selectedFile.name);
        } else {
            setFileName("");
        }
    };
    const separateLinks = (input) => {
        const links = JSON.parse(input);
        const htmlLinks = links.filter(link => link.endsWith('.html'));
        const pngLinks = links.filter(link => link.endsWith('.png'));
        return {
            htmlLinks,
            pngLinks
        };
    };

    useEffect(()=> {
return ()=> {
    handleCloseModal();
}
    },[])
    // Upload and Replace Existing Chapter Data
    const saveUploadModal = async () => {

        setIsLoading({ ...isLoading, saving_data: true });
        try {
            setSaveButtonText('Processing data...');
            await getBearerToken();
            const html = await processFile(selectedStructures?.file?.currentFile, 'Convert to HTML5', false, false, true);
            const { htmlLinks, pngLinks } = separateLinks(html);
            // const queryParams = new URLSearchParams(location.search);
            // const id = queryParams.get("id");
            // const type = id.toString().split("");
            setSaveButtonText('Mapping data...');
            const data = {
                // chapter_id: metaData?.chapter?.id ?? "",
                // standard_type: type[0],
                // animal_type: type[1],
                // lang_id: metaData?.lang_id,
                // area_vol_no: metaData?.area_vol_no ?? null,
                // area_vol_name: metaData?.area_vol_name ?? null,
                // section_no: metaData?.section_no ?? null,
                // section_name: metaData?.section_name ?? null,
                // chapter_name: metaData?.chapter_name ?? null,
                // chapter_no: metaData?.chapter_no ?? null,
                // subsection_id: '',
                // RID: metaData?.RID ?? null,
                file_link: htmlLinks[0],
                image_links: pngLinks,
                draft_structure_id:metaData.structures_id
            }
            const ret = await sendAPIRequest(`/api/draft-chapter/m-create-new/?is_new=${0}`, 'POST', data);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                const isCode = metaData.standard_type === LIST_DETAIL_ELEMENTS.standard_type.codes;
                navigate(`/portal/draft/edit/${res.data.id}/?is_code=${isCode}&is_modify=true`);
                displayNotification('success', 'Successfully Upload [x].');
            }
            else if (res.status === 409) {
                displayNotification('error', res?.message);
            }
            else {
                showPopupAlert('Error', 'Failed to upload file [x]', 'error');
            }
        } catch (err) {
            console.log({ err });
            showPopupAlert('Error', 'Failed to upload file', 'error');
        }
        setSaveButtonText('Save');
        setIsLoading({ ...isLoading, saving_data: false });
    }

    return (
        <>
            <Modal show={open} size='lg' centered>
                <Modal.Header closeButton
                    className='p-4 fw-bold fs-3'
                    onClick={() => { handleCloseModal() }}
                >
                    Upload Chapter
                </Modal.Header>
                <Modal.Body className='p-4'>
                    {

                        <div className='d-flex justify-content-center'>
                            <div className="d-flex flex-column justify-content-center my-3">
                                <button
                                    type="button"
                                    className="btn btn--outline mb-2 fs-6"
                                    onClick={() => fileUpload()}
                                >
                                    <i class="fa-solid fa-cloud-arrow-up me-3 fs-5"></i>
                                    {t("37", "Browse File")}
                                </button>
                                <label>
                                    {fileName ? (
                                        <div>
                                            <strong>{t("-1", "Selected File")}: </strong>
                                            {fileName}
                                            <i class="fa-solid fa-xmark mx-3 cursor-pointer" onClick={() => clearFile()}></i>
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </label>
                                <input
                                    ref={browseFileRef}
                                    type="file"
                                    id="get-file"
                                    style={{ display: "none" }}
                                    onChange={(e) => getFile(e.target.files)}
                                />
                            </div>
                        </div>

                    }

                </Modal.Body>
                <Modal.Footer className='p-4 d-flex justify-content-center'>
                    <div className='d-flex justify-content-center'>
                        <button
                            type='button'
                            className='btn btn--outline font-custom fs-6'
                            onClick={() => { handleCloseModal() }}
                        >
                            Close
                        </button>
                        <button
                            type='button'
                            className='btn btn--primary font-custom fs-6 ms-1'
                            disabled={isLoading.saving_data}
                            onClick={() => { saveUploadModal() }}
                        >
                            {isLoading.saving_data ? <Spinner message={saveButtonText} /> : saveButtonText}
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default UploadChapter;