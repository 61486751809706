import React, { useRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DataTable from 'pages/components/DataTable';
import { GridActionsCellItem } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { displayNotification, getLanguageAgainstIDs, getStandardAgainstIDs, sendAPIRequest } from 'helpers';
import DraftGlossaryEditModal from 'components/modals/DraftGlossaryEditModal';
import DocumentTransferModal from 'components/modals/DocumentTransferModal';
import { TRANSFER_STATUS } from 'constants';
import CustomChip from 'components/CustomChip';
import { USER_PERMISSIONS } from 'constants';
import PreviewModal from 'components/modals/PreviewModal';
import EquivalentModal from 'components/modals/EquivalentModal';
import { IconButton, Tooltip, Typography } from '@mui/material';
import { LIST_DETAIL_ELEMENTS } from 'constants';
import LinkGlossaryModal from 'components/modals/LinkGlossaryModal';
import { useSelector } from 'react-redux';
import { Add } from '@mui/icons-material';


const GlossaryAccordionComponent = ({ standardDetails, componentLanguage, setEditingComplete }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);
    const [isPublishing, setIsPublishing] = useState(false);
    const [equivalentModal, setEquivalentModal] = useState(false);
    const [addGlossaryModal, setAddGlossaryModal] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [reFetch, setrefetch] = useState(false)
    const userAuth = useSelector((s) => s.auth);
    const authData = useSelector(s => s.auth?.data);
    const [popupDetail, setPopupDetail] = useState({
        title: '',
        body: '',
        varaint: ''
    });
    const [editData, setEditData] = useState(null);
    const [transferDocumentDetails, setTraferDocumentDetails] = useState({});
    const [transferModal, setTransferModal] = useState(false);
    const [openPreview, setopenPreview] = useState(false);
    const [term, setTerm] = useState('');
    const [access, setAccess] = useState();
    const [glossaryterm, setGlossaryterm] = useState('');
    const [glossaryPreviewData, setGlossaryPreviewData] = useState({ term: "", content: "" });
    const [prevId, setprevId] = useState('');
    const [isExclude, setIsExclude] = useState(false);

    useEffect(() => {
        loadGlossary()
    }, [standardDetails, componentLanguage])

    const loadGlossary = async () => {
        setLoading(true);
        try {
            const ret = await sendAPIRequest(`/api/draft-glossaries/draft-version-glossary/?standard_type=${standardDetails.standard_type}&animal_type=${standardDetails.animal_type}&language_id=${componentLanguage ?? "102"}`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                setData(res.data);
                const data = res?.data;
                const isTransferStatusOneInAll = data.every(item =>
                    item.draft_glossary.every(glossary => glossary.transfer_status === 1)
                );
                setEditingComplete(isTransferStatusOneInAll);
            } else {
                displayNotification('error', res.message);
            }
        } catch (error) {
            console.error({ error });
            displayNotification("error", "Loading Glossary Error");
        }
        setLoading(false);
    }

    const permissionsForActions = (transfer = []) => {
        try {
            if (transfer.length === 0) {
                return true;
            } else {
                if (transfer.length === 1) {
                    if (transfer[0].sender && [null, undefined].includes(transfer[0].receiver)) {
                        if (transfer[0].sender_id === userAuth.data.id) {
                            return true;
                        }
                    }
                    if (transfer[0].sender && transfer[0].receiver) {
                        if (transfer[0].receiver_id === userAuth.data.id) {
                            return true;
                        }
                    }
                }
                if (transfer.length > 1) {
                    const lastItem = transfer[transfer.length - 1];
                    if (lastItem.receiver_id === userAuth.data.id) {
                        return true;
                    }
                }
            }
            return false;
        } catch (error) {
            console.error({ error });
        }
    }

    const handleOpenEditModal = (id) => {
        setEditModal(true);
        setEditData(data.find(item => item.id === id));
    }



    const handleCloseEditModal = () => {
        setEditModal(false);
        setEditData(null);

    }

    const handleEquivalentModalOpen = (id = '', is_exlude = false, term) => {
        setGlossaryterm(term)
        setprevId(id);
        setEquivalentModal(true);
        setIsExclude(is_exlude);
    }

    const handleEquivalentModalClose = () => {
        setEquivalentModal(false);
        setprevId('');
        setGlossaryterm('');
    }
    const handleCloseGlossaryModal = () => {
        setAddGlossaryModal(false);
    }
    const deleteDraftGlosary = async (id) => {
        if (window.confirm('Are You Sure To Delete?')) {
            //   setIsLoading({ ...isLoading, delete_document: true });
            try {
                let ret;
                ret = await sendAPIRequest(`/api/draft-glossaries/${id}`, "DELETE");
                const res = await ret.json();
                if (res.success && res.status === 200) {
                    await loadGlossary()
                    displayNotification('success', 'Successfully deleted draft glossary');
                } else {
                    displayNotification('error', 'Failed to delete draft glossary term');
                }
            } catch (e) {
                console.log({ e });
                displayNotification('error', 'Failed to delete draft glossary term');
            }
            //   setIsLoading({ ...isLoading, delete_document: false });
        }
    }

    const handleTranserferDocument = (glossary_id, animal_type, standard_type, lang_id, type = '') => {
        const standard = getStandardAgainstIDs(standard_type, animal_type);
        const lang_code = getLanguageAgainstIDs(lang_id, true);
        setTraferDocumentDetails({
            glossary_id,
            standard,
            lang_code,
            type
        })
        setTransferModal(true)
    }

    const handleReturnStatus = (arr = []) => {
        let status = arr.find((i) => i.transfer_status === TRANSFER_STATUS.publishing);
        if (status) {
            return 3;
        }
        status = arr.find((i) => i.transfer_status === TRANSFER_STATUS.validation);
        if (status) {
            return 2;
        } else {
            return 1;
        }
    }

    const getStatusChip = (status) => {
        switch (status) {
            case 4:
                return <CustomChip label="Published" type="primary" />;
            case 3:
                return <CustomChip label="Validated" type="orange" />;
            case 2:
                return <CustomChip label="Pending Validation" type="blue" />;
            default:
                return <CustomChip label="Draft" type="green" />;
        }
    };

    const handleReturnUser = (arr = []) => {
        let status = arr.find((i) => i.transfer_status === TRANSFER_STATUS.publishing && i.permissions === USER_PERMISSIONS.publisher);
        if (status) {
            return status.receiver.user_name;
        }
        status = arr.find((i) => i.transfer_status === TRANSFER_STATUS.validation && i.permissions === USER_PERMISSIONS.validator);
        if (status) {
            return status.receiver.user_name;
        }
        status = arr.find((i) => i.transfer_status === TRANSFER_STATUS.editing);
        if (status) {
            return status?.receiver?.user_name ? status?.receiver?.user_name : status?.sender?.user_name ?? '';
        }
        else {
            return '';
        }
    }

    const getAccess = (publisher, author, validator) => {
        if (publisher === true && author === false && validator === false) {
            return "No access";
        }
        if (validator === true && author === false && publisher === false) {
            return "Modify preview and transfer access";
        }
        if (author === true) {
            return "All access";
        }
        return "All access";
    }

    useEffect(() => {
        const accessLevel = getAccess(authData?.is_publisher, authData?.is_author, authData?.is_validator);
        setAccess(accessLevel);
    }, [])

    const handleClose = () => {
        setopenPreview(false);
        setprevId('');
        setTerm('');
        setGlossaryterm('');
    }

    const openPreviewModal = (id = '', term = '', content = '') => {
        setopenPreview(true);
        setprevId(id);
        setGlossaryPreviewData({ term: term, content: content });
        setTerm(term);
    }

    return (
        <div>
            <div className="accordion mt-4 border-0" id={"accordionExample"}>
                <div className="accordion-item rounded-0">
                    <h1 className="accordion-header accordion__item-label collapsed" id={`heading`}>
                        <button
                            className="accordion-button font-custom-4 fs-4 rounded-0 py-2 collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#collapse`}
                            aria-expanded="false"
                            aria-controls={`collapse`}
                        >
                            <span className="me-auto fs-4 fw-bold">
                                {t('-1', 'Glossary')}
                                <Tooltip title="Add New Glossary Term" placement="top">
                                    <IconButton>
                                        <Add color='primary' fontSize='medium'
                                            onClick={() => {
                                                setAddGlossaryModal(true);
                                            }} />
                                    </IconButton>
                                </Tooltip>
                            </span>
                        </button>
                    </h1>
                    <div
                        id={`collapse`}
                        className="accordion-collapse collapse"
                        aria-labelledby={`heading`}
                        data-bs-parent={`#accordionExample`}
                    >
                        <DataTable
                            getRowId={(row) => row.id}
                            isLoading={loading}
                            pagination={true}
                            rows={data}
                            className="text-white bg-primary rounded-0"
                            columns={[
                                {
                                    field: "glossary_term",
                                    headerName: "Term",
                                    headerClassName: "text-white bg-primary rounded-0",
                                    flex: 2.5,
                                    renderCell: (params) => {
                                        const term = params.value;
                                        const isDeleted = params.row.deletedAt !== null;
                                        return (
                                            <Typography sx={{ textDecoration: isDeleted ? 'line-through' : 'none', color: isDeleted ? 'gray' : 'inherit' }} color="inherit" >
                                                {term}
                                            </Typography>)
                                    }
                                },
                                {
                                    field: "equivalentTerms",
                                    headerName: t("-1", "Equivalent Terms"),
                                    headerClassName: "text-white bg-primary rounded-0",
                                    flex: 2,
                                    renderCell: (params) => {
                                        const isDeleted = params.row.deletedAt !== null;
                                        return (
                                            <div>
                                                {params.row?.relationExcludeTerms
                                                    ?.filter(term => term.is_excluded === false)
                                                    .map((i, index) => (
                                                        <Typography variant="body2" className="fs-7" key={index + "equivalent"} sx={{ textDecoration: isDeleted ? 'line-through' : 'none', color: isDeleted ? 'gray' : 'inherit' }} color="inherit">
                                                            {i.txt}
                                                        </Typography>
                                                    ))}
                                            </div>
                                        );
                                    },
                                },
                                {
                                    field: "excludedTerms",
                                    headerName: t("-1", "Excluded Terms"),
                                    headerClassName: "text-white bg-primary rounded-0",
                                    flex: 2,
                                    renderCell: (params) => {
                                        const isDeleted = params.row.deletedAt !== null;
                                        return (
                                            <div>
                                                {params.row?.relationExcludeTerms
                                                    ?.filter(term => term.is_excluded === true)
                                                    .map((i, index) => (
                                                        <Typography variant="body2" className="fs-7" key={index + "excluded"} sx={{ textDecoration: isDeleted ? 'line-through' : 'none', color: isDeleted ? 'gray' : 'inherit' }} color="inherit">
                                                            {i.txt}
                                                        </Typography>
                                                    ))}
                                            </div>
                                        );
                                    },
                                },
                                {
                                    field: "user",
                                    headerName: "User",
                                    headerClassName: "text-white bg-primary rounded-0",
                                    flex: 1,
                                    renderCell: (params) => {
                                        const isDeleted = params.row.deletedAt !== null;

                                        return (
                                            <Typography
                                                sx={{ textDecoration: isDeleted ? 'line-through' : 'none', color: isDeleted ? 'gray' : 'inherit' }}
                                                color="inherit"
                                            >
                                                {params?.row?.draft_glossary.length > 0 ? handleReturnUser(params?.row?.draft_glossary) : ""}
                                            </Typography>
                                        );
                                    }
                                },

                                {
                                    field: "status",
                                    headerName: "Status",
                                    headerClassName: "text-white bg-primary rounded-0",
                                    flex: 1.3,
                                    renderCell: (params) => {
                                        // const transferStatus = params.row?.document_transfers[0]?.transfer_status;
                                        return params?.row?.draft_glossary.length > 0 ? getStatusChip(handleReturnStatus(params?.row?.draft_glossary ?? [])) : '';
                                    },
                                },
                                {
                                    field: "actions",
                                    type: "actions",
                                    headerClassName: "text-white bg-primary rounded-0",
                                    headerName: "Actions",
                                    getActions: (params) => {
                                        const isDeleted = params.row.deletedAt !== null;
                                        const status = params.row?.draft_glossary.length > 0 ? params.row?.draft_glossary[0]?.transfer_status : null;
                                        if (isDeleted) {
                                            return [];
                                        }
                                        if (status === TRANSFER_STATUS?.publishing) {
                                            return [
                                                <GridActionsCellItem
                                                    label="Preview"
                                                    key={4}
                                                    showInMenu
                                                    onClick={() => openPreviewModal(params.row.id, params.row.glossary_term, params.row.content)}
                                                />
                                            ]
                                        }
                                        else {
                                            return [
                                                ...(access == 'All access' ? [
                                                    <GridActionsCellItem
                                                        label="Modify Term"
                                                        key={0}
                                                        showInMenu
                                                        onClick={() => handleOpenEditModal(params.row.id)}
                                                        disabled={(standardDetails?.structureStatus === 4 || standardDetails?.structureStatus === 5 || !permissionsForActions(params.row.draft_glossary))}
                                                    />,
                                                    <GridActionsCellItem
                                                        label="Equivalent Terms"
                                                        key={2}
                                                        showInMenu
                                                        onClick={() => { handleEquivalentModalOpen(params.row.id, false, params.row.glossary_term) }}
                                                        disabled={(standardDetails?.structureStatus === 4 || standardDetails?.structureStatus === 5 || !permissionsForActions(params.row.draft_glossary))}
                                                    />,
                                                    <GridActionsCellItem
                                                        label="Excluded Terms"
                                                        key={3}
                                                        showInMenu
                                                        onClick={() => handleEquivalentModalOpen(params.row.id, true, params.row.glossary_term)}
                                                        disabled={(standardDetails?.structureStatus === 4 || standardDetails?.structureStatus === 5 || !permissionsForActions(params.row.draft_glossary))}
                                                    />,
                                                    <GridActionsCellItem
                                                        label="Preview"
                                                        key={4}
                                                        showInMenu
                                                        onClick={() => openPreviewModal(params.row.id, params.row.glossary_term, params.row.content)}
                                                    />,

                                                    <GridActionsCellItem
                                                        label="Transfer"
                                                        key={5}
                                                        showInMenu
                                                        onClick={() => handleTranserferDocument(params.row?.id, params.row?.animal_type, params.row?.standard_type, params.row?.language_id, 2
                                                        )}
                                                        disabled={(standardDetails?.structureStatus === 4 || standardDetails?.structureStatus === 5 || !permissionsForActions(params.row.draft_glossary))}
                                                    />,
                                                    <GridActionsCellItem
                                                        label="Delete Term"
                                                        key={1}
                                                        showInMenu
                                                        onClick={() => deleteDraftGlosary(params.row.id)}
                                                        disabled={(standardDetails?.structureStatus === 4 || standardDetails?.structureStatus === 5 || !permissionsForActions(params.row.draft_glossary))}
                                                    />,
                                                ] : []),
                                                ...(access === "Modify preview and transfer access" ? [
                                                    <GridActionsCellItem
                                                        label="Modify Term"
                                                        key={0}
                                                        showInMenu
                                                        onClick={() => handleOpenEditModal(params.row.id)}
                                                        disabled={(standardDetails?.structureStatus === 4 || standardDetails?.structureStatus === 5 || !permissionsForActions(params.row.draft_glossary))}
                                                    />,
                                                    <GridActionsCellItem
                                                        label="Preview"
                                                        key={4}
                                                        showInMenu
                                                        onClick={() => openPreviewModal(params.row.id, params.row.glossary_term, params.row.content)}
                                                    />,
                                                    <GridActionsCellItem
                                                        label="Transfer"
                                                        key={6}
                                                        showInMenu
                                                        onClick={() => handleTranserferDocument(params.row?.id, params.row?.animal_type, params.row?.standard_type, params.row?.language_id, 2)}
                                                        disabled={(standardDetails?.structureStatus === 4 || standardDetails?.structureStatus === 5 || !permissionsForActions(params.row.draft_glossary))}
                                                    />,
                                                ] : [])
                                            ];
                                        }
                                    }
                                },
                            ]}
                        />
                    </div>
                </div>
            </div>
            <LinkGlossaryModal
                open={addGlossaryModal}
                handleClose={handleCloseGlossaryModal}
                loadGlossary={loadGlossary}
                setrefetch={setrefetch}
                showPopup={showPopup}
                setShowPopup={setShowPopup}
                popupDetail={popupDetail}
                setPopupDetail={setPopupDetail}
                reFetch={reFetch}
                standardDetails={standardDetails}
                componentLanguage={componentLanguage}
            />
            <DocumentTransferModal
                open={transferModal}
                handleClose={() => {
                    setTransferModal(false);
                    setTraferDocumentDetails({});
                }}
                documentDetails={transferDocumentDetails}
                loadData={loadGlossary}
            />
            <EquivalentModal
                open={equivalentModal}
                handleClose={handleEquivalentModalClose}
                id={prevId}
                standardDetails={standardDetails}
                componentLanguage={componentLanguage}
                loadGlossary={loadGlossary}
                isExclude={isExclude}
                glossaryTerm={glossaryterm}
            />
            {editData &&
                <DraftGlossaryEditModal
                    open={editModal}
                    handleClose={handleCloseEditModal}
                    data={editData}
                    setData={setEditData}
                    showPopup={showPopup}
                    setShowPopup={setShowPopup}
                    popupDetail={popupDetail}
                    setPopupDetail={setPopupDetail}
                    handleCloseEditModal={handleCloseEditModal}
                    loadGlossary={loadGlossary}
                    is_modify={true}

                />
            }
            <PreviewModal
                open={openPreview}
                handleClose={handleClose}
                prevId={prevId}
                term={term}
                is_glossary={true}
                standardDetails={standardDetails}
                componentLanguage={componentLanguage}
                glossaryPreviewData={glossaryPreviewData}
                load={loadGlossary}
            />
        </div>
    );
};

export default GlossaryAccordionComponent;
