import AutoCompleteDropdown from 'components/autocomplete/AutoCompleteDropdown';
import React, { useEffect, useMemo, useState } from 'react';
import { BsInfoCircleFill } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';
import { displayNotification, generateOptionsFromData, sendAPIRequest } from 'helpers';
import { Container } from 'react-bootstrap';
import { GridActionsCellItem } from '@mui/x-data-grid';
import DataTable from 'pages/components/DataTable';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import CustomCard from 'components/common/CustomCard';
import CardHeader from 'components/common/CardHeader';
import { setCurrentPage } from 'redux/generalSlice';
import { useDispatch } from 'react-redux';

const CrossLinkImplementation = () => {
    const { t } = useTranslation();
    const [alertText, setAlertText] = useState(t("-1", `All prepublished chapters in Upload are shown in the chapter dropdown. Users can verify each chapter individually or use 'Load Next Chapter' to load the next one. When a new term is added and validated for pre-publication, it will appear below with all relevant data from the applicable standard.`));
    const [isAlertVisible, setIsAlertVisible] = useState(true);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isSearch, setIsSearch] = useState(false);
    const [DropDownData, setDropDownData] = useState([]);
    const [filters, setFilters] = useState({
        chapter_selection: []
    });
    const [chapterData, setChapterData] = useState([]);
    const [selectedChapter, setSelectedChapter] = useState({ value: '', label: '' });
    const [selectedTerms, setSelectedTerms] = useState({});

    const chapterOptions = useMemo(() => {
        return generateOptionsFromData(chapterData, {
            value: "id",
            label: "chapter_display_name",
        });
    }, [chapterData]);

    const handleCheckboxChange = (id) => {
        setSelectedTerms(prev => ({
            ...prev,
            [id]: !prev[id],
        }));
    };

    const handleSelectAllChange = (event) => {
        const isSelected = event.target.checked;
        const allSelectedTerms = {};
        rows.forEach(row => {
            allSelectedTerms[row.id] = isSelected;
        });
        setSelectedTerms(allSelectedTerms);
    };

    const rows = [
        { id: 1, link_text: "1.1.3", description: "Standatds for 1.1.3 are described" },
        { id: 2, link_text: "6.5", description: "standards referred to in Chapter 6.5." },
        { id: 3, link_text: "9.1.2", description: "with the exception of those listed in Article 9.1.2." },
    ];

    const showAlert = (text) => {
        setAlertText(text);
        setIsAlertVisible(prev => !prev);
    };

    return (
        <Container>
            <CustomCard>
                <CardHeader>
                    Cross-Link Implementation
                </CardHeader>
            </CustomCard>
            <CustomCard >
                <div className="row mt-3">
                    <div className="d-flex align-items-center mb-6">
                        <BsInfoCircleFill
                            className="bi flex-shrink-0 me-4 ms-3 text-blue-light-2 cursor-pointer"
                            size={24}
                            onClick={() => showAlert(alertText)}
                        />
                        {isAlertVisible && (
                            <div className="alert my-0 py-0 me-5 rounded-0 text-dark d-flex align-items-center" role="alert">
                                <div className="fs-7 text-justify me-0">
                                    {alertText}
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="col-md-5 mb-3">
                        <AutoCompleteDropdown
                            showClearIcon={true}
                            error={false}
                            multiple={false}
                            label={t("-1", "Chapter")}
                            options={chapterOptions}
                            onChange={(event, value) => {
                                setSelectedChapter(value ? { value: value.value, label: value.label } : { value: "", label: "" });
                                setCurrentIndex(chapterOptions.findIndex((element) => element.value === value.value));
                            }}
                            value={selectedChapter.value ? selectedChapter : { value: "", label: "" }}
                            size='medium'
                        />
                    </div>
                    <div className="col-md-7 mt-3 text-end">
                        <button
                            className="btn btn--primary font-custom fs-6 ms-1"
                            disabled={chapterOptions.length === currentIndex + 1}
                        >
                            {t("-1", "Load Next Chapter")}
                        </button>
                    </div>
                </div>
            </CustomCard>
            <CustomCard>
                <DataTable
                    getRowId={(row) => row.id}
                    isLoading={false}
                    pagination={true}
                    rows={rows}
                    className="text-white bg-primary rounded-0"
                    columns={[
                        {
                            field: "link_text",
                            headerName: "Link Text",
                            headerClassName: "text-white bg-primary rounded-0",
                            flex: 1,
                            renderCell: (params) => <span>{params.value}</span>,
                        },
                        {
                            headerClassName: "text-white bg-primary rounded-0",
                            flex: 2,
                            renderHeader: () => (
                                <div className="d-flex align-items-center">
                                    <Checkbox
                                        indeterminate={Object.values(selectedTerms).some(Boolean) && !Object.values(selectedTerms).every(Boolean)}
                                        checked={Object.values(selectedTerms).every(Boolean)}
                                        onChange={handleSelectAllChange}
                                        color="white"
                                    />
                                    <Typography variant="body1" className="ms-2">Select All</Typography>
                                </div>
                            ),
                            renderCell: (params) => (
                                <Checkbox
                                    checked={!!selectedTerms[params.row.id]}
                                    onChange={() => handleCheckboxChange(params.row.id)}
                                    color="primary"
                                />
                            ),
                        },

                        {
                            field: "description",
                            headerName: "Description",
                            headerClassName: "text-white bg-primary rounded-0",
                            flex: 3,
                        },
                        {
                            field: "actions",
                            type: "actions",
                            headerClassName: "text-white bg-primary rounded-0",
                            headerName: "Actions",
                            getActions: (params) => [
                                <GridActionsCellItem label="Accept" key={0} showInMenu />,
                                <GridActionsCellItem label="Preview" key={1} showInMenu />,
                                <GridActionsCellItem label="Ignore" key={2} showInMenu />,
                            ],
                        },
                    ]}
                    disableColumnMenu={false}
                />
            </CustomCard>
        </Container>
    );
};

export default CrossLinkImplementation;
