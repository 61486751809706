import React from 'react';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';

const Page404 = () => {
    const { t } = useTranslation();
    const navigate = useNavigate()
    const handleRedirect = () => {
        navigate('/portal/'); 
    };
    return (
        <div className='container min-h-50 py-20 d-flex'>
            <div className="m-auto text-center">
                <h1 className='display-1'>{t("-1", "Ops")}!</h1>
                <h1>{t("-1", "Page Not Found")}</h1>
                <button 
                    className="btn btn-primary mt-3" 
                    onClick={handleRedirect}
                >
                    {t("Return to Home")}
                </button>
            </div>
        </div>
    );
}

export default Page404;
