import { createSlice } from '@reduxjs/toolkit';
import { SITE_LANGUAGES } from '../constants';

export const searchContentLanguage = createSlice({
    name: 'searchContentLanguage',
    initialState: {
        sidebarOpened: false,
        searchContentLanguageSONT: '102',
        searchContentLanguageSONTSF: 'en',
        currentPage: '',
        headerTheme: 'white',
    },
    reducers: {
        setSidebarState: (state, payload = false) => {
            state.sidebarOpened = payload.payload;
        },
        setContentSiteLanguage: (state, payload) => {
            state.searchContentLanguageSONT = payload.payload;
            state.searchContentLanguageSONTSF = SITE_LANGUAGES[payload.payload] ?? 'en';
        },
        setCurrentPage: (state, payload) => {
            state.currentPage = payload.payload;
        },
        setHeaderTheme: (state, payload) => {
            state.headerTheme = payload.payload ? payload.payload : 'white';
        },
    },
})

export const { setSidebarState, setCurrentPage, setHeaderTheme, setContentSiteLanguage } = searchContentLanguage.actions

export default searchContentLanguage.reducer