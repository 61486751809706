import { sendAPIRequest } from "helpers";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const ChapterRedirectComponent = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [isloading, setIsLoading] = useState(false);

    useEffect(() => {
        loadStructure();
    }, [id]);

    const handleRedirect = (link = '/portal/content-navigation') => {
        navigate(link);
    };

    const loadStructure = async () => {
        setIsLoading(true);
        try {
            const ret = await sendAPIRequest(`/api/chapter-linking/get-structure/${id}`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                const structure = res.data;
                handleRedirect(`/portal/get-chapter-preview/?area_number=${structure.part ?? ''}&section_number=${structure.section}&chapter_number=${structure.chapter}&article_number=${structure.article ?? ''}&language_id=${structure.language_id}&standard_type=${structure.standard_type}&animal_type=${structure.animal_type}`);
            } else {
                handleRedirect();
            }
        } catch (err) {
            console.log({ err });
            handleRedirect();
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <div className="container py-5 text-center">
            Redirecting...
        </div>
    );
}

export default ChapterRedirectComponent;
