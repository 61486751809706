import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import { useImperativeHandle } from 'react';

const EditModal = React.forwardRef(({children, content, header, reloadData, rows, btn1Text, btn2Text}, ref) => {

    const { t } = useTranslation();

    const [modalShowing, setModalShowing] = useState(false);
    const [modalOpened, setModalOpened] = useState(false);
    const [data, setData] = useState({
        updated_content: content,
        header: header,
        reloadData: reloadData,
        rows: rows,
        btn1_text: btn1Text,
        btn2_text: btn2Text,
    });

    useImperativeHandle(ref, () => {
        return {
            handleOpen
        }
    });

    const handleSave = () => {
        setModalShowing(false);
        setModalOpened(false);
        data.reloadData && data.reloadData(data.updated_content);
        setData({});
    }

    const handleOpen = (content, header, reloadData, rows, btn1Text, btn2Text) => {
        if (!modalOpened) {
            content && setData(prev => ({ ...prev, updated_content: content }));
            header && setData(prev => ({ ...prev, header: header }));
            reloadData && setData(prev => ({ ...prev, reloadData: reloadData }));
            rows && setData(prev => ({ ...prev, rows: rows }));
            btn1Text && setData(prev => ({ ...prev, btn1_text: btn1Text }));
            btn2Text && setData(prev => ({ ...prev, btn2_text: btn2Text }));
            setModalOpened(true);
            setModalShowing(true);
        }
    }

    return (
        <>
            {children(handleOpen)}
            <Modal show={modalShowing} size='md' centered={true}>
                <Modal.Header 
                    className='p-4' 
                    closeButton 
                    onClick={()=>(setModalShowing(false), setModalOpened(false))}
                >
                    <Modal.Title>{data.header}</Modal.Title>
                </Modal.Header>
                <Modal.Body className='p-4'>
                    <textarea 
                        className='form-control'
                        value={data.updated_content}
                        onChange={e => setData(prev => ({ ...prev, updated_content: e.target.value }))}
                        rows={data.rows}
                    />
                </Modal.Body>
                <Modal.Footer className='p-4 d-flex justify-content-center'>
                    <button 
                        // variant="dark" 
                        className='btn btn--primary' 
                        onClick={()=>(setModalShowing(false), setModalOpened(false))}
                    >
                        {t("201", "Close")}
                    </button>
                    <button 
                        // variant="primary" 
                        className='btn btn--outline' 
                        onClick={() => handleSave()}
                    >
                        {t("109", "Update")}
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    );
});

EditModal.defaultProps = {
    children: () => { },
    content: "",
    header: "Edit Text",
    reloadData: null,
    rows: 1,
    btn1Text: "Close",
    btn2Text: "Update",
}

export default EditModal;