import React from 'react';
import { Tab } from '@mui/material';

const CommonTab = (props) => {
    return (
        <Tab
            {...props}
            sx={{
                fontSize: '1rem',
                fontWeight: 'bold',
                color: 'text.primary',
                '&.Mui-selected': {
                    color: '#ff4815',
                },
                '&:hover': {
                    color: '#ff4815',
                    backgroundColor: '#ffece8',
                },
                padding: '12px 24px',
            }}
        />
    );
};

export default CommonTab;
