import React, { useEffect, useRef, useState } from 'react';
import DOMPurify from 'dompurify';
import { Tooltip, styled, tooltipClasses } from '@mui/material';
import { getBaseURL, sendAPIRequest } from '../helpers';


const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#ffece8',
        color: '#ff4815',
        boxShadow: theme.shadows[1],
        fontSize: 12,
        padding: '13px'
    },
}));

const HtmlRenderer = ({ className, style, key, id, htmlContent, tag, isItalic, crawling }) => {

    const [glossary_text, setGLossaryText] = useState('')
    const contentRef = useRef(null);
    if (isItalic) {
        let regex = /<i\b[^>]*>/gi;
        htmlContent = htmlContent.replace(regex, `<i style= font-size: 1.15rem; font-weight: 600; font-family: 'Sohne-kraftig'">`);
    }

    const loadGLorraryData = async (id) => {
        try {
            const ret = await sendAPIRequest(`/api/glossaries/get-glossary/${id}`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                setGLossaryText(res.data.glossary_text)
                return res.data
            }
        } catch (e) {
            console.log({ e });
            // displayNotification('error', 'Failed to load chapter data [F]');
        }
        // setIsLoading({ ...isLoading, loading_data: false });

    }


    useEffect(() => {
        if (contentRef.current && crawling) {
            const anchorTags = contentRef.current.querySelectorAll('a');
            anchorTags.forEach(async (anchor, index) => {
                if (anchor.href.includes("/sont-be/api/glossaries/get-glossary/") && !anchor.href.includes("/sont-be/api/#/glossary/chapter/")) {
                    anchor.id = `anchor-${index}+${id}`;
                    anchor.innerHTML = `<em>${anchor.innerHTML}</em>`;
                    let parts = anchor.href.split("/")
                    const lastPart = parts[parts.length - 1].toString();
                    let x = await loadGLorraryData(lastPart)
                    let defi = x.glossary_text
                    anchor.setAttribute('href', getBaseURL(`/glossary-chapter/?term=${lastPart}&standard_type=${x.standard_type ? x.standard_type : ''}&animal_type=${x.animal_type ? x.animal_type : ''}&lang_id=${x.language_id ? x.language_id : ''}`));
                    anchor.setAttribute('target', '_blank');
                    anchor.setAttribute('title', '');
                    anchor.setAttribute('data-title', `${defi ? defi : 'no Definition'}`);
                    anchor.classList.add('custom-tooltip');
                    const tempTooltip = document.createElement('span');
                    tempTooltip.classList.add('span_tooltip');
                    const uniqueID = `span-${index}-${id}`;
                    tempTooltip.id = uniqueID;
                    tempTooltip.innerHTML = defi ? defi : 'No Definition';
                    anchor.addEventListener('mouseenter', (event) => {
                        const tmp = event.target.getBoundingClientRect();
                        const el = event.target.querySelector(`#${uniqueID}`);
                        el.style.top = (tmp.top - 20) + 'px';
                        el.style.left = (tmp.left) + 'px';
                        el.style.display = 'block';
                        el.style.opacity = 0;
                        setTimeout(() => {
                            const elRect = el.getBoundingClientRect();
                            el.style.top = (tmp.top - elRect.height) + 'px';
                            el.style.left = ((tmp.left + (tmp.width / 2)) - (elRect.width / 2)) + 'px';
                            el.style.opacity = 1;
                        }, 200);
                    });
                    anchor.addEventListener('mouseleave', (event) => {
                        event.target.querySelector(`#${uniqueID}`).style.display = 'none';
                    });
                    anchor.appendChild(tempTooltip);
                }
                if (anchor.href.includes('get-chapter-preview')) {
                    anchor.setAttribute('target', '_blank');
                }
            });
            return () => {
                anchorTags.forEach(anchor => {
                    if (anchor.href.includes("/sont-be/api/glossaries/get-glossary/")) {
                    }
                });
            };
        }
    }, [htmlContent]);

    const sanitizedHtml = DOMPurify.sanitize(htmlContent);
    const Tag = tag ?? 'div';
    return <Tag
        ref={contentRef}
        className={className}
        style={style}
        key={key}
        id={id}
        dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
    />;
};

HtmlRenderer.defaultProps = {
    className: '',
    style: {},
    key: Math.random() * 100 * Math.random(),
    id: '',
    htmlContent: <></>,
    tag: 'div',
    isItalic: false,
    crawling: true,
}

export default HtmlRenderer;
