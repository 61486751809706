import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import TextFieldCustom from 'components/TextFieldCustom';
import { LIST_DETAIL_ELEMENTS } from 'constants';
import HtmlRenderer from 'components/HtmlRenderer';
import { displayNotification, getBearerToken, processFile, sendAPIRequest } from 'helpers';
import collect from 'collect.js';
import SiteLoader from 'pages/components/SiteLoader';
import { useDispatch, useSelector } from 'react-redux';
import { Breadcrumbs, Tooltip } from '@mui/material';
import Spinner from 'components/spinner/Spinner';
import { isExpandedState, isSectionSecState, isSectionState } from 'redux/expandSlice';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const ManualArticles = (props) => {
  const scrollspyContainerRef = useRef(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const headerTheme = useSelector((s) => s.general.headerTheme);
  const siteLanguage = useSelector(s => s.general.siteLanguageSont);
  const isExpanded = useSelector((s) => s.expand.isExpanded);
  const [isExpanded1, setIsExpanded1] = useState(false);
  const [activeArticleText, setActiveArticleText] = useState('');
  const [activeTab, setActiveTab] = useState('');
  // console.log("TAb is", activeTab);
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [highlightedData, setHighlightedData] = useState(data);
  const [lastUpdated, setLastUpdated] = useState('');
  const [isLoading, setIsLoading] = useState({
    loading_data: false,
  });
  const [dbStyleLoad, setDbStyleLoad] = useState(null)
  const [search, setSearch] = useState('');
  const [searchingContentValue, setSearchingContentValue] = useState("")
  var originalTextMap = new Map();
  const [documentID, setDocumentID] = useState('');
  const [isMapped, setIsMapped] = useState(false);


  useEffect(() => {
    getDocId()
    setActiveTab('')
  }, [props.chapter, props.dat]);

  useEffect(() => {
    const listGroupItems = document.querySelectorAll('#list-example');
    listGroupItems.forEach(item => {
      item.addEventListener('click', handleClick);
    });

    return () => {
      listGroupItems.forEach(item => {
        item.removeEventListener('click', handleClick);
      });
    };
  }, [props.selectedChapter]);


  const [documentMetaData, setDocumentMetaData] = useState({
    section_name: '',
    chapter_name: '',
    chapter_no: '',
    section_no: ""
  });

  const getManualStyle = async () => {
    try {
      const ret = await sendAPIRequest('/api/manual-styles');
      const res = await ret.json();
      if (res.success && res.status === 200) {
        if (res.data && res.data.length > 0) {
          const css = res.data[0].style.replace(/<[^>]+>/g, '');
          return css;
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  const injectCSS = (cssContent) => {
    const styleElement = document.createElement('style');
    styleElement.appendChild(document.createTextNode(cssContent));
    document.head.appendChild(styleElement);
  };

  const getHTMLContent = async () => {
    if (scrollspyContainerRef.current) {
      displayNotification('info', 'Downloading file [F]');
      const htmlContent = scrollspyContainerRef.current.innerHTML;
      const css = await getManualStyle();
      const html = `
      <!DOCTYPE html>
      <html lang="en">
      <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <style>${css}</style>
      </head>
      <body>
        ${htmlContent}
      </body>
      </html>
      `;
      // Create a blob from the HTML content
      const blob = new Blob([html], { type: 'text/html' });
      // Create a temporary URL for the blob
      const url = URL.createObjectURL(blob);

      // Create a link element
      const a = document.createElement('a');
      a.href = url;
      a.download = 'test_html_file.htm'; // Set the filename

      // Append the link to the document body
      document.body.appendChild(a);

      // Trigger the click event on the link
      a.click();

      // Cleanup
      URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }
  };

  const handleWordDownload = async () => {
    if (scrollspyContainerRef.current) {
      displayNotification('info', 'Downloading file [F]');
      const htmlContent = scrollspyContainerRef.current.innerHTML;
      const css = await getManualStyle();
      const html = `
      <!DOCTYPE html>
      <html lang="en">
      <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <style>${css}</style>
      </head>
      <body>
        ${htmlContent}
      </body>
      </html>
      `;
      // Create a blob from the HTML content
      const blob = new Blob([html], { type: 'text/html' });
      const file = new File([blob], 'test_html_file.htm', { type: 'text/html' });
      console.log(file);
      await getBearerToken();
      await processFile(file);
    }
  }


  const getDocId = async () => {
    try {

      let getchapterValue = props.availableChapters?.find((r) => r.id === props.dat?.value)
      let newLabe = props.dat.label?.replace(/^Chapter\d+:? */, '');
      const ret = await sendAPIRequest(`/api/structure/get-doc-id`, "POST", {
        name: newLabe,
        no: getchapterValue.chapter_no

      })
      if (!props.dat.value && !props.dat.value) {
        return
      }
      const res = await ret.json()
      if (res.success && res.status === 200) {
        // setSections(res.data);
        if (res.data) {
          await loadHtmlData(res.data.document_id)
          await laodMetaData(res.data.document_id)
          setDocumentID(res.data.document_id);
        }
      }
    } catch (error) {
      console.log(error)
      displayNotification('error', 'SomeThing Went Wrong Please Try Again');
    }
  }

  const loadHtmlData = async (id) => {

    try {
      setIsLoading({ ...isLoading, load_data: true });
      const ret = await sendAPIRequest(`/api/html/manual-crack/${id}?isrequired=true`);
      const res = await ret.json();
      if (res.success && res.status === 200) {
        setData(res.data)
        setDbStyleLoad(res.flag.styleTag ?? null)
        setIsMapped(res.flag.is_cracked)
        setIsLoading({ ...isLoading, load_data: false });
      }
    } catch (e) {
      console.log({ e });
      setIsLoading({ ...isLoading, load_data: false });
    }
    setIsLoading({ ...isLoading, loading_data: false });
  };

  const laodMetaData = async (id) => {
    try {
      // let id = document
      const ret = await sendAPIRequest(`/api/html/manual-heading?id=${id}`);
      const res = await ret.json();
      if (res.success && res.status === 200) {
        // console.log('ooo',res.data)

        setDocumentMetaData(prevState => ({
          ...prevState,
          section_name: res.data.section_name,
          chapter_name: res.data.chapter_name,
          section_no: res.data.section_no,
          chapter_no: res.data.chapter_no
        }));
      }
    } catch (error) {
      console.log(error)
    }
  }



  const handleScrollToArticle = (targetId, event, key) => {
    const clickedLink = event.currentTarget;
    const href = clickedLink.getAttribute('ind');
    const activeArticleText = key + 1;
    setActiveTab(key);


    const allLinks = document.querySelectorAll('.list-group-item');
    allLinks.forEach(link => link.classList.remove('list-group-item-checked'));
    clickedLink.classList.add('list-group-item-checked');
    const targetElement = document.getElementById(targetId);
    const yourAttributeValue = targetElement.getAttribute('data-myattribute');
    const yourAttributeValue1 = targetElement.getAttribute('ind');

    setActiveArticleText(yourAttributeValue ?? "")
    const scrollspyContainer = scrollspyContainerRef.current;
    if (targetElement && scrollspyContainer) {
      const offsetTop = targetElement.offsetTop - scrollspyContainer.offsetTop;
      scrollspyContainer.scrollTo({
        top: offsetTop,
        behavior: 'smooth'
      });
    }
  };

  const handleClick = (e) => {
    e.preventDefault();
    const targetId = e.target.getAttribute('href');
    if (targetId && targetId.startsWith('#')) {
      const targetElement = document.getElementById(targetId.slice(1));
      const scrollspyContainer = scrollspyContainerRef.current;
      if (targetElement && scrollspyContainer) {
        const offsetTop = targetElement.offsetTop - scrollspyContainer.offsetTop;
        scrollspyContainer.scrollTo({
          top: offsetTop,
          behavior: 'smooth'
        });
      }
    }
  };

  const escapeRegExp = (string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // Escape special characters
  };

  const highlightSearchTerm = (htmlContent, search) => {
    if (typeof htmlContent !== 'string' || !search) return { highlightedContent: htmlContent, occurrences: 0 };

    const escapedSearch = escapeRegExp(search);
    const regex = new RegExp(`(${escapedSearch})`, 'gi');

    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, 'text/html');

    let occurrences = 0;

    const highlightNode = (node) => {
      Array.from(node.childNodes).forEach((childNode) => {
        if (childNode.nodeType === Node.TEXT_NODE) {
          const parts = childNode.textContent.split(regex);
          const fragment = document.createDocumentFragment();
          parts.forEach((part, index) => {
            const span = document.createElement('span');
            if (part.toLowerCase() === search.toLowerCase()) {
              span.style.backgroundColor = 'yellow';
              occurrences++;
            }
            span.textContent = part;
            fragment.appendChild(span);
          });
          childNode.parentNode.replaceChild(fragment, childNode);
        } else if (childNode.nodeType === Node.ELEMENT_NODE) {
          highlightNode(childNode);
        }
      });
    };

    highlightNode(doc.body);

    const serializer = new XMLSerializer();
    const highlightedHtml = serializer.serializeToString(doc);

    return { highlightedContent: highlightedHtml, occurrences };
  };

  useEffect(() => {
    if (!search) {
      setHighlightedData(data);
      setCount(0);
      return;
    }


    let totalCount = 0;
    const highlighted = data.map(item => {
      const highlightedItem = {};
      let itemOccurrences = 0;

      for (const key in item) {
        if (Object.hasOwnProperty.call(item, key)) {
          if (key === 'html_contents') {
            const highlightedContents = item[key].map(htmlContent => {
              const { highlightedContent, occurrences } = highlightSearchTerm(htmlContent.html, search);
              itemOccurrences += occurrences;
              return {
                ...htmlContent,
                html: highlightedContent
              };
            });
            highlightedItem[key] = highlightedContents;
          } else {

            const { highlightedContent, occurrences } = highlightSearchTerm(item[key], search);
            if (occurrences > 0) {
              itemOccurrences += occurrences;
              highlightedItem[key] = highlightedContent;
            } else {
              highlightedItem[key] = item[key];
            }
          }
        }
      }
      totalCount += itemOccurrences;
      return highlightedItem;
    });

    setHighlightedData(highlighted);
    setCount(totalCount);
  }, [search, data]);

  // useEffect(() => {

  //   return () => {
  //     dispatch(isExpandedState(false));
  //     dispatch(isSectionState(false));
  //     dispatch(isSectionSecState(false));
  //   };
  // }, []);

  const handleIsExpanded = (value) => {
    // setIsExpanded1(true);
    dispatch(isExpandedState(value));
    dispatch(isSectionState(value));
    dispatch(isSectionSecState(value));
  }

  return (
    <>
      <div className="container my-2">
        {/* <style   dangerouslySetInnerHTML={{ __html: dbStyleLoad }} /> */}
        <div className="row mb-0 pb-0">
          {!isExpanded1 ? (<div className={`col-md-2 border border-secondary mx-0 px-0`}>
            <div
              id="list-example"
              className="list-group mt-20 border-0 overflow-x-hidden overflow-y-auto rounded-0"
              style={{ height: "500px" }}
            >

              {data && data.length > 0 ? (
                data.map(
                  (d, k) =>
                    d.text_type ===
                    LIST_DETAIL_ELEMENTS.text_types.manual_heading && (
                      <a
                        key={`SideBar+${d.id}+k`}
                        href={`#article-${d.id}`}
                        className="list-group-item rounded-0 list-group-item-action my-5 fs-5 fw-bold"
                        onClick={(e) =>
                          handleScrollToArticle(`article-${d.id}`, e, k)
                        }
                        isActive={activeTab === k}
                      >
                        <span className='d-flex' style={{
                          flexShrink: 0
                        }}>
                          <span className=' me-2'>
                          </span>
                          <span className=''>

                            <HtmlRenderer
                              tag={"span"}
                              className="fs-4"
                              style={{
                                flexShrink: 0
                              }}
                              htmlContent={`${d.standard_content}`}
                            />
                          </span>
                        </span>
                      </a>
                    )
                )
              ) : (
                <div className="text-center text-primary">
                  <Spinner />
                </div>
              )}
            </div>
          </div>) : (null)}

          <div className={`col-md-${!isExpanded1 ? "10" : "12"} border border-secondary pt-3 mb-0 pb-0`}>
            <Breadcrumbs aria-label="breadcrumb"
              sx={{
                marginBottom: '10px',
              }}>
              <Typography variant="caption" color="inherit" >
                Section {documentMetaData.section_name}
              </Typography>
              <Typography
                variant="caption"
                color="inherit"
              >
                Chapter {documentMetaData.section_name}.{documentMetaData.chapter_name}.
              </Typography>
              <Typography variant="caption"
                color="primary">
                <HtmlRenderer
                  htmlContent={`${data[activeTab]?.standard_content}`}
                />
              </Typography>
            </Breadcrumbs>
            <div className="row d-flex mb-5">
              <div className="col-md-8">
                <TextFieldCustom
                  className="col-md-4 ms-auto my-auto"
                  required={false}
                  error={false}
                  fullWidth
                  size="small"
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder='Search content here...'
                />
              </div>
              <div className="col-md-2 mt-1">
                {
                  search && search.length > 0 &&
                  <span className='fs-5'>No of Matches: {count}</span>
                }
              </div>
              <div className="col-md-2 mt-1">
                <div className="d-flex justify-content-start">
                  <Tooltip
                    title={
                      !isExpanded ? "Expand Chapter" : "Collapse Chapter"
                    }
                    placement="top"
                  >
                    <span
                      className={`bg-${headerTheme} cursor-pointer list-group-item text-hover-primary border-0 text-center font-custom ms-2`}
                      onClick={() => {
                        handleIsExpanded(!isExpanded);
                        setIsExpanded1((prev) => !prev)
                      }}
                    >
                      {!isExpanded ? (
                        <i className="fa-solid fa-maximize text-primary fs-3"></i>
                      ) : (
                        <i className="fa-solid fa-minimize text-primary fs-3"></i>
                      )}
                    </span>
                  </Tooltip>
                  <Tooltip title={"Open in new tab"} placement="top">
                    <a
                      href={
                        documentID

                          ? `/portal/manual-chapter/${documentID}`
                          : ``
                      }
                      target="_blank"
                      className={`bg-${headerTheme} cursor-pointer list-group-item text-hover-primary border-0 text-center font-custom ms-2`}
                    >
                      <i className="fa-solid fa-up-right-from-square text-primary fs-3"></i>
                    </a>
                  </Tooltip>
                  <Tooltip title={"Download Html"} placement="top">
                    <span onClick={() => getHTMLContent()}
                    className={`bg-${headerTheme} cursor-pointer list-group-item text-hover-primary border-0 text-center font-custom ms-2`}
                    >
                      <i class="fa-solid fa-file-arrow-down text-primary fs-3"></i>
                    </span>
                  </Tooltip>
                  <Tooltip title={"Download Word File"} placement="top">
                    <span onClick={() => handleWordDownload()}
                    className={`bg-${headerTheme} cursor-pointer list-group-item text-hover-primary border-0 text-center font-custom ms-2`}
                    >
                    <i class="fa-solid fa-file-word text-primary fs-3"></i>
                    </span>
                  </Tooltip>
                </div>
              </div>
            </div>

            <div
              data-bs-spy="scroll"
              data-bs-target="#scrollspy-container"
              data-bs-offset="0"
              className="d-flex flex-column overflow-x-hidden overflow-y-auto scrollspy-example"
              tabIndex="0"
              style={{ height: "500px" }}
            >
              <div
                ref={scrollspyContainerRef}
                id="scrollspy-container"
                className="scrollspy-inner overflow-x-hidden overflow-y-auto py-2"
                style={{ height: "465px" }}
              >
                {
                  isMapped ? <>
                    {highlightedData && highlightedData.length > 0 ? (
                      highlightedData.map((d, index) => (
                        <div key={`key-${index}+d.id`}>
                          {d.text_type ===
                            LIST_DETAIL_ELEMENTS.text_types.manual_heading &&
                            LIST_DETAIL_ELEMENTS.text_types.manual_reference &&
                            LIST_DETAIL_ELEMENTS.text_types.manual_appendex &&
                            LIST_DETAIL_ELEMENTS.text_types.manual_biblography &&

                            (
                              <div key={`article-${d.id}+index`} id={`article-${d.id}`}>
                                <HtmlRenderer
                                  tag={"div"}
                                  className={d.class_name ?? "text-center py-4"}
                                  htmlContent={d.standard_content}
                                />
                                {d.html_contents &&
                                  d.html_contents.length > 0 &&
                                  d.html_contents.map((child, ch) => (
                                    <div
                                      key={`art-child-${child.id}` + index + ch}
                                    >
                                      <HtmlRenderer
                                        tag={"div"}
                                        className={child.class_name}
                                        htmlContent={child.html}
                                      />
                                    </div>
                                  ))}
                              </div>
                            )}
                          {(d.text_type === null || d.text_type === undefined) &&

                            (
                              <div key={`article-${d.id}+index`} id={`article-${d.id}`}>
                                <HtmlRenderer
                                  tag={"div"}
                                  className={d.class_name ?? "text-center py-4"}
                                  htmlContent={d.standard_content}
                                />
                                {d.html_contents &&
                                  d.html_contents.length > 0 &&
                                  d.html_contents.map((child, ch) => (
                                    <div
                                      key={`art-child-${child.id}` + index + ch}
                                    >
                                      <HtmlRenderer
                                        tag={"div"}
                                        className={child.class_name}
                                        htmlContent={child.html}
                                      />
                                    </div>
                                  ))}
                              </div>
                            )}
                          {d.text_type ===
                            LIST_DETAIL_ELEMENTS.heading_types
                              .general_heading && (
                              <div key={`general-${d.id}`} id={`${d.id}`}>
                                <HtmlRenderer
                                  tag={"div"}
                                  className="py-4 fw-bold"
                                  htmlContent={d.standard_content}
                                />
                                {d.html_contents &&
                                  d.html_contents.length > 0 &&
                                  d.html_contents.map((child, i) => (
                                    <div
                                      key={`gen-child-${child.id}` + d.id + index}
                                      className="d-flex"
                                    >
                                      <div className="text-justified">
                                        <HtmlRenderer
                                          tag={"div"}
                                          style={{
                                            fontSize: `${props.fontSize}px`,
                                          }}
                                          htmlContent={child.content}
                                        />
                                      </div>
                                    </div>
                                  ))}
                              </div>
                            )}
                          {(d.text_type ===
                            LIST_DETAIL_ELEMENTS.text_types.manual_paragraph || d.text_type === LIST_DETAIL_ELEMENTS.text_types.manual_paragraph
                          ) && (
                              <div key={`general-${d.id}`} id={`${d.id}+index`}>
                                <HtmlRenderer
                                  tag={d.tag_name ?? "div"}
                                  className={d.class_name}
                                  htmlContent={d.standard_content}
                                />
                              </div>
                            )}
                          {[
                            LIST_DETAIL_ELEMENTS.recommendation_types.safe_commodities,
                            LIST_DETAIL_ELEMENTS.recommendation_types.recommendations_for_importations,
                            LIST_DETAIL_ELEMENTS.recommendation_types.inactivation_procedures,
                          ].includes(d.text_type) && (
                              <div id={d.id} key={`recommendation-${d.id}`}>
                                <HtmlRenderer
                                  tag={"div"}
                                  className="py-4 fw-bold"
                                  style={{ fontSize: `${props.fontSize + 2}px` }}
                                  htmlContent={d.standard_content}
                                />
                                {d.html_contents &&
                                  d.html_contents.length > 0 &&
                                  d.html_contents.map((child, ch) => (
                                    <div
                                      key={`rec-child-${child.id}+d.id+ch`}
                                      className="d-flex"
                                    >
                                      <div className="text-justified">
                                        <HtmlRenderer
                                          tag={"div"}
                                          style={{
                                            fontSize: `${props.fontSize}px`,
                                          }}
                                          htmlContent={child.content}
                                        />
                                      </div>
                                    </div>
                                  ))}
                              </div>
                            )}
                        </div>
                      ))
                    ) : (
                      <div className="text-center text-primary">
                        <Spinner />
                      </div>
                    )}</>
                    :
                    <div>Not Mapped</div>
                }

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ManualArticles 
