import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';

const ViewButton = () => {

    const { t } = useTranslation();
    const [currentTab, setCurrentTab] = useState('codes');

    return (
        <>
            <button
                className="mt-5 btn btn--primary font-custom fs-6"
                onClick={() => setCurrentTab('codes')}
            >
                {t("-1", "View")}
            </button>

        </>
    )
}

export default ViewButton
