
import AutoCompleteDropdown from "components/autocomplete/AutoCompleteDropdown";
import { displayNotification, generateOptionsFromData, sendAPIRequest } from "helpers";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { setCurrentPage } from "redux/generalSlice";
import { LIST_DETAIL_ELEMENTS } from "constants";

const CreateChapterTaging = () => {
    const [selectedRadio, setSelectedRadio] = useState({ animal_type: LIST_DETAIL_ELEMENTS.animal_type.terrestrial, standard: LIST_DETAIL_ELEMENTS.standard_type.codes });
    const [selectedOption, setSelectedOption] = useState('');
    const [standardDetails, setStandardDetails] = useState({
        iscode: "",
        isterrestrial: ""
    });

    const { t } = useTranslation();
    const [location, setLocation] = useSearchParams();
    const dispatch = useDispatch();
    const { chapter_id, id, filter_id ,is_modify } = {
        chapter_id: location.get('chapter_id'),
        id: location.get('id'),
        filter_id: location.get('filter_id'),
        is_modify: location.get('is_modify')==='true'?true:false,
    };

    const initForm = {
        animal_id: [],
        disease_id: '',
        RID: '',
        link_id: 1,
    }
    const initFiltersForm = {
        d_filter: [],
        v_filter: [],
        RID: '',
    }

    const [form, setForm] = useState(initForm);
    const [filtersForm, setFiltersForm] = useState(initFiltersForm);
    const [isLoading, setIsLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [animals, setAnimals] = useState([]);
    const [diseases, setDiseases] = useState([]);
    const [dynamicFilters, setDynamicFilters] = useState([]);
    const [filtersValues, setFiltersValue] = useState([]);
    const [chapterDetails, setChaterDetails] = useState({});



    useEffect(() => {
        dispatch(setCurrentPage("administration"));
        // loadData();
        getChaptDetail();
        loadLinkingDiseaseData();
        loadDynamicFiltersData();
        //  getAnimals();
        //  getDiseases();
    }, []);

    useEffect(() => {
        getAnimals();
        getDiseases();
        getDynamicFilters();
    }, [standardDetails])

    useEffect(() => {
        if (filtersForm.d_filter) {
            getDynamicFiltersValues();
        }
    }, [filtersForm.d_filter])

    useEffect(() => {
        if (filter_id) {

            handleGetFIlterDataForUpdate();
        }
    }, [])

    const loadLinkingDiseaseData = async () => {
        if (!chapter_id) return;
        setIsLoading(true);
        let disId = '';
        try {
            let url = ''
            if(is_modify) {
                url= `/api/taging/draft-animal-linking/?draft_structure_id=${chapter_id ?? ""}`
            }else{
                url = `/api/taging/draft-animal-linking/?chapter_id=${chapter_id ?? ""}`
            }
            const ret = await sendAPIRequest(url);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                if (res.data.length > 0) {
                    let animalids = res.data.flatMap(item => item.animal_id);
                    res.data.forEach(element => {
                        if (element && element.disease_id) {
                            disId = element.disease_id;
                            setForm({ ...form, disease_id: disId, animal_id: animalids });
                            return
                        }
                    });
                }
                // setData(res.data);
            }
        } catch (err) {
            console.log({ err });
        }
        setIsLoading(false);
    }

    const loadDynamicFiltersData = async () => {
        if (!chapter_id) return;
        setIsLoading(true);
        try {
            const ret = await sendAPIRequest(`/api/draft-dynamic-filters/draft-chapter-filters/?chapter_id=${chapter_id}`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                if (res.data.length > 0) {
                    handleSetFiltersvalues(res.data);
                }
                // setData(res.data);
            }
        } catch (err) {
            console.log({ err });
        }
        setIsLoading(false);
    }

    const handleSetFiltersvalues = (data = []) => {
        let list_ids = []
        let list_master_ids = []
        data?.forEach(item => {
            list_ids.push(item.list_details.c_id);
            list_master_ids.push(item.list_details.p_id);
        });
        // console.log({list_ids})
        // console.log({list_master_ids})
        setFiltersForm({ ...filtersForm, d_filter: list_master_ids, v_filter: list_ids });
    }

    const handleGetFIlterDataForUpdate = async () => {
        try {
            try {
                const ret = await sendAPIRequest(`/api/dynamic-filters/filter_data/?filter_id=${filter_id}`);
                const res = await ret.json();
                if (res.success && res.status === 200) {
                    // list_master_id
                    setFiltersForm({ ...filtersForm, d_filter: res.data.list_master_id, v_filter: res.data.id });
                } else {

                }
            } catch (err) {
                console.error({ err });
            }
        } catch (error) {
            console.error({ error });
            displayNotification('error', 'Failed to Load Filter Data [F]');
        }
    }

    // const loadData = async () => {
    //     setIsLoading(true);
    //     try {
    //         const ret = await sendAPIRequest(`/api/taging/draft-animal-linking/?chapter_id=${chapter_id}`);
    //         const res = await ret.json();
    //         if (res.success && res.status === 200) {
    //             setData(res.data);
    //         }
    //     } catch (err) {
    //         console.log({ err });
    //     }
    //     setIsLoading(false);
    // }




    // const loadData = async () => {
    //     setIsLoading(true);
    //     try {
    //         // if (chapter_id) {
    //         //     await getDraftAnimalLinking();
    //         // }
    //         // await getChaptDetail();
    //         // await getAnimals();
    //         // await getDiseases();

    //     } catch (err) {
    //         console.error({ err });
    //     }
    //     setIsLoading(false);
    // }
    const returnStnadardSelect = (stan = 5, animal = 7) => {
        if (stan === 5 && animal === 7) {
            return 'terrestrial-code'
        } else if (stan === 5 && animal === 8) {
            return 'aquatic-code'
        } else if (stan === 6 && animal === 7) {
            return 'terrestrial-manual'
        } else return 'aquatic-manual'

    }
    const getChaptDetail = async () => {
        try {
            if (chapter_id) {
                let url = ''
                if(is_modify) {
                    url= `/api/draft-standard-structure/get-chapter/${chapter_id}`
                }else{
                    url=`/api/draft-chapter/get-chapter/${chapter_id}`
                }
                const ret = await sendAPIRequest(url);
                const res = await ret.json();
                if (res.success && res.status === 200) {
                    setChaterDetails(res.data);
                    if (res.data) {
                        setSelectedOption(returnStnadardSelect(res.data.standard_type, res.data.animal_type));
                        setStandardDetails({
                            iscode: res.data.standard_type === LIST_DETAIL_ELEMENTS.standard_type.codes ? 1 : 0,
                            isterrestrial: res.data.animal_type === LIST_DETAIL_ELEMENTS.animal_type.terrestrial ? 1 : 0,
                        });
                    }
                    setForm({
                        ...form,
                        RID: res.data?.RID,
                        draft_chapter_id: !is_modify ? chapter_id:"",
                        draft_structure_id:is_modify ?chapter_id:"",
                    });
                    setFiltersForm({ ...filtersForm, RID: res.data?.RID });
                }
            }
            if (id) {
                const ret = await sendAPIRequest(`/api/taging/draft-animal-linking/${id}`);
                const res = await ret.json();
                if (res.success && res.status === 200) {
                    setForm({
                        ...form,
                        RID: res.data?.RID,
                        animal_id: res.data?.animal_id,
                        disease_id: res.data?.disease_id,
                        link_id: res.data?.link_id,
                        draft_chapter_id: res.data?.draft_chapter_id,
                    });
                }
            }
        } catch (err) {
            console.error({ err });
        }
    }

    const getAnimals = async () => {
        try {
            const ret = await sendAPIRequest(`/api/animals/?iscode=${standardDetails.iscode}&isterrestrial=${standardDetails.isterrestrial}`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                setAnimals([...res.data]);
            } else {
                setAnimals([]);
            }
        } catch (err) {
            console.error({ err });
        }
    }

    const getDiseases = async () => {
        try {
            const ret = await sendAPIRequest(`/api/diseases/?iscode=${standardDetails.iscode}&isterrestrial=${standardDetails.isterrestrial}`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                setDiseases(res.data);
            } else {
                setDiseases([]);
            }
        } catch (err) {
            console.error({ err });
        }
    }

    const getDynamicFilters = async () => {
        try {
            const ret = await sendAPIRequest(`/api/dynamic-filters/`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                setDynamicFilters(res.data);
            } else {
                setDynamicFilters([]);
            }
        } catch (err) {
            console.error({ err });
        }
    }

    const getDynamicFiltersValues = async () => {
        try {
            const ret = await sendAPIRequest(`/api/dynamic-filters/values/?terrestrial=${selectedRadio?.standard === LIST_DETAIL_ELEMENTS.animal_type.terrestrial ? 1 : 0}&code=${selectedRadio?.standard === LIST_DETAIL_ELEMENTS.standard_type.codes ? 1 : 0}`, "POST", {
                d_filters: filtersForm.d_filter,
            });
            const res = await ret.json();
            if (res.success && res.status === 200) {
                setFiltersValue(res.data);
            } else {
                setFiltersValue([]);
            }
        } catch (err) {
            console.error({ err });
        }
    }


    const handleSaveFilter = async () => {
        try {
            let ret = ''
            if (filter_id) {
                ret = await sendAPIRequest(`/api/dynamic-filters/${filter_id ?? ''}`, 'PUT', {
                    filter_id: filtersForm.v_filter ?? "",
                });
            } else {
                ret = await sendAPIRequest(`/api/draft-dynamic-filters/?draft_chapter_id=${chapter_id}`, 'POST', {
                    RID: filtersForm.RID ?? '',
                    filter_id: filtersForm.v_filter ?? "",
                    draft_chapter_id: chapter_id
                });
            }
            const res = await ret.json();
            if (res.success && res.status === 200) {
                displayNotification('success', 'Successfully Linked Dynamic Filter [B]');
                return;
            } else {
                displayNotification('error', 'Failed to save dynamic filters');
            }
        } catch (error) {
            console.log({ error });
            displayNotification('error', 'Failed to save dynamic filters');
        }
    }

    const handleSave = async () => {
        setIsSaving(true);
        try {
            if (!window.confirm('Are you sure you want to perform operation?')) {
                return;
            }
            if (filtersForm.d_filter.length && filtersForm.v_filter.length) {
                await handleSaveFilter();
            }
            if (form.animal_id.length || form.disease_id) {
                let ret;
                if (id) {
                    ret = await sendAPIRequest(`/api/taging/draft-animal-linking/${id}`, 'PUT', form);
                } else {
                    ret = await sendAPIRequest(`/api/taging/draft-animal-linking/?draft_chapter_id=${chapter_id}`, 'POST', form);
                }
                const res = await ret.json();
                if (res.success && res.status === 200) {
                    displayNotification('success', 'Successfully completed request [B]');
                } else {
                    displayNotification('error', 'Failed to complete request [B]');
                }
            }

        } catch (err) {
            console.log({ err });
            displayNotification('error', 'Failed to complete request [F]');
        }
        setIsSaving(false);

        // navigate(-1)
    }

    const animalsOptions = useMemo(() => {
        return generateOptionsFromData(animals, {
            value: "id",
            label: "animal_name",
        });
    }, [animals]);
    const diseasesOptions = useMemo(() => {
        return generateOptionsFromData(diseases, {
            value: "id",
            label: "disease_name",
        });
    }, [diseases]);

    const filtersOptions = useMemo(() => {
        return generateOptionsFromData(dynamicFilters, {
            value: "id",
            label: "list_name",
        });
    }, [dynamicFilters]);
    const filtersValueOptions = useMemo(() => {
        return generateOptionsFromData(filtersValues, {
            value: "id",
            label: "list_name",
        });
    }, [filtersValues]);


    return (
        <div className="container my-2">

            {/* <div className="header card-woah border border-secondary d-flex px-4 py-2 shadow-sm w-100">
                <div className='d-flex justify-content-between w-100'>
                    <div className="card-title1 lh-1">{t("-1", "Chapter Taging Form")}{chapter_id?chapterDetails.chapter_name:""}</div>
                </div>
            </div> */}
            <div className="header card-woah border border-secondary d-flex px-4 py-2 my-2 shadow-sm w-100">
                <div className="d-flex justify-content-between w-100">
                    <div className="card-title1 lh-1">
                        {t("-1", "Chapter Taging Form")}
                        {chapter_id ? (
                            <span className="card-title1" style={{ fontSize: "0.8em" }}> ({chapterDetails.chapter_name})</span>
                        ) : ""}
                    </div>
                </div>
            </div>
            <div className="header card-woah border border-secondary d-flex ps-4 py-2 shadow-sm w-100">
                <div className="row w-100 g-0">
                    <div className="col-md-6">
                        <AutoCompleteDropdown
                            className={isLoading ? 'opacity-50' : 'opacity-100'}
                            disabled={isLoading}
                            label={isLoading ? 'loading...' : t("-1", "Diseases")}
                            showClearIcon={true}
                            error={false}
                            required={false}
                            multiple={false}
                            hasselectall={false}
                            options={diseasesOptions}
                            onChange={(event, value) => {
                                setForm({ ...form, disease_id: value.value });
                            }}
                            value={
                                !form.disease_id
                                    ? { value: '', label: '' }
                                    : diseasesOptions.find((r) => parseInt(r.value) === parseInt(form.disease_id)) ?? { value: '', label: '' }
                            }
                            size='medium'
                        />
                    </div>
                    <div className="col-md-6">
                        <AutoCompleteDropdown
                            className={isLoading ? 'opacity-50' : 'opacity-100'}
                            disabled={isLoading}
                            label={isLoading ? 'loading...' : t("-1", "Animals")}
                            showClearIcon={true}
                            error={false}
                            required={false}
                            multiple={true}
                            hasselectall={false}
                            options={animalsOptions}
                            onChange={(event, value) => {
                                // setForm({ ...form, animal_id: value.value });
                                setForm({ ...form, animal_id: value.map((r) => r.value) });
                            }}
                            value={form.animal_id}
                            size='small'
                        />
                    </div>
                    <div className="col-md-6">
                        <AutoCompleteDropdown
                            className={isLoading ? 'opacity-50' : 'opacity-100'}
                            disabled={isLoading}
                            label={isLoading ? 'loading...' : t("145", "Dynamic Category")}
                            showClearIcon={true}
                            error={false}
                            required={false}
                            multiple={true}
                            hasselectall={false}
                            options={filtersOptions}
                            onChange={(event, value) => {
                                // setFiltersForm({ ...filtersForm, d_filter: value.value });
                                setFiltersForm({ ...filtersForm, d_filter: value.map((r) => r.value) })
                            }}
                            value={filtersForm.d_filter}
                            size='small'
                        />
                    </div>
                    <div className="col-md-6">
                        <AutoCompleteDropdown
                            className={isLoading ? 'opacity-50' : 'opacity-100'}
                            disabled={isLoading}
                            label={isLoading ? 'loading...' : t("-1", "Value")}
                            showClearIcon={true}
                            error={false}
                            required={false}
                            multiple={true}
                            hasselectall={false}
                            options={filtersValueOptions}
                            onChange={(event, value) => {
                                // setFiltersForm({ ...filtersForm, v_filter: value.value });
                                setFiltersForm({ ...filtersForm, v_filter: value.map((r) => r.value) });
                            }}
                            value={filtersForm.v_filter}
                            size='small'
                        />
                    </div>
                    <div className="d-flex justify-content-end mt-3 px-4">
                        <button
                            className="btn btn--primary font-custom fs-6"
                            onClick={() => {
                                handleSave();

                            }}
                            disabled={isLoading || isSaving}
                        >
                            {
                                id || filter_id ? "Update" : "Save"
                            }
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CreateChapterTaging;
