import React, { useEffect, useMemo, useState } from 'react'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { LIST_DETAIL_ELEMENTS } from 'constants';
import { displayNotification, hasPermissionForAquaticCode, hasPermissionForAquaticManual, hasPermissionForTerrestrialCode, hasPermissionForTerrestrialManual, sendAPIRequest } from 'helpers';
import { setCurrentPage } from 'redux/generalSlice';
import PopupAlert from 'components/common/PopupAlert';
import usePopupAlert from 'hooks/usePopupAlert';
import { useDispatch, useSelector } from 'react-redux';
import StructureNavigationComponent from '../../StructureNavigationComponent';
import Container from 'components/common/Container';
import CustomCard from 'components/common/CustomCard';
import CardHeader from 'components/common/CardHeader';
import CardBody from 'components/common/CardBody';
import Button from 'components/common/Button';

const Draft = () => {

  const [structureChange, setStructureChange] = useState(false);

  const [componentLanguage, setComponentLanguage] = useState(LIST_DETAIL_ELEMENTS.languages.english);
  const authData = useSelector(s => s.auth?.data);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState({
    loading_filters: false,
    loading_data: false,
    saving: false,
    subHeading: false,
  });
  const { showPopup, popupDetail, showPopupAlert, handleClose } = usePopupAlert();
  const [metaData, setMetaData] = useState(null);

  useEffect(() => {
    dispatch(setCurrentPage('administration'));
  }, []);

  const handleSaveAsDraft = async () => {
    try {
      if (!window.confirm('Are you sure you want to save this chapter as draft?')) {
        return
      }
      if (!metaData?.standard_type || !metaData?.animal_type || (metaData?.section?.section_no === undefined || metaData?.section?.section_no === null) || !metaData?.section?.section_name || (metaData?.chapter?.chapter_no === undefined || metaData?.chapter?.chapter_no === null) || !metaData?.chapter?.chapter_name
        || !metaData?.language_id
      ) {
        displayNotification('info', 'Please fill all fields!');
        return;
      }
      setIsLoading({ ...isLoading, saving: true });
      const info = {
        // chapter_id: metaData?.chapter?.id,
        standard_type: metaData?.standard_type,
        animal_type: metaData?.animal_type,
        area_vol_no: metaData?.volume?.area_vol_no ?? metaData?.chapter?.area_vol_no ?? null,
        area_vol_name: metaData?.volume?.area_vol_name ?? metaData?.chapter?.area_vol_name ?? null,
        section_no: metaData?.section?.section_no ?? metaData?.chapter?.section_no ?? null,
        section_name: metaData?.section?.section_name ?? metaData?.chapter?.section_name ?? null,
        chapter_no: metaData?.chapter?.chapter_no,
        chapter_name: metaData?.chapter?.chapter_name,
        subsection_id: metaData?.sub_section?.id ?? '',
        lang_id: metaData?.language_id,
        RID: metaData?.chapter?.RID,
        is_structure_change: structureChange
      };
      const ret = await sendAPIRequest(
        `/api/draft-chapter`,
        'POST',
        info
      );
      const res = await ret.json();
      if (res.success && res.status === 200) {
        const isCode = metaData?.standard_type === LIST_DETAIL_ELEMENTS.standard_type.codes;
        // showPopupAlert('Success', `Successfully saved chapter as draft [x].</br>Go to<a target="_blank" href="${BASE_URL}/portal/draft/edit/${res.data.id}/?is_code=${isCode}"> editing</a>.`, 'success');
        displayNotification('success', 'Successfully saved chapter as draft [x].');
        // navigate(``)
        // window.open(`/portal/draft/edit/${res.data.id}/?is_code=${isCode}`, '_blank'); 
        navigate(`/portal/draft/edit/${res.data.id}/?is_code=${isCode}`)
      } else {
        displayNotification('error', res.message);
      }
      setIsLoading({ ...isLoading, saving: false });
    } catch (err) {
      displayNotification('error', 'Failed to save chapter as draft');
      setIsLoading({ ...isLoading, saving: false });
    }
  }

  const handleChangeForRadioButtons = (event) => {
    const value = event.target.value
    if (value == 'Minor') {
      setStructureChange(false);
    }
    else {
      setStructureChange(true);
    }
  }

  return (
    <Container>
      <PopupAlert show={showPopup} variant={popupDetail.variant} title={popupDetail.title} body={popupDetail.body} handleClose={handleClose} />
      <CustomCard className={'d-flex justify-content-between'}>
        <CardHeader>
          {t("341", "Modify Existing Chapter")}
        </CardHeader>
        <CardBody>
          <Button
            onClick={() => navigate('/portal/draft/index')}
          >
            {t("162", "Back")}
          </Button>
        </CardBody>
      </CustomCard>
      <StructureNavigationComponent
        config={{
          is_display_standard_heading: true,
          is_display_structures: true,
          is_display_area: false,
          is_display_sections: true,
          is_display_chapters: true,
          is_display_subsections: true,
          is_display_languages: true,
          is_display_font_changer: false,
        }}
        reFetch={componentLanguage}
        reloadData={(state) => setMetaData(state)}
      />
      <CustomCard className={'d-flex justify-content-between'}>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          className="text-center d-flex justify-content-between"
          onChange={handleChangeForRadioButtons}
          value={structureChange ? 'New' : 'Minor'}
        >
          <FormControlLabel
            className="px-5 mb-2"
            value="New"
            control={<Radio />}
            label={"Adopted Change"}
          />
          <FormControlLabel
            className="px-5 mb-2"
            value="Minor"
            control={<Radio />}
            label={"Editorial Change​"}
          />
        </RadioGroup>
        <CardBody>
          <Button
            disabled={isLoading.saving || (!hasPermissionForTerrestrialCode(authData) && !hasPermissionForTerrestrialManual(authData) && !hasPermissionForAquaticCode(authData) && !hasPermissionForAquaticManual(authData))}
            onClick={() => handleSaveAsDraft()}
          >
            {isLoading.saving ? t("-1", "Saving...") : t("180", "Save")}
          </Button>
        </CardBody>
      </CustomCard>
    </Container>
  )
}

export default Draft


