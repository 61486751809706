import { Card } from '@mui/material'
import React from 'react'

const themes = {
    header: {
        color: '#000',
        backgroundColor: '#fff',
        border: '1px solid #eaeaea',
        marginY: '10px',
        padding: '.5rem 1rem',
        borderRadius: '0px',
        boxShadow: '0 0.1rem 1rem 0.25rem rgba(0, 0, 0, 0.05)',
    },
    innerHeader: {
        color: '#000',
        borderBottom: '1px solid #eaeaea',
        padding: '.5rem 1rem',
        marginY: '5px',
    }
};

function CustomCard(props) {
    return (
        <Card
            {...props}
            sx={{
                ...themes[props.type],
                ...props.sx,
            }}
        />
    )
}

CustomCard.defaultProps = {
    type: 'primary',
    sx: {}
};

export default CustomCard