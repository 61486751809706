import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useTranslation } from 'react-i18next';
import { Divider, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState } from 'react';
import TextFieldCustom from 'components/TextFieldCustom';
import CustomCard from 'components/common/CustomCard';
import CardHeader from 'components/common/CardHeader';
import CardBody from 'components/common/CardBody';
import { displayNotification, sendAPIRequest } from 'helpers';

function EquivalentModal({ open, handleClose, id, componentLanguage = '', standardDetails = {}, loadGlossary = async () => { }, isExclude = false, glossaryTerm }) {
    const { t } = useTranslation();
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '40%',
        height: 'auto',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        overflowY: 'auto',
        padding: 0,
    };

    const [formFields, setFormFields] = useState([
    ])
    const [term, setTerms] = useState('')

    const handleAddFields = () => {
        setFormFields([
            ...formFields,
            { en_term: "" },
        ]);
    };

    const handleCloseModal = () => {

        handleClose();
        setFormFields([]);
    };

    const handleRemoveFields = (index) => {
        const updatedFormFields = [...formFields];
        updatedFormFields.splice(index, 1);
        setFormFields(updatedFormFields);
    };

    const addEqvivalentTerms = async () => {
        try {
            if (!term) {
                displayNotification('warning', 'Please enter a term');
                return;
            }
            const data = modifyData(formFields);
            const ret = await sendAPIRequest(`/api/draft-glossaries/include-terms`, "POST", data);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                await loadGlossary();
                handleCloseModal();
                setFormFields([]);
                setTerms('');
                displayNotification('success', 'Successfully Added terms [F]');
            } else {
                displayNotification('error', 'Failed to Added terms [F]');
            }
        } catch (error) {
            console.error(error);
            displayNotification("error", "Failed To Add Eqvivalent Terms [F]")
        }
    }


    const handleInputChange = (index, fieldName) => (event) => {
        const updatedFields = [...formFields];
        updatedFields[index][fieldName] = event.target.value;
        setFormFields(updatedFields);
    };

    const modifyData = (data = []) => {
        let obj = {};
        if (term) {
            obj = {
                txt: term,
                standard_type: standardDetails.standard_type,
                animal_type: standardDetails.animal_type,
                lang_id: componentLanguage,
                draft_glossary_id: id,
                is_excluded: isExclude,
            }
        }
        let newData = data.map((x) => {
            if (x.en_term) {
                return {
                    txt: x.en_term,
                    standard_type: standardDetails.standard_type,
                    animal_type: standardDetails.animal_type,
                    lang_id: componentLanguage,
                    draft_glossary_id: id,
                    is_excluded: isExclude,

                }
            }
        })
        if (obj && obj.txt) {
            newData.push(obj);
        }
        return newData;
    }
    return (
        <>
            <Modal
                open={open}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box sx={{ padding: '10px 20px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            {
                                !isExclude ? "Add Equivalent Term" : 'Add Exclude Term'
                            }
                            {/* <span className='ms-2'>
                                {glossaryTerm}
                            </span> */}


                        </Typography>
                        <IconButton onClick={handleCloseModal}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Divider sx={{ backgroundColor: '#000', margin: '0' }} />
                    <Box sx={{ paddingX: '20px', paddingBottom: '20px', paddingTop: '5px' }}>
                        <div className='row pt-2'>
                            <div className="d-flex justify-content-start mb-3">
                                <div className='me-2'>
                                    <Typography variant="h7" sx={{ fontWeight: 'bold' }}>
                                        Glossary Term:
                                    </Typography>
                                </div>
                                <div>
                                    <Typography variant="h7">
                                        {glossaryTerm}
                                    </Typography>
                                </div>
                            </div>
                            <div className='col-11 mb-3'>
                                <TextFieldCustom
                                    variant="outlined"
                                    id="Chapter"
                                    label={t("-1", "Add Term")}
                                    fullWidth
                                    rows={4}
                                    required={true}
                                    sx={{
                                        backgroundColor: "white",
                                        color: "white",
                                        border: "#ff4815",
                                        width: "99%",
                                    }}
                                    size='small'
                                    value={term}
                                    onChange={(e) => setTerms(e.target.value)}
                                />
                            </div>
                            <div className='col-1 mb-3 text-start'>
                                <IconButton onClick={handleAddFields}
                                    aria-label="add"
                                    style={{ marginLeft: '10px', fontSize: '30px' }}
                                >
                                    <i className="fa-solid fa-circle-plus" style={{ fontSize: "20px", color: '#04AA6D' }}></i>
                                </IconButton>
                            </div>
                            {formFields.map((field, index) => (
                                <>
                                    <div className='col-11 mb-3'>
                                        <TextFieldCustom
                                            variant="outlined"
                                            id={index + 'en'}
                                            label={t("-1", "Term")}
                                            sx={{
                                                backgroundColor: "white",
                                                color: "white",
                                                border: "#ff4815",
                                                width: "99%",
                                            }}
                                            size='small'
                                            value={field.en_term}
                                            onChange={handleInputChange(index, 'en_term')}
                                        />
                                    </div>
                                    <div className='col-1 mb-3 text-start'>
                                        <IconButton
                                            onClick={() => handleRemoveFields(index)}
                                            aria-label="add"
                                            style={{ marginLeft: '10px', fontSize: '30px' }}
                                        >
                                            <i className="fa-solid fa-circle-minus" style={{ fontSize: "20px", color: 'red' }}></i>
                                        </IconButton>
                                    </div>
                                </>
                            ))}
                        </div>
                        <div className='row'>
                            <div className="col-12 text-center mt-3 mb-3">
                                <button
                                    className="btn btn--primary  font-custom fs-6 me-2"
                                    onClick={() => {
                                        handleCloseModal()
                                    }}
                                >
                                    {t("-1", "Close")}
                                </button>
                                <button
                                    className="btn btn--outline font-custom fs-6"
                                    onClick={() => {
                                        addEqvivalentTerms()
                                    }}
                                >
                                    {t("-1", "Save")}
                                </button>
                            </div>
                        </div>
                    </Box>
                </Box>
            </Modal>
        </>
    )
}

export default EquivalentModal
