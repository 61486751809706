import * as React from 'react';
import { useState, useRef, useEffect } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import ClassicEditor from 'components/text_editor/CustomCKEditor';
import { Typography } from '@mui/material';
import { displayNotification, sendAPIRequest } from 'helpers';
import Spinner from 'components/spinner/Spinner';


function EditFootnotesModal({ open = false, handleClose, footNotesData, reloadFootnotes }) {
    const [chapterInfo, setChapterInfo] = useState({});
    const [editorData, setEditorData] = useState(footNotesData.defination);
    const [editorLoading, setEditorLoading] = useState(false);
    const editorRef = useRef(null);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '60%',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 2,
        overflowY: 'auto',
    };

    useEffect(() => {
        if (footNotesData) {
            setEditorLoading(true);
            setTimeout(() => {
                setEditorLoading(false); 
            }, 1000);
        }
    }, []);

    const handleSave = async () => {
        if (!window.confirm('Are you sure you want to edit this footnote?')) {
            return;
        }
        // setIsLoading(true);
        try {
            const ret = await sendAPIRequest(`/api/foot-note/edit-foot-notes/${footNotesData?.id}`, 'PUT', {
                defination: editorData
            });
            const res = await ret.json();
            if (res.success && res.status === 200) {
                displayNotification("success", "Footnote updated Successfully");
                reloadFootnotes();
                handleClose(false);
                // await loadData(standardDetails.standard_type, standardDetails.animal_type);
            } else {
                displayNotification('error', res.message);
            }
        } catch (error) {
            console.log({ error });
            displayNotification("error", "Internall Server Error [F]")
        }
        // finally {
        //     setIsLoading(false);
        // }
    }


    return (
        <Modal
            open={open}
            onClose={() => handleClose(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Edit Footnotes
                    </Typography>
                    <IconButton onClick={() => handleClose(false)}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Divider sx={{ backgroundColor: '#000', margin: '10px 0' }} />
                {!editorLoading && (
                    <ClassicEditor
                        editorData={editorData}
                        setEditorData={setEditorData}
                        customConfig={{
                            chapterId: chapterInfo.id
                        }}
                        disable={true}
                    />)
                }
                {
                    editorLoading && (
                        <Spinner message="Loading editor..." />
                    )
                }
                <Box>
                    <div className='row'>
                        <div className="col-12 text-center mt-3">
                            <button
                                className="btn btn--outline font-custom fs-6 me-2"
                                onClick={() => handleClose(false)}
                            >
                                Close
                            </button>
                            <button
                                className="btn btn--primary font-custom fs-6"
                                onClick={() => handleSave()}
                            // disabled={isLoading}
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </Box>
            </Box>
        </Modal>
    );
}

export default EditFootnotesModal;
