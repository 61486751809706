import { Replay } from '@mui/icons-material';
import { Box, CircularProgress, Typography } from '@mui/material';
import ActionButton from 'components/common/ActionButton';
import Container from 'components/common/Container';
import CustomCard from 'components/common/CustomCard';
import Loader from 'components/common/Loader';
import CustomChip from 'components/CustomChip';
import { displayNotification, getBaseURL, sendAPIRequest } from 'helpers';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsInfoCircleFill } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setCurrentPage } from 'redux/generalSlice';

const PublishingStandards = () => {
    const { t } = useTranslation();
    const authData = useSelector(s => s.auth.data);
    const [publisherPermission, setPublisherPermission] = useState();
    const [isPublishDisabled, setIsPublishDisabled] = useState(false);
    const [roleId, setRoleId] = useState();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [standardData, setStandardData] = useState([]);
    const [isLoading, setIsLoading] = useState({
        load_standard: false,
    });
    const [isEnabled, setIsEnabled] = useState(false);
    const [isMigrating, setIsMigrating] = useState(false);

    useEffect(() => {
        dispatch(setCurrentPage('publishing-standards'));
        setPublisherPermission(authData?.is_publisher);
        setRoleId(authData?.role_id);
    }, []);

    useEffect(() => {
        getPublishingStandards();
        const interval = setInterval(() => {
            getPublishingStandards();

        }, 30 * 1000);
        return () => clearInterval(interval);
    }, []);

    const getPublishingStandards = async () => {
        setIsLoading({ ...isLoading, load_standard: true });
        try {
            const ret = await sendAPIRequest(`/api/structure/standard-types`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                setStandardData(res.data);
                await findStatus(res.data)
            } else {
                displayNotification("error", "Failed to get Publishing Standards");
            }
            setIsLoading({ ...isLoading, load_standard: false });
        } catch (e) {
            console.log({ e });
        }
    };

    const draftmigrationCatalogs = async () => {
        setIsMigrating(true);
        if (!window.confirm('Are you sure you want to migrate Catalogs?')) {
            return;
        }
        try {
            const ret = await sendAPIRequest(`/api/handle-drafting/catalog-migration`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                setStandardData([]);
                await getPublishingStandards();
            } else {
                displayNotification('error', res.message);
            }
        } catch (error) {
            console.log({ error });
            displayNotification("error", "Internall Server Error [F]")
        } finally {
            setIsMigrating(false);
        }
    }

    const areAllStatusesValidated = () => {
        return standardData.every((item) =>
            item?.english_status === 'Validated' &&
            item?.french_status === 'Validated' &&
            item?.spanish_status === 'Validated'
        );
    };
    const prePublishCatalogs = async () => {
        if (!window.confirm('Are you sure you want to Pre-Publish Catalogue?')) {
            return;
        }
        try {
            const ret = await sendAPIRequest(`/api/handle-drafting/catalog-migration-with-year`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                setStandardData([]);
                await getPublishingStandards();
                displayNotification("success", "Catalogs published successfully.");
                setIsEnabled(true);
            } else {
                displayNotification('error', res.message);
            }
        } catch (error) {
            console.log({ error });
            displayNotification("error", "Internal Server Error");
        }
    };

    const getColorClass = (structureCode) => {
        switch (structureCode) {
            case "TC":
                return "btn-terrestrial-code";
            case "TM":
                return "btn-terrestrial-manual";
            case "AC":
                return "btn-aquatic-code";
            case "AM":
                return "btn-aquatic-manual";
            default:
                return "btn-default";
        }
    };

    const getColorDisabledClass = (structureCode) => {
        switch (structureCode) {
            case "TC":
                return "btn-terrestrial-code-default";
            case "TM":
                return "btn-terrestrial-manual-default";
            case "AC":
                return "btn-aquatic-code-default";
            case "AM":
                return "btn-aquatic-manual-default";
            default:
                return "btn-default";
        }
    };

    const handleClickStandard = async (standard_detail = {}) => {
        try {
            if (standard_detail.structure_status === 1) {
                await handlemigrate(standard_detail.id);
            } else if (standard_detail.structure_status === 2) {
                displayNotification("warning", "Migration is in progress");
            } else {
                navigate(`/portal/publishing-standards-details?id=${standard_detail?.id}`);
            }
        } catch (error) {
            console.log({ error });
        }
    }


    const handlemigrate = async (id = '') => {
        if (!window.confirm("Do You Want To Migrate Standard")) {
            return;
        }
        try {
            const ret = await sendAPIRequest(`/api/handle-drafting/`, "POST", {
                standard_type_id: id,
            });
            const res = await ret.json();
            if (res.success && res.status === 200) {
                displayNotification("success", "Migration initiated successfully.");
                setStandardData([]);
                await getPublishingStandards();
            }
        } catch (e) {
            console.log({ e });
        }
    };

    const finalPublishing = async () => {
        if (!window.confirm('Are you sure you want to publish?')) {
            return;
        }
        setIsLoading(true);
        setIsPublishDisabled(true);
        try {
            const ret = await sendAPIRequest(`/api/handle-drafting/final-publication`, 'POST');
            const res = await ret.json();
            if (res.success && res.status === 200) {
                displayNotification('success', 'Process created will finished soon')
            } else {
                displayNotification('error', res.message);
            }
        } catch (e) {
            console.log({ e });
        } finally {
            setIsLoading(false);
        }
    }

    const handleRevertStandard = async (standard_type_id) => {
        if (!window.confirm('Are you sure you want to revert this standard to draft?')) {
            return;
        }
        try {
            const ret = await sendAPIRequest(`/api/handle-drafting/revert-standard-to-draft/`, 'POST', {
                standard_type_id
            });
            const res = await ret.json();
            if (res.success && res.status === 200) {
                await getPublishingStandards();
            }
        } catch (e) {
            console.log(e);
            displayNotification('error', 'Failed to revert the standard to draft');
        }
    }

    const findStatus = async (data) => {
        try {
            const ret = await sendAPIRequest(`/api/doc-transfer/get-status-permission-draft/`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                const tem = modifyStandardData(res.data, data)
                if (tem.length > 0) {
                    setStandardData(tem);
                }
            }
        } catch (e) {
            console.log(e);
            displayNotification('error', 'Failed to revert the standard to draft');
        }
    }

    const modifyStandardData = (data = [], standrdData = []) => {
        const returnData = standrdData?.map((x) => {
            const y = data.find((z) => z.id === x.id);
            return {
                ...x,
                status_data: y
            }
        })
        return returnData;
    }


    return (
        <Container>
            <div className="card card-woah w-100 border shadow-sm rounded-0 px-4 py-2 my-2">
                <div className="row g-0">
                    <div className="col-md-4">
                        <div className="card-title1 lh-1 ">
                            {t("-1", "Global Publication Management")}
                        </div>
                    </div>
                    <div className="col-md-8 d-flex justify-content-end align-items-center">
                        {![undefined, null, 0].includes(standardData[0]?.catalog_status) ? (
                            <button
                                className="btn btn-primary font-custom fs-6 ms-1"
                                onClick={() => window.open(`/portal/catalog/?is_draft=true&status=${standardData[0]?.catalog_status}`, '_blank')}
                                disabled={standardData[0]?.catalog_status !== 1}
                            >
                                {t("-1", "Modify Catalogue")}
                            </button>
                        ) : (
                            <button
                                className="btn btn-primary font-custom fs-6 ms-1"
                                onClick={async () => draftmigrationCatalogs()}
                                disabled={![undefined, null, 0].includes(standardData[0]?.catalog_status)}
                            >
                                {t("-1", "Migrate Catalogue")}
                            </button>
                        )}
                        {![undefined, null, 0].includes(standardData[0]?.catalog_status) && (
                            <button
                                className="btn btn--primary font-custom fs-6 ms-1"
                                onClick={async () => prePublishCatalogs()}
                                disabled={standardData[0]?.catalog_status !== 1}
                            >
                                {t("-1", "Close Catalogue")}
                            </button>
                        )}
                    </div>
                </div>
            </div>
            <CustomCard >
                <div className="row justify-content-start"
                    disabled={[undefined, null, 0, 1].includes(standardData[0]?.catalog_status)}
                >
                    {standardData?.map((item) => {
                        const colorClass = getColorClass(item.structure_code);
                        const isDisabled = [undefined, null, 0, 1].includes(item.catalog_status);
                        const disabledColorClass = getColorDisabledClass(item.structure_code);

                        return (

                            <div key={item.id} className="col-md-3 col-sm-6 col-12">
                                <div className={`${isDisabled ? '' : 'st-card'} `}>
                                    <span
                                        className={`d-block text-center ${isDisabled ? disabledColorClass : colorClass} my-2 py-4 px-3 shadow-sm ${isDisabled ? '' : 'cursor-pointer'}`}
                                        onClick={!isDisabled ? () => handleClickStandard(item) : null}
                                        style={{
                                            pointerEvents: isDisabled ? "none" : "auto",
                                            opacity: isDisabled ? 0.6 : 1
                                        }}
                                    >
                                        <span className="d-block py-15 card-title3">{item.structure_name.toUpperCase()}</span>
                                        <img
                                            src={getBaseURL("/assets/icons/links-bar.png")}
                                            alt="Hover Image"
                                            className="hover-image"
                                        />
                                    </span>
                                </div>
                                <div className={item?.migrated_year !== null ? 'custom-card-front mb-2' : ''}>
                                    {item?.migrated_year !== null ? (
                                        <div>
                                            <h6>
                                                EN:
                                                <span className="ps-10">
                                                    {item?.status_data?.in_eng ? (
                                                        <CustomChip
                                                            type={
                                                                item?.structure_status === 2
                                                                    ? 'red'
                                                                    : item?.structure_status === 3
                                                                        ? 'blue'
                                                                        : item?.structure_status === 5
                                                                            ? 'green'
                                                                            : 'primary'
                                                            }
                                                            label={item.status_data.in_eng}
                                                        />
                                                    ) : (
                                                        <CircularProgress size={12} />
                                                    )}
                                                </span>
                                            </h6>
                                            <h6>
                                                FR:
                                                <span className="ps-10">
                                                    {item?.status_data?.in_fr ? (
                                                        <CustomChip
                                                            type={
                                                                item?.structure_status === 2
                                                                    ? 'red'
                                                                    : item?.structure_status === 3
                                                                        ? 'blue'
                                                                        : item?.structure_status === 5
                                                                            ? 'green'
                                                                            : 'primary'
                                                            }
                                                            label={item.status_data.in_fr}
                                                        />
                                                    ) : (
                                                        <CircularProgress size={12} />
                                                    )}
                                                </span>
                                            </h6>
                                            <h6>
                                                SP:
                                                <span className="ps-10">
                                                    {item?.status_data?.in_sp ? (
                                                        <CustomChip
                                                            type={
                                                                item?.structure_status === 2
                                                                    ? 'red'
                                                                    : item?.structure_status === 3
                                                                        ? 'blue'
                                                                        : item?.structure_status === 5
                                                                            ? 'green'
                                                                            : 'primary'
                                                            }
                                                            label={item.status_data.in_sp}
                                                        />
                                                    ) : (
                                                        <CircularProgress size={12} />
                                                    )}
                                                </span>
                                            </h6>
                                        </div>
                                    ) : null}
                                </div>
                                {roleId === 2 ? (
                                    item?.migrated_year !== null && (
                                        <CustomChip
                                            label={
                                                <Box
                                                    display="flex"
                                                    alignItems="center"
                                                    sx={{
                                                        fontSize: '14px',
                                                    }}
                                                >
                                                    <Replay sx={{ marginRight: 1, fontSize: '20px' }} />
                                                    {item.structure_status === 8 ? (
                                                        <Loader message="Reverting..." style={{ color: "#fff" }} />
                                                    ) : (
                                                        "Revert Standard"
                                                    )}
                                                </Box>
                                            }
                                            type="red"
                                            onClick={() => handleRevertStandard(item.id)}
                                            disabled={item.structure_status === 8}
                                            sx={{
                                                width: "100%",
                                                marginY: '5px',
                                            }}
                                        />
                                    )
                                ) : null}
                            </div>
                        );
                    })}
                </div>
                <div className="row py-4 px-2">
                    <div className="col-md-12 d-flex justify-content-end">
                        {publisherPermission === true ?
                            <button className='btn btn--primary font-custom fs-6 ms-1 '
                                onClick={() => { finalPublishing() }}
                                disabled={!areAllStatusesValidated() || isPublishDisabled}
                            >
                                Publish Standards
                            </button>
                            : null}
                    </div>
                </div>
            </CustomCard>
        </Container>
    );
};


export default PublishingStandards;
