import ThreeDotsDropdown from "components/ThreeDotsDropdown";
import React from "react";

const HTMLDropDownMenu = ({className, menuList, id, handleMenuClick, index}) => {
    return (
        <ThreeDotsDropdown
                options={[
                    ...menuList.map(list => 
                        (className) => (
                            <div
                                    className={className}
                                    onClick={() => {
                                        handleMenuClick(id, list.id, index);
                                    }}
                                >
                                    {list.label}
                                </div>
                        ),
                    )
                ]}
                className={`text-primary fs-5 ms-2 ${className}`}
                isVertical={true}
            />
    );
}

HTMLDropDownMenu.defaultProps = {
    menuList: [],
    id: null,
    handleMenuClick: () => {},
    className: '',
    index: null
}

export default HTMLDropDownMenu;