import ThreeDotsDropdown from "components/ThreeDotsDropdown";
import React from "react";

const StylesDropDownMenu = ({styleList, id, is_content, styleHandler}) => {
    return (
        <ThreeDotsDropdown
                options={[
                    ...styleList.map(list => 
                        (className) => (
                            <div
                                    className={className}
                                    onClick={() => {
                                        styleHandler(id, list.id, is_content);
                                    }}
                                >
                                    Mark as {list.list_element_name}
                                </div>
                        ),
                    )
                ]}
                className="text-primary fs-5 ms-2"
                isVertical={true}
            />
    );
}

StylesDropDownMenu.defaultProps = {
    styleList: [],
    id: null,
    is_content: true,
    styleHandler: () => {}
}

export default StylesDropDownMenu;