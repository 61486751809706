import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { setCurrentPage, setHeaderTheme } from '../../../../redux/generalSlice';
import { useTranslation } from "react-i18next";

const SearchCommodities = () => {

    const dispatch = useDispatch();
    const { t } = useTranslation();

    // useEffect(() => {
    //     dispatch(setHeaderTheme('white'));
    //     dispatch(setCurrentPage("form"));
    // }, []);
    
    return (
        <>
            <div className="container my-2">
                <div className="header card-woah border border-secondary px-4 py-2 shadow-sm w-100 my-2">
                    <div className='d-flex justify-content-between w-100'>
                        <div className="card-title1 lh-1">{t("-1", "Dynamic search and access to the information")}</div>
                    </div>
                 
                </div>
            </div>
        </>
    )
}

export default SearchCommodities;
