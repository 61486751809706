import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';
import { sendAPIRequest, displayNotification } from 'helpers';

export default class CustomFootNotePlugin extends Plugin {
    init() {
        const editor = this.editor;
        const chapterId = editor.config.get('chapterId')

        // Allow custom attributes on the model schema
        editor.model.schema.extend('$text', {
            allowAttributes: ['data-footnoteid', 'footnoteStyle']
        });

        // Downcast the footnote attribute to a `span` in the view
        editor.conversion.for('downcast').attributeToElement({
            model: 'data-footnoteid',
            view: (attributeValue, { writer }) => {
                return writer.createAttributeElement('span', {
                    'data-footnoteid': attributeValue,
                    class: 'footnote-style'
                });
            }
        });

        editor.ui.componentFactory.add('footnote', locale => {
            const view = new ButtonView(locale);
            view.set({
                label: 'Insert Footnote',
                withText: true,
                tooltip: true
            });

            view.on('execute', () => {
                editor.model.change(writer => {
                    const footnoteText = prompt('Enter the footnote text:');
                    const selection = editor.model.document.selection;
                    const selectedTextRange = selection.getFirstRange();

                    if (!selectedTextRange || selectedTextRange.isCollapsed) {
                        alert('Please select text to add a footnote.');
                        return;
                    }

                    const uniqueHash = Math.random().toString(36).substr(2, 9);

                    let selectedText = '';
                    for (const item of selectedTextRange.getItems()) {
                        selectedText += item.data;
                    }

                    this.createFootNote({
                        rid: chapterId,
                        element_id: uniqueHash,
                        defination: footnoteText,
                        selected_text: selectedText
                    });

                    writer.setAttribute('data-footnoteid', uniqueHash, selectedTextRange);
                    writer.setAttribute('footnoteStyle', true, selectedTextRange);
                    console.log(`Footnote added with ID`);
                });
            });

            return view;
        });

        // Call function to add persistent event listener
        this.addFootnoteClickListener();
    }

    async createFootNote(body) {
        try {
            const footNote = await sendAPIRequest(`/api/foot-note/create`, 'POST', body);
            const res = await footNote.json();
            if (res.success && res.status === 200) {
                return res.data;
            } else {
                displayNotification("error", "Failed to create Footnote");
            }
        } catch (error) {
            console.error("Error creating footnote:", error);
        }
    }

    // Function to show popup for footnotes
    async showFootnotePopup(footnoteId) {
        let popup = document.querySelector('.footnote-popup');
        if (!popup) {
            popup = document.createElement('div');
            popup.className = 'footnote-popup';
            popup.style.position = 'absolute';
            popup.style.backgroundColor = '#fff';
            popup.style.border = '1px solid #ccc';
            popup.style.padding = '10px';
            document.body.appendChild(popup);
        }

        popup.textContent = `Fetching footnote...`;

        const fetchNote = await this.fetchFootnote(footnoteId);
        if (fetchNote) {
            popup.textContent = fetchNote.defination;
        } else {
            popup.textContent = 'Failed to fetch footnote.';
        }

        const updatePopupPosition = (event) => {
            const popupWidth = popup.offsetWidth;
            const popupHeight = popup.offsetHeight;
            let left = event.pageX + 10;
            let top = event.pageY + 10;

            if (left + popupWidth > window.innerWidth) {
                left = window.innerWidth - popupWidth - 10;
            }
            if (top + popupHeight > window.innerHeight) {
                top = window.innerHeight - popupHeight - 10;
            }

            popup.style.left = `${left}px`;
            popup.style.top = `${top}px`;
            popup.style.zIndex = '1000';
        };

        window.addEventListener('mousemove', updatePopupPosition);

        window.addEventListener('click', function removePopup() {
            popup.remove();
            window.removeEventListener('mousemove', updatePopupPosition);
            window.removeEventListener('click', removePopup);
        });
    }

    async fetchFootnote(footnoteId) {
        try {
            const footNote = await sendAPIRequest(`/api/foot-note/find/${footnoteId}`);
            const res = await footNote.json();
            if (res.success && res.status === 200) {
                return res.data;
            } else {
                displayNotification("error", "Failed to fetch Footnote");
            }
        } catch (error) {
            console.error("Error fetching footnote:", error);
        }
      }

    // Function to add click listener to footnote elements in the view
    addFootnoteClickListener() {
        const editor = this.editor;
        
        // Listen to the editing view to add event listeners for elements with `data-footnoteId`
        editor.editing.view.document.on('click', (evt, data) => {
            const clickedElement = data.target;

            if (clickedElement.hasAttribute('data-footnoteid')) {
                const footnoteId = clickedElement.getAttribute('data-footnoteid');
                this.showFootnotePopup(footnoteId);
                data.preventDefault();
            }
        });
    }
}
