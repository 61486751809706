import React, { useState } from 'react'
import { useEffect } from 'react';

import logo from "images/WOAH_ANNIVERSARY_PRIMARY LOGO_ORANGE + GOLD_ENG.png";
import { useLocation, useSearchParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { Link } from 'react-router-dom';
import html2pdf from 'html2pdf.js';
import { Chip } from '@mui/material';
import { BsExclamationTriangleFill, BsInfoCircleFill } from 'react-icons/bs';
import Spinner from 'components/spinner/Spinner';
import { useTranslation } from 'react-i18next';
import HtmlRenderer from 'components/HtmlRenderer';
import useLocalStorage from 'hooks/useLocalStorage';
import { displayNotification, sendAPIRequest } from 'helpers';
import { LIST_DETAIL_ELEMENTS } from 'constants';

let readyForPrint = false;

function ManualPrint(props) {
    const { t } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();
    const componentRef = React.useRef();
    const { fileName } = {
        fileName: searchParams.get('file_name'),
    };
    const location = useLocation();
    const [isLoading, setIsLoading] = useState({
        loading_data: false,
    });
    const [data, setData] = useState([]);
    const [dbStyleLoad, setDbStyleLoad] = useState(null)
    const [documentMetaData, setDocumentMetaData] = useState({
        section_name: '',
        chapter_name: '',
        chapter_no: '',
        section_no: ""
    });
    const getQueryParam = (name) => {
        const params = new URLSearchParams(location.search);
        return params.get(name);
    };

    useEffect(() => {
        getDocId()
    }, [getQueryParam("chapterValue")]);

    useEffect(() => {
        console.log({ readyForPrint });
        readyForPrint && data.length > 0 && handlePrint();
    }, [readyForPrint]);

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: fileName ? fileName : 'WOAH SONT',
        onAfterPrint: () => window.close(),
    })


    const getDocId = async () => {
        try {
            const name = getQueryParam("chapterLabel");
            const no = getQueryParam("chapterValue");
            let newLabel = name.replace(/^Chapter\d+: */, '');
            const ret = await sendAPIRequest(`/api/structure/get-doc-id`, "POST", {
                name: newLabel,
                no: no
            })
            const res = await ret.json()
            if (res.success && res.status === 200) {
                // setSections(res.data);
                if (res.data) {
                    await loadHtmlData(res.data.document_id)
                    await laodMetaData(res.data.document_id)
                }
            }
            readyForPrint = true;
        } catch (error) {
            console.log(error)
            displayNotification('error', 'SomeThing Went Wrong Please Try Again');
        }
    }

    const loadHtmlData = async (id) => {

        try {
            // setIsLoading({ ...isLoading, load_data: true });
            const ret = await sendAPIRequest(`/api/html/manual-crack/${id}?isrequired=true`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                setData(res.data)
                setDbStyleLoad(res.flag.styleTag ?? null)
                // setIsLoading({ ...isLoading, load_data: false });
            }
        } catch (e) {
            console.log({ e });
            // setIsLoading({ ...isLoading, load_data: false });
        }
        // setIsLoading({ ...isLoading, loading_data: false });
    };

    const laodMetaData = async (id) => {
        try {
            // let id = document
            const ret = await sendAPIRequest(`/api/html/manual-heading?id=${id}`);
            const res = await ret.json();
            // console.log("test",res)
            if (res.success && res.status === 200) {

                setDocumentMetaData(prevState => ({
                    ...prevState,
                    section_name: res.data.section_name,
                    chapter_name: res.data.chapter_name,
                    section_no: res.data.section_no,
                    chapter_no: res.data.chapter_no
                }));
               
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div className='container'>
            <div
                style={{
                    display: "flex",
                    position: "fixed",
                    zIndex: 99999,
                    background: "#fff",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                }}
            >
                <Spinner message="Loading..." className="m-auto" />
            </div>
            <div className=''>
                <div ref={componentRef} className="p-4">
                    <div className="border-bottom border-dark navbar">
                        <div className="d-flex header-res">
                            <a
                                className="navbar-brand"
                                id="logo-brand"
                                href="https://www.woah.org/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img src={logo} alt="woah-logo" height="40px" />
                            </a>
                            <div
                                style={{ height: "4rem" }}
                                className="ms-4 title-res border border-right-0 border-top-0 border-bottom-0 border-dark ps-3"
                            >
                                <Link className="navbar-brand" id="logo-brand-pvs" to="/">
                                    <h4 className="pe-4 pvs-title fw-bold py-4">
                                        WOAH International Standards
                                    </h4>
                                </Link>
                            </div>
                        </div>
                    </div>
                    {/* <h1>hello world</h1> */}
                    {
                        documentMetaData.section_name
                        && (
                            <div >

                                <div className="fs-5 text-black text-center pt-10 pb-5" style={{ letterSpacing: '2px' }} >
                                    Section {documentMetaData.section_no}
                                </div>
                                <h3 className="chapter-heading text-black text-center" style={{ letterSpacing: '2px' }} >

                                    {documentMetaData.section_name}
                                    {/* <HtmlRenderer
                                            tag={'div'}
                                            // className={child.class_name ?? 'fs-4'}
                                            htmlContent={finalData.section_name} /> */}
                                </h3>

                                <hr className='dark-underline mb-0' />
                                <hr className='dark-underline mt-1' />
                            </div>
                        )
                    }
                    {
                        documentMetaData.chapter_name
                        && (
                            <div >

                                <div className="fs-4 fw-lighter text-black text-center pt-5 pb-7" style={{ letterSpacing: '2px' }} >
                                    CHAPTER {documentMetaData.section_no}.{documentMetaData.chapter_no}.
                                </div>
                                <div className="d-flex justify-content-center chapter-heading text-black text-center pb-5" style={{ letterSpacing: '2px' }} >
                                    {/* {d.standard_content} */}
                                    <div className='px-4 text-justified' >
                                        {documentMetaData.chapter_name}
                                        {/* <HtmlRenderer
                                            tag={'div'}
                                            // className={child.class_name ?? 'fs-4'}
                                            htmlContent={finalData.chapter_name} /> */}
                                    </div>

                                </div>
                            </div>
                        )
                    }
                    {data && data.length > 0 ? (
                        data.map((d, index) => (
                            <div key={`key-${index}`} className='mt-5'>
                                {d.text_type === LIST_DETAIL_ELEMENTS.text_types.chapter_name && (
                                    <div key={`chapter-${d.id}`} >
                                        <div
                                            className="fs-4 fw-lighter text-black text-center pt-10 pb-7"
                                            style={{ letterSpacing: "2px" }}
                                            key={d.id}
                                        >
                                            CHAPTER {documentMetaData.section_name}.
                                            {documentMetaData.chapter_name}
                                        </div>
                                        <h2
                                            className="chapter-heading text-black text-center pb-5"
                                            style={{ letterSpacing: "2px" }}
                                            key={`name-${d.id}`}
                                        >
                                            <HtmlRenderer
                                                tag={"p"}
                                                className="document-chftre-intitule"
                                                htmlContent={d.standard_content}
                                            />
                                        </h2>
                                    </div>
                                )}
                                {d.text_type === LIST_DETAIL_ELEMENTS.text_types.manual_heading &&
                                    LIST_DETAIL_ELEMENTS.text_types.manual_reference &&
                                    LIST_DETAIL_ELEMENTS.text_types.manual_appendex &&
                                    LIST_DETAIL_ELEMENTS.text_types.manual_biblography && (
                                        <div key={`article-${d.id}`} id={`article-${d.id}`}>
                                            <HtmlRenderer
                                                tag={"div"}
                                                className={d.class_name ?? "text-center py-4"}
                                                htmlContent={d.standard_content}
                                            />
                                            {d.html_contents &&
                                                d.html_contents.length > 0 &&
                                                d.html_contents.map((child) => (
                                                    <div key={`art-child-${child.id}`}>
                                                        <HtmlRenderer
                                                            tag={"div"}
                                                            className={child.class_name}
                                                            htmlContent={child.html}
                                                        />
                                                    </div>
                                                ))}
                                        </div>
                                    )}
                                {d.text_type === LIST_DETAIL_ELEMENTS.heading_types.general_heading && (
                                    <div key={`general-${d.id}`} id={`${d.id}`}>
                                        <HtmlRenderer
                                            tag={"div"}
                                            className="py-4 fw-bold"
                                            htmlContent={d.standard_content}
                                        />
                                        {d.html_contents &&
                                            d.html_contents.length > 0 &&
                                            d.html_contents.map((child, i) => (
                                                <div key={`gen-child-${child.id}`} className="d-flex">
                                                    <div className="text-justified">
                                                        <HtmlRenderer
                                                            tag={"div"}
                                                            style={{ fontSize: `${props.fontSize}px` }}
                                                            htmlContent={child.content}
                                                        />
                                                    </div>
                                                </div>
                                            ))}
                                    </div>
                                )}
                                {(d.text_type === LIST_DETAIL_ELEMENTS.text_types.manual_paragraph ||
                                    d.text_type === LIST_DETAIL_ELEMENTS.text_types.manual_paragraph) && (
                                        <div key={`general-${d.id}`} id={`${d.id}`}>
                                            <HtmlRenderer
                                                tag={d.tag_name ?? "div"}
                                                className={d.class_name}
                                                htmlContent={d.standard_content}
                                            />
                                        </div>
                                    )}
                                {[LIST_DETAIL_ELEMENTS.recommendation_types.safe_commodities,
                                LIST_DETAIL_ELEMENTS.recommendation_types.recommendations_for_importations,
                                LIST_DETAIL_ELEMENTS.recommendation_types.inactivation_procedures].includes(d.text_type) && (
                                        <div id={d.id} key={`recommendation-${d.id}`}>
                                            <HtmlRenderer
                                                tag={"div"}
                                                className="py-4 fw-bold"
                                                style={{ fontSize: `${props.fontSize + 2}px` }}
                                                htmlContent={d.standard_content}
                                            />
                                            {d.html_contents &&
                                                d.html_contents.length > 0 &&
                                                d.html_contents.map((child) => (
                                                    <div key={`rec-child-${child.id}`} className="d-flex">
                                                        <div className="text-justified">
                                                            <HtmlRenderer
                                                                tag={"div"}
                                                                style={{ fontSize: `${props.fontSize}px` }}
                                                                htmlContent={child.content}
                                                            />
                                                        </div>
                                                    </div>
                                                ))}
                                        </div>
                                    )}
                            </div>
                        ))
                    ) : (
                        <div className="text-center text-primary">
                            <Spinner />
                        </div>
                    )}

                </div>
            </div>

        </div>
    )
}

export default ManualPrint

