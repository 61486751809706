import React, { useState, useMemo, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import Box from '@mui/material/Box'
import AutoCompleteDropdown from 'components/autocomplete/AutoCompleteDropdown';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

const SetImageSrcModel = ({getAllImages,setGetAllImages,markedString,setImageContent, handleSetMultipleImages,setMarkedString,imageContent,setSelectedImageInfo,imageOptions,handleImageSet,documentImages,image,loading,setSelectedImage,currentImage,setCurrentImage,s,children, content, header, reloadData, rows, btn1Text, btn2Text ,
    checkModal,setCheckModal}) => {

    const [imageSplit , setImageSplit] = useState([])
    const { t } = useTranslation();
    const[imageTagCount , setImageCount] = useState(0)
    const [selectedRadioIndex, setSelectedRadioIndex] = useState(0); 


    function countImageTags(inputString='') {
        var pattern = /<img[^>]*>/g;   
        var matches = inputString.match(pattern);
    
        if (!matches) {
            return 0;
        }    
        return matches.length;
    }

    useEffect (()=> {
       if(imageContent!==""){
        const le = countImageTags(imageContent)
       test()
       setImageCount(le)
       }else return

    },[imageContent])
    
    const handleCLose = () => {
        setCheckModal(false)
        setSelectedImageInfo({
                child:false,
                parentIndex:null,
                elemetIndex:null
            })
            setSelectedImage({
                value:"",
                label:''
            })
            setMarkedString('')
            setImageContent('')
            setGetAllImages(false)
            setImageCount(0)
    }

    const handleRadioChange = (index) => {
        setSelectedRadioIndex(index); 
    }
    // const renderImageOptions = () => {
    //     return imageSplit.length > 0 && imageSplit.map((option, index) => (
    //         <div key={index} style={{marginTop:"10px"}}>
    //             <label>
    //             {option}
    //                 <input
    //                     type="radio"
    //                     name="imageOption"
    //                     value={index}
    //                      onChange={() => handleRadioChange(index)}
    //                     checked={selectedRadioIndex === index} // Set checked based on selectedRadioIndex state
    //                      />
                    
    //             </label>
    //         </div>
    //     ));
    // }

    const renderImageOptions = () => {
        return (
            <div>
                {imageSplit.length > 0 && imageSplit.map((option, index) => (
                    <div key={index} className="form-check" style={{ marginBottom: "10px" }}>
                        <input
                            type="radio"
                            id={`imageOption_${index}`}
                            name="imageOption"
                            value={index}
                            onChange={() => handleRadioChange(index)}
                            checked={selectedRadioIndex === index}
                            className="form-check-input"
                        />
                        <label htmlFor={`imageOption_${index}`} className="form-check-label">{option}</label>
                    </div>
                ))}
            </div>
        );
    }

    const test = () => {
        var imgRegex = /<img\b[^>]*>/g;
        var imgTagsArray = [];
        if(imageContent !==''){
        var counter = 0;
        var markedHtmlString = imageContent.replace(imgRegex, function(match) {
            imgTagsArray.push(match); 
            return '{IMG_MARKER_' + (counter++) + '}'; 
        });
        setMarkedString(markedHtmlString)
        setImageSplit(imgTagsArray)
        }
        else{
            return
        }
    }

    const handleMultipleUpdate = async (isMultiple,index) =>{
        let returnResult = await  handleSetMultipleImages(isMultiple ,index,imageSplit[index])
        if(!returnResult){
            return
        }
        reSetImageSrc(index,returnResult)

    }

    const reSetImageSrc = (index,newString)=> {
    let oldTags = [...imageSplit]
       oldTags[index]=newString;
        setImageSplit(oldTags)
    }

  return (
    <>
            <Modal show={checkModal} size='md' centered={true}>
                <Modal.Header
                    className='p-4'
                    closeButton
                    onClick={() => handleCLose()}
                >
                    <Modal.Title>Image Mapping</Modal.Title>
                </Modal.Header>
                    <Modal.Body className='p-4'>
                    <div className="row d-flex">
                        <div className="col-md-10">

                            <Box sx={{ minWidth: 120 }}>
                                <AutoCompleteDropdown
                                    className={loading ? 'opacity-50' : 'opacity-100'}
                                    disabled={loading}
                                    label={loading ? 'loading...' : t("-1", "Images")}
                                    showClearIcon={true}
                                    error={false}
                                    required={false}
                                    multiple={false}
                                    hasselectall={false}
                                    options={imageOptions}
                                    onChange={(event, value) => {
                                        setSelectedImage({
                                            value: value?.value,
                                            label: value?.label,

                                        });
                                        // getSectionID(value?.value);
                                        setCurrentImage(documentImages.filter(img => img.id === value?.value)?.map(({ id, image_name }) => ({ value: id, label: image_name }))[0]
                                        );


                                    }
                                    }
                                    value={
                                        !image.value ? null : imageOptions.find(r => r?.value == image?.value) ?? { value: '', label: '' }
                                    }
                                    size='small'
                                />
                            </Box>
                            {/* <textarea 
                        className='form-control'
                        value={imageContent}
                        // onChange={e => setData(prev => ({ ...prev, updated_content: e.target.value }))}
                        rows={4}
                    /> */}

                        </div>
                        <div className="col-md-2"> <FormControlLabel
                          className="py-4"
                            control={<Checkbox checked={getAllImages} onChange={(e) => setGetAllImages(e.target.checked)} />}
                            label="All images"
                        /></div>

                    </div>

                    <div>

                        {imageTagCount > 1 && renderImageOptions()}
                        {/* {imageTagCount > 1 && 
                         <div className="d-flex justify-content-center mt-3"> 
                         <button
                             className='btn btn-primary btn-sm' // Use small-sized button
                             onClick={() => handleMultipleUpdate(true, selectedRadioIndex)}
                             style={{ width: '100px' }} // Set width to 100px
                         >
                             Set
                         </button>
                     </div> */}

                    </div>
                </Modal.Body>
 
                <Modal.Footer className='p-4 d-flex justify-content-center'>
                    {
                        imageTagCount > 1 ? <button
                        // variant="dark" 
                        className='btn btn--primary'
                        onClick={() => handleMultipleUpdate(true,selectedRadioIndex)}
                    >
                        {t("-1", "Set")}
                    </button>:
                        <button
                        // variant="dark" 
                        className='btn btn--primary'
                        onClick={() => handleCLose()}
                    >
                        {t("-1", "Close")}
                    </button>
                    
               


                }
                    <button
                        // variant="primary" 
                        className='btn btn--outline'
                        onClick={() => {
                            if(imageTagCount>1){
                                handleImageSet(true)
                            }
                            else{
                                handleImageSet(false)
                            }
                        }}
                       
                    >
                        {t("-1", "Update")}
                    </button>
                </Modal.Footer>
            </Modal>
        </>
  )
}


SetImageSrcModel.defaultProps = {
    // children: () => { },
    // content: "",
    // header: "Edit Text",
    // reloadData: null,
    // rows: 1,
    // btn1Text: "Close",
    // btn2Text: "Update",
}


export default SetImageSrcModel
