import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import TextFieldCustom from 'components/TextFieldCustom';
import { useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import { displayNotification, sendAPIRequest } from 'helpers';
import { Divider, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

function
    DraftGlossaryEditModal({ open, handleClose, id, data, setData, showPopup, setShowPopup, popupDetail, setPopupDetail, handleCloseEditModal, loadGlossary, is_modify = false }) {
    const { t } = useTranslation();
    const headerTheme = useSelector((s) => s.general.headerTheme);
    const [sections, setSections] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '40%',
        height: 'auto',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        overflowY: 'auto',
        padding: 0,
    };


    const updateDraftGlosary = async (id) => {
        setIsLoading(true);
        try {
            let ret;
            ret = await sendAPIRequest(`/api/draft-glossaries/${id}/?is_modify=${is_modify ? 1 : 0}`, "PUT", {
                glossary_term: data.glossary_term,
                glossary_text: data.glossary_text
            });
            const res = await ret.json();
            if (res.success && res.status === 200) {
                handleCloseEditModal()
                await loadGlossary()
                displayNotification('success', 'Successfully updated draft glossary term');
            } else {
                displayNotification('error', 'Failed to update draft glossary term');
            }
        } catch (e) {
            console.log({ e });
            displayNotification('error', 'Failed to update draft glossary term');
        }
        setIsLoading(false);
    }

    return (
        <>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box sx={{ padding: '10px 20px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Modify Term
                        </Typography>
                        <IconButton onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Divider sx={{ backgroundColor: '#000', margin: '0' }} />
                    <Box sx={{ padding: '20px' }}>
                        <div className="row">
                            <div className='col-12 mb-3 fs-4 '>
                                <span className='fw-bold pe-2'>{t("-1", "Glossary Term")}</span>
                                <TextFieldCustom
                                    variant="outlined"
                                    id="name"
                                    fullWidth
                                    rows={4}
                                    required={true}
                                    sx={{
                                        backgroundColor: "white",
                                        color: "white",
                                        border: "#ff4815",
                                        width: "99%",
                                    }}
                                    value={data.glossary_term}
                                    onChange={(e) => setData({ ...data, glossary_term: e.target.value })}
                                    size='small'
                                />
                            </div>
                            <div className='col-12 mb-3 fs-4 '>
                                <span className='fw-bold pe-2'>{t("-1", "Glossary Definition")}</span>
                                <TextFieldCustom
                                    variant="outlined"
                                    id="email"
                                    fullWidth
                                    rows={4}
                                    required={true}
                                    sx={{
                                        backgroundColor: "white",
                                        color: "white",
                                        border: "#ff4815",
                                        width: "99%",
                                    }}
                                    size='large'
                                    value={data.glossary_text}
                                    onChange={(e) => setData({ ...data, glossary_text: e.target.value })}
                                />
                            </div>
                        </div>
                        <div className='row '>
                            <div className="col-12 text-center mt-3">
                                <button
                                    className="btn btn--outline font-custom fs-6"
                                    onClick={() => {
                                        handleClose();
                                    }}
                                >
                                    {t("-1", "Cancel")}
                                </button>
                                <button
                                    className="btn btn--primary font-custom fs-6 ms-2"
                                    onClick={() => updateDraftGlosary(data.id)}
                                    disabled={isLoading}
                                >
                                    {isLoading ? t("-1", "Updating...") : t("-1", "Update")}
                                </button>
                            </div>
                        </div>
                    </Box>
                </Box>
            </Modal>
        </>

    )
}

export default DraftGlossaryEditModal
