import React, { useEffect, useState } from 'react'
import { displayNotification, getBaseURL, sendAPIRequest } from 'helpers';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { LIST_DETAIL_ELEMENTS } from 'constants';
import HtmlRenderer from 'components/HtmlRenderer';
import Container from 'components/common/Container';
import CustomCard from 'components/common/CustomCard';
import CardHeader from 'components/common/CardHeader';
// import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'components/text_editor/CustomCKEditor';
import Spinner from 'components/spinner/Spinner';
import Row from 'components/common/Row';
import Column from 'components/common/Column';
import { MANUAL_HEADINGS } from 'constants';
import Button from 'components/common/Button';
import ReactDOMServer from 'react-dom/server';
import { SIDEBAR_HEADINGS } from 'constants';
import SideBarComponent from './SideBarComponent';
import CardBody from 'components/common/CardBody';
import Loader from 'components/common/Loader';
import { setCurrentPage } from 'redux/generalSlice';
import { useDispatch } from 'react-redux';
import { IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const ModifyExistingChapter = () => {
    const [queryParams] = useSearchParams();
    const [location] = useSearchParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { structure_id, is_code } = {
        structure_id: queryParams.get('structure_id'),
        is_code: queryParams.get('is_code') === "true" ? true : false
    };
    const { disable } = {
        disable: location.get('disable') === "true" ? true : false
    };
    const [isLoading, setIsLoading] = useState(true);
    const [sideBarData, setSideBarData] = useState([]);
    const [data, setData] = useState('');
    const [dataToShow, setDataToShow] = useState('');
    const [editorData, setEditorData] = useState('');
    const [currentSelected, setCurrentSelected] = useState('');
    const [currentArticle, setCurrentArticle] = useState('');
    const [chapterInfo, setChapterInfo] = useState({});
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const getHTMLContent = async () => {
        const htmlContent = GenerateContent();
        setEditorData(htmlContent);
    };

    const generateSideBarDataLength = (data = []) => {
        const d = data.filter(d => SIDEBAR_HEADINGS.includes(parseInt(d.text_type)));
        setSideBarData(d);
    }

    useEffect(() => {
        dispatch(setCurrentPage("publishing-standards"));
    }, []);

    const handleSubSectionSelection = (id) => {
        try {
            const currentData = data.filter(d => d.id === id);
            if (currentData) {
                setDataToShow(currentData);
                setCurrentSelected(id)
            }
        } catch (err) {
            console.log({ err });
        }
    }

    const loadData = async () => {
        setIsLoading(true);
        try {
            let url = `/api/contents/navigation-chapter-preview/?structures_id=${structure_id}&edit_chapter=true`;
            const ret = await sendAPIRequest(url);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                if (res.data.length > 0) {
                    generateSideBarDataLength(res.data);
                    setData(res.data);
                }
            } else {
                displayNotification('error', 'Error loading data [B]');
            }
            setIsLoading(false);
        } catch (err) {
            console.log({ err });
            displayNotification('error', 'Error loading data [F]');
        }
    }

    const getChapterInformation = async () => {
        try {
            const ret = await sendAPIRequest(`/api/standard-structures/chapter-information/${structure_id}`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                setChapterInfo(res.data);
                console.log('sadaf', res.data);
            }
        } catch (err) {
            console.log(err);
        }
    }

    const updateContent = async () => {
        if (!currentArticle) {
            displayNotification('error', 'No heading is selected to update content [F]');
            return
        }
        const userConfirmed = window.confirm('Are you sure you want to publish?');
        if (!userConfirmed) {
            return;
        }
        try {
            let url = `/api/contents/update-standard-structure-content/${currentArticle}`;
            const ret = await sendAPIRequest(url, 'PUT', {
                html: editorData
            });
            const res = await ret.json();
            if (res.success && res.status === 200) {
                await loadData();
                displayNotification('success', 'Successfully updated content [B]');
            } else {
                displayNotification('error', 'Error loading data [B]');
            }
        } catch (err) {
            console.log({ err });
            displayNotification('error', 'Error loading data [F]');
        }
        handleClose();
    }

    const handleReturn = (id) => {
        setCurrentArticle(id);
    }

    const GenerateContent = () => {
        return ReactDOMServer.renderToStaticMarkup(
            <>
                {
                    dataToShow && dataToShow.length > 0 && dataToShow.map(d => (
                        (d.text_type === LIST_DETAIL_ELEMENTS.text_types.article_heading || MANUAL_HEADINGS.includes(d.text_type) || d.text_type === LIST_DETAIL_ELEMENTS.text_types.manual_default_heading) && (
                            <React.Fragment key={d.id}>
                                {d.html_contents && d.html_contents.length > 0 ? (
                                    d.html_contents.map((child) => (
                                        <>
                                            <HtmlRenderer
                                                tag={"div"}
                                                htmlContent={child.content}
                                            />
                                        </>
                                    ))
                                ) : (
                                    d.children && d.children.length > 0 && d.children.map(child => (
                                        <div key={`general-${child.id}`} id={`${child.id}`}>
                                            {child.html_contents && child.html_contents.map((ch) => (
                                                <div
                                                    key={`gen-child-${ch.id}`}
                                                    className={`d-flex ${ch.text_type === LIST_DETAIL_ELEMENTS.styles_categories.code_editing_date_text ? 'justify-content-end pt-3' : ''}`}
                                                >
                                                    <div className={LIST_DETAIL_ELEMENTS.heading_types.general_heading === child.text_type ? "text-justified" : 'justify-content-end'}>
                                                        <HtmlRenderer
                                                            tag={LIST_DETAIL_ELEMENTS.heading_types.general_heading === child.text_type ? "div" : "p"}
                                                            htmlContent={ch.content}
                                                        />
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    ))
                                )}
                            </React.Fragment>
                        )
                    ))
                }
            </>
        );
    };

    useEffect(() => {
        getChapterInformation();
        loadData();
    }, [structure_id]);

    useEffect(() => {
        getHTMLContent();
    }, [dataToShow]);

    useEffect(() => {
        if (currentArticle) {
            handleSubSectionSelection(currentArticle);
        }
    }, [currentArticle]);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const goBack = () => {
        navigate(-1)
    }
    const previewChapter = (data = {}) => {
        const url = getBaseURL(`/portal/chapter/?structure=${structure_id}&ismanual=${chapterInfo.standard_type === LIST_DETAIL_ELEMENTS.standard_type.manuals ? true : false}`);
        window.open(url, "_blank");
        handleClose();
    };

    return (
        <React.Fragment>
            <Container>
                <CustomCard className={'d-flex justify-content-between'}>
                    <h4 className="text-uppercase font-custom fw-bold my-auto mx-0 px-0">
                        {isLoading ? (
                            <>
                                <Loader
                                    message="Loading..."
                                    style={{ color: "#333", fontSize: "1rem", textTransform: 'none', }} />
                            </>
                        ) : (
                            chapterInfo?.chapter_name
                        )}
                    </h4>
                    <CardBody>
                        <Button
                            onClick={goBack}
                        >
                            Back
                        </Button>
                        <Tooltip
                            title={'More Actions'}
                            placement="top"
                            arrow
                            fullWidth
                        >
                            <IconButton
                                aria-label="more"
                                id="long-button"
                                aria-controls={open ? 'long-menu' : undefined}
                                aria-expanded={open ? 'true' : undefined}
                                aria-haspopup="true"
                                onClick={handleClick}
                            >
                                <MoreVertIcon sx={{ color: '#ff4815', fontSize: '24px' }} />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            id="long-menu"
                            MenuListProps={{
                                'aria-labelledby': 'long-button',
                            }}
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            disablePortal
                            slotProps={{
                                paper: {
                                    sx: {
                                        backgroundColor: '#f0f0f0',
                                        border: '1px solid rgb(198, 198, 198)',
                                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                                    },
                                },
                            }}
                            container={document.body}
                            keepMounted
                            sx={{
                                position: 'absolute',
                            }}
                        >
                            <MenuItem
                                onClick={updateContent}
                            >
                                Publish
                            </MenuItem>
                            <MenuItem
                                onClick={previewChapter}
                            >
                                Preview
                            </MenuItem>
                        </Menu>
                    </CardBody>
                </CustomCard>
                <Row className={"mb-0 pb-0 g-0"}>
                    <Column
                        col={2}
                        className={'border border-secondary mx-0 px-0 bg-light'}
                        style={{
                            position: 'sticky',
                            top: 0,
                            height: '100vh',
                            zIndex: '999',
                            overflowY: 'auto',
                        }}
                    >
                        <SideBarComponent
                            data={sideBarData}
                            isCode={is_code}
                            showAction={false}
                            striptHtml={!is_code}
                            returnData={(id) => handleReturn(id)}
                        // handleEdit={(id) => handleEdit(id)}
                        // handleDelete={(id) => handleDelete(id)}
                        // handleMoveUp={(index) => handleMoveUp(index)}
                        // handleMoveDown={(index) => handleMoveDown(index)}
                        // handleTagging={(id) => handleTagging(id)}
                        // handleAddArticle={(id) => handleAddArticle(id)}
                        // setAddButtonClicked={setAddButtonClicked}
                        />
                    </Column>
                    <Column col={10}>
                        {data && data.length > 0 ? (
                            <ClassicEditor editorData={editorData} setEditorData={setEditorData} customConfig={{
                                chapterId: chapterInfo.id
                            }} disable={disable} />
                        ) : (
                            <Spinner message="Loading editor..." />
                        )}
                    </Column>
                </Row>
            </Container>
        </React.Fragment>
    );
}

export default ModifyExistingChapter;