import Container from 'components/common/Container'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import CustomCard from 'components/common/CustomCard';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import SectionChapterCordianComponent from './Components/SectionChapterCordianComponent';
import { displayNotification, sendAPIRequest } from 'helpers';
import Loader from 'components/common/Loader';
import { LIST_DETAIL_ELEMENTS } from 'constants';
import GlossaryAccordionComponent from './Components/GlossaryAccordionComponent';
import { setCurrentPage, setComponentLang } from 'redux/generalSlice';
import UploadCoverLetter from 'components/modals/UploadCoverLetter';
import { Box, CircularProgress, IconButton, Tooltip } from '@mui/material'
import Button from 'components/common/Button';
import { Spinner } from 'react-bootstrap';
import AddSectionModal from 'components/modals/AddSectionModal';
import { STANDARD_MIGRATION_STATUS } from 'constants';
import { Add } from '@mui/icons-material';

const PublishingStandardsDetails = () => {

    const dispatch = useDispatch();
    const [location] = useSearchParams();
    const siteLanguage = useSelector(s => s.general.siteLanguageSont);
    const compLanguage = useSelector(s => s.general.componentLanguage)
    const [componentLanguage, setComponentLanguage] = useState(compLanguage ?? '102');
    const { t } = useTranslation();
    const currentDate = new Date().toISOString().slice(0, 10);
    const [data, setData] = useState([]);
    const [open, setOpen] = useState(false);
    const headerTheme = useSelector((s) => s.general.headerTheme);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [structureStatus, setStructureStatus] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [isPdfLoading, setIsPdfLoading] = useState(false);
    const dropdownRef = useRef(null);
    const [editingComplete, setEditingComplete] = useState();
    const [sectionEditing, setSectionEditing] = useState(true);
    const [addSectionModal, setAddSectionModal] = useState(false);
    const [isPDFProcessing, setIsPDFProcessing] = useState(false);
    const [standardDetails, setStandardDetails] = useState({
        standard_type: '',
        animal_type: '',
        version_year: '',
        structureStatus: ''
    });
    let [catalogStatus, setCatalogStatus] = useState(null);
    const [reducing, setReducing] = useState(false);
    const [canGeneratePdf, setCanGeneratePdf] = useState(true);
    const [errorBook, setErrorBook] = useState(null);

    const { id } = {
        id: location.get('id'),
    };
    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setDropdownOpen(false);
        }
    };
    const handlePdfModel = () => {
        setOpen(true)
    }

    const handleCloseModal = () => {
        setOpen(false);
    }
    useEffect(() => {
        dispatch(setCurrentPage("publishing-standards"));
        dispatch(setComponentLang(compLanguage ?? '102'));
    }, []);

    useEffect(() => {
        if (standardDetails.animal_type && standardDetails.standard_type && standardDetails.version_year) {
            fetchPDFStatus()
        }
    }, [standardDetails])

    const fetchPDFStatus = async () => {
        try {
            const ret = await sendAPIRequest(`/api/generate-pdf/full-chapter-pdf-status?version_year=${standardDetails.version_year}&lang_id=${componentLanguage}&animal_type=${standardDetails.animal_type}&standard_type=${standardDetails.standard_type}`);
            const res = await ret.json();
            if (res.success) {
                if (res.data.pdf_link == 'processing') {
                    setIsPDFProcessing(true);
                }
            }
        } catch (error) {

        }
    }

    const changeComponentLanguage = async (lang) => {
        setCanGeneratePdf(true);
        dispatch(setComponentLang(lang));
        setComponentLanguage(lang);
    }
    const loadStandardDetails = async () => {
        try {
            const ret = await sendAPIRequest(`/api/standard-structures/standard-type/${id}`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                if (res.data.standard) {
                    setStructureStatus(res?.data?.standard?.structure_status);
                    setCatalogStatus(res?.data.standard?.catalog_status)
                    setStandardDetails({
                        standard_type: res.data.standard.standard_type,
                        animal_type: res.data.standard.animal_type,
                        version_year: res.data?.standard?.migrated_year,
                        structureStatus: res?.data?.standard?.structure_status
                    })
                    await loadData(res.data.standard.standard_type, res.data.standard.animal_type);
                    if (res?.data?.standard?.structure_status === STANDARD_MIGRATION_STATUS.edit_completed) {
                        await loadPdfGenerationPermission(res.data.standard.standard_type, res.data.standard.animal_type);
                    }
                }
            } else {
                displayNotification('error', res.message);
            }
        } catch (error) {
            console.log({ error });
            displayNotification("error", "Internall Server Error [F]")
        }
    }

    const loadPdfGenerationPermission = async (standard_type = '', animal_type = '') => {
        try {
            const ret = await sendAPIRequest(`/api/doc-transfer/generate-pdf-permission/${id}/?standard_type=${standard_type}&animal_type=${animal_type}&lang_id=${componentLanguage}`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                setCanGeneratePdf(!res.data)
            } else {
                displayNotification('error', res.message);
            }
        } catch (error) {
            console.log({ error });
            displayNotification("error", "Internall Server Error [F]")
        }
    }

    const loadData = async (standard_type, animal_type) => {
        setIsLoading(true);
        try {
            const ret = await sendAPIRequest(`/api/standard-structures/standard-sections/?standard_type=${standard_type}&animal_type=${animal_type}&lang_id=${componentLanguage ?? "102"}&draft=true`)
            const res = await ret.json();
            if (res.success && res.status === 200) {
                setData(res.data);
                let test = handleReturNSetSediting(res.data);
                setSectionEditing(handleReturNSetSediting(res.data));
            } else {
                displayNotification('error', res.message);
            }
        } catch (e) {
            console.log({ e });
        }
        setIsLoading(false);
    }

    const handleReturNSetSediting = (data = []) => {
        try {
            for (const x of data) {
                for (const y of x.sections) {
                    for (let t of y.chapters) {
                        if (t.relationDocTransferDraftStructures.some(tt => [1, 2].includes(tt.transfer_status))) {
                            return true;
                        }
                    }
                }
            }
        } catch (error) {
            console.log({ error })
        }
        return false;
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        loadStandardDetails();
    }, [componentLanguage]);

    const getHeaderText = (text) => {
        switch (text) {
            case '57':
                return t("32", 'Terrestrial Animal Health Code');
            case '67':
                return t('-1', 'Manual of Diagnostic Tests and Vaccines for Terrestrial Animals');
            case '58':
                return t('-1', 'Aquatic Animal Health Code');
            case '68':
                return t('-1', 'Manual of Diagnostic Tests for Aquatic Animals');
            default:
                return '';
        }
    };

    const PublishingStandardsDetails = () => {
        const location = useLocation();
        const queryParams = new URLSearchParams(location.search);
        const id = queryParams.get("id");
        let ide = standardDetails.standard_type + "" + standardDetails.animal_type
        return getHeaderText(ide);
    };

    const handlePublish = async () => {
        if (!window.confirm('Are you sure you want to publish standards in all 3 languages?')) {
            return;
        }
        setIsLoading(true);
        try {
            const ret = await sendAPIRequest(`/api/handle-drafting/pre-publication`, 'POST', {
                "standard_type_id": id,
                "languageId": componentLanguage
            })
            const res = await ret.json();
            if (res.success && res.status === 200) {
                setData([]);
                loadStandardDetails();
                displayNotification('success', 'Process created will finished soon')
            } else {
                displayNotification('error', res.message);
            }
        } catch (e) {
            console.log({ e });
        } finally {
            setIsLoading(false);
        }
    }

    const downloadFullPDF = async () => {
        try {
            setIsPdfLoading(true);
            const fName = `WOAH_Report_${currentDate}.pdf`;
            let generalText = "";
            const ret = await sendAPIRequest(
                `/api/generate-pdf/full-chapter-pdf-download?versionYear=${standardDetails.version_year}&lang_id=${componentLanguage}&animal_type=${standardDetails.animal_type}&standard_type=${standardDetails.standard_type}&general_text=${generalText}`,
                "GET"
            );
            const res = await ret.json();
            if (res.success && res.status === 200) {
                // For local enviornment
                const downloadResponse = await sendAPIRequest(
                    `/api/content-navigation-pdf/download?fileName=${fName}&delete_action=false&uniqueName=${res.data.pdf_link}`
                );
                // For live enviornment only
                // const downloadResponse = await fetch(${CONTENT_PDF_DOWNLOAD_API}?file_name=${fName}, {
                //     method: 'GET',
                //     headers: {
                //         'Content-Type': 'application/json',
                //     },
                // });
                if (downloadResponse.ok) {
                    const blob = await downloadResponse.blob();
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement("a");
                    a.href = url;
                    a.download = fName ?? "testing.pdf";
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                    window.URL.revokeObjectURL(url);
                    displayNotification("success", "Successfully downloaded file");
                } else {
                    setErrorBook(res.data);
                    displayNotification("error", "Error downloading file");
                }
            } else {
                displayNotification("error", "Error creating PDF");
            }
        } catch (error) {
            displayNotification("error", "Unexpected error occurred");
        } finally {
            setIsPdfLoading(false);
        }
    };

    const reduceStandard = async () => {
        if (!window.confirm("Are you sure you want to reduce standard size? This action is not reversible.")) {
            return;
        }
        setReducing(true);
        try {
            const ret = await sendAPIRequest(`/api/handle-drafting/reduce-standard`, "DELETE", {
                "standard_type_id": id,
                lang_id: componentLanguage,
            });
            const res = await ret.json();
            if (res.success && res.status === 200) {
                displayNotification('sucess', 'Successfully reduced standard size');
                await loadStandardDetails();
            } else {
                displayNotification('error', res.message);
            }
        } catch (e) {
            console.log({ e });
            displayNotification('error', e.message);
        }
        setReducing(false);
    }

    const handleAddSectionClose = () => {
        setAddSectionModal(false);
    }

    const deleteFullPDF = async () => {
        if (!window.confirm('Are you sure you want to delete the PDF?')) {
            return;
        }
        setIsPdfLoading(true);
        try {
            console.log(`/api/generate-pdf/full-chapter-delete/${errorBook.id}`)
            const ret = await sendAPIRequest(`/api/generate-pdf/full-chapter-delete?book_id=${errorBook.id}`, 'DELETE');
            const res = await ret.json();
            if (res.success && res.status === 200) {
                setErrorBook(null);
                displayNotification('success', 'PDF deleted successfully');
            } else {
                displayNotification('error', res.message);
            }
        } catch (e) {
            console.log({ e });
            displayNotification('error', 'Internal Server Error');
        } finally {
            setIsPdfLoading(false);
        }
    }

    return (
        <>
            {isLoading && (
                <Box
                    sx={{
                        position: "fixed",
                        top: "50%",
                        left: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        transform: "translate(-50%, -50%)",
                        zIndex: 10000,
                    }}
                >
                    <CircularProgress
                        size={40}
                        thickness={4}
                        sx={{
                            color: "#ff4815",
                        }}
                    />
                </Box>
            )}
            <Container
                style={{
                    opacity: isLoading ? 0.5 : 1,
                    pointerEvents: isLoading ? "none" : "auto",
                    position: "relative",
                }}
            >
                <div className="header card-woah border border-secondary d-flex px-4 py-2 my-2 shadow-sm w-100">
                    <div className='d-flex justify-content-between'>
                        <div className="card-title1 d-flex align-items-center lh-1">
                            {t("-1", "Publishing Standards")}
                            {(catalogStatus === 2 && !isPDFProcessing) && (
                                isPdfLoading ? (
                                    <div className="d-flex align-items-center fs-7 align-middle px-3 mt-2">
                                        <Loader message="Downloading Pdf..." />
                                    </div>
                                ) : (
                                    <>
                                        <Tooltip
                                            title={t("36", "Download Complete Standard PDF")}
                                            placement="top"
                                        >
                                            <i
                                                className="fas fa-file-pdf text-primary fs-2 px-3 cursor-pointer align-middle mt-2"
                                                onClick={downloadFullPDF}
                                            ></i>
                                        </Tooltip>

                                        {errorBook && (
                                            <i
                                                className="fas fa-trash text-primary fs-2 px-3 cursor-pointer align-middle mt-2"
                                                onClick={deleteFullPDF}
                                            ></i>
                                        )}
                                    </>
                                )
                            )}
                        </div>
                    </div>
                    <div className="header-right-items ms-auto d-flex align-items-center">

                        <div className="ms-auto">
                            {/* <Button
                            variant="primary"
                            onClick={reduceStandard}
                            disabled={reducing}
                        >
                            {
                                reducing
                                    ? <Loader message="Reducing" />
                                    : "Reduce Standard"
                            }
                        </Button> */}
                            {/* {(catalogStatus === 2 && isPDFProcessing === true) ? (<Button
                            disabled={true}
                            variant="outline"
                        >
                            {t("-1", "Processing PDF")}
                        </Button>) : <Button
                            onClick={handlePdfModel}
                            variant="outline"
                        >
                            {t("-1", "Generate PDF")}
                        </Button>} */}

                            <button
                                className="btn btn--primary font-custom fs-6 ms-1"
                                onClick={handlePdfModel}
                                disabled={canGeneratePdf}
                            >
                                {/* {isPDFProcessing ? t("-1", "Generating PDF") : t("-1", "Generate PDF")} */}
                                {t("-1", "Generate PDF")}
                            </button>

                            <button
                                className="btn btn--outline font-custom fs-6 ms-1"
                                onClick={() => { window.open(`/portal/glossary-implementation/?animal=${standardDetails.animal_type}&standard=${standardDetails.standard_type}&lang=${componentLanguage}`, '_blank') }}
                                disabled={structureStatus === 5 ? false : true}
                            >
                                {t("-1", "Glossary Implementation")}
                            </button>
                        </div>
                    </div>
                </div>
                <div className=" header-res header card-woah border border-secondary d-flex px-4 py-2 shadow-sm w-100">
                    <div className="d-flex justify-content-between">
                        <h4 class="text-uppercase font-custom fw-bold my-auto mx-0 px-0">{PublishingStandardsDetails()}</h4>
                    </div>
                    <div className="header-right-items ms-auto d-flex align-items-center">
                        <div className="ms-auto">
                            <div className="list-group list-group-horizontal ms-auto">
                                <span className="vertical-line mx-5"></span>
                                <span
                                    className={`bg-${headerTheme} cursor-pointer list-group-item text-hover-primary border-0 text-center font-custom ${componentLanguage == `${LIST_DETAIL_ELEMENTS.languages.english}` ? "text-decoration-underline" : ""
                                        } `}
                                    onClick={() => (changeComponentLanguage(`${LIST_DETAIL_ELEMENTS.languages.english}`))}
                                >
                                    EN
                                </span>
                                <span
                                    className={`bg-${headerTheme} cursor-pointer list-group-item text-hover-primary border-0 text-center font-custom ${componentLanguage == `${LIST_DETAIL_ELEMENTS.languages.french}` ? "text-decoration-underline" : ""
                                        } `}
                                    onClick={() => (changeComponentLanguage(`${LIST_DETAIL_ELEMENTS.languages.french}`))}
                                >
                                    FR
                                </span>
                                <span
                                    className={`bg-${headerTheme} cursor-pointer list-group-item text-hover-primary border-0 text-center  font-custom  me-3 ${componentLanguage == `${LIST_DETAIL_ELEMENTS.languages.spanish}` ? "text-decoration-underline" : ""
                                        } `}
                                    onClick={() => (changeComponentLanguage(`${LIST_DETAIL_ELEMENTS.languages.spanish}`))}
                                >
                                    ES
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='py-2 d-flex justify-content-center bg-terrestrial-manual border border-secondary shadow-sm card-title3 my-2'>
                    {t("-1", "Glossary")}
                </div>
                <CustomCard>
                    <GlossaryAccordionComponent
                        standardDetails={standardDetails}
                        setEditingComplete={setEditingComplete}
                        componentLanguage={componentLanguage ?? "102"}
                    />
                </CustomCard>
                <div className='py-2 d-flex justify-content-center bg-aquatic-manual border border-secondary shadow-sm card-title3'>
                    {t("-1", "Sections")}
                    <Tooltip title="Add New Section" placement="top">
                        <IconButton>
                            <Add color='primary' fontSize='medium'
                                onClick={() => {
                                    setAddSectionModal(true);
                                }} />
                        </IconButton>
                    </Tooltip>
                </div>
                <CustomCard>
                    {
                        data.length > 0 ? (
                            data.map((a) =>
                                a?.sections?.map((x, ind) => (
                                    <SectionChapterCordianComponent
                                        key={ind}
                                        data={x}
                                        standardDetails={standardDetails}
                                        loadData={loadData}
                                        isLoading={isLoading}
                                        setIsLoading={setIsLoading}
                                        standard_type={standardDetails.standard_type}
                                        area_vol_no={a?.area_vol_no ?? ''}
                                        componentLanguage={componentLanguage}
                                    />
                                ))
                            )
                        ) : isLoading ? (
                            <div className="d-flex justify-content-center align-items-center vh-40">
                                <Loader message="Loading data...." />
                            </div>
                        ) : null
                    }
                    <div className="row pt-3">
                        {/* <div className="col-12 d-flex justify-content-end">
                        {structureStatus === 3 && (
                            <button
                                className="btn btn--primary font-custom fs-6 ms-1"
                                onClick={() => { handlePublish() }}
                                disabled={sectionEditing || editingComplete ? true : false}
                                disabled={sectionEditing}
                            >
                                {t("-1", "Editing Complete")}
                            </button>
                        )}
                    </div> */}
                        {addSectionModal && <AddSectionModal
                            open={addSectionModal}
                            handleClose={handleAddSectionClose}
                            standardDetails={standardDetails}
                            componentLanguage={componentLanguage}
                            loadData={loadData}
                        />}
                    </div>
                </CustomCard>
                {open && <UploadCoverLetter
                    open={open}
                    componentLanguage={componentLanguage ?? "102"}
                    standardDetails={standardDetails}
                    setIsPDFProcessing={setIsPDFProcessing}
                    handleCloseModal={handleCloseModal}
                    setCanGeneratePdf={setCanGeneratePdf}
                />}
            </Container>
        </>
    )
}

export default PublishingStandardsDetails
