const { Spinner } = require("react-bootstrap")

const Loader = ({ message, style }) => {
    return (
        <div className="d-flex align-items-center" style={style}>
            <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                style={{ color: "#ff4815", ...style }}
            />
            <div className="m-0 p-0 ps-2">{message}</div>
        </div>
    );
}

export default Loader;