import { displayNotification, sendAPIRequest } from 'helpers';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import styles

const MyEditor = () => {
    const [editorHtml, setEditorHtml] = useState('');
    const [id, setID] = useState();
    const { t } = useTranslation();

    useEffect(() => {
        getManualStyle();
    }, []);

    const handleChange = (html) => {
        setEditorHtml(html);
    };

    const getManualStyle = async () => {
        try {
            const ret = await sendAPIRequest('/api/manual-styles');
            const res = await ret.json();
            if (res.success && res.status === 200) {
                if (res.data && res.data.length > 0) {
                    setID(res.data[0].id);
                    setEditorHtml(res.data[0].style);
                }
            }
        } catch (err) {
            console.log(err);
        }
    }

    const handleSave = async () => {
        try {
            let ret;
            if (id) {
                ret = await sendAPIRequest(
                    `/api/manual-styles/${id}`,
                    'PUT',
                    {
                        style: editorHtml
                    }
                );
            } else {
                ret = await sendAPIRequest(
                    `/api/manual-styles/`,
                    'POST',
                    {
                        style: editorHtml
                    }
                );
            }
            const res = await ret.json();
            if (res.success) {
                displayNotification('success', 'Successfully save or update style [B]');
            } else {
                displayNotification('error', 'Failed to save or update style [B]');
            }
        } catch (err) {
            displayNotification('error', 'Failed to save or update style [F]');
            console.log(err);
        }
    }

    return (
        <div className='container my-2'>
            <div className='card-body'>
                <div>
                    <ReactQuill
                        theme="snow" // Specify theme ('snow' for a simple toolbar)
                        value={editorHtml}
                        onChange={handleChange}
                    />
                </div>
                <div className='my-2'>
                    <button
                        className="btn btn--primary font-custom  ms-1"
                        onClick={handleSave}
                    >
                        {t("-1", id ? 'Update' : 'Save')}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default MyEditor;
