import React from 'react';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

const CustomTab = ({ label, index, onClick, isActive, sx }) => {
  const handleTabClick = () => {
    onClick(index);
  };

  return (
    <Tab
      label={label}
      sx={{
        position: 'relative',
        padding: '5px 5px !important',
        marginBottom: '10px',
        width: '300px',
        color: isActive ? '#FF4815' : '#000',
        textTransform: 'none',
        backgroundColor: isActive ? '#FFECE8' : '#F4F4F4',
        borderRadius: isActive ? 'none' : '',
        opacity: '1 !important',
        borderLeft: isActive ? '2px solid #FF4815' : '',
        borderBottom: '1px solid #ccc',
        '&:hover': {
          backgroundColor: '#ffece8 !important',
          color: '#FF4815 !important',
          borderLeft: '2px solid #FF4815 !important',
          padding: '5px 5px !important',
        },
        ...sx,
      }}
      onClick={handleTabClick}
    />
  );
};

CustomTab.defaultProps = {
  sx: {},
};

export default CustomTab;
