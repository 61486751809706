import React ,{useState,useEffect} from 'react'
import Container from 'components/common/Container'
import { Button, Menu, MenuItem } from '@mui/material';
import { useTranslation } from "react-i18next";
import CustomCard from 'components/common/CustomCard';
import Checkbox from '@mui/material/Checkbox';
import { displayNotification, sendAPIRequest } from 'helpers';
import UserNameModel from 'components/modals/UserNameModel';
import {  useNavigate } from 'react-router-dom';

const Profile = () => {
    // const history = useHistory();
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation();
    const [data ,setData] = useState(null);
    const [modal ,setModal] = useState(false);

    useEffect(() => {
        if(!modal){
            loadData();
        }
    }, [modal]);

    const handleCloseModal = () => {
        setModal(false);
    }

    const handleGoBack = () => {
        navigate(-1);
    }

    const loadData = async () => {
        setIsLoading(true);
        try {
            const ret = await sendAPIRequest(`/api/users/profile`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                setData(res.data);
            }
        } catch (err) {
            console.log({ err });
            displayNotification('error', 'Failed to load Profile data [F]!');
        }
        setIsLoading(false);
    }

    return (
        <Container>
            <div className="header card-woah border border-secondary d-flex px-4 py-2 shadow-sm w-100 my-2">
                <div className='d-flex justify-content-between w-100'>
                    <div className="card-title1 lh-1">{t("-1", "Profile")}</div>
                    <div className=''>
                        <div>
                            <Button
                                className="btn btn--primary font-custom fs-6 ms-1"
                                onClick={handleGoBack}
                            >
                                {t("-1", "Back")}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <CustomCard>
                <div className="row py-3">
                    <div className="col-md-12">
                        <div className="d-flex justify-content-between align-items-center mb-2">
                            <div className="col-md-6">
                                <div className='d-flex justify-content-between w-50'>
                                    <h3>{t("-1", "Name")}:</h3>
                                    <h3 className='ps-5'>{data?.user_name}   <i className="fas fa-edit ms-3" style={{ cursor: 'pointer' }} onClick={()=>setModal(true)}></i>
                                    </h3>

                                </div>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center mb-2">
                            <div className="col-md-6">
                                <div className='d-flex justify-content-between w-50'>
                                    <h3>{t("-1", "Email")}:</h3>
                                    <h3>{data?.email}
                                    </h3>
                                </div>
                            </div>
                        </div>

                        <div className="d-flex justify-content-between align-items-center mb-2">
                            <div className="col-md-6">
                                <div className='d-flex justify-content-between w-50'>
                                    <h3>{t("-1", "Role")}:</h3>
                                    <h3>{data?.role?.role_name}
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </CustomCard>
            <CustomCard>
                <div className='w-100'>
                    <div className="card-title1 lh-1">{t("-1", "Permissions")}</div>
                </div>
                <div className='px-5 py-10'>
                    <div className="col-md-6">
                        <div className='d-flex justify-content-between w-100'>
                            <h1>Author</h1>
                            <div>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <span>TC</span>
                                        <Checkbox
                                            checked={data?.author_permissions?.terrestrial_code?? false}
                                             disabled
                                            color="primary"
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <span>TM</span>
                                        <Checkbox
                                            checked={data?.author_permissions?.terrestrial_manual?? false}
                                            disabled
                                            color="primary"
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <span>AC</span>
                                        <Checkbox
                                            checked={data?.author_permissions?.aquatic_code?? false}
                                            disabled
                                            color="primary"
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <span>AM</span>
                                        <Checkbox
                                            checked={data?.author_permissions?.aquatic_manual?? false}
                                            disabled
                                            color="primary"
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                    </div>
                                </div>
                                <div>
                                </div>
                                <div>
                                    <div style={{ display: 'flex', }}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <span>EN</span>
                                            <Checkbox
                                                checked={data?.author_permissions?.is_en?? false}
                                                disabled
                                                color="primary"
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <span>FR</span>
                                            <Checkbox
                                                checked={data?.author_permissions?.is_fr?? false}
                                                disabled
                                                color="primary"
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <span>SP</span>
                                            <Checkbox
                                                checked={data?.author_permissions?.is_es?? false}
                                                disabled
                                                color="primary"
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='px-5 py-10'>
                    <div className="col-md-6">
                        <div className='d-flex justify-content-between w-100'>
                            <h1>Validator</h1>
                            <div>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <span>TC</span>
                                        <Checkbox
                                            checked={data?.validator_permissions?.terrestrial_code?? false}
                                             disabled
                                            color="primary"
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <span>TM</span>
                                        <Checkbox
                                            checked={data?.validator_permissions?.terrestrial_manual?? false}
                                            disabled
                                            color="primary"
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <span>AC</span>
                                        <Checkbox
                                            checked={data?.validator_permissions?.aquatic_code?? false}
                                            disabled
                                            color="primary"
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <span>AM</span>
                                        <Checkbox
                                            checked={data?.validator_permissions?.aquatic_manual?? false}
                                            disabled
                                            color="primary"
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                    </div>
                                </div>
                                <div>
                                </div>
                                <div>
                                    <div style={{ display: 'flex', }}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <span>EN</span>
                                            <Checkbox
                                                checked={data?.validator_permissions?.is_en?? false}
                                                disabled
                                                color="primary"
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <span>FR</span>
                                            <Checkbox
                                                checked={data?.validator_permissions?.is_fr?? false}
                                                disabled
                                                color="primary"
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <span>SP</span>
                                            <Checkbox
                                                checked={data?.validator_permissions?.is_es?? false}
                                                disabled
                                                color="primary"
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='px-5 py-10'>
                    <div className="col-md-6">
                        <div className='d-flex justify-content-between w-100'>
                            <h1>Publisher</h1>
                            <div>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <span>TC</span>
                                        <Checkbox
                                            checked={data?.publisher_permissions?.terrestrial_code?? false}
                                             disabled
                                            color="primary"
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <span>TM</span>
                                        <Checkbox
                                            checked={data?.publisher_permissions?.terrestrial_manual?? false}
                                            disabled
                                            color="primary"
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <span>AC</span>
                                        <Checkbox
                                            checked={data?.publisher_permissions?.aquatic_code?? false}
                                            disabled
                                            color="primary"
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <span>AM</span>
                                        <Checkbox
                                            checked={data?.publisher_permissions?.aquatic_manual?? false}
                                            disabled
                                            color="primary"
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                    </div>
                                </div>
                                <div>
                                </div>
                                <div>
                                    <div style={{ display: 'flex', }}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <span>EN</span>
                                            <Checkbox
                                                checked={data?.publisher_permissions?.is_en?? false}
                                                disabled
                                                color="primary"
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <span>FR</span>
                                            <Checkbox
                                                checked={data?.publisher_permissions?.is_fr?? false}
                                                disabled
                                                color="primary"
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <span>SP</span>
                                            <Checkbox
                                                checked={data?.publisher_permissions?.is_es?? false}
                                                disabled
                                                color="primary"
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </CustomCard>
            <UserNameModel
            open={modal}
            handleClose={handleCloseModal}
            data={data}
            />
        </Container>
    )
}

export default Profile
