import React, { useEffect, useState } from "react";
import { displayNotification, sendAPIRequest } from "../../../../helpers";
import { useDispatch } from "react-redux";
import { LAST_SYSTEM_UPDATE_DATETIME } from "../../../../constants";
import { useTranslation } from "react-i18next";
import { setCurrentPage } from "redux/generalSlice";
import SiteLoader from "pages/components/SiteLoader";

const SystemStatus = () => {

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [databaseStatus, setDatabaseStatus] = useState(false);
    const [databaseStatusLoader, setDatabaseStatusLoader] = useState(false);
    const [blobStorageStatus, setBlobStorageStatus] = useState(false);
    const [blobStorageStatusLoader, setBlobStorageStatusLoader] = useState(false);

    useEffect(() => {
        loadDatabaseStatus();
        loadBlobStorageStatus();
    }, []);

    const loadDatabaseStatus = async () => {
        try {
            const req = await sendAPIRequest(`/api/devs/services-status/database`);
            const res = await req.json();
            if (res.success) {
                setDatabaseStatus(res.data);
            } else {
                displayNotification('error', `${t("137", "Error loading database status")} [B]`);
            }
        } catch (e) {
            console.log(e);
            displayNotification('error', `${t("137", "Error loading database status")} [F]`);
        }
    };

    const loadBlobStorageStatus = async () => {
        try {
            setBlobStorageStatusLoader(true);
            const req = await sendAPIRequest(`/api/devs/services-status/blob-storage`);
            const res = await req.json();
            if (res.success) {
                setBlobStorageStatus(res.data);
            } else {
                displayNotification('error', `${t("138", "Error loading blob storage status")} [B]`);
            }
            setBlobStorageStatusLoader(false);
        } catch (e) {
            setBlobStorageStatusLoader(false);
            console.log(e);
            displayNotification('error', `${t("138", "Error loading blob storage status")} [F]`);
        }
    };

    return (
        <>
            <div className="container">
                <div className="header card-woah border border-secondary d-flex px-4 py-2 shadow-sm w-100 my-2">
                    <div className="card-title1 lh-1">{t("136", "System Status")}</div>
                </div>
                <div className="card card-woah border border-secondary shadow rounded-0">
                    <div className="card-body">
                        <table className="table table-bordered border-secondary">
                            <thead className="bg-primary text-white">
                                <tr>
                                    <th>{t("143", "Services")}</th>
                                    <th>{t("144", "Status")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th>{t("145", "Database")}</th>
                                    <td>
                                        {
                                            databaseStatusLoader ? (
                                                <SiteLoader position='relative' background='transparent' size={2} alignment='left' />
                                            ) : (
                                                <>
                                                    <i
                                                        className={`fas fa-circle text-${databaseStatus ? "success" : "danger"
                                                            } me-2`}
                                                    ></i>
                                                    <span>
                                                        {databaseStatus ? t("146", "Online") : t("147", "Offline")}
                                                    </span>
                                                </>
                                            )
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <th>{t("148", "Blob Storage")}</th>
                                    <td>
                                        {
                                            blobStorageStatusLoader ? (
                                                <SiteLoader position='relative' background='transparent' size={2} alignment='left' />
                                            ) : (
                                                <>
                                                    <i
                                                        className={`fas fa-circle text-${blobStorageStatus ? "success" : "danger"
                                                            } me-2`}
                                                    ></i>
                                                    <span>
                                                        {blobStorageStatus
                                                            ? t("146", "Online")
                                                            : t("147", "Offline")}
                                                    </span>
                                                </>
                                            )
                                        }
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="mt-4">
                            <div className="my-2">
                                <strong className="me-3">
                                    {t("153", "Last System Updated")}:
                                </strong>
                                <span>{LAST_SYSTEM_UPDATE_DATETIME}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SystemStatus;
