import { displayNotification, generateOptionsFromData, sendAPIRequest } from "helpers";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams ,useLocation } from "react-router-dom";
import { LIST_DETAIL_ELEMENTS } from 'constants';
import HtmlRenderer from "components/HtmlRenderer";
import { Spinner } from "react-bootstrap";
import AutoCompleteDropdown from "components/autocomplete/AutoCompleteDropdown";

const ImageMapping = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const isCode = queryParams.get('iscode');

    const { id } = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState({
        loading_images: false,
        loading_headings: false,
    });
    const [documentImages, setDocumentImages] = useState([]);
    const [imageHeadings, setImageHeadings] = useState([]);
    const [currentItem, setCurrentItem] = useState('');
    const [selectedImage, setSelectedImage] = useState('');
    const [imageSrc, setImageSrc] = useState(null);
    const [mappedImageName, setMappedImageName] = useState('');
    const [checkCode,setCheckCode]= useState(isCode  === 'true' ? true : false)
    const[manualHeadings ,setManualHeadings] = useState([])

    useEffect(() => {
        if(!checkCode){
            loadManualImageTags()
        }else{

            loadImages();
            loadImageHeadings();
        }
        


    }, []);

    const loadImages = async () => {
        setIsLoading({ ...isLoading, loading_images: true });
        try {
            const ret = await sendAPIRequest(`/api/document-images/?document_id=${id}`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                setDocumentImages(res.data);
            }
        } catch (err) {
            console.error(err);
        }
        setIsLoading({ ...isLoading, loading_images: false });
    }
    const loadManualImageTags = async () => {
        setIsLoading({ ...isLoading, loading_images: true });
        try {
            const ret = await sendAPIRequest(`/api/document-images/get-image-tags/${id}`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                // setDocumentImages(res.data);
                console.log('.........................',res.data)
                // setManualHeadings(res.data)
            }
        } catch (err) {
            console.error(err);
        }
        setIsLoading({ ...isLoading, loading_images: false });
    }

    const loadImageHeadings = async () => {
        setIsLoading({ ...isLoading, loading_headings: true });
        try {
            const ret = await sendAPIRequest(`/api/document-images/specific-type-content/?id=${id}&text_type=${LIST_DETAIL_ELEMENTS.styles_categories.image}`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                setImageHeadings(res.data);
            }
        } catch (err) {
            console.error(err);
        }
        setIsLoading({ ...isLoading, loading_headings: false });
    }

    const selectImage = async (id) => {
        const img = documentImages.filter(di => parseInt(di.id) === parseInt(id))[0];
        setMappedImageName(img?.html_content?.content);
        sendAPIRequest(`/api/document-images/image-buffer/${id}`)
        .then((response) => response.blob())
        .then((blob) => {
            const url = window.URL.createObjectURL(
                new Blob([blob]),
            );
            setImageSrc(url);
        });
        setSelectedImage(id);
    }

    const getSelectedHeadingImage = async (id) => {
        const img = documentImages.filter(di => parseInt(di.content_id) === parseInt(id))[0];
        setMappedImageName(img?.html_content?.content);
        sendAPIRequest(`/api/document-images/heading-image/${id}`)
        .then((response) => response.blob())
        .then((blob) => {
            const url = window.URL.createObjectURL(
                new Blob([blob]),
            );
            setImageSrc(url);
        });
    }

    const handleMap = async () => {
        try {
            if (!currentItem || !selectedImage) {
                displayNotification('warning', 'Please Select image and heading for mapping');
                return;
            }
            const content_id = imageHeadings.find((i, k) => parseInt(k) === parseInt(currentItem)).id;
            const ret = await sendAPIRequest(`/api/document-images/map-image/?content_id=${content_id}&image_id=${selectedImage}`);
            const res = await ret.json();
            if (res.success) {
                displayNotification('success', 'Successfully mapped image [B]');
            } else {
                displayNotification('error', 'Failed to mapp image [B]');
            }
        } catch (err) {
            console.error(err);
            displayNotification('error', 'Failed to mapp image [F]');
        }
    }


    const goBack = () => {
        navigate(`/portal/data-migrations`);
    }

    const ImageOptions = useMemo(() => {
        return generateOptionsFromData(documentImages, { value: 'id', label: 'image_name' });
    }, [documentImages]);

    return (
        <>
            <div className="container my-2">
                <div className="row header card-woah border border-secondary d-flex px-4 py-2 shadow-sm w-100">
                    <div className='col-md-8 d-flex justify-content-between'>
                        <h1 className="col-md-4 card-title1 font-custom-2 lh-1">{t("159", "HTML Data Mapping")}</h1>
                    </div>
                    <div className="col-md-4 header-right-items ms-auto d-flex align-items-center">
                        <div className="ms-auto">
                            <button
                                className="btn btn--primary font-custom fs-6 ms-1"
                                onClick={() => goBack()}
                            >
                                {t("162", "Back")}
                            </button>
                        </div>
                        <div className="ms-auto">
                            <button
                                className="btn btn--primary font-custom fs-6 ms-1"
                                onClick={() => handleMap()}
                            >
                                {t("205", "Map")}
                            </button>
                        </div>
                    </div>
                </div>
                <div className="card card-woah border border-secondary shadow rounded-0 mt-2">
                    <div className="row">
                        <div className='col-md-2'>
                            <div
                                className='overflow-x-hidden overflow-y-auto scrollspy-inner'
                                style={{
                                    height: "75vh",
                                }}
                            >
                                {

                                    imageHeadings && imageHeadings.length > 0
                                        ? imageHeadings.map((d, index) =>
                                            <div key={d.id} className='my-1' >
                                                <div className='list-group bg-light btn-sm d-flex flex-column p-1'>
                                                    <button
                                                        disabled={isLoading.loading_headings}
                                                        className={
                                                            `${isLoading.loading_headings ? 'opacity-50' : 'opacity-100'} list-group-item rounded-0 list-group-item-action my-1 fs-5 fw-bold cursor-pointer  ${currentItem === index ? 'list-group-item-checked' : ''}`
                                                        }
                                                        onClick={() => {
                                                            getSelectedHeadingImage(d.id);
                                                            setCurrentItem(index);
                                                        }}
                                                    >
                                                        <div className='d-flex flex-column'>
                                                            <HtmlRenderer tag={'span'} htmlContent={d.content} />
                                                        </div>
                                                    </button>
                                                </div>
                                            </div>
                                        ) : 
                                        
                                       manualHeadings && manualHeadings.length > 0?
                                       manualHeadings.map((d, index) =>
                                            <div key={d.id} className='my-1' >
                                                <div className='list-group bg-light btn-sm d-flex flex-column p-1'>
                                                    <button
                                                        disabled={isLoading.loading_headings}
                                                        className={
                                                            `${isLoading.loading_headings ? 'opacity-50' : 'opacity-100'} list-group-item rounded-0 list-group-item-action my-1 fs-5 fw-bold cursor-pointer  ${currentItem === index ? 'list-group-item-checked' : ''}`
                                                        }
                                                        onClick={() => {
                                                            getSelectedHeadingImage(d.id);
                                                            setCurrentItem(index);
                                                        }}
                                                    >
                                                        <div className='d-flex flex-column'>
                                                            {/* <HtmlRenderer tag={'span'} htmlContent={d.content} /> */}
                                                            {d.html}
                                                        </div>
                                                    </button>
                                                </div>
                                            </div>
                                        ):
                                        
                                        (
                                            isLoading.loading_headings ?
                                                <Spinner message="Loading..." />
                                                : <div className='text-center'>No content available</div>
                                        )
                                }
                            </div>
                        </div>
                        <div className='col-md-10'>
                            <div className='row p-1'>
                                <div className='col-md-12'>
                                    <div
                                        className='overflow-x-hidden overflow-y-auto scrollspy-example'
                                        style={{
                                            height: "75vh",
                                        }}
                                    >
                                        <div className='d-flex justify-content-between ms-auto my-2 border-bottom border-2'>
                                            <div className="row w-100">
                                                <h2 className='p-3 lh-1 col-md-2 my-auto'>{t("207","Images")}</h2>
                                                <div className="col-md-10">
                                                    <AutoCompleteDropdown
                                                        className={isLoading.loading_images ? 'opacity-50' : 'opacity-100'}
                                                        disabled={isLoading.loading_images}
                                                        label={isLoading.loading_images ? 'loading...' : t("206", "Document Images")}
                                                        showClearIcon={true}
                                                        error={false}
                                                        required={false}
                                                        multiple={false}
                                                        hasselectall={false}
                                                        options={ImageOptions}
                                                        onChange={(event, value) => {
                                                            selectImage(value.value)
                                                        }}
                                                        value={
                                                            !selectedImage
                                                                ? { value: '', label: '' }
                                                                : ImageOptions.find((r) => parseInt(r.value) === parseInt(selectedImage)) ?? { value: '', label: '' }
                                                        }
                                                        size='small'
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex">
                                            <p><b className="my-auto">{t("208","Mapped heading:")} </b> </p>
                                            {
                                                mappedImageName ?
                                                <HtmlRenderer tag={'p'} className="my-auto" htmlContent={mappedImageName} />
                                                : <span>{t("209","No heading is linked")}</span>
                                            }
                                        </div>
                                        {
                                            imageSrc ? (
                                                <img src={imageSrc} alt="Blob-file" style={{ maxWidth: '100%' }} />
                                            ) : (
                                                <p>{t("210","No image data provided")}</p>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

ImageMapping.defaultProps = {};

export default ImageMapping;