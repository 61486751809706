import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import { useImperativeHandle } from 'react';

const HTMLSplitModal = React.forwardRef(({ children, content,  header, reloadData, rows, btn1Text, btn2Text }, ref) => {

    const { t } = useTranslation();

    const [isShow, setIsShow] = useState(false);
    const [modalOpened, setModalOpened] = useState(false);

    const [data, setData] = useState({
        updated_content: content,
        new_content: '',
        header: header,
        reloadData: reloadData,
        rows: rows,
        btn1_text: btn1Text,
        btn2_text: btn2Text,
    });

    useImperativeHandle(ref, () => {
        return {
            handleOpen
        }
    });

    const handleOpen = (content, header, reloadData, rows, btn1Text, btn2Text) => {
        if (!modalOpened) {
            content && setData(prev => ({ ...prev, updated_content: content }));
            content && setData(prev => ({ ...prev, updated_content: content }));
            header && setData(prev => ({ ...prev, header: header }));
            reloadData && setData(prev => ({ ...prev, reloadData: reloadData }));
            rows && setData(prev => ({ ...prev, rows: rows }));
            btn1Text && setData(prev => ({ ...prev, btn1_text: btn1Text }));
            btn2Text && setData(prev => ({ ...prev, btn2_text: btn2Text }));
            setModalOpened(true);
            setIsShow(true);
        }
    }

    const handleSplit = () => {
        setIsShow(false);
        setModalOpened(false);
        data.reloadData && data.reloadData(
            {
                updated_content: data.updated_content,
                new_content: data.new_content
            }
        );
        setData({});
    }

    return (
        <>
            {children(handleOpen)}
            <Modal show={isShow}>
                <Modal.Header closeButton onClick={() => (setIsShow(false), setModalOpened(false))}>
                    <Modal.Title>{t("-1", "Split Text")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form className='p-2'>
                        <textarea
                            onChange={(e) => { setData({ ...data, updated_content: e.target.value }) }}
                            rows='4'
                            className='p-2 rounded rounded-2 border border-secondary w-100'
                            value={data.updated_content}
                        >
                        </textarea>
                        <textarea
                            onChange={(e) => { setData({ ...data, new_content: e.target.value }) }}
                            rows='4'
                            className='p-2 rounded rounded-2 border border-secondary w-100'
                            value={data.new_content}
                        >
                        </textarea>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => (setIsShow(false), setModalOpened(false))}>
                        {t("-1", "Close")}
                    </Button>
                    <Button variant="dark" onClick={handleSplit}>
                        {t("-1", "Split")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
});

HTMLSplitModal.defaultProps = {
    children: () => { },
    content: "",
    header: "Split Text",
    reloadData: null,
    rows: 1,
    btn1Text: "Close",
    btn2Text: "Split",
}

export default HTMLSplitModal;