import CardBody from 'components/common/CardBody'
import CardHeader from 'components/common/CardHeader'
import Container from 'components/common/Container'
import CustomCard from 'components/common/CustomCard'
import React from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom'
import DataTable from 'pages/components/DataTable';
import { GridActionsCellItem } from '@mui/x-data-grid';

const dummyData = [
    {
        id: 1,
        error_type: '404 Not Found',
        api: '/api/users',
        error_details: 'User endpoint not found',
        status: 'true',
        actions: 'view'
    },
    {
        id: 2,
        error_type: '500 Internal Server Error',
        api: '/api/orders',
        error_details: 'Database connection failed',
        status: 'false',
        actions: 'view'
    },
    {
        id: 3,
        error_type: '401 Unauthorized',
        api: '/api/login',
        error_details: 'Invalid authentication token',
        status: 'true',
        actions: 'view'
    }
]
const ErrorsSite = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const goBack = () => {
        navigate('/portal/error-site');
    }


    const handleNavigate = (id) => {

        navigate('/portal/error-site-details');
    };



    return (
        <Container>
            <CustomCard className={'d-flex justify-content-between'}>
                <CardHeader>
                    {t("-1", "Errors Site")}
                </CardHeader>
                <CardBody>

                    <button onClick={goBack} className="btn btn-dark btn-bg-dark fs-7 lh-1">
                        {" "}
                        <i className="fas fa-angle-left" />
                        {t("-1", "Back")}
                    </button>
                </CardBody>
            </CustomCard>
            <CustomCard>
                <DataTable
                    rows={dummyData}
                    getRowId={(row) => row.id}
                    className="text-white bg-primary rounded-0"
                    columns={[
                        {
                            field: "error_type",
                            headerName: "Error Type",
                            headerClassName: "text-white bg-primary rounded-0",
                            flex: 1
                        },
                        {
                            field: "api",
                            headerName: "API",
                            headerClassName: "text-white bg-primary rounded-0",
                            flex: 1
                        },
                        {
                            field: "error_details",
                            headerName: "Error Details",
                            headerClassName: "text-white bg-primary rounded-0",
                            flex: 1
                        },
                        {
                            field: "status",
                            headerName: "Status",
                            headerClassName: "text-white bg-primary rounded-0",
                            flex: 1
                        },
                        {
                            field: "actions",
                            type: "actions",
                            headerName: "Actions",
                            headerClassName: "text-white bg-primary rounded-0",
                            flex: 1,
                            getActions: (params) => [
                                <GridActionsCellItem
                                    label="View"
                                    key={0}
                                    showInMenu
                                    onClick={() => handleNavigate(params.row.id)}
                                />,

                            ]
                        }
                    ]}
                />
            </CustomCard>
        </Container>
    )
}

export default ErrorsSite
