import React from 'react';
import PropTypes from 'prop-types';

/**
 * 
 * @param {*} children
 * @param {*} onClick
 * @param {*} varaint | [primary, secondary, success, danger, warning, info, light, dark, link]
 * @param {*} className
 * @param {*} sx
 * @returns 
 */
const ActionButton = ({ children, disabled, onClick, variant, className, sx }) => {

    const variants = {
        primary: 'bg-primary',
        secondary: 'bg-secondary',
        success: 'bg-success',
        danger: 'bg-danger',
        warning: 'bg-warning',
        info: 'bg-info',
        light: 'bg-light',
        dark: 'bg-dark',
        link: 'bg-link',
    }
    const current_variant = variants[variant] ?? 'bg-primary';
    const color = ['light', 'warning', 'info', "secondary"].includes(variant) ? "#000" : "#ffff";
    const customStyle = {
        color: color,
        ...sx
    }

    return (
        <span className={`badge ${current_variant} ${className}  ${disabled ? '' : "cursor-pointer"}`} style={customStyle} onClick={() => disabled ? '' : onClick()}>
            {children}
        </span>
    );
};

ActionButton.propTypes = {
    children: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
};

ActionButton.defaultProps = {
    children: '',
    // onClick: () => {},
    disabled: false,
    variant: 'primary',
    className: '',
    sx: {}
};

export default ActionButton;
