import React, { useEffect, useState } from 'react'
import CustomCard from 'components/CustomCard';
import CardHeader from 'components/common/CardHeader';
import CardBody from 'components/common/CardBody';
import { useTranslation } from 'react-i18next';
import TextFieldCustom from 'components/TextFieldCustom';
import AutoCompleteDropdown from 'components/autocomplete/AutoCompleteDropdown';
import { displayNotification, sendAPIRequest } from 'helpers';
import { Box, Divider, IconButton, Modal, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'react-redux';
import { LIST_DETAIL_ELEMENTS } from 'constants';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    height: 'auto',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    overflowY: 'auto',
    padding: 0,
};

const MoveSectionModal = ({ open = false, handleClose, moveSectionData, standardDetails, componentLanguage }) => {
    const [areaVolName, setAreaVolName] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [areaVolNameValue, setAreaVolNameValue] = useState('');
    const [sectionNumber, setSectionNumber] = useState();
    const { t } = useTranslation();
    const [errorMessages, setErrorMessages] = useState({
        areaVolName: '',
        sectionNumber: ''
    });


    const validateInputs = () => {
        let errors = {};
        if (moveSectionData.standard_type === LIST_DETAIL_ELEMENTS.standard_type.manuals) {
            if (!areaVolNameValue) errors.areaVolName = "Area/Vol is required";
        }
        if (!sectionNumber) {
            errors.sectionNumber = "Section Number is required";
        } else if (isNaN(sectionNumber) || parseInt(sectionNumber) <= 0) {
            errors.sectionNumber = "Section Number must be a positive number";
        }

        setErrorMessages(errors);
        return Object.keys(errors).length === 0;
    };

    const getAreaVolData = async () => {
        try {
            const ret = await sendAPIRequest(`/api/draft-standard-structure/get-area-volume/?animal_type=${moveSectionData?.animal_type}&standard_type=${moveSectionData?.standard_type}&lang_id=${moveSectionData?.lang_id}&version_year=${standardDetails?.version_year}`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                setAreaVolName(res.data);
            } else {
                displayNotification("error", "Failed to get Area Volume names.");
            }
        } catch (e) {
            console.log({ e });
        }
    }

    const saveUploadModal = async () => {
        if (!validateInputs()) return;
        if (!window.confirm('Are you sure you want to move this section?')) {
            return;
        }
        setIsLoading(true);
        try {
            let targetAreaVolNo = areaVolNameValue?.value
            if (moveSectionData?.standard_type === LIST_DETAIL_ELEMENTS?.standard_type?.codes && moveSectionData?.animal_type === LIST_DETAIL_ELEMENTS?.animal_type?.terrestrial) {
                if (sectionNumber > 7) {
                    targetAreaVolNo = 2
                }
                else {
                    targetAreaVolNo = 1
                }
            };
            const requestId = Date.now().toString();
            const ret = await sendAPIRequest(`/api/draft-chapter/move-section`, 'POST', {
                "id": moveSectionData?.id,
                "area_vol_no": moveSectionData?.area_vol_no ?? '',
                "section_no": moveSectionData?.section_no,
                "section_name": moveSectionData?.section_name,
                "lang_id": moveSectionData?.lang_id,
                "animal_type": moveSectionData?.animal_type,
                "standard_type": moveSectionData?.standard_type,
                "target_area_vol_no": targetAreaVolNo ?? '',
                "target_area_vol_name": areaVolNameValue?.label ?? '',
                "target_section_no": sectionNumber,
                "requestId": requestId
            });
            const res = await ret.json()
            if (res.success && res.status === 200) {
                await pollMoveStatus(requestId);
                // setAreaVolName(res.data);
                // handleClose();
                // displayNotification("success", res?.message);
            }
            else if (res.success === false && res.status === 409) {
                displayNotification("warning", res?.message);
            }
            else {
                displayNotification('error', 'Error loading data [B]');
            }
        } catch (e) {
            console.log({ e });
            displayNotification('error', 'Error loading data [F]');
        }
        finally {
            setIsLoading(false);
        }
    };

    const pollMoveStatus = async (requestId) => {
        while (true) {
            try {
                const response = await sendAPIRequest(`/api/draft-chapter/move-section/status/?requestId=${requestId}`);
                const res = await response.json();
                if (res.statusCode === 1) {
                    // setAreaVolName([]);
                    handleClose();
                    displayNotification("success", res?.message);
                    break;
                }
                else {
                    displayNotification("info", res?.message);
                }
            } catch (e) {
                console.log("Polling error:", e);
            }
            await new Promise(resolve => setTimeout(resolve, 10000));
        }
    };

    useEffect(() => {
        getAreaVolData();
    }, [])
    return (
        <Modal
            open={open}
            onClose={() => handleClose(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Box sx={{ padding: '10px 20px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Move Section
                    </Typography>
                    <IconButton disabled={isLoading} onClick={() => handleClose(false)}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Divider sx={{ backgroundColor: '#000', margin: '0' }} />
                <Box sx={{ paddingX: '20px', paddingBottom: '20px' }}>
                    <div className='row pt-2'>
                        <Typography variant="h7" sx={{ fontWeight: 'bold', marginBottom: '20px' }}>
                            Previous Structure:
                        </Typography>
                        { }
                        {moveSectionData?.standard_type === LIST_DETAIL_ELEMENTS?.standard_type?.manuals ?
                            <div className='col-6'>
                                <TextFieldCustom
                                    variant="outlined"
                                    id="Section"
                                    label={t("-1", "Area/Vol")}
                                    fullWidth
                                    rows={4}
                                    disabled={true}
                                    sx={{
                                        backgroundColor: "white",
                                        color: "white",
                                        border: "#ff4815",
                                        width: "99%",
                                    }}
                                    value={moveSectionData?.area_vol_name}
                                    size='medium'
                                />
                            </div>
                            : null}
                        <div className='col-6'>
                            <TextFieldCustom
                                variant="outlined"
                                id="Section"
                                label={t("-1", "Section No")}
                                fullWidth
                                rows={4}
                                disabled={true}
                                sx={{
                                    backgroundColor: "white",
                                    color: "white",
                                    border: "#ff4815",
                                    width: "99%",
                                }}
                                value={moveSectionData?.section_no}
                                size='medium'
                            />

                        </div>
                    </div>
                    <div className='row pt-3'>
                        <Typography variant="h7" sx={{ fontWeight: 'bold', marginY: '10px' }}>
                            New Structure:
                        </Typography>
                        {moveSectionData?.standard_type === LIST_DETAIL_ELEMENTS?.standard_type?.manuals ?
                            <div className='col-6 pe-1'>
                                <AutoCompleteDropdown
                                    label='Area/Vol'
                                    className='opacity-100'
                                    isGroup={false}
                                    showClearIcon={true}
                                    error={!!errorMessages.areaVolName}
                                    helperText={errorMessages.areaVolName}
                                    required={true}
                                    multiple={false}
                                    hasselectall={false}
                                    options={areaVolName}
                                    value={areaVolNameValue}
                                    onChange={(event, value) => {
                                        if (value) {
                                            setAreaVolNameValue(value)
                                            setErrorMessages({ ...errorMessages, areaVolName: '' });
                                        }
                                        else {
                                            setAreaVolNameValue('')
                                        }
                                    }}
                                    size='medium'
                                    disabled={isLoading}
                                />
                            </div>
                            : null}
                        <div className='col-6 pt-2'>
                            <TextFieldCustom
                                variant="outlined"
                                id="Section"
                                label={t("-1", "Section No")}
                                fullWidth
                                multiline={false}
                                rows={4}
                                required={true}
                                error={!!errorMessages.sectionNumber}
                                helperText={errorMessages.sectionNumber}
                                sx={{
                                    backgroundColor: "white",
                                    color: "white",
                                    border: "#ff4815",
                                    width: "99%",
                                }}
                                value={sectionNumber}
                                onChange={(e) => {
                                    setSectionNumber(e.target.value);
                                    setErrorMessages({ ...errorMessages, sectionNumber: '' });
                                }}
                                disabled={isLoading}
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-12 text-center mt-3">
                            <button
                                className='btn btn--outline font-custom fs-6'
                                onClick={() => { handleClose() }}
                                disabled={isLoading}
                            >
                                Close
                            </button>
                            <button
                                className="btn btn--primary font-custom fs-6 ms-2"
                                onClick={() => saveUploadModal()}
                                disabled={isLoading}
                            >
                                {isLoading ? "Saving..." : "Save"}
                            </button>
                        </div>
                    </div>
                </Box>
            </Box>
        </Modal>
    )
}

export default MoveSectionModal
