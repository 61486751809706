import { Tooltip } from "@mui/material";
import TextFieldCustom from "components/TextFieldCustom";
import AutoCompleteDropdown from "components/autocomplete/AutoCompleteDropdown";
import { displayNotification, generateOptionsFromData, sendAPIRequest } from "helpers";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { setCurrentPage } from "redux/generalSlice";
import { GridActionsCellItem } from "@mui/x-data-grid";
import DataTable from "pages/components/DataTable";
import FiltersData from "./FiltersData";
import { Box, Tab, Tabs } from '@mui/material';
import CommonTab from "components/common/CommonTab";
import DynamicFiltersData from "./DynamicFiltersData";
import CustomTabPanel from 'pages/components/CustomTabPanel';

const ChapterTaging = () => {

    const { t } = useTranslation();
    const [location, setLocation] = useSearchParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id } = useParams();
    const { chapter_id} = {
        chapter_id: location.get('chapter_id'),
    };
    const [value, setValue] = React.useState(0);
    const initForm = {
        commodity_group_id: '',
        commodity_id: '',
        animal_id: '',
        recommendation_id: '',
        status_type_id: '',
        disease_id: '',
        section_id: '',
        chapter_id: '',
        article_id: '',
        draft_chapter_id:chapter_id

    }

    const [form, setForm] = useState(initForm);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoading_filters, setIsLoading_filters] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [data, setData] = useState([]);
    const [dynamicFilterData, setDynamicFilterData] = useState([]);


    useEffect(() => {
        dispatch(setCurrentPage("administration"));
        loadData();
    }, []);

    useEffect(() => {
        if(form.draft_chapter_id){
            loadDynamicFiltersData()
        }
    }, [form.draft_chapter_id]);

    const loadData = async () => {
        setIsLoading(true);
        try {
            const ret = await sendAPIRequest(`/api/taging/draft-animal-linking/?chapter_id=${chapter_id}`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                setData(res.data);
            }
        } catch (err) {
            console.log({ err });
        }
        setIsLoading(false);
    }

    const loadDynamicFiltersData = async () => {
        setIsLoading_filters(true);
        try {
            const ret = await sendAPIRequest(`/api/dynamic-filters/chapter-filters/?chapter_id=${chapter_id}`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                setDynamicFilterData(res.data);
            }
        } catch (err) {
            console.log({ err });
        }
        setIsLoading_filters(false);
    }

 

    const handleSave = async () => {
        setIsSaving(true);
        try {
            if (!window.confirm('Are you sure you want to perform operation?')) {
                return;
            }
            let ret;
            if (id) {
                ret = await sendAPIRequest(`/api/draft-commodities-animals/${id}`, 'PUT', form);
            } else {
                ret = await sendAPIRequest(`/api/draft-commodities-animals`, 'POST', form);
            }
            const res = await ret.json();
            if (res.success && res.status === 200) {
                displayNotification('success', 'Successfully completed request [B]');
            } else {
                displayNotification('error', 'Failed to complete request [B]');
            }
        } catch (err) {
            console.log({ err });
            displayNotification('error', 'Failed to complete request [F]');
        }
        setIsSaving(false);
    }

   
    
    const handleDelete = async (id) => {
        try {
            if (!window.confirm('Are you sure you want to delete?')) {
                return;
            }
            const ret = await sendAPIRequest(`/api/taging/draft-animal-linking/${id}`, 'DELETE');
            const res = await ret.json();
            if (res.success && res.status === 200) {
                await loadData();
                displayNotification('success', 'Successfully deleted data [B]');
            } else {
                displayNotification('error', 'Failed to delete data [B]');
            }
        } catch (err) {
            console.error({ err });
            displayNotification('error', 'Failed to delete data [F]');
        }
    }
    
    const handleDynamicFilterDelete = async (id) => {
        try {
            if (!window.confirm('Are you sure you want to delete?')) {
                return;
            }
            const ret = await sendAPIRequest(`/api/dynamic-filters/${id}`, 'DELETE');
            const res = await ret.json();
            if (res.success && res.status === 200) {
                await loadDynamicFiltersData();
                displayNotification('success', 'Successfully deleted data [B]');
            } else {
                displayNotification('error', 'Failed to delete data [B]');
            }
        } catch (err) {
            console.error({ err });
            displayNotification('error', 'Failed to delete data [F]');
        }
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    return (
        <div>
        <div className="container my-2">
            <div className="header card-woah border border-secondary d-flex px-4 my-2 py-2 shadow-sm w-100">
                <div className='d-flex justify-content-between w-100'>
                    <div className="card-title1 lh-1">{t("-1", "Chapter Taging")}{chapter_id?data.chapter_name:""}</div>
                    <button
                        className="btn btn--primary font-custom fs-6 ms-1"
                        onClick={() => navigate(`/portal/draft/taging/chapter/create/?chapter_id=${chapter_id}`)}
                    >
                        {t("-1", "Add New")}
                    </button>
                </div>
            </div>
            <Box sx={{ width: '100%', bgcolor: 'background.paper', marginBottom: '10px' }}>
                    <Tabs value={value} onChange={handleChange} centered>
                        <CommonTab label="Animal/Disease Filters" />
                        <CommonTab label="Dynamic Filters" />
                    </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0}>
                        <FiltersData 
                        data = {data}
                        isLoading = {isLoading} 
                        handleDelete = {handleDelete} />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>
                    <DynamicFiltersData
                    data = {dynamicFilterData}
                    isLoading = {isLoading_filters}
                    handleDelete={handleDynamicFilterDelete}
                    />
                    </CustomTabPanel>
             

           
        </div>
    </div>
    );
}

export default ChapterTaging;
