import Button from "components/common/Button";
import CardBody from "components/common/CardBody";
import CardHeader from "components/common/CardHeader";
import Container from "components/common/Container";
import CustomCard from "components/common/CustomCard";
import { displayNotification, sendAPIRequest, toCapitalizedFirstLowerCaseRest } from "helpers";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import LinkIcon from '@mui/icons-material/Link';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import Spinner from "components/spinner/Spinner";
import { TRANSFER_STATUS } from "constants";
import StandardStructureModal from "components/modals/StandardStructureModal";
import PublishedLinksModal from "components/modals/PublishedLinksModal";
import { useSearchParams } from 'react-router-dom';
import { LIST_DETAIL_ELEMENTS } from "constants";

const HandlePublication = () => {

    const { id } = useParams();
    const { t } = useTranslation();
    const [location,] = useSearchParams();

    const { is_preview } = {
        is_preview: location.get('is_preview'),

    };

    const [showStructureModal, setShowStructureModal] = useState(false);

    const [draftInfo, setDraftInfo] = useState({});
    const [canPublish, setCanPublish] = useState(false);
    const [chapterinfo, setChapterInfo] = useState(false);
    const [isPublished, setIsPublished] = useState(false);
    const [updatedStructure, setUpdatedStructure] = useState(false);
    const [publishedLinks, setPublishedLinks] = useState([]);
    const [showPublishedLinksModal, setShowPublishedLinksModal] = useState(false);
    const [loaders, setLoaders] = useState({
        header_text: false,
        publishing: false,
        loading_draft_info: false,
        loading_permissions: false,
        removing_published_tagging: false,
        publishing_article_tagging: false,
        removing_published_chapter_tagging: false,
        publishing_chapter_tagging: false,
        deleting_and_inserting_content: false,
        publishing_glossary: false,
        applying_glossary: false,
        migrating_links: false,
        migrating_structures: false,
    });
    const [publicationStatus, setPublicationStatus] = useState({});

    const chapterName = draftInfo?.chapter_name || '';
    const standardShortName = draftInfo?.standard_short_name || '';

    useEffect(() => {
        settingUp();
    }, [id]);

    useEffect(() => {
        if (!draftInfo.is_new && chapterinfo && !is_preview && !draftInfo.is_structure_change) {
            publishDraft();
        } else {
            if (!draftInfo.is_new && chapterinfo && !is_preview && canPublish) {
                publishDraft();
            }
        }
    }, [draftInfo, chapterinfo, canPublish]);

    useEffect(() => {
        if (canPublish) {
            getPublicationStatus();
        }
    }, [canPublish]);

    const settingUp = async () => {
        const t = await getDraftInfo();
        if (t.is_structure_change) {
            await hasPermission();
            await isDraftPublished();
        }
    }


    // Nota-Bini API in progress, Will Implement this later (Further disscussion with Team)
    // const NB = async () => {
    //     const ret = await sendAPIRequest(`/api/handle-publication/set-nb/${id}`)
    //     const res = await ret.json();
    // }

    const getDraftInfo = async () => {
        setLoaders({ ...loaders, loading_draft_info: true });
        try {
            const ret = await sendAPIRequest(`/api/handle-publication/draft-info/${id}`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                console.log('data', res.data)
                setDraftInfo(res.data);
                setChapterInfo(true);
                setLoaders({ ...loaders, loading_draft_info: false });
                return res.data;
            } else {
                setLoaders({ ...loaders, loading_draft_info: false });
                return {};
            }
        } catch (e) {
            console.log({ e });
        }
        setLoaders({ ...loaders, loading_draft_info: false });
    }

    const hasPermission = async () => {
        setLoaders({ ...loaders, loading_permissions: true });
        try {
            const ret = await sendAPIRequest(`/api/handle-publication/check-permissions/${id}`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                setCanPublish(true);
            } else {
                displayNotification("error", "User has no permission to publish");
            }
        } catch (e) {
            console.log({ e });
        }
        setLoaders({ ...loaders, loading_permissions: false });
    }

    const getPublicationStatus = async () => {
        try {
            const ret = await sendAPIRequest(`/api/handle-publication/status/${id}`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                setPublicationStatus(res.data);
            }
        } catch (e) {
            console.log({ e });
        }
    }

    const removePublishedArticleTagging = async () => {
        setLoaders({ ...loaders, removing_published_tagging: true });
        try {
            const ret = await sendAPIRequest(`/api/handle-publication/remove-published-tags/${id}`);
            const res = await ret.json();
            setLoaders({ ...loaders, removing_published_tagging: false });
            if (res.success && res.status === 200) {
                return true;
            }
            return false;
        } catch (e) {
            console.log({ e });
        }
        setLoaders({ ...loaders, removing_published_tagging: false });
        return false;
    }

    const publishArticleTagging = async () => {
        setLoaders({ ...loaders, publishing_article_tagging: true });
        try {
            const ret = await sendAPIRequest(`/api/handle-publication/publish-article-tags/${id}`);
            const res = await ret.json();
            setLoaders({ ...loaders, publishing_article_tagging: false });
            if (res.success && res.status === 200) {
                return true;
            }
            return false;
        } catch (e) {
            console.log({ e });
        }
        setLoaders({ ...loaders, publishing_article_tagging: false });

        return false;
    }

    const removePublishedChapterTagging = async () => {
        setLoaders({ ...loaders, removing_published_chapter_tagging: true });
        try {
            const ret = await sendAPIRequest(`/api/handle-publication/remove-published-chapter-tags/${id}`);
            const res = await ret.json();
            setLoaders({ ...loaders, removing_published_chapter_tagging: false });
            if (res.success && res.status === 200) {
                return true;
            }
            return false;
        } catch (e) {
            console.log({ e });
        }
        setLoaders({ ...loaders, removing_published_chapter_tagging: false });
        return false;
    }

    const publishChapterTagging = async () => {
        setLoaders({ ...loaders, publishing_chapter_tagging: true });
        try {
            const ret = await sendAPIRequest(`/api/handle-publication/publish-chapter-tags/${id}`);
            const res = await ret.json();
            setLoaders({ ...loaders, publishing_chapter_tagging: false });
            if (res.success && res.status === 200) {
                return true;
            }
            return false;
        } catch (e) {
            console.log({ e });
        }
        setLoaders({ ...loaders, publishing_chapter_tagging: false });
        return false;
    }

    const publishDraftContent = async () => {
        setLoaders({ ...loaders, deleting_and_inserting_content: true });
        try {
            const ret = await sendAPIRequest(`/api/handle-publication/publish-draft-content/${id}`);
            const res = await ret.json();
            setLoaders({ ...loaders, deleting_and_inserting_content: false });
            if (res.success && res.status === 200) {
                return true;
            }
            return false;
        } catch (e) {
            console.log({ e });
        }
        setLoaders({ ...loaders, deleting_and_inserting_content: false });
        return false;
    }

    const publishDraftGlossary = async () => {
        setLoaders({ ...loaders, publishing_glossary: true });
        try {
            const ret = await sendAPIRequest(`/api/handle-publication/publish-draft-glossary/${id}`);
            const res = await ret.json();
            setLoaders({ ...loaders, publishing_glossary: false });
            if (res.success && res.status === 200) {
                return true;
            }
            return false;
        } catch (e) {
            console.log({ e });
        }
        setLoaders({ ...loaders, publishing_glossary: false });
        return false;
    }

    const applyGlossary = async () => {
        setLoaders({ ...loaders, applying_glossary: true });
        try {
            const ret = await sendAPIRequest(`/api/handle-publication/apply-glossary/${id}`);
            const res = await ret.json();
            setLoaders({ ...loaders, applying_glossary: false });
            if (res.success && res.status === 200) {
                return true;
            }
            return false;
        } catch (e) {
            console.log({ e });
        }
        setLoaders({ ...loaders, applying_glossary: false });
        return false;
    }

    const markAsPublish = async () => {
        try {
            let isMinor = false;
            if (!draftInfo.is_structure_change) {
                isMinor = true
            }
            const ret = await sendAPIRequest(`/api/handle-publication/mark-status/${id}/?is_minor=${isMinor}`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                return true;
            }
            return false;
        } catch (e) {
            console.log({ e });
        }
        return false;
    }

    const isDraftPublished = async () => {
        try {
            const ret = await sendAPIRequest(`/api/handle-publication/is-published/${id}`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                if (res.data.transfer_status === TRANSFER_STATUS.published) {
                    setIsPublished(true);
                } else {
                    setIsPublished(false);
                }
            }
            return false;
        } catch (e) {
            console.log({ e });
        }
        return false;
    }

    const startPublication = async () => {
        try {
            const ret = await sendAPIRequest(`/api/handle-publication/start-published/${id}`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                return true
            }
            return false;
        } catch (e) {
            console.log({ e });
        }
        return false;
    }

    const migrateLinks = async () => {
        setLoaders({ ...loaders, migrating_links: true });
        try {
            const ret = await sendAPIRequest(`/api/handle-publication/published-links/${id}`);
            const res = await ret.json();
            setLoaders({ ...loaders, migrating_links: false });
            if (res.success && res.status === 200) {
                setPublishedLinks(res.data);
                return true;
            }
            return false;
        } catch (e) {
            console.log({ e });
        }
        setLoaders({ ...loaders, migrating_links: false });
        return false;
    }

    const migrateStructure = async () => {
        setLoaders({ ...loaders, migrating_structures: true });
        try {
            const ret = await sendAPIRequest(`/api/handle-publication/migrate-structure/${id}`);
            const res = await ret.json();
            setLoaders({ ...loaders, migrating_structures: false });
            if (res.success && res.status === 200) {
                return true;
            }
            return false;
        } catch (e) {
            console.log({ e });
        }
        setLoaders({ ...loaders, migrating_structures: false });
        return false;
    }

    const publishDraft = async () => {
        try {
            setLoaders({ ...loaders, publishing: true });
            const is_started = await startPublication();
            if (!is_started) {
                setLoaders({ ...loaders, publishing: false });
                return;
            }
            if (draftInfo.is_new) {
                if (publicationStatus?.publish_structure !== 2) {
                    await getPublicationStatus();
                    const is_migrated_structure = await migrateStructure();
                    if (!is_migrated_structure) {
                        setLoaders({ ...loaders, publishing: false });
                        return;
                    };
                }
            }
            if (publicationStatus?.publish_article_tagging !== 2) {
                await getPublicationStatus();
                const is_published_article_tags = await publishArticleTagging();
                if (!is_published_article_tags) {
                    setLoaders({ ...loaders, publishing: false });
                    return;
                };
            }
            // if (publicationStatus?.removed_published_article_tagging !== 2 ) {
            //     const is_removed = await removePublishedArticleTagging();
            //     await getPublicationStatus();
            //     if (!is_removed) {
            //         setLoaders({ ...loaders, publishing: false });
            //         return;
            //     }
            // }
            // if (publicationStatus?.removed_published_chapter_tagging !== 2) {
            //     await getPublicationStatus();
            //     const is_removed_chapter_tagging = await removePublishedChapterTagging();
            //     if (!is_removed_chapter_tagging) {
            //         setLoaders({ ...loaders, publishing: false });
            //         return;
            //     };
            // }
            if (publicationStatus?.publish_chapter_tagging !== 2) {
                await getPublicationStatus();
                const is_published_chapter_tagging = await publishChapterTagging();
                if (!is_published_chapter_tagging) {
                    setLoaders({ ...loaders, publishing: false });
                    return;
                };
            }
            if (publicationStatus?.publish_content !== 2) {
                await getPublicationStatus();
                const is_published_draft_content = await publishDraftContent();
                if (!is_published_draft_content) {
                    setLoaders({ ...loaders, publishing: false });
                    return;
                };
            }
            if (publicationStatus?.publish_glossary !== 2) {
                await getPublicationStatus();
                const is_published_draft_glossary = await publishDraftGlossary();
                if (!is_published_draft_glossary) {
                    setLoaders({ ...loaders, publishing: false });
                    return;
                };
            }
            if (draftInfo.is_new) {
                if (publicationStatus?.updating_links !== 2) {
                    await getPublicationStatus();
                    const is_migrated_links = await migrateLinks();
                    if (!is_migrated_links) {
                        setLoaders({ ...loaders, publishing: false });
                        return;
                    };
                }
            }
            if (publicationStatus?.apply_published_glossary !== 2) {
                await getPublicationStatus();
                const is_applied_glossary = await applyGlossary();
                if (!is_applied_glossary) {
                    setLoaders({ ...loaders, publishing: false });
                    return;
                };
            }
            const is_published = await markAsPublish();
            await getPublicationStatus();
            if (!is_published) {
                setLoaders({ ...loaders, publishing: false });
                return;
            };
            await isDraftPublished();
            setLoaders({ ...loaders, publishing: false });
        } catch (e) {
            console.log({ e });
            setLoaders({ ...loaders, publishing: false });
        }
    }

    return (
        <Container>
            {
                showStructureModal && <StandardStructureModal draftID={id} children={handleOpen => handleOpen()} reloadData={(isSet) => { setShowStructureModal(false); setUpdatedStructure(isSet) }} />
            }
            {
                showPublishedLinksModal && <PublishedLinksModal children={handleOpen => handleOpen()} data={publishedLinks} reloadData={() => setShowPublishedLinksModal(false)} />
            }
            <CustomCard className="d-flex justify-content-between">
                <CardHeader>
                    {
                        loaders.header_text
                            ? <Spinner message="loading..." />
                            : `Draft - ${toCapitalizedFirstLowerCaseRest(chapterName)} (${standardShortName})`
                    }
                </CardHeader>
                {/* <CardBody>
                    {
                        draftInfo.is_new && (
                            <div>
                                <Button
                                    onClick={async () => await publishDraft()}
                                    disabled={(!canPublish || loaders.publishing || isPublished)}
                                >
                                    {
                                        loaders.publishing
                                            ? <Spinner message="Publishing..." />
                                            : "Publish Draft"
                                    }
                                </Button>
                                {
                                    draftInfo?.is_new &&
                                    <Button
                                        disabled={!canPublish || loaders.publishing || isPublished}
                                        onClick={() => setShowStructureModal(true)}
                                        className='m-1'
                                    >
                                        Set Structure
                                    </Button>
                                }
                            </div>
                        )
                    }
                </CardBody> */}
            </CustomCard>
            <CustomCard className="d-flex justify-content-center align-items-center">
                <div>
                    {
                        draftInfo.is_new && (
                            <div>
                                {
                                    draftInfo?.is_new &&
                                    <Button
                                        disabled={!canPublish || loaders.publishing || isPublished}
                                        onClick={() => setShowStructureModal(true)}
                                        className='m-1'
                                    >
                                        Set Structure
                                    </Button>
                                }
                                {
                                    draftInfo?.is_new &&
                                    <Button
                                        disabled={!canPublish || loaders.publishing || isPublished}
                                        onClick={() => window.open(`/portal/draft/taging/chapter/?chapter_id=${draftInfo?.id ?? ''}`, '_blank')}
                                        className='m-1'
                                    >
                                        Chapter Tagging
                                    </Button>
                                }
                                {
                                    draftInfo?.is_new &&
                                    <Button
                                        disabled={!canPublish || loaders.publishing || isPublished}
                                        onClick={() => window.open(`/portal/draft/edit/${draftInfo?.id ?? ''}/?is_code=${draftInfo?.standard_type === LIST_DETAIL_ELEMENTS.standard_type.codes ? true : false ?? ''}`, '_blank')}
                                        className='m-1'
                                    >
                                        Commodity Tagging
                                    </Button>
                                }
                                <Button
                                    onClick={async () => await publishDraft()}
                                    disabled={(!canPublish || loaders.publishing || isPublished)}
                                >
                                    {
                                        loaders.publishing
                                            ? <Spinner message="Publishing..." />
                                            : "Publish Draft"
                                    }
                                </Button>
                            </div>
                        )
                    }
                </div>
            </CustomCard>
            <CustomCard>
                <CardBody>
                    <div>
                        <>
                            <div className="d-flex align-items-center my-1">
                                {
                                    loaders.loading_draft_info
                                        ? <>
                                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            <span class="visually-hidden">Loading...</span>
                                            <span className="ms-1">Getting information...</span>
                                        </>
                                        : chapterinfo
                                            ? <i><CheckCircleOutlineIcon color="success" /> Information Fetched</i>
                                            : <i><HighlightOffIcon color="error" /> Failed To Fetch Information</i>
                                }
                            </div>
                            {/* {
                                chapterinfo &&
                                <div className="d-flex align-items-center my-1">
                                    {
                                        loaders.loading_permissions
                                            ? <>
                                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                <span class="visually-hidden">Loading...</span>
                                                <span className="ms-1">Checking for permissions...</span>
                                            </>
                                            : canPublish
                                                ? <i><CheckCircleOutlineIcon color="success" /> Can Publish</i>
                                                : <i><HighlightOffIcon color="error" /> Can Publish</i>
                                    }
                                </div>
                            } */}
                            {
                                draftInfo.is_new &&
                                (
                                    loaders.migrating_structures
                                        ? <>
                                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            <span class="visually-hidden">Loading...</span>
                                            <span className="ms-1">Migrating structure...</span>
                                        </>
                                        : <GetPublicationProgress status={publicationStatus?.publish_structure}
                                            loading_text={"Loading"}
                                            pending_text={"Migrate Structure"}
                                            success_text={"Successfully Migrated Structure"}
                                            fail_text={"Failed To Migrate Structure"}
                                        />
                                )
                            }
                            {/* <div className="d-flex align-items-center my-1">
                                {
                                    loaders.removing_published_tagging
                                        ? <>
                                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            <span class="visually-hidden">Loading...</span>
                                            <span className="ms-1">Removing published article tagging...</span>
                                        </>
                                        : <GetPublicationProgress status={publicationStatus?.removed_published_article_tagging}
                                            loading_text={"Loading"}
                                            pending_text={"Remove Article Level Tagging"}
                                            success_text={"Article Level Tagging Removed"}
                                            fail_text={"Failed To Remove Article Level Tagging"}
                                        />
                                }
                            </div> */}
                            <div className="d-flex align-items-center my-1">
                                {

                                    loaders.publishing_article_tagging
                                        ? <>
                                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            <span class="visually-hidden">Loading...</span>
                                            <span className="ms-1">Publishing commodity tagging...</span>
                                        </>
                                        : <GetPublicationProgress status={publicationStatus?.publish_article_tagging}
                                            loading_text={"Loading"}
                                            pending_text={"Apply Article Level Tagging"}
                                            success_text={"Article Level Tagging Applied"}
                                            fail_text={"Failed To Apply Article Level Tagging"}
                                        />
                                }
                            </div>
                            {/* <div className="d-flex align-items-center my-1">
                                {

                                    loaders.removing_published_chapter_tagging
                                        ? <>
                                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            <span class="visually-hidden">Loading...</span>
                                            <span className="ms-1">Removing published chapter tagging...</span>
                                        </>
                                        : <GetPublicationProgress status={publicationStatus?.publish_article_tagging}
                                            loading_text={"Loading"}
                                            pending_text={"Remove Chapter Level Tagging"}
                                            success_text={"Chapter Level Tagging Removed"}
                                            fail_text={"Failed To Remove Chapter Level Tagging"}
                                        />
                                }
                            </div> */}
                            <div className="d-flex align-items-center my-1">
                                {

                                    loaders.publishing_chapter_tagging
                                        ? <>
                                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            <span class="visually-hidden">Loading...</span>
                                            <span className="ms-1">Publishing chapter tagging...</span>
                                        </>
                                        : <GetPublicationProgress status={publicationStatus?.publish_chapter_tagging}
                                            loading_text={"Loading"}
                                            pending_text={"Apply Chapter Tagging"}
                                            success_text={"Chapter Tagging Applied"}
                                            fail_text={"Failed To Apply Chapter Tagging"}
                                        />
                                }
                            </div>
                            <div className="d-flex align-items-center my-1">
                                {

                                    loaders.deleting_and_inserting_content
                                        ? <>
                                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            <span class="visually-hidden">Loading...</span>
                                            <span className="ms-1">Publishing draft content...</span>
                                        </>
                                        : <GetPublicationProgress status={publicationStatus?.publish_content}
                                            loading_text={"Loading"}
                                            pending_text={"Publish Draft Content"}
                                            success_text={"Draft Content Published"}
                                            fail_text={"Failed To Publish Content"}
                                        />
                                }
                            </div>
                            <div className="d-flex align-items-center my-1">
                                {

                                    loaders.publishing_glossary
                                        ? <>
                                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            <span class="visually-hidden">Loading...</span>
                                            <span className="ms-1">Publishing glossary...</span>
                                        </>
                                        : <GetPublicationProgress status={publicationStatus?.publish_glossary}
                                            loading_text={"Loading..."}
                                            pending_text={"Publish Glossary"}
                                            success_text={"Glossary Invloved Published"}
                                            fail_text={"Failed To Publish Glossary"}
                                        />
                                }
                            </div>
                            {
                                draftInfo.is_new &&
                                (
                                    loaders.migrating_links
                                        ? <>
                                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            <span class="visually-hidden">Loading...</span>
                                            <span className="ms-1">Updating pulished links...</span>
                                        </>
                                        :
                                        <div className="d-flex align-items-center my-1">
                                            <GetPublicationProgress status={publicationStatus?.updating_links} loading_text={"Loading"} pending_text={"Updated Published Links"} success_text={"Updated Published Links"} fail_text={"Failed To Update Published Links"} />
                                            {/* <i className="cursor-pointer" onClick={() => setShowPublishedLinksModal(true)}><LinkIcon color="primary" /> Open Link Management</i> */}
                                        </div>
                                )
                            }
                            <div className="d-flex align-items-center my-1">
                                {
                                    loaders.applying_glossary
                                        ? <>
                                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            <span class="visually-hidden">Loading...</span>
                                            <span className="ms-1">Applying glossary...</span>
                                        </>
                                        : <GetPublicationProgress status={publicationStatus?.apply_published_glossary}
                                            loading_text={"Loading"}
                                            pending_text={"Apply Glossary on content"}
                                            success_text={"Applied Glossary On All Content"}
                                            fail_text={"Failed To Apply Glossary"}
                                        />
                                }
                            </div>
                        </>
                        {
                            isPublished
                            && (
                                <div className="d-flex align-items-center my-1">
                                    {
                                        <i><CheckCircleOutlineIcon color="success" /> Published ! &#129321;</i>
                                    }
                                </div>
                            )
                        }
                    </div>
                </CardBody>
            </CustomCard>
        </Container>
    );
}

const GetPublicationProgress = ({ status, loading_text, pending_text, success_text, fail_text }) => {
    if (status === 0) {
        return <i><PendingOutlinedIcon color='secondary' /> {pending_text}</i>;
    }
    if (status === 1) {
        return <>
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span class="visually-hidden">Loading...</span>
            <span className="ms-1">{loading_text}...</span>
        </>;
    }
    if (status === 2) {
        return <i><CheckCircleOutlineIcon color="success" /> {success_text}</i>;
    }
    if (status === 3) {
        return <i><HighlightOffIcon color="error" /> {fail_text}</i>;
    }
    return null;
}


export default HandlePublication;