import Container from 'components/common/Container'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import CustomCard from 'components/common/CustomCard';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import SectionChapterCordianComponent from './Components/SectionChapterCordianComponent';
import { displayNotification, sendAPIRequest } from 'helpers';
import Loader from 'components/common/Loader';
import { LIST_DETAIL_ELEMENTS } from 'constants';
import GlossaryAccordionComponent from './Components/GlossaryAccordionComponent';
import { setCurrentPage } from 'redux/generalSlice';

const PublishingStandardsDetails = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [location] = useSearchParams();
    const siteLanguage = useSelector(s => s.general.siteLanguageSont);
    const [componentLanguage, setComponentLanguage] = useState(siteLanguage ?? 102);
    const { t } = useTranslation();
    const [data, setData] = useState([])
    const headerTheme = useSelector((s) => s.general.headerTheme);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [structureStatus, setStructureStatus] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const dropdownRef = useRef(null);
    const [standardDetails, setStandardDetails] = useState({
        standard_type: '',
        animal_type: '',

    });
    let [catalogStatus, setCatalogStatus]=useState(null)

    const { id } = {
        id: location.get('id'),
    };
    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setDropdownOpen(false);
        }
    };

    useEffect(() => {
        dispatch(setCurrentPage("publishing-standards"));
    }, []);

    const changeComponentLanguage = async (lang) => {
        setComponentLanguage(lang);
    }
    const loadStandardDetails = async () => {
        try {
            const ret = await sendAPIRequest(`/api/standard-structures/standard-type/${id}`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                if (res.data.standard) {
                    setStructureStatus(res?.data?.standard?.structure_status);
                    setCatalogStatus(res?.data.standard?.catalog_status)
                    setStandardDetails({
                        standard_type: res.data.standard.standard_type,
                        animal_type: res.data.standard.animal_type,
                        version_year: res.data?.standard?.migrated_year,
                        structureStatus: res?.data?.standard?.structure_status
                    })
                    await loadData(res.data.standard.standard_type, res.data.standard.animal_type);
                }
                //     else if(res.data.standard && !res.data.standard_process  )
                //    {
                //    await migrateStandard();
                //    }
            } else {
                displayNotification('error', res.message);
            }
        } catch (error) {
            console.log({ error });
            displayNotification("error", "Internall Server Error [F]")
        }
    }

    const loadData = async (standard_type, animal_type) => {
        setIsLoading(true);
        try {
            const ret = await sendAPIRequest(`/api/standard-structures/standard-sections/?standard_type=${standard_type}&animal_type=${animal_type}&lang_id=${componentLanguage ?? "102"}&draft=true`)
            const res = await ret.json();
            if (res.success && res.status === 200) {
                setData(res.data);
            } else {
                displayNotification('error', res.message);
            }
        } catch (e) {
            console.log({ e });
        }
        setIsLoading(false);
    }
    // handle mifgration 
    // const migrateStandard = async (standard_type ,animal_type) => {
    //     if (!window.confirm('Dou You want to migrate structure?')) {
    //         return;
    //     }
    //     try {
    //         const ret = await sendAPIRequest(`/api/standard-structures/standard-sections/?standard_type=${standard_type}&animal_type=${animal_type}&lang_id=${siteLanguage??"102"}&draft=true`)
    //         const res = await ret.json();
    //         if (res.success && res.status === 200) {
    //             console.log({data:res.data[0]})
    //             setData(res.data);
    //         } else {
    //             displayNotification('error', res.message);
    //         }
    //     } catch (e) {
    //         console.log({ e });
    //     }
    // }


    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        // loadData();
        loadStandardDetails();
    }, [componentLanguage]);

    const getHeaderText = (text) => {
        switch (text) {
            case '57':
                return t("32", 'Terrestrial Animal Health Code');
            case '67':
                return t('-1', 'Manual of Diagnostic Tests and Vaccines for Terrestrial Animals');
            case '58':
                return t('-1', 'Aquatic Animal Health Code');
            case '68':
                return t('-1', 'Manual of Diagnostic Tests for Aquatic Animals');
            default:
                return '';
        }
    };

    const PublishingStandardsDetails = () => {
        const location = useLocation();
        const queryParams = new URLSearchParams(location.search);
        const id = queryParams.get("id");
        return getHeaderText(id);;
    };

    const handlePublish = async () => {
        if (!window.confirm('Are you sure you want to publish?')) {
            return;
        }
        setIsLoading(true);
        try {
            const ret = await sendAPIRequest(`/api/handle-drafting/pre-publication`, 'POST', {
                "standard_type_id": id
            })
            const res = await ret.json();
            if (res.success && res.status === 200) {
                displayNotification('success', 'Process created will finished soon')
            } else {
                displayNotification('error', res.message);
            }
        } catch (e) {
            console.log({ e });
        } finally {
            setIsLoading(false);
        }
    }

    const finalPublishing = async () => {
        if (!window.confirm('Are you sure you want to publish?')) {
            return;
        }
        setIsLoading(true);
        try {
            const ret = await sendAPIRequest(`/api/handle-drafting/final-publication`, 'POST', {
                "standard_type_id": id
            })
            const res = await ret.json();
            if (res.success && res.status === 200) {
                displayNotification('success', 'Process created will finished soon')
            } else {
                displayNotification('error', res.message);
            }
        } catch (e) {
            console.log({ e });
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <Container>
            <div className="header card-woah border border-secondary d-flex px-4 py-2 my-2 shadow-sm w-100">
                <div className='d-flex justify-content-between'>
                    <div className="card-title1  lh-1">{t("-1", "Publishing Standards-2025")}</div>
                </div>
                <div className="header-right-items ms-auto d-flex align-items-center">
                    <div className="ms-auto">
                        <button
                            className="btn btn--primary font-custom fs-6 ms-1"
                            onClick={() => window.open(`/portal/catalog/?is_draft=true&status=${catalogStatus}`, '_blank')}
                        >
                            {t("-1", "Modify Catalogue")}
                        </button>
                        <button
                            className="btn btn--outline font-custom fs-6 ms-1"
                            onClick={() => { window.open(`/portal/glossary-implementation/?animal=${standardDetails.animal_type}&standard=${standardDetails.standard_type}&lang=${componentLanguage}`, '_blank') }}
                            disabled={structureStatus === 5 ? false : true}
                        >
                            {t("-1", "Glossary Implementation")}
                        </button>
                    </div>
                </div>
            </div>
            <div className=" header-res header card-woah border border-secondary d-flex px-4 py-2 shadow-sm w-100">
                <div className="d-flex justify-content-between">
                    <div className="card-title2 lh-1">
                        {PublishingStandardsDetails()}
                    </div>
                </div>
                <div className="header-right-items ms-auto d-flex align-items-center">
                    <div className="ms-auto">
                        <div className="list-group list-group-horizontal ms-auto">
                            <span className="vertical-line mx-5"></span>
                            <span
                                className={`bg-${headerTheme} cursor-pointer list-group-item text-hover-primary border-0 text-center font-custom ${componentLanguage == `${LIST_DETAIL_ELEMENTS.languages.english}` ? "text-decoration-underline" : ""
                                    } `}
                                onClick={() => (changeComponentLanguage(`${LIST_DETAIL_ELEMENTS.languages.english}`))}
                            >
                                EN
                            </span>
                            <span
                                className={`bg-${headerTheme} cursor-pointer list-group-item text-hover-primary border-0 text-center font-custom ${componentLanguage == `${LIST_DETAIL_ELEMENTS.languages.french}` ? "text-decoration-underline" : ""
                                    } `}
                                onClick={() => (changeComponentLanguage(`${LIST_DETAIL_ELEMENTS.languages.french}`))}
                            >
                                FR
                            </span>
                            <span
                                className={`bg-${headerTheme} cursor-pointer list-group-item text-hover-primary border-0 text-center  font-custom  me-3 ${componentLanguage == `${LIST_DETAIL_ELEMENTS.languages.spanish}` ? "text-decoration-underline" : ""
                                    } `}
                                onClick={() => (changeComponentLanguage(`${LIST_DETAIL_ELEMENTS.languages.spanish}`))}
                            >
                                ES
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className='py-2 d-flex justify-content-center bg-terrestrial-manual border border-secondary shadow-sm card-title3 my-2'>
                {t("-1", "Glossary")}
            </div>
            <CustomCard>
                <GlossaryAccordionComponent
                    componentLanguage={componentLanguage}
                    standardDetails={standardDetails}

                />
            </CustomCard>
            <div className='py-2 d-flex justify-content-center bg-aquatic-manual border border-secondary shadow-sm card-title3'>
                {t("-1", "Sections")}
            </div>
            <CustomCard>
                {
                    data.length > 0 ? (
                        data.map((a) =>
                            a?.sections?.map((x, ind) => (
                                <SectionChapterCordianComponent
                                    key={ind}
                                    data={x}
                                    standardDetails={standardDetails}
                                    loadData={loadData}
                                    standard_type={standardDetails.standard_type}
                                    area_vol_no={a?.area_vol_no ?? ''}
                                />
                            ))
                        )
                    ) : isLoading ? (
                        <div className="d-flex justify-content-center align-items-center vh-40">
                            <Loader message="Loading data...." />
                        </div>
                    ) : null
                }
                <div className="row pt-3">
                    <div className="col-12 d-flex justify-content-end">
                        {structureStatus === 3 && (<button
                            className="btn btn--primary font-custom fs-6 ms-1"
                            onClick={() => { handlePublish() }}
                            // disabled={structureStatus === 3}
                        >
                            {/* {isLoading ? "Publishing..." : "Publish"} */}
                            {t("-1", "Editing Complete")}
                        </button>)}
                        {structureStatus === 5 && (<button
                            className="btn btn--outline font-custom fs-6 ms-1"
                            disabled={structureStatus !==5 }
                            onClick={()=>finalPublishing()}
                        >
                            {t("-1", "Publish")}
                        </button>)}

                    </div>
                </div>
            </CustomCard>

        </Container>
    )
}

export default PublishingStandardsDetails
