import React, { useEffect, useMemo, useRef, useState } from 'react'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import AutoCompleteDropdown from 'components/autocomplete/AutoCompleteDropdown';
import { useTranslation } from 'react-i18next';
import { displayNotification, generateOptionsFromData, sendAPIRequest } from 'helpers';
import TextFieldCustom from 'components/TextFieldCustom';
import { Button, Divider, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { USER_ROLES } from 'constants';
import { useSelector } from 'react-redux';
import { USERS_WITH_PUBLISHER } from 'constants';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '40%',
    height: 'auto',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    overflowY: 'auto',
    padding: 0,
};

const DocumentTransferModal = ({ open = false, handleClose, documentDetails = null, showPublisher, loadData, is_modify = false }) => {

    const { t } = useTranslation();
    const authData = useSelector(s => s.auth?.data);
    const [availableUsers, setAvailableUsers] = useState([]);
    const [comment, setComment] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [selectedUser, setSelectedUser] = useState({
        value: '',
        label: ''
    });
    const [selectedPermissions, setSelectedPermissions] = useState({
        value: '',
        label: ''
    });

    useEffect(() => {
        setComment('');
        setSelectedPermissions({
            value: '',
            label: ''
        })
        setSelectedUser({
            value: '',
            label: ''
        });
        setIsLoading(false);
    }, [open])

    useEffect(() => {
        selectedPermissions.value && fetchUser();
    }, [selectedPermissions.value]);

    const usersOptions = useMemo(() => {
        return generateOptionsFromData(availableUsers, {
            value: "id",
            label: "user_name",
        }
        );
    }, [availableUsers]);

    const permissionsOptions = useMemo(() => {
        return generateOptionsFromData(showPublisher ? USERS_WITH_PUBLISHER : USER_ROLES, {
            value: "id",
            label: "value",
        }
        );
    }, [showPublisher ? USERS_WITH_PUBLISHER : USER_ROLES]);

    const handleSave = async () => {
        if (!window.confirm('Are you sure you want to transfer Document?')) {
            return;
        }
        if (!selectedUser.value || !selectedPermissions.value) {
            displayNotification('warning', 'Please select user and permission');
            return;
        }
        setIsLoading(true);
        try {
            const ret = await sendAPIRequest(`/api/doc-transfer/create/?is_modify=${is_modify}`, "POST", {
                draft_id: !is_modify ? documentDetails?.draft_id ?? null : null,
                receiver_id: selectedUser.value,
                permissions: selectedPermissions.value,
                comment: comment ?? "",
                draft_type: documentDetails?.type ?? "",
                draft_glossary_id: documentDetails?.glossary_id ?? null,
                draft_structure_id: is_modify ? documentDetails?.draft_id ?? null : null
            });
            const res = await ret.json();
            if (res.success && res.status === 200) {
                displayNotification('success', res.message);
                await loadData()
                handleClose(is_modify ?true: false);
            } else {
                displayNotification('error', res.message);
            }
        } catch (err) {
            console.log({ err });
            displayNotification('error', 'Error loading data [F]');
        } finally {
            setIsLoading(false);
        }
    }

    const fetchUser = async () => {
        try {
            const ret = await sendAPIRequest(`/api/users/specific-users/?role_id=${selectedPermissions.value}&skip_id=${authData.id}&standard=${documentDetails.standard}&lang_code=${documentDetails.lang_code}`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                setAvailableUsers(res.data);
            } else {
                displayNotification('error', 'Error loading data [B]');
            }
        } catch (err) {
            console.log({ err });
            displayNotification('error', 'Error loading data [F]');
        }
    }

    return (
        <>
            <Modal
                open={open}
                onClose={()=>handleClose(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box sx={{ padding: '10px 20px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Document Transfer
                        </Typography>
                        <IconButton onClick={()=>handleClose(false)}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Divider sx={{ backgroundColor: '#000', margin: '0' }} />
                    <Box sx={{ padding: '20px' }}>
                        <div className='row'>
                            <div className='col-6 mb-3'>
                                <AutoCompleteDropdown
                                    label="Role"
                                    showClearIcon={true}
                                    isGroup={true}
                                    error={false}
                                    required={false}
                                    multiple={false}
                                    hasselectall={false}
                                    options={permissionsOptions}
                                    onChange={(e, value) => {
                                        if (!value) {
                                            setSelectedPermissions({
                                                value: '',
                                                label: ''
                                            });
                                        }
                                        setSelectedPermissions({
                                            value: value?.value,
                                            label: value?.label
                                        });
                                    }}
                                    value={
                                        !selectedPermissions.value
                                            ? { value: '', label: '' }
                                            : permissionsOptions.find((r) => parseInt(r.value) === parseInt(selectedPermissions.value)) ?? { value: '', label: '' }
                                    }
                                    size='small'
                                />
                            </div>
                            <div className='col-6 mb-3'>
                                <AutoCompleteDropdown
                                    label="Users"
                                    showClearIcon={true}
                                    isGroup={true}
                                    error={false}
                                    required={false}
                                    multiple={false}
                                    hasselectall={false}
                                    options={usersOptions}
                                    onChange={(e, value) => {
                                        if (!value) {
                                            setSelectedUser({
                                                value: '',
                                                label: ''
                                            });
                                        }
                                        setSelectedUser({
                                            value: value?.value,
                                            label: value?.label
                                        });
                                    }}
                                    value={
                                        !selectedUser.value
                                            ? { value: '', label: '' }
                                            : usersOptions.find((r) => parseInt(r.value) === parseInt(selectedUser.value)) ?? { value: '', label: '' }
                                    }
                                    size='small'
                                />
                            </div>
                            {/* <div className='col-12'>
                                <TextFieldCustom
                                    variant="outlined"
                                    id="comment"
                                    label={t("-1", "Comment")}
                                    fullWidth
                                    multiline
                                    rows={4}
                                    sx={{
                                        backgroundColor: "white",
                                        color: "white",
                                        border: "#ff4815",
                                        width: "99%",
                                    }}
                                    size='small'
                                    value={comment}
                                    onChange={(e) => setComment(e.target.value)}
                                />
                            </div> */}
                        </div>
                        <div className='row'>
                            <div className="col-12 text-center mt-3">
                                <button
                                    className="btn btn--outline font-custom fs-6 me-2"
                                    onClick={()=>handleClose(false)}
                                >
                                    {t("201", "Close")}
                                </button>
                                <button
                                    className="btn btn--primary font-custom fs-6"
                                    onClick={handleSave}
                                    disabled={isLoading}
                                >
                                    {isLoading ? t("-1", "Transferring...") : t("-1", "Transfer")}
                                </button>
                            </div>
                        </div>
                    </Box>
                </Box>
            </Modal>
        </>
    )
}

DocumentTransferModal.defaultProps = {
    showPublisher: false,
};

export default DocumentTransferModal
