import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    report_repository: {
        report_year: [],
        country_id: [],
        report_type: [],
        mission_type: [],
        language_id: [],
        confidentionality_status_id: [],
        region_id: [],
    }
};

export const filtersSlice = createSlice({
    name: 'filters',
    initialState: { ...initialState },
    reducers: {
        updateFilter: (state, action = { key: '', payload: '' }) => {
            if (state[action.payload.key] != undefined) {
                state[action.payload.key] = action.payload.payload;
            } else {
                throw Error('Invalid key passed. Filters does not exists.');
            }
        },
        resetFiltersState: (state, action = { payload: '' }) => {
            if (state[action.payload] != undefined) {
                state[action.payload] = {...initialState[action.payload]};
            } else {
                throw Error('Invalid key passed. Filters does not exists.');
            }
        },
    },
})

// Action creators are generated for each case reducer function
export const { updateFilter, resetFiltersState } = filtersSlice.actions

export default filtersSlice.reducer