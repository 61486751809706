import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

const ViewButton = () => {

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const authData = useSelector(s => s.auth.data);
    const [currentTab, setCurrentTab] = useState('codes');

    return (
        <>
            <button
                className="mt-5 btn btn--primary font-custom fs-6"
                onClick={() => setCurrentTab('codes')}
            >
                {t("-1", "View")}
                {/* <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M20.9996 11C20.9996 16.523 16.5225 21 10.9996 21C5.47704 21 1 16.523 1 11C1 5.47704 5.47704 1 10.9996 1C16.5225 1 20.9996 5.47704 20.9996 11Z" stroke="#27282A" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M10.1233 10.5005H4.15314V11.5005H10.1233V10.5005ZM17.501 10.5005H11.5308V11.5005H17.501V10.5005ZM10.3271 11.6784V17.4307H11.3271V11.6784H10.3271ZM10.3271 4.57031V10.3226H11.3271V4.57031H10.3271Z" fill="#27282A" />
                </svg> */}
            </button>

        </>
    )
}

export default ViewButton
