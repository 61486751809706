import React, { useState } from 'react'
import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { displayNotification, getBaseURL, sendAPIRequest, getValidationPermission, getStandardAgainstIDs, getLanguageAgainstIDs } from 'helpers';
import { LIST_DETAIL_ELEMENTS } from 'constants';
import Spinner from 'components/spinner/Spinner';
import { Box } from '@mui/system';
import HtmlRenderer from 'components/HtmlRenderer copy';
import { useTranslation } from "react-i18next";
import { setCurrentPage, setHeaderTheme } from 'redux/generalSlice';
import { Divider, IconButton, Typography, Tooltip, styled, tooltipClasses, CircularProgress } from '@mui/material';
import { useSelector } from 'react-redux';
import Button from 'components/common/Button';
import NorthIcon from '@mui/icons-material/North';
import CustomCard from 'components/common/CustomCard';
import CardHeader from 'components/common/CardHeader';
import CardBody from 'components/common/CardBody';
import PrintIcon from '@mui/icons-material/Print';
import { useReactToPrint } from 'react-to-print';
import logo from "images/WOAH_ANNIVERSARY_PRIMARY LOGO_ORANGE + GOLD_ENG.png";
import { Link } from 'react-router-dom';


const scrollToTopButtonStyle = {
    position: 'fixed',
    bottom: '2%',
    right: '2%',
    zIndex: 1000,
    fontSize: '1.5rem',
    borderRadius: '50%',
    backgroundColor: '#ff4815',
    boxShadow: '0 0.1rem 1rem 0.25rem rgba(0, 0, 0, 0.3)',
};
const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#ffece8',
        color: '#ff4815',
        boxShadow: theme.shadows[1],
        fontSize: 12,
        padding: '13px'
    },
}));

function DraftChapterPreview() {
    const siteLanguage = useSelector(s => s.general.siteLanguageSont);
   const isDraftUrl = window.location.href.includes("draft-chapter-preview");
    const [location] = useSearchParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [chapterData, setChapterData] = useState('');
    const [footNotes, setFootNotes] = useState([]);

    const { chapter_id, is_draft, linking_references } = {
        chapter_id: location.get('chapter_id'),
        linking_references: location.get('linking_references'),
        is_draft: location.get('is_draft') === "true" ? true : false,
    };
    const [isprinted, setIsPrinted] = useState(false);
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState({
        loading_data: false,
    });
    const [validator, setValidator] = useState(false);
    const [showScroll, setShowScroll] = useState(false);
    const componentRef = useRef(null);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    const handleScroll = () => {
        if (window.scrollY > 300) {
            setShowScroll(true);
        } else {
            setShowScroll(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        dispatch(setHeaderTheme('white'));
        dispatch(setCurrentPage("draft-management"));
    }, []);

    useEffect(() => {
        if (is_draft && chapter_id) {
            getValidationPermissions();
        }
        const fetchData = async () => {
            if (linking_references) {
                getDraftChapterData();
            } else {
                getChapterData();
            }

        };
        fetchData();
        return () => {
        };
    }, [chapter_id]);




    const getChapterData = async () => {
        setIsLoading({ ...isLoading, loading_data: true });
        try {
            let ret;
            ret = await sendAPIRequest(`/api/draft-content/draft-preview/${chapter_id}/?is_draft=${is_draft}`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                let temp = modifyForNB(res.data)
                const newTemp = handleReplaceUrl(temp)
                setChapterData(newTemp);
            }
        } catch (e) {
            console.log({ e });
            displayNotification('error', 'Failed to load chapter data [F]');
        }
        setIsLoading({ ...isLoading, loading_data: false });
    }

    const getDraftChapterData = async () => {
        setIsLoading({ ...isLoading, loading_chapter: true });
        try {
            let url = `/api/draft-content/draft-preview-with-reference/?linking_references=${linking_references}`
            const ret = await sendAPIRequest(url);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                let temp = modifyForNB(res.data)
                fetchFootNotes(res.data.parent.id);
                if(isDraftUrl) {
                 temp = handleReplaceUrl(temp)
                }

                setChapterData(temp);
            } else {
                setChapterData('');
            }
        } catch (e) {
            console.error({ e });
        }
        setIsLoading({ ...isLoading, loading_chapter: false });
    };


    const getValidationPermissions = async () => {
        try {
            let ret;
            ret = await sendAPIRequest(`/api/doc-transfer/get-validation-permission-draft/${chapter_id}`);
            const res = await ret.json();
            if (res.success && res.status === 200 && res.data === true) {
                setValidator(true);
            } else {
                setValidator(false);
            }
        } catch (e) {
            console.log({ e });
            displayNotification('error', 'Failed to load chapter data [F]');
        }
    }

    const handleValidate = async (comments = '') => {
        if (!window.confirm('Are you sure you want to Validate?')) {
            return;
        }
        try {
            const standard = getStandardAgainstIDs(chapterData?.parent?.standard_type ?? "", chapterData?.parent?.animal_type);
            const lang_code = getLanguageAgainstIDs(chapterData?.parent?.lang_id, true);
            const role_id = LIST_DETAIL_ELEMENTS.roles.publisher;

            const ret = await sendAPIRequest(`/api/doc-transfer/validate/?standard=${standard}&lang_code=${lang_code}&role_id=${role_id}`, 'POST', {
                draft_id: !is_draft ? chapter_id : null,
                comments: comments,
                draft_structure_id: is_draft ? chapter_id : null,
                is_draft: is_draft
            });
            const res = await ret.json();
            if (res.success && res.status === 400) {
                displayNotification('success', 'No validator available against specific standard [x]');
            }
            if (res.success && res.status === 200) {
                let hasPer = await getValidationPermission(chapter_id);
                if (hasPer) {
                    setValidator(true);
                } else {
                    setValidator(false);
                }
                displayNotification('success', 'Successfully validated [x]');
            } else {
                displayNotification('error', 'Failed to validate [x]');
            }
        } catch (err) {
            console.log({ err });
            displayNotification('error', 'Failed to validate.');
        }
    }

    const modifyForNB = (data = []) => {
        if (!data.children || !Array.isArray(data.children)) {
            return data;
        }
        const itemsToMove = [];
        data.children = data.children.filter((item) => {
            if (item.text_type === 137) {
                itemsToMove.push(item); // Collect the item
                return false; // Remove the item from original array
            }
            return true; // Keep other items
        });
        data.children.push(...itemsToMove);
        return data;
    };



    // const handleReplaceUrl = (data = {}) => {
    //     const cloneData = JSON.parse(JSON.stringify(data)); // Deep clone
    //     if (!cloneData.children || !Array.isArray(cloneData.children)) {
    //         return cloneData;
    //     }
    //     for (let x of cloneData.children) {
    //         if (x.children.length > 0) {
    //             for (let y of x.children) {
    //                 if (y.draft_html_contents.length > 0) {
    //                     for (let t of y.draft_html_contents) {
    //                         t.content = t.content.replace(/draft-chapter-preview\/|draft-chapter-preview\/\/get-chapter-preview/g, '');
    //                     }
    //                 }
    //             }
    //         } else {
    //             for (let z of x.draft_html_contents) {
    //                 z.content = z.content.replace(/draft-chapter-preview\/|draft-chapter-preview\/\/get-chapter-preview/g, '');
    //             }
    //         }
    //     }
    //     // console.log({cloneData.children})
    //     return cloneData;
    // };

    const handleReplaceUrl = (data = {}) => {
        const cloneData = JSON.parse(JSON.stringify(data)); // Deep clone
        if (!cloneData.children || !Array.isArray(cloneData.children)) {
            return cloneData;
        }
    
        // Regex patterns for replacing paths while keeping query parameters
        const draftChapterPreviewRegex = /draft-chapter-preview\//g; // Matches 'draft-chapter-preview/'
        const getChapterPreviewRegex = /get-chapter-preview\//g; // Matches 'get-chapter-preview/'
    
        const replaceContentUrls = (content) => {
            // Replace 'draft-chapter-preview/' with an empty string, keep query params intact
            content = content.replace(draftChapterPreviewRegex, '');
            // Replace 'get-chapter-preview/' with an empty string, keep query params intact
            content = content.replace(getChapterPreviewRegex, '');
            return content;
        };
    
        for (let x of cloneData.children) {
            if (x.children.length > 0) {
                for (let y of x.children) {
                    if (y.draft_html_contents.length > 0) {
                        for (let t of y.draft_html_contents) {
                            t.content = replaceContentUrls(t.content);
                        }
                    }
                }
            } else {
                for (let z of x.draft_html_contents) {
                    z.content = replaceContentUrls(z.content);
                }
            }
        }
        return cloneData;
    };
    
    const fetchFootNotes = async () => {
        try {
            const ret = await sendAPIRequest(`/api/foot-note/findAllByChapter/${chapter_id}`);
            const res = await ret.json();
            if (res.success && res.status === 200) {

                setFootNotes(res.data);
            }
        } catch (error) {
            console.log({ error });
        }
    }

    useEffect(() => {
        if (footNotes.length > 0) {
            const handleMouseOver = (event) => {
                const footNoteId = event.target.getAttribute('data-footnoteid');
                if (footNoteId) {
                    //scroll to the foot note
                    const footNoteElement = document.getElementById(`footnote-${footNoteId}`);
                    if (footNoteElement) {
                        footNoteElement.scrollIntoView({ behavior: 'smooth' });
                    }
                }
            };

            const currentRef = componentRef.current;
            if (currentRef) {
                currentRef.addEventListener('click', handleMouseOver);
            }

            return () => {
                if (currentRef) {
                    currentRef.removeEventListener('mouseover', handleMouseOver);
                }
            };
        }
    }, [componentRef, footNotes]);

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        onBeforeGetContent: () => {
            return new Promise((resolve) => {
                setIsPrinted(true);
                // setTimeout(() => {
                //     resolve();
                // }, 0);
                resolve();
            });
        },
        onAfterPrint: () => {
            setIsPrinted(false);
        },
        onPrintError: () => {

        },
    });

    return (
        <>
            <div className="container">
                <CustomCard className={'d-flex justify-content-between'}>
                    <CardBody>
                        <LightTooltip title="Print report" placement="top"
                        >
                            <IconButton >
                                <PrintIcon
                                    color='primary'
                                    className='fs-1'
                                    // onClick={() => { handlePrint(); }}
                                    onClick={handlePrint}
                                />
                            </IconButton>
                        </LightTooltip>
                        {validator && (
                            <Button
                                onClick={() => handleValidate()}
                                variant="contained"
                            >
                                {t("-1", "Validate")}
                            </Button>
                        )}
                    </CardBody>
                </CustomCard>
                <Box ref={componentRef}>
                    {
                        <div className="border-bottom border-dark navbar is-print">
                            <div className="d-flex  header-res">
                                <a
                                    className="navbar-brand"
                                    id="logo-brand"
                                    href="https://www.woah.org/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <img src={logo} alt="woah-logo" height="40px" />
                                </a>
                                <div
                                    style={{ height: "4rem" }}
                                    className="ms-4 title-res border border-right-0 border-top-0 border-bottom-0 border-dark ps-3"
                                >
                                    <Link className="navbar-brand" id="logo-brand-pvs" to="/">
                                        <h4 className="pe-4 pvs-title fw-bold py-4">
                                            WOAH International Standards
                                        </h4>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    }

                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        my={2}
                        width="100%"

                    >
                        {isLoading.loading_chapter ? (
                            <Spinner message={t('-1', 'Loading Data, Please wait...')} />
                        ) : (
                            chapterData && chapterData.children && chapterData.children.length > 0 ? (
                                <>
                                    <Box key={`section-${chapterData.parent?.id}`}>
                                        <Typography
                                            textAlign="center"
                                            pt={2}
                                            style={{
                                                letterSpacing: '2px',
                                                textTransform: 'uppercase'
                                            }}
                                            key={chapterData.parent.id}
                                        >
                                            {
                                                chapterData.parent.standard_type == LIST_DETAIL_ELEMENTS.standard_type.codes ? (
                                                    `Section ${chapterData.parent?.section_no}`
                                                ) : (
                                                    `Section ${chapterData.parent?.area_vol_no}.${chapterData.parent?.section_no}`
                                                )
                                            }
                                        </Typography>
                                        <Typography
                                            className="chapter-heading text-black text-center"
                                            key={`name-${chapterData.parent?.chapter_name}`}
                                            style={{
                                                letterSpacing: '2px',
                                                textTransform: 'uppercase',
                                            }}
                                        >
                                            <span className='fs-6'>
                                                {chapterData.parent?.section_name}
                                            </span>
                                        </Typography>
                                    </Box>
                                    <Divider
                                        variant="middle"
                                        sx={{
                                            width: '50%',
                                            margin: 'auto',
                                            marginTop: '16px',
                                            marginBottom: '6px',
                                            backgroundColor: '#000',
                                        }}
                                    />
                                    <Divider
                                        variant="middle"
                                        sx={{
                                            width: '50%',
                                            margin: 'auto',
                                            backgroundColor: '#000',
                                        }}
                                    />
                                    <Box key={`chapter-${chapterData.id}`}>
                                        <Typography
                                            my={0}
                                            py={0}
                                            textAlign="center"
                                            p={3}
                                            style={{
                                                letterSpacing: '2px',
                                                textTransform: 'uppercase'
                                            }}
                                            key={chapterData.id}
                                        >
                                            {
                                                chapterData.parent.standard_type == LIST_DETAIL_ELEMENTS.standard_type.codes ? (
                                                    `Chapter ${chapterData.parent?.section_no}.${chapterData.parent?.chapter_no}`
                                                ) : (
                                                    `Chapter ${chapterData.parent?.area_vol_no}.${chapterData.parent?.section_no}.${chapterData.parent?.chapter_no}`
                                                )
                                            }
                                        </Typography>
                                        <Typography
                                            textAlign="center"
                                            style={{
                                                letterSpacing: '2px',
                                                textTransform: 'uppercase'
                                            }}
                                            className="chapter-heading text-center pb-5"
                                            key={`name-${chapterData.id}`}
                                        >
                                            {chapterData.parent?.chapter_name}
                                        </Typography>
                                    </Box>
                                    {chapterData.children && chapterData.children.length > 0 && chapterData.children.map(d => (
                                        <Box
                                            key={`article-${d.id}`}
                                            id={`article-${d.id}`}
                                            data-myattribute={d.ref_number}
                                            className={'w-100'}
                                        >
                                            <HtmlRenderer
                                                tag={"div"}
                                                className="text-center py-4 fs-5"
                                                htmlContent={d?.standard_content}
                                            />
                                            {d.draft_html_contents && d.draft_html_contents.length > 0 ? (
                                                d.draft_html_contents.map((child) => (
                                                    <Typography
                                                        className="px-4 text-justified"
                                                        key={`art-child-${child.id}`}
                                                    >
                                                        <HtmlRenderer
                                                            tag={"div"}
                                                            htmlContent={child.content}
                                                        />
                                                    </Typography>
                                                ))
                                            ) : (
                                                d.children && d.children.map(child => (
                                                    <Box key={`general-${child.id}`} id={`${child.id}`}>
                                                        <HtmlRenderer
                                                            tag={"div"}
                                                            className={LIST_DETAIL_ELEMENTS.heading_types.general_heading === child.text_type ? "py-4 fw-bold" : "py-4"}
                                                            htmlContent={child?.standard_content}
                                                        />
                                                        {child.draft_html_contents && child.draft_html_contents.length > 0 && child.draft_html_contents.map((c, i) => (
                                                            <Box
                                                                className={`d-flex ${c.text_type === LIST_DETAIL_ELEMENTS.styles_categories.code_editing_date_text && 'justify-content-end pt-3'}`}
                                                            >
                                                                <Typography className={LIST_DETAIL_ELEMENTS.heading_types.general_heading === c.text_type ? "text-justified" : 'justify-content-end'}>
                                                                    <HtmlRenderer
                                                                        tag={LIST_DETAIL_ELEMENTS.heading_types.general_heading === c.text_type ? "div" : "p"}
                                                                        htmlContent={c.content}
                                                                    />
                                                                </Typography>
                                                            </Box>
                                                        ))}
                                                    </Box>
                                                ))
                                            )}
                                        </Box>

                                    ))}
                                </>
                            ) : (
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    textAlign: 'center',
                                }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <CircularProgress size={20} />
                                        <Typography variant="body1" sx={{ marginLeft: '10px' }}>
                                            {t('-1', 'Loading data please wait...')}
                                        </Typography>
                                    </Box>
                                </Box>
                            )
                        )}
                    </Box>
                    {showScroll && (
                        <IconButton
                            onClick={scrollToTop}
                            disableRipple
                            sx={scrollToTopButtonStyle}
                        >
                            <NorthIcon
                                className="custom-scroll-top-button cursor-pointer"
                                fontSize="medium"
                            />
                        </IconButton>
                    )}
                </Box>
                <Box>
                    {footNotes.map(footNote => (
                        <Box
                            key={footNote.element_id}
                            id={`footnote-${footNote.element_id}`}
                            sx={{
                                marginY: '8px',
                                fontSize: '1rem',
                                padding: '8px',
                                borderLeft: '4px solid #ff4815',
                                backgroundColor: '#f9f9f9',
                                borderRadius: '4px'
                            }}
                        >
                            <Typography
                                variant="body2"
                                sx={{
                                    fontStyle: 'italic',
                                    fontSize: '1rem',
                                    color: '#4a4a4a',
                                }}
                            >
                                {footNote.selected_text}: {footNote.defination}
                            </Typography>
                        </Box>
                    ))}
                </Box>

            </div>
        </>
    );
}

export default DraftChapterPreview