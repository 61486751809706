import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentPage } from 'redux/generalSlice';

const AddUpdateContentSearch = () => {

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const siteLanguage = useSelector(s => s.general.siteLanguageSont);
    const authData = useSelector(s => s.auth.data);

    useEffect(() => {
        dispatch(setCurrentPage('update-publish'));
    }, []);

    return (
        <>
            <div className="container my-2">
                <div className="header card-woah border border-secondary d-flex px-4 py-2 shadow-sm w-100 my-2">
                    <div className='d-flex justify-content-between w-100'>
                        <div className="card-title1 lh-1">Add/Update Content through Search</div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default AddUpdateContentSearch;




