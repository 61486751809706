import { createTheme } from "@mui/material";

export const muiTheme = createTheme({
  input: {
    "& input.Mui-disabled": {
      color: "#000000b3 !important",
      WebkitTextFillColor: "black !important",
    }
  },
  palette: {
    primary: {
      main: '#ff4815'
    },
    white: {
      main: '#fff',
      darker: '#fff',
      contrastText: '#000',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
      },
    },

    MuiSelect: {
      styleOverrides: {
        select: {
          ":focus": {
            // backgroundColor: "green",
            // border: '3px solid #A6CBF3',
          },
        },

      }
    },

    // MuiMenuItem: {
    //   styleOverrides: {
    //     root: {
    //       backgroundColor: "green",
    //       border: '3px solid #A6CBF3'

    //     }
    //   }
    // },

    MuiTextField: {
      defaultProps: {
        sx: {
          '& .MuiFormLabel-root': {
            color: 'pink'
          }
        }
      },
      styleOverrides: {
        root: {
          '& .MuiFormLabel-root': {
            color: '#000'
          }
        },
        label: {
          color: 'purple',
          '& .MuiFormLabel-root': {
            color: 'primary'
          }
        }
      }
    }
  },
  typography: {
    fontFamily: ['Sohne-Buch, arial, verdana, sans-serif']
  }
});