import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { APP_VERSION, BASE_URL, ENV_SITE_DEV } from 'constants';
import { useDispatch, useSelector } from 'react-redux';
import { setSidebarState } from 'redux/generalSlice';
import TestSite from 'pages/TestSite';
import { ThemeProvider, createTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Redirect from 'pages/components/Redirect';
import NavbarUserIcon from 'pages/components/NavbarUserIcon';
import { isMobile } from 'react-device-detect';

const customTheme = createTheme({
  palette: {
    primary: {
      main: "#ff4815",
      contrastText: "white",
    },
  },
});

const BackendLayout = ({ children }) => {

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const sidebarOpened = useSelector((s) => s.general.sidebarOpened);
  const currentPage = useSelector((s) => s.general.currentPage);
  const authData = useSelector((s) => s.auth.data);

  useEffect(() => {
    if (isMobile) {
      dispatch(setSidebarState(true));
    }
    document.getElementById("root").classList.remove("w-100");
    document.getElementById("root").classList.add("d-flex");
    document.getElementById("root").classList.add("flex-grow-1");
    window.KTToggle.init();
    window.KTScroll.init();
  }, []);

  useEffect(() => {
    document.getElementById("kt_app_body").dataset.ktAppSidebarMinimize = !sidebarOpened ? "on" : "";
    if (isMobile) {
      document.getElementById("kt_app_body").dataset.ktDrawerAppSidebar = !sidebarOpened ? "on" : "";
      document.getElementById("kt_app_body").dataset.ktDrawer = !sidebarOpened ? "on" : "";
    }
  }, [sidebarOpened]);

  return authData.id != '' ? (
    <ThemeProvider theme={customTheme}>
      <link rel="stylesheet" href={`${BASE_URL}/assets/css/backend.css`} />
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css?family=Inter:300,400,500,600,700"
      />
      <div className="d-flex flex-column flex-root app-root" id="kt_app_root">
        <div
          className="app-page flex-column flex-column-fluid"
          id="kt_app_page"
        >
          <div id="kt_app_header" className="app-header">
            <div
              className="w-100 d-flex align-items-stretch justify-content-between"
              id="kt_app_header_container"
            >
              <div
                className="d-flex align-items-center d-lg-none ms-n3 me-1 me-md-2 ps-2 ps-sm-0 ps-md-0 ps-lg-0 ps-xl-0"
                title="Show sidebar menu"
              >
                <button
                  className="btn btn-icon btn-active-color-primary w-35px h-35px"
                  id="kt_app_sidebar_mobile_toggle"
                  onClick={() => dispatch(setSidebarState(!sidebarOpened))}
                >
                  <span className="svg-icon svg-icon-2 svg-icon-md-1">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z"
                        fill="currentColor"
                      ></path>
                      <path
                        opacity="0.3"
                        d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </span>
                </button>
              </div>
              <div className="header card-woah border border-secondary d-flex py-1 w-100 ps-4">
                <div className="header-left mr-auto d-flex flex-grow-1 py-2">
                  <a
                    href="https://www.woah.org/"
                    target="_blank"
                  >
                    <img
                      className="woah-logo"
                      src={`${BASE_URL}/assets/icons/logo.png`}
                      alt="LOGO"
                    />
                  </a>
                  <Link to="/portal" className='ms-5 border border-left-3 border-right-0 border-top-0 border-bottom-0 border-dark ps-4 my-auto align-middle '>
                    <h4 className="pvs-title fw-normal text-uppercase">
                      PVS Pathway Information System
                    </h4>
                  </Link>
                  {/* <img
                                        className="pvs-logo d-block mx-4 my-auto"
                                        src={`${BASE_URL}/assets/icons/pvs.png`}
                                        alt=""
                                    /> */}
                </div>
                <div className="header-right">
                  <div
                    className="app-navbar-item"
                    id="kt_header_user_menu_toggle"
                  >
                    <div
                      className="d-flex pt-1"
                      data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                      data-kt-menu-attach="parent"
                      data-kt-menu-placement="bottom-end"
                    >
                      <NavbarUserIcon />
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="d-flex align-items-stretch justify-content-between flex-lg-grow-1"
                id="kt_app_header_wrapper"
              >
                <div
                  className="app-header-menu app-header-mobile-drawer align-items-stretch"
                  data-kt-drawer="true"
                  data-kt-drawer-name="app-header-menu"
                  data-kt-drawer-activate="{default: true, lg: false}"
                  data-kt-drawer-overlay="true"
                  data-kt-drawer-width="250px"
                  data-kt-drawer-direction="end"
                  data-kt-drawer-toggle="#kt_app_header_menu_toggle"
                  data-kt-swapper="true"
                  data-kt-swapper-mode="{default: 'append', lg: 'prepend'}"
                  data-kt-swapper-parent="{default: '#kt_app_body', lg: '#kt_app_header_wrapper'}"
                ></div>
              </div>
            </div>
          </div>
          <div
            className="app-wrapper flex-column flex-row-fluid"
            id="kt_app_wrapper"
          >
            <div
              id="kt_app_sidebar"
              className={`app-sidebar flex-column ${isMobile ? 'drawer drawer-start' : ''} ${!sidebarOpened && isMobile ? 'drawer-on' : ''}`}
              data-kt-drawer="true"
              data-kt-drawer-name="app-sidebar"
              data-kt-drawer-activate="{default: true, lg: false}"
              data-kt-drawer-overlay="true"
              data-kt-drawer-width="225px"
              data-kt-drawer-direction="start"
              data-kt-drawer-toggle="#kt_app_sidebar_mobile_toggle"
              style={{ backgroundColor: "#363740" }}
            >
              <div
                className="app-sidebar-logo"
                id="kt_app_sidebar_logo"
                style={{ borderBottom: "#363740, solid" }}
              >
                <div className="container text-center" id="kt_app_sidebar_logo">
                  {/* <img
                    src={`${BASE_URL}/assets/icons/logo.png`}
                    className="img-fluid app-sidebar-logo-default"
                    style={{ height: "50px", width: "auto" }}
                    alt=""
                  /> */}
                  <img
                    src={`${BASE_URL}/assets/icons/woah-logo-icon.png`}
                    className="h-40px app-sidebar-logo-minimize img-fluid w-auto"
                    alt=""
                  />
                </div>
                <button
                  id="kt_app_sidebar_toggle"
                  className="app-sidebar-toggle btn btn-icon btn-shadow btn-sm btn-color-muted btn-active-color-primary body-bg h-30px w-30px position-absolute top-50 start-100 translate-middle rotate"
                  data-kt-toggle="true"
                  data-kt-toggle-state="active"
                  data-kt-toggle-target="body"
                  data-kt-toggle-name="app-sidebar-minimize"
                  onClick={() => dispatch(setSidebarState(!sidebarOpened))}
                >
                  <span className="svg-icon svg-icon-2 rotate-180">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        opacity="0.5"
                        d="M14.2657 11.4343L18.45 7.25C18.8642 6.83579 18.8642 6.16421 18.45 5.75C18.0358 5.33579 17.3642 5.33579 16.95 5.75L11.4071 11.2929C11.0166 11.6834 11.0166 12.3166 11.4071 12.7071L16.95 18.25C17.3642 18.6642 18.0358 18.6642 18.45 18.25C18.8642 17.8358 18.8642 17.1642 18.45 16.75L14.2657 12.5657C13.9533 12.2533 13.9533 11.7467 14.2657 11.4343Z"
                        fill="currentColor"
                      />
                      <path
                        d="M8.2657 11.4343L12.45 7.25C12.8642 6.83579 12.8642 6.16421 12.45 5.75C12.0358 5.33579 11.3642 5.33579 10.95 5.75L5.40712 11.2929C5.01659 11.6834 5.01659 12.3166 5.40712 12.7071L10.95 18.25C11.3642 18.6642 12.0358 18.6642 12.45 18.25C12.8642 17.8358 12.8642 17.1642 12.45 16.75L8.2657 12.5657C7.95328 12.2533 7.95328 11.7467 8.2657 11.4343Z"
                        fill="currentColor"
                      />
                    </svg>
                  </span>
                </button>
              </div>
              <div className="app-sidebar-menu overflow-hidden flex-column-fluid">
                <div
                  id="kt_app_sidebar_menu_wrapper"
                  className="app-sidebar-wrapper hover-scroll-overlay-y my-5 d-flex flex-column"
                  data-kt-scroll="true"
                  data-kt-scroll-activate="true"
                  data-kt-scroll-height="auto"
                  data-kt-scroll-dependencies="#kt_app_sidebar_logo, #kt_app_sidebar_footer"
                  data-kt-scroll-wrappers="#kt_app_sidebar_menu"
                  data-kt-scroll-offset="5px"
                  data-kt-scroll-save-state="true"
                >
                  <div
                    className="menu menu-column menu-rounded menu-sub-indention"
                    id="#kt_app_sidebar_menu"
                    data-kt-menu="true"
                    data-kt-menu-expand="false"
                  >
                    {/* Example Dropmenu */}
                    {/* <div data-kt-menu-trigger="click" className="menu-item menu-accordion">
                                            <span className="menu-link">
                                                <span className="menu-icon">
                                                    <i className="fas fa-home fa-2x"></i>
                                                </span>
                                                <span className="menu-title">Menu Item</span>
                                                <span className="menu-arrow"></span>
                                            </span>
                                            <div className="menu-sub menu-sub-accordion">
                                                <div className="menu-item">
                                                    <Link className="menu-link" to='/portal'>
                                                        <span className="menu-bullet">
                                                            <span className="bullet bullet-dot"></span>
                                                        </span>
                                                        <span className="menu-title">Sub Menu Item 1</span>
                                                    </Link>
                                                </div>
                                                <div className="menu-item">
                                                    <Link className="menu-link" to='/portal'>
                                                        <span className="menu-bullet">
                                                            <span className="bullet bullet-dot"></span>
                                                        </span>
                                                        <span className="menu-title">Sub Menu Item 2</span>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div> */}
                    <div className="menu-item">
                      <Link
                        className={`menu-link ${currentPage == "dashboard" ? "active" : ""
                          }`}
                        to={"/portal"}
                        style={{
                          borderRadius: "0%",
                          marginLeft: "0%",
                          marginRight: "0%",
                        }}
                      >
                        <span className="menu-icon">
                          <i className="fas fa-home fa-2x"></i>
                        </span>
                        <span className="menu-title ">
                          {t("-1", "Home")}
                        </span>
                      </Link>
                    </div>
                    <hr />
                    <div className="menu-item">
                      <Link
                        className={`menu-link ${currentPage == "report-repository" ? "active" : ""
                          }`}
                        to={"/portal/document-management"}
                        style={{
                          borderRadius: "0%",
                          marginLeft: "0%",
                          marginRight: "0%",
                        }}
                      >
                        <span className="menu-icon">
                          <span className="svg-icon svg-icon-2">
                            <img
                              src={`${BASE_URL}/assets/icons/bill-line.png`}
                              width="19px"
                              height="19px"
                              viewBox="0 0 24 24"
                              version="1.1"
                            />
                          </span>
                        </span>
                        <span className="menu-title">
                          {t("-1", "Report Repository")}
                        </span>
                      </Link>
                    </div>
                    <div className="menu-item">
                      <Link
                        className={`menu-link ${currentPage == "metadata-management" ? "active" : ""
                          }`}
                        to={"/portal/metadata-management"}
                        style={{
                          borderRadius: "0%",
                          marginLeft: "0%",
                          marginRight: "0%",
                        }}
                      >
                        <span className="menu-icon">
                          <span className="svg-icon svg-icon-2">
                            <img
                              src={`${BASE_URL}/assets/icons/v2.png`}
                              width="17px"
                              height="17px"
                              viewBox="0 0 24 24"
                              version="1.1"
                            />
                          </span>
                        </span>
                        <span className="menu-title">
                          {t("-1", "Metadata Management")}
                        </span>
                      </Link>
                    </div>
                    <div className="menu-item">
                      <Link
                        className={`menu-link ${currentPage == "mission-management" ? "active" : ""
                          }`}
                        to={"/portal/mission-management"}
                        style={{
                          borderRadius: "0%",
                          marginLeft: "0%",
                          marginRight: "0%",
                        }}
                      >
                        <span className="menu-icon">
                          <span className="svg-icon svg-icon-2">
                            <img
                              src="/assets/icons/v3.png"
                              width="17px"
                              height="17px"
                              viewBox="0 0 24 24"
                              version="1.1"
                            />
                          </span>
                        </span>
                        <span className="menu-title">
                          {t("-1", "Mission Management")}
                        </span>
                      </Link>
                    </div>
                    <div className="menu-item">
                      <Link
                        className={`menu-link ${currentPage == "experts-management" ? "active" : ""
                          }`}
                        to={"/portal/experts-management"}
                        style={{
                          borderRadius: "0%",
                          marginLeft: "0%",
                          marginRight: "0%",
                        }}
                      >
                        <span className="menu-icon">
                          <span className="svg-icon svg-icon-2">
                            <img
                              src={`${BASE_URL}/assets/icons/Group_add_light.png`}
                              width="21px"
                              height="21px"
                              viewBox="0 0 24 24"
                              version="1.1"
                            />
                          </span>
                        </span>
                        <span className="menu-title">
                          {t("-1", "Experts Management")}
                        </span>
                      </Link>
                    </div>
                    <div className="menu-item">
                      <Link
                        className={`menu-link ${currentPage == "event-management" ? "active" : ""
                          }`}
                        to={"/portal/event-management"}
                        style={{
                          borderRadius: "0%",
                          marginLeft: "0%",
                          marginRight: "0%",
                        }}
                      >
                        <span className="menu-icon">
                          <span className="svg-icon svg-icon-2">
                            <img
                              src={`${BASE_URL}/assets/icons/Date_range_duotone_line.png`}
                              width="19px"
                              height="19px"
                              viewBox="0 0 24 24"
                              version="1.1"
                            />
                          </span>
                        </span>
                        <span className="menu-title">
                          {t("-1", "Event Management")}
                        </span>
                      </Link>
                    </div>
                    <div className="menu-item">
                      <Link
                        className={`menu-link ${currentPage == "power-bi" ? "active" : ""
                          }`}
                        to={"/portal/powerbi"}
                        style={{
                          borderRadius: "0%",
                          marginLeft: "0%",
                          marginRight: "0%",
                        }}
                      >
                        <span className="menu-icon">
                          <span className="svg-icon svg-icon-2">
                            <img
                              src={`${BASE_URL}/assets/icons/Power-BI-Logo.png`}
                              width="20px"
                              height="20px"
                              viewBox="0 0 24 24"
                              version="1.1"
                            />
                          </span>
                        </span>
                        <span className="menu-title">{t("-1", "Power BI")}</span>
                      </Link>
                    </div>
                    <div className="menu-item">
                      <Link
                        className={`menu-link ${currentPage == "powerbi-dashboard" ? "active" : ""
                          }`}
                        to={"/portal/powerbi-dashboard-management"}
                        style={{
                          borderRadius: "0%",
                          marginLeft: "0%",
                          marginRight: "0%",
                        }}
                      >
                        <span className="menu-icon">
                          <span className="svg-icon svg-icon-2">
                            <img
                              src={`${BASE_URL}/assets/icons/Power-BI-Logo.png`}
                              width="20px"
                              height="20px"
                              viewBox="0 0 24 24"
                              version="1.1"
                            />
                          </span>
                        </span>
                        <span className="menu-title">{t("-1", "Power BI Dashboard Management")}</span>
                      </Link>
                    </div>
                    <hr />
                    <div className="menu-item">
                      <Link
                        className={`menu-link ${currentPage == "insights-generator" ? "active" : ""
                          }`}
                        to={"/portal/insights-generator"}
                        style={{
                          borderRadius: "0%",
                          marginLeft: "0%",
                          marginRight: "0%",
                        }}
                      >
                        <span className="menu-icon">
                          <i className="fa fa-file-alt"></i>
                        </span>
                        <span className="menu-title">
                          {t("-1", "Insights Generator")}
                        </span>
                      </Link>
                    </div>
                    {authData.role_id == 1 && (
                      <>
                        <hr />
                        <div className="menu-item">
                          <Link
                            className={`menu-link ${currentPage == "data-migration" ? "active" : ""
                              }`}
                            to={"/portal/data-migration"}
                            style={{
                              borderRadius: "0%",
                              marginLeft: "0%",
                              marginRight: "0%",
                            }}
                          >
                            <span className="menu-icon">
                              <i className="fa fa-arrow-right-arrow-left"></i>
                            </span>
                            <span className="menu-title">
                              {t("4", "Data Migration")}
                            </span>
                          </Link>
                        </div>
                        <div className="menu-item">
                          <Link
                            className={`menu-link ${currentPage == "key-phrases" ? "active" : ""
                              }`}
                            to={"/portal/key-phrases"}
                            style={{
                              borderRadius: "0%",
                              marginLeft: "0%",
                              marginRight: "0%",
                            }}
                          >
                            <span className="menu-icon">
                              {/* <i className="fa fa-arrow-right-arrow-left"></i> */}
                              <i className="fa-solid fa-quote-left"></i>
                            </span>
                            <span className="menu-title">
                            {t("-1", "Key Phrases")}
                            </span>
                          </Link>
                        </div>
                      </>
                    )}
                    <hr />
                    <div className="menu-item">
                      <Link
                        className={`menu-link ${currentPage == "user-management" ? "active" : ""
                          }`}
                        to={"/portal/user-management"}
                        style={{
                          borderRadius: "0%",
                          marginLeft: "0%",
                          marginRight: "0%",
                        }}
                      >
                        <span className="menu-icon">
                          <span className="svg-icon svg-icon-2">
                            <img
                              src={`${BASE_URL}/assets/icons/user.png`}
                              width="17px"
                              height="19px"
                              viewBox="0 0 24 24"
                              version="1.1"
                            />
                          </span>
                        </span>
                        <span className="menu-title">
                          {t("-1", "User Management")}
                        </span>
                      </Link>
                    </div>
                    <div className="menu-item">
                      <Link
                        className={`menu-link ${currentPage == "role-management" ? "active" : ""
                      }`}
                        to={"/portal/role-management"}
                        style={{ borderRadius: '0%', marginLeft: '0%', marginRight: '0%' }}
                      >
                        <span className="menu-icon">
                          <span className="svg-icon svg-icon-2">
                            <img
                              src={`${BASE_URL}/assets/icons/v1.png`}
                              width="17px"
                              height="17px"
                              viewBox="0 0 24 24"
                              version="1.1"
                            />
                          </span>
                        </span>
                        <span className="menu-title">{t("-1", "Role Management")}</span>
                      </Link>
                    </div>
                    <div className="menu-item">
                      <Link
                        className={`menu-link ${currentPage == "pdf-management" ? "active" : ""
                      }`}
                        to={"/portal/pdf-management"}
                        style={{ borderRadius: '0%', marginLeft: '0%', marginRight: '0%' }}
                      >
                        <span className="menu-icon">
                          <span className="svg-icon svg-icon-2">
                            <img
                              src={`${BASE_URL}/assets/icons/v1.png`}
                              width="17px"
                              height="17px"
                              viewBox="0 0 24 24"
                              version="1.1"
                            />
                          </span>
                        </span>
                        <span className="menu-title">{t("-1", "PDF Management")}</span>
                      </Link>
                    </div>
                    <hr />
                    <div className="menu-item">
                      <Link
                        className={`menu-link ${currentPage == "news-management" ? "active" : ""
                          }`}
                        to={"/portal/news-management"}
                        style={{
                          borderRadius: "0%",
                          marginLeft: "0%",
                          marginRight: "0%",
                        }}
                      >
                        <span className="menu-icon">
                          <span className="svg-icon svg-icon-2">
                            <img
                              src={`${BASE_URL}/assets/icons/rss-fill.png`}
                              width="19px"
                              height="19px"
                              viewBox="0 0 24 24"
                              version="1.1"
                            />
                          </span>
                        </span>
                        <span className="menu-title">
                          {t("-1", "News Management")}
                        </span>
                      </Link>
                    </div>
                    <hr />
                    <div className="menu-item">
                      <Link
                        className={`menu-link ${currentPage == "multilingual-keywords" ? "active" : ""
                          }`}
                        to="/portal/multilingual-keywords"
                        style={{
                          borderRadius: "0%",
                          marginLeft: "0%",
                          marginRight: "0%",
                        }}
                      >
                        <span className="menu-icon">
                          <i className="fas fa-language"></i>
                        </span>
                        <span className="menu-title">
                          {t("127", "Multilingual Keywords")}
                        </span>
                      </Link>
                    </div>
                    <hr />
                    <div className="menu-item">
                      <Link
                        className={`menu-link ${currentPage == "system-status" ? "active" : ""
                          }`}
                        to="/portal/system-status"
                        style={{
                          borderRadius: "0%",
                          marginLeft: "0%",
                          marginRight: "0%",
                        }}
                      >
                        <span className="menu-icon">
                          <i className="fas fa-plug"></i>
                        </span>
                        <span className="menu-title">
                          {t("136", "System Status")}
                        </span>
                      </Link>
                    </div>
                  </div>
                  <div className={sidebarOpened ? "px-7 mt-auto" : 'px-7 mt-auto'}>
                    <Link
                      to='/changelog'
                      className='text-secondary'>
                      {APP_VERSION}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="app-main flex-column flex-row-fluid"
              id="kt_app_main"
              onClick={() => {
                if (isMobile) {
                  dispatch(setSidebarState(true));
                }
              }}
            >
              {ENV_SITE_DEV && <TestSite />}
              <div className="px-4">
                {children}
                <div className="d-flex flex-column flex-column-fluid">
                  <div
                    id="kt_app_content"
                    className="app-content flex-column-fluid"
                  >
                    <div
                      id="kt_app_content_container"
                      className="app-container container-fluid"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ThemeProvider>
  ) : (
    <Redirect to='/login' passCurrentRoute={true} />
  );
};

export default BackendLayout;
