import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import AutoCompleteDropdown from 'components/autocomplete/AutoCompleteDropdown';
import { useTranslation } from 'react-i18next';
import { Divider } from '@mui/material';
import { useState } from 'react';
import { displayNotification, generateOptionsFromData, sendAPIRequest } from 'helpers';

function SetTextTypeModals({ open, id, handleClose, textTypeDetail ,setReload ,reload}) {
    const [selectedType, setSelectedType] = useState({
        value: '',
        label: ''
    })
    const [textTypeArray, setTextTypeArray] = useState([
        { "id": 144, "label": "Manual Heading" },
        { "id": 146, "label": "Manual references" },
        { "id": 147, "label": "Manual Footer" },
        { "id": 148, "label": "Manual Appendex" },
        { "id": 149, "label": "Manual Biblography" },
        { "id": 151, "label": "Further Reading" },
        { "id": 152, "label": "Online Resources" },
        { "id": 156, "label": "Glossary Term" },
        { "id": 137, "label": "NB" },
    ])
    const { t } = useTranslation();

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 'auto',
        height: 'auto',
        bgcolor: 'background.paper',
        // border: '3px solid #ff4815',
        boxShadow: 24,
        p: 4,
        overflowY: 'auto',
        padding: 0,
    };


    const TextTypeOptions = React.useMemo(() => {
        return generateOptionsFromData(textTypeArray, {
            value: "id",
            label: "label",
        });
    }, [textTypeArray]);

    React.useEffect(() => {
        if (textTypeDetail && textTypeDetail.text_type) {
            let selected = textTypeArray.find((i) => i.id === textTypeDetail.text_type);
            if (selected) {
                setSelectedType({
                    value: selected.id,
                    label: selected.label
                });
            }
        }
    }, [textTypeDetail])


    const handleUpdate = async (Id = '',text_type='') => {
        try {
            if(id ==='' || text_type ==='') {
                displayNotification("error", "someThing went wrong")
                return ;
            }
            if (window.confirm('Are You Sure To Change Text Type?')) {
                const ret = await sendAPIRequest(`/api/html/update/text-type/${Id}`, "POST", {
                  text_type: selectedType.value,
                });
                const res = await ret.json();
                if (res.success && res.status === 200) {
                    setReload(!reload)
                    handleClose();
                    displayNotification("success", "Saved Content Successfully")
                } else {
                  displayNotification("error", "someThing went wrong")
                }
            }
        } catch (error) {
            console.log({error})
            displayNotification("error", "someThing went wrong")
        }
    }

    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box sx={{ padding: '10px 20px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Change Text Type
                        </Typography>
                    </Box>
                    <Divider sx={{ backgroundColor: '#000', margin: '0' }} />
                    <Box sx={{ padding: '20px' }}>
                        {/* <div className="row">
                            <div className="col-md-3">
                                <div className='fw-bold fs-3 me-2'>{t("-1", "Glossary Term")}:</div>
                            </div>
                            <div className="col-md-9">
                                <TextFieldCustom
                                    variant="outlined"
                                    id="Chapter"
                                    // label={t("-1", "English")}
                                    fullWidth
                                    required={true}
                                    // value={updateGlossary.glossary_term}
                                    disabled={true}
                                    sx={{
                                        backgroundColor: "white",
                                        color: "white",
                                        border: "#ff4815",
                                        width: "99%",
                                    }}
                                    size='small'
                                    // onChange={(e) => setUpdateGlossary({ ...updateGlossary, glossary_term: e.target.value })
                                    // }
                                />
                            </div>
                            <div className="col-md-3 mt-3">
                                <div className='fw-bold fs-3 me-4'>{t("-1", "Definition")}:</div>
                            </div>
                          
                        </div> */}
                        <div className='row mt-5'>
                            <div className='col-12 mb-3'>
                                <AutoCompleteDropdown
                                    label="Text Types"
                                    showClearIcon={true}
                                    error={false}
                                    required={false}
                                    multiple={false}
                                    hasselectall={false}
                                    options={TextTypeOptions}
                                    onChange={(e, value) => {
                                        setSelectedType({
                                            value: value.value,
                                            label: value.label
                                        });
                                    }}
                                    value={!selectedType.value ? { value: '', label: '' } : textTypeArray.find(r => r.id == selectedType?.value) ?? { value: '', label: '' }}
                                    size='small'
                                />
                            </div>
                        </div>
                        <div className='row mt-5'>
                            <div className='d-flex justify-content-center'>
                                <div className="text-center">
                                    <button
                                        className="btn btn--outline font-custom fs-6"
                                        onClick={() => {
                                             handleUpdate(textTypeDetail.id,selectedType.value);
                                        }}
                                    >
                                        {t("-1", "Update")}
                                    </button>
                                </div>
                                <div className="text-center ms-2">
                                    <button
                                        className="btn btn--primary font-custom fs-6"
                                        onClick={() => {
                                            handleClose();
                                        }}
                                    >
                                        {t("-1", "Cancel")}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Box>
                </Box>
            </Modal>
        </>
    )
}

export default SetTextTypeModals
