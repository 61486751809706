// src/ckeditor/customPlugin.js
import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';
import React from 'react';
import ReactDOM from 'react-dom';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import '../../../src/ckeditorListener'
import { displayNotification, sendAPIRequest } from 'helpers';


class ImagePickerDialog extends React.Component {


  constructor(props) {
    super(props);

    this.state = {
      // Define your state variables here
      images: [],
    };
  }


  handleImageSelect = (url) => {
    this.props.onSelect(url);
    this.props.onClose();
  };



  componentDidMount() {
    // Moved data fetching to useEffect
    this.getDraftImages();
  }

  getDraftImages = async () => {
    try {
        const chapterId = this.extractParameter()
        const ret = await sendAPIRequest(`/api/draft-images/?draft_chapter_id=${chapterId}`);
        const res = await ret.json();
        if (res.success && res.status === 200) {
            this.setState({ images: res.data })

          } else {
            displayNotification('error', 'Error loading data [B]');
        }
    } catch (err) {
        console.log({ err });
        displayNotification('error', 'Error loading data [F]');
    }
  }

  extractParameter(searchString = '/edit/') {
    const fragment = window.location.href
    const index = fragment.indexOf(searchString);
  
    if (index !== -1) {
      // Extract substring after searchString (including following slash)
      const substring = fragment.substring(index + searchString.length);
  
      // Use a regular expression to extract digits only
      const match = substring.match(/^\d+/); // Matches one or more digits at the beginning
  
      if (match) {
        const param = match[0]; // Extract the first match (the digits)

        return param;

      } else {
        console.log("No digits found after", searchString);
      }
    } else {
      console.log(searchString, "not found in the URL fragment.");
    }
  }
  

  render() {

    return (
      <Dialog
        open={this.props.isOpen}
        onClose={this.props.onClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Select an Image</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {this.state.images.map((image, index) => (

              <Grid item xs={4} key={index}>
                { console.log() }
                <img
                  src={ image.url }
                  alt={`img-${index}`}
                  style={{ width: '100%', cursor: 'pointer' }}
                  onClick={() => this.handleImageSelect( image.url )}
                />
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.onClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

class CustomPlugin extends Plugin {
  init() {
    const editor = this.editor;

    editor.ui.componentFactory.add('imagePicker', locale => {
      const view = new ButtonView(locale);

      view.set({
        label: 'Insert Image',
        withText: true,
        tooltip: true
      });

      view.on('execute', () => {
        const modalContainer = document.createElement('div');
        document.body.appendChild(modalContainer);

        const handleSelect = (url) => {
          editor.model.change(writer => {
            const insertPosition = editor.model.document.selection.getFirstPosition();

            window.ckEditorImageUrl = url

            writer.insertElement( 'imageBlock', { }, insertPosition );

          });
        };

        const handleClose = () => {
          ReactDOM.unmountComponentAtNode(modalContainer);
          document.body.removeChild(modalContainer);
        };

        ReactDOM.render(
          <ImagePickerDialog isOpen={true} onSelect={handleSelect} onClose={handleClose} />,
          modalContainer
        );
      });

      return view;
    });
  }
}

export default CustomPlugin;
