import AutoCompleteDropdown from 'components/autocomplete/AutoCompleteDropdown';
import CardBody from 'components/common/CardBody';
import CardHeader from 'components/common/CardHeader';
import { Box } from '@mui/material';
import Loader from 'components/common/Loader';
import Container from 'components/common/Container';
import CustomCard from 'components/common/CustomCard';
import { displayNotification, getBaseURL, sendAPIRequest } from 'helpers';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { setCurrentPage } from 'redux/generalSlice';
import { useDispatch } from 'react-redux';

const ModifyExisting = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const navigate = useNavigate();
    const [standardData, setStandardData] = useState([]);

    useEffect(() => {
        dispatch(setCurrentPage("publishing-standards"));
    }, []);

    useEffect(() => {
        getPublishingStandards();
    }, []);

    const getPublishingStandards = async () => {
        try {
            const ret = await sendAPIRequest(`/api/structure/standard-types`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                setStandardData(res.data);
            } else {
                displayNotification("error", "Failed to get Publishing Standards");
            }
        } catch (e) {
            console.log({ e });
        }
    };

    const handleClickStandard = (standard_detail = {}) => {
        try {
            navigate(`/portal/modify-existing-details?id=${standard_detail?.id}&disable=true`);
        }
        catch (error) {
            console.log(error);
        }
    }

    const getColorClass = (structureCode) => {
        switch (structureCode) {
            case "TC":
                return "btn-terrestrial-code";
            case "TM":
                return "btn-terrestrial-manual";
            case "AC":
                return "btn-aquatic-code";
            case "AM":
                return "btn-aquatic-manual";
            default:
                return "btn-default";
        }
    };

    return (
        <Container>
            <div className="card card-woah w-100 border shadow-sm rounded-0 px-4 py-2 my-2">
                <div className="row g-0">
                    <div className="col-md-6">
                        <div className="card-title1 lh-1 ">
                            {t("Modify Existing Content")}
                        </div>
                    </div>

                </div>
            </div>
            <CustomCard>
                <div className="row justify-content-start">
                    {standardData?.map((item) => {
                        const colorClass = getColorClass(item.structure_code);
                        return (
                            <div key={item.id} className="col-md-3 col-sm-6 col-12">
                                <div className="st-card">
                                    <span
                                        className={`d-block text-center ${colorClass} my-2 py-4 px-3 shadow-sm cursor-pointer`}
                                        target="_blank"
                                        onClick={() => handleClickStandard(item)}
                                    >
                                        <span className="d-block py-15 card-title4">{item.structure_name.toUpperCase()}</span>
                                        <img
                                            src={getBaseURL("/assets/icons/lines.png")}
                                            alt="Hover Image"
                                            className="hover-image"
                                        />
                                    </span>

                                </div>
                            </div>
                        );
                    })}
                </div>
            </CustomCard>
        </Container>
    );
};

export default ModifyExisting;
