import { changeSiteLanguage } from 'index';
import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import PDFViewerModal from '../components/pdf-viewer/PDFViewerModal';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { Breadcrumbs, Link, Typography } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const PDFComponent = (props) => {
    console.log(props);
    const headerTheme = useSelector((s) => s.general.headerTheme);
    const siteLanguage = useSelector(s => s.general.siteLanguageSont);
    const [open, setOpen] = useState(false);
    const handleClick = () => {
        <PDFViewerModal />
    }

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <div className="container">
                <div className=" border border-secondary p-2">
                    <div className="row border-bottom mb-2">
                        <div
                            id="lang-group"
                            className={`d-flex border-secondary border-bottom`}
                            style={{ height: '34px' }}
                        >
                            <nav aria-label="breadcrumb" className='my-auto'>
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item">{props.terrestrialCode}</li>
                                    <li class="breadcrumb-item">{props.terrestrialManual}</li>
                                    <li class="breadcrumb-item active" aria-current="page">{props.currentArticle}</li>
                                </ol>
                            </nav>
                            <div className="list-group list-group-horizontal ms-auto">
                                <span
                                    className={`bg-${headerTheme} cursor-pointer list-group-item text-hover-primary border-0 text-center font-custom ${siteLanguage == '3' ? 'text-decoration-underline' : ''}`}
                                    onClick={() => changeSiteLanguage('3')}
                                >
                                    EN
                                </span>
                                <span
                                    className={`bg-${headerTheme} cursor-pointer list-group-item text-hover-primary border-0 text-center font-custom ${siteLanguage == '5' ? 'text-decoration-underline' : ''}`}
                                    onClick={() => changeSiteLanguage('5')}
                                >
                                    FR
                                </span>
                                <span
                                    className={`bg-${headerTheme} cursor-pointer list-group-item text-hover-primary border-0 text-center  font-custom ${siteLanguage == '4' ? 'text-decoration-underline' : ''}`}
                                    onClick={() => changeSiteLanguage('4')}
                                >
                                    ES
                                </span>

                                <span className="vertical-line mx-1"></span>
                                <div className="d-flex" style={{ height: '25px' }}>
                                    <span className="fs-1 cursor-pointer mt-auto">A</span>
                                    <span className="fs-5 cursor-pointer mt-auto ms-1" style={{ marginBottom: -3 }}>A</span>
                                </div>
                                <span className="vertical-line ms-1"></span>
                                <span
                                    className={`bg-${headerTheme} cursor-pointer list-group-item text-hover-primary border-0 text-center font-custom`}
                                >
                                    <i className="fas fa-file-pdf text-primary fs-3"></i>
                                </span>
                                <span
                                    className={`bg-${headerTheme} cursor-pointer list-group-item text-hover-primary border-0 text-center font-custom ms-2`}
                                >
                                    <i className="fas fa-print text-primary fs-3"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {/* <div className='col-md-12 d-flex border-bottom border-secondary'>

                            <Breadcrumbs aria-label="breadcrumb"
                                separator={<NavigateNextIcon fontSize="small" />} >
                              
                                <Typography color="text.primary">{props.currentArticle}</Typography>
                            </Breadcrumbs>
                        </div> */}
                        <div className='col-md-12 d-flex border-bottom border-secondary'>
                            <h3>Article 8.8.2. FMD free country or zone where vaccination is not practised</h3>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 h-auto">
                            
                            <p>
                                <strong>General provisions</strong>
                                <br />

                                For the purposes of the Terrestrial Code, acarapisosis, also known as acarine disease or tracheal mite infestation, is an infestation of adult honey bees (species of the genus Apis), primarily Apismellifera L. with the mite Acarapiswoodi, an internal obligate parasite of the respiratory system which spreads by direct contact from adult honey bee to adult honey bee.
                                <br />
                                When authorising import or transit of the commodities covered in the chapter, with the exception of those listed in Article 9.1.2., Veterinary Authorities should require the conditions prescribed in this chapter relevant to the acarapisosis status of the honey bee population of the exporting country or zone.
                                <br />
                                Standards for diagnostic tests are described in the Terrestrial Manual.</p>
                        </div>
                    </div>
                    <div className="row">
                        <div
                            id="lang-group"
                            className={`d-flex`}
                        >
                            <strong>Last Update: 02-August-2023</strong>
                            <div className="list-group list-group-horizontal ms-auto">

                                <span className="text-primary cursor-pointer" variant="outlined" onClick={handleOpen}>Expand full article</span>

                                <Dialog open={open} onClose={handleClose} maxWidth="md" >
                                    <DialogContent>
                                        <PDFViewerModal />
                                    </DialogContent>
                                </Dialog>


                            </div>
                        </div>
                    </div>
                </div>
            </div>



        </>
    )
}

export default PDFComponent
