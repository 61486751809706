import { Tooltip } from "@mui/material";
import TextFieldCustom from "components/TextFieldCustom";
import AutoCompleteDropdown from "components/autocomplete/AutoCompleteDropdown";
import { LIST_DETAIL_ELEMENTS } from "constants";
import { displayNotification, findValueForDropdown, generateOptionsFromData, sendAPIRequest } from "helpers";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { setCurrentPage } from "redux/generalSlice";

const CreateTaging = ({ refetch, setRefetch, chapterDetail = null, disesaesDetails = null }) => {
    const siteLanguage = useSelector(s => s.general.siteLanguageSont)
    const { t } = useTranslation();
    const [location, setLocation] = useSearchParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id } = useParams();
    const { section_no, chapter_no, article_no, chapter_id } = {
        section_no: location.get('section_no'),
        chapter_no: location.get('chapter_no'),
        article_no: location.get('article_no'),
        chapter_id: location.get('chapter_id'),
    };
    const [disDetail, setDisDetail] = useState(disesaesDetails)
    const initForm = {
        commodity_group_id: '',
        commodity_id: [],
        animal_id: [],
        recommendation_id: disesaesDetails?.draft_recommendations_id ?? "",
        status_type_id: disesaesDetails?.recommendations_type_id ?? "",
        disease_id: disesaesDetails?.disease_id ?? '',
        section_id: chapterDetail?.section_no ?? '',
        chapter_id: chapterDetail?.chapter_no ?? '',
        article_id: chapterDetail?.article_no ?? '',
        draft_chapter_id: chapterDetail?.chapter_id ?? ""
    }
    const [form, setForm] = useState(initForm);
    const [isLoading, setIsLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    const [commodityGroups, setCommodityGroups] = useState([]);
    const [commodities, setCommodities] = useState([]);
    const [animals, setAnimals] = useState([]);
    const [recommendations, setRecommendations] = useState([]);
    const [recommendationStatusType, setRecommendationStatusType] = useState([]);
    const [diseases, setDiseases] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const l1 = searchParams.get('level1');
    const l2 = searchParams.get('level2');
    const l3 = searchParams.get('level3');
    const [level1, setLevel1] = useState(l1 ?? '0');
    const [level2, setLevel2] = useState(l2 ?? '0');
    const [level3, setLevel3] = useState(l3 ?? '0');
    const [animalList, setAnimalList] = useState([]);

    useEffect(() => {
        dispatch(setCurrentPage("administration"));
        loadData();
    }, []);
    useEffect(() => {
        setDisDetail(disesaesDetails);
        if (disesaesDetails) {
            setForm({
                ...form, recommendation_id: disesaesDetails?.draft_recommendations_id ?? "",
                status_type_id: disesaesDetails?.recommendations_type_id ?? "",
                disease_id: disesaesDetails?.disease_id ?? '',
            })
        }else{
            setForm(initForm);
        }
    }, [disesaesDetails])

    useEffect(() => {
        getCommodities();
        // getAnimalsList();
    }, [form.commodity_group_id]);

    // useEffect(() => {
    //     getCommodityGroups();
    //     getAnimalsList();
    // }, [form.commodity_id]);

    // useEffect(() => {
    //     getCommodityGroups();
    //     getCommodities();
    // }, [form.animal_id]);

    useEffect(() => {
        getCommodities();
        getCommodityGroups();
        getAnimalsList();
    }, [siteLanguage]);

    const loadData = async () => {
        setIsLoading(true);
        try {
            if (id) {
                await getCommodityData();
            }
            await getCommodityGroups();
            await getCommodities();
            // await getAnimals();
            await getAnimalsList();
            await getDiseases();
            await getRecommendations();
            await getRecommendationsStatusType();
        } catch (err) {
            console.error({ err });
        }
        setIsLoading(false);
    }

    const getCommodityData = async () => {
        try {
            const ret = await sendAPIRequest(`/api/draft-commodities-animals/${id}`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                const temp = {
                    commodity_group_id: res.data.commodity_group_id,
                    commodity_id: res.data.commodity_id,
                    animal_id: res.data.animal_id,
                    recommendation_id: res.data.recommendation_id,
                    status_type_id: res.data.status_type_id,
                    disease_id: res.data.disease_id,
                    section_id: res.data.section_id,
                    chapter_id: res.data.chapter_id,
                    article_id: res.data.article_id
                };
                setForm(temp);
            }
        } catch (err) {
            console.error({ err });
        }
    }
    /**
     * @deprecated
     */
    const _getCommodityGroups = async () => {
        try {
            const ret = await sendAPIRequest(`/api/commoditiy-groups`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                setCommodityGroups([...res.data]);
            } else {
                setCommodityGroups([]);
            }
        } catch (err) {
            console.error({ err });
        }
    }

    const getCommodityGroups = async () => {
        try {
            const ret = await sendAPIRequest(
                `/api/commoditiy-groups/?language_id=${siteLanguage}&show_in_filter=1`
            );
            const res = await ret.json();
            if (res.success && res.status === 200) {
                if (res.data.length === 1) {
                    form.commodity_group_id = res.data[0].id;
                }
                let obj = { id: 0, 'commodity_group_name': 'All' };
                if (siteLanguage == '103') {
                    obj = { id: 0, 'commodity_group_name': 'Tout' };
                } else if (siteLanguage == '104') {
                    obj = { id: 0, 'commodity_group_name': 'Todo' };
                }
                setCommodityGroups([...(res.data.length > 1 ? [obj] : []), ...res.data]);
            }
        } catch (err) {
            console.log(err);
        }
    };

    /**
     * @deprecated
     */
    const _getCommodities = async () => {
        try {
            const ret = await sendAPIRequest(`/api/commdities`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                setCommodities([...res.data]);
            } else {
                setCommodities([]);
            }
        } catch (err) {
            console.error({ err });
        }
    }

    const getCommodities = async () => {
        try {
            const ret = await sendAPIRequest(
                `/api/commdities/?commodity_group_id=${form.commodity_group_id}&language_id=${siteLanguage}&show_in_filter=1`
            );
            const res = await ret.json();
            if (res.success && res.status === 200) {
                if (res.data.length === 1) {
                    form.commodity_id = res.data[0].id;
                }
                let obj = { id: 0, 'commodity_name': 'All' };
                if (siteLanguage == '103') {
                    obj = { id: 0, 'commodity_name': 'Tout' };
                } else if (siteLanguage == '104') {
                    obj = { id: 0, 'commodity_name': 'Todo' };
                }
                setCommodities([...(res.data.length > 1 ? [obj] : []), ...res.data]);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const handleReturnIsteerestial = (isterrestrial = true, isCode = false) => {
        if (chapterDetail) {
            if (isterrestrial) {
                if (chapterDetail.animal_type === LIST_DETAIL_ELEMENTS.animal_type.terrestrial) {
                    return 1
                } else return 0
            } else {
                if (chapterDetail.standard_type === LIST_DETAIL_ELEMENTS.standard_type.codes) {
                    return 1
                } else return 0
            }
        }
        else return ''
    }

    /**
     * @deprecated
     */
    const getAnimals = async () => {
        try {
            const ret = await sendAPIRequest(`/api/animals/?isterrestrial=${handleReturnIsteerestial(true)}&iscode=${handleReturnIsteerestial(false)}`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                setAnimals([...res.data]);
            } else {
                setAnimals([]);
            }
        } catch (err) {
            console.error({ err });
        }
    }

    const getAnimalsList = async () => {
        try {
            const ret = await sendAPIRequest(`/api/animals/?isterrestrial=${handleReturnIsteerestial(true)}&iscode=${handleReturnIsteerestial(false)}&language_id=${siteLanguage}&show_in_filter=1`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                if (res.data.length === 1) {
                    form.animal_id = res.data[0].id;
                }
                let obj = { id: 0, 'animal_name': 'All' };
                if (siteLanguage == '103') {
                    obj = { id: 0, 'animal_name': 'Tout' };
                } else if (siteLanguage == '104') {
                    obj = { id: 0, 'animal_name': 'Todo' };
                }
                setAnimalList([...(res.data.length > 1 ? [obj] : []), ...res.data]);
            }
        } catch (err) {
            console.log(err);
        }
    };

    /**
     * @deprecated
     */
    const navigationFunction = (key, value) => {
        let { l1, l2, l3 } = {
            l1: level1,
            l2: level2,
            l3: level3,
        }
        if (key === 'level1') {
            l1 = value;
            setLevel1(value);
        } else if (key === 'level2') {
            l2 = value;
            setLevel2(value);
        } else if (key === 'level3') {
            l3 = value;
            setLevel3(value);
        }
        navigate(`/portal/access-commodities/?level1=${l1}&level2=${l2}&level3=${l3}`);
    }

    /**
     * @deprecated
     */
    const getFiltersLists = async () => {
        setIsLoading({ ...isLoading, loading_filters: true });
        await getCommodityGroups();
        await getCommodities();
        await getAnimalsList();
        setIsLoading({ ...isLoading, loading_filters: false });
    };

    const getDiseases = async () => {
        try {
            const ret = await sendAPIRequest(`/api/lists/master/3`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                setDiseases([...res.data]);
            } else {
                setDiseases([]);
            }
        } catch (err) {
            console.error({ err });
        }
    }
    const getRecommendations = async () => {
        try {
            const ret = await sendAPIRequest(`/api/lists/master/5`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                setRecommendations([...res.data]);
            } else {
                setRecommendations([]);
            }
        } catch (err) {
            console.error({ err });
        }
    }
    const getRecommendationsStatusType = async () => {
        try {
            const ret = await sendAPIRequest(`/api/lists/master/6`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                setRecommendationStatusType([...res.data]);
            } else {
                setRecommendationStatusType([]);
            }
        } catch (err) {
            console.error({ err });
        }
    }

    const handleSave = async () => {
        setIsSaving(true);
        try {
            if (!window.confirm('Are you sure you want to perform operation?')) {
                return;
            }
            let ret;
            if (id) {
                ret = await sendAPIRequest(`/api/draft-commodities-animals/${id}`, 'PUT', form);
            } else {
                ret = await sendAPIRequest(`/api/draft-commodities-animals`, 'POST', form);
            }
            const res = await ret.json();
            if (res.success && res.status === 200) {
                displayNotification('success', 'Successfully completed request [B]');
                handleFormReSet();
                setRefetch(!refetch);
            } else {
                displayNotification('error', 'Failed to complete request [B]');
            }
        } catch (err) {
            console.log({ err });
            displayNotification('error', 'Failed to complete request [F]');
        }
        setIsSaving(false);
    }

    const commoditiyGroupsOptions = useMemo(() => {
        return generateOptionsFromData(commodityGroups, {
            value: "id",
            label: "commodity_group_name",
        });
    }, [commodityGroups]);
    const commoditiesOptions = useMemo(() => {
        return generateOptionsFromData(commodities, {
            value: "id",
            label: "commodity_name",
        });
    }, [commodities]);
    const animalsOptions = useMemo(() => {
        return generateOptionsFromData(animalList, {
            value: "id",
            label: "animal_name",
        });
    }, [animalList]);
    const diseasesOptions = useMemo(() => {
        return generateOptionsFromData(diseases, {
            value: "id",
            label: "list_element_name",
        });
    }, [diseases]);
    const recommendationsOptions = useMemo(() => {
        return generateOptionsFromData(recommendations, {
            value: "id",
            label: "list_element_name",
        });
    }, [recommendations]);
    const recommendationsStatusTypeOptions = useMemo(() => {
        return generateOptionsFromData(recommendationStatusType, {
            value: "id",
            label: "list_element_name",
        });
    }, [recommendationStatusType]);
    const isFormValid = () => {
        if (!disesaesDetails) {
            const isValid = (
                form.commodity_group_id &&
                form.commodity_id.length > 0 &&
                form.animal_id.length > 0 &&
                form.section_id &&
                form.chapter_id &&
                form.article_id
            );
            return isValid;
        } else {
            const isValid = (
                form.commodity_group_id &&
                form.commodity_id.length > 0 &&
                form.animal_id.length > 0 &&
                form.status_type_id &&
                form.disease_id &&
                form.recommendation_id &&
                form.section_id &&
                form.chapter_id &&
                form.article_id
            );
            return isValid;
        }
    };

    const handleFormReSet = () => {
        setForm({
            commodity_group_id: '',
            commodity_id: [],
            animal_id: [],
            recommendation_id: disesaesDetails?.draft_recommendations_id ?? "",
            status_type_id: disesaesDetails?.recommendations_type_id ?? "",
            disease_id: disesaesDetails?.disease_id ?? '',
            section_id: section_no,
            chapter_id: chapter_no,
            article_id: article_no,
            draft_chapter_id: chapter_id
        });
    }



    return (
        <div className="my-2">
            {/* <div className="header card-woah border border-secondary d-flex px-4 py-2 my-2 shadow-sm w-100">
                <div className='d-flex justify-content-between w-100'>
                    <div className="card-title1 lh-1">{t("-1", "Article Tagging Form")}</div>
                    <button
                        className="btn btn--primary font-custom fs-6 ms-1"
                        onClick={() => navigate(-1)}
                    >
                        {t("-1", "Go Back")}
                    </button>
                </div>
            </div> */}

            <div className="header card-woah border border-secondary d-flex px-4 py-2 shadow-sm w-100">
                <div className="row w-100">
                    <div className="col-md-4 col-12 mt-3">
                        <AutoCompleteDropdown
                            className={isLoading ? 'opacity-50' : 'opacity-100'}
                            disabled={isLoading}
                            label={isLoading ? 'loading...' : t("-1", "Commodity Group")}
                            showClearIcon={true}
                            error={false}
                            required={true}
                            multiple={false}
                            hasselectall={false}
                            options={commoditiyGroupsOptions}
                            onChange={(event, value) => {
                                if (!value) {
                                    setForm({ ...form, commodity_group_id: '' });
                                    return;
                                }
                                setForm({ ...form, commodity_group_id: value.value });

                            }}
                            value={
                                form.commodity_group_id === ''
                                    ? { value: '', label: '' }
                                    : findValueForDropdown(commoditiyGroupsOptions, form.commodity_group_id)
                            }
                            size='small'
                        />
                    </div>
                    <div className="col-md-4 col-12 mt-3">
                        <AutoCompleteDropdown
                            className={isLoading ? 'opacity-50' : 'opacity-100'}
                            disabled={isLoading}
                            label={isLoading ? 'loading...' : t("-1", "Commodity")}
                            showClearIcon={true}
                            error={false}
                            required={true}
                            multiple={true}
                            hasselectall={false}
                            options={commoditiesOptions}
                            // onChange={(event, value) => {
                            //     if (!value) {
                            //         setForm({ ...form, commodity_id: '' });
                            //         return;
                            //     }
                            //     setForm({ ...form, commodity_id: value.value });
                            // }}
                            // value={
                            //     form.commodity_id === ''
                            //         ? { value: '', label: '' }
                            //         : findValueForDropdown(commoditiesOptions, form.commodity_id)
                            // }
                            onChange={(e, value) => {
                                // setForm(value.map((r) => r.value));

                                setForm({...form , commodity_id: value.map((r) => r.value) });
                            }}
                            value={form.commodity_id}
                            size='small'
                        />
                    </div>
                    <div className="col-md-4 col-12 mt-3">
                        <AutoCompleteDropdown
                            className={isLoading ? 'opacity-50' : 'opacity-100'}
                            disabled={isLoading}
                            label={isLoading ? 'loading...' : t("-1", "Animal")}
                            showClearIcon={true}
                            error={false}
                            required={true}
                            multiple={true}
                            hasselectall={false}
                            options={animalsOptions}
                            // onChange={(event, value) => {
                            //     if (!value) {
                            //         setForm({ ...form, animal_id: '' });
                            //         return;
                            //     }
                            //     setForm({ ...form, animal_id: value.value });
                            // }}
                            // value={
                            //     form.animal_id === ''
                            //         ? { value: '', label: '' }
                            //         : findValueForDropdown(animalsOptions, form.animal_id)
                            // }
                            onChange={(e, value) => {
                                setForm({...form , animal_id: value.map((r) => r.value) });
                            }}
                            value={form.animal_id}
                            size='small'
                        />
                    </div>
                    {
                        !disDetail ?
                            (<div className="col-md-4 col-12 mt-3">
                                <AutoCompleteDropdown
                                    className={isLoading ? 'opacity-50' : 'opacity-100'}
                                    disabled={isLoading}
                                    label={isLoading ? 'loading...' : t("-1", "Diseases")}
                                    showClearIcon={true}
                                    error={false}
                                    required={true}
                                    multiple={false}
                                    hasselectall={false}
                                    options={diseasesOptions}
                                    onChange={(event, value) => {
                                        if (!value) {
                                            setForm({ ...form, disease_id: '' });
                                            return;
                                        }
                                        setForm({ ...form, disease_id: value.value });
                                    }}
                                    value={
                                        form.disease_id === ''
                                            ? { value: '', label: '' }
                                            : diseasesOptions.find((r) => parseInt(r.value) === parseInt(form.disease_id)) ?? { value: '', label: '' }
                                    }
                                    size='small'
                                />
                            </div>) : !form.recommendation_id && <div className="col-md-4 col-12 mt-3">
                                <AutoCompleteDropdown
                                    className={isLoading ? 'opacity-50' : 'opacity-100'}
                                    disabled={isLoading}
                                    label={isLoading ? 'loading...' : t("-1", "Diseases")}
                                    showClearIcon={true}
                                    error={false}
                                    required={true}
                                    multiple={false}
                                    hasselectall={false}
                                    options={diseasesOptions}
                                    onChange={(event, value) => {
                                        if (!value) {
                                            setForm({ ...form, disease_id: '' });
                                            return;
                                        }
                                        setForm({ ...form, disease_id: value.value });
                                    }}
                                    value={
                                        form.disease_id === ''
                                            ? { value: '', label: '' }
                                            : diseasesOptions.find((r) => parseInt(r.value) === parseInt(form.disease_id)) ?? { value: '', label: '' }
                                    }
                                    size='small'
                                />
                            </div>
                    }
                    {

                        !disDetail ? (
                            <div className="col-md-4 col-12 mt-3">
                                <AutoCompleteDropdown
                                    className={isLoading ? 'opacity-50' : 'opacity-100'}
                                    disabled={isLoading}
                                    label={isLoading ? 'loading...' : t("-1", "Recommendations")}
                                    showClearIcon={true}
                                    error={false}
                                    required={true}
                                    multiple={false}
                                    hasselectall={false}
                                    options={recommendationsOptions}
                                    onChange={(event, value) => {
                                        if (!value) {
                                            setForm({ ...form, recommendation_id: '' });
                                            return;
                                        }
                                        setForm({ ...form, recommendation_id: value.value });
                                    }}
                                    value={
                                        form.recommendation_id === ''
                                            ? { value: '', label: '' }
                                            : recommendationsOptions.find((r) => parseInt(r.value) === parseInt(form.recommendation_id)) ?? { value: '', label: '' }
                                    }
                                    size='small'
                                />
                            </div>
                        ) :
                            !form?.recommendation_id &&
                            <div className="col-md-4 col-12 mt-3">
                                <AutoCompleteDropdown
                                    className={isLoading ? 'opacity-50' : 'opacity-100'}
                                    disabled={isLoading}
                                    label={isLoading ? 'loading...' : t("-1", "Recommendations")}
                                    showClearIcon={true}
                                    error={false}
                                    required={true}
                                    multiple={false}
                                    hasselectall={false}
                                    options={recommendationsOptions}
                                    onChange={(event, value) => {
                                        if (!value) {
                                            setForm({ ...form, recommendation_id: '' });
                                            return;
                                        }
                                        setForm({ ...form, recommendation_id: value.value });
                                    }}
                                    value={
                                        form.recommendation_id === ''
                                            ? { value: '', label: '' }
                                            : recommendationsOptions.find((r) => parseInt(r.value) === parseInt(form.recommendation_id)) ?? { value: '', label: '' }
                                    }
                                    size='small'
                                />
                            </div>
                    }
                    {
                        !disDetail ? (
                            <div className="col-md-4 col-12 mt-3">
                                <AutoCompleteDropdown
                                    className={isLoading ? 'opacity-50' : 'opacity-100'}
                                    disabled={isLoading}
                                    label={isLoading ? 'loading...' : t("-1", "Recommendations Status Type")}
                                    showClearIcon={true}
                                    error={false}
                                    required={true}
                                    multiple={false}
                                    hasselectall={false}
                                    options={recommendationsStatusTypeOptions}
                                    onChange={(event, value) => {
                                        if (!value) {
                                            setForm({ ...form, status_type_id: '' });
                                            return;
                                        }
                                        setForm({ ...form, status_type_id: value.value });
                                    }}
                                    value={
                                        form.status_type_id === ''
                                            ? { value: '', label: '' }
                                            : recommendationsStatusTypeOptions.find((r) => parseInt(r.value) === parseInt(form.status_type_id)) ?? { value: '', label: '' }
                                    }
                                    size='small'
                                />
                            </div>
                        ) :
                            !form?.recommendation_id &&
                            <div className="col-md-4 col-12 mt-3">
                                <AutoCompleteDropdown
                                    className={isLoading ? 'opacity-50' : 'opacity-100'}
                                    disabled={isLoading}
                                    label={isLoading ? 'loading...' : t("-1", "Recommendations Status Type")}
                                    showClearIcon={true}
                                    error={false}
                                    required={true}
                                    multiple={false}
                                    hasselectall={false}
                                    options={recommendationsStatusTypeOptions}
                                    onChange={(event, value) => {
                                        if (!value) {
                                            setForm({ ...form, status_type_id: '' });
                                            return;
                                        }
                                        setForm({ ...form, status_type_id: value.value });
                                    }}
                                    value={
                                        form.status_type_id === ''
                                            ? { value: '', label: '' }
                                            : recommendationsStatusTypeOptions.find((r) => parseInt(r.value) === parseInt(form.status_type_id)) ?? { value: '', label: '' }
                                    }
                                    size='small'
                                />
                            </div>
                    }
                    <div className="col-12 text-end mt-3 pe-5">
                        <button
                            className="btn btn--primary font-custom fs-6"
                            onClick={() => {
                                handleSave();
                                navigate(-1)
                            }}
                            disabled={!isFormValid() || isSaving}
                        >
                            {
                                id ? "Update" : "Save"
                            }
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CreateTaging;
