import { Box, FormControl, FormControlLabel, Grid, Radio, RadioGroup } from '@mui/material'
import HtmlRenderer from 'components/HtmlRenderer copy';
import AutoCompleteDropdown from 'components/autocomplete/AutoCompleteDropdown';
import CardBody from 'components/common/CardBody';
import CardHeader from 'components/common/CardHeader';
import Column from 'components/common/Column';
import CustomCard from 'components/common/CustomCard'
import Row from 'components/common/Row';
import { displayNotification, generateOptionsFromData, sendAPIRequest } from 'helpers';
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next';
import cloneDeep from "lodash/cloneDeep";
import Button from 'components/common/Button';

const ListClasses = [
    { id: 1, label: 'Para1i' },
    { id: 2, label: 'Para2i' },
    { id: 3, label: 'Para3i' },
    { id: 4, label: 'Para4i' },
    { id: 5, label: 'Para5i' },
    { id: 6, label: 'Para6i' },
    { id: 7, label: 'Para7i' },
    { id: 8, label: 'Para8i' },
    { id: 9, label: 'Para9i' }
];

const OrderListTypes = [
    { id: 1, label: "a" },
    { id: 2, label: "A" },
    { id: 3, label: "i" },
    { id: 4, label: "I" },
    { id: 5, label: "1" },
];

const UnorderListTypes = [
    { id: 1, label: "disc" },
    { id: 2, label: "circle" },
    { id: 3, label: "square" },
    { id: 4, label: "none" },
];

const ListsComponent = ({ list }) => {
    const { t } = useTranslation();
    const [selectedListOption, setSelectedListOption] = useState('ordered-list');
    const [selectedListType, setSelectedListType] = useState({ value: '', label: '' });
    const [selectedClass, setSelectedClass] = useState({ value: '', label: '' });
    const [tempList, setTempList] = useState(list.map(l => l?.content).join(''));

    useEffect(() => {
        handleChangeListOption();
    }, [selectedListOption]);

    useEffect(() => {
        handleTypeSelection();
    }, [selectedListType.label]);

    useEffect(() => {
        handleClassSelection();
    }, [selectedClass.label]);

    const handleTypeSelection = () => {
        let temp = cloneDeep(list.map(l => l?.content).join(''));
        if (selectedListOption === 'ordered-list') {
            temp = `<ol class="maunal-order-list ${selectedClass.label ?? ''}" type=${selectedListType.label ?? 1}>${temp}</ol>`;
            setTempList(temp);
        } else {
            temp = `<ul class="maunal-unorder-list ${selectedClass.label ?? ''}" style="list-style-type:${selectedListType.label ?? "circle"} !important;">${temp}</ul>`;
            setTempList(temp);
        }
    }

    const handleClassSelection = () => {
        let temp = cloneDeep(list.map(l => l?.content).join(''));
        if (selectedListOption === 'ordered-list') {
            temp = `<ol class="maunal-order-list ${selectedClass.label ?? ''}" type=${selectedListType.label ?? 1}>${temp}</ol>`;
            setTempList(temp);
        } else {
            temp = `<ul class="maunal-unorder-list ${selectedClass.label ?? ''}" style="list-style-type:${selectedListType.label ?? "circle !important"};">${temp}</ul>`;
            setTempList(temp);
        }
    }

    const handleChangeListOption = () => {
        let temp = cloneDeep(list.map(l => l?.content).join(''));
        if (selectedListOption === 'ordered-list') {
            temp = `<ol class="maunal-order-list">${temp}</ol>`;
            setTempList(temp);
        } else {
            temp = `<ul class="maunal-unorder-list">${temp}</ul>`;
            setTempList(temp);
        }
    }

    const handleChangeList = (event) => {
        setSelectedListOption(event.target.value);
    };

    const handleSave = async () => {
        try {
            if (!window.confirm('Are you sure you want to update?')) {
                return;
            }
            const ids = list.map(x => x.id);
            const idForUpdate = ids[0];
            const idsForDelete = ids.filter((v, i) => i !== 0);
            const ret = await sendAPIRequest(`/api/data-cleaning/update-list/${idForUpdate}`,
                'PUT',
                {
                    ids: idsForDelete,
                    content: tempList
                }
            );
            const res = await ret.json();
            if (res.success && res.status === 200) {
                displayNotification('success', 'Successfully updated list [B]');
            } else {
                displayNotification('error', 'Failed update list [B]');
            }
        } catch (err) {
            console.log({ err });
            displayNotification('error', 'Failed update list [F]');
        }
    }

    const getSelectedListTypeValue = () => {
        const currentOptions = selectedListOption === 'ordered-list' ? orderListTypesOptions : unorderListTypesOptions;
        return !selectedListType
            ? { value: '', label: '' }
            : currentOptions.find((r) => parseInt(r.value) === parseInt(selectedListType.value)) ?? { value: '', label: '' };
    }

    const listClassesOptions = useMemo(() => {
        return generateOptionsFromData(ListClasses, {
            value: "id",
            label: "label",
        });
    }, []);
    const orderListTypesOptions = useMemo(() => {
        return generateOptionsFromData(OrderListTypes, {
            value: "id",
            label: "label",
        });
    }, []);
    const unorderListTypesOptions = useMemo(() => {
        return generateOptionsFromData(UnorderListTypes, {
            value: "id",
            label: "label",
        });
    }, []);

    return (
        <>
            <CustomCard>
                <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <FormControl>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={selectedListOption}
                            onChange={handleChangeList}
                            className="text-center d-flex justify-content-between"
                        >
                            <FormControlLabel
                                sx={{
                                    paddingX: '20px',
                                }}
                                value="ordered-list"
                                control={<Radio size="small" />}
                                label={t("-1", "Ordered List​")}
                            />
                            <FormControlLabel
                                sx={{
                                    paddingX: '20px',
                                }}
                                value="unordered-list"
                                control={<Radio size="small" />}
                                label={t("-1", "Un-Ordered List​")}
                            />
                        </RadioGroup>
                    </FormControl>

                </Grid>
                <div className="row">
                    <div className="col-md-6">
                        <Box sx={{ minWidth: 120 }}>
                            <AutoCompleteDropdown
                                label="List Types"
                                showClearIcon={true}
                                error={false}
                                required={false}
                                multiple={false}
                                hasselectall={false}
                                options={selectedListOption === 'ordered-list' ? orderListTypesOptions : unorderListTypesOptions}
                                onChange={(event, value) => {
                                    setSelectedListType({
                                        value: value?.value,
                                        label: value?.label
                                    })
                                }}
                                value={getSelectedListTypeValue()}
                                size='small'
                            />
                        </Box>
                    </div>
                    <div className="col-md-6">
                        <Box sx={{ minWidth: 120 }}>
                            <AutoCompleteDropdown
                                label="Classes"
                                showClearIcon={true}
                                error={false}
                                required={false}
                                multiple={false}
                                hasselectall={false}
                                options={listClassesOptions}
                                onChange={(event, value) => {
                                    setSelectedClass({
                                        value: value?.value,
                                        label: value?.label
                                    })
                                }}
                                value={
                                    !selectedClass
                                        ? { value: '', label: '' }
                                        : listClassesOptions.find((r) => parseInt(r.value) === parseInt(selectedClass.value)) ?? { value: '', label: '' }
                                }
                                size='small'
                            />
                        </Box>
                    </div>
                </div>
            </CustomCard>

            <CustomCard>
                <CardBody>
                    {
                        tempList && <>
                            <Row>
                                <Column col={6} className={'border-right p-2'}>
                                    {
                                        tempList
                                    }
                                </Column>
                                <Column col={6}>
                                    <HtmlRenderer
                                        htmlContent={tempList}
                                    />
                                </Column>
                            </Row>
                        </>
                    }
                    <Button
                        onClick={() => handleSave()}
                    >
                        Save Changes
                    </Button>
                </CardBody>
            </CustomCard>
        </>
    )
}

ListsComponent.defaultProps = {
    list: []
};

export default ListsComponent
