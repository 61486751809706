import React, { useState, useMemo, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import Typography from '@mui/material/Typography';
import { useImperativeHandle } from 'react';
import Box from '@mui/material/Box'
import AutoCompleteDropdown from 'components/autocomplete/AutoCompleteDropdown';
import { generateOptionsFromData, sendAPIRequest } from "../../helpers"

const SetImageModal = ({ tooltipModal, setTooltipModal, setGLossaryData, glossaryData, handleSetMultipleImages, setMarkedString, imageContent, setSelectedImageInfo, imageOptions, handleImageSet, documentImages, image, loading, setSelectedImage, currentImage, setCurrentImage, s, children, content, header, reloadData, rows, btn1Text, btn2Text,
    checkModal, setCheckModal }) => {




    const { t } = useTranslation();

    const [modalShowing, setModalShowing] = useState(false);
    const [modalOpened, setModalOpened] = useState(false);

    // const [documentImages, setDocumentImages] = useState([])
    // const [loading, setLoading] = useState(false)
    // const [image, setSelectedImage] = useState({
    //     value: '',
    //         label: ''
    // })
    // const [currentImage, setCurrentImage] = useState([])

    const [data, setData] = useState({
        updated_content: content,
        header: header,
        reloadData: reloadData,
        rows: rows,
        btn1_text: btn1Text,
        btn2_text: btn2Text,
    });


    const handleSave = () => {
        setTooltipModal(false);
        setGLossaryData(null)
        setModalOpened(false);
        data.reloadData && data.reloadData(data.updated_content);
        setData({});
    }

    const handleOpen = (content, header, reloadData, rows, btn1Text, btn2Text) => {
        if (!modalOpened) {
            content && setData(prev => ({ ...prev, updated_content: content }));
            header && setData(prev => ({ ...prev, header: header }));
            reloadData && setData(prev => ({ ...prev, reloadData: reloadData }));
            rows && setData(prev => ({ ...prev, rows: rows }));
            btn1Text && setData(prev => ({ ...prev, btn1_text: btn1Text }));
            btn2Text && setData(prev => ({ ...prev, btn2_text: btn2Text }));
            setModalOpened(true);
            setModalShowing(true);
        }
    }


    return (
        <>
            {children(handleOpen)}
            <Modal show={tooltipModal} size='md' centered={true}>
                <Modal.Header
                    className='p-4'
                    closeButton
                    onClick={() => (setTooltipModal(false), setGLossaryData(null))}
                >
                    <Modal.Title>{data.header}</Modal.Title>
                </Modal.Header>
                <Modal.Body className='p-4'>
                    <div className="col-md-12-">

                        {/* <Box sx={{ minWidth: 120 }}>
                            <AutoCompleteDropdown
                                className={loading ? 'opacity-50' : 'opacity-100'}
                                // className={'opacity-100'}
                                disabled={loading}
                                label={loading ? 'loading...' : t("-1", "Images")}
                                // label={ t("-1", "Chapter")}
                                showClearIcon={true}
                                error={false}
                                required={false}
                                multiple={false}
                                hasselectall={false}
                                options={imageOptions}
                                onChange={(event, value) => {
                                    setSelectedImage({
                                        value: value?.value,
                                        label: value?.label,

                                    });
                                    setCurrentImage(documentImages.filter(img => img.id === value.value).map(({ id, image_name }) => ({ value: id, label: image_name }))[0]
                                    );
                                }
                                }
                                value={
                                    !image.value ? null : imageOptions.find(r => r.value == image.value) ?? { value: '', label: '' }
                                }
                                size='small'
                            />
                        </Box> */}
                     <Typography variant="body1">glossaryTerm:{glossaryData?.glossary_term?glossaryData.glossary_term:""}</Typography>
                     <Typography variant="body1">glossaryText:{glossaryData?.glossary_text?glossaryData.glossary_text:""}</Typography>
                    </div>
                </Modal.Body>
                <Modal.Footer className='p-4 d-flex justify-content-center'>
                    <button
                        // variant="dark" 
                        className='btn btn--primary'
                        onClick={() => (setTooltipModal(false), setGLossaryData(null))}
                    >
                        {t("-1", "Close")}
                    </button>
                    <button
                        // variant="primary" 
                        className='btn btn--outline'
                        onClick={() => handleImageSet(content,)}
                    >
                        {t("-1", "Update")}
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

SetImageModal.defaultProps = {
    children: () => { },
    content: "",
    header: "Edit Text",
    reloadData: null,
    rows: 1,
    btn1Text: "Close",
    btn2Text: "Update",
}

export default SetImageModal;