
import React, { useEffect, useMemo, useState } from 'react'
import Checkbox from '@mui/material/Checkbox';
import { useTranslation } from 'react-i18next';
import AutoCompleteDropdown from 'components/autocomplete/AutoCompleteDropdown';
import { displayNotification, generateOptionsFromData, sendAPIRequest } from 'helpers';
import { Box } from '@mui/material';
import HtmlRenderer from 'components/HtmlRenderer';
import Loader from 'components/common/Loader';
import PreviewModal from 'components/modals/PreviewModal';
import CustomChip from 'components/CustomChip';
import { BsInfoCircleFill } from 'react-icons/bs';

const GlossaryValidation = ({ animal_type, standard_type, chapter, componentLanguage }) => {
    const { t } = useTranslation();
    const [alertText, setAlertText] = useState(t("-1", `All prepublished chapters in Upload are shown in the chapter dropdown. Users can verify each chapter individually or use 'Load Next Chapter' to load the next one. When a new term is added and validated for pre-publication, it will appear below with all relevant data from the applicable standard.`));
    const [isAlertVisible, setIsAlertVisible] = useState(true);
    const [DropDownData, setDropDownData] = useState([]);
    const [openPreview, setopenPreview] = useState(false);
    const [selectedValue, setSelectedValue] = useState([]);
    const [data, setData] = useState({});
    const [isSearch, setIsSearch] = useState(false);
    const [prevId, setprevId] = useState('');
    const [spinloader, setSpinLoder] = useState(false);
    const [term, setTerm] = useState('');
    const [selectedRows, setSelectedRows] = useState(new Set());
    const [chapterData, setChapterData] = useState([])
    const [selectedChapter, setSelectedChapter] = useState({
        value: '',
        label: ''
    })
    const [currentIndex, setCurrentIndex] = useState(0);


    // Pagination states
    const [currentPage, setCurrentPage] = useState(1);
    const rowsPerPage = 15;
    const totalRows = Object.keys(data).reduce((total, key) => total + data[key].length, 0);
    const startRow = (currentPage - 1) * rowsPerPage + 1;
    const endRow = Math.min(startRow + rowsPerPage - 1, totalRows);
    const [filters, setFilters] = useState({
        chapter_selection: []
    });
    const handleSelectAll = (e) => {
        const newSelectedRows = new Set();
        if (e.target.checked) {

            Object.keys(data).forEach((key) => {
                data[key].forEach((val) => {
                    newSelectedRows.add(val.id);
                });
            });
        }
        setSelectedRows(newSelectedRows);
    };

    const showAlert = (text) => {
        setAlertText(text);
        setIsAlertVisible(prev => !prev);
    };

    const handleSelectRow = (id) => {
        setSelectedRows((prevSelectedRows) => {
            const newSelectedRows = new Set(prevSelectedRows);
            if (newSelectedRows.has(id)) {
                newSelectedRows.delete(id);
            } else {
                newSelectedRows.add(id);
            }
            return newSelectedRows;
        });
    };

    const isAllSelected = selectedRows.size === totalRows;



    const loadFilterData = async () => {
        try {
            const ret = await sendAPIRequest(`/api/temp-glossary-linking/get-glossaries`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                setDropDownData(res.data);
            }
        } catch (e) {
            console.log({ e });
            displayNotification('error', e.message);
        }
    }

    const loadChapterData = async () => {
        try {
            const ret = await sendAPIRequest(`/api/temp-glossary-linking/load-chapter-with-linking`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                setChapterData(res.data);
            }
        } catch (e) {
            console.log({ e });
            displayNotification('error', e.message);
        }
    }

    const handleignoreGlossary = async (ids = []) => {
        if (!window.confirm('Are you sure you want to delete?')) {
            return;
        }
        try {
            const ret = await sendAPIRequest(`/api/handle-publication/ignore-temp-glossary`, "DELETE", {
                id: ids
            });
            const res = await ret.json();
            if (res.success && res.status === 200) {
                loadData();
                displayNotification("success", "SuccessFully Deleted");
            } else {
                displayNotification("error", res.message);
            }
        } catch (error) {
            console.log(error);
            displayNotification('error', "Error ignoring glossary");
        }
    }

    const handlebulkIgnoreGlossary = async () => {
        if (!window.confirm('Are you sure you want to delete?')) {
            return;
        }
        try {
            const ret = await sendAPIRequest(`/api/handle-publication/ignore-temp-glossary`, "DELETE", {
                ids: Array.from(selectedRows)
            });
            const res = await ret.json();
            if (res.success && res.status === 200) {
                loadData();
                displayNotification("success", "SuccessFully Deleted");
            } else {
                displayNotification("error", res.message);
            }
        } catch (error) {
            console.log(error);
            displayNotification('error', "Error ignoring glossary");
        }
    }

    const handlePublishGlossary = async () => {
        if (!window.confirm('Are you sure you want to publish?')) {
            return;
        }
        try {
            setSpinLoder(true)
            const ret = await sendAPIRequest(`/api/handle-publication/glossary-final-publish/`, "POST", {
                ids: Array.from(selectedRows)
            });
            const res = await ret.json();
            if (res.success && res.status === 200) {
                loadChapterData();
                setSpinLoder(false);
                loadData();
                displayNotification("success", "Successfully Published");
            } else {
                setSpinLoder(false);
                displayNotification("error", res.message);
            }
        } catch (error) {
            setSpinLoder(false);
            console.log(error);
            displayNotification('error', t("-1", "Error publishing glossary"));
        }
    }

    const handleCloseModal = () => {
        setopenPreview(false);
        setprevId('');
        setTerm('');
    }

    const openPreviewModal = (id = '', term) => {
        setopenPreview(true);
        setprevId(id);
        setTerm(term);
    }

    const loadData = async () => {
        setSpinLoder(true);
        try {
            const ret = await sendAPIRequest(`/api/temp-glossary-linking/?structures_id=${selectedChapter.value ?? ""}`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                setData(res.data);
            }
        } catch (e) {
            console.log({ e });
            displayNotification('error', e.message);
        }
        setSpinLoder(false);
    }

    const currentRows = Object.keys(data).reduce((acc, key) => {
        return acc.concat(data[key]);
    }, []).slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);

    const totalPages = Math.ceil(totalRows / rowsPerPage);

    const chapterOptions = useMemo(() => {
        return generateOptionsFromData(chapterData, {
            value: "id",
            label: "chapter_display_name",
        }
        );
    }, [chapterData]);


    const loadNextChapter = () => {
        if(chapterOptions.length !== currentIndex) {
            let tem = chapterOptions[currentIndex+1];
            if(tem) {
                setCurrentIndex(currentIndex+1);
                setSelectedChapter({
                    value: tem.value,
                    label: tem.label
                });
            }
        }
    }

    useEffect(() => {
        loadFilterData();
        loadChapterData();
    }, []);

    useEffect(() => {
        if (selectedChapter.value) {
            loadData();
        }
    }, [selectedChapter.value]);

    useEffect(() => {
        if (chapterOptions.length > 0) {
            setCurrentIndex(0);
            setSelectedChapter({
                value: chapterOptions[currentIndex].value,
                label: chapterOptions[currentIndex].label   
            });
        }
    }, [chapterOptions]);



    return (
        <>
            <div className=" my-2 container">
                <div className='row'>
                    <div className="d-flex align-items-center mb-6">
                        <BsInfoCircleFill
                            className="bi flex-shrink-0 me-4 ms-3 mb-auto text-blue-light-2 cursor-pointer"
                            size={24}
                            onClick={() => showAlert(alertText)}
                        />
                        {isAlertVisible && (
                            <div className="alert my-0 py-0 me-5  rounded-0 text-dark d-flex align-items-center" role="alert">
                                <div className="fs-7 text-justify me-0">
                                    {alertText}
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="col-md-4 mb-3">
                        <AutoCompleteDropdown
                            showClearIcon={true}
                            required={false}
                            error={false}
                            label={t("-1", "Filter Terms")}
                            multiple={true}
                            size='medium'
                            hasselectall={true}
                            disabled={false}
                            options={DropDownData}
                            onChange={(event, value) => {
                                if (value.some((v) => v.value === "Select all")) {
                                    if (filters.chapter_selection.length === DropDownData.length) {

                                        setFilters({
                                            ...filters,
                                            chapter_selection: [],
                                        });
                                    } else {

                                        setFilters({
                                            ...filters,
                                            chapter_selection: DropDownData.map((r) => r.value),
                                        });
                                    }
                                } else {

                                    setFilters({
                                        ...filters,
                                        chapter_selection: value.map((r) => r.value),
                                    });
                                }
                                setIsSearch(true);
                            }}
                            value={filters.chapter_selection}
                        />
                    </div>
                    <div className="col-md-5 mb-3">
                        <AutoCompleteDropdown
                            showClearIcon={true}
                            multiple={false}
                            required={false}
                            error={false}
                            label={t("-1", "Chapter")}
                            size='medium'
                            disabled={false}
                            options={chapterOptions}
                            onChange={(event, value) => {
                                if (!value) {
                                    setSelectedChapter({
                                        value: "",
                                        label: ""
                                    });
                                    return;
                                }
                                setSelectedChapter({
                                    value: value.value,
                                    label: value.label
                                });
                                setCurrentIndex(chapterOptions.findIndex((element) => element.value === value.value));
                                setIsSearch(true);
                            }}
                            value={
                                selectedChapter.value ? selectedChapter : { value: "", label: "" }
                            }
                        />
                    </div>
                    <div className="col-md-3 mt-3 text-end">
                        <button
                            className="btn btn--primary font-custom fs-6 ms-1"
                            disabled={chapterOptions.length === currentIndex + 1}
                            onClick={loadNextChapter}
                        >
                            {t("-1", "Load Next Chapter")}
                        </button>
                    </div>
                </div>

                {
                    spinloader && (
                        <div className='d-flex justify-content-center'>
                            <Loader message='Loading Data, Please wait...' />
                        </div>
                    )
                }
                {
                    !spinloader && <>
                        <div className='table-responsive'>
                            <table
                                className="table border table-bordered border-secondary scrollbar"
                                style={{ backgroundColor: "#ffff", 'overflowY': 'auto' }}
                            >
                                <thead
                                    className="bg-primary position-sticky text-white"
                                    style={{ top: "0", zIndex: 1 }}
                                >
                                    <tr>
                                        <th className="">Sr.</th>
                                        <th className="">Glossary Term</th>
                                        <th className="text-center">
                                            <Checkbox className="text-light p-0"
                                                onChange={handleSelectAll}
                                                checked={isAllSelected}
                                            /> <span className='ms-1'>Select All</span></th>
                                        <th className="">Text</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentRows.map((val, index) => (
                                        <tr key={val.id}>
                                            <td>{(currentPage - 1) * rowsPerPage + index + 1}</td>
                                            <td>{val.glossary_term}</td>
                                            <td>
                                                <Checkbox
                                                    className="text-primary"
                                                    checked={selectedRows.has(val.id)}
                                                    onChange={() => handleSelectRow(val.id)}
                                                />
                                            </td>
                                            <td>
                                                <Box
                                                    key={val.id}
                                                    sx={{
                                                        width: '600px',
                                                        color: "black",
                                                        borderRadius: "4px",
                                                        fontSize: "14px",
                                                    }}
                                                >
                                                    <HtmlRenderer htmlContent={val.content} />
                                                    <i>{val.chapter_display_name}-({val.standard_short_name})</i>
                                                </Box>
                                            </td>
                                            <td>
                                                <div className="d-flex mt-2">
                                                    <CustomChip
                                                        label="Ignore" type="red"
                                                        style={{ marginRight: '10px' }}
                                                        onClick={() => handleignoreGlossary([val.id])}
                                                    />
                                                    <CustomChip
                                                        label="Preview" type="green"
                                                        style={{ marginRight: '10px' }}
                                                        onClick={() => openPreviewModal(val.content_id, val.glossary_term)}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="d-flex justify-content-end align-items-center my-2">
                            <span className='px-5'>Rows per page:</span>
                            <span className='px-5'>{startRow}-{endRow} of {totalRows}</span>
                            <div>
                                <i
                                    className="fa-solid fa-angle-left cursor-pointer mx-4 text-dark"
                                    onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                                ></i>
                                <i
                                    className="fa-solid fa-angle-right cursor-pointer mx-4 text-dark"
                                    onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                                ></i>
                            </div>
                        </div>
                        <div>
                            <div className="row">
                                <div className="col-md-12 d-flex justify-content-end">

                                    <div className="ms-auto">
                                        <button
                                            className="btn btn--primary font-custom fs-6 ms-1"
                                            disabled={selectedRows.size === 0}
                                            onClick={handlebulkIgnoreGlossary}
                                        >
                                            {t("-1", "Ignore")}
                                        </button>
                                        <button
                                            className="btn btn--outline font-custom fs-6 ms-1"
                                            disabled={selectedRows.size === 0}
                                            onClick={handlePublishGlossary}
                                        >
                                            {t("-1", "Publish")}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>
            <PreviewModal
                open={openPreview}
                handleCloseModal={handleCloseModal}
                prevId={prevId}
                term={term}
            />
        </>
    )
}

export default GlossaryValidation
