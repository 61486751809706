import { createSlice } from '@reduxjs/toolkit';
import { SITE_LANGUAGES } from '../constants';

export const tabsSlice = createSlice({
  name: 'tabs',
  initialState: {
    tabName: ''
  },
  reducers: {
    setCurrentTab: (state, payload ) => {
      state.tabName = payload.payload;
    },
  },
})

export const { setCurrentTab } = tabsSlice.actions

export default tabsSlice.reducer