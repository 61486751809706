const CustomCard = ({children, className, style}) => {
    return (
        <div style={style} className={`header card-woah border border-secondary px-4 py-2 my-2 shadow-sm w-100 ${className}`}>
            {children}
        </div>
    );
}

CustomCard.defaultProps = {
    children: <></>,
    style: {},
    className: ''
};

export default CustomCard;