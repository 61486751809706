/**
 * 
 * @param {*} children
 * @param {string} className
 * @param {*} varaint - primary(default) | outline 
 * @param {function} onClick
 * @param {string} font
 * @returns 
 */
const Button = ({ children, className, variant, onClick, font, disabled }) => {

    const variants = {
        custom_primary: 'btn--primary',
        primary: 'btn-primary',
        outline: 'btn--outline',
        secondary: 'btn-secondary',
        danger: 'btn-danger',
        success: 'btn-success',
        warning: 'btn-warning',
        info: 'btn-info',
        light: 'btn-light',
        dark: 'btn-dark',
    }
    const current_variant = variants[variant] ?? 'btn--primary';

    return (
        <button
            className={`btn ms-1 ${className} ${current_variant} ${font}`}
            onClick={() => onClick()}
            disabled={disabled}
        >
            {children}
        </button>
    );
}

Button.defaultProps = {
    children: <></>,
    className: '',
    variant: 'custom_primary',
    font: 'font-custom fs-6',
    disabled: false,
    onClick: () => {}
};

export default Button;