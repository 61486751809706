import { displayNotification, sendAPIRequest } from 'helpers';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import HTMLDropDownMenu from './HTMLDropDownMenu';
import { HTML_DROP_DOWN_MENU_LIST } from 'constants';
import Spinner from 'components/spinner/Spinner';
import EditModal from 'components/modals/EditModal';
import HTMLSplitModal from 'components/modals/HTMLSplitModal';
import HtmlRenderer from 'components/HtmlRenderer';
import { LIST_DETAIL_ELEMENTS } from 'constants';

const HTMLDataMapping = () => {

    const { t } = useTranslation();
    const { id } = useParams();
    const editRef = useRef();
    const splitRef = useRef();
    const navigate = useNavigate();
    const targetArticleRef = useRef(null);

    const [documentData, setDocumentData] = useState([]);
    const [htmlArticleContent, setHTMLArticleContent] = useState([]);
    const [selectedItem, setSelectedItem] = useState('');
    const [currentItem, setCurrentItem] = useState('');
    const [currentItemTextType, setCurrentItemTextType] = useState('');
    const [isLoading, setIsLoading] = useState({
        loading_document_data: true,
        loading_article_content: false,
        mark_as_complete: false,
    });

    useEffect(() => {
        getDocumentData();
    }, []);

    const scrollToArticle = () => {
        if (targetArticleRef.current) {
            targetArticleRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }
    }

    const goBack = () => {
        navigate(`/portal/data-migrations`);
    }

    const getDocumentData = async () => {
        setIsLoading({ ...isLoading, loading_document_data: true });
        try {
            const ret = await sendAPIRequest(`/api/contents/articles/?document_id=${id}`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                setDocumentData(res.data);
            }
        } catch (error) {
            console.error(error);
        }
        setIsLoading({ ...isLoading, loading_document_data: false });
    }

    const getArticleContent = async (id, index) => {
        setIsLoading({ ...isLoading, loading_article_content: true });
        try {
            const ret = await sendAPIRequest(`/api/contents/article-content/${id}`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                setSelectedItem(id);
                setCurrentItem(index);
                setHTMLArticleContent(res.data);
            }
        } catch (error) {
            console.error(error);
        }
        setIsLoading({ ...isLoading, loading_article_content: false });
    }

    const markAsCompleted = async () => {
        setIsLoading({ ...isLoading, mark_as_complete: true });
        try {
            const ret = await sendAPIRequest(
                `/api/documents/${id}`,
                'PUT',
                {
                    is_completed_htm_mapping: 1,
                }
            );
            const res = await ret.json();
            if (res.success && res.status === 200) {
                displayNotification('success', 'Html mapping completed [B]');
            } else {
                displayNotification('error', 'Failed to complete process [B]');
            }
        } catch (error) {
            console.error(error);
            displayNotification('error', 'Failed to complete process [F]');
        }
        setIsLoading({ ...isLoading, mark_as_complete: false });
    }

    const handleEdit = async (id, content) => {
        try {
            const ret = await sendAPIRequest(
                `/api/html-contents/${id}`,
                'PUT',
                {
                    content
                }
            );
            const res = await ret.json();
            if (res.success && res.status === 200) {
                displayNotification('success', 'Successfully updated html content [B]');
                await getArticleContent(selectedItem, currentItem);
            } else {
                displayNotification('error', 'Error updating html content [B]');
            }
        } catch (error) {
            console.error(error);
            displayNotification('error', 'Error updating html content [F]');
        }
    }

    const handleSplit = async (content, splitedContent) => {
        try {
            const id = content.id;
            delete content.id;
            const updatedContent = { ...content, content: splitedContent.updated_content };
            const newContent = { ...content, content: splitedContent.new_content };
            const ret = await sendAPIRequest(
                `/api/html-contents/split-content/${id}`,
                'POST',
                {
                    updated_content: updatedContent,
                    new_content: newContent
                }
            );
            const res = await ret.json();
            if (res.success && res.status === 200) {
                displayNotification('success', 'Successfully splited content [B]');
                await getArticleContent(selectedItem, currentItem);
            } else {
                displayNotification('error', 'Error spliting content [B]');
            }
        } catch (error) {
            console.error(error);
            displayNotification('error', 'Error spliting content [F]');
        }
    }

    const handleRecommendation = async (content, text_type) => {
        try {
            let ret;
            let child_id = selectedItem;
            const con = documentData.filter(d => d.id === child_id);
            if (parseInt(currentItemTextType) === parseInt(LIST_DETAIL_ELEMENTS.text_types.article_heading)) {
                ret = await sendAPIRequest(
                    `/api/contents/update-content-linking`,
                    'POST',
                    {
                        text_type: text_type,
                        standard_content: content.content,
                        parent_id: selectedItem,
                        document_id: id,
                        level: 5,
                        ...(
                            con ? { offset: con[0].offset } : {}
                        )
                    }
                );
            } else {
                ret = await sendAPIRequest(
                    `/api/contents/${selectedItem}`,
                    'PUT',
                    {
                        standard_content: content.content,
                        ...(
                            con ? { offset: con[0].offset } : {}
                        )
                    }
                );
            }
            const res = await ret.json();
            child_id = res.data.id;
            if (res.success && res.status === 200) {
                const ret = await sendAPIRequest(
                    `/api/html-contents/${content.id}`,
                    'PUT',
                    {
                        is_ignore: 1
                    }
                )
                const res = await ret.json();
                if (res.success && res.status === 200) {
                    displayNotification('success', 'Successfully perform operation [B]');
                    await getDocumentData();
                    await getArticleContent(child_id, currentItem);
                    // scrollToArticle();
                } else {
                    displayNotification('error', 'Error performing operation [B]');
                }
            } else {
                displayNotification('error', 'Error performing operation [B]');
            }
        } catch (error) {
            console.error(error);
            displayNotification('error', 'Error performing operation [F]');
        }
    }

    const handleMenuClick = async (id, menu_id, index = null) => {
        try {
            let body = {};
            if (menu_id === -1) {
                const content = htmlArticleContent.filter(article => article.id === id)[0];
                editRef.current.handleOpen(content.content, "Edit Content", (con) => handleEdit(id, con), 5)
                return;
            }
            if (menu_id === -2) {
                const content = htmlArticleContent.filter(article => article.id === id)[0];
                splitRef.current.handleOpen(content.content, "Split Content", (con) => handleSplit(content, con), 5)
                return;
            }
            // if ([92, 93, 94, 101].includes(menu_id) === -3) {
            if ([92, 93, 94, 101].includes(parseInt(menu_id))) {
                const content = htmlArticleContent.filter(article => article.id === id)[0];
                await handleRecommendation(content, menu_id);
                return;
            }
            if (menu_id === 0) {
                body = {
                    is_ignore: 1
                }
            } else {
                body = {
                    text_type: menu_id
                }
            }
            const ret = await sendAPIRequest(
                `/api/html-contents/${id}`,
                'PUT',
                body
            );
            const res = await ret.json();
            if (res.success && res.status === 200) {
                displayNotification('success', 'Successfully updated html content [B]');
                await getArticleContent(selectedItem, currentItem);
            } else {
                displayNotification('error', 'Error updating html content [B]');
            }
        } catch (error) {
            console.error(error);
            displayNotification('error', 'Error updating html content [F]');
        }
    }

    return (
        <>
            <EditModal ref={editRef} />
            <HTMLSplitModal ref={splitRef} />
            <div className="container my-2">
                <div className="row header card-woah border border-secondary d-flex px-4 py-2 shadow-sm w-100">
                    <div className='col-md-8 d-flex justify-content-between'>
                        <h1 className="col-md-4 card-title1 font-custom-2 lh-1">{t("159", "HTML Data Mapping")}</h1>
                    </div>
                    <div className="col-md-4 header-right-items ms-auto d-flex align-items-center">
                        <div className='ms-auto'>
                            <button
                                className="btn btn--primary font-custom fs-6 ms-1"
                                onClick={() => window.open(`/portal/html-chapter/?document=${id}`, '__blank')}
                            >
                                {t("160", "Chapter Preview")}
                            </button>
                        </div>
                        <div className="ms-auto">
                            <button
                                className="btn btn--primary font-custom fs-6 ms-1"
                                onClick={() => goBack()}
                            >
                                {t("162", "Back")}
                            </button>
                        </div>
                    </div>
                </div>
                <div className="card card-woah border border-secondary shadow rounded-0 mt-2">
                    <div className='row'>
                        <div className='col-md-2'>
                            <div
                                className='overflow-x-hidden overflow-y-auto scrollspy-inner'
                                style={{
                                    height: "75vh",
                                }}
                            >
                                {

                                    documentData && documentData.length > 0
                                        ? documentData.map((d, index) =>
                                            <div key={d.id} className='my-1' id={`article-${d.id}`} ref={currentItem === index ? targetArticleRef : null}>
                                                <div className='list-group bg-light btn-sm d-flex flex-column p-1'>
                                                    <button
                                                        disabled={isLoading.loading_document_data}
                                                        className={
                                                            `${isLoading.loading_document_data ? 'opacity-50' : 'opacity-100'} list-group-item rounded-0 list-group-item-action my-1 fs-5 fw-bold cursor-pointer  ${currentItem === index ? 'list-group-item-checked' : ''}`
                                                        }
                                                        onClick={() => {
                                                            getArticleContent(d.child ? d.child.id : d.id, index);
                                                            setCurrentItemTextType(d.child ? d.child.text_type : d.text_type)
                                                        }}
                                                    >
                                                        <div className='d-flex flex-column'>
                                                            <HtmlRenderer tag={'span'} htmlContent={d.standard_content} />
                                                            {d.child && <HtmlRenderer tag={'span'} className={'fs-8 text-primary ms-3'} htmlContent={d.child && d.child.standard_content} />}
                                                        </div>
                                                    </button>
                                                </div>
                                            </div>
                                        ) : (
                                            isLoading.loading_document_data ?
                                                <Spinner message="Loading..." />
                                                : <div className='text-center'>No content available</div>
                                        )
                                }
                            </div>
                        </div>
                        <div className='col-md-10'>
                            <div className='row p-1'>
                                <div className='col-md-12'>
                                    <div
                                        className='overflow-x-hidden overflow-y-auto scrollspy-example'
                                        style={{
                                            height: "75vh",
                                        }}
                                    >
                                        <div className='d-flex justify-content-between ms-auto my-2  border-bottom border-2'>
                                            <h2 className='p-3 lh-1'>{t("163", "HTML Migrated Content")}</h2>
                                            <button
                                                type='button'
                                                className='my-auto mx-2 border border-2 rounded-2 p-3 bg-light border-primary fs-7'
                                                onClick={() => markAsCompleted()}
                                            >
                                                {t("164", "Mark as Completed")}
                                            </button>
                                        </div>
                                        {
                                            htmlArticleContent && htmlArticleContent.length > 0 ? (
                                                htmlArticleContent.map((d, i) =>
                                                    <div className='d-flex my-auto' key={d.id}>
                                                        <HtmlRenderer
                                                            tag={
                                                                [LIST_DETAIL_ELEMENTS.styles_categories.image, LIST_DETAIL_ELEMENTS.styles_categories.table].includes(d.text_type)
                                                                    ? 'i' : 'div'
                                                            }
                                                            className={isLoading.loading_article_content ? "opacity-50" : "opacity-100"}
                                                            htmlContent={d.content}
                                                        />
                                                        {
                                                            !isLoading.loading_article_content &&
                                                            <HTMLDropDownMenu
                                                                menuList={
                                                                    [
                                                                        ...HTML_DROP_DOWN_MENU_LIST,
                                                                        { id: -1, label: t('166','Edit Content') },
                                                                        { id: -2, label: t('167','Split Content') }
                                                                    ]
                                                                }
                                                                id={d.id}
                                                                className='fs-3'
                                                                handleMenuClick={handleMenuClick}
                                                                index={i}
                                                            />
                                                        }
                                                        {/* <div className='my-auto ms-4'>
                                                                <span
                                                                    type='button'
                                                                    className='my-auto border border-2 rounded-2 p-1 bg-light border-primary fs-7'
                                                                    onClick={() => handleMenuClick(d.id, -3)}
                                                                >
                                                                    Update Style
                                                                </span>
                                                            </div> */}
                                                    </div>
                                                )
                                            ) : (
                                                <div className='text-center'>No content available</div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default HTMLDataMapping;