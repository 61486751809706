import { Editor } from '@tinymce/tinymce-react'
import React from 'react'
import collect from 'collect.js';
import HtmlRenderer from 'components/HtmlRenderer';
import Spinner from 'components/spinner/Spinner';
import { LIST_DETAIL_ELEMENTS } from 'constants';
import { displayNotification, sendAPIRequest } from 'helpers';
import { useEffect, useState } from 'react';
import { useRef } from 'react'
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { setCurrentPage, setHeaderTheme } from 'redux/generalSlice';
import { SpringContext } from '@react-spring/core';
import { set } from 'lodash';


const Editer = (props) => {
  const dispatch = useDispatch();
  const [location, setLocation] = useSearchParams();
  const [lastUpdated, setLastUpdated] = useState('');
  let [initialValue, setInitialValue] = useState('');
  const [isLoader, setIsLoader] = useState(true);
  const [editerLoader, setEditerLoader] = useState(false);
  const { chapter_id, article_id, document_id } = {
    chapter_id: location.get('chapter'),
    article_id: location.get('article'),
    document_id: location.get('document'),
  };
  const [data, setData] = useState([]);
  const [documentID, setDocumentID] = useState('');
  const [isLoading, setIsLoading] = useState({
    loading_data: false,
  });
  const [documentMetaData, setDocumentMetaData] = useState({
    section_name: '',
    chapter_name: '',
  });


  useEffect(() => {

    if (props.selectedOption === 'terrestrial-manual' || props.selectedOption === 'aquatic-manual') {
      getDocId();
    }
    else {
      getChapterData();
    }

  }, [props.chapter]);

  useEffect(() => {
    setStringValue();
  }, [data]);




  const getDocId = async () => {
    try {
      const ret = await sendAPIRequest(`/api/structure/get-doc-id`, "POST", {
        name: props.dat.label,
        no: props.dat.value
      })
      const res = await ret.json()
      if (res.success && res.status === 200) {
        // setSections(res.data);

        if (res.data) {
          loadHtmlData(res.data.document_id)
          // laodMetaData(res.data.document_id)
        }
      }
    } catch (error) {
      console.log(error)
      displayNotification('error', 'SomeThing Went Wrong Please Try Again');
    }
  }

  const loadHtmlData = async (id) => {

    try {
      setIsLoading({ ...isLoading, load_data: true });
      const ret = await sendAPIRequest(`/api/html/manual-crack/${id}?isrequired=true`);
      const res = await ret.json();
      // console.log("Data", res.data)
      // console.log("DbStyleLoad", res.flag.styleTag)


      if (res.success && res.status === 200) {
        setData(res.data);
        setStringValue();
        // setDbStyleLoad(res.flag.styleTag ?? null)
        // setIsLoading({ ...isLoading, load_data: false });
      }
    } catch (e) {
      console.log({ e });
      setIsLoader(false);
    }
    setIsLoader(false)
  };



  const getChapterData = async () => {
    setIsLoading({ ...isLoading, loading_data: true });
    try {

      const ret = await sendAPIRequest(`/api/contents/navigation-chapter-preview/?chapter=${props.chapter}`);
      const res = await ret.json();
      if (res.success && res.status === 200) {
        setData(res.data);
        setIsLoader(false);
        res.data.forEach(d => (
          d.html_contents && d.html_contents.length > 0 && d.html_contents.forEach(c => {
            if (c.text_type == LIST_DETAIL_ELEMENTS.styles_categories.code_editing_date_text) {
              setLastUpdated(c.content);
            }
          })
        ))
        setStringValue();
        const section = res.data.filter(d => d.text_type === LIST_DETAIL_ELEMENTS.text_types.section_name)[0]?.ref_number;
        const chapter = res.data.filter(d => d.text_type === LIST_DETAIL_ELEMENTS.text_types.chapter_name)[0]?.ref_number;
        setDocumentID(res.data.filter(d => d.text_type === LIST_DETAIL_ELEMENTS.text_types.chapter_name)[0]?.document_id);
        setDocumentMetaData({ ...documentMetaData, section_name: section, chapter_name: chapter })
      }
    } catch (e) {
      displayNotification('error', 'Failed to load chapter data [F]');
    }
    setIsLoading({ ...isLoading, loading_data: false });
  }



  // Check if data is available and not empty
  if (data && data.length > 0) {
    // Concatenate all the text content from the data array
    let initialValue1 = '';
    initialValue1 = data.reduce((acc, d) => {
      // Concatenate the text content of the current item
      if (d.standard_content) {
        acc += `${d.standard_content}\n`;
      }
      // Concatenate the text content of children if present
      if (d.children && d.children.length > 0) {
        d.children.forEach(child => {
          if (child.standard_content) {
            acc += `${child.standard_content}\n`;
          }
        });
      }
      return acc;
    }, '');
  } else {
    // If data is empty, set initialValue to indicate no data
    initialValue = 'No Data Found';
  }
  const handleEditerLoader = () => {
    setEditerLoader(false);
  }

  const setStringValue = () => {
    let finalString = ''
    if (data && data.length > 0) {
      for (let x of data) {
        finalString += " " + x.standard_content + '\n'
        if (x.html_contents && x.html_contents.length > 0) {
          for (let y of x.html_contents) {
            if (props.selectedOption === 'terrestrial-manual' || props.selectedOption === 'aquatic-manual') {
              finalString += " " + y.html+ '\n'
            }
            else{
              finalString +=" "+y.content +'\n'
            }
            
          }
        }
      }
      setInitialValue(finalString);
    }
    else {
      return
    }


  }


  return (
    <>
      {isLoader ? (<div className="text-center">
        <Spinner />
      </div>) : (<>
        {editerLoader && <Spinner />}
        <Editor
          apiKey='446lfc67h6qcqdq5qf12qu4yf9v9sh0sisrab1nf2cd2gtfr'
          init={{
            plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount checklist mediaembed casechange export formatpainter pageembed linkchecker a11ychecker tinymcespellchecker permanentpen powerpaste advtable advcode editimage advtemplate ai mentions tinycomments tableofcontents footnotes mergetags autocorrect typography inlinecss markdown',
            toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
            tinycomments_mode: 'embedded',
            tinycomments_author: 'Author name',
            mergetags_list: [
              { value: 'First.Name', title: 'First Name' },
              { value: 'Email', title: 'Email' },
            ],
            ai_request: (request, respondWith) => respondWith.string(() => Promise.reject("See docs to implement AI Assistant")),
          }}
          initialValue={initialValue}
          onInit={handleEditerLoader}
        />
      </>)}

    </>
  )
}

export default Editer
