import { CKEditor } from "@ckeditor/ckeditor5-react";
import HtmlRenderer from "components/HtmlRenderer copy";
import ClassicEditor from "components/text_editor/CustomCKEditor";
import { getStandardAgainstIDs, sendAPIRequest } from "helpers";
import useDebounce from "hooks/useDebounse";
import { cloneDeep } from "lodash";
import DataTable from "pages/components/DataTable";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const PublishedLinksModal = ({ children, data, reloadData, }) => {

    const { t } = useTranslation();

    const [showModal, setshowModal] = useState(false);
    const [isModalOpened, setIsModalOpened] = useState(false);
    const [linksData, setLinksData] = useState(data);
    const [editorData, setEditorData] = useState('');
    const [focusContent, setFocusContent] = useState(null);
    const debouncedValue = useDebounce(editorData, 60 * 1000);

    useEffect(() => {
        if (editorData && focusContent) {
            handleUpdate();
        }
    }, [debouncedValue, focusContent, editorData]);

    const handleUpdate = async () => {
        try {
            const ret = await sendAPIRequest(`/api/html-contents/${focusContent}`, 'PUT', { content: editorData });
            const res = await ret.json();
        } catch (e) {
            console.log({ e });
        }
    }

    const handleOpen = () => {
        if (!isModalOpened) {
            setshowModal(true);
            setIsModalOpened(true);
        }
    }

    const handleClose = () => {
        setshowModal(false);
        setIsModalOpened(false);
        reloadData();
    }

    return (
        <>
            {children(handleOpen)}
            <Modal
                show={showModal} fullscreen centered={true}
            >
                <Modal.Header
                    className='p-4'
                    closeButton
                    onClick={() => (handleClose())}
                >Published Links</Modal.Header>
                <Modal.Body className='p-4'>
                    <DataTable
                        getRowId={(row) => row.id}
                        isLoading={false}
                        rows={linksData}
                        className="text-white bg-primary rounded-0"
                        columns={
                            [
                                {
                                    field: "standard_",
                                    headerName: t("-1", "Standard"),
                                    headerClassName: "text-white bg-primary rounded-0",
                                    flex: 0.7,
                                    valueGetter: (params) => {
                                        return getStandardAgainstIDs(params.row?.standard_type, params.row?.animal_type, true);
                                    }
                                },
                                {
                                    field: "target",
                                    headerName: t("-1", "Link Refrence"),
                                    headerClassName: "text-white bg-primary rounded-0",
                                    flex: 0.5,
                                },
                                {
                                    field: "link_text",
                                    headerName: t("-1", "Link Text"),
                                    headerClassName: "text-white bg-primary rounded-0",
                                    flex: 0.7,
                                },
                                {
                                    field: "content",
                                    headerName: t("-1", "Content"),
                                    headerClassName: "text-white bg-primary rounded-0",
                                    flex: 6,
                                    renderCell: (params) => {
                                        return <HtmlRenderer
                                        htmlContent={params.row?.content}
                                        />
                                    }
                                },
                            ]
                        }
                    />
                    {/* < table className = "table table-striped table-hover" >
                        <thead className="bg-primary text-white p-2">
                            <tr>
                                <th>#</th>
                                <th>Standard</th>
                                <th>Volume No.</th>
                                <th>Section No.</th>
                                <th>Chapter No.</th>
                                <th>Article No.</th>
                                <th>Link Text</th>
                                <th>Content</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                linksData.map((d, index) => (
                                    <tr key={index}>
                                        <td>
                                            {index + 1}
                                        </td>
                                        <td>
                                            {
                                                getStandardAgainstIDs(d.standard_type, d.animal_type, true)
                                            }
                                        </td>
                                        <td>
                                            { d.volume }
                                        </td>
                                        <td>{ d.section }</td>
                                        <td>{ d.chapter }</td>
                                        <td>{ d.article }</td>
                                        <td>{ d.link_text }</td>
                                        <td>
                                        <CKEditor
                                                className="rounded-0"
                                                editor={ClassicEditor}
                                                data={d.chapter_reference.html_content.content}
                                                onReady={editor => {}}
                                                onChange={(event, editor) => {
                                                    setEditorData(editor.getData());
                                                    setFocusContent(d.chapter_reference.content_id);
                                                    const temp = cloneDeep(linksData);
                                                    temp[index].chapter_reference.html_content.content = editor.getData();
                                                    setLinksData(temp);
                                                }}
                                                onBlur={(event, editor) => {}}
                                                onFocus={(event, editor) => {}}
                                            />
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </> */}
                </Modal.Body>
                <Modal.Footer className='p-4 d-flex justify-content-end'></Modal.Footer>
            </Modal>
        </>
    );
}

PublishedLinksModal.defaultProps = {
    children: () => { },
    data: [],
    reloadData: () => { },
};

export default PublishedLinksModal;