import { Button, CardBody, Modal } from 'react-bootstrap';
import {
    Box,
    Divider,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
} from "@mui/material";
import TextFieldCustom from "components/TextFieldCustom";
import AutoCompleteDropdown from "components/autocomplete/AutoCompleteDropdown";
import CardHeader from "components/common/CardHeader";
import Container from "components/common/Container";
import CustomCard from "components/common/CustomCard";
import StandardsSelectCard from "components/common/StandardsSelectCard";
import ChapterAddModel from "components/modals/ChapterAddModel";
import { CODE_VOLUMES_AGAINST_AREA_NUMBER } from "constants";
import { AQUATIC_MANUAL_VOLUMES_AGAINST_AREA_NUMBER } from "constants";
import { TERRESTRIAL_MANUAL_VOLUMES_AGAINST_AREA_NUMBER } from "constants";
import { LIST_DETAIL_ELEMENTS } from "constants";
import { displayNotification, generateOptionsFromData, sendAPIRequest, } from "helpers";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setCurrentPage, setHeaderTheme } from "redux/generalSlice";
import StructureNavigationComponent from 'pages/frontend/components/StructureNavigationComponent';
import Row from 'components/common/Row';
import Column from 'components/common/Column';
import ActionButton from 'components/common/ActionButton';
import Loader from 'components/common/Loader';

const initForm = {
    standard_type: 5,
    animal_type: 7,
    area_vol_no: "",
    area_vol_name: "",
    section_no: "",
    section_name: "",
    chapter_no: "",
    chapter_name: "",
    lang_id: 102,
};

const StandardStructureModal = ({ children, draftID, reloadData }) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [modalShowing, setModalShowing] = useState(false);
    const [modalOpened, setModalOpened] = useState(false);

    const [sections, setSections] = useState([]);
    const [currentVolumeGroup, setCurrentVolumeGroup] = useState({});
    const [currentSection, setCurrentSection] = useState([]);
    const [availableVolumes, setAvailableVolumes] = useState([]);
    const [componentLanguage, setComponentLanguage] = useState(
        LIST_DETAIL_ELEMENTS.languages.english
    );
    const [codesSectionsGroups, setCodesSectionsGroups] = useState({});
    const [sectionID, setSectionID] = useState("");
    const [availableChapters, setAvailableChapters] = useState([]);
    const [selectedOption, setSelectedOption] = useState("terrestrial-code");
    const [volumn, setSelectedVolum] = useState({
        value: "",
        label: "",
    });
    const [chapter, setSelectedChapter] = useState({
        value: "",
        label: "",
    });
    const [section, setSelectedSection] = useState({
        value: "",
        label: "",
    });
    const [isLoading, setIsLoading] = useState({
        loading_filters: false,
        loading_data: false,
        deleting: {
            status: false,
            rid: null,
        },
    });
    const [selectedRadio, setSelectedRadio] = useState({
        animal_type: LIST_DETAIL_ELEMENTS.animal_type.terrestrial,
        standard: LIST_DETAIL_ELEMENTS.standard_type.codes,
    });
    const [form, setForm] = useState(initForm);
    const [chaps, setChaps] = useState(null);
    const [dropDownForm, setDropDownForm] = useState({
        area_vol_no: '',
        area_vol_name: "",
        section_no: '',
        section_name: "",
        structures_id: "",
        chapter_no: "",
        chapter_name: "",
    });

    const [openModal, setOpenModal] = useState(false);
    const [isSetStructure, setIsSetStructure] = useState(false);

    useEffect(() => {
        dispatch(setHeaderTheme('white'));
        dispatch(setCurrentPage("glossary-management"));
    }, []);

    useEffect(() => {
        loadSections();
        loadAreaVolumns()
    }, [componentLanguage]);

    useEffect(() => {
        setSelectedOption("terrestrial-code");
        window.scrollTo(0, 0);
        const handleScroll = () => {
        };
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    useEffect(() => {
        setAvailableChapters([]);
        setSelectedChapter({ value: "", label: "" });
        loadSections();
        loadAreaVolumns()
    }, [selectedOption]);

    useEffect(() => {
        if (form.section_no) {
            try {
                loadChapters();
            } catch (error) {
                console.error("Error loading chapters:", error);
            }
        } else {
            setAvailableChapters([]);
            setSelectedChapter({ value: "", label: "" });
        }
    }, [form.section_no, form.lang_id]);

    useEffect(() => {
        switchLanguage();
        setVolume();
    }, [componentLanguage]);

    useEffect(() => {
        loadSections()
    }, [form.area_vol_no]);

    useEffect(() => {
        loadSections();
        if (selectedOption === "terrestrial-manual") {
            setCurrentVolumeGroup(TERRESTRIAL_MANUAL_VOLUMES_AGAINST_AREA_NUMBER);
        } else if (selectedOption === "aquatic-manual") {
            setCurrentVolumeGroup(AQUATIC_MANUAL_VOLUMES_AGAINST_AREA_NUMBER);
        } else {
            setCurrentVolumeGroup(CODE_VOLUMES_AGAINST_AREA_NUMBER);
        }
    }, [selectedOption]);

    const switchLanguage = async () => {
        try {
            const sect = await loadSections();
            const s_id = getSectionID(section.value, sect);
            if (s_id) {
                const chap = await loadChapters(s_id);
                getChapterID(chapter.value, chap);
            } else {
                if (section.value) {
                    await loadChapters();
                }
            }
        } catch (err) {
            console.error(err);
        }
    };

    const setVolume = () => {
        if (selectedOption === 'terrestrial-manual') {
            setCurrentVolumeGroup(TERRESTRIAL_MANUAL_VOLUMES_AGAINST_AREA_NUMBER[parseInt(componentLanguage)]);
        } else if (selectedOption === 'aquatic-manual') {
            setCurrentVolumeGroup(AQUATIC_MANUAL_VOLUMES_AGAINST_AREA_NUMBER[parseInt(componentLanguage)]);
        } else {
            setCurrentVolumeGroup(CODE_VOLUMES_AGAINST_AREA_NUMBER[parseInt(componentLanguage)]);
        }
    }

    const getChapterID = (ref_number = null, chap = null, sect = null) => {
        const chapters = chap ? chap : availableChapters;
        let c_id;
        if (sect) {
            c_id = chapters.filter(
                (chapter) =>
                    chapter.ref_number === ref_number && chapter.parent_id === sect
            )[0];
        } else {
            c_id = chapters.filter(
                (chapter) => parseInt(chapter.ref_number) === parseInt(ref_number)
            )[0];
        }
        return c_id?.id;
    };

    const setCodesSectionsGroupsList = (sect = []) => {
        let obj = {};
        sect.forEach((section) => {
            obj[section.id] = section.display_name;
        });
        setCodesSectionsGroups(obj);
    };

    const modifyData = (data, modiferText) => {
        let temp = [];
        data.forEach((d) => {
            if (d.ref_number === 0) {
                d.children &&
                    d.children.length > 0 &&
                    d.children.forEach((child) => {
                        temp.push({
                            ...child,
                            standard_content: `${child.standard_content}`,
                        });
                        // }
                    });
            } else {
                temp.push(d);
            }
        });
        return temp;
    };

    const getSectionID = (unique_name = null, sect = null) => {
        const tempSections = sect ? sect : sections;
        const s_id = tempSections.filter(
            (section) => section.unique_name === unique_name
        )[0];
        setSectionID(s_id?.id);
        return s_id?.id;
    };

    const loadAreaVolumns = async () => {
        setIsLoading({ ...isLoading, load_sections: true });
        try {
            const ret = await sendAPIRequest(
                `/api/draft-standard-structure/get/generic-volumns/?language_id=${componentLanguage}&animal_type=${selectedRadio.animal_type}&standard_type=${selectedRadio.standard}`
            );
            const res = await ret.json();
            if (res.success && res.status === 200) {
                setAvailableVolumes(res.data)
                setIsLoading({ ...isLoading, load_sections: false });
            }
        } catch (e) {
            console.log({ e });
            setIsLoading({ ...isLoading, load_sections: false });
        }
        setIsLoading({ ...isLoading, load_sections: false });
    };

    const loadSections = async () => {
        setIsLoading({ ...isLoading, load_sections: true });
        try {
            const ret = await sendAPIRequest(
                `/api/draft-standard-structure/get/generic-sections/?language_id=${componentLanguage}&animal_type=${selectedRadio.animal_type}&standard_type=${selectedRadio.standard}&volume_no=${volumn.value ?? ''}`
            );
            const res = await ret.json();
            if (res.success && res.status === 200) {
                const sect = modifyData(res.data, "Section");
                setSections(sect);
                setCodesSectionsGroupsList(res.data);
                setIsLoading({ ...isLoading, load_sections: false });
                return sect;
            }
        } catch (e) {
            console.log({ e });
            setIsLoading({ ...isLoading, load_sections: false });
        }
        setIsLoading({ ...isLoading, load_sections: false });
    };

    const loadChapters = async () => {
        setIsLoading({ ...isLoading, load_chapters: true });
        try {
            const ret = await sendAPIRequest(`/api/standard-structures/chapters/?model=draft&lang_id=${form.lang_id}&animal_type=${form.animal_type}&standard_type=${form.standard_type}&area_vol_no=${form.area_vol_no ?? ''}&section_no=${form.section_no ?? ''}`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                setAvailableChapters(res.data);
                setIsLoading({ ...isLoading, load_chapters: false });
                return res.data;
            }
        } catch (e) {
            console.log({ e });
            setIsLoading({ ...isLoading, load_chapters: false });
        }
    }

    // const loadChapters = async (id = null) => {
    //     setIsLoading({ ...isLoading, load_chapters: true });
    //     try {
    //         let RID = sections?.find(
    //             (sections) => sections.unique_name === section.value
    //         )?.RID;
    //         const ret = await sendAPIRequest(
    //             `/api/draft-standard-structure/get/generic-chapters/?language_id=${form.lang_id}&section_id=${id ? id : sectionID ? sectionID : ""
    //             }&animal_type=${form.animal_type}&standard_type=${form.standard}&RID=${RID ? RID : ""}`);
    //         const res = await ret.json();
    //         if (res.success && res.status === 200) {
    //             setAvailableChapters(res.data);
    //             setIsLoading({ ...isLoading, load_chapters: false });
    //             return res.data;
    //         }
    //     } catch (e) {
    //         console.log({ e });
    //     }
    //     setIsLoading({ ...isLoading, load_chapters: false });
    // };

    const handleCLoseMoal = () => {
        setOpenModal(false);
        setDropDownForm({
            area_vol_no: "",
            area_vol_name: "",
            section_no: "",
            section_name: "",
            chapter_no: "",
            chapter_name: "",
            lang_id: "",
        });
    };

    const handleAddVolSection = async (reloadData = null) => {
        try {
            if (!window.confirm("Are you sure you want to add a new chapter?")) { return; }
            const ret = await sendAPIRequest(`/api/handle-publication/update-metadata/${draftID}`, 'PUT', {
                area_vol_no: form?.area_vol_no,
                area_vol_name: form?.area_vol_name,
                section_no: form?.section_no,
                section_name: form?.section_name,
                chapter_no: reloadData ? reloadData.chapter_no : dropDownForm.chapter_no,
                chapter_name: reloadData ? reloadData.chapter_name : dropDownForm.chapter_name,
                structures_id: '',
                volumn: dropDownForm.volume_name,
                section: dropDownForm.section_name,
                standard_type: form.standard_type,
                animal_type: form.animal_type,
                lang_id: form.lang_id,
            });
            const res = await ret.json();
            if (res.success && res.status === 200) {
                if (dropDownForm && dropDownForm.chapter_name && dropDownForm.chapter_no) {
                    setIsSetStructure(true);
                    await loadChapters();
                } else {
                    await loadSections();
                    await loadAreaVolumns()
                    await loadChapters();
                    resetSectionSelected();
                    dropDownFormReset();
                }
                handleCLoseMoal();
                displayNotification("success", "Successfully Added");
            } else {
                if (res.message === 'Section already exists' || res.message === 'Volumn already exists') {
                    displayNotification("error", res.message);
                } else {
                    displayNotification("error", "Failed to save");
                }
            }
        } catch (e) {
            console.log({ e });
            setIsLoading({ ...isLoading, load_sections: false });
            displayNotification("error", "failed to add");
        }
    };

    const dropDownFormReset = () => {
        setDropDownForm({
            animal_type: "",
            standard_type: "",
            area_vol_no: "",
            area_vol_name: "",
            section_no: "",
            section_name: "",
            chapter_no: "",
            chapter_name: "",
            lang_id: "",
        });
    };

    const resetSectionSelected = () => {
        setSelectedSection({ value: "", label: "" });
        setCurrentSection([]);
        setSectionID("");
    };

    const handleChapterEdit = async (rid, lang_id, chapter_name) => {
        try {
            if (!rid || !chapter_name) {
                return;
            }
            const ret = await sendAPIRequest(
                `/api/draft-standard-structure/edit/${rid}/${lang_id}`, "PUT", {
                chapter_name: chapter_name
            },
            );
            const res = await ret.json();
            if (res.success && res.status === 200) {
                setIsSetStructure(true);
                loadChapters();
                handleCLoseMoal();
            }
        } catch (e) {
            console.log({ e });
        }
    }

    const handleOpen = () => {
        if (!modalOpened) {
            setModalShowing(true);
            setModalOpened(true);
        }
    }

    const handleClose = () => {
        setModalShowing(false);
        setModalOpened(false);
        reloadData(isSetStructure);
    }

    const handleDelete = async (RID = null) => {
        try {
            setIsLoading({
                ...isLoading, deleting: {
                    status: true,
                    rid: RID
                }
            });
            if (!RID) {
                displayNotification('error', 'Invalid request');
                return;
            }
            if (!window.confirm("Are you sure you want to delete this chapter?")) { return; }
            const ret = await sendAPIRequest(`/api/standard-structures/delete-standard-chapter/${RID}`, 'DELETE');
            const res = await ret.json();
            if (res.success && res.status === 200) {
                await loadChapters();
                displayNotification("success", "Successfully Deleted");
            } else {
                displayNotification("error", "Failed to delete");
            }
            setIsLoading({
                ...isLoading, deleting: {
                    status: false,
                    rid: RID
                }
            });
        } catch (e) {
            console.log({ e });
            displayNotification("error", "Failed to delete");
            setIsLoading({
                ...isLoading, deleting: {
                    status: false,
                    rid: RID
                }
            });
        }
    }

    return (
        <>
            {children(handleOpen)}
            <Modal show={modalShowing} size='lg' centered={true}>
                <Modal.Header
                    className='p-4'
                    closeButton
                    onClick={() => (handleClose())}
                >
                    <Modal.Title>Standard Structure</Modal.Title>
                </Modal.Header>
                <Modal.Body className='p-4'>
                    <>
                        <CustomCard>
                            <CardHeader>
                                {t("231", "Structure Management")}
                            </CardHeader>
                        </CustomCard>
                        <StructureNavigationComponent
                            config={
                                {
                                    is_display_header: false,
                                    is_display_structures: true,
                                    is_display_area: dropDownForm?.area_vol_no ? false : true,
                                    is_display_sections: dropDownForm?.section_no ? false : true,
                                    is_display_chapters: false,
                                    is_display_subsections: false,
                                    is_display_languages: true,
                                    is_display_chapter_name: true,
                                    is_display_standard_heading: true,
                                }
                            }
                            reloadData={(state) => {
                                setForm(
                                    {
                                        standard_type: state.standard_type,
                                        animal_type: state.animal_type,
                                        area_vol_no: state.volume?.area_vol_no,
                                        area_vol_name: state.volume?.area_vol_name,
                                        section_no: state.section?.section_no,
                                        section_name: state.section?.section_name,
                                        chapter_no: state.chapter?.chapter_no,
                                        chapter_name: state.chapter?.chapter_name,
                                        lang_id: state.language_id,
                                    }
                                )
                            }}
                            model='draft'
                            check_version_year={false}
                        />
                        {
                            (!form.section_no || !form.area_vol_no) && (
                                <CustomCard>
                                    <CardBody>
                                        {

                                            !form.area_vol_no &&
                                            <>
                                                <Row className={"my-2"}>
                                                    <Column col={6}>
                                                        <TextFieldCustom
                                                            variant="outlined"
                                                            id="AreaVolNo"
                                                            label="Volume/Part Number"
                                                            type="number"
                                                            fullWidth
                                                            rows={4}
                                                            required
                                                            sx={{
                                                                backgroundColor: "white",
                                                                color: "white",
                                                                border: "#ff4815",
                                                                width: "99%",
                                                            }}
                                                            size='small'
                                                            value={dropDownForm.area_vol_no}
                                                            onChange={(e) =>
                                                                setDropDownForm({
                                                                    ...dropDownForm,
                                                                    area_vol_no: e.target.value,
                                                                })
                                                            }
                                                        />
                                                    </Column>
                                                    <Column col={6}>
                                                        <TextFieldCustom
                                                            variant="outlined"
                                                            id="AreaVolName"
                                                            label="Volume/Part Name"
                                                            fullWidth
                                                            rows={4}
                                                            required
                                                            sx={{
                                                                backgroundColor: "white",
                                                                color: "white",
                                                                border: "#ff4815",
                                                                width: "99%",
                                                            }}
                                                            size='small'
                                                            value={dropDownForm.area_vol_name}
                                                            onChange={(e) =>
                                                                setDropDownForm({
                                                                    ...dropDownForm,
                                                                    area_vol_name: e.target.value,
                                                                })
                                                            }
                                                        />
                                                    </Column>
                                                </Row>
                                            </>
                                        }
                                        {
                                            !form.section_no &&
                                            <>
                                                <Row>
                                                    <Column col={6}>
                                                        <TextFieldCustom
                                                            variant="outlined"
                                                            id="SectionNo"
                                                            label= {t("211","Section Number")}
                                                            type='number'
                                                            fullWidth
                                                            rows={4}
                                                            required
                                                            sx={{
                                                                backgroundColor: "white",
                                                                color: "white",
                                                                border: "#ff4815",
                                                                width: "99%",
                                                            }}
                                                            size='small'
                                                            value={dropDownForm.section_no}
                                                            onChange={(e) =>
                                                                setDropDownForm({
                                                                    ...dropDownForm,
                                                                    section_no: e.target.value,
                                                                })
                                                            }
                                                        />
                                                    </Column>
                                                    <Column col={6}>
                                                        <TextFieldCustom
                                                            variant="outlined"
                                                            id="SectionName"
                                                            label="Section Name"
                                                            fullWidth
                                                            rows={4}
                                                            required
                                                            sx={{
                                                                backgroundColor: "white",
                                                                color: "white",
                                                                border: "#ff4815",
                                                                width: "99%",
                                                            }}
                                                            size='small'
                                                            value={dropDownForm.section_name}
                                                            onChange={(e) =>
                                                                setDropDownForm({
                                                                    ...dropDownForm,
                                                                    section_name: e.target.value,
                                                                })
                                                            }
                                                        />
                                                    </Column>
                                                </Row>
                                            </>
                                        }
                                    </CardBody>
                                </CustomCard>
                            )
                        }
                        {
                            form.section_no && !dropDownForm.section_no && !dropDownForm.section_name && (
                                <CustomCard>
                                    <CardBody>
                                        <Row className="my-2">
                                            <Column>
                                                <Box sx={{ background: "#ffece8", border: '1px solid #ff4815', paddingY: '10px' }}>
                                                    <span className="p-4 fs-2 text-black">
                                                        Section {form.section_no}
                                                    </span>
                                                    <span className="p-4">
                                                        <i
                                                            className="fa fa-plus cursor-pointer fs-2 rounded-circle text-primary"
                                                            aria-hidden="true"
                                                            onClick={() => {
                                                                setOpenModal(true);
                                                                setChaps(null); // Clear the selected chapter
                                                            }}
                                                        ></i>
                                                    </span>
                                                </Box>
                                            </Column>
                                            {
                                                availableChapters?.map((i, index) => (
                                                    <Row
                                                        key={`chapter-${index}`}
                                                        className='p-2'
                                                    >
                                                        <Column col={8}>
                                                            <div className="d-flex align-items-center">
                                                                <span className="px-4 py-2 fs-4 font-custom">{i.display_name}</span>
                                                            </div>
                                                        </Column>
                                                        <Column col={4} className='text-end'>
                                                            <span className="px-4 py-2">
                                                                <ActionButton
                                                                    variant='success'
                                                                    className='m-1'
                                                                    onClick={() => {
                                                                        setOpenModal(true);
                                                                        const trimmedName = i.display_name.split(' ').slice(2).join(' ');
                                                                        setChaps({ name: trimmedName, refNumber: i.chapter_no, id: i.id, lang_id: i.lang_id, RID: i.RID });
                                                                    }}
                                                                >
                                                                    <i
                                                                        className="fa fa-edit m-1"
                                                                        aria-hidden="true"
                                                                        style={{
                                                                            color: "#ffff"
                                                                        }}
                                                                    ></i> Edit
                                                                </ActionButton>
                                                                <ActionButton
                                                                    variant='danger'
                                                                    className='m-1'
                                                                    onClick={() => handleDelete(i.RID)}
                                                                >
                                                                    {
                                                                        isLoading.deleting.status && isLoading.deleting.rid === i.RID ? (
                                                                            <Loader message='Deleting...' />
                                                                        ) : (
                                                                            <>
                                                                                <i className="fa-solid fa-trash-can m-1" style={{
                                                                                    color: "#ffff"
                                                                                }}></i> Delete
                                                                            </>
                                                                        )
                                                                    }
                                                                </ActionButton>
                                                            </span>
                                                        </Column>
                                                    </Row>
                                                ))
                                            }
                                        </Row>
                                    </CardBody>
                                </CustomCard>
                            )
                        }
                        <ChapterAddModel
                            open={openModal}
                            handleClose={handleCLoseMoal}
                            reloadData={handleAddVolSection}
                            selectedChapterName={chaps}
                            handleChapterEdit={handleChapterEdit}
                        />
                    </>
                </Modal.Body>
                <Modal.Footer className='p-4 d-flex justify-content-end'>
                    <button
                        className='btn btn--primary'
                        onClick={() => { handleClose() }}
                    >
                        {t("-1", "Close")}
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

StandardStructureModal.defaultProps = {
    children: () => { },
    draftID: null,
    reloadData: () => { },
}

export default StandardStructureModal;