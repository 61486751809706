import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { setCurrentPage, setHeaderTheme } from '../../../../redux/generalSlice';
import { useTranslation } from "react-i18next";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import NavigateStandards from '../sub-navigation/NavigateStandards';
import SearchCommodities from '../sub-navigation/SearchCommodities';
import SearchStandards from '../sub-navigation/_SearchStandards';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const CustomTabs = () => {

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    // useEffect(() => {
    //     dispatch(setHeaderTheme('white'));
    //     dispatch(setCurrentPage("form"));
    // }, []);

    return (
        <>
            <div className="container py-3">
                {/* <div className="header card-woah border border-secondary px-4 py-2 shadow-sm w-100 my-2"> */}
                {/* <div className='d-flex justify-content-between w-100'>
                        <h1 className="card-title1 font-custom-2 lh-1">{t("2877", "Contact Us")}</h1>
                    </div> */}
                <div className="row justify-content-center">
                    <div className="col-md-6">
                        <Box sx={{ width: '100%' }}>
                            <Box sx={{ borderBottom: 0, borderColor: 'divider', }}
                                className="border-bottom-1">
                                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" centered>
                                    <Tab className='fs-7 text-dark' label={t("-1", "Latest Edition")} {...a11yProps(0)} />
                                    <Tab className='fs-7 text-dark' label={t("-1", "Recommended Measures for Trade by Commodity")} {...a11yProps(1)} />
                                    {/* <Tab className='fs-7 text-dark' label={t("-1", "Search Commodities")} {...a11yProps(2)} /> */}
                                </Tabs>
                            </Box>

                        </Box>
                    </div>
                </div>
                {/* </div> */}
            </div>
            <CustomTabPanel value={value} index={0}>
                <NavigateStandards />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
                <h1 className='text-center card-title1 font-custom-2 lh-1'>{t("-1", "Access Recommended Measures for Trade by Commodity")}</h1>
                <SearchStandards />
            </CustomTabPanel>
            
        </>
    )
}

export default CustomTabs;
