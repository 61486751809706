import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import PublishingHistory from 'pages/frontend/pages-component/PublishingHistory';
import { setCurrentPage, setHeaderTheme } from 'redux/generalSlice';
import EditingHistory from 'pages/frontend/pages-component/EditingHistory';
import CodeTabs from 'pages/frontend/pages-component/CodeTabs';
import ManualTab from 'pages/frontend/pages-component/ManualTab';

const MainHomePage = () => {

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const authData = useSelector(s => s.auth.data);
  const [currentTab, setCurrentTab] = useState('codes');

  useEffect(() => {
    dispatch(setHeaderTheme('white'));
    dispatch(setCurrentPage("home"));
  }, []);

  return (
    <>
      <div className="container my-2">
        <div className="header card-woah border border-secondary d-flex px-4 py-2 shadow-sm w-100">
          <div className='d-flex justify-content-between'>
            <div className="card-title1  lh-1">{t("63", "Welcome")} {authData.name}!</div>
          </div>
          <div className="header-right-items ms-auto d-flex align-items-center">
            <div className="ms-auto">
              <button
                className="btn btn--primary font-custom fs-6 ms-1"
                onClick={() => setCurrentTab('codes')}
              >
                {t("64", "Codes")}
              </button>
              <button
                className="btn btn--outline font-custom fs-6 ms-1"
                onClick={() => setCurrentTab('manuals')}
              >
                {t("65", "Manuals")}
              </button>
            </div>
          </div>
        </div>
        <div className=" my-2">
          <div className="row g-0 d-flex justify-content-between">
            <div className="col-md-5" style={{ backgroundColor: '#f0f0f0' }}>
              {
                currentTab === 'codes' ? (
                  <CodeTabs />
                ) : (
                  <ManualTab />
                )
              }
            </div>
            <div className="col-md-6" style={{ backgroundColor: '#f0f0f0' }}>
              {
                [1, 2].includes(authData.role_id) ? (
                  <PublishingHistory />
                ) : (
                  <EditingHistory />
                )
              }
            </div>
          </div>
        </div>
        <div>
        </div>
      </div>
    </>
  )
}

export default MainHomePage
