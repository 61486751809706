import HttpApi from 'i18next-http-backend';
import { getAPIURL } from './helpers';
import { store } from './redux/store';
import { LIST_DETAIL_ELEMENTS } from './constants';
const i18n = require("i18next");
const { initReactI18next } = require("react-i18next");

i18n.use(initReactI18next)
    .use(HttpApi)
    .init({
        lng: store.getState().general.siteLanguageSont,
        fallbackLng: `${LIST_DETAIL_ELEMENTS.languages.english}`,
        ns: ['133', '134'],
        defaultNS: '133',
        //debug: true,
        supportedLngs: [
            `${LIST_DETAIL_ELEMENTS.languages.english}`,
            `${LIST_DETAIL_ELEMENTS.languages.french}`,
            `${LIST_DETAIL_ELEMENTS.languages.spanish}`
        ],
        interpolation: {
            escapeValue: false // react already safes from xss
        },
        backend: {
            loadPath: getAPIURL('/api/multilingual/{{lng}}/133/all'),
            crossDomain: true,
        },
        react: {
            useSuspense: false
        }
    });

export default i18n;

