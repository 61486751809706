import React from 'react';

const SiteLoader = ({ position, background, size, alignment }) => {

    const getAlignmentClass = () => {
        if (alignment == 'left') {
            return 'me-auto my-auto';
        } else if (alignment == 'right') {
            return 'ms-auto my-auto';
        } else {
            return 'm-auto';
        }
    }

    return (
        <div 
            className={`position-${position} bg-${background} d-flex`} 
            style={{ zIndex: '10000', top: 0, left: 0, right: 0, bottom: 0 }}
        >
            <i className={`fas fa-circle-notch fa-spin fs-${size}x text-primary ${getAlignmentClass()}`} />
        </div>
    );
}

SiteLoader.defaultProps = {
    position: 'fixed', // fixed | absolute | relative
    background: 'white',
    alignment: 'center',
    size: '4',
};

export default SiteLoader;
