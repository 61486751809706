import CustomChip from 'components/CustomChip';
import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@mui/material';
import { displayNotification, getBaseURL, getLanguageAgainstIDs, getStandardAgainstIDs, sendAPIRequest, } from 'helpers';
import UploadChapter from 'components/modals/UploadChapterModal';
import DataTable from 'pages/components/DataTable';
import { Typography } from '@mui/material';
import { GridActionsCellItem } from '@mui/x-data-grid';
import { useLocation, useNavigate } from 'react-router-dom';
import { LIST_DETAIL_ELEMENTS } from 'constants';
import DocumentTransferModal from 'components/modals/DocumentTransferModal';
import { TRANSFER_STATUS } from 'constants';
import AddChapterModalNew from 'components/modals/AddChapterModalNew';
import MoveChapterModal from 'components/modals/MoveChapterModal';
import { USER_PERMISSIONS } from 'constants';
import { useSelector } from 'react-redux';
import AddNBModal from 'components/modals/AddNBModal';

const SectionChapterCordianComponent = ({ data = {}, loadData = async () => { }, standardDetails = {}, standard_type, area_vol_no = '', componentLanguage = '102' }) => {
    const userAuth = useSelector((s) => s.auth);
    const location = useLocation();
    const { t } = useTranslation();
    const [openDropdownId, setOpenDropdownId] = useState(null);
    const dropdownRef = useRef(null);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [openUploadModal, setOpenUploadModal] = useState(false);
    const [moveChapterModal, setMoveChapterModal] = useState(false);
    const [metaData, setMetaData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [transferDocumentDetails, setTraferDocumentDetails] = useState({});
    const [NBModal, setNBModal] = useState({
        id: "",
        open: false
    });
    const closeDropdown = () => {
        setOpenDropdownId(null);
        setDropdownOpen(false);
    };
    const navigate = useNavigate();
    const [transferModal, setTransferModal] = useState(false);
    const [AddChapterModal, setAddChapterModal] = useState(false);
    const [AddChapterData, setAddChapterData] = useState([]);
    const [editChapterModal, setEditChapterModal] = useState(false);
    const [formData, setFormData] = useState({ chapter_name: '', chapter_no: '' });
    const [EditChapterId, setEditChapterId] = useState('');
    const [moveChapterData, setMoveChapterData] = useState([]);
    const [access, setAccess] = useState();

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setDropdownOpen(false);
            setOpenDropdownId(null);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const previewChapter = (data = {}) => {
        const url = getBaseURL(`/portal/draft-chapter-preview/?chapter_id=${data.structures_id}&is_draft=true`);
        window.open(url, "_blank");
        closeDropdown()
    };

    const handleCloseModal = () => {
        setOpenUploadModal(false);
    }

    const handleUploadModal = (metaData) => {
        setMetaData(metaData);
        setOpenUploadModal(true);
        closeDropdown();
    }
    const handleMoveChap = (data) => {
        setMoveChapterData(data);
        setMoveChapterModal(true);
    }

    const handleCloseMoveChap = async () => {
        setMoveChapterModal(false);
        setMoveChapterData([]);
        await loadData(standardDetails?.standard_type ?? 5, standardDetails?.animal_type ?? 7);
    }

    const handleSave = async (formData) => {
        try {
            const ret = await sendAPIRequest(`/api/draft-chapter/add-new-Chapter`, 'POST', {
                "area_vol_no": AddChapterData?.area_vol_no,
                "section_no": AddChapterData?.section_no,
                "lang_id": AddChapterData?.lang_id,
                "animal_type": AddChapterData?.animal_type,
                "standard_type": AddChapterData?.standard_type,
                "chapter_no": formData?.chapter_no,
                "area_vol_name": AddChapterData?.area_vol_name,
                "section_name": AddChapterData?.section_name,
                "chapter_name": formData?.chapter_name
            });
            const res = await ret.json()
            if (res.success && res.status === 200) {
                await loadData(standardDetails?.standard_type ?? 5, standardDetails?.animal_type ?? 7);
                setEditChapterModal(false);
                displayNotification("success", "Chapter Added Successfully");
            } else {
                displayNotification("error", "Failed to Add Chapter");
            }
        } catch (e) {
            console.log({ e });
        }
    }
    const handleClose = async () => {
        setAddChapterModal(false);
        setEditChapterModal(false);
        setFormData({ chapter_name: '', chapter_no: '' })
    }

    const handleReview = async (params) => {
        closeDropdown();
        if (!window.confirm("Are you sure you want to revert")) {
            return;
        }
        try {
            const ret = await sendAPIRequest(`/api/handle-drafting/revert-chapter/${params.RID}/${params.lang_id}`)
            const res = await ret.json()
            if (res.success && res.status === 200) {
                await loadData(standardDetails?.standard_type ?? 5, standardDetails?.animal_type ?? 7);
                displayNotification("success", "Successfully Revert ");
            } else {
                displayNotification("error", "Failed to revert chapter");
            }
        } catch (e) {
            console.log({ e });
        }
    }

    const handleDeleteChapter = async (x) => {
        closeDropdown();
        if (!window.confirm("Are you sure you want to delete this chapter?")) {
            return;
        }
        try {
            const ret = await sendAPIRequest(`/api/draft-chapter/delete-Chapter`, 'DELETE', {
                "area_vol_no": x?.area_vol_no,
                "section_no": x?.section_no,
                "lang_id": x?.lang_id,
                "animal_type": x?.animal_type,
                "standard_type": x?.standard_type,
                "chapter_no": x?.chapter_no,
                "area_vol_name": x?.area_vol_name,
                "section_name": x?.section_name,
                "chapter_name": x?.chapter_name,
                "id": x?.id,
            });
            const res = await ret.json()
            if (res.success && res.status === 200) {
                await loadData(standardDetails?.standard_type ?? 5, standardDetails?.animal_type ?? 7);
                displayNotification("success", "Successfully Deleted");
            } else {
                displayNotification("error", "Failed to delete");
            }
        } catch (e) {
            console.log({ e });
        }
    }

    const editChapterCall = async () => {
        try {
            const ret = await sendAPIRequest(`/api/draft-chapter/rename-chapter-name`, 'POST', {
                "id": EditChapterId,
                "chapter_name": formData?.chapter_name
            });
            const res = await ret.json()
            if (res.success && res.status === 200) {
                await loadData(standardDetails?.standard_type ?? 5, standardDetails?.animal_type ?? 7);
                setEditChapterModal(false);
                displayNotification("success", "Chapter Name Edited Successfully");
                setEditChapterId('');
            } else {
                displayNotification("error", "Failed to Edit Chapter");
            }
        } catch (e) {
            console.log({ e });
        }
    }

    const handleAddNB = async (txt = '', structures_id = '') => {
        if (!window.confirm("Are you sure you want to Add NB")) {
            return;
        };
        try {
            const ret = await sendAPIRequest(`/api/draft-content/add-nb/${structures_id}`, "POST", {
                txt
            })
            const res = await ret.json()
            if (res.success && res.status === 200) {
                displayNotification("success", "Successfully Added");
            } else {
                displayNotification("error", "Failed to add NB");
            }
        } catch (e) {
            console.log({ e });
            displayNotification("error", "Failed to add NB[F]");
        }
    }

    const hanldeEditChapter = async (params) => {
        setFormData({ chapter_name: params?.chapter_name, chapter_no: '' })
        setAddChapterModal(true);
        setEditChapterModal(true);

    }
    const handleCloseTransferModal = async (load_data = false) => {
        setTransferModal(false);
        setTraferDocumentDetails({});
        if (load_data) {
            await loadData(standardDetails?.standard_type ?? 5, standardDetails?.animal_type ?? 7);
        }
    }

    const handleTranserferDocument = (draft_id, animal_type, standard_type, lang_id, type = '') => {
        const standard = getStandardAgainstIDs(standard_type, animal_type);
        const lang_code = getLanguageAgainstIDs(lang_id, true);
        setTraferDocumentDetails({
            draft_id,
            standard,
            lang_code,
            type
        })
        setTransferModal(true)
    }

    const getStatusChip = (status) => {
        switch (status) {
            case 4:
                return <CustomChip label="Published" type="primary" />;
            case 3:
                return <CustomChip label="Validated" type="orange" />;
            case 2:
                return <CustomChip label="Pending Validation" type="blue" />;
            default:
                return <CustomChip label="Draft" type="green" />;
        }
    };

    const handleReturnStatus = (arr = []) => {
        let status = arr.find((i) => i.transfer_status === TRANSFER_STATUS.publishing);
        if (status) {
            return 3;
        }
        status = arr.find((i) => i.transfer_status === TRANSFER_STATUS.validation);
        if (status) {
            return 2;
        } else {
            return 1;
        }
    }

    const handleReturnUser = (arr = []) => {
        let status = arr.find((i) => i.transfer_status === TRANSFER_STATUS.publishing && i.permissions === USER_PERMISSIONS.publisher);
        if (status) {
            return status.receiver.user_name;
        }
        status = arr.find((i) => i.transfer_status === TRANSFER_STATUS.validation && i.permissions === USER_PERMISSIONS.validator);
        if (status) {
            return status.receiver.user_name;
        }
        status = arr.find((i) => i.transfer_status === TRANSFER_STATUS.editing);
        if (status) {
            return status?.receiver?.user_name ? status?.receiver?.user_name : status?.sender?.user_name ?? '';
        }
        else {
            return '';
        }
    }

    const handleMofifyEditChapter = (data = {}) => {
        const url = `/portal/draft/edit/${data.structures_id}/?is_code=${data.standard_type === LIST_DETAIL_ELEMENTS.standard_type.codes ? true : false}&is_modify=true`;
        navigate(url);
    };

    const toTitleCase = (str) => {
        return str
            .toLowerCase()
            .replace(/\b(\w)/g, (char) => char.toUpperCase());
    };

    const handleNavigateForChapterTaging = (structure = {}) => {
        navigate(`/portal/draft/taging/chapter/create/?chapter_id=${structure.structures_id}&is_modify=true&version_year=${structure.version_year ?? ""}`);
    }

    const authData = useSelector(s => s.auth?.data);


    const getAccess = (publisher, author, validator) => {
        if (publisher === true && author === false && validator === false) {
            return "No access";
        }
        if (validator === true && author === false && publisher === false) {
            return "Modify preview and transfer access";
        }
        if (author === true) {
            return "All access";
        }
        return "All access";
    }

    useEffect(() => {
        const accessLevel = getAccess(authData?.is_publisher, authData?.is_author, authData?.is_validator);
        setAccess(accessLevel);
    }, [])


    const getData = (data) => {
        let count = 0;
        data.forEach(item => {
            if (Array.isArray(item.relationDocTransferDraftStructures) && item.relationDocTransferDraftStructures.length > 0) {
                count += 1;
            }
        })
        return count;
    }

    const permissionsForActions = (transfer = []) => {
        try {
            if (transfer.length === 0) {
                return true;
            } else {
                if (transfer.length === 1) {
                    if (transfer[0].sender && [null, undefined].includes(transfer[0].receiver)) {
                        if (transfer[0].sender_id === userAuth.data.id) {
                            return true;
                        }
                    }
                    if (transfer[0].sender && transfer[0].receiver) {
                        if (transfer[0].receiver_id === userAuth.data.id) {
                            return true;
                        }
                    }
                }
                if (transfer.length > 1) {
                    const lastItem = transfer[transfer.length - 1];
                    if (lastItem.receiver_id === userAuth.data.id) {
                        return true;
                    }
                }
            }
            return false;
        } catch (error) {
            console.error({ error });
        }
    }

    const handleCloseNBModal = () => {
        setNBModal({
            id: "",
            open: false
        });
    }

    return (
        <div>
            <div className="accordion mt-4 border-0" id={"accordionExample" + data.id}>
                <div className="accordion-item rounded-0">
                    <h1 className="accordion-header accordion__item-label collapsed" id={`heading-${data.id}`}>
                        <button
                            className="accordion-button font-custom-4 fs-4 rounded-0 py-2 collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#collapse-${data.id}`}
                            aria-expanded="false"
                            aria-controls={`collapse-${data.id}`}
                        >
                            <span className="me-auto fs-4 fw-bold capitalize-text">
                                {
                                    standardDetails.standard_type === LIST_DETAIL_ELEMENTS.standard_type.codes
                                        ? `Section: ${data.section_no}${data.section_no !== "0" ? `. ${toTitleCase(data.section_name)}   ` : ''}`
                                        : `Section: ${area_vol_no}.${data.section_no}${data.section_no !== "0" ? `. ${toTitleCase(data.section_name)}` : ''}`
                                }
                                {/* {getData(data.chapters) > 0 && <span className="text-primary ms-2">({getData(data.chapters)} Draft)</span>
                                } */}
                                <span className='ms-2'>
                                    <Tooltip title="Add New Chapter" placement="top">
                                        <i className="fa-solid fs-3 fa-plus text-primary" onClick={() => {
                                            setAddChapterModal(true);
                                            setAddChapterData(data.chapters[0]);
                                        }}>
                                        </i>
                                    </Tooltip>
                                </span>
                            </span>
                        </button>
                    </h1>
                    <div
                        id={`collapse-${data.id}`}
                        className="accordion-collapse collapse"
                        aria-labelledby={`heading-${data.id}`}
                        data-bs-parent={`#accordionExample${data.id}`}
                    >
                        <DataTable
                            getRowId={(row) => row.structures_id}
                            isLoading={isLoading}
                            pagination={false}
                            rows={data.chapters}
                            className="text-white bg-primary rounded-0"
                            columns={[
                                {
                                    field: "display_name",
                                    headerName: t("-1", "Chapter"),
                                    headerClassName: "text-white bg-primary rounded-0",
                                    renderCell: (params) => {
                                        const chapterName = params.value;
                                        const isDeleted = params.row.deletedAt !== null;

                                        return (
                                            <Typography
                                                sx={{ textDecoration: isDeleted ? 'line-through' : 'none', color: isDeleted ? 'gray' : 'inherit' }}
                                                color="inherit"
                                            >
                                                {standard_type === LIST_DETAIL_ELEMENTS.standard_type.manuals
                                                    ? `${area_vol_no}.${chapterName}`
                                                    : standard_type === LIST_DETAIL_ELEMENTS.standard_type.codes
                                                        ? `${chapterName}`
                                                        : null}
                                            </Typography>
                                        );
                                    },
                                    flex: 3.5,
                                },
                                {
                                    field: "User",
                                    headerName: t("-1", "User"),
                                    headerClassName: "text-white bg-primary rounded-0",
                                    flex: 0.7,
                                    renderCell: (params) => {
                                        const userName = params.value;
                                        const isDeleted = params.row.deletedAt !== null;

                                        return (
                                            <Typography
                                                sx={{ textDecoration: isDeleted ? 'line-through' : 'none', color: isDeleted ? 'gray' : 'inherit' }}
                                                color="inherit"
                                            >
                                                {params?.row?.relationDocTransferDraftStructures.length > 0 ? handleReturnUser(params?.row?.relationDocTransferDraftStructures) : userName}
                                            </Typography>
                                        )
                                    }
                                },
                                {
                                    field: "status",
                                    headerName: t("-1", "Status"),
                                    headerClassName: "text-white bg-primary rounded-0",
                                    flex: 1,
                                    renderCell: (params) => {
                                        const status = params.value;
                                        const isDeleted = params.row.deletedAt !== null;
                                        return (
                                            <Typography
                                                sx={{ textDecoration: isDeleted ? 'line-through' : 'none', color: isDeleted ? 'gray' : 'inherit' }}
                                                color="inherit"
                                            >
                                                {params?.row?.relationDocTransferDraftStructures.length ? getStatusChip(handleReturnStatus(params?.row?.relationDocTransferDraftStructures ?? [])) : ""}
                                            </Typography>
                                        )
                                    },
                                },
                                {
                                    field: "actions",
                                    type: "actions",
                                    headerClassName: "text-white bg-primary rounded-0",
                                    headerName: t("-1", "Actions"),
                                    getActions: (params) => {
                                        const isDeleted = params.row.deletedAt !== null;
                                        const transferStatus = params.row?.relationDocTransferDraftStructures.length > 0 ? params.row?.relationDocTransferDraftStructures[0]?.transfer_status : null;

                                        if (isDeleted) {
                                            return [];
                                        }
                                        if (transferStatus === TRANSFER_STATUS.publishing) {
                                            return [
                                                <GridActionsCellItem
                                                    label="Preview"
                                                    key={5}
                                                    showInMenu
                                                    onClick={() => { previewChapter(params.row); }}
                                                />,
                                            ]
                                        }
                                        else {
                                            return [
                                                ...(access == 'All access' ? [
                                                    < GridActionsCellItem
                                                        label="Upload & Replace Chapter"
                                                        key={0}
                                                        showInMenu
                                                        onClick={() => handleUploadModal(params.row)}
                                                        disabled={(standardDetails?.structureStatus === 4 || standardDetails?.structureStatus === 5 || !permissionsForActions(params.row.relationDocTransferDraftStructures))}
                                                    />,
                                                    <GridActionsCellItem
                                                        label="Modify Chapter"
                                                        key={1}
                                                        showInMenu
                                                        onClick={() => handleMofifyEditChapter(params.row)}
                                                        disabled={(standardDetails?.structureStatus === 4 || standardDetails?.structureStatus === 5 || !permissionsForActions(params.row.relationDocTransferDraftStructures))}
                                                    />,
                                                    <GridActionsCellItem
                                                        label="Rename Chapter"
                                                        key={2}
                                                        showInMenu
                                                        onClick={() => {
                                                            hanldeEditChapter(params.row);
                                                            setEditChapterId(params.row.id);
                                                        }}
                                                        disabled={(standardDetails?.structureStatus === 4 || standardDetails?.structureStatus === 5 || !permissionsForActions(params.row.relationDocTransferDraftStructures))}
                                                    />,
                                                    <GridActionsCellItem
                                                        label={t("-1", "Move Chapter")}
                                                        key={3}
                                                        showInMenu
                                                        onClick={() => handleMoveChap(params.row)}
                                                        disabled={(standardDetails?.structureStatus === 4 || standardDetails?.structureStatus === 5 || !permissionsForActions(params.row.relationDocTransferDraftStructures))}
                                                    />,
                                                    <GridActionsCellItem
                                                        label="Preview"
                                                        key={5}
                                                        showInMenu
                                                        onClick={() => { previewChapter(params.row); }}
                                                    />,
                                                    <GridActionsCellItem
                                                        label="Chapter Tagging"
                                                        key={5}
                                                        showInMenu
                                                        onClick={() => handleNavigateForChapterTaging(params.row)}
                                                        disabled={(standardDetails?.structureStatus === 4 || standardDetails?.structureStatus === 5 || !permissionsForActions(params.row.relationDocTransferDraftStructures))}
                                                    />,
                                                    <GridActionsCellItem
                                                        label="Add NB"
                                                        key={5}
                                                        showInMenu
                                                        onClick={() => setNBModal({
                                                            open: true,
                                                            id: params.row.structures_id
                                                        })}
                                                        disabled={(standardDetails?.structureStatus === 4 || standardDetails?.structureStatus === 5 || !permissionsForActions(params.row.relationDocTransferDraftStructures))}
                                                    />,
                                                    <GridActionsCellItem
                                                        label="Transfer"
                                                        key={6}
                                                        showInMenu
                                                        onClick={() => handleTranserferDocument(params.row?.structures_id, params.row?.animal_type, params.row?.standard_type, params.row?.lang_id, 1)}
                                                        disabled={(standardDetails?.structureStatus === 4 || standardDetails?.structureStatus === 5 || !permissionsForActions(params.row.relationDocTransferDraftStructures))}
                                                    />,
                                                    <GridActionsCellItem
                                                        label="Delete Chapter"
                                                        key={7}
                                                        showInMenu
                                                        onClick={() => handleDeleteChapter(params.row)}
                                                        disabled={(standardDetails?.structureStatus === 4 || standardDetails?.structureStatus === 5 || !permissionsForActions(params.row.relationDocTransferDraftStructures))}
                                                    />,
                                                    <GridActionsCellItem
                                                        label="Revert to Original"
                                                        key={7}
                                                        showInMenu
                                                        onClick={() => handleReview(params.row)}
                                                        disabled={standardDetails?.structureStatus == 4 || standardDetails?.structureStatus == 5 || !permissionsForActions(params.row.relationDocTransferDraftStructures)}
                                                    />,
                                                ] : []),
                                                ...(access === "Modify preview and transfer access" ? [
                                                    <GridActionsCellItem
                                                        label="Modify Chapter"
                                                        key={1}
                                                        showInMenu
                                                        onClick={() => handleMofifyEditChapter(params.row)}
                                                        disabled={(standardDetails?.structureStatus === 4 || standardDetails?.structureStatus === 5 || !permissionsForActions(params.row.relationDocTransferDraftStructures))}
                                                    />,
                                                    <GridActionsCellItem
                                                        label="Preview"
                                                        key={5}
                                                        showInMenu
                                                        onClick={() => { previewChapter(params.row); }}
                                                    />,
                                                    <GridActionsCellItem
                                                        label="Transfer"
                                                        key={6}
                                                        showInMenu
                                                        onClick={() => handleTranserferDocument(params.row?.structures_id, params.row?.animal_type, params.row?.standard_type, params.row?.lang_id, 1)}
                                                        disabled={(standardDetails?.structureStatus === 4 || standardDetails?.structureStatus === 5 || !permissionsForActions(params.row.relationDocTransferDraftStructures))}
                                                    />,
                                                ] : [])
                                            ];
                                        }
                                    },
                                }
                            ]}
                            disableColumnMenu={true}
                        />
                    </div>
                    <UploadChapter
                        open={openUploadModal}
                        handleCloseModal={handleCloseModal}
                        metaData={metaData}
                    />

                    {moveChapterModal && <MoveChapterModal
                        open={moveChapterModal}
                        handleClose={handleCloseMoveChap}
                        moveChapterData={moveChapterData}
                        standardDetails={standardDetails}
                        componentLanguage={componentLanguage}
                    />}

                    <DocumentTransferModal
                        open={transferModal}
                        handleClose={handleCloseTransferModal}
                        documentDetails={transferDocumentDetails}
                        loadData={handleCloseTransferModal}
                        is_modify={true}
                    />

                    <AddChapterModalNew
                        open={AddChapterModal}
                        handleSave={handleSave}
                        handleClose={handleClose}
                        edit={editChapterModal}
                        hanldeEditChapter={hanldeEditChapter}
                        formData={formData}
                        setFormData={setFormData}
                        editChapterCall={editChapterCall}
                    />
                    <AddNBModal
                        open={NBModal}
                        handleClose={handleCloseNBModal}
                        handleSaveNB={handleAddNB}
                    />
                </div>
            </div>
        </div>
    );
};

export default SectionChapterCordianComponent;
