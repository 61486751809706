import { getBaseURL } from 'helpers'
import { useTranslation } from "react-i18next";
import { useDispatch } from 'react-redux';
import React, { useEffect } from 'react'
import { setCurrentPage, setHeaderTheme } from 'redux/generalSlice';

function AboutPage() {

    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        dispatch(setCurrentPage('about'));
        dispatch(setHeaderTheme('white'));
    }, []);

    return (
        <>
            <div className="container my-2">
                <div className=" mb-5 mt-4 border-bottom border-2 border-dark">
                    <div className="row">
                        <div className="col-md-5 py-5 pe-8">
                            <div
                                style={{
                                    backgroundImage: `url(${getBaseURL('/assets/icons/forgot-password.jpg')})`,
                                    backgroundSize: "cover",
                                    backgroundRepeat: "no-repeat",
                                    minHeight: "340px"
                                }}
                                alt="doc"
                                className="h-100 w-100"
                            >
                            </div>
                        </div>
                        <div className="col-md-7 border-start border-2 border-dark">
                            <div className="caption ps-7">
                                <h1 className=" font-custom-2 fs-b border-bottom border-2 border-dark pb-5">
                                    {t("-1", "About SONT")}
                                </h1>
                                <div className="paragraph pt-5">
                                    <p className='fs-5 text-justify'>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                        Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                        Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                                        Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.                                    </p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AboutPage
