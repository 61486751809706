import { useMsal } from '@azure/msal-react';
import { Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Popover, Tooltip } from '@mui/material';
import { getBaseURL } from 'helpers';
import React, { Fragment, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { resetAuthState } from 'redux/authSlice';
import { BsBook, BsChatSquareText, BsGear, BsPower, BsQuestionSquare } from "react-icons/bs";
import Notifications from './Notifications';
import { changeSiteLanguage } from 'index';
import ConfirmModal from 'components/modals/ConfirmModal';
import { LIST_DETAIL_ELEMENTS } from "constants";

const NavbarUserIcon = ({ className }) => {

    const { instance } = useMsal();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const authData = useSelector(s => s.auth.data);
    const siteLanguage = useSelector(s => s.general.siteLanguageSont);
    const imageRef = useRef();
    const confirmModelRef = useRef();

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        event.preventDefault();
        setAnchorEl(imageRef.current);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    // const handleLogout = () => {
    //     if (authData.user_type > 0) {
    //         dispatch(resetAuthState());
    //         instance.logoutRedirect({
    //             onRedirectNavigate: (url) => {
    //                 return true;
    //             },
    //         });
    //     } else {
    //         dispatch(resetAuthState());
    //         navigate("/login");
    //     }
    // };

    const handleLogout = () => {
        dispatch(resetAuthState());
        localStorage.clear();
        sessionStorage.clear();
        if (authData?.user_type > 0) {
            instance.logoutRedirect({
                onRedirectNavigate: (url) => {
                    return false;
                },
            });
        }
        window.location.href = "/login";
    };

    const open = Boolean(anchorEl);
    const id = open ? 'notification-popover' : undefined;
    const options = [
        {
            key: '0',
            label: t("-1", "Profile"),
            icon: <BsGear className="align-top text-dark" />,
            onClick: () => navigate('/portal/user-profile'),
        },
        // {
        //     key: '1',
        //     label: 'FAQs',
        //     icon: <BsBook className="align-top text-dark" />,
        //     onClick: () => navigate('/portal/faqs'),
        // },
        // {
        //     key: '2',
        //     label: t("2875", "Help"),
        //     icon: <BsQuestionSquare className="align-top text-dark" />,
        //     onClick: () => navigate('/portal/help'),
        // },
        {
            key: '3',
            label: t("-1", "Contact Us"),
            icon: <BsChatSquareText className="align-top text-dark" />,
            onClick: () => navigate('/portal/contact-us'),
        },
        {
            key: '4',
            label: t("-1", "Logout"),
            icon: <BsPower className="align-top text-dark" />,
            onClick: () => {
                handleClose();
                return confirmModelRef.current.handleOpen('', 'Confirm', '', '', handleLogout)
            },
        },
    ];

    return (
        <>
            <ConfirmModal
                ref={confirmModelRef}
            />
            <div className="align-content-center">
                <a
                    href="#"
                    className="font-custom text-dark align-self-end lead dropdown-toggle"
                    onClick={handleClick}
                >
                    {authData?.name ? authData?.name : authData?.user_name ? authData?.user_name : "-"}
                </a>
                {/* <div>
                    <span className="h-15px align-middle">
                        <Notifications className='align-middle' />
                    </span>
                    <span className="mx-3 align-middle">|</span>
                    <span
                        className={`font-family-default cursor-pointer align-middle ${siteLanguage == `${LIST_DETAIL_ELEMENTS.languages.english}` ? "text-decoration-underline" : ""}`}
                        onClick={() => changeSiteLanguage(`${LIST_DETAIL_ELEMENTS.languages.english}`)}
                    >
                        EN
                    </span>
                    <span className="mx-2 align-middle">|</span>
                    <span
                        className={`font-family-default mx-2 cursor-pointer align-middle ${siteLanguage == `${LIST_DETAIL_ELEMENTS.languages.french}` ? "text-decoration-underline" : ""}`}
                        onClick={() => changeSiteLanguage(`${LIST_DETAIL_ELEMENTS.languages.french}`)}
                    >
                        FR
                    </span>
                    <span className="mx-2 align-middle">|</span>
                    <span
                        className={`font-family-default cursor-pointer align-middle ${siteLanguage == `${LIST_DETAIL_ELEMENTS.languages.spanish}` ? "text-decoration-underline" : ""}`}
                        onClick={() => changeSiteLanguage(`${LIST_DETAIL_ELEMENTS.languages.spanish}`)}
                    >
                        ES
                    </span>
                    <span className="ms-3 align-middle">|</span>
                </div> */}
            </div>
            <div className="text-center px-2">
                <Tooltip title={authData?.name ?? "-"}>
                    <a
                        href="#"
                        className={`me-2 d-block ${className}`}
                        onClick={handleClick}
                    >
                        <div className="border border-pvs border-1 cursor-pointer symbol symbol symbol-circle symbol-50px">
                            <img
                                className="rounded-circle h-75"
                                src={getBaseURL(`/assets/icons/avatar.png`)}
                                alt="user"
                                ref={imageRef}
                            />
                        </div>
                    </a>
                </Tooltip>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    sx={{
                        '& .MuiPopover-paper': {
                            width: 150,
                            marginTop: 1
                        }
                    }}
                >
                    <List
                        sx={{
                            width: '100%',
                            maxWidth: 150,
                            bgcolor: 'var(--theme-light)',
                            position: 'relative',
                            overflow: 'auto',
                            paddingBottom: 0,
                            '& .MuiDivider-inset': { margin: 0 },
                            '& .MuiListItem-root': { paddingX: 0 },
                            '& .MuiListItemText-root': { margin: 0 },
                            '& .MuiTypography-root': { margin: 0 },
                            '& .MuiListItemButton-root': {
                                paddingLeft: '15px',
                                paddingRight: '15px',
                            },
                            '& .MuiListItemIcon-root': {
                                minWidth: 0,
                                marginRight: '15px',
                            },
                            '& .MuiSvgIcon-root': {
                                fontSize: 20,
                            },
                        }}
                        subheader={<li style={{ listStyle: 'none', margin: '0' }} />}
                    >
                        {
                            options.length > 0 && (
                                options.map(r => (
                                    <Fragment key={r.key}>
                                        <ListItemButton
                                            sx={{ p: 0, width: '100%' }}
                                            selected={false}
                                            onClick={r.onClick}
                                        >
                                            <ListItemIcon>
                                                {r.icon}
                                            </ListItemIcon>
                                            <ListItem className='d-block'>
                                                <ListItemText primary={r.label} />
                                            </ListItem>
                                        </ListItemButton>
                                        <Divider variant="inset" component="li" />
                                    </Fragment>
                                ))
                            )
                        }
                    </List>
                </Popover>
            </div>
        </>
    );
}

NavbarUserIcon.defaultProps = {
    className: ''
};

export default NavbarUserIcon;
