import React, { useEffect, useState } from 'react'
import { displayNotification, sendAPIRequest } from 'helpers';
import { useSearchParams } from 'react-router-dom';
import { LIST_DETAIL_ELEMENTS } from 'constants';
import HtmlRenderer from 'components/HtmlRenderer';
import Container from 'components/common/Container';
import CustomCard from 'components/common/CustomCard';
import CardHeader from 'components/common/CardHeader';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'components/text_editor/CustomCKEditor';
import Spinner from 'components/spinner/Spinner';
import Row from 'components/common/Row';
import Column from 'components/common/Column';
import { MANUAL_HEADINGS } from 'constants';
import Button from 'components/common/Button';
import ReactDOMServer from 'react-dom/server';
import { SIDEBAR_HEADINGS } from 'constants';
import SideBarComponent from './SideBarComponent';
import CardBody from 'components/common/CardBody';

const ModifyExistingChapter = () => {
    const [queryParams] = useSearchParams();
    const { structure_id, is_code } = {
        structure_id: queryParams.get('structure_id'),
        is_code: queryParams.get('is_code') === "true" ? true : false,
    }
    const [sideBarData, setSideBarData] = useState([]);
    const [data, setData] = useState('');
    const [dataToShow, setDataToShow] = useState('');
    const [editorData, setEditorData] = useState('');
    const [currentSelected, setCurrentSelected] = useState('');
    const [currentArticle, setCurrentArticle] = useState('');
    const [chapterInfo, setChapterInfo] = useState({});

    const getHTMLContent = async () => {
        const htmlContent = GenerateContent();
        setEditorData(htmlContent);
    };

    const generateSideBarDataLength = (data = []) => {
        const d = data.filter(d => SIDEBAR_HEADINGS.includes(parseInt(d.text_type)));
        setSideBarData(d);
    }

    const handleSubSectionSelection = (id) => {
        try {
            const currentData = data.filter(d => d.id === id);
            if (currentData) {
                setDataToShow(currentData);
                setCurrentSelected(id)
            }
        } catch (err) {
            console.log({ err });
        }
    }

    const loadData = async () => {
        try {
            let url = `/api/contents/navigation-chapter-preview/?structures_id=${structure_id}&edit_chapter=true`;
            const ret = await sendAPIRequest(url);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                if (res.data.length > 0) {
                    generateSideBarDataLength(res.data);
                    setData(res.data);
                }
            } else {
                displayNotification('error', 'Error loading data [B]');
            }
        } catch (err) {
            console.log({ err });
            displayNotification('error', 'Error loading data [F]');
        }
    }

    const getChapterInformation = async () => {
        try {
            const ret = await sendAPIRequest(`/api/standard-structures/chapter-information/${structure_id}`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                setChapterInfo(res.data);
            }
        } catch (err) {
            console.log(err);
        }
    }

    const updateContent = async () => {
        if (!currentArticle) {
            displayNotification('error', 'No heading is selected to update content [F]');
            return
        }
        try {
            let url = `/api/contents/update-standard-structure-content/${currentArticle}`;
            const ret = await sendAPIRequest(url, 'PUT', {
                html: editorData
            });
            const res = await ret.json();
            if (res.success && res.status === 200) {
                await loadData();
                displayNotification('success', 'Successfully updated content [B]');
            } else {
                displayNotification('error', 'Error loading data [B]');
            }
        } catch (err) {
            console.log({ err });
            displayNotification('error', 'Error loading data [F]');
        }
    }

    const handleReturn = (id) => {
        setCurrentArticle(id);
    }

    const GenerateContent = () => {
        return ReactDOMServer.renderToStaticMarkup(
            <>
                {
                    dataToShow && dataToShow.length > 0 && dataToShow.map(d => (
                        (d.text_type === LIST_DETAIL_ELEMENTS.text_types.article_heading || MANUAL_HEADINGS.includes(d.text_type)) && (
                            <React.Fragment key={d.id}>
                                {d.html_contents && d.html_contents.length > 0 ? (
                                    d.html_contents.map((child) => (
                                        <>
                                            <HtmlRenderer
                                                tag={"div"}
                                                htmlContent={child.content}
                                            />
                                        </>
                                    ))
                                ) : (
                                    d.children && d.children.length > 0 && d.children.map(child => (
                                        <div key={`general-${child.id}`} id={`${child.id}`}>
                                            {child.html_contents && child.html_contents.map((ch) => (
                                                <div
                                                    key={`gen-child-${ch.id}`}
                                                    className={`d-flex ${ch.text_type === LIST_DETAIL_ELEMENTS.styles_categories.code_editing_date_text ? 'justify-content-end pt-3' : ''}`}
                                                >
                                                    <div className={LIST_DETAIL_ELEMENTS.heading_types.general_heading === child.text_type ? "text-justified" : 'justify-content-end'}>
                                                        <HtmlRenderer
                                                            tag={LIST_DETAIL_ELEMENTS.heading_types.general_heading === child.text_type ? "div" : "p"}
                                                            htmlContent={ch.content}
                                                        />
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    ))
                                )}
                            </React.Fragment>
                        )
                    ))
                }
            </>
        );
    };

    useEffect(() => {
        getChapterInformation();
        loadData();
    }, [structure_id]);

    useEffect(() => {
        getHTMLContent();
    }, [dataToShow]);

    useEffect(() => {
        if (currentArticle) {
            handleSubSectionSelection(currentArticle);
        }
    }, [currentArticle]);

    return (
        <React.Fragment>
            <Container>
                <CustomCard className={'d-flex justify-content-between'}>
                    <CardHeader>
                        {chapterInfo?.chapter_name}
                    </CardHeader>
                    <CardBody>
                        <Button
                            onClick={updateContent}
                        >
                            Save
                        </Button>
                    </CardBody>
                </CustomCard>
                <Row className={"mb-0 pb-0 g-0"}>
                    <Column
                        col={2}
                        className={'border border-secondary mx-0 px-0 bg-light'}
                        style={{
                            position: 'sticky',
                            top: 0,
                            height: '100vh',
                            zIndex: '999',
                            overflowY: 'auto',
                        }}
                    >
                        <SideBarComponent
                            data={sideBarData}
                            isCode={is_code}
                            showAction={false}
                            striptHtml={!is_code}
                            returnData={(id) => handleReturn(id)}
                        // handleEdit={(id) => handleEdit(id)}
                        // handleDelete={(id) => handleDelete(id)}
                        // handleMoveUp={(index) => handleMoveUp(index)}
                        // handleMoveDown={(index) => handleMoveDown(index)}
                        // handleTagging={(id) => handleTagging(id)}
                        // handleAddArticle={(id) => handleAddArticle(id)}
                        // setAddButtonClicked={setAddButtonClicked}
                        />
                    </Column>
                    <Column col={10}>
                        {data && data.length > 0 ? (
                            <CKEditor
                                className="rounded-0"
                                editor={ClassicEditor}
                                data={editorData}
                                config={{
                                    licenseKey: 'NHovd1ZGN2hudTNGQW5rT2pKcGpQM2daNkFLcXFoVUltWnJBKzhKSWN4akkzVGFKYi9scy1NakF5TlRFd01ERT0'  // Add the license key here
                                }}
                                onReady={(editor) => {
                                }}
                                onChange={(event, editor) => {
                                    setEditorData(editor.getData());
                                }}
                            />
                        ) : (
                            <Spinner message="Loading editor..." />
                        )}
                    </Column>
                </Row>
            </Container>
        </React.Fragment>
    );
}

export default ModifyExistingChapter;