const CardBody = ({children, className}) => {
    return (
        <div className={`header-right-items ms-auto ${className}`}>
            {children}
        </div>
    );
}

CardBody.defaultProps = {
    children: <></>,
    className: ''
};

export default CardBody;