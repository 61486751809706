import React from 'react';

const ThreeDotsDropdown = ({ className, options, isVertical = false }) => {
    return (
        <div className="dropdown">
            <i 
                className={`${isVertical ? "fa-solid fa-ellipsis-vertical" : "fas fa-ellipsis-h"} cursor-pointer ${className}`}
                data-bs-toggle="dropdown"
            />
            <ul className="dropdown-menu">
                {
                    options.map((el, k) => (
                        <li key={k}>
                            {el('dropdown-item cursor-pointer')}
                        </li>
                    ))
                }
            </ul>
        </div>
    );
}

ThreeDotsDropdown.defaultProps = {
    className: '',
    options: []
};

export default ThreeDotsDropdown;
