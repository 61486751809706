import HtmlRenderer from "components/HtmlRenderer";
import TextFieldCustom from "components/TextFieldCustom";
import HTMLDropDownMenu from "components/mapping/HTMLDropDownMenu";
import EditModal from "components/modals/EditModal";
import HTMLSplitModal from "components/modals/HTMLSplitModal";
import SetImageModal from "components/modals/SetImageModal";
import Spinner from "components/spinner/Spinner";
import { LIST_DETAIL_ELEMENTS } from "constants";
import { displayNotification, sendAPIRequest } from "helpers";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams, useParams } from "react-router-dom";
import { setCurrentPage, setHeaderTheme } from "redux/generalSlice";
import { styled } from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
const HtmlManualData = () => {

    const LightTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#ffece8',
            color: '#ff4815',
            boxShadow: theme.shadows[1],
            fontSize: 12,
            padding: '13px'
        },
    }));



    const { id } = useParams()
    const editRef = useRef();
    const splitRef = useRef();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const authData = useSelector(s => s.auth.data);
    const [location, setLocation] = useSearchParams();
    const { chapter_id, article_id, document_id } = {
        chapter_id: location.get('chapter'),
        article_id: location.get('article'),
        document_id: location.get('document'),
    };
    const [showMetaData, setShowMetaData] = useState(authData.role_id === LIST_DETAIL_ELEMENTS.roles.data_migrator ? true : false);
    const [data, setData] = useState([]);
    const [count, setCount] = useState(0);
    const [search, setSearch] = useState('');
    const [highlightedData, setHighlightedData] = useState(data);
    const [dbStyleLoad, setDbStyleLoad] = useState(null)
    const [glossaryData, setGLossaryData] = useState(null)
    const [isLoading, setIsLoading] = useState({
        loading_data: false,
    });
    const [tooltipModal, setTooltipModal] = useState(false)
    const [documentMetaData, setDocumentMetaData] = useState({
        section_name: '',
        chapter_name: '',
        chapter_no: '',
        section_no: ""
    });
    const [finalData, setFinalData] = useState(documentMetaData);

    useEffect(() => {
        dispatch(setHeaderTheme('white'));
        dispatch(setCurrentPage('access-commodities'));
        getChapterData();
        laodMetaData()
        if (document.getElementById(`${article_id}`)) {
            const headingElement = document.getElementById(`${article_id}`);
            if (headingElement) {
                const offsetTop = headingElement.offsetTop;
                window.scrollTo({
                    top: offsetTop - 50,
                    behavior: 'smooth'
                });
            }
        }
    }, []);

    useEffect(() => {
        if (document.getElementById(`${article_id}`)) {
            const headingElement = document.getElementById(`${article_id}`);
            if (headingElement) {
                const offsetTop = headingElement.offsetTop;
                window.scrollTo({
                    top: offsetTop - 50,
                    behavior: 'smooth'
                });
            }
        }
    }, [isLoading.loading_data]);
    // asad 
    const laodMetaData = async () => {
        try {
            // let id = document
            const ret = await sendAPIRequest(`/api/html/manual-heading?id=${id}`);
            const res = await ret.json();
            // console.log("test",res)
            if (res.success && res.status === 200) {

                setDocumentMetaData(prevState => ({
                    ...prevState,
                    section_name: res.data.section_name,
                    chapter_name: res.data.chapter_name,
                    section_no: res.data.section_no,
                    chapter_no: res.data.chapter_no
                }));
            }
        } catch (error) {
            console.log(error)
        }
    }
    const getChapterData = async () => {
        setIsLoading({ ...isLoading, loading_data: true });
        try {
            const ret = await sendAPIRequest(`/api/contents/manual-chapter/${id}?isrequired=true`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                setData(res.data);

                setDbStyleLoad(res.flag.styleTag ?? null)
            }
        } catch (e) {
            console.log({ e });
            displayNotification('error', 'Failed to load chapter data [F]');
        }
        setIsLoading({ ...isLoading, loading_data: false });
    }
    const handleEdit = async (id, content) => {
        try {
            const ret = await sendAPIRequest(
                `/api/html-contents/${id}`,
                'PUT',
                {
                    content
                }
            );
            const res = await ret.json();
            if (res.success && res.status === 200) {
                displayNotification('success', 'Successfully updated html content [B]');
                await getChapterData();
            } else {
                displayNotification('error', 'Error updating html content [B]');
            }
        } catch (error) {
            console.error(error);
            displayNotification('error', 'Error updating html content [F]');
        }
    }
    const handleSplit = async (content, splitedContent) => {
        try {
            const id = content.id;
            delete content.id;
            const updatedContent = { ...content, content: splitedContent.updated_content };
            const newContent = { ...content, content: splitedContent.new_content };
            const ret = await sendAPIRequest(
                `/api/html-contents/split-content/${id}`,
                'POST',
                {
                    updated_content: updatedContent,
                    new_content: newContent
                }
            );
            const res = await ret.json();
            if (res.success && res.status === 200) {
                displayNotification('success', 'Successfully splited content [B]');
                await getChapterData();
            } else {
                displayNotification('error', 'Error spliting content [B]');
            }
        } catch (error) {
            console.error(error);
            displayNotification('error', 'Error spliting content [F]');
        }
    }
    const handleRecommendation = async (id, content) => {
        try {
            const ret = await sendAPIRequest(
                `/api/contents/${id}`,
                'PUT',
                {
                    standard_content: content.content
                }
            );
            const res = await ret.json();
            if (res.success && res.status === 200) {
                const ret = await sendAPIRequest(
                    `/api/html-contents/${content.id}`,
                    'PUT',
                    {
                        is_ignore: 1
                    }
                )
                const res = await ret.json();
                if (res.success && res.status === 200) {
                    displayNotification('success', 'Successfully perform operation [B]');
                    await getChapterData();
                } else {
                    displayNotification('error', 'Error performing operation [B]');
                }
            } else {
                displayNotification('error', 'Error performing operation [B]');
            }
        } catch (error) {
            console.error(error);
            displayNotification('error', 'Error performing operation [F]');
        }
    }
    const updateContentAPI = async (id, info, updateContent = false) => {
        try {
            let url = '';
            let body = {};
            if (updateContent) {
                url = `/api/contents/${id}`;
                body = {
                    standard_content: info
                };
            } else {
                url = `/api/html-contents/${id}`;
                body = {
                    content: info
                };
            }
            const ret = await sendAPIRequest(
                url,
                'PUT',
                body
            );
            const res = await ret.json();
            if (res.success && res.status === 200) {
                displayNotification('success', "Successfully updated content [B]");
                await getChapterData();
            } else {
                displayNotification('error', 'Failed to update content [B]');
            }
        } catch (e) {
            console.log({ e });
            displayNotification('error', 'Failed to update content [F]');
        }
    }


    const loadGlossaryTerms = async (urlStr) => {
        try {
            if (urlStr) {
                const ret = await fetch(urlStr);
                const res = await ret.json();
                if (res.success && res.status === 200) {
                    setGLossaryData(res.data);
                }
            }

        } catch (error) {
            console.log(error)
            displayNotification('error', 'Failed to Load Glossary [F]');
        }
    }


    const handleMenuClick = async (id, menu_id, index = null) => {
        try {
            let body = {};
            if (menu_id === -1) {
                const content = data[index].html_contents.filter(article => article.id === id)[0];
                editRef.current.handleOpen(content.content, "Edit Content", (con) => handleEdit(content.id, con), 5)
                return;
            }
            if (menu_id === -2) {
                const content = data[index].html_contents.filter(article => article.id === id)[0];
                splitRef.current.handleOpen(content.content, "Split Content", (con) => handleSplit(content, con), 5)
                return;
            }
            if (menu_id === -3) {
                const content = data[index].html_contents.filter(article => article.id === id)[0];
                await handleRecommendation(data[index].id, content);
                return;
            }
            if (menu_id === -4) {
                let content;
                let updateContent = false;
                let text;
                if (index != null) {
                    content = data[index].html_contents.filter(article => article.id === id)[0];
                    updateContent = false;
                    text = content.content;
                } else {
                    content = data.filter(article => article.id === id)[0];
                    updateContent = true;
                    text = content.standard_content;
                }
                editRef.current.handleOpen(text, "Edit Content", (con) => updateContentAPI(content.id, con, updateContent), 4)
                return;
            }
            if (menu_id === 0) {
                body = {
                    is_ignore: 1
                }
            } else {
                body = {
                    text_type: menu_id
                }
            }
            const ret = await sendAPIRequest(
                `/api/html-contents/${id}`,
                'PUT',
                body
            );
            const res = await ret.json();
            if (res.success && res.status === 200) {
                displayNotification('success', 'Successfully updated html content [B]');
                await getChapterData();
            } else {
                displayNotification('error', 'Error updating html content [B]');
            }
        } catch (error) {
            console.error(error);
            displayNotification('error', 'Error updating html content [F]');
        }
    }
    const escapeRegExp = (string) => {
        return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // Escape special characters
    };
    const _highlightSearchTerm = (htmlContent, search) => {
        if (typeof htmlContent !== 'string' || !search) return { highlightedContent: htmlContent, occurrences: 0 };
        const escapedSearch = escapeRegExp(search);
        const regex = new RegExp(`(${escapedSearch})`, 'gi');
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlContent, 'text/html');
        let occurrences = 0;
        const highlightNode = (node) => {
            Array.from(node.childNodes).forEach((childNode) => {
                if (childNode.nodeType === Node.TEXT_NODE) {
                    const parts = childNode.textContent.split(regex);
                    const fragment = document.createDocumentFragment();
                    parts.forEach((part, index) => {
                        const span = document.createElement('span');
                        if (part.toLowerCase() === search.toLowerCase()) {
                            span.style.backgroundColor = 'yellow';
                            occurrences++;
                        }
                        span.textContent = part;
                        fragment.appendChild(span);
                    });
                    childNode.parentNode.replaceChild(fragment, childNode);
                } else if (childNode.nodeType === Node.ELEMENT_NODE) {
                    highlightNode(childNode);
                }
            });
        };
        highlightNode(doc.body);
        const serializer = new XMLSerializer();
        const highlightedHtml = serializer.serializeToString(doc);
        return { highlightedContent: highlightedHtml, occurrences };
    };
    const highlightSearchTerm = (htmlContent, search) => {
        if (typeof htmlContent !== 'string' || !search) return { highlightedContent: htmlContent, occurrences: 0 };
        const escapedSearch = escapeRegExp(search);
        const regex = new RegExp(`(${escapedSearch})`, 'gi');
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlContent, 'text/html');
        let occurrences = 0;
        const highlightNode = (node) => {
            Array.from(node.childNodes).forEach((childNode) => {
                if (childNode.nodeType === Node.TEXT_NODE) {
                    const parts = childNode.textContent.split(regex);
                    const fragment = document.createDocumentFragment();
                    parts.forEach((part, index) => {
                        const span = document.createElement('span');
                        if (part.toLowerCase() === search.toLowerCase()) {
                            span.style.backgroundColor = 'yellow';
                            occurrences++;
                        }
                        span.textContent = part;
                        fragment.appendChild(span);
                    });
                    childNode.parentNode.replaceChild(fragment, childNode);
                } else if (childNode.nodeType === Node.ELEMENT_NODE) {
                    highlightNode(childNode);
                }
            });
        };

        highlightNode(doc.body);

        const highlightedHtml = doc.documentElement.outerHTML;

        return { highlightedContent: highlightedHtml, occurrences };
    };
    useEffect(() => {
        if (!search) {
            setHighlightedData(data);
            setCount(0);
            return;
        }
        let totalCount = 0;
        const highlighted = data.map(item => {
            const highlightedItem = {};
            let itemOccurrences = 0;
            for (const key in item) {
                if (Object.hasOwnProperty.call(item, key)) {
                    if (key === 'html_contents') {
                        const highlightedContents = item[key].map(htmlContent => {
                            const { highlightedContent, occurrences } = highlightSearchTerm(htmlContent.content, search);
                            itemOccurrences += occurrences;
                            return {
                                ...htmlContent,
                                content: highlightedContent
                            };
                        });
                        highlightedItem[key] = highlightedContents;
                    } else {
                        const { highlightedContent, occurrences } = highlightSearchTerm(item[key], search);
                        if (occurrences > 0) {
                            itemOccurrences += occurrences;
                            highlightedItem[key] = highlightedContent;
                        } else {
                            highlightedItem[key] = item[key];
                        }
                    }
                }
            }
            totalCount += itemOccurrences;
            return highlightedItem;
        });
        console.log(highlighted);
        setCount(totalCount);
    }, [search, data]);

    useEffect(() => {
        const metaDataString = JSON.stringify(documentMetaData);
        const { highlightedsection, occurrences } = highlightSearchTerm(documentMetaData.section_name);
        const { highlightedChapter, occurrences2 } = highlightSearchTerm(documentMetaData.chapter_name);
        if (occurrences > 0) {
            setFinalData({ ...finalData, section_name: highlightedsection });
            setCount(prevCount => prevCount + occurrences);
        }
        else if (occurrences2 > 0) {
            setFinalData({ ...finalData, chapter_name: highlightedChapter });
            setCount(prevCount => prevCount + occurrences2);
        }
        else {
            setFinalData(documentMetaData);
        }
    }, [search, documentMetaData]);




    const shouldHaveTooltip = (element) => {
        // Check if the element is an anchor tag with the specific href value
        return (
            element.type === 'a' &&
            element.props &&
            element.props.href &&
            element.props.href.includes('sontbe/api/glossary/term')
        );
    };

    const handleMouseHover = async (Url) => {
        // alert('Mouse hovered over anchor tag with desired href');
        setTooltipModal(true)
        // console.log(Url,'iiiiiiiiiiii');
        await loadGlossaryTerms(Url)
    };

    const handleMouseLeave = (event) => {
        // setTooltipModal(false)
    }

    //   const addTooltipToElements = (element) => {
    //     if (React.isValidElement(element)) {
    //       if (shouldHaveTooltip(element)) {
    //         return (
    //           <Tooltip title="Your tooltip content" arrow>
    //             {element}
    //           </Tooltip>
    //         );
    //       }
    //       return React.cloneElement(element, {}, React.Children.map(element.props.children, addTooltipToElements));
    //     }

    //     return element;
    //   };

    return (
        <div className="container my-2"
        >
            <style dangerouslySetInnerHTML={{ __html: dbStyleLoad }} />
            <EditModal ref={editRef} />
            <HTMLSplitModal ref={splitRef} />
            <SetImageModal
                tooltipModal={tooltipModal}
                setTooltipModal={setTooltipModal}
                setGLossaryData={setGLossaryData}
                glossaryData={glossaryData}
            />
            {
                authData.role_id === LIST_DETAIL_ELEMENTS.roles.data_migrator && (
                    <div className="row header card-woah border border-secondary d-flex px-4 py-2 shadow-sm w-100 my-2">
                        <div className='col-md-8 d-flex justify-content-between'>
                            <h1 className="col-md-4 card-title1 font-custom-2 lh-1">{t("-1", "Manual Chapter Preview")}</h1>
                        </div>
                        <div className="col-md-4 header-right-items ms-auto d-flex align-items-center">
                            <div className='ms-auto'>
                                <button
                                    className="btn btn--primary font-custom fs-6 ms-1"
                                    onClick={() => setShowMetaData(prev => !prev)}
                                >
                                    {
                                        showMetaData
                                            ? t("-1", "Hide Meta Data")
                                            : t("-1", "Show Meta Data")
                                    }
                                </button>
                            </div>
                        </div>
                    </div>
                )
            }
            {
                isLoading.loading_data ? (
                    <Spinner message='Loading Data, Please wait...' />
                ) : (
                    <div className='bg-light-details px-4'>
                        <div className="row d-flex mb-5">
                            <div className="col-md-10">
                                <TextFieldCustom
                                    className="col-md-4 ms-auto my-auto"
                                    required={false}
                                    error={false}
                                    // label={t("-1", "Search from content")}
                                    fullWidth
                                    size="small"
                                    onChange={(e) => setSearch(e.target.value)}
                                    placeholder='Search content here...'
                                />
                            </div>
                            <div className="col-md-2 mt-1">
                                {
                                    search && search.length > 0 &&
                                    <span className='fs-5'>No of Matches: {count}</span>
                                }
                            </div>

                        </div>

                        {
                            documentMetaData.section_name
                            && (
                                <div >

                                    <div className="fs-5 text-black text-center pt-10 pb-5" style={{ letterSpacing: '2px' }} >
                                        Section {documentMetaData.section_no}.
                                    </div>
                                    <h3 className="chapter-heading text-black text-center" style={{ letterSpacing: '2px' }} >

                                        {finalData.section_name}
                                        {/* <HtmlRenderer
                                            tag={'div'}
                                            // className={child.class_name ?? 'fs-4'}
                                            htmlContent={finalData.section_name} /> */}
                                    </h3>

                                    <hr className='dark-underline mb-0' />
                                    <hr className='dark-underline mt-1' />
                                </div>
                            )
                        }
                        {
                            documentMetaData.chapter_name
                            && (
                                <div >

                                    <div className="fs-4 fw-lighter text-black text-center pt-10 pb-7" style={{ letterSpacing: '2px' }} >
                                        CHAPTER {documentMetaData.section_no}.{documentMetaData.chapter_no}.
                                    </div>
                                    <div className="d-flex justify-content-center chapter-heading text-black text-center pb-5" style={{ letterSpacing: '2px' }} >
                                        {/* {d.standard_content} */}
                                        <div className='px-4 text-justified' >
                                            {finalData.chapter_name}
                                            {/* <HtmlRenderer
                                            tag={'div'}
                                            // className={child.class_name ?? 'fs-4'}
                                            htmlContent={finalData.chapter_name} /> */}
                                        </div>

                                    </div>
                                </div>
                            )
                        }
                        {
                            highlightedData && highlightedData.length > 0 ? (
                                highlightedData.map((d, index) => (
                                    <div key={`key-${index}`}>
                                        {
                                            d.text_type === 86 || d.text_type === 88 ? (
                                                d.text_type === 86 ? (
                                                    <div className="fs-4 fw-lighter text-black text-center pt-10 pb-7" style={{ letterSpacing: '2px' }} >
                                                        <HtmlRenderer
                                                            tag={'div'}
                                                            htmlContent={d.standard_content}
                                                            handleMouseHover={handleMouseHover}
                                                        />
                                                        <HTMLDropDownMenu
                                                            menuList={
                                                                [
                                                                    { id: -4, label: 'Edit' },
                                                                ]
                                                            }
                                                            id={d.id}
                                                            className='fs-3'
                                                            handleMenuClick={handleMenuClick}
                                                        />
                                                    </div>
                                                ) : (
                                                    <div className="fs-4 fw-lighter text-black text-center pt-10 pb-7" style={{ letterSpacing: '2px' }} >
                                                        <HtmlRenderer
                                                            tag={'div'}
                                                            htmlContent={d.standard_content}
                                                            handleMouseHover={handleMouseHover}
                                                        />
                                                        <HTMLDropDownMenu
                                                            menuList={
                                                                [
                                                                    { id: -4, label: 'Edit' },
                                                                ]
                                                            }
                                                            id={d.id}
                                                            className='fs-3'
                                                            handleMenuClick={handleMenuClick}
                                                        />
                                                    </div>
                                                )
                                            ) : (
                                                <div key={`general-${d.id}`} id={`${d.id}`}>
                                                    <HtmlRenderer
                                                        tag={'div'}
                                                        className={d.class_name ?? "py-4 document-article-intitule"}
                                                        htmlContent={d.standard_content}
                                                        // isItalic="f"
                                                        handleMouseHover={handleMouseHover}
                                                    />
                                                    {
                                                        d.html_contents && d.html_contents.length > 0 &&
                                                        d.html_contents.map((child, i) =>
                                                            <div key={`gen-child-${child.id}`} className={`d-flex ${child.text_type === LIST_DETAIL_ELEMENTS.styles_categories.code_editing_date_text && 'justify-content-center'}`}>
                                                                <div className='text-justified'>
                                                                    <HtmlRenderer
                                                                        tag={child.tag_name ?? 'p'}
                                                                        className={child.class_name ?? 'fs-4'}
                                                                        htmlContent={child.content}
                                                                        // isItalic="f"
                                                                        handleMouseHover={handleMouseHover}
                                                                    />
                                                                </div>
                                                                {
                                                                    authData.role_id == LIST_DETAIL_ELEMENTS.roles.data_migrator && showMetaData &&
                                                                    <HTMLDropDownMenu
                                                                        menuList={
                                                                            [
                                                                                { id: -4, label: 'Edit' },
                                                                            ]
                                                                        }
                                                                        id={child.id}
                                                                        className='fs-3'
                                                                        handleMenuClick={handleMenuClick}
                                                                        index={index}
                                                                    />
                                                                }
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            )
                                        }
                                    </div>

                                ))
                            ) : (
                                <div className='text-center'>No Data Found</div>
                            )
                        }
                    </div>
                )
            }
        </div>
    );
}

HtmlManualData.defaultProps = {

}

export default HtmlManualData;