import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentPage, setSiteLanguage } from "redux/generalSlice";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import { BsExclamationTriangleFill, BsInfoCircleFill } from "react-icons/bs";
import { Checkbox, FormControlLabel, FormGroup, styled } from "@mui/material";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import AutoCompleteDropdown from "components/autocomplete/AutoCompleteDropdown";
import { displayNotification, generateOptionsFromData, sendAPIRequest } from "helpers";
import Spinner from "components/spinner/Spinner";
import { LIST_DETAIL_ELEMENTS } from "constants";
import Typography from '@mui/material/Typography';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { cloneDeep } from "lodash";
import collect from "collect.js";
import { PDF_GENERATE_API, PDF_DOWNLOAD_API } from "constants";
import HtmlRenderer from "components/HtmlRenderer";
import useLocalStorage from "hooks/useLocalStorage";

const AccessToCommoditiesV3 = () => {
    const [componentLanguage, setComponentLanguage] = useState('102');
    const siteLanguage = useSelector(s => s.general.siteLanguageSont)
    const { t, i18n } = useTranslation('134');

    const LightTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#ffece8',
            color: '#ff4815',
            boxShadow: theme.shadows[1],
            fontSize: 12,
            padding: '13px'
        },
    }));

    const { setItem } = useLocalStorage('sont-commodities-search-data', {});
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [alertText, setAlertText] = useState(t("154", `The disease-specific chapters of the WOAH Terrestrial and Aquatic Codes recommend sanitary measures to mitigate the risk of pathogenic agents of WOAH-listed diseases, infections or infestations being introduced into an importing country through international trade of Commodities. By applying various filters, this tool allows quick access to these specific recommendations, for specific commodities, across the different WOAH-listed diseases for Terrestrial animals. Select an option from each filter, and the results that align with your chosen criteria will appear below. Next, you can further refine your search by selecting the status of the animal population at origin (i.e. country, zone, compartment, herd, others) for each disease, to visualize only the relevant recommendations. This tool provides the option to export and print a complete or detailed report in PDF format for your reference. The complete text of the Terrestrial Code is available on link to the navigation tool.  `));
    const [isAlertVisible, setIsAlertVisible] = useState(true);
    const [isDefaultAlertVisible, setIsDefaultAlertVisible] = useState(true);
    const [isSearchAlertVisible, setIsSearchAlertVisible] = useState(false);
    const [isAdditionalAlertVisible, setIsAdditionalAlertVisible] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const l1 = searchParams.get('level1');
    const l2 = searchParams.get('level2');
    const l3 = searchParams.get('level3');
    const headerTheme = useSelector((s) => s.general.headerTheme);
    const authData = useSelector((s) => s.auth.data);
    const [level1, setLevel1] = useState(l1 ?? '0');
    const [level2, setLevel2] = useState(l2 ?? '0');
    const [level3, setLevel3] = useState(l3 ?? '0');
    const [isSearchclick, setIsSearchClick] = useState(false);
    const [commodityGroups, setCommodityGroups] = useState([]);
    const [commodities, setCommodities] = useState([]);
    const [animalList, setAnimalList] = useState([]);
    const [DATA, setDATA] = useState([]);
    const [DATA_REC, setDATA_REC] = useState([]);
    const [data, setData] = useState([]);
    const [baseFontSize, setBaseFontSize] = useState(14);
    const [isLoading, setIsLoading] = useState({
        loading_filters: false,
        loading_data: false,
    });
    const currentDate = new Date().toISOString().slice(0, 10);

    const HtmlTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} placement="top-start" />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#f5f5f9',
            color: 'rgba(0, 0, 0, 0.87)',
            maxWidth: 1000,
            fontSize: theme.typography.pxToRem(12),
            border: '1px solid #787878',
            padding: '30px',
            boxShadow: '1px 2px 9px #A9A9A9',
        },
    }));

    useEffect(() => {
        dispatch(setCurrentPage("access-commodities"));
        getFiltersLists();
    }, []);
    useEffect(() => {
        getCommodities();
        getAnimalsList();
    }, [level1]);
    useEffect(() => {
        getCommodityGroups();
        getAnimalsList();
    }, [level2]);
    useEffect(() => {
        getCommodityGroups();
        getCommodities();
    }, [level3]);
    useEffect(() => {
        getCommodities();
        getCommodityGroups();
        getAnimalsList();
    }, [siteLanguage]);
    useEffect(() => {
        ((level1 && level1 != '0') || (level2 && level2 != '0') || (level3 && level3 != '0')) && getData();
        setIsSearchClick(true);
    }, [componentLanguage]);

    const changeComponentLanguage = async (lang) => {
        setComponentLanguage(lang);
    }

    const getData = async () => {
        setIsLoading({ ...isLoading, loading_data: true });
        try {
            const ret = await sendAPIRequest(`/api/commodities-animals/search-commodities-v4/?commodity_group_id=${level1}&commodity_id=${level2}&animal_id=${level3}&language_id=${componentLanguage}`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                setDATA(res.data);
                displayNotification('success', 'Successfully searched data [B]');
            } else if (res.success && res.status === 404) {
                setDATA([]);
                displayNotification('warning', 'No Data Found [B]');
            } else {
                setDATA([]);
                displayNotification('error', 'Failed to get data [B]');
            }
            setIsLoading({ ...isLoading, loading_data: false });
        } catch (e) {
            setIsLoading({ ...isLoading, loading_data: false });
            console.log(e);
        }
    }

    const getFiltersLists = async () => {
        setIsLoading({ ...isLoading, loading_filters: true });
        await getCommodityGroups();
        await getCommodities();
        await getAnimalsList();
        setIsLoading({ ...isLoading, loading_filters: false });
    };

    const getCommodityGroups = async () => {
        try {
            const ret = await sendAPIRequest(
                `/api/commoditiy-groups/?commodity_id=${level2}&animal_id=${level3}&language_id=${siteLanguage}&show_in_filter=1`
            );
            const res = await ret.json();
            if (res.success && res.status === 200) {
                if (res.data.length === 1) {
                    let { l1, l2, l3 } = {
                        l1: level1,
                        l2: level2,
                        l3: level3,
                    };
                    l1 = res.data[0].id;
                    setLevel1(res.data[0].id);
                    navigate(`/portal/access-commodities/?level1=${l1}&level2=${l2}&level3=${l3}`);
                }
                let obj = { id: 0, 'commodity_group_name': 'All' };
                if (siteLanguage == '103') {
                    obj = { id: 0, 'commodity_group_name': 'Tout' };
                } else if (siteLanguage == '104') {
                    obj = { id: 0, 'commodity_group_name': 'Todo' };
                }
                setCommodityGroups([...(res.data.length > 1 ? [obj] : []), ...res.data]);
            }
        } catch (err) {
            console.log(err);
        }
    };
    const getCommodities = async () => {
        try {
            const ret = await sendAPIRequest(
                `/api/commdities/?commodity_group_id=${level1}&animal_id=${level3}&language_id=${siteLanguage}&show_in_filter=1`
            );
            const res = await ret.json();
            if (res.success && res.status === 200) {
                if (res.data.length === 1) {
                    let { l1, l2, l3 } = {
                        l1: level1,
                        l2: level2,
                        l3: level3,
                    }
                    l2 = res.data[0].id;
                    setLevel2(res.data[0].id)
                    navigate(`/portal/access-commodities/?level1=${l1}&level2=${l2}&level3=${l3}`);
                }
                let obj = { id: 0, 'commodity_name': 'All' };
                if (siteLanguage == '103') {
                    obj = { id: 0, 'commodity_name': 'Tout' };
                } else if (siteLanguage == '104') {
                    obj = { id: 0, 'commodity_name': 'Todo' };
                }
                setCommodities([...(res.data.length > 1 ? [obj] : []), ...res.data]);
            }
        } catch (err) {
            console.log(err);
        }
    };
    const getAnimalsList = async () => {
        try {
            const ret = await sendAPIRequest(`/api/animals/?commodity_group_id=${level1}&commodity_id=${level2}&language_id=${siteLanguage}&show_in_filter=1`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                if (res.data.length === 1) {
                    let { l1, l2, l3 } = {
                        l1: level1,
                        l2: level2,
                        l3: level3,
                    }
                    l3 = res.data[0].id;
                    setLevel3(res.data[0].id)
                    navigate(`/portal/access-commodities/?level1=${l1}&level2=${l2}&level3=${l3}`);
                }
                let obj = { id: 0, 'animal_name': 'All' };
                if (siteLanguage == '103') {
                    obj = { id: 0, 'animal_name': 'Tout' };
                } else if (siteLanguage == '104') {
                    obj = { id: 0, 'animal_name': 'Todo' };
                }
                setAnimalList([...(res.data.length > 1 ? [obj] : []), ...res.data]);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const generateFilterLabels = () => {
        const l1_name = commodityGroupsOptions.filter(val => parseInt(val.value) === parseInt(level1))[0]?.label ?? '';
        const l2_name = commoditiesOptions.filter(val => parseInt(val.value) === parseInt(level2))[0]?.label ?? '';
        const l3_name = animalListOptions.filter(val => parseInt(val.value) === parseInt(level3))[0]?.label ?? '';
        return `${l1_name}, ${l2_name}, ${l3_name}`;
    }

    const handleListClick = async (isDownload = false) => {
        if (isSearchclick && DATA.length > 0) {
            const file_name = `WOAH_Report_List_${currentDate}.pdf`;
            if (isDownload) {
                await sendDataToBackend(file_name, 'list');
            } else {
                const name = generateFilterLabels();
                setItem({ DATA });
                const url = `/portal/pdf-list/?level1=${level1}&level2=${level2}&level3=${level3}&labels=${name}&download=${isDownload}&file_name=${file_name}&language_id=${componentLanguage}`;
                window.open(url, "self");
            }
        } else {
            displayNotification('warning', `${t("-1", "No content available for download")}`);
        }
    };

    const handleDetailsClick = async (isDownload = false) => {
        if (isSearchclick && DATA.length > 0) {
            const file_name = `WOAH_Report_Details_${currentDate}.pdf`;
            if (isDownload) {
                await sendDataToBackend(file_name, 'detail')
            } else {
                const name = generateFilterLabels();
                setItem({ DATA });
                const url = `/portal/pdf-details/?level1=${level1}&level2=${level2}&level3=${level3}&labels=${name}&download=${isDownload}&file_name=${file_name}&language_id=${componentLanguage}`;
                window.open(url, "self");
            }
        } else {
            displayNotification('warning', `${t("-1", "No content available for download")}`);
        }
    };

    const handleChangeSearch = async () => {
        if (((!level1 || level1 == '0') && (level2 == '0' || !level2) && (level3 == '0' || !level3))) {
            displayNotification('error', `${t("-1", "Please select at least one value from one of the three filters")}`);
            return;
        } else {
            await getData();
        }
        setIsSearchClick(true);
    };


    const resetFilters = async () => {
        setLevel1('0');
        searchParams.set('level1', '');
        setLevel2('0');
        searchParams.set('level2', '');
        setLevel3('0');
        searchParams.set('level3', '');
        navigate('/portal/access-commodities');
        setIsSearchClick(false);
        setDATA([]);
    }

    const handleCheckboxChange = (type, index, checked) => {
        let temp = cloneDeep(DATA);
        let falseCount = 0;
        temp[index].status_filters = temp[index].status_filters.map(filter => {
            if (parseInt(filter.type_id) === parseInt(type)) {
                filter.checked = checked;
            }
            return filter;
        });
        temp[index].status_filters.forEach(filter => {
            if (!filter.checked) {
                falseCount++;
            }
        });
        if (falseCount !== temp[index].status_filters.length) {
            temp[index].articles = temp[index].articles.map(article => {
                if (article.filters.includes(parseInt(type))) {
                    if (article.filters.length > 1) {
                        let is_show = true;
                        for (const filterData of temp[index].status_filters) {
                            if (article.filters.includes(filterData.type_id)) {
                                if (filterData.checked) {
                                    is_show = true;
                                    break;
                                } else {
                                    is_show = false;
                                }
                            }
                        }
                        article.is_show = is_show;
                    } else {
                        article.is_show = checked;
                    }
                }
                return article;
            });
            setDATA([...temp]);
        }

        temp = null;
    }

    const increaseFontSize = () => {
        if (baseFontSize < 24) {
            setBaseFontSize(prevSize => prevSize + 2);
        }
    };

    const decreaseFontSize = () => {
        if (baseFontSize > 12) {
            setBaseFontSize(prevSize => prevSize - 2);
        }
    };
    // const h5FontSize = baseFontSize + 2;

    const commodityGroupsOptions = useMemo(() => {
        return generateOptionsFromData(commodityGroups, {
            value: "id",
            label: "commodity_group_name",
        });
    }, [commodityGroups]);
    const commoditiesOptions = useMemo(() => {
        return generateOptionsFromData(commodities, {
            value: "id",
            label: "commodity_name",
        });
    }, [commodities]);
    const animalListOptions = useMemo(() => {
        return generateOptionsFromData(animalList, {
            value: "id",
            label: "animal_name",
        });
    }, [animalList]);

    const navigationFunction = (key, value) => {
        let { l1, l2, l3 } = {
            l1: level1,
            l2: level2,
            l3: level3,
        }
        if (key === 'level1') {
            l1 = value;
            setLevel1(value);
        } else if (key === 'level2') {
            l2 = value;
            setLevel2(value);
        } else if (key === 'level3') {
            l3 = value;
            setLevel3(value);
        }
        navigate(`/portal/access-commodities/?level1=${l1}&level2=${l2}&level3=${l3}`);
    }

    const showAlert = (text) => {
        setAlertText(text);
        setIsAlertVisible(prev => !prev);
    };

    const handleChangeAlert = () => {
        setIsAlertVisible(false);
        setIsDefaultAlertVisible(false);
        setIsSearchAlertVisible(true);
        setIsAdditionalAlertVisible(true);
    };

    const sendDataToBackend = async (fileName, fileType = 'detail') => {
        try {
            displayNotification('info', `${t('-1', 'Generating PDF please wait...')}`);
            const labels = generateFilterLabels().split(',');
            const ret = await sendAPIRequest(
                `/api/generate-pdf/?file=${fileType}`,
                'POST',
                {
                    DATA,
                    DATA_REC,
                    labels,
                    componentLanguage
                }
            );
            const data = await ret.json();
            console.log({ data });
            if (data.success && data.status === 200) {
                const downloadResponse = await sendAPIRequest(`/api/generate-pdf/download/?file_name=${fileName}&uniqueName=${data.data.file_name}`);
                if (downloadResponse.ok) {
                    const blob = await downloadResponse.blob();
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = fileName ?? 'test.pdf';
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                    window.URL.revokeObjectURL(url);
                    console.log({ url });
                    displayNotification('success', 'Successfully downloaded file');
                } else {
                    displayNotification('error', 'Error downloading file');
                }
            } else {
                displayNotification('error', 'Error creating PDF');
            }
        } catch (error) {
            console.error('Error sending data:', error);
        }
    };



    return (
        <>
            <div className="container my-2">
                <div className="header card-woah border border-secondary d-flex px-4 py-2 shadow-sm w-100 my-2">
                    <div className="d-flex justify-content-between w-100">
                        <div className="card-title1 lh-1">
                            {t("7", "Recommendations for safe international trade by commodity")}
                        </div>
                    </div>
                </div>
                <div className="d-flex align-items-center my-3">
                    <BsInfoCircleFill
                        className="bi flex-shrink-0 me-4 ms-3 mb-auto text-blue-light-2 cursor-pointer"
                        size={24}
                        onClick={() => showAlert(t("154", "The disease-specific chapters of the WOAH Terrestrial and Aquatic Codes recommend sanitary measures to mitigate the risk of pathogenic agents of WOAH-listed diseases, infections or infestations being introduced into an importing country through international trade of Commodities. By applying various filters, this tool allows quick access to these specific recommendations, for specific commodities, across the different WOAH-listed diseases. Select an option from each filter, and the results that align with your chosen criteria will appear below. Next, you can further refine your search by selecting the status from the animal population for each disease at origin, to visualize only the relevant recommendations. This tool provides the option to export a complete or detailed report in PDF format for your reference. The complete text of the Terrestrial Code is available on (link to the navigation tool)"))}
                    />
                    {isAlertVisible && (
                        <div className="alert my-0 py-0 me-5  rounded-0 bg-white text-dark d-flex align-items-center" role="alert">
                            <div className="fs-7 text-justify me-0">
                                {alertText}
                            </div>
                        </div>
                    )}
                </div>
                <div className="header card-woah border border-secondary px-4 py-2 shadow-sm w-100 my-2 ">
                    {
                        false ? (
                            <Spinner message="Loading filters" />
                        ) : (
                            <div className="row py-3">
                                <div className="col-md-4">
                                    <Box sx={{ minWidth: 120 }}>
                                        <div className="col-12">
                                            <AutoCompleteDropdown
                                                className={isLoading.loading_filters ? 'opacity-50' : 'opacity-100'}
                                                disabled={isLoading.loading_filters}
                                                label={isLoading.loading_filters ? 'loading...' : t("8", "Commodity Group")}
                                                showClearIcon={true}
                                                error={false}
                                                required={false}
                                                multiple={false}
                                                hasselectall={false}
                                                options={commodityGroupsOptions}
                                                onChange={(event, value) => {
                                                    navigationFunction('level1', value?.value ?? '');
                                                }}
                                                value={
                                                    level1 === "" || level1 === undefined
                                                        ? null
                                                        : commodityGroupsOptions.find((r) => r.value == level1) ?? { value: '', label: '' }
                                                }
                                                size="medium"
                                            />
                                        </div>
                                    </Box>
                                </div>
                                <div className="col-md-4">
                                    <Box sx={{ minWidth: 120 }}>
                                        <div className="col-12">
                                            <AutoCompleteDropdown
                                                className={isLoading.loading_filters ? 'opacity-50' : 'opacity-100'}
                                                disabled={isLoading.loading_filters}
                                                label={isLoading.loading_filters ? 'loading...' : t("9", "Commodity")}
                                                showClearIcon={true}
                                                error={false}
                                                required={false}
                                                multiple={false}
                                                hasselectall={false}
                                                options={commoditiesOptions}
                                                onChange={(event, value) => {
                                                    navigationFunction('level2', value?.value ?? '');
                                                }}
                                                value={
                                                    level2 === "" || level2 === undefined
                                                        ? null
                                                        : commoditiesOptions.find((r) => r.value == level2) ?? { value: '', label: '' }
                                                }
                                                size="medium"
                                            />
                                        </div>
                                    </Box>
                                </div>
                                <div className="col-md-4">
                                    <Box sx={{ minWidth: 120 }}>
                                        <div className="col-12">
                                            <AutoCompleteDropdown
                                                className={isLoading.loading_filters ? 'opacity-50' : 'opacity-100'}
                                                disabled={isLoading.loading_filters}
                                                label={isLoading.loading_filters ? 'loading...' : t("10", "Animal")}
                                                showClearIcon={true}
                                                error={false}
                                                required={false}
                                                multiple={false}
                                                hasselectall={false}
                                                options={animalListOptions}
                                                onChange={(event, value) => {
                                                    navigationFunction('level3', value?.value ?? '');
                                                }}
                                                value={
                                                    level3 === "" || level3 === undefined
                                                        ? null
                                                        : animalListOptions.find((r) => r.value == level3) ?? { value: '', label: '' }
                                                }
                                                size="medium"
                                            />
                                        </div>
                                    </Box>
                                </div>
                            </div>
                        )
                    }
                    <div className="text-end">

                        <button
                            className="btn btn--primary font-custom fs-6"
                            onClick={() => {
                                handleChangeSearch();
                                handleChangeAlert();
                            }}
                            disabled={isLoading.loading_data}
                        >
                            {isLoading.loading_data ? (
                                <Spinner message="Searching..." />
                            ) : (
                                <span>{t("13", "Search")}</span>
                            )}
                        </button>
                        <button
                            className="btn btn--outline font-custom fs-6 ms-1"
                            onClick={() => {
                                resetFilters();
                            }}
                        >
                            <span> {t("14", "Reset")}</span>
                        </button>
                    </div>
                </div>
                <div className=" header-res header card-woah border border-secondary d-flex px-4 py-2 shadow-sm w-100">
                    <div className="d-flex justify-content-between">
                        <div className="card-title2 lh-1">
                            {t("15", "Result")}
                        </div>
                        <div className="d-flex mt-1 ms-10">
                            <span
                                className={`bg-${headerTheme} cursor-pointer list-group-item text-hover-primary border-0 text-center font-custom ms-2`}
                            >
                                <LightTooltip title={t("-1", "Print the summarized version of the report")} placement="top">
                                    <i
                                        className="fas fa-print text-primary fs-2"
                                        onClick={() => handleListClick()}
                                    >
                                    </i>
                                </LightTooltip>
                            </span>
                            <span
                                className={`bg-${headerTheme} cursor-pointer list-group-item text-hover-primary border-0 text-center font-custom`}
                            >
                                <LightTooltip title={t("17", "Export the summarized version of the report")} placement="top">
                                    <i
                                        className="fas fa-file-pdf text-primary fs-2"
                                        onClick={() => handleListClick(true)}
                                    >
                                    </i>
                                </LightTooltip>
                            </span>
                            <span className="mt-1 fs-4">{t("18", "List")}</span>
                        </div>
                        <div className="d-flex mt-1 border-1 border-start border-dark ms-3">
                            <span
                                className={`bg-${headerTheme} cursor-pointer list-group-item text-hover-primary border-0 text-center font-custom ms-2`}
                            >
                                <LightTooltip title={t("19", "Print the detailed version of the report")} placement="top">
                                    <i
                                        className="fas fa-print text-primary fs-2"
                                        onClick={() => handleDetailsClick()}
                                    >

                                    </i>
                                </LightTooltip>
                            </span>
                            <span
                                className={`bg-${headerTheme} cursor-pointer list-group-item text-hover-primary border-0 text-center font-custom ms-2`}
                            >
                                <LightTooltip title={t("20", "Export the detailed version of the report")} placement="top">
                                    <i
                                        className="fas fa-file-pdf text-primary fs-2"
                                        onClick={() => handleDetailsClick(true)}
                                    >
                                    </i>
                                </LightTooltip>
                            </span>
                            <span className="mt-1 fs-lg-4 ">{t("21", "Details")}</span>

                        </div>
                    </div>
                    <div className="header-right-items ms-auto d-flex align-items-center">
                        <div className="ms-auto">
                            <div className="list-group list-group-horizontal ms-auto">
                                <span className="vertical-line mx-5"></span>
                                <span
                                    className={`bg-${headerTheme} cursor-pointer list-group-item text-hover-primary border-0 text-center font-custom ${componentLanguage == `${LIST_DETAIL_ELEMENTS.languages.english}` ? "text-decoration-underline" : ""
                                        } `}
                                    onClick={() => (changeComponentLanguage(`${LIST_DETAIL_ELEMENTS.languages.english}`))}
                                >
                                    EN
                                </span>
                                <span
                                    className={`bg-${headerTheme} cursor-pointer list-group-item text-hover-primary border-0 text-center font-custom ${componentLanguage == `${LIST_DETAIL_ELEMENTS.languages.french}` ? "text-decoration-underline" : ""
                                        } `}
                                    onClick={() => (changeComponentLanguage(`${LIST_DETAIL_ELEMENTS.languages.french}`))}
                                >
                                    FR
                                </span>
                                <span
                                    className={`bg-${headerTheme} cursor-pointer list-group-item text-hover-primary border-0 text-center  font-custom  me-3 ${componentLanguage == `${LIST_DETAIL_ELEMENTS.languages.spanish}` ? "text-decoration-underline" : ""
                                        } `}
                                    onClick={() => (changeComponentLanguage(`${LIST_DETAIL_ELEMENTS.languages.spanish}`))}
                                >
                                    ES
                                </span>
                                <div className="d-flex border-start border-dark" style={{ height: "25px" }}>
                                    <span className="fs-1 cursor-pointer mt-auto ms-3" onClick={increaseFontSize}>
                                        A
                                    </span>
                                    <span className="fs-5 cursor-pointer mt-auto ms-3" onClick={decreaseFontSize} style={{ marginBottom: -3 }}>
                                        A
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {isSearchAlertVisible && (
                    <div
                        className="alert rounded-0 bg-alert-light-1 text-primary d-flex align-items-center my-3"
                        role="alert"
                    >
                        <BsExclamationTriangleFill
                            className="bi flex-shrink-0 me-5 mb-auto "
                            size={24}
                        />
                        <div className="fs-7 text-justify me-5">
                            {t("22", "The absence of recommendations on specific pathogenic agents or commodities does not preclude the application of appropriate sanitary measures by the Veterinary Authorities, provided they are based on risk analyses conducted in accordance with the Terrestrial Animal Health Code.)")}

                        </div>
                    </div>
                )}
                <div className="accordion mt-4 border-0" id="accordionExample">
                    <div className="accordion-item rounded-0">
                        <h1
                            className="accordion-header accordion__item-label collapsed"
                            id="headingOne"
                        >
                            <button
                                className="accordion-button font-custom-4 fs-3 rounded-0 py-2 collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseOne"
                                aria-expanded="false"
                                aria-controls="collapseOne"
                            >
                                <span className="me-auto">
                                    {t("23", "Filter by Disease Status at origin")}
                                </span>
                            </button>
                        </h1>
                        <div
                            id="collapseOne"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingOne"
                            data-bs-parent="#accordionExample"
                        >
                            {
                                isSearchclick && (
                                    isLoading.loading_data ? (
                                        <Spinner message="Loading..." />
                                    ) : (
                                        DATA && DATA.length > 0 && DATA.map((r, k) => (
                                            <div
                                                className={[
                                                    "border-start",
                                                    "border-4",
                                                    "my-1",
                                                    "d-flex",
                                                    "px-4",
                                                    "w-100",
                                                    ...(k % 2
                                                        ? ["bg-light-accordion", "border-light-accordion"]
                                                        : ["bg-light-accordion-2", "border-light-accordion-2"]),
                                                ].join(" ")}
                                                key={`filters-${r.id}`}
                                            >
                                                <div className="row g-0 font-custom-4 fs-3 text-dark my-2 ps-1 w-100">
                                                    <div className="col-md-6">
                                                        <HtmlRenderer
                                                            tag={'p'}
                                                            className="document-chapitre-intitule fs-6 mb-0"
                                                            htmlContent={r.chapter_title}
                                                        />
                                                        <span>
                                                        </span>
                                                    </div>
                                                    <div className="col-md-6 text-end pe-0">
                                                        <FormControl
                                                            className="ms-auto" component="fieldset"
                                                        >
                                                            <FormGroup aria-label="position" row>
                                                                {
                                                                    r.status_filters && r.status_filters.length > 0
                                                                    && r.status_filters.map(filter => (
                                                                        filter.type_id !== 98 &&
                                                                        <FormControlLabel
                                                                            key={`${filter.type_id}-${r.id}`}
                                                                            value={filter.type_id}
                                                                            disabled={filter.disabled}
                                                                            control={
                                                                                <Checkbox
                                                                                    checked={filter.checked}
                                                                                    onChange={(e) =>
                                                                                        handleCheckboxChange(filter.type_id, k, e.target.checked)
                                                                                    }
                                                                                    name={filter.type_name} sx={{
                                                                                        paddingTop: 1,
                                                                                        '& .MuiSvgIcon-root': {
                                                                                            fontSize: '1.3rem',
                                                                                        },
                                                                                    }}

                                                                                />
                                                                            }
                                                                            label={filter.type_name}
                                                                            labelPlacement="end"
                                                                        />
                                                                    ))
                                                                }
                                                            </FormGroup>
                                                        </FormControl>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    )
                                )
                            }
                        </div>
                    </div>
                </div>
                <div className="accordion my-4 border-0" >
                    <div className="accordion-item rounded-0 border-0">
                        <h1
                            className="accordion-header accordion__item-label"
                            id="headingTwo"
                        >
                            <button
                                className="accordion-button  font-custom-4 fs-1 fw-bold  rounded-0  py-2"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseTwo"
                                aria-expanded="true"
                                aria-controls="collapseTwo"
                            >
                                <span className="me-auto">{t("25", "Recommendations")}</span>
                            </button>
                        </h1 >
                        <div
                            id="collapseTwo"
                            className="accordion-collapse show"
                            aria-labelledby="headingTwo"
                        >
                            {
                                isSearchclick && (
                                    isLoading.loading_data ? (
                                        <Spinner message="Loading..." />
                                    ) : (
                                        DATA && DATA.length > 0 &&
                                        DATA.map((chapter, j) => (
                                            chapter.articles.some((article) => article.is_show) &&
                                            <div
                                                key={chapter.id}
                                                className={[
                                                    "accordion-body",
                                                    "border-start",
                                                    "border-4",
                                                    "mt-1",
                                                    "pe-4",
                                                    "ps-0",
                                                    "py-4",
                                                    "w-100",
                                                    ...(j % 2
                                                        ? ["bg-light-accordion", "border-light-accordion"]
                                                        : ["bg-light-accordion-2", "border-light-accordion-2"]),
                                                ].join(" ")}>
                                                <h2
                                                    className="accordion-header data-accordion d-flex justify-content-between"
                                                    id="headingOne"
                                                >
                                                    <button
                                                        className="accordion-button accordion-button-2 mb-0 collapsed pt-0 pb-2 ps-4 pe-3"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target={`#collapse-${chapter.id}`}
                                                        aria-expanded="false"
                                                        aria-controls="collapseOne"
                                                    >
                                                        <HtmlRenderer
                                                            tag={"p"}
                                                            htmlContent={chapter.chapter_title}
                                                            className={"font-custom-4 fs-5 ps-1 me-auto mb-0"}
                                                            isItalic={true}
                                                        />
                                                        {/* <div className="font-custom-4 fs-3 ps-1 me-auto">
                                                            </div> */}
                                                    </button>
                                                </h2>
                                                <div
                                                    id={`collapse-${chapter.id}`}
                                                    // key={k+1}
                                                    className="accordion-collapse collapse "
                                                    aria-labelledby="headingOne"
                                                >
                                                    {
                                                        chapter.articles.map((d, k) => (
                                                            d.is_show &&
                                                            <div key={`article-${d.id}`} className="accordion-body py-0">
                                                                <div>
                                                                    <div className="row mb-0">
                                                                        <div className="col-md-2 ps-0">
                                                                            <span
                                                                                className="pt-1"
                                                                                style={{ fontSize: `${baseFontSize}px`, paddingLeft: "63px" }}
                                                                            >
                                                                                {d.article_name}
                                                                            </span>
                                                                        </div>
                                                                        <div className="col-md-10 ps-0 mb-0"> {
                                                                            d.is_recommendation === true ? (
                                                                                <div>
                                                                                    <HtmlTooltip
                                                                                        title={
                                                                                            <React.Fragment>
                                                                                                <Typography color="inherit">
                                                                                                    {
                                                                                                        d.content_paragraphs && d.content_paragraphs.length > 0 && d.content_paragraphs?.map(t =>
                                                                                                            <HtmlRenderer
                                                                                                                tag={'div'}
                                                                                                                id={`popup-${t.id}`}
                                                                                                                className={""}
                                                                                                                style={{ fontSize: `${baseFontSize}px` }}
                                                                                                                htmlContent={t.content_text}
                                                                                                            />
                                                                                                        )
                                                                                                    }
                                                                                                </Typography>
                                                                                            </React.Fragment>
                                                                                        }
                                                                                    >
                                                                                        <Link
                                                                                            to={`/portal/html-chapter/?rid=${d.RID}&article=${d.id}&lang=${componentLanguage}`}
                                                                                            className="text-primary d-flex "
                                                                                            target="_blank"
                                                                                        >
                                                                                            <HtmlRenderer
                                                                                                tag={'span'}
                                                                                                className={"text-primary fw-bold"}
                                                                                                style={{ fontSize: `${baseFontSize}px` }}
                                                                                                htmlContent={d.standard_content}
                                                                                            />
                                                                                            <svg
                                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                                width="32"
                                                                                                height="32"
                                                                                                viewBox="0 0 16 16"
                                                                                                className="pb-2  ps-4"
                                                                                            >
                                                                                                <path
                                                                                                    d="M9,2v1h3.29297l-6.26953,6.27344l0.70313,0.70313l6.27344,-6.26953v3.29297h1v-5zM4,4c-1.10547,0 -2,0.89453 -2,2v6c0,1.10547 0.89453,2 2,2h6c1.10547,0 2,-0.89453 2,-2v-5l-1,1v4c0,0.55078 -0.44922,1 -1,1h-6c-0.55078,0 -1,-0.44922 -1,-1v-6c0,-0.55078 0.44922,-1 1,-1h4l1,-1z"
                                                                                                    fill="#27282AA1"
                                                                                                ></path>
                                                                                            </svg>
                                                                                        </Link>
                                                                                    </HtmlTooltip>
                                                                                    {
                                                                                        d.content_paragraphs && d.content_paragraphs.length > 0 && d.content_paragraphs?.map(t =>
                                                                                            parseInt(t.style) === 135 &&
                                                                                            <HtmlRenderer
                                                                                                tag={'div'}
                                                                                                style={{ fontSize: `${baseFontSize}px` }}
                                                                                                htmlContent={t.content_text}
                                                                                            />
                                                                                        )
                                                                                    }
                                                                                </div>
                                                                            ) : (
                                                                                <Link
                                                                                    to={`/portal/html-chapter/?rid=${d.RID}&article=${d.id}&lang=${componentLanguage}`}
                                                                                    className="text-primary d-flex"
                                                                                    target="_blank"
                                                                                >
                                                                                    <HtmlRenderer
                                                                                        tag={'span'}
                                                                                        className={"text-primary fw-bold"}
                                                                                        style={{ fontSize: `${baseFontSize}px` }}
                                                                                        htmlContent={d.standard_content}
                                                                                    />
                                                                                    <svg
                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                        width="32"
                                                                                        height="32"
                                                                                        viewBox="0 0 16 16"
                                                                                        className="pb-2  ps-4"
                                                                                    >
                                                                                        <path
                                                                                            d="M9,2v1h3.29297l-6.26953,6.27344l0.70313,0.70313l6.27344,-6.26953v3.29297h1v-5zM4,4c-1.10547,0 -2,0.89453 -2,2v6c0,1.10547 0.89453,2 2,2h6c1.10547,0 2,-0.89453 2,-2v-5l-1,1v4c0,0.55078 -0.44922,1 -1,1h-6c-0.55078,0 -1,-0.44922 -1,-1v-6c0,-0.55078 0.44922,-1 1,-1h4l1,-1z"
                                                                                            fill="#27282AA1"
                                                                                        ></path>
                                                                                    </svg>
                                                                                </Link>
                                                                            )
                                                                        }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div style={{
                                                                    paddingLeft: "54px",
                                                                }}>
                                                                    {
                                                                        d.is_recommendation === false && (
                                                                            d.content_paragraphs && d.content_paragraphs.length > 0 && d.content_paragraphs?.map(t =>
                                                                                <HtmlRenderer
                                                                                    tag={'div'}
                                                                                    id={`content-paragraph-${t.id}`}
                                                                                    className={""}
                                                                                    style={{ fontSize: `${baseFontSize}px` }}
                                                                                    htmlContent={t.content_text}
                                                                                />
                                                                            )

                                                                        )
                                                                    }
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        ))
                                    )
                                )
                            }
                        </div>
                    </div>
                </div>
                {isAdditionalAlertVisible && (
                    <div
                        className="alert rounded-0 bg-alert-light-1 text-primary d-flex align-items-center my-3"
                        role="alert"
                    >
                        <BsExclamationTriangleFill
                            className="bi flex-shrink-0 me-5 mb-auto "
                            size={24}
                        />
                        <div className="fs-7 text-justify me-5">
                            {t("26", "These recommendations must be considered in the context of a corresponding full chapter, and in conjunction with other standards in the Code. The standards take into account the nature of the traded commodity, the animal health status of the animal population or subpopulation at origin, and the risk reduction measures applicable to each commodity, and assume compliance with the relevant horizontal chapters in Sections 1,2,3,4,5, and 6 of the Terrestrial Code.")}
                        </div>
                    </div>
                )}
            </div >
        </>
    );
};

export default AccessToCommoditiesV3;
