import { TextField } from '@mui/material';
import React from 'react';

const CustomTextField = (props) => {
    return (
        <TextField
            {...props}
            sx={{
                ...props.sx,
                '& .MuiFormLabel-root, & .MuiFormLabel-root.Mui-disabled': {
                    color: '#252525',
                    fontSize: '.8rem',
                },
            }}
        />
    );
}

CustomTextField.defaultProps = {
    sx: {}
};

export default CustomTextField;
