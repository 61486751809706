import CardBody from 'components/common/CardBody'
import CardHeader from 'components/common/CardHeader'
import Container from 'components/common/Container'
import CustomCard from 'components/common/CustomCard'
import React from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom'

    const dummyData = {
      errorType: '404 Not Found',
      errorCode: '404',
      message: 'The requested resource could not be found.',
      stack: 'Error: Not Found\n at app.js:34:23\n at server.js:12:4',
      apiReq: '/api/users',
      apiDetails: 'GET /api/users - The endpoint does not exist.'
    }  
    
const ErrorSiteDetails = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const goBack = () => {
        navigate(-1);
    }
    return (
        <Container>
            <CustomCard className={'d-flex justify-content-between'}>
                <CardHeader>
                    {t("-1", "Errors Site")}
                </CardHeader>
                <CardBody>

                    <button onClick={goBack} className="btn btn-dark btn-bg-dark fs-7 lh-1">
                        {" "}
                        <i className="fas fa-angle-left" />
                        {t("-1", "Back")}
                    </button>
                </CardBody>
            </CustomCard>
            <CustomCard style={{backgroundColor:'#f0f0f0'}}>
            <div className="card-title2 lh-1 border-bottom border-dark py-2">{t("-1", "Errors")}</div>
            <div>
      <div className="row py-2">
        <div className="col-md-2"><h2>Error Type:</h2></div>
        <div className="col-md-10 fw-bold" style={{ backgroundColor: '#FCEDEB', color: 'red' }}>
          {dummyData.errorType}
        </div>
      </div>
      <div className="row py-2">
        <div className="col-md-2"><h2>Error Code:</h2></div>
        <div className="col-md-10 fw-bold" style={{color:'red'}}>
          {dummyData.errorCode}
        </div>
      </div>
      <div className="row py-2">
        <div className="col-md-2"><h2>Message:</h2></div>
        <div className="col-md-10 fw-bold" style={{backgroundColor:"#FCEDEB", color:'red'}}>
          {dummyData.message}
        </div>
      </div>
      <div className="row py-2">
        <div className="col-md-2"><h2>Stack:</h2></div>
        <div className="col-md-10 fw-bold" style={{color:'red'}}>
          <pre>{dummyData.stack}</pre>
        </div>
      </div>
      <div className="row py-2">
        <div className="col-md-2"><h2>Api req:</h2></div>
        <div className="col-md-10 fw-bold" style={{backgroundColor:"#FCEDEB", color:'red'}}>
          {dummyData.apiReq}
        </div>
      </div>
      <div className="row py-2">
        <div className="col-md-2"><h2>Api Details:</h2></div>
        <div className="col-md-10 fw-bold" style={{color:'red'}}>
          {dummyData.apiDetails}
        </div>
      </div></div>
            </CustomCard>
        </Container>
    )
}

export default ErrorSiteDetails
